import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "@chakra-ui/react";
import {
  disableNotificationFollowOnAction as disableNotificationFollowOnActionThunk,
  dismissNotification as dismissNotificationThunk,
  dismissNotifications as dismissAllNotificationsThunk,
} from "state/notification/operations";
import type { RootState } from "state/rootReducer";
import type { Notification } from "types/notifications";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { sendMessage } from "state/websocket/operations";

const HIDE_EARLIER_NOTIFICATIONS_OLDER_THAN_DAYS = 120;

export function useNotifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notifications: notificationDictionary, hasInitialSyncCompleted } = useSelector((state: RootState) => state.notification);

  const notifications = useMemo(() => {
    return Object.values(notificationDictionary).sort((a, b) => (new Date(a.creationDateEpoch) < new Date(b.creationDateEpoch) ? 1 : -1));
  }, [notificationDictionary]);

  const newNotifications = useMemo(() => {
    return notifications.filter((notification) => !notification.hasBeenDismissed);
  }, [notifications]);

  const dismissedNotAcceptedNotifications = useMemo(() => {
    return notifications.filter((notification) => notification.followOnActionEnabled);
  }, [notifications]);

  const acceptedNotifications = useMemo(() => {
    return notifications.filter((notification) => !notification.followOnActionEnabled && notification.hasBeenDismissed);
  }, [notifications]);

  const earlierNotifications = useMemo(() => {
    const now = new Date();
    const earliestDateToIncludeNotifications = now.setDate(now.getDate() - HIDE_EARLIER_NOTIFICATIONS_OLDER_THAN_DAYS);

    return notifications.filter(
      (notification) => !notification.followOnActionEnabled && notification.creationDateEpoch >= earliestDateToIncludeNotifications
    );
  }, [notifications]);

  const previousNewNotifications = usePrevious(newNotifications);
  const hasInitialSyncCompletedPreviously = usePrevious(hasInitialSyncCompleted);

  const notificationsToPopup: Notification[] = useMemo(() => {
    // Don't show popups for notifications added by the initial poll
    if (!hasInitialSyncCompletedPreviously) return [];

    const previousIds = previousNewNotifications.map((notification) => notification.id);
    return newNotifications.filter((notification) => {
      return !previousIds.includes(notification.id);
    });
  }, [newNotifications, previousNewNotifications, hasInitialSyncCompletedPreviously]);

  const getNotification = useCallback(
    (id: string) => {
      return notificationDictionary[id];
    },
    [notificationDictionary]
  );

  const dismissNotification = useCallback(
    (id: string) => {
      if (notificationDictionary[id] && !notificationDictionary[id].hasBeenDismissed) {
        dispatch(dismissNotificationThunk(id));
      }
    },
    [dispatch, notificationDictionary]
  );

  const dismissAllNotifications = useCallback(() => {
    if (newNotifications.length > 0) {
      dispatch(dismissAllNotificationsThunk());
    }
  }, [dispatch, newNotifications.length]);

  const disableNotificationFollowOnAction = useCallback(
    (id: string) => {
      dispatch(disableNotificationFollowOnActionThunk(id));
    },
    [dispatch]
  );

  const actionNotification = useCallback(
    (id: string) => {
      const notification = notificationDictionary[id];
      if (!notification) return;

      if (notification.type === "NEW_SHARE") {
        if (!notification.hasBeenDismissed) {
          dismissNotification(notification.id);
        }

        if (notification.followOnActionEnabled) {
          const reference = notification.reference;

          if (reference && typeof reference.inviteCode === "string") {
            disableNotificationFollowOnAction(notification.id);
            const code = reference.inviteCode as string;
            const conversationId = uuid();
            dispatch(
              sendMessage({
                conversationId: conversationId,
                intent: "/accept_share",
                entities: [{ entity: "share_invite_id", value: code }],
                message: `/accept_share >share_invite_id ${code}`,
              })
            );
            navigate(`/search/${conversationId}`, { replace: true });
          }
        }
      }
    },
    [disableNotificationFollowOnAction, dismissNotification, dispatch, navigate, notificationDictionary]
  );

  return {
    notifications,
    newNotifications,
    dismissedNotAcceptedNotifications,
    earlierNotifications,
    notificationsToPopup,
    acceptedNotifications,
    getNotification,
    dismissNotification,
    dismissAllNotifications,
    actionNotification,
    disableNotificationFollowOnAction,
  };
}
