import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMemorizedClarificationConfig,
  getMemorizedClarificationsConfigs,
  createMemorizedClarificationConfig,
  updateMemorizedClarificationConfig,
  deleteMemorizedClarificationConfig,
} from "api/memorizedClarificationConfig";
import type {
  CreateMemorizedClarificationConfigRequest,
  UpdateMemorizedClarificationConfigRequest,
} from "api/memorizedClarificationConfig/models/MemorizedClarificationConfigApi";

export const downloadMemorizedClarificationsConfigs = createAsyncThunk("memorized-clarification-config/download-all", () => {
  return getMemorizedClarificationsConfigs();
});

export const downloadMemorizedClarificationConfig = createAsyncThunk("memorized-clarification-config/download", (id: string) => {
  return getMemorizedClarificationConfig(id);
});

export const createMemorizedClarificationConfigAction = createAsyncThunk(
  "memorized-clarification-config/create",
  (payload: CreateMemorizedClarificationConfigRequest) => {
    return createMemorizedClarificationConfig(payload);
  }
);

export const updateMemorizedClarificationConfigAction = createAsyncThunk(
  "memorized-clarification-config/update",
  (payload: UpdateMemorizedClarificationConfigRequest) => {
    return updateMemorizedClarificationConfig(payload);
  }
);

export const deleteMemorizedClarificationConfigAction = createAsyncThunk("memorized-clarification-config/delete", (id: string) => {
  return deleteMemorizedClarificationConfig(id);
});
