import { Union, Literal } from "runtypes";
import type { Static } from "runtypes";

export const TickerType = Union(
  Literal("equity"),
  Literal("fixed_income"),
  Literal("cash"),
  Literal("mutual_funds"),
  Literal("index_funds"),
  Literal("real_estate"),
  Literal("commodities"),
  Literal("currencies"),
  Literal("etf")
);
export type TickerType = Static<typeof TickerType>;
