import type { Static } from "runtypes";
import { Record, String, Array } from "runtypes";

export const ExtractedTableHighlightsDetail = Record({
  highlight: String,
  entity_name: String,
  statistical_measure: String,
  categorical_entities: Array(Record({})),
});

export type ExtractedTableHighlightsDetail = Static<typeof ExtractedTableHighlightsDetail>;
