import { Box, Center, Icon, Stack, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { FileTypeBadge } from "screens/common/components";
import { RecentItemContainer } from "screens/common/components/RecentItemContainer";
import { useContentDetails, useConversation } from "hooks";
import { ContentImageWrapper } from "screens/content/common/ContentImage/ContentImageWrapper";
import { useNavigate } from "react-router-dom";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { formatDistanceToNow } from "date-fns";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";

interface Props {
  label: string;
  extension: string;
  metadataId: string;
  onSelect: () => void;
  isChecked: boolean;
  hideCheckbox?: boolean;
}

export const RecentItemTile: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  label,
  extension,
  onSelect,
  isChecked,
  metadataId,
  hideCheckbox,
}) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const color = useColorModeValue("gray.500", "gray.300");
  const canvasItems = useContentDetails(metadataId);
  const tileState = useConversation(canvasItems?.mediaId || "");

  const navigate = useNavigate();
  const timeCreated =
    canvasItems && canvasItems.timeCreated
      ? formatDistanceToNow(new Date(canvasItems.timeCreated), {
          addSuffix: true,
          includeSeconds: false,
        })
      : undefined;

  const onHandleClick = () => {
    if (hideCheckbox && canvasItems) {
      navigate(`/library/resource/${canvasItems.id}`);
    } else {
      onSelect();
    }
  };

  return (
    <Box className="ch-resource-item" data-ext={extension} data-state={`${tileState.messageStateTooltip.toLowerCase()}`}>
      <RecentItemContainer
        cellHeight="4rem"
        onClick={(event) => {
          onHandleClick();
          event.stopPropagation();
        }}
        isChecked={isChecked}
        hideCheckbox={hideCheckbox}
        header={
          <>
            {canvasItems && (
              <Box>
                <ContentImageWrapper
                  metadataId={canvasItems.id}
                  thumbnailWidth="135px"
                  thumbnailHeight="75px"
                  thumbnailBorderRadius="5px"
                  fit="cover"
                  thumbnailFit="fill"
                />
              </Box>
            )}
            <Box position="absolute" bottom="-5px" left="10px" background={bgColor} borderRadius="md">
              <FileTypeBadge extension={extension} width="1rem" hideExtensionLabel />
            </Box>
          </>
        }>
        <Tooltip aria-label="" label={`View : ${label}`} placement="top" hasArrow>
          <Box
            background={bgColor}
            whiteSpace="normal"
            width="100%"
            onClick={(event) => {
              event.stopPropagation();
              if (canvasItems?.urls?.url) {
                window.open(canvasItems?.urls?.url, "_blank");
              }
            }}>
            {tileState.conversationState === "in_progress" ? (
              <Box py=".25rem">
                <TypingIndicator />
              </Box>
            ) : (
              <Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Text fontSize="xs" isTruncated color={color} background={bgColor}>
                    {label}
                  </Text>
                  <Icon color={color} fontSize="1rem" as={ExternalLinkIcon} />
                </Stack>
                <Center>
                  <TextOverflowTooltip color={color} fontSize="9px" label={timeCreated ? timeCreated : "Recently Added"} />
                </Center>
              </Stack>
            )}
          </Box>
        </Tooltip>
      </RecentItemContainer>
    </Box>
  );
};
