import type { JSONSchema7 } from "json-schema";
import { AnswerFocusOptionsSchema } from "./AnswerFocusOptionsSchema";
import { CostPerProjectSchema } from "./CostPerProjectSchema";
import { NewProjectFormSchema } from "./NewProjectFormSchema";
import { OnBoardingStepsSchema } from "./OnBoardingStepsSchema";

export const ProjectConfigSchema: JSONSchema7 = {
  $id: "http://json-schema.org/draft-07/schema#",
  type: "object",
  properties: {
    costPerProject: CostPerProjectSchema,
    route: {
      type: "string",
      examples: ["projects/due_diligence"],
      description:
        "The route used to determine path for router. if missing no sidebar menu will be created. Route must start with dashboard, library or projects",
      pattern: "^(dashboard|library|projects/|home)",
    },
    intent: {
      type: "string",
      examples: ["due_diligence"],
      description: "The intent that will be sent to charli.",
    },
    maxActiveProjectsAllowed: {
      type: "string",
      examples: [3],
      description:
        "Set the number of projects that can be active at one time. This is determined by the project statuses, clarification_needed, in_progress, and queued",
    },
    icon: {
      type: "string",
      examples: ["file-list-3"],
      description:
        "The icon that will be displayed in the sidebar menu. Must be one of the icons in the IconsList defined in src/configs/configMap.ts",
    },
    title: {
      type: "string",
      examples: ["Create Due Diligence"],
      description: "The title that will be displayed in the sidebar menu and views",
    },
    onBoardingTitle: {
      type: "string",
      examples: ["Financial Due Diligence"],
      description: "Onboarding title when there are no projects of this type",
    },
    onBoardingMessage: {
      type: "string",
      examples: ["Create a new project to get started"],
      description: "Onboarding message when there are no projects of this type",
    },
    onBoardingSteps: OnBoardingStepsSchema,
    collectionType: {
      type: "string",
      examples: ["due_diligence"],
      description: "The collection type used to filter collections for the views. Label can only be lowercase letters and underscores",
      pattern: "^[a-z_]*$",
    },
    showMenu: {
      type: "boolean",
      examples: [true],
      description:
        "The system preference for showing the menu. entitlement must be set for this to work. If this is set to false, the view will not be shown in the sidebar menu",
      pattern: "^(true|false)$",
    },
    showStockTicker: {
      type: "boolean",
      examples: [false],
      description:
        "Show a ticker in the project tile. This will only show if the project has a company_details subtype. If this is set to false, the ticker will not be shown in the project tile",
      pattern: "^(true|false)$",
    },
    showProjectTags: {
      type: "boolean",
      examples: [false],
      description: "Show the tags in the project tile. If this is set to false, the tags will not be shown in the project tile",
      pattern: "^(true|false)$",
    },
    entitlement: {
      type: "string",
      examples: ["due_diligence_project"],
      description:
        "Checks that the user is entitled to the view. showMenu can still hide entitled views. If this is set to false, the view will not be shown in the sidebar menu",
    },
    reverseEntitlement: {
      type: "string",
      examples: ["hide_this_feature"],
      description: "Checks that the user is specifically not allowed to see the view",
    },
    workflowIntentFilters: {
      type: "array",
      items: {
        type: "string",
        description:
          "The workflow intent that will be used to filter the workflow stepper. The workflow intents can be found in /admin/configured-workflows",
      },
      description: "The workflow intent filters used in the workflow stepper",
    },
    addSourceOptions: {
      type: "array",
      examples: ["['files', 'links']"],
      items: {
        type: "string",
        enum: ["files", "links", "search", "content"],
        description: "The source options for adding content to the project",
      },
      description: "The source options that will be enabled in the add source panel",
    },
    keyProjectContent: {
      type: "array",
      examples: ["['GIS Open Data','Zoning Bylaw' ...]"],
      items: {
        type: "string",
        description: "This is the category tag that will be used to find the metadata required to create a link to the content.",
      },
      description: "The key project content shown as links on the right of each tile in the list layouts on the projects landing page",
    },
    defaultFilterTags: {
      type: "array",
      examples: ["['compliance-label-mismatch','compliance-label-missing' ...]"],
      items: {
        type: "string",
        description: "A tag that is used to filter the project content.",
      },
      description: "The tags that will be selected by default in the content filter when the project opens",
    },
    metricTags: {
      type: "array",
      examples: ["['compliance-label-mismatch' ...]"],
      items: {
        type: "string",
        description: "A tag that is used as a metric in the project stats.",
      },
      description: "The tags that will be used to display stats for the project",
    },
    aiResultResourceSections: {
      type: "array",
      examples: ["['extracted_info' ...]"],
      items: {
        type: "string",
        description: "The detail sections from project resources that will be displayed in the AI result in the project details view",
      },
      description: "The sections that will be displayed in the AI result in the project details view",
    },
    deleteReasons: {
      type: "array",
      examples: ["['content_delete_reason', 'highlight_deletion_reason' ...]"],
      items: {
        type: "string",
        description:
          "This value will be sent as the value for the delete reason entity. ie. content_delete_reason or highlight_deletion_reason.",
      },
      description:
        "This array is used to populate the delete reason dropdown. The value is the reason that will be sent to charli when the user deletes the item.",
    },
    requiredIntegrations: {
      type: "array",
      examples: ["['google_drive', 'dropbox']"],
      items: {
        type: "string",
        description:
          "The URN for the required integration. These can be found by turning on Debug Mode in settings and then clicking any integration tile on the /integrations page",
      },
      description: "Used to determine what integrations are required before a project of this type can be created",
    },
    newProjectForm: NewProjectFormSchema,
    defaultReport: {
      type: "string",
      examples: ["due_diligence_report"],
      description: "The default report that will be used when creating a new project",
    },
    answerFocusOptions: AnswerFocusOptionsSchema,
    projectDetailQAInput: {
      type: "object",
      properties: {
        source: {
          type: "string",
          enum: ["tickers", "questions"],
          description: "The source of the question input",
        },
        showInput: {
          type: "boolean",
          description: "Show the question input",
        },
      },
    },
    projectLandingQAInput: {
      type: "object",
      properties: {
        source: {
          type: "string",
          enum: ["tickers", "questions"],
          description: "The source of the question input",
        },
        showInput: {
          type: "boolean",
          description: "Show the question input",
        },
      },
    },
  },
  required: ["intent", "title", "route"],
};
