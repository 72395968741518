import { createAction, createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import type { RootState } from "state/rootReducer";
import { FeatureFlags } from "types/flag";
import { downloadFlags } from "./operations";

const rehydrate = createAction<RootState>(REHYDRATE);

interface FlagsState {
  flags: {
    [intent in typeof FeatureFlags[number]]: boolean;
  };
}

const initialState: FlagsState = {
  flags: FeatureFlags.reduce(
    (accum, flag) => {
      accum[flag] = false;
      return accum;
    },
    {} as {
      [intent in typeof FeatureFlags[number]]: boolean;
    }
  ),
};

export const { actions, reducer } = createSlice({
  name: "flag",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (state, action) => {
      if (action.payload?.flag?.flags) {
        /*
         * Ignore the maintenance flag when rehydrating state from storage.
         * Persisting the maintenance flag presents a problem when we refresh the browser after maintenance is over, as
         * the refresh is triggered before redux-persist has had a chance to persist state changes to storage. This causes
         * an infinite loop as the page keeps detecting maintenance transitions on every reload.
         *
         * I also prefer this flag not be persisted as it guarantees this flag will never be stale.
         */
        return { flags: { ...action.payload.flag.flags, show_maintenance_screen: false } };
      }
    });
    builder.addCase(downloadFlags.fulfilled, (state, action) => {
      state.flags = action.payload;
    });
  },
});

export default reducer;
