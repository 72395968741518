import { Record, String, Dictionary } from "runtypes";
import type { Static } from "runtypes";
import { FeatureUsage } from "./FeatureUsage";

export const UserFeatureUsage = Record({
  userId: String,
  featureAccess: Dictionary(FeatureUsage, String),
});

export type UserFeatureUsage = Static<typeof UserFeatureUsage>;
