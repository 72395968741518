import type { Static } from "runtypes";
import { Array, Record, String } from "runtypes";
import { PlanComponent } from "./PlanComponents";
import { isEmpty } from "lodash";

export const PlanDetails = Record({
  productHandle: String,
  components: Array(PlanComponent).withConstraint((components) => !isEmpty(components) || "Components cannot be empty"),
});

export type PlanDetails = Static<typeof PlanDetails>;
