import { Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";

interface Props {
  onClick: () => void;
  isLoading?: boolean;
  label?: string;
  totalItemCount: number;
  currentItemCount: number;
}

export const ItemLoadingIndicator: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  onClick,
  label = "Projects",
  totalItemCount,
  currentItemCount,
}) => {
  const buttonColor = useColorModeValue("gray.600", "gray.500");

  return (
    <Stack direction="row" spacing="0.75rem">
      <Text
        px=".5rem"
        fontSize="xs"
        color={buttonColor}
        fontWeight="normal">{`Showing ${currentItemCount} of ${totalItemCount} ${label}.`}</Text>
    </Stack>
  );
};
