import type { ValueType } from "react-select";
import React, { createContext, useState } from "react";
import type { ReactNode } from "react";
import type { SearchHistory } from "types/collection";
import { useDisclosure } from "@chakra-ui/react";

export type SelectValue = ValueType<
  {
    label: string;
    value: string;
    alreadyExists?: boolean;
  },
  false
>;

const defaultThrowError = (): void => {
  throw new Error("Component must be nested inside <ResearchContextProvider />");
};

export const ResearchContext = createContext({
  isAddToResearch: false,
  setIsAddToResearch: defaultThrowError as (isAddToResearch: boolean) => void,
  selectedSearchHistory: {} as SearchHistory | undefined,
  setSelectedSearchHistory: (text: SearchHistory | undefined): void => {
    defaultThrowError();
  },
  projectType: undefined as string | undefined,
  setProjectType: (projectType: string | undefined): void => {
    defaultThrowError();
  },
  isResearchHistoryPanelOpen: false,
  onResearchHistoryPanelClose: defaultThrowError,
  onResearchHistoryPanelOpen: defaultThrowError,
  isDynamicFormEditorPanelOpen: false,
  onDynamicFormEditorPanelClose: defaultThrowError,
  onDynamicFormEditorPanelToggle: defaultThrowError,
});

export const ResearchContextProvider = ({ children }: { children: ReactNode }) => {
  const [selectedSearchHistory, setSelectedSearchHistory] = useState<SearchHistory>();
  const [projectType, setProjectType] = useState<string | undefined>(undefined);
  const [isAddToResearch, setIsAddToResearch] = useState(false);
  const {
    isOpen: isResearchHistoryPanelOpen,
    onClose: onResearchHistoryPanelClose,
    onToggle: onResearchHistoryPanelOpen,
  } = useDisclosure();
  const {
    isOpen: isDynamicFormEditorPanelOpen,
    onClose: onDynamicFormEditorPanelClose,
    onToggle: onDynamicFormEditorPanelToggle,
  } = useDisclosure();

  return (
    <ResearchContext.Provider
      value={{
        isAddToResearch,
        setIsAddToResearch,
        selectedSearchHistory,
        setSelectedSearchHistory,
        projectType,
        setProjectType,
        isResearchHistoryPanelOpen,
        onResearchHistoryPanelClose,
        onResearchHistoryPanelOpen,
        isDynamicFormEditorPanelOpen,
        onDynamicFormEditorPanelClose,
        onDynamicFormEditorPanelToggle,
      }}>
      {children}
    </ResearchContext.Provider>
  );
};
