import { Literal, Record, String, Union } from "runtypes";
import type { Static } from "runtypes";

export const Question = Record({
  id: String,
  question: String,
  focus: Union(Literal("analytical"), Literal("sentiment")),
});

export type Question = Static<typeof Question>;
