import React from "react";
import { Box, Button, Flex, FormControl, FormHelperText, FormLabel, Select } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useButtonProps } from "hooks";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ExchangeOptions } from "../utils/constants";

export interface ExportTickersForm {
  exchange: string;
}

interface IProps {
  onSubmit: (values: ExportTickersForm) => void;
  isLoading?: boolean;
}

interface IPanelProps extends IProps {
  onClose: () => void;
  isOpen: boolean;
}

const validationSchema = yup.object().shape({
  exchange: yup.string().required("Exchange is required"),
});

const ExportTickers = ({ onSubmit, isLoading }: IProps) => {
  const { handleSubmit, control } = useForm<ExportTickersForm>({
    defaultValues: {
      exchange: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const commonButtonProps = useButtonProps("sm", "primary");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="exchange"
        render={({ field, formState: { errors } }) => {
          return (
            <FormControl isInvalid={!!errors.exchange}>
              <FormLabel>Exchange</FormLabel>
              <Select {...field} placeholder="Select exchange" isDisabled={isLoading}>
                {ExchangeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              {errors.exchange && <FormHelperText color="red">{errors.exchange.message}</FormHelperText>}
            </FormControl>
          );
        }}
      />

      <Flex mt={"1rem"} justifyContent={"flex-end"}>
        <Button isLoading={isLoading} type="submit" {...commonButtonProps}>
          Export tickers
        </Button>
      </Flex>
    </form>
  );
};

const ExportTickersPanel = (props: IPanelProps) => {
  const { onClose, isOpen, ...rest } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={"Export tickers to csv file"}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem">{isOpen && <ExportTickers {...rest} />}</Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};

export { ExportTickersPanel };
