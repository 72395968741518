import React, { useState, useEffect, useContext } from "react";
import {
  Stack,
  Text,
  useColorModeValue,
  Button,
  MenuItem,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Center,
} from "@chakra-ui/react";
import { TypingIndicator } from "screens/thread/components/cells/components";
import {
  useButtonProps,
  useCollection,
  useCollectionKey,
  useCommaSeparatedEntitiesMap,
  useConfigMap,
  useConversation,
  useItemSelector,
  useProjectParams,
} from "hooks";
import { ConversationContext } from "screens/thread/ConversationContext";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";
import { ResearchTopicsInput } from "../research/ResearchTopicsInput";
import { useDispatch } from "react-redux";
import { ToastMessageContent } from "screens/common/components";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { sendMessage } from "state/websocket/operations";
import type { RequestEntities } from "types/charliui";
import { v4 as uuid } from "uuid";

interface Props {
  showAsButton?: boolean;
}

export const HighlightsButtonMenuItem = ({ showAsButton = false }: Props) => {
  const { projectId = "", contentId } = useProjectParams();
  const { setRequestEntities, onConversationOpen, requestEntities, setConversationId } = useContext(ConversationContext);
  const collection = useCollection(projectId);
  const collectionConversationId = useCollectionKey(projectId, "conversationId");
  const highlightsState = useConversation(contentId || "", ["extract_highlight", "generate_highlights", "cancel"]);
  const primaryButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const additionalThumbnailCountTextColor = useColorModeValue("gray.500", "gray.400");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const configMap = useConfigMap();
  const dispatch = useDispatch();
  const { focusedItem } = useItemSelector();
  const toast = useToast();
  const [topics, setTopics] = useState("");
  const commaSeparatedEntitiesMap = useCommaSeparatedEntitiesMap(configMap);
  const currentConversationId = collectionConversationId || contentId;

  useEffect(() => {
    const newTopics = getRequestValue("topic", requestEntities);
    setTopics(newTopics);
  }, [requestEntities]);

  useEffect(() => {
    if (topics.length > 0) {
      updateBatchRequestEntities(
        [
          { entity: "min_length", value: "90", source: "topic-inputs" },
          { entity: "max_length", value: "130", source: "topic-inputs" },
        ],
        setRequestEntities
      );
      if (focusedItem) {
        updateBatchRequestEntities([{ entity: focusedItem.type, value: focusedItem.id, source: "topic-inputs" }], setRequestEntities);
      }
      if (collection) {
        updateBatchRequestEntities(
          [
            { entity: "collection_id", value: projectId || "", source: "topic-inputs" },
            { entity: "metadata_id", value: projectId || "", source: "topic-inputs" },
          ],
          setRequestEntities
        );
      }
    } else {
      setRequestEntities([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics]);

  const generateHighlights = () => {
    onClose();
    const entities: RequestEntities = [];

    if (requestEntities && requestEntities.length > 0) {
      requestEntities.forEach((requestEntity) => {
        const splitEntity = commaSeparatedEntitiesMap[requestEntity.entity];
        if (splitEntity) {
          requestEntity.value.split(splitEntity).forEach((itemValue) => {
            if (itemValue.length > 0) {
              entities.push({ entity: requestEntity.entity, value: itemValue.trim() });
            }
          });
        } else {
          entities.push({ entity: requestEntity.entity, value: requestEntity.value });
        }
      });
    }

    dispatch(
      sendMessage({
        conversationId: currentConversationId || uuid(),
        intent: "/generate_highlights",
        entities: entities,
      })
    );

    setRequestEntities([]);

    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`No problem, I'll generate the highlights. You can open the conversation I started about it by clicking this message`}
          onClick={() => {
            currentConversationId && setConversationId(currentConversationId);
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const onCancel = () => {
    onClose();
    setRequestEntities([]);
  };

  useEffect(() => {
    if (!isOpen) {
      setRequestEntities([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {showAsButton ? (
        <Center width="100%" height="4rem">
          {highlightsState.conversationState !== "in_progress" ? (
            <Button {...primaryButtonProps} onClick={onOpen}>
              Generate Highlights
            </Button>
          ) : (
            <Stack direction="row" spacing="1rem">
              <Text color={additionalThumbnailCountTextColor} fontSize={"sm"}>
                Generating Highlights
              </Text>
              <TypingIndicator size="small" />
            </Stack>
          )}
        </Center>
      ) : (
        <>
          {highlightsState.conversationState === "in_progress" ? (
            <MenuItem isDisabled>
              <Stack direction="row" spacing="1rem">
                <Text color={additionalThumbnailCountTextColor} fontSize={"sm"}>
                  Generating Highlights
                </Text>
                <TypingIndicator size="small" />
              </Stack>
            </MenuItem>
          ) : (
            <OptionsMenuItem
              menuLabel={"Generate Highlights"}
              className="ch-highlight-btn"
              onClick={() => {
                onOpen();
              }}
            />
          )}
        </>
      )}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate Highlights</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize={"sm"}>
            <Stack justifyContent="space-between" spacing="2rem">
              <ResearchTopicsInput />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack direction="row" spacing="1rem">
              <Button {...secondaryButtonProps} onClick={onCancel}>
                Cancel
              </Button>
              <Button className="ch-highlight-generate-button-modal" {...primaryButtonProps} mr={3} onClick={generateHighlights}>
                Generate Highlights
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
