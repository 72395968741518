import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBillingPlans, createBillingPlan as createBillingPlanAPI, updateBillingPlan as updateBillingPlanAPI } from "api/billingPlan";
import type { CreatePlanRequest } from "api/billingPlan/models/CreatePlanRequest";
import type { UpdatePlanRequest } from "api/billingPlan/models/UpdatePlanRequest";
import { getPlanEntitlements } from "api/entitlements";

export const downloadBillingPlans = createAsyncThunk("billingPlans/download-all", async () => {
  return await getBillingPlans();
});

export const createBillingPlan = createAsyncThunk("billingPlans/create", async (payload: CreatePlanRequest) => {
  return await createBillingPlanAPI(payload);
});

export const updateBillingPlan = createAsyncThunk(
  "billingPlans/update",
  async ({ code, payload }: { code: string; payload: UpdatePlanRequest }) => {
    return await updateBillingPlanAPI(code, payload);
  }
);

export const downloadPlanEntitlements = createAsyncThunk(
  "billingPlans/entitlements/download",
  async ({ planCode }: { planCode: string }) => {
    return await getPlanEntitlements(planCode);
  }
);
