import { createSlice } from "@reduxjs/toolkit";
import type { Resource } from "api/informationExtractionConfig/models/Resource";
import type { TargetEntity } from "api/informationExtractionConfig/models/TargetEntity";
import reduce from "lodash/reduce";
import {
  downloadResources,
  addResource,
  editResource,
  removeResource,
  addTargetEntities,
  editTargetEntity,
  removeTargetEntity,
} from "./operations";

export type ResourceWithId = Resource & { id: string };
type NormalizedResource = Omit<ResourceWithId, "entities"> & { targetEntitiesIds: string[] };

interface InformationExtractionState {
  isLoading: boolean;
  resources: {
    byId: Record<string, NormalizedResource>;
    allIds: string[];
  };
  targetEntities: {
    byId: Record<string, TargetEntity>;
    allIds: string[];
  };
}

const initialState: InformationExtractionState = {
  isLoading: false,
  resources: {
    byId: {},
    allIds: [],
  },
  targetEntities: {
    byId: {},
    allIds: [],
  },
};

export const { actions, reducer } = createSlice({
  name: "informationExtractionConfig",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadResources.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadResources.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadResources.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.resources.byId = reduce(
        payload,
        (acc: Record<string, NormalizedResource>, data) => {
          const { entities, ...resource } = data;

          acc[resource.urn] = {
            ...resource,
            id: resource.urn,
            targetEntitiesIds: entities?.map((entity) => entity.id) ?? [],
          };
          return acc;
        },
        {}
      );

      state.resources.allIds = payload.map((resource) => resource.urn);

      state.targetEntities.byId = reduce(
        payload.flatMap((resource) => resource.entities ?? []),
        (acc: Record<string, TargetEntity>, entity) => {
          acc[entity.id] = entity;
          return acc;
        },
        {}
      );

      state.targetEntities.allIds = payload.flatMap((resource) => resource.entities ?? []).map((entity) => entity.id);
    });

    builder.addCase(addResource.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addResource.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addResource.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      // Remove old target entities, if any
      if (state.resources.byId[payload.urn]) {
        const oldTargetEntitiesIds = state.resources.byId[payload.urn].targetEntitiesIds;

        oldTargetEntitiesIds.length > 0 &&
          oldTargetEntitiesIds.forEach((id) => {
            delete state.targetEntities.byId[id];
            state.targetEntities.allIds = state.targetEntities.allIds.filter((entityId) => entityId !== id);
          });
      } else {
        state.resources.allIds.push(payload.urn);
      }

      const { entities, ...resource } = payload;

      state.resources.byId[resource.urn] = {
        ...resource,
        id: resource.urn,
        targetEntitiesIds: entities?.map((entity) => entity.id) ?? [],
      };

      entities?.forEach((entity) => {
        state.targetEntities.byId[entity.id] = entity;
        state.targetEntities.allIds.push(entity.id);
      });
    });

    builder.addCase(editResource.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editResource.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editResource.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.resources.byId[payload.urn] = {
        ...payload,
        id: payload.urn,
        targetEntitiesIds: state.resources.byId[payload.urn].targetEntitiesIds,
      };
    });

    builder.addCase(removeResource.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeResource.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeResource.fulfilled, (state, { meta }) => {
      state.isLoading = false;
      delete state.resources.byId[meta.arg];
      state.resources.allIds = state.resources.allIds.filter((id) => id !== meta.arg);
    });

    builder.addCase(addTargetEntities.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addTargetEntities.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addTargetEntities.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      payload.forEach((entity) => {
        state.resources.byId[entity.resourceUrn].targetEntitiesIds.push(entity.id);
        state.targetEntities.byId[entity.id] = entity;
        state.targetEntities.allIds.push(entity.id);
      });
    });

    builder.addCase(editTargetEntity.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editTargetEntity.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editTargetEntity.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.targetEntities.byId[payload.id] = payload;
    });

    builder.addCase(removeTargetEntity.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeTargetEntity.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeTargetEntity.fulfilled, (state, { meta }) => {
      state.isLoading = false;
      const resourceUrn = state.targetEntities.byId[meta.arg].resourceUrn;

      delete state.targetEntities.byId[meta.arg];
      state.resources.byId[resourceUrn].targetEntitiesIds = state.resources.byId[resourceUrn].targetEntitiesIds.filter(
        (id) => id !== meta.arg
      );

      state.targetEntities.allIds = state.targetEntities.allIds.filter((id) => id !== meta.arg);
    });
  },
});

export default reducer;
