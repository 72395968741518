import { CheckIcon } from "@chakra-ui/icons";
import { useColorModeValue, IconButton } from "@chakra-ui/react";
import React from "react";

export const ContentCheckbox = ({
  isChecked,
  onClick,
  offset = ".2rem",
}: {
  isChecked: boolean;
  onClick: (isChecked) => void;
  offset?: string;
}) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const hoverColor = useColorModeValue("gray.400", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const tickColor = useColorModeValue("primary.darkGray", "gray.400");

  return (
    <IconButton
      top={offset}
      right={offset}
      boxSize="1rem"
      minWidth={"1rem"}
      border="1px solid"
      borderColor={borderColor}
      zIndex="3"
      size="sm"
      backgroundColor={bgColor}
      borderRadius="sm"
      position="absolute"
      boxShadow="none"
      aria-label=""
      icon={<CheckIcon color={tickColor} opacity={isChecked ? 1 : 0} />}
      color={tickColor}
      _hover={{
        backgroundColor: hoverColor,
      }}
      onClick={onClick}
    />
  );
};
