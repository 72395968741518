import type { FunctionComponent } from "react";
import React, { useEffect, useContext } from "react";
import { v4 as uuid } from "uuid";
import { useCollectionKey, useConfigForm, useConfigMap, useGetViewConfig, useProjectParams } from "hooks";
import { Wizard } from "react-use-wizard";
import { ResearchContext } from "./ResearchContext";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { ConversationContext } from "screens/thread/ConversationContext";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ProjectReportForm } from "./ProjectReportForm";
import { ProjectCriteriaForm } from "./ProjectCriteriaForm";
import { AddToCharliUploadForm } from "../addToCharli/AddToCharliWizard";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { getViewConfig } from "configs/configMap";
import { useSendToCharli } from "../addToCharli/AddToCharliWizard/useSendToCharli";
import { ResearchSearchInputs } from "./ResearchSearchInputs";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  collectionType?: string;
}

export const AddNewProjectPanel: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  isOpen,
  onClose,
  collectionType: collectionTypeFromProp,
}) => {
  const { projectId = "", isPortfolios, contentId, projectFilter } = useProjectParams();
  const collectionConversationId = useCollectionKey(projectId, "conversationId");
  const projectCollectionType = useCollectionKey(projectId, "collectionType");
  const { requestEntities, setRequestEntities, setConversationId, conversationId } = useContext(ConversationContext);
  const currentConversationId = conversationId || collectionConversationId || contentId;
  const { projectType, setProjectType, setIsAddToResearch, isAddToResearch } = useContext(ResearchContext);
  const { resetFormState } = useAddToCharliContext();
  const configMap = useConfigMap();
  const collectionTypeFromRoute = useGetViewConfig("collectionType", projectType || projectFilter, configMap);
  const collectionType = projectCollectionType || collectionTypeFromProp || collectionTypeFromRoute;
  const { sendRequest, useCharliRequestLog } = useSendToCharli();

  useEffect(() => {
    if (collectionType && isOpen) {
      updateBatchRequestEntities([{ entity: "collection_type", value: collectionType, source: "project-form-inputs" }], setRequestEntities);
    }
    const portfolioIdValue = projectFilter && isPortfolios ? projectFilter : undefined;
    if (portfolioIdValue) {
      updateBatchRequestEntities([{ entity: "portfolio_id", value: portfolioIdValue, source: "project-form-inputs" }], setRequestEntities);
    }
  }, [collectionType, isOpen, isPortfolios, projectCollectionType, projectFilter, setRequestEntities]);

  const send = () => {
    const selectedCollectionId = getRequestValue("collection_id", requestEntities);
    const newConversationId = currentConversationId ? currentConversationId : uuid();
    setConversationId(newConversationId);

    // get highlights if topics are selected and no query is entered
    const isTopicsOnly = getRequestValue("topic", requestEntities).length > 0 && !getRequestValue("query", requestEntities);
    const intent =
      isTopicsOnly && collectionType === "research"
        ? "generate_highlights"
        : `${getViewConfig("intent", collectionType || "research", configMap)}`;

    sendRequest(
      intent,
      onClose,
      `No problem, I'll ${
        selectedCollectionId.length > 0 ? "update the" : "create a new"
      } project now. The project stepper will keep you up to date with the progress. You can also open the conversation I started about it by clicking this message`
    );

    handleOnClose();
  };

  const handleOnClose = () => {
    setIsAddToResearch(false);
    setRequestEntities([]);
    resetFormState();
    setProjectType(undefined);
    onClose();
  };

  useCharliRequestLog(isOpen);

  const { projectForm } = useConfigForm(collectionType || "", configMap);
  const showUploadMaxFiles = projectForm?.showUploadMaxFiles;

  const projectFormStepOrder = projectForm && projectForm?.projectFormStepOrder;
  const defaultProjectStepOrder = ["upload", "criteria", "report"];
  const stepsOrder = projectFormStepOrder || defaultProjectStepOrder;

  const StepLabels = {
    upload: "Upload Sources",
    criteria: "Project Criteria",
    report: "Project Report",
    create_project: "Create Project",
  };

  const getStep = (step: string) => {
    return (
      <PanelStep
        key={step}
        onPreviousLabel={stepsOrder.indexOf(step) > 0 ? StepLabels[stepsOrder[stepsOrder.indexOf(step) - 1]] : ""}
        onNextLabel={
          stepsOrder[stepsOrder.indexOf(step) + 1] ? StepLabels[stepsOrder[stepsOrder.indexOf(step) + 1]] : StepLabels.create_project
        }
        onSubmit={stepsOrder[stepsOrder.length - 1] === step ? send : undefined}>
        {step === "upload" && <AddToCharliUploadForm maxFiles={showUploadMaxFiles} />}
        {step === "criteria" && <ProjectCriteriaForm collectionType={collectionType} />}
        {step === "report" && <ProjectReportForm />}
      </PanelStep>
    );
  };

  const steps = {
    upload: getStep("upload"),
    criteria: getStep("criteria"),
    report: getStep("report"),
  };

  return (
    <PanelView
      isOpen={isOpen}
      onClose={handleOnClose}
      panelTitle={`${isAddToResearch ? "Add" : "New"} ${getViewConfig("title", collectionType || "research", configMap)} Project`}>
      {isAddToResearch ? (
        <Wizard>
          <PanelStep onNextLabel="Add to Project" onSubmit={send}>
            <ResearchSearchInputs showResearchTopics={collectionType === "research"} copyResearchHistory />
          </PanelStep>
        </Wizard>
      ) : (
        <Wizard>{stepsOrder.map((step) => steps[step])}</Wizard>
      )}
    </PanelView>
  );
};
