import type { FunctionComponent } from "react";
import React from "react";
import { useColorModeValue, Stack, Text, Image } from "@chakra-ui/react";
import type { ImageProps } from "@chakra-ui/react";
import SheetIcon from "screens/common/static/fileTypes/sheet-icon.png";
import PdfIcon from "screens/common/static/fileTypes/pdf-icon.png";
import DocIcon from "screens/common/static/fileTypes/doc-icon.png";
import ImageIcon from "screens/common/static/fileTypes/image-icon.png";
import SlideIcon from "screens/common/static/fileTypes/slide-icon.png";
import LinkIcon from "screens/common/static/fileTypes/link-icon.png";
import ExpenseIcon from "screens/common/static/fileTypes/expense-icon.png";
import HelpIcon from "screens/common/static/fileTypes/help-icon.svg";
import AudioIcon from "screens/common/static/fileTypes/audio-icon.png";
import VideoIcon from "screens/common/static/fileTypes/video-icon.png";
import DefaultIcon from "screens/common/static/fileTypes/default-icon.png";
import DataIcon from "screens/common/static/fileTypes/data-icon.png";
import ProjectIcon from "screens/common/static/fileTypes/project-icon.png";
import omit from "lodash/omit";
import { getFileTypeNameByExtension } from "screens/common/modal/utils";

interface Props {
  fileTypeName?: string;
  extension?: string;
  hideExtensionLabel?: boolean;
}

const FileTypeBadgeNonMemo: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props & ImageProps>>> = (props) => {
  const width = props.width ?? ".8rem";
  const maxHeight = props.height ?? "1rem";
  const imageProps: ImageProps = {
    opacity: ".7",
    borderRadius: "sm",
    width: width,
    maxHeight: maxHeight,
    ...omit(props, ["extension", "fileTypeName", "hideExtensionLabel"]),
  };
  const extension = props.extension;
  const tileBorderColor = useColorModeValue("gray.500", "gray.600");
  const hideLabel = props.hideExtensionLabel ?? false;

  const FileTypeImage = (props: { src: string }) => {
    return (
      <Stack direction="row" spacing="0.6rem">
        <Image {...imageProps} src={props.src} />
        {hideLabel === false && (
          <Text pt="3px" fontWeight="semibold" fontSize="sm" color={tileBorderColor}>
            {extension && getFileTypeNameByExtension(extension.slice(0, 3).toLowerCase()).toUpperCase()}
          </Text>
        )}
      </Stack>
    );
  };

  switch (extension?.slice(0, 3).toLowerCase()) {
    case "pdf":
      return <FileTypeImage src={PdfIcon} />;
    case "xls":
    case "xlsx":
    case "xlsm":
    case "csv":
    case "ods":
      return <FileTypeImage src={SheetIcon} />;
    case "ppt":
    case "pptx":
    case "odp":
      return <FileTypeImage src={SlideIcon} />;
    case "lnk":
    case "lin":
    case "link":
      return <FileTypeImage src={LinkIcon} />;
    case "doc":
    case "docx":
    case "txt":
    case "odt":
    case "rtf":
    case "md":
    case "vtt":
      return <FileTypeImage src={DocIcon} />;
    case "img":
    case "jpg":
    case "jpe":
    case "png":
    case "bmp":
    case "svg":
    case "webp":
      return <FileTypeImage src={ImageIcon} />;
    case "exp":
      return <FileTypeImage src={ExpenseIcon} />;
    case "hel":
      return <FileTypeImage src={HelpIcon} />;
    case "col":
    case "pro":
    case "mee":
    case "val":
      return <FileTypeImage src={ProjectIcon} />;
    case "aud":
      return <FileTypeImage src={AudioIcon} />;
    case "vid":
      return <FileTypeImage src={VideoIcon} />;
    case "dyn":
    case "dat":
    case "gis":
    case "lts":
    case "sec":
      return <FileTypeImage src={DataIcon} />;
    default:
      return <FileTypeImage src={DefaultIcon} />;
  }
};

export const FileTypeBadge = React.memo(FileTypeBadgeNonMemo);
