import React, { useEffect, useContext } from "react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { Highlights } from "./Highlights";
import { Box } from "@chakra-ui/react";
import { useCollectionKey, useContentDetails, useProjectParams } from "hooks";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ConversationContext } from "screens/thread/ConversationContext";
import { HighlightsButton } from "./HighlightsButton";

export const HighlightsPanel = () => {
  const { projectId = "", contentId } = useProjectParams();
  const { setRequestEntities } = useContext(ConversationContext);
  const collectionExtractedHighlights = useCollectionKey(projectId, "extractedHighlights");
  const getContentDetails = useContentDetails(contentId);
  const allHighlights = getContentDetails?.extractedHighlights || collectionExtractedHighlights;
  const { onShareModalClose, isHighlightsPanelOpen, onHighlightsPanelClose, setShowHighlightsForm } = useAddToCharliContext();

  useEffect(() => {
    setShowHighlightsForm(true);
    if (!isHighlightsPanelOpen) {
      setShowHighlightsForm(false);
      setRequestEntities([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHighlightsPanelOpen, onShareModalClose]);

  return (
    <PanelView isOpen={isHighlightsPanelOpen} onClose={onHighlightsPanelClose} panelTitle="Highlights">
      <Wizard>
        <PanelStep>
          <Box pb="5rem">
            <HighlightsButton />
            <Highlights
              extractedHighlights={allHighlights}
              showCopyButton
              textTruncated={false}
              showRecordLinkBtn
              onClose={onHighlightsPanelClose}
            />
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
