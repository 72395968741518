import React from "react";
import { Heading, Stack, Text } from "@chakra-ui/react";

import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { ValueDisplay } from "../components/ValueDisplay";

import moment from "moment";
import type { Preference } from "api/systemPreferences/models/Preference";

interface IProps {
  preference: Preference;
  isOpen: boolean;
  onClose: () => void;
}

const formatDisplayDate = "YYYY-MM-DD HH:mm:ss";

export const PreferenceDetailModal = (props: IProps) => {
  const { isOpen, onClose, preference } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${preference.key} details`}>
      <Wizard>
        <PanelStep>
          <Stack spacing={8} px="1rem">
            <Stack my="0.5rem">
              <Stack direction="row" alignItems="center">
                <Heading fontSize="md">Key:</Heading>
                <Text fontSize="md">{preference.key}</Text>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Heading fontSize="md">Value:</Heading>
                <ValueDisplay preference={preference} />
              </Stack>
              <Stack direction="row" alignItems="center">
                <Heading fontSize="md">Created:</Heading>
                <Text fontSize="md">{moment(preference.createdAt).format(formatDisplayDate)}</Text>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Heading fontSize="md">Modified:</Heading>
                <Text fontSize="md">{moment(preference.updatedAt).format(formatDisplayDate)}</Text>
              </Stack>
            </Stack>
          </Stack>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
