import React, { useCallback, useEffect } from "react";
import { PanelView } from "screens/panels/components/PanelView";
import { PanelStep } from "screens/panels/components/PanelStep";
import { Wizard } from "react-use-wizard";
import { WorkflowSummary } from "screens/common/components/WorkflowSummary/v2/WorkflowSummary";
import { Box, Stack } from "@chakra-ui/react";
import { TabTitle } from "screens/common/components/TabTitle";
import type { Workflow } from "types/workflows/workflow";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { getWorkflowSummary } from "api/workflows";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  workflow?: Workflow;
  isContentViewOpen: boolean;
  isLoadingWorkflow: boolean;
  isMessageId: string;
}

export const WorkflowPanel = ({ isOpen, workflow, isLoadingWorkflow, isContentViewOpen, isMessageId, onClose }: Props) => {
  const { setWorkflowSummary } = useWorkflowContext();

  const fetchWorkflowSummary = useCallback(() => {
    const id = workflow?.id;

    if (id) {
      getWorkflowSummary(id)
        .then((workflowSummary) => setWorkflowSummary(workflowSummary))
        .catch((error) => console.error(error));
    }
  }, [workflow?.id, setWorkflowSummary]);

  useEffect(() => {
    fetchWorkflowSummary();
  }, [fetchWorkflowSummary]);

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`Workflow Details ${workflow?.title ? ": " + workflow.title : ""}`}>
      <Wizard>
        <PanelStep>
          <TabTitle title={`Charli > Workflow Detail`} />
          <Stack>
            {isLoadingWorkflow ? (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box flex="1" textAlign="left" fontSize="sm" fontWeight="semibold">
                  Loading workflow...
                </Box>
                <TypingIndicator size="small" />
              </Box>
            ) : workflow ? (
              <WorkflowSummary workflowSummary={workflow} id={isMessageId} />
            ) : (
              <Box flex="1" textAlign="left" fontSize="sm" fontWeight="semibold">
                Workflow not found.
              </Box>
            )}
          </Stack>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
