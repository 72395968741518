import { Record, String, Number, Array } from "runtypes";
import type { Static } from "runtypes";

export const StockExchangeRecord = Record({
  name: String,
  exchange: String,
  ticker: String,
});

export type StockExchangeRecord = Static<typeof StockExchangeRecord>;

export const NewStockExchangeRecord = Record({
  company_name: String,
  company_stock_exchange: String,
  company_ticker: String,
});

export type NewStockExchangeRecord = Static<typeof NewStockExchangeRecord>;

export const StockDataRecords = Record({
  data: Array(
    Record({
      date: String,
      value: Number,
    })
  ),
});

export type StockDataRecords = Static<typeof StockDataRecords>;

export type StockEquityData = {
  data: {
    chart: {
      result: [
        {
          meta: {
            currentTradingPeriod: {
              pre: {
                end: number;
                start: number;
                timezone: string;
                gmtoffset: number;
              };
              post: {
                end: number;
                start: number;
                timezone: string;
                gmtoffset: number;
              };
              regular: {
                end: number;
                start: number;
                timezone: string;
                gmtoffset: number;
              };
            };
            exchangeTimezoneName: string;
            hasPrePostMarketData: boolean;
            regularMarketDayHigh: number;
          };
          events: {
            dividends: {
              [key: string]: {
                date: number;
                amount: number;
              };
            };
          };
          timestamp: number[];
          indicators: {
            quote: [
              {
                low: (number | null)[];
                high: (number | null)[];
                open: (number | null)[];
                close: (number | null)[];
                volume: (number | null)[];
              }
            ];
            adjclose: [
              {
                adjclose: (number | null)[];
              }
            ];
          };
        }
      ];
    };
  };
  title: string;
  type: string;
};

export const generateMockStockEquityData = (): StockEquityData => {
  const now = new Date();
  const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  const timestamps: number[] = [];
  const low: number[] = [];
  const high: number[] = [];
  const open: number[] = [];
  const close: number[] = [];
  const volume: number[] = [];
  const adjclose: number[] = [];

  let previousClose = 65;

  for (let i = 0; i < 30; i++) {
    const currentDate = new Date(thirtyDaysAgo.getTime() + i * 24 * 60 * 60 * 1000);
    timestamps.push(Math.floor(currentDate.getTime() / 1000));

    const closeValue = Math.max(30, Math.min(100, previousClose + (Math.random() - 0.5) * 2));
    close.push(parseFloat(closeValue.toFixed(2)));

    low.push(parseFloat((closeValue - Math.random() * 2).toFixed(2)));
    high.push(parseFloat((closeValue + Math.random() * 2).toFixed(2)));
    open.push(parseFloat((closeValue - 1 + Math.random() * 2).toFixed(2)));
    volume.push(Math.floor(100000 + Math.random() * 90000));
    adjclose.push(parseFloat(closeValue.toFixed(2)));

    previousClose = closeValue;
  }

  return {
    data: {
      chart: {
        result: [
          {
            meta: {
              currentTradingPeriod: {
                pre: {
                  end: 1625050200,
                  start: 1625036400,
                  timezone: "EDT",
                  gmtoffset: -14400,
                },
                post: {
                  end: 1625097600,
                  start: 1625086800,
                  timezone: "EDT",
                  gmtoffset: -14400,
                },
                regular: {
                  end: 1625086800,
                  start: 1625050200,
                  timezone: "EDT",
                  gmtoffset: -14400,
                },
              },
              exchangeTimezoneName: "America/New_York",
              hasPrePostMarketData: true,
              regularMarketDayHigh: Math.max(...high),
            },
            events: {
              dividends: {},
            },
            timestamp: timestamps,
            indicators: {
              quote: [
                {
                  low,
                  high,
                  open,
                  close,
                  volume,
                },
              ],
              adjclose: [
                {
                  adjclose,
                },
              ],
            },
          },
        ],
      },
    },
    title: "Mock Stock Data",
    type: "EQUITY",
  };
};
