import React, { useCallback } from "react";
import { Box, Heading, Stack, Table, Thead, Tr, Th, Tbody, Text, Td } from "@chakra-ui/react";
import { formatDate } from "screens/common/modal/formatters";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useEntityConfiguration } from "hooks/useEntityConfigurations";
import type { EntityConfiguration } from "api/entityConfigurations/models/EntityConfiguration";

interface IProps {
  entityConfiguration: EntityConfiguration;
}

export const EntityConfigurationDetail = (props: IProps) => {
  const { entityConfiguration } = props;

  const renderConfigs = useCallback(() => {
    return (
      <Table size={"sm"} variant="simple">
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Intent</Th>
            <Th>Entities</Th>
          </Tr>
        </Thead>
        <Tbody>
          {entityConfiguration.config.map((config) => (
            <Tr key={`${config.type}-${config.intent}`}>
              <Td>{config.type}</Td>
              <Td>{config.intent}</Td>
              <Td>{config.type === "trigger_child_workflow" ? config.entitiesToInclude.join(", ") : "---"}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }, [entityConfiguration]);

  return (
    <Stack spacing={8} px="1rem">
      <Stack my="0.5rem">
        <Stack direction="row" alignItems="center">
          <Heading fontSize="md">Entity name:</Heading>
          <Text fontSize="md">{entityConfiguration.entityName}</Text>
        </Stack>

        <Text wordBreak="break-word" fontWeight="light" fontSize="xs">{`Created ${formatDate(
          new Date(entityConfiguration.creationDate)
        )} by ${entityConfiguration.creationByUserName}`}</Text>

        <Text wordBreak="break-word" fontWeight="light" fontSize="xs">{`Modified ${formatDate(
          new Date(entityConfiguration.lastUpdatedDate)
        )} by ${entityConfiguration.lastUpdatedByUserName}`}</Text>
      </Stack>

      <Box>
        <Heading marginBottom={4} fontSize="md">
          Config
        </Heading>
        {renderConfigs()}
      </Box>
    </Stack>
  );
};

export const EntityConfigurationDetailModal = ({ id, isOpen, onClose }) => {
  const entityConfiguration = useEntityConfiguration(id);

  if (!entityConfiguration) {
    return <React.Fragment />;
  }

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle="Entity Configuration detail">
      <Wizard>
        <PanelStep>
          <EntityConfigurationDetail entityConfiguration={entityConfiguration} />
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
