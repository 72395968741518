import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";

export const useWorkflowMilestonesIds = (workflowId: string | undefined): string[] => {
  return useSelector(
    (state: RootState) =>
      workflowId && state.milestones.milestonesIdsByWorkflow[workflowId]
        ? state.milestones.milestonesIdsByWorkflow[workflowId].milestonesIds
        : [],
    shallowEqual
  );
};

export const useWorkflowMilestoneById = (milestoneId?: string) => {
  return useSelector(
    (state: RootState) =>
      milestoneId && state.milestones.milestonesById[milestoneId] ? state.milestones.milestonesById[milestoneId] : undefined,
    shallowEqual
  );
};
