import { prependProxyBaseUrl, request } from "api/request";
import type { Member } from "types/organization/member";
import type { MemberOrganization } from "types/organization/MemberOrganization";
import type { Organization } from "types/organization/organization";
import type { Role } from "types/organization/Role";
import type { AddMembersRequest } from "./models/AddOrganizationMembers";
import type { CreateOrganizationRequest } from "./models/CreateOrganizationRequest";
import { GetOrganizationLog } from "./models/GetOrganizationLog";
import type { UpdateOrganizationRequest } from "./models/UpdateOrganizationRequest";
import type { CreateNewOrgUserRequest } from "./models/CreateNewOrgUserRequest";

const ORGANIZATIONS_ADMIN_ENDPOINT = prependProxyBaseUrl("/api/organizations");
const ORGANIZATIONS_ADMIN_CREATE_USER_ENDPOINT = prependProxyBaseUrl("/api/admin/register-organization-user");

export async function listOrganizations(): Promise<Organization[]> {
  return await request().url(`${ORGANIZATIONS_ADMIN_ENDPOINT}`).get().json();
}

export async function getOrganization(organizationId: string): Promise<Organization> {
  return await request().url(`${ORGANIZATIONS_ADMIN_ENDPOINT}/${organizationId}`).get().json();
}

export async function createOrganization(payload: CreateOrganizationRequest): Promise<Organization> {
  return await request().url(`${ORGANIZATIONS_ADMIN_ENDPOINT}`).post(payload).json();
}

export async function updateOrganization(organizationId: string, payload: UpdateOrganizationRequest): Promise<Organization> {
  return await request().url(`${ORGANIZATIONS_ADMIN_ENDPOINT}/${organizationId}`).put(payload).json();
}

export async function deleteOrganization(organizationId: string): Promise<void> {
  return await request().url(`${ORGANIZATIONS_ADMIN_ENDPOINT}/${organizationId}`).delete().res();
}

export async function addOrganizationMembers(organizationId: string, members: AddMembersRequest): Promise<Member[]> {
  return await request().url(`${ORGANIZATIONS_ADMIN_ENDPOINT}/${organizationId}/members`).post(members).json();
}

export async function removeMember(organizationId: string, memberId: string): Promise<void> {
  return await request().url(`${ORGANIZATIONS_ADMIN_ENDPOINT}/${organizationId}/members/${memberId}`).delete().res();
}

export async function updateMemberRole(organizationId: string, memberId: string, role: Role): Promise<Member> {
  return await request().url(`${ORGANIZATIONS_ADMIN_ENDPOINT}/${organizationId}/members/${memberId}/role`).put({ role }).json();
}

export async function getMemberOrganizations(memberId: string): Promise<MemberOrganization[]> {
  return await request().url(`${ORGANIZATIONS_ADMIN_ENDPOINT}/${memberId}/organizations`).get().json();
}

export async function getOrganizationLogs(organizationId: string): Promise<GetOrganizationLog> {
  const response = await request().url(`${ORGANIZATIONS_ADMIN_ENDPOINT}/${organizationId}/logs`).get().json();

  const validateResponse = GetOrganizationLog.validate(response);

  if (validateResponse.success) {
    return validateResponse.value;
  } else {
    throw new Error(validateResponse.message);
  }
}

export async function createAndAddOrgUser(createRequests: CreateNewOrgUserRequest[]): Promise<Member[]> {
  const results = await Promise.allSettled(
    createRequests.map((requests) => request().url(ORGANIZATIONS_ADMIN_CREATE_USER_ENDPOINT).post(requests).json<Member[]>())
  );
  return results.flatMap((result) => {
    if (result.status === "fulfilled") {
      return [...result.value];
    }

    return [];
  });
}
