import { Record, String, Array, Union, Boolean, InstanceOf, Literal, Number } from "runtypes";
import type { Static } from "runtypes";

export const Occurrence = Union(Literal("first"), Literal("last"), Literal("unique"), Literal("most_frequent"), Literal("auto"));

export const TargetEntity = Record({
  id: String,
  resourceUrn: String,
  entityName: String,
  entityType: Array(String),
  keywords: Array(String).nullable(),
  pattern: String.nullable(),
  categories: Record({}).nullable(),
  allowMultipleValues: Boolean,
  occurrence: Occurrence,
  titleKeywords: Array(String).nullable(),
  confidenceThreshold: Number.nullable(),
  negativeKeywords: Array(String).nullable(),
  exactMatching: Boolean.nullable(),
  creationDate: Union(InstanceOf(Date), String),
  lastUpdatedDate: Union(InstanceOf(Date), String),
  creationByUserId: String,
  creationByUserName: String,
  lastUpdatedByUserId: String,
  lastUpdatedByUserName: String,
});

export type TargetEntity = Static<typeof TargetEntity>;
export type Occurrence = Static<typeof Occurrence>;
