import { MessageType } from "../MessageType";

export const WorkflowDestinationMapping = new Map([
  ["workflow/refresh", MessageType.refreshWorkflow],
  ["workflow/progress/task", MessageType.workflowTaskStart],
  ["workflow/progress/full", MessageType.workflowProgress],
  ["workflow/progress/stepper", MessageType.childWorkflowStatusUpdate],
  ["workflow/progress/partial", MessageType.childWorkflowsProgress],
  ["workflow/progress/milestone", MessageType.workflowMilestone],
]);

export const UsageDestinationMapping = new Map([["usage/update", MessageType.usageUpdate]]);

export const ContentDestinationMapping = new Map([
  ["content/refresh", MessageType.refreshContent],
  ["content/delete", MessageType.deleteContent],
]);
