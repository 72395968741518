import type { FunctionComponent } from "react";
import React from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import ReactJson from "react-json-view";

interface Props {
  json: string;
}

export const JsonItemView: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ json }) => {
  const parsedJson = JSON.parse(json);
  const prettyPrintedJson = JSON.stringify(parsedJson, undefined, 2);
  const { colorMode } = useColorMode();
  const getStringMaxLength = useBreakpointValue([50, 70, 70, 100, 180]);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  return (
    <Tabs maxWidth={isMobile ? "100%" : "calc(100vw - 8rem)"} overflow="auto">
      <TabList>
        <Tab>Tree Viewer</Tab>
        <Tab>Raw JSON</Tab>
      </TabList>
      <TabPanels>
        <TabPanel defaultChecked>
          <ReactJson
            collapseStringsAfterLength={getStringMaxLength}
            src={parsedJson}
            theme={colorMode === "dark" ? "ashes" : "rjv-default"}
          />
        </TabPanel>
        <TabPanel>
          <pre>{prettyPrintedJson}</pre>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
