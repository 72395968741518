import { prependProxyBaseUrl, request } from "../request";
import { EntitlementConfig, ListEntitlementConfig } from "./models/Entitlement";
import type { PlanUpsert } from "../../state/entitlements/operations";

export const PROXY_PREFIX = prependProxyBaseUrl("/api/entitlements");

export async function listEntitlementConfigs(): Promise<EntitlementConfig[]> {
  const entitlementCheckResult = await request().url(`${PROXY_PREFIX}/entitlements`).get().json();
  const validationResult = ListEntitlementConfig.validate(entitlementCheckResult);

  if (validationResult.success) {
    return validationResult.value;
  } else {
    console.warn(`Could not fetch entitlements configs <- ${JSON.stringify(validationResult.details)}`);
    return [];
  }
}

export async function postEntitlementConfig(entitlement: EntitlementConfig): Promise<EntitlementConfig | "error"> {
  const entitlementCheckResult = await request().url(`${PROXY_PREFIX}/entitlements`).post(entitlement).json();
  const validationResult = EntitlementConfig.validate(entitlementCheckResult);

  if (validationResult.success) {
    return validationResult.value;
  } else {
    console.warn(`Could not create entitlements configs <- ${JSON.stringify(validationResult.details)}`);
    return "error";
  }
}

export async function putEntitlementConfig(entitlement: Partial<EntitlementConfig>): Promise<EntitlementConfig | "error"> {
  const entitlementCheckResult = await request().url(`${PROXY_PREFIX}/entitlements/${entitlement.code}`).put(entitlement).json();
  const validationResult = EntitlementConfig.validate(entitlementCheckResult);

  if (validationResult.success) {
    return validationResult.value;
  } else {
    console.warn(`Could not update entitlements configs <- ${JSON.stringify(validationResult.details)}`);
    return "error";
  }
}

export async function postPlanEntitlementConfig(entitlementId: string, plan): Promise<void> {
  return await request().url(`${PROXY_PREFIX}/entitlements/${entitlementId}/plans`).post(plan).json();
}

export async function putPlanEntitlementConfig(entitlementId: string, payload: Partial<PlanUpsert>): Promise<void> {
  const { newPlan, ...plan } = payload;
  return await request()
    .url(`${PROXY_PREFIX}/entitlements/${entitlementId}/plans/${plan.plan}`)
    .put({ ...plan, ...(newPlan ? { plan: newPlan } : {}) })
    .json();
}

export async function putUserEntitlementConfig(entitlementId: string, userId: string): Promise<void> {
  return await request().url(`${PROXY_PREFIX}/entitlements/${entitlementId}/users/${userId}`).put().json();
}

export async function deleteUserEntitlementConfig(entitlementId: string, userId: string): Promise<void> {
  return await request().url(`${PROXY_PREFIX}/entitlements/${entitlementId}/users/${userId}`).delete().res();
}

export async function putOrganizationEntitlementConfig(entitlementId: string, organizationId: string): Promise<void> {
  return await request().url(`${PROXY_PREFIX}/entitlements/${entitlementId}/organizations/${organizationId}`).put().json();
}

export async function deleteOrganizationEntitlementConfig(entitlementId: string, organizationId: string): Promise<void> {
  return await request().url(`${PROXY_PREFIX}/entitlements/${entitlementId}/organizations/${organizationId}`).delete().res();
}

export async function deleteEntitlementConfig(entitlementId: string): Promise<void> {
  return await request().url(`${PROXY_PREFIX}/entitlements/${entitlementId}`).delete().res();
}
