import { prependProxyBaseUrl, request } from "api/request";
import {
  CreateMemorizedClarificationConfigResponse,
  UpdateMemorizedClarificationConfigResponse,
  GetMemorizedClarificationConfigResponse,
  GetMemorizedClarificationsConfigsResponse,
} from "./models/MemorizedClarificationConfigApi";
import type {
  CreateMemorizedClarificationConfigRequest,
  UpdateMemorizedClarificationConfigRequest,
} from "./models/MemorizedClarificationConfigApi";

const MEMORIZED_CLARIFICATION_CONFIG_ENDPOINT = prependProxyBaseUrl("/api/memorized-clarification-configuration");

export const createMemorizedClarificationConfig = async (
  payload: CreateMemorizedClarificationConfigRequest
): Promise<CreateMemorizedClarificationConfigResponse> => {
  const response = await request().url(`${MEMORIZED_CLARIFICATION_CONFIG_ENDPOINT}`).post(payload).json();
  const maybeMemorizedClarificationConfig = CreateMemorizedClarificationConfigResponse.validate(response);

  if (maybeMemorizedClarificationConfig.success) {
    return maybeMemorizedClarificationConfig.value;
  } else {
    console.error(maybeMemorizedClarificationConfig);
    throw new Error(maybeMemorizedClarificationConfig.message);
  }
};

export const updateMemorizedClarificationConfig = async (payload: UpdateMemorizedClarificationConfigRequest) => {
  const response = await request().url(`${MEMORIZED_CLARIFICATION_CONFIG_ENDPOINT}`).put(payload).json();
  const maybeMemorizedClarificationConfig = UpdateMemorizedClarificationConfigResponse.validate(response);

  if (maybeMemorizedClarificationConfig.success) {
    return maybeMemorizedClarificationConfig.value;
  } else {
    console.error(maybeMemorizedClarificationConfig);
    throw new Error(maybeMemorizedClarificationConfig.message);
  }
};

export const getMemorizedClarificationConfig = async (id: string): Promise<GetMemorizedClarificationConfigResponse> => {
  const response = await request().url(`${MEMORIZED_CLARIFICATION_CONFIG_ENDPOINT}/${id}`).get().json();
  const maybeMemorizedClarificationConfig = GetMemorizedClarificationConfigResponse.validate(response);

  if (maybeMemorizedClarificationConfig.success) {
    return maybeMemorizedClarificationConfig.value;
  } else {
    console.error(maybeMemorizedClarificationConfig);
    throw new Error(maybeMemorizedClarificationConfig.message);
  }
};

export const getMemorizedClarificationsConfigs = async (): Promise<GetMemorizedClarificationsConfigsResponse> => {
  const response = await request().url(`${MEMORIZED_CLARIFICATION_CONFIG_ENDPOINT}`).get().json();
  const maybeMemorizedClarificationConfigs = GetMemorizedClarificationsConfigsResponse.validate(response);

  if (maybeMemorizedClarificationConfigs.success) {
    return maybeMemorizedClarificationConfigs.value;
  } else {
    console.error(maybeMemorizedClarificationConfigs);
    throw new Error(maybeMemorizedClarificationConfigs.message);
  }
};

export const deleteMemorizedClarificationConfig = async (id: string): Promise<void> => {
  await request().url(`${MEMORIZED_CLARIFICATION_CONFIG_ENDPOINT}/${id}`).delete();
};
