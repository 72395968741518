import React from "react";
import { Box, Table, Tbody, Thead, Tr, Th, useBreakpointValue } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

export const ContentCellTable = ({ children }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { pathname } = useLocation();
  const isSearchRoute = pathname.includes("/search/") || pathname.includes("/research/");

  return (
    <Box width={"100%"} overflow="auto" className="table_layout">
      <Table variant="simple" fontSize={isMobile ? "xs" : "sm"}>
        <Thead>
          <Tr>
            {!isMobile && <Th p=".5rem"></Th>}
            <Th p=".5rem" width="15rem">
              Name
            </Th>
            {!isMobile && !isSearchRoute && <Th p=".5rem">Source</Th>}
            {!isMobile && (
              <Th p=".5rem" textAlign={"center"}>
                Type
              </Th>
            )}
            {!isMobile && <Th p=".5rem">Category</Th>}
            {!isMobile && isSearchRoute && <Th p=".5rem">Tags</Th>}
            {!isMobile && <Th p=".5rem">Date Added</Th>}
            <Th p=".5rem" textAlign={"right"}>
              Options
            </Th>
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </Box>
  );
};
