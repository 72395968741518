import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWorkflowsProgressSteps } from "api/workflows";
import { WorkflowFetchError } from "api/workflows/models/WorkflowFetchError";
import type { RootState } from "state/rootReducer";

// TODO: cleanup this after migration to new stepper
export const downloadProgressSteps = createAsyncThunk(
  "progressSteps/download-progress-steps",
  async ({ workflowIds, intentFilters }: { workflowIds: string[]; intentFilters?: string[] }, thunkAPI) => {
    const { configMap } = thunkAPI.getState() as RootState;

    const intentsToFilter = configMap.order.flatMap((id) => {
      const config = configMap.defaultConfigById[id].config;

      if (config.workflowIntentFilters && config.workflowIntentFilters.length > 0) {
        return config.workflowIntentFilters;
      } else {
        return [];
      }
    });

    const response = await getWorkflowsProgressSteps(workflowIds, intentFilters ?? Array.from(new Set(intentsToFilter)));

    const filteredProgressSteps = response.flatMap((newWorkflow) => {
      return WorkflowFetchError.guard(newWorkflow) ? [] : [newWorkflow];
    });

    return filteredProgressSteps;
  }
);
