import type { Static } from "runtypes";
import { Array, Record, String, Dictionary } from "runtypes";
import { EntitlementPlanConfig } from "./EntitlementPlanConfig";
import { User } from "@charliai/node-core-lib/lib/src/userService/models/User";
import { Organization } from "types/billingPlans/Organization";

export const EntitlementConfig = Record({
  code: String,
  name: String,
  description: String.nullable(),
  plans: Dictionary(EntitlementPlanConfig, String),
  users: Array(User).nullable(),
  organizations: Array(Organization).optional(),
});

export const ListEntitlementConfig = Array(EntitlementConfig);

export type EntitlementConfig = Static<typeof EntitlementConfig>;
export type ListEntitlementConfig = Static<typeof ListEntitlementConfig>;
