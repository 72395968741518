import { Box, Stack } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import React, { useCallback } from "react";
import type { TableRowValue } from "screens/content/common/TwoColumnTable";
import { TwoColumnTable } from "screens/content/common/TwoColumnTable";
import type { ContentDetails } from "types/content/ContentDetails";
import type { DetailSection } from "types/content/DetailSection";
import { BlockSectionHeader } from "../previewSection/BlockSectionHeader";
import type { DetailSectionData } from "types/content/DetailSectionData";

interface Props {
  contentDetails: ContentDetails;
  includedSections: string[];
  includedEntities?: string[];
  hideSectionTitles?: boolean;
}

export const ContentViewExtractedAISectionEntities: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  contentDetails,
  includedSections,
  includedEntities,
  hideSectionTitles = true,
}) => {
  const detailsSections = useMemo(() => {
    let filteredSections = contentDetails?.detailSections ?? [];
    if (includedSections.length > 0) {
      filteredSections = filteredSections.filter((section) => includedSections.includes(section.sectionName));
    }
    return filteredSections;
  }, [contentDetails, includedSections]);

  const getEntities = useCallback((detailSection: DetailSection, includedEntities: string[] | undefined): DetailSectionData[] => {
    if (!includedEntities) {
      return detailSection.data;
    }
    return detailSection.data.filter((entity) => includedEntities.includes(entity.label as string));
  }, []);

  const renderGroupedSections = useCallback(() => {
    return (
      <Stack spacing="1rem">
        {detailsSections.map((section, index) => {
          const sectionData = getEntities(section, includedEntities);

          return (
            sectionData.length > 0 && (
              <Stack key={index} spacing="1rem">
                {!hideSectionTitles && <BlockSectionHeader title={section.sectionTitle} />}
                <TwoColumnTable
                  sectionHeader={section.sectionTitle}
                  rows={sectionData.map((record: DetailSectionData) => {
                    return {
                      className: record.label,
                      title: record.label,
                      value: record.value,
                      type: "url",
                      entityName: record.entityName,
                      url: record.value,
                      rowMaxHeight: "20rem",
                    } as TableRowValue;
                  })}
                />
              </Stack>
            )
          );
        })}
      </Stack>
    );
  }, [detailsSections, getEntities, hideSectionTitles, includedEntities]);

  const renderDetailsSections = useCallback(() => {
    return <Stack spacing="1rem">{renderGroupedSections()}</Stack>;
  }, [renderGroupedSections]);

  return <Box>{renderDetailsSections()}</Box>;
};
