import React from "react";
import { Box, Button, Select, FormControl, FormLabel, Icon, IconButton, Input, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { AiOutlineDelete } from "react-icons/ai";
import { EntityConfigTypes } from "api/entityConfigurations/models/EntityConfiguration";
import type { FormValues } from "./UpsertEntityConfiguration";
import { EntityFieldArray } from "./EntityFieldArray";

const ENTITY_TYPE_NAME_MAP = {
  trigger_child_workflow: "Trigger Child Workflow",
  run_task: "Run Task",
};

export const EntityConfigFieldArray = () => {
  const { control, watch } = useFormContext<FormValues>();
  const commonButtonProps = useButtonProps("sm", "primary");
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const { fields, append, remove } = useFieldArray({
    name: "config",
    control,
  });

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" mb="1rem">
        <Text fontSize="sm" mr="1rem">
          Config
        </Text>
        <Button
          {...commonButtonProps}
          onClick={() =>
            append({
              type: "run_task",
              intent: "",
            })
          }>
          Add Config
        </Button>
      </Stack>
      <Stack overflow="auto" spacing={4} marginBottom="6">
        {fields.map((field, index) => (
          <Box borderWidth="2px" borderRadius="lg" padding="1rem" key={field.id}>
            <Stack direction="row" justifyContent="space-between" spacing={1} width="100%">
              <FormControl>
                <FormLabel fontSize="sm">Type</FormLabel>
                <Controller
                  render={({ field }) => (
                    <Select {...field} size="sm" mr="0.5rem" fontSize="sm" boxShadow="none" required>
                      {EntityConfigTypes.map((type) => (
                        <option key={type} value={type}>
                          {ENTITY_TYPE_NAME_MAP[type] || type}
                        </option>
                      ))}
                    </Select>
                  )}
                  name={`config.${index}.type`}
                  control={control}
                />
              </FormControl>

              <FormControl mr="0.5rem" width="100%">
                <FormLabel fontSize="sm">Intent</FormLabel>
                <Controller
                  render={({ field }) => <Input {...field} size="xs" type="text" mr="0.5rem" fontSize="sm" boxShadow="none" required />}
                  name={`config.${index}.intent`}
                  control={control}
                />
              </FormControl>
              <Box pt="1.5rem">
                <IconButton
                  onClick={() => remove(index)}
                  aria-label="Delete"
                  backgroundColor="unset"
                  icon={<Icon as={AiOutlineDelete} color={buttonColor} boxSize="1rem" _hover={{ color: buttonHoverColor }} />}
                  size="sm"
                  _hover={{ backgroundColor: "unset" }}
                />
              </Box>
            </Stack>

            {watch(`config.${index}.type`) === "trigger_child_workflow" && <EntityFieldArray configIndex={index} />}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};
