import { Alert, AlertDescription, Button, Flex, Stack, Text, HStack, useColorModeValue } from "@chakra-ui/react";
import { useCommandSuggestions, useButtonProps } from "hooks";
import type { FunctionComponent } from "react";
import React, { useMemo, useState, useEffect } from "react";
import type { MessageIntent } from "types/conversation";
import { track } from "api/analytics";
import { SUGGESTED_COMMAND_CLICKED, SUGGESTED_COMMAND_ENTITY_CLICKED } from "api/analytics/events";
import { InputBarTagsInput } from "../InputBarTagsInput";

export type FollowUpIntentSource = { type: "last_intent"; intent: string } | { type: "list"; intents: string[] };

interface Props {
  onSuggestionClick: (text: string) => void;
  followUpIntentSource: FollowUpIntentSource;
  messageText: string;
}

const ENTITIES_THAT_CAN_ACCEPT_MULTIPLE_INPUTS = [">email_address", ">tag", ">note"];
type CommandCategory = "suggested actions" | "required" | "recommended" | "optional";
type Suggestion = { type: CommandCategory; value: string };

const getAnalyticsTypeForCategory = (
  type: CommandCategory
): "suggested_actions" | "required_property" | "recommended_property" | "optional_property" => {
  switch (type) {
    case "suggested actions":
      return "suggested_actions";
    case "required":
      return "required_property";
    case "recommended":
      return "recommended_property";
    case "optional":
      return "optional_property";
  }
};

const getHintForEntity = (entity: string) => {
  switch (entity.replace(">", "").trim()) {
    case "email_address":
      return "Enter one or more e-mail addresses, separated by spaces";
    case "month":
      return "Enter the name of a month";
    case "year":
      return "Enter the 4-digit year";
    case "tag":
      return "Enter one or more tags, eg. #personal #business";
    case "when":
      return "Enter the date and time, eg. 12/25/2021 at 3pm, Tomorrow at 10am";
    case "start_date":
      return "The start date, in either YYYY-MM-DD format or using natural language (eg. today, yesterday)";
    case "end_date":
      return "The end date, in either YYYY-MM-DD format or using natural language (eg. today, yesterday)";
    case "link_url":
      return "Enter the full URL, eg. https://charli.ai";
    case "total_price":
      return "Enter the total price of the expense, eg. $1.23";
    case "merchant_name":
      return "Enter the merchant name where the expense was incurred";
    case "tax_amount":
      return "Enter the total tax charged, eg. $1.23";
    case "location":
      return "Enter the location the expense was incurred, eg. Vancouver, BC";
    case "message":
      return "Enter the message to be included with your sent items";
    case "custom_subdir":
      return "Enter the name of the custom subdirectory where your content will be stored";
    case "currency":
      return "Enter the currency code for your expense, eg. USD, CAD";
    case "date":
      return "Enter the date, in either YYYY-MM-DD format or using natural language (eg. today, yesterday)";
    default:
      return `Enter value for ${entity.replace(/_/g, " ")}`;
  }
};

export const InputBarCommandLineSuggestions: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  onSuggestionClick,
  followUpIntentSource,
  messageText,
}) => {
  const { commandsByIntent: commands, commandsByLabel } = useCommandSuggestions();
  const [showComponent, setShowComponent] = useState(false);
  const hintTextColor = useColorModeValue("gray.600", "gray.100");
  const categoryTextColor = useColorModeValue("gray.500", "gray.200");
  const commonButtonProps = useButtonProps("sm", "primary");
  const hoverColor = useColorModeValue("primary.default", "gray.500");

  useEffect(() => {
    // Hide suggestions briefly as a hacky workaround for suggestions quickly changing when the initial state of the input bar is changed
    const timeout = setTimeout(() => {
      setShowComponent(true);
    }, 50);
    return () => {
      clearTimeout(timeout);
    };
  }, [setShowComponent]);

  const followUpIntents = useMemo(() => {
    if (followUpIntentSource.type === "list") {
      return followUpIntentSource.intents;
    } else if (followUpIntentSource.type === "last_intent") {
      const command = commands[followUpIntentSource.intent];
      if (command && command.follow_up_intents) {
        return command.follow_up_intents.reduce((accum, intent) => {
          if (commands[intent]?.command_label) {
            accum.push(intent);
          }
          return accum;
        }, [] as MessageIntent[]);
      }
    }

    return [];
  }, [followUpIntentSource, commands]);

  const getSuggestionsForText = (): Suggestion[] => {
    if (messageText.trim() === "")
      // Find commands
      return followUpIntents.reduce((accum, intent) => {
        // If command exists
        if (commands[intent] && commands[intent]?.command_label) {
          accum.push({ type: "suggested actions", value: `/${commands[intent]!.command_label}` });
        }
        return accum;
      }, [] as Suggestion[]);

    if (messageText.startsWith("/")) {
      const tokens = messageText.split(" ");

      const firstToken = tokens[0];
      const lastToken = tokens[tokens.length - 1];

      // If there's only one token, show command suggestions filtered by the user's input
      if (tokens.length === 1) {
        return followUpIntents.reduce((accum, intent) => {
          // If command exists
          const cleanedToken = firstToken.replace("/", "");

          if (
            commands[intent] &&
            commands[intent]?.command_label &&
            commands[intent]?.command_label!.startsWith(cleanedToken) &&
            commands[intent]?.command_label !== cleanedToken
          ) {
            accum.push({ type: "suggested actions", value: `/${commands[intent]!.command_label}` });
          }
          return accum;
        }, [] as Suggestion[]);
      }

      // Check that user isn't entering an entity value, and bail if they are
      if (tokens[tokens.length - 2].startsWith(">")) return [];

      // Assume first token is a command
      const command = commandsByLabel[firstToken.replace("/", "")];

      // Bail if command doesn't exist
      if (!command) return [];

      const entitySuggestions: Suggestion[] = command.mandatory_entities
        .map((entity) => {
          return { type: "required" as CommandCategory, value: `>${entity}` };
        })
        .concat(
          command.recommended_entities.map((entity) => {
            return { type: "recommended" as CommandCategory, value: `>${entity}` };
          })
        )
        .concat(
          command.optional_entities.map((entity) => {
            return { type: "optional" as CommandCategory, value: `>${entity}` };
          })
        )
        .reduce((accum, suggestion) => {
          // Has the user already typed this suggestion?
          if (messageText.includes(suggestion.value)) {
            // Is the suggestion whitelisted to appear multiple times?
            if (ENTITIES_THAT_CAN_ACCEPT_MULTIPLE_INPUTS.includes(suggestion.value)) {
              // Include the suggestion, but label it optional
              accum.push({ type: "optional", value: suggestion.value });
            }
          } else {
            accum.push(suggestion);
          }
          return accum;
        }, [] as Suggestion[])
        .sort((a, b) => {
          // When suggestion type is the same (like two "optional" suggestions) sort by asc alphabetical order - eg message, note, tag
          if (a.type === b.type) {
            if (a.value < b.value) {
              return -1;
            } else {
              return 0;
            }
          } else {
            // When suggestion type is not the same (like "optional" and "required") sort by desc alphabetical order - required, then recommended, then optional
            if (a.type > b.type) {
              return -1;
            } else {
              return 0;
            }
          }
        });

      if (lastToken.trim() === "") return entitySuggestions;
      else return entitySuggestions.filter((suggestion) => suggestion.value.startsWith(lastToken));
    }

    return [];
  };

  const getCurrentEntity = () => {
    // Get the last token in the message's text that starts with >, ignoring the last token (so as not to return a > command that's currently being typed)
    const entity = messageText.match(/>[a-zA-Z0-9_]+\s/gi)?.reverse()[0];
    const lastToken = messageText.match(/\s[a-zA-Z0-9_>]+\s?$/gi);

    if (!entity || entity.trim() === "") return;

    // Never offer entity description if the last token is an entity label (because that entity label is unlikely to be complete - it's still being typed)
    if (lastToken && lastToken[0] && lastToken[0].trim().startsWith(">") && !lastToken[0].endsWith(" ")) return;

    return entity.replace(">", "");
  };

  const suggestions = getSuggestionsForText().reduce((accum, suggestion) => {
    const addSuggestion = (category: CommandCategory, value: string) => {
      if (!accum[category]) accum[category] = [];
      accum[category].push(value);
    };

    addSuggestion(suggestion.type, suggestion.value);

    return accum;
  }, {} as { [key in CommandCategory]: string[] });

  const currentEntity = getCurrentEntity();

  if (followUpIntents.length === 0) {
    return <React.Fragment />;
  } else {
    return (
      <Stack visibility={showComponent ? "visible" : "hidden"} pb="5px">
        {Object.keys(suggestions).length > 0
          ? Object.entries(suggestions).map(([category, labels]) => {
              const type = getAnalyticsTypeForCategory(category as CommandCategory);
              return (
                <Stack key={category} spacing="0.2rem">
                  <HStack align="top">
                    <Text casing="uppercase" fontWeight="500" fontSize="xs" color={categoryTextColor} fontFamily="heading">
                      {category}
                    </Text>
                  </HStack>
                  <Stack direction="row" overflow="auto" id="chat-suggestions">
                    {labels.map((label) => {
                      return (
                        <Button
                          key={label}
                          {...commonButtonProps}
                          fontWeight="normal"
                          minWidth="unset"
                          color={categoryTextColor}
                          py="unset"
                          pl="unset"
                          backgroundColor="transparent"
                          borderWidth="0"
                          _hover={{ backgroundColor: "transparent", color: hoverColor }}
                          onClick={(event) => {
                            if (type === "suggested_actions") {
                              track(SUGGESTED_COMMAND_CLICKED, { command: label.substr(1) });
                            } else {
                              track(SUGGESTED_COMMAND_ENTITY_CLICKED, {
                                entity: label.substr(1),
                                entity_type: category,
                              });
                            }
                            onSuggestionClick(label);
                            event.stopPropagation();
                          }}>
                          {label}
                        </Button>
                      );
                    })}
                  </Stack>
                </Stack>
              );
            })
          : currentEntity &&
            (currentEntity.replace(">", "").trim() === "tag" ? (
              <InputBarTagsInput hideDefaultTags onSuggestionClick={onSuggestionClick} />
            ) : (
              <Alert status="info" p="0" background="clear">
                <Flex flexDirection="column">
                  <AlertDescription fontSize="sm" color={hintTextColor}>
                    {getHintForEntity(currentEntity)}
                  </AlertDescription>
                </Flex>
              </Alert>
            ))}
      </Stack>
    );
  }
};
