import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import { AreaChart, Area, Tooltip, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { chartItemColor, CustomTooltipAreaChart } from "./CustomChartUtils";
import type { DataType } from "hooks/useStats";
import { defaultProps } from "react-select/creatable";
import uniq from "lodash/uniq";
import { useSumAllValuesByDate } from "../../operations/tabs/useWorkflowStats";

interface Props {
  data: DataType[];
  width?: number | string;
  height?: string;
  chartHeight?: number;
  title?: string;
  chartId?: string;
}

export const CustomAreaChart: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  data,
  width,
  height = "13rem",
  chartHeight = 100,
  title,
  chartId,
}) => {
  const uniqueStates = uniq(data.map((item) => item.label));
  const dataByDate = useSumAllValuesByDate(data);

  return (
    <Stack height={height} width={width}>
      <ResponsiveContainer width={"100%"} height={chartHeight}>
        <AreaChart id={chartId} data={dataByDate} margin={{ top: 0, right: 0, left: 0, bottom: 12 }}>
          <XAxis dataKey="name" fontSize={"10px"} interval={2} />
          <YAxis fontSize={"10px"} />
          {uniqueStates.map((state) => (
            <Area
              key={state}
              stackId="1"
              isAnimationActive={false}
              type="monotone"
              dataKey={state || ""}
              stroke={chartItemColor(state.toLowerCase() || "default")}
              fill={chartItemColor(state.toLowerCase() || "default")}
            />
          ))}
          <Tooltip
            isAnimationActive={false}
            content={<CustomTooltipAreaChart active={defaultProps.active} payload={defaultProps.payload} prefix="" />}
          />
        </AreaChart>
      </ResponsiveContainer>
      <Text mt="0!important" fontSize={"xs"} width={"100%"} align={"center"}>
        {title}
      </Text>
    </Stack>
  );
};
