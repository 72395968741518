import { Record, String, Unknown } from "runtypes";
import type { Static } from "runtypes";

export const ProjectConfig = Record({
  id: String,
  config: Unknown,
  creationDate: String,
  lastModifiedDate: String,
  creationByUserId: String,
  lastModifiedByUserId: String,
  lastImportedDate: String.nullable(),
  lastImportedByUserId: String.nullable(),
});

export type ProjectConfig = Static<typeof ProjectConfig>;
