import { useEffect, useMemo } from "react";
import { shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { requestToDownloadCollectionMetadata } from "state/collectionMetadata/utils";
import type { RootState } from "state/rootReducer";
import type { ContentDetails } from "types/content/ContentDetails";
import type { StockEquityData } from "types/stock";

export function useCollectionMetadataWithTagId(metadataIds: string[], tag: string): string | undefined {
  return useSelector((state: RootState) => {
    return metadataIds.find((metadataId) => state.collectionMetadata.contentsByIds[metadataId]?.manualTags?.includes(tag));
  }, shallowEqual);
}

export function useProjectStockEquityDataFromCollectionMetadata(metadataIds: string[]): StockEquityData | null {
  return useSelector((state: RootState) => {
    for (const metadataId of metadataIds) {
      const content = state.collectionMetadata.contentsByIds[metadataId];
      if (content && content.name?.toLowerCase() === "stock equity data") {
        return (content.dynamicData?.dynamic_data as StockEquityData) || null;
      }
    }
    return null;
  }, shallowEqual);
}

export function useCollectionMetadata(metadataId: string): ContentDetails | undefined {
  return useSelector((state: RootState) => {
    return state.collectionMetadata.contentsByIds[metadataId];
  }, shallowEqual);
}

export function useCollectionMetadataIds(collectionId?: string): string[] {
  return useSelector((state: RootState) => {
    return collectionId ? state.collectionMetadata.contentsIdsByCollection[collectionId] ?? [] : [];
  }, shallowEqual);
}

export function useDownloadCollectionContents(metadataIds: string[] | undefined, collectionId?: string) {
  const deDuplicatedMetadataIds = useMemo(() => [...new Set(metadataIds)], [metadataIds]);
  const nonExistingMetadataIds = useSelector((state: RootState) => {
    return deDuplicatedMetadataIds.filter((metadataId) => !state.collectionMetadata.contentsByIds[metadataId]);
  }, shallowEqual);
  const collectionChecks = useSelector((state: RootState) => {
    return collectionId ? state.collectionMetadata.contentsChecksByCollection[collectionId] : undefined;
  }, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((collectionChecks && Object.values(collectionChecks).every((id) => id)) || !collectionId) {
      return;
    }

    requestToDownloadCollectionMetadata({ metadataIds: nonExistingMetadataIds, collectionId }, dispatch);
  }, [dispatch, collectionId, collectionChecks, nonExistingMetadataIds]);
}
