import { prependProxyBaseUrl, request } from "./request";

const USER_PREFERENCES_ENDPOINT = (userId: string, key: string) =>
  prependProxyBaseUrl(`/api/user-preferences/user-preferences/${userId}/${key}`);
const USER_PREFERENCES_BY_KEYS_ENDPOINT = (userId: string) => prependProxyBaseUrl(`/api/user-preferences/user-preferences/${userId}`);

export function updateUserPreference(userId: string, key: string, value: boolean | string | number | string[]) {
  return request().url(USER_PREFERENCES_ENDPOINT(userId, key)).json({ value }).put().res();
}

export function deleteUserPreference(userId: string, key: string) {
  return request().url(USER_PREFERENCES_ENDPOINT(userId, key)).delete().res();
}

export function fetchUserPreferencesByKeys(userId: string, keys?: string[]): Promise<{ key: string; value: string | boolean | number }[]> {
  return request()
    .url(USER_PREFERENCES_BY_KEYS_ENDPOINT(userId))
    .query({ keys: keys ?? [] })
    .get()
    .json();
}

export function fetchUserPreference(userId: string, key: string): Promise<boolean | string> {
  return request()
    .url(USER_PREFERENCES_ENDPOINT(userId, key))
    .get()
    .notFound(() => {
      return false;
    })
    .json((json) => {
      if (json.value) {
        return json.value;
      } else {
        return false;
      }
    });
}

export function fetchUserMarketingPreference() {
  return request()
    .url(prependProxyBaseUrl("/api/analytics/attributes"))
    .get()
    .notFound(() => {
      return false;
    })
    .json((json) => {
      return json.marketingOptIn ?? false;
    });
}
