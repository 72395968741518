import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import type { RootState } from "../state/rootReducer";
import type { ResourceWithId } from "state/informationExtractionConfig/reducer";
import { downloadResources } from "state/informationExtractionConfig/operations";
import type { TargetEntity } from "api/informationExtractionConfig/models/TargetEntity";

export const useResources = (options: { refreshFromNetwork: boolean } = { refreshFromNetwork: false }): ResourceWithId[] => {
  const { resources, targetEntities } = useSelector((state: RootState) => state.informationExtractionConfig);

  const dispatch = useDispatch();

  useEffect(() => {
    if (options.refreshFromNetwork) dispatch(downloadResources());
  }, [dispatch, options.refreshFromNetwork]);

  return useMemo(() => {
    return resources.allIds.map((resourceId) => {
      const resource = resources.byId[resourceId];

      return { ...resource, entities: resource.targetEntitiesIds.map((entityId) => targetEntities.byId[entityId]) };
    });
  }, [resources, targetEntities]);
};

export const useResourcesMap = (): Record<string, ResourceWithId> => {
  const { resources, targetEntities } = useSelector((state: RootState) => state.informationExtractionConfig);

  return useMemo(() => {
    return Object.fromEntries(
      resources.allIds.map((resourceId) => {
        const { targetEntitiesIds, ...resource } = resources.byId[resourceId];

        return [resourceId, { ...resource, entities: targetEntitiesIds.map((entityId) => targetEntities.byId[entityId]) }];
      })
    );
  }, [resources, targetEntities]);
};

export const useResource = (resourceId: string | null): ResourceWithId | undefined => {
  const { resources, targetEntities } = useSelector((state: RootState) => state.informationExtractionConfig);

  return useMemo(() => {
    if (!resourceId) return undefined;

    const resource = resources.byId[resourceId];

    return { ...resource, entities: resource.targetEntitiesIds.map((entityId) => targetEntities.byId[entityId]) };
  }, [resources, targetEntities, resourceId]);
};

export const useTargetEntities = (): TargetEntity[] => {
  const { targetEntities } = useSelector((state: RootState) => state.informationExtractionConfig);

  return useMemo(() => {
    return targetEntities.allIds.map((entityId) => targetEntities.byId[entityId]);
  }, [targetEntities]);
};

export const useIsLoadingInformationExtractionConfig = (): boolean => {
  return useSelector((state: RootState) => state.informationExtractionConfig.isLoading);
};
