import { SourceWeights } from "./SourceWeights";
import { Record, String, Literal, Union, Boolean } from "runtypes";
import type { Static } from "runtypes";

export const SourceWeightsArrayItem = Record({
  label: String,
  key: Union(Literal("analytical"), Literal("sentiment")),
  sourceContentWeights: SourceWeights,
  default: Boolean.optional(),
});

export type SourceWeightsArrayItem = Static<typeof SourceWeightsArrayItem>;
