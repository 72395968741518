import React from "react";
import { Text } from "@visx/text";
import { scaleLog } from "@visx/scale";
import Wordcloud from "@visx/wordcloud/lib/Wordcloud";
import { Box } from "@chakra-ui/react";

interface Props {
  width: number;
  height: number;
  tags: string[];
}

export interface WordData {
  text: string;
  value: number;
}

const colors = ["#143059", "#2F6B9A", "#82a6c2"];

function wordFreq(text: string): WordData[] {
  const words: string[] = text.replace(/\./g, "").split(/\s/);
  const freqMap: Record<string, number> = {};

  for (const w of words) {
    if (!freqMap[w]) freqMap[w] = 0;
    freqMap[w] += 1;
  }
  return Object.keys(freqMap).map((word) => ({ text: word, value: freqMap[word] }));
}

const fixedValueGenerator = () => 0.5;

export default function WordCloud({ width, height, tags }: Props) {
  const words = wordFreq(tags.join(" "));

  const fontScale = scaleLog({
    domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
    range: [10, 80],
  });
  const fontSizeSetter = (datum: WordData) => fontScale(datum.value);

  const handleOnClick = (tag?: string) => {
    console.log(tag);
  };

  return (
    <Box className="wordcloud">
      <Wordcloud
        words={words}
        width={width}
        height={height}
        fontSize={fontSizeSetter}
        font={"Impact"}
        padding={2}
        rotate={0}
        spiral={"rectangular"}
        random={fixedValueGenerator}>
        {(cloudWords) =>
          cloudWords.map((w, i) => (
            <Text
              onClick={() => handleOnClick(w.text)}
              cursor="pointer"
              key={w.text}
              fill={colors[i % colors.length]}
              textAnchor={"middle"}
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}>
              {w.text}
            </Text>
          ))
        }
      </Wordcloud>
    </Box>
  );
}
