import { Tag, Button, Icon, Stack, Tooltip, useToast, Table, Thead, Tbody, Tr, Th, Td, useColorModeValue, Box } from "@chakra-ui/react";
import React, { useCallback, useState, useMemo } from "react";
import { useAppDispatch, useButtonProps, useEntitlements, useOrganizationIsLoading, useOrganizations } from "hooks";
import { AiOutlineDelete } from "react-icons/ai";
import { UpsertOrganizationModal } from "./modals/UpsertOrganizationModal";
import { ConfirmDeleteModal } from "screens/common/components/ConfirmDeleteModal";
import { deleteOrganization } from "state/organization/operations";
import { formatDistanceToNow } from "date-fns";
import sortBy from "lodash/sortBy";
import { OrganizationModal } from "./modals/OrganizationModal";
import type { ModalKey } from "types/modal";
import { InviteUsersPanel } from "./modals/InviteUsersPanel";
import { useFilterInput } from "hooks/useFilterInput";
import type { Organization } from "types/organization/organization";
import { HiOutlineMail } from "react-icons/hi";
import { FaList } from "react-icons/fa";
import { OrganizationLogsPanel } from "./modals/OrganizationLogsPanel";

export const Organizations = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<ModalKey | undefined>();
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const {
    manage_organizations_write: hasOrganizationsWrite,
    manage_organizations_delete: hasOrganizationsDelete,
    manage_organizations_read: hasOrganizationsRead,
    manage_users_write: hasUsersWrite,
  } = useEntitlements();
  const unorderedOrganizations = useOrganizations();
  const organizations = useMemo(() => sortBy(unorderedOrganizations, "name"), [unorderedOrganizations]);
  const isLoading = useOrganizationIsLoading();
  const { filteredList, renderFilterInputComponent } = useFilterInput<Organization>(["name"], organizations);
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");

  const onClose = useCallback(() => {
    setIsOpen(undefined);
  }, []);

  const onOpenUpsert = useCallback((openId?: string) => {
    if (openId) {
      setSelectedId(openId);
    } else {
      setSelectedId(null);
    }

    setIsOpen("upsert");
  }, []);

  const onOpenModal = useCallback((modalKey: ModalKey, openId?: string) => {
    if (openId) setSelectedId(openId);

    setIsOpen(modalKey);
  }, []);

  const onDelete = useCallback(async () => {
    try {
      if (!selectedId) {
        return;
      }

      const response = await dispatch(deleteOrganization({ organizationId: selectedId }));

      if (response.type.includes("rejected")) {
        const {
          error: { message },
        } = response as { error: { message: string } };

        toast({
          title: "Organization",
          description: message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });

        return;
      }

      toast({
        title: "Organization",
        description: `Organization deleted`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  }, [dispatch, onClose, selectedId, toast]);

  const commonButtonProps = useButtonProps("sm", "primary");

  const renderOrganizations = useCallback(() => {
    return (filteredList || []).map((organization) => {
      return (
        <Tr
          key={organization.id}
          {...(hasOrganizationsWrite && { onClick: () => onOpenModal("upsert", organization.id), cursor: "pointer" })}>
          <Td px="0" py=".5rem">
            {organization.name}
          </Td>
          <Td px="0" py=".5rem">
            <Tag size="sm">{organization.planCode.toUpperCase()}</Tag>
          </Td>
          <Td px="0" py=".5rem">
            {formatDistanceToNow(new Date(organization.lastUpdatedDate), { addSuffix: true })}
          </Td>
          <Td px="0" py=".5rem">
            {organization.lastUpdatedByUserName}
          </Td>
          <Td px="0" py=".5rem">
            <Tag size="sm">{organization.members?.length ?? 0}</Tag>
          </Td>
          <Td px="0" py=".5rem">
            <Stack direction="row" gridGap={2}>
              {hasOrganizationsRead && (
                <Tooltip aria-label="Org Logs" label="Logs" placement="top" hasArrow>
                  <Box>
                    <Icon
                      pt="3px"
                      cursor="pointer"
                      as={FaList}
                      color={buttonColor}
                      boxSize="1rem"
                      _hover={{ color: buttonHoverColor }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenModal("logs", organization.id);
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
              {hasUsersWrite && (
                <Tooltip aria-label="Email users" label="Email users" placement="top" hasArrow>
                  <Box>
                    <Icon
                      cursor="pointer"
                      as={HiOutlineMail}
                      color={buttonColor}
                      boxSize="1rem"
                      _hover={{ color: buttonHoverColor }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenModal("invite-users", organization.id);
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
              {hasOrganizationsDelete && (
                <Tooltip aria-label="Delete Org" label="Delete organization" placement="top" hasArrow>
                  <Box>
                    <Icon
                      cursor="pointer"
                      as={AiOutlineDelete}
                      color={buttonColor}
                      boxSize="1rem"
                      _hover={{ color: buttonHoverColor }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenModal("delete", organization.id);
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
            </Stack>
          </Td>
        </Tr>
      );
    });
  }, [
    filteredList,
    hasOrganizationsWrite,
    hasOrganizationsRead,
    buttonColor,
    buttonHoverColor,
    hasUsersWrite,
    hasOrganizationsDelete,
    onOpenModal,
  ]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" width="100%" pb="1rem" mt="0!important" spacing="1rem">
          {renderFilterInputComponent("Filter by organization name")}
        </Stack>
        {hasOrganizationsWrite && (
          <Button width="11rem" {...commonButtonProps} onClick={() => onOpenUpsert()}>
            Add Organization
          </Button>
        )}
      </Stack>
      {organizations.length > 0 && (
        <Table variant="simple" fontSize={"sm"}>
          <Thead>
            <Tr>
              <Th px="0" py=".5rem">
                Name
              </Th>
              <Th px="0" py=".5rem">
                Plan
              </Th>
              <Th px="0" py=".5rem">
                Last updated
              </Th>
              <Th px="0" py=".5rem">
                Last updated by
              </Th>
              <Th px="0" py=".5rem">
                Members
              </Th>
              <Th px="0" py=".5rem"></Th>
            </Tr>
          </Thead>
          <Tbody>{renderOrganizations()}</Tbody>
        </Table>
      )}

      {isOpen === "upsert" && (
        <UpsertOrganizationModal hasWriteOrganizationEntitlement={hasOrganizationsWrite} id={selectedId} isOpen onClose={onClose} />
      )}
      {isOpen === "delete" && (
        <ConfirmDeleteModal
          title="Delete Organization"
          body="Are you sure you want to delete this organization? All users in this organization will loose the entitlements of this organisation but will still be able to login to Charli with any personal entitlements they have."
          isOpen
          onClose={onClose}
          onConfirm={onDelete}
          isLoading={isLoading}
        />
      )}
      {isOpen === "detail" && <OrganizationModal id={selectedId} onClose={onClose} />}
      {isOpen === "invite-users" && <InviteUsersPanel id={selectedId} onClose={onClose} />}
      {isOpen === "logs" && <OrganizationLogsPanel id={selectedId} onClose={onClose} />}
    </>
  );
};
