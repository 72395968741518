import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { SuggestedQuestions } from "api/suggestions";
import type { RootState } from "state/rootReducer";
import { REHYDRATE } from "redux-persist";
import { fetchSuggestedQuestionData } from "./operations";

const rehydrate = createAction<RootState>(REHYDRATE);

interface SuggestedQuestionsState {
  questionData: SuggestedQuestions | [];
}

const initialState: SuggestedQuestionsState = {
  questionData: [],
};

export const { reducer, actions } = createSlice({
  name: "suggestedQuestions",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuggestedQuestionData.fulfilled, (state, action) => {
        state.questionData = action.payload ? action.payload : [];
      })
      .addCase(rehydrate, (state, action: PayloadAction<RootState>) => {
        if (action.payload?.suggestedQuestions) {
          state.questionData = action.payload.suggestedQuestions.questionData;
        }
      });
  },
});

export default reducer;
