import { Record, String } from "runtypes";
import type { Static } from "runtypes";
import { TickerStatus } from "./TickerStatus";
import { TickerType } from "./TickerType";

export const Ticker = Record({
  creationByUser: String,
  creationDate: String,
  exchange: String,
  id: String,
  lastUpdatedByUser: String.nullable(),
  lastUpdatedDate: String.nullable(),
  name: String,
  status: TickerStatus,
  type: TickerType.nullable(),
  symbol: String,
});

export type Ticker = Static<typeof Ticker>;
