import { Array, Record, String } from "runtypes";
import type { Static } from "runtypes";
import { WorkflowMilestone } from "./WorkflowMilestone";

export const GetWorkflowsMilestones = Array(
  Record({
    id: String,
    events: Array(WorkflowMilestone),
  })
);

export type GetWorkflowsMilestones = Static<typeof GetWorkflowsMilestones>;
