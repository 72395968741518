import React, { useContext, useEffect } from "react";
import { Box, HStack, StackDivider, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { TabTitle } from "screens/common/components/TabTitle";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useEntitlements } from "hooks";
import { NavLink, Outlet } from "react-router-dom";
import type { NavLinkProps } from "react-router-dom";
import type { AdminPath } from "types/admin";
import { adminTabsEntitlementsMapping } from "./adminTabsEntitlementsMapping";

const NavLinkAdminWrapper = ({ to, params, ...props }: Omit<NavLinkProps, "to"> & { to: AdminPath; params?: string }) => {
  return <NavLink to={to + `${params ? "?" + params : ""}`} {...props} />;
};

const CustomLinkText = ({ isActive, children }) => {
  const selectedButtonColor = useColorModeValue("primaryButton.selectedColor", "primaryButton.selectedColorDarkMode");

  return (
    <Text fontSize={"sm"} {...(isActive && { color: selectedButtonColor, borderBottom: "2px solid" })} my="5px">
      {children}
    </Text>
  );
};

export const AdminTabs = () => {
  const { setHeaderText } = useContext(SettingsProviderContext);
  const { onConversationClose, setConversationId } = useContext(ConversationContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const allEntitlements = useEntitlements();

  useEffect(() => {
    onConversationClose();
    setHeaderText("Administration");
  }, [onConversationClose, setConversationId, setHeaderText]);

  return (
    <Box width="100%" height={"100%"} px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"} mt="0!important">
      <TabTitle title="Charli > Admin" />
      <HStack flexWrap="wrap" mb={"1rem"} spacing={3} divider={<StackDivider />} as="nav">
        {adminTabsEntitlementsMapping.map(({ entitlement, path, linkText, params }) => {
          if (!allEntitlements[entitlement]) {
            return null;
          } else {
            return (
              <NavLinkAdminWrapper to={path} params={params} key={path}>
                {({ isActive }) => <CustomLinkText isActive={isActive}>{linkText}</CustomLinkText>}
              </NavLinkAdminWrapper>
            );
          }
        })}
      </HStack>
      <Outlet />
    </Box>
  );
};
