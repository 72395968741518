import React from "react";
import { Box, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useOrganization, useOrganizationEntitlements } from "hooks";
import { Wizard } from "react-use-wizard";
import { useBillingPlanEntitlements } from "hooks/useBillingPlans";

import type { Role } from "types/organization/Role";
import { RenderIfHasEntitlements, EntitlementsList } from "screens/common/components";

export interface OrganizationFormValues {
  id?: string;
  name: string;
  planCode: string;
  members: { label: string; value: string; id: string; role: Role }[];
}

interface IProps {
  id: string | null;
  onClose: () => void;
  maxHeight?: string;
}

export const UpsertOrganizationEntitlementModal = (props: IProps) => {
  const { id, onClose, maxHeight } = props;

  const organization = useOrganization(id);
  const { entitlements, isLoading: isLoadingEntitlements } = useOrganizationEntitlements(organization ? organization.id : null);
  const { entitlements: billingPlanEntitlements, isLoading: isLoadingBillingPlanEntitlements } = useBillingPlanEntitlements(
    organization ? organization.planCode : null
  );
  const titleColor = useColorModeValue("gray.900", "gray.500");

  return (
    <PanelView isOpen onClose={onClose} panelTitle={`Update Entitlements${organization?.name ? `: ${organization.name}` : ""}`}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem" height="100%">
            <Stack spacing="1.5rem" pb="3rem">
              {!organization ? null : (
                <Stack direction="row" spacing="1.5rem">
                  <Box width="100%">
                    <Text fontWeight={"bold"} color={titleColor} fontSize="md" marginBottom={4}>
                      Organization Entitlements
                    </Text>
                    <RenderIfHasEntitlements entitlement="manage_entitlements">
                      <EntitlementsList
                        type="organization"
                        entityId={organization.id}
                        entitlements={entitlements}
                        isLoading={isLoadingEntitlements}
                        maxHeight={maxHeight}
                      />
                    </RenderIfHasEntitlements>
                  </Box>
                  <Box width="100%">
                    <Text fontWeight={"bold"} color={titleColor} fontSize="md" marginBottom={4}>
                      Plan Entitlements
                    </Text>
                    <RenderIfHasEntitlements entitlement="manage_entitlements">
                      <EntitlementsList
                        type="plan"
                        entityId={organization.planCode}
                        entitlements={billingPlanEntitlements}
                        isLoading={isLoadingBillingPlanEntitlements}
                        maxHeight={maxHeight}
                      />
                    </RenderIfHasEntitlements>
                  </Box>
                </Stack>
              )}
            </Stack>
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
