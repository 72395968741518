import { track as segmentTrack, identify as segmentIdentify, page as segmentPage, reset as segmentReset } from "api/analytics/segment";
import type { TrackableProperties } from "types/analytics";

const CLIENT_KEY = "client";
const CLIENT_VALUE = "web-app";

export const UTM_PARAMETERS = ["utm_campaign", "utm_source", "utm_medium", "utm_content", "utm_name", "utm_id", "utm_term"] as const;

export const track = (eventName: string, properties: TrackableProperties = {}) => {
  if (properties && properties[CLIENT_KEY]) {
    console.warn(`\`{CLIENT_KEY}\` was already set when calling \`track()\`.`);
  } else if (properties) {
    properties[CLIENT_KEY] = CLIENT_VALUE;
  } else {
    properties = {
      [CLIENT_KEY]: CLIENT_VALUE,
    };
  }

  segmentTrack(eventName, properties);
};

export const identify = (userId: string, properties: TrackableProperties = {}) => {
  segmentIdentify(userId, properties);
};

export const reset = () => {
  segmentReset();
};

export const page = (pagePath: string) => {
  const properties: { [key: string]: string } = {};

  properties[CLIENT_KEY] = CLIENT_VALUE;

  segmentPage(pagePath, properties);
};
