import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import type { DropResult } from "@hello-pangea/dnd";
import { v4 as uuid } from "uuid";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Box, Flex, Input, Select, IconButton, Button, Text, Stack, Icon, FormHelperText, FormControl, useToast } from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useButtonProps, useCollection } from "hooks";
import { MdDragIndicator } from "react-icons/md";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import type { RequestEntities } from "types/charliui";
import type { PortfolioSettings } from "types/collection";
import { ConversationContext } from "screens/thread/ConversationContext";
import { ToastMessageContent } from "./components";

interface Question {
  id: string;
  text: string;
  focus: "sentiment" | "analytical";
}

type ValidationErrorsByIndex = {
  [index: string]: string;
};

type EditableQuestionListProps = {
  portfolioId: string;
  portfolioSettings?: PortfolioSettings;
};

const EditableQuestionList: React.FC<EditableQuestionListProps> = ({ portfolioId, portfolioSettings }) => {
  const portfolioProject = useCollection(portfolioId);
  const { onConversationOpen, setConversationId } = useContext(ConversationContext);
  const commonButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const dispatch = useDispatch();
  const toast = useToast();

  const [questions, setQuestions] = useState<Question[]>([{ id: "1", text: "", focus: "sentiment" }]);
  const [hasChanges, setHasChanges] = useState(false);
  const addQuestionButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (portfolioSettings?.questions) {
      setQuestions(
        portfolioSettings.questions.map((portfolioQuestion, index) => ({
          id: `${index + 1}`,
          focus: portfolioQuestion.focus,
          text: portfolioQuestion.question,
        }))
      );
      setHasChanges(false);
    }
  }, [portfolioSettings?.questions]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuestions(items);
    validateQuestions(items);
    setHasChanges(true);
  };

  const handleQuestionChange = (id: string, text: string, index: number) => {
    const updatedQuestions = questions.map((q) => (q.id === id ? { ...q, text } : q));
    setQuestions(updatedQuestions);
    validateQuestions(updatedQuestions);
    setHasChanges(true);
  };

  const handleFocusChange = (id: string, focus: "sentiment" | "analytical") => {
    setQuestions(questions.map((q) => (q.id === id ? { ...q, focus } : q)));
    setHasChanges(true);
  };

  const addNewQuestion = () => {
    const newId = (questions.length + 1).toString();
    setQuestions([...questions, { id: newId, text: "", focus: "sentiment" }]);
    setHasChanges(true);
  };

  const deleteQuestion = (id: string) => {
    const updatedQuestions = questions.filter((q) => q.id !== id);
    setQuestions(updatedQuestions);
    validateQuestions(updatedQuestions);
    setHasChanges(true);
  };

  useEffect(() => {
    if (addQuestionButtonRef.current) {
      addQuestionButtonRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [questions.length]);

  const validationSchema = Yup.array().of(
    Yup.object().shape({
      text: Yup.string().required("Please provide a valid question").min(1, "Please provide a valid question"),
    })
  );

  // Validation and onUpdate handlers
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsByIndex>({});
  const validateQuestions = useCallback(
    (questions: Array<Question>) => {
      return validationSchema
        .validate(questions, { abortEarly: false })
        .then(() => {
          setValidationErrors({});
          return questions;
        })
        .catch((err) => {
          const errorsByIndex: ValidationErrorsByIndex = err.inner.reduce((errorsObject, currentErrorObject) => {
            const index = currentErrorObject.path.match(/\d+/)[0];
            if (!errorsObject[index]) {
              return { ...errorsObject, ...{ [index]: currentErrorObject.message } };
            }
            return errorsObject;
          }, {});
          setValidationErrors(errorsByIndex);
          return Promise.resolve(null);
        });
    },
    [validationSchema]
  );
  const callUpdateHandler = useCallback(
    (questions: Array<Question>) => {
      const newConversationId = portfolioProject?.conversationId || uuid();
      const entities: RequestEntities = [];
      entities.push({ entity: "collection_id", value: portfolioId });
      questions.forEach((question) => {
        entities.push({ entity: "questions", value: question.text });
        entities.push({ entity: "answer_focuses", value: question.focus });
      });
      dispatch(
        sendMessage({
          conversationId: newConversationId,
          intent: "/update_portfolio_questions",
          entities,
        })
      );
      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message="I'll update the questions for this portfolio now. You can open the conversation I started about it by clicking this message"
            onClick={() => {
              setConversationId(newConversationId);
              onConversationOpen();
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      setHasChanges(false);
    },
    [dispatch, onConversationOpen, portfolioId, portfolioProject?.conversationId, setConversationId, toast]
  );
  const onSaveQuestions = useCallback(() => {
    validateQuestions(questions).then((validatedQuestions) => {
      if (validatedQuestions) {
        callUpdateHandler(questions);
      }
    });
  }, [questions, callUpdateHandler, validateQuestions]);

  return (
    <Box fontSize="sm">
      <Flex mb={4} alignItems="center" fontWeight="bold">
        <Box width="45px" />
        <Text flex={1} mr={2}>
          Question
        </Text>
        <Text width="110px" mr={2}>
          Focus
        </Text>
        <Box width="40px" />
      </Flex>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {questions.map((question, index) => (
                <Draggable key={question.id} draggableId={question.id} index={index}>
                  {(provided, snapshot) => {
                    const { style } = provided.draggableProps;
                    return (
                      <Flex
                        {...provided.draggableProps}
                        style={{ ...style, left: "auto !important", top: "auto !important", alignItems: "flex-start" }}
                        ref={provided.innerRef}
                        alignItems="center"
                        py="1"
                        bgColor="white"
                        borderRadius="md">
                        <IconButton
                          {...secondaryButtonProps}
                          aria-label="Drag handle"
                          icon={<Icon as={MdDragIndicator} boxSize="1.3rem" />}
                          {...provided.dragHandleProps}
                          bg={snapshot.isDragging ? "gray.100" : "white"}
                          mr={2}
                          cursor="grab"
                        />
                        <FormControl flex={1} isInvalid={!!validationErrors[index]}>
                          <Input
                            size="sm"
                            placeholder="Enter question to be asked in projects"
                            value={question.text}
                            onChange={(e) => handleQuestionChange(question.id, e.target.value, index)}
                            flex={1}
                          />
                          {!!validationErrors[index] && <FormHelperText>{validationErrors[index]}</FormHelperText>}
                        </FormControl>
                        <Select
                          size="sm"
                          value={question.focus}
                          onChange={(e) => handleFocusChange(question.id, e.target.value as "sentiment" | "analytical")}
                          width="120px"
                          mr={2}
                          ml={2}>
                          <option value="sentiment">Sentiment</option>
                          <option value="analytical">Analytical</option>
                        </Select>
                        <IconButton
                          {...secondaryButtonProps}
                          aria-label="Delete question"
                          icon={<DeleteIcon />}
                          onClick={() => deleteQuestion(question.id)}
                          colorScheme="red"
                        />
                      </Flex>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Stack direction="row" width="100%" justifyContent="space-between" height="4rem" ref={addQuestionButtonRef}>
        <Button {...secondaryButtonProps} leftIcon={<AddIcon />} mt={4} onClick={addNewQuestion}>
          Add New Question
        </Button>
        <Button {...commonButtonProps} mt={4} onClick={onSaveQuestions} isDisabled={!hasChanges}>
          Save Questions
        </Button>
      </Stack>
    </Box>
  );
};

export default EditableQuestionList;
