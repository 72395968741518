import type { FunctionComponent } from "react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions as sessionActions } from "state/session/reducer";
import { useToast } from "@chakra-ui/react";

export const Logout: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  useEffect(() => {
    toast.closeAll();
    dispatch(sessionActions.logout());
  }, [dispatch, toast]);

  return <React.Fragment />;
};
