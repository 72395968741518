import { createSlice } from "@reduxjs/toolkit";
import type { OrganizationPreference } from "api/organizationsPreferences/models/Preference";
import { downloadOrganizationsPreferences, removeOrganizationPreference, upsertOrganizationPreference } from "./operations";
import { setLoaderFalse, setLoaderTrue } from "state/utils";
import reduce from "lodash/reduce";

const initialState = {
  isLoading: false,
  preferencesByKey: {} as Record<string, OrganizationPreference>,
  preferencesKeys: [] as string[],
};

export const { actions, reducer } = createSlice({
  name: "organizationsPreference",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadOrganizationsPreferences.pending, setLoaderTrue);
    builder.addCase(downloadOrganizationsPreferences.rejected, setLoaderFalse);
    builder.addCase(downloadOrganizationsPreferences.fulfilled, (state, action) => {
      state.preferencesByKey = reduce(
        action.payload,
        (acc, preference) => ({
          ...acc,
          [preference.key]: preference,
        }),
        {}
      );

      state.preferencesKeys = action.payload.map((preference) => preference.key);
      state.isLoading = false;
    });

    builder.addCase(upsertOrganizationPreference.pending, setLoaderTrue);
    builder.addCase(upsertOrganizationPreference.rejected, setLoaderFalse);
    builder.addCase(upsertOrganizationPreference.fulfilled, setLoaderFalse);

    builder.addCase(removeOrganizationPreference.pending, setLoaderTrue);
    builder.addCase(removeOrganizationPreference.rejected, setLoaderFalse);
    builder.addCase(removeOrganizationPreference.fulfilled, (state, action) => {
      state.preferencesKeys = state.preferencesKeys.filter((key) => key !== action.meta.arg.key);
      delete state.preferencesByKey[action.meta.arg.key];
      state.isLoading = false;
    });
  },
});

export default reducer;
