import { useBreakpointValue } from "@chakra-ui/react";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useTabProps } from "hooks";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabTitle } from "screens/common/components/TabTitle";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { ActivityTilesSection } from "./ActivityTilesSection";

export const Activities = () => {
  const { setHeaderText } = useContext(SettingsProviderContext);
  const { activityType } = useParams() as { activityType: string };
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { defaultTabProps } = useTabProps();

  useEffect(() => {
    setHeaderText("Activity History");
  }, [setHeaderText]);

  const getActivityTypeIndex = () => {
    switch (activityType) {
      case "all":
        return 0;
      case "needs_action":
        return 1;
      case "in_progress":
        return 2;
      case "completed":
        return 3;
      default:
        return 0;
    }
  };

  return (
    <Box height={"100%"} width="100%" px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "0.5rem"}>
      <TabTitle title="Charli > Activity History" />
      <Tabs height={"100%"} index={getActivityTypeIndex()} isLazy>
        <TabList>
          <Tab onClick={() => navigate("/activities/all")} {...defaultTabProps}>
            All Tasks
          </Tab>
          <Tab onClick={() => navigate("/activities/needs_action")} {...defaultTabProps}>
            Need Action
          </Tab>
          <Tab onClick={() => navigate("/activities/in_progress")} {...defaultTabProps}>
            In Progress
          </Tab>
          <Tab onClick={() => navigate("/activities/completed")} {...defaultTabProps}>
            Completed
          </Tab>
        </TabList>
        {/* This is for allowing the panel to take the rest of the page
        and prevent a duplicated scrollbar */}
        <TabPanels height={"calc(100% - 60px)"}>
          <TabPanel height={"inherit"} px="0" pt="1rem">
            <ActivityTilesSection hasInfiniteScroll noItemsFoundMessage="Everything looks good, no action is required from you." />
          </TabPanel>
          <TabPanel height={"inherit"} px="0" pt="1rem">
            <ActivityTilesSection
              hasInfiniteScroll
              noItemsFoundMessage="Everything looks good, no action is required from you."
              blacklist={["complete", "cancelled", "dismissed"]}
            />
          </TabPanel>
          <TabPanel height={"inherit"} px="0" pt="1rem">
            <ActivityTilesSection
              hasInfiniteScroll
              noItemsFoundMessage="All of your activities are complete."
              states={["queued", "in_progress"]}
            />
          </TabPanel>
          <TabPanel height={"inherit"} px="0" pt="1rem">
            <ActivityTilesSection hasInfiniteScroll noItemsFoundMessage="You have no activities." states={["complete"]} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
