import type { Static } from "runtypes";
import { Record, String, Array } from "runtypes";
import { ExtractedHighlightsDetail } from "./ExtractedHighlightsDetail";

export const ExtractedHighlights = Record({
  topic: String,
  highlights: Array(ExtractedHighlightsDetail),
});

export type ExtractedHighlights = Static<typeof ExtractedHighlights>;
