import { Text, useColorModeValue, Stack, Center, Box } from "@chakra-ui/react";
import React, { useContext, useMemo, useState } from "react";
import { useWorkflowInProgressOrClarificationRequested } from "hooks/useCheckpoints";
import type { Workflow } from "types/workflows/workflow";
import { WorkflowProgress } from "./WorkflowProgress";
import { useWorkflowsStatsMap } from "hooks/useWorkflowsStats";
import { useMainCollectionWorkflowId } from "hooks";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useWorkflowKey, useWorkflowStatus } from "hooks/useWorkflows";
import { WorkflowStatusIcon } from "screens/common/components/WorkflowStatusIcon";

const convertMillisecondsToHoursAndMinutes = (ms: number) => {
  // Convert milliseconds to seconds
  const seconds = Math.floor(ms / 1000);

  // Calculate the number of hours and remaining seconds
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;

  // Calculate the number of minutes
  const minutes = Math.floor(remainingSeconds / 60);
  if (seconds < 60) return "less then a minute";
  return hours ? `${hours} hour${hours > 1 ? "s" : ""} and ${minutes} minutes` : `${minutes} minute${minutes > 1 ? "s" : ""}`;
};

interface Props {
  workflow?: Workflow;
  workflowId: string;
  size?: "compact" | "full";
  showInline?: boolean;
  progressIndicatorStyle?: "bar" | "circle" | "combined";
  lastUpdated?: string;
  hideCompletedMessage?: boolean;
  hideCompletedInDate?: boolean;
  collectionId?: string;
  conversationId?: string;
  maxWidth?: string;
}

export const WorkflowProgressBar = (props: Props) => {
  const {
    workflowId,
    size,
    lastUpdated,
    hideCompletedMessage,
    hideCompletedInDate,
    collectionId,
    progressIndicatorStyle,
    showInline = false,
    conversationId,
    maxWidth,
  } = props;
  const workflowState = useWorkflowStatus(workflowId);
  const isWorkflowInProgress = useWorkflowInProgressOrClarificationRequested(workflowId);
  const progressTextColor = useColorModeValue("gray.600", "gray.300");
  const mainCollectionWorkflowId = useMainCollectionWorkflowId(collectionId);
  const workflowsStatsMap = useWorkflowsStatsMap(mainCollectionWorkflowId ? [mainCollectionWorkflowId] : []);
  const workflowCreationDate = useWorkflowKey(workflowId, "creationDate");
  const workflowIntent = useWorkflowKey(workflowId, "intent");
  const currentWorkflowCreationDate = useMemo(
    () => (workflowCreationDate ? new Date(workflowCreationDate) : new Date()),
    [workflowCreationDate]
  );
  const { setConversationId, onConversationOpen } = useContext(ConversationContext);
  const [isCancelling, setIsCancelling] = useState(false);

  const handleOnClick = (event: { stopPropagation: () => void }) => {
    if (!conversationId || isCancelling) return;
    event.stopPropagation();
    setConversationId(conversationId);
    onConversationOpen();
  };

  const calculateTimeDistance = () => {
    if (currentWorkflowCreationDate && !hideCompletedInDate) {
      return `, ${formatDistanceToNow(new Date(currentWorkflowCreationDate), {
        addSuffix: true,
        includeSeconds: false,
      })}`;
    }
    return "";
  };

  const stateMessage = () => {
    switch (workflowState) {
      case "complete":
        return `All ${workflowIntent?.split("_").join(" ")} tasks finished ${lastUpdated ?? ""}.`;
      case "error":
        return `One or more tasks are in error, ${lastUpdated ?? ""}. Please review the conversation.`;
      case "failed":
      case "incomplete":
        return `One or more tasks did not complete, ${lastUpdated ?? ""}. Please review the conversation.`;
      case "clarification_needed":
        return `One or more tasks needs a clarification from you, ${lastUpdated ?? ""}.`;
      case "queued":
        return `Project was queued ${lastUpdated ?? ""} and will proceed shortly.`;
      case "cancelled":
        return `This project was cancelled ${lastUpdated ?? ""}.`;
    }
  };

  const message = stateMessage();
  let executionTimeText = "";

  if (mainCollectionWorkflowId && workflowsStatsMap[mainCollectionWorkflowId]) {
    const executionTime = convertMillisecondsToHoursAndMinutes(workflowsStatsMap[mainCollectionWorkflowId].executionTime);
    executionTimeText = `Completed in ${executionTime}`;
  }

  if (isWorkflowInProgress && !isCancelling) {
    return (
      <Stack direction={showInline ? "row" : "column"} width="100%" justifyContent={showInline ? "end" : "space-between"}>
        <WorkflowProgress
          showInline={showInline}
          maxWidth={maxWidth}
          workflowId={workflowId}
          size={size}
          progressIndicatorStyle={progressIndicatorStyle}
          appendText={calculateTimeDistance()}
          setIsCancelling={setIsCancelling}
        />
      </Stack>
    );
  } else if (hideCompletedMessage || !workflowState) {
    return null;
  } else {
    return (
      <>
        {progressIndicatorStyle === "bar" && (
          <Stack
            direction="row"
            onClick={(event: { stopPropagation: () => void }) => {
              handleOnClick(event);
            }}>
            <Center>
              <Text fontSize={size === "compact" ? "11px" : "md"} color={progressTextColor}>
                {message} {executionTimeText}
              </Text>
            </Center>
          </Stack>
        )}
        {(progressIndicatorStyle === "circle" || progressIndicatorStyle === "combined") && (
          <Box
            onClick={(event: { stopPropagation: () => void }) => {
              handleOnClick(event);
            }}>
            <WorkflowStatusIcon status={workflowState} boxSize="22px" altCheckmark />
          </Box>
        )}
      </>
    );
  }
};
