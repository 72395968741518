import { prependProxyBaseUrl, request } from "api/request";
import { Result } from "@badrap/result";

const INVITE_STATUS_ENDPOINT = prependProxyBaseUrl("/api/invite");

export enum InviteErrors {
  notFound = "Invitation code does not exist",
  forbidden = "Invitation code was not issued for this user",
  serverError = "Unhandled exception when retrieving invitation status",
}

export type InviteStatusResponse = {
  id: string;
  hasBeenAccepted: boolean;
  recipient: string;
  ownerEmails: string[];
};

/**
 * Returns the invitation status for the supplied code.
 *
 * @param {string} inviteCode
 */
export async function getInviteStatus(inviteCode: string): Promise<Result<InviteStatusResponse, Error>> {
  try {
    const inviteStatus: InviteStatusResponse = await request()
      .url(`${INVITE_STATUS_ENDPOINT}/${inviteCode}`)
      .get()
      .notFound(() => {
        throw new Error(InviteErrors.notFound);
      })
      .forbidden(() => {
        throw new Error(InviteErrors.forbidden);
      })
      .json();

    return Result.ok(inviteStatus);
  } catch (err) {
    console.error(err);
    return Result.err(err);
  }
}
