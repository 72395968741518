import { Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import type { ContentDetails } from "types/content/ContentDetails";
import { Summary } from "screens/content/common/summary/Summary";
import { BlockSectionHeader } from "./BlockSectionHeader";

interface Props {
  itemContent: ContentDetails;
  hideTitle?: boolean;
  bgColor?: string;
}

export const ContentSummaryData: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  itemContent,
  hideTitle = false,
  bgColor = "transparent",
}) => {
  const findDataWithLabel = (itemContent: ContentDetails | undefined, label: string): string | undefined => {
    const summaryData = itemContent?.summaryDetails?.data;
    const summary = summaryData?.find((data) => data.label === label);
    return summary?.value;
  };

  const summarySectionHeader = (itemContent: ContentDetails | undefined): string => {
    const titleDetails = findDataWithLabel(itemContent, "Summary Title");
    return titleDetails ? `AI Generated Summary: ${titleDetails}` : "AI Generated Summary";
  };

  return (
    <>
      {findDataWithLabel(itemContent, "Summary") && (
        <Box backgroundColor={bgColor}>
          {!hideTitle && <BlockSectionHeader title={summarySectionHeader(itemContent)} />}
          <Summary
            textTruncated
            summary={findDataWithLabel(itemContent, "Summary")}
            noSummaryMessage="To get a summary for this resource click the Summarize button above."
          />
        </Box>
      )}
    </>
  );
};
