import React from "react";
import { ContentImageWrapper } from "screens/content/common/ContentImage/ContentImageWrapper";

interface Props {
  maxHeight?: string;
  thumbnailHeight?: string;
  thumbnailWidth?: string;
  thumbnailBorderRadius?: string;
  bgColor?: string;
  fit?: "cover" | "fill" | "contain" | "none" | "scale-down";
  thumbnailFit?: "cover" | "fill" | "contain";
  thumbnailPosition?: "top" | "center";
  onClick?: () => void;
  contentId: string;
}

export function ContentDetailImage(props: Props) {
  const {
    maxHeight = "2rem",
    thumbnailHeight,
    thumbnailWidth,
    thumbnailBorderRadius,
    bgColor,
    fit = "contain",
    thumbnailFit = "cover",
    thumbnailPosition = "center",
    onClick,
    contentId,
  } = props;

  return (
    <ContentImageWrapper
      metadataId={contentId}
      thumbnailWidth={thumbnailWidth}
      thumbnailHeight={thumbnailHeight}
      fit={fit}
      thumbnailFit={thumbnailFit}
      thumbnailPosition={thumbnailPosition}
      thumbnailBorderRadius={thumbnailBorderRadius}
      onClick={onClick}
      bgColor={bgColor}
      maxHeight={maxHeight}
    />
  );
}
