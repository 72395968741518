import Uppy from "@uppy/core";
import { useUppy as useUppyInstance } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";
import { track } from "api/analytics";
import { FILE_ADD_FAILED, FILE_UPLOADED, FILE_UPLOAD_FAILED } from "api/analytics/events";
import { getAccessToken } from "api/auth";
import { deleteFile } from "api/media";
import { useCallback, useState } from "react";
import store from "state/store";
import type { UploadFileResponse, UppyFile } from "types/files";
import { v4 as uuid } from "uuid";
import { prependProxyBaseUrl } from "../api/request";

const MAX_FILE_SIZE = 31457280; // 30MB
const UPLOAD_FILE_ENDPOINT = prependProxyBaseUrl("/api/media/internal/upload-file");

const getUserEmail = () => {
  const state = store.getState();
  return state.session.user!.email;
};

const getFileExtension = (file: { name: string; type: string }) => {
  const re = /(?:\.([^.]+))?$/;
  return re.exec(file.name)![1] || file.type;
};

export function useUppy({ id } = { id: "default" }) {
  const [files, setFiles] = useState<UppyFile[]>([]);
  const [status, setStatus] = useState<"ready" | "pending" | "error">("ready");

  const uppy = useUppyInstance(() => {
    const uppy = Uppy({
      id: id,
      autoProceed: true,
      restrictions: {
        maxFileSize: MAX_FILE_SIZE,
      },
    }).use(XHRUpload, {
      endpoint: UPLOAD_FILE_ENDPOINT,
      metaFields: ["key", "from", "type"],
      fieldName: "file",
      timeout: 0,
      limit: 2,
      headers: { authorization: `Bearer ${getAccessToken()}` },
    });

    uppy.setMeta({ from: getUserEmail() });

    uppy.on("file-added", (file) => {
      const fileId = uuid();

      // Update metadata so that charli-media can accept it
      uppy.setFileMeta(file.id, {
        key: fileId,
        type: getFileExtension(file),
      });
    });

    uppy.on("file-removed", (removedFile, reason) => {
      setFiles(uppy.getFiles());

      // Note: is async, but is not awaited as should occur in parallel
      if (reason === "removed-by-user") deleteFile(removedFile.meta.key);

      if (uppy.getFiles().length === 0) {
        setStatus("ready");
      }
    });

    uppy.on("upload", (file) => {
      setStatus("pending");

      // Retrieve the current token from state at the beginning of each upload in case it's been refreshed since Uppy was initialized
      uppy.getPlugin("XHRUpload").setOptions({ headers: { authorization: `Bearer ${getAccessToken()}` } });
    });

    uppy.on("upload-success", (file, response) => {
      const { fileId, fileName } = response.body.content as UploadFileResponse;

      uppy.setFileMeta(file.id, {
        key: fileId,
        name: fileName,
      });

      setFiles(uppy.getFiles());
    });

    uppy.on("complete", (result) => {
      result.successful.forEach((file) => {
        track(FILE_UPLOADED, {
          file_size: file.size,
          file_extension: file.extension,
          mime_type: file.type,
        });
      });

      result.failed.forEach((file) => {
        track(FILE_UPLOAD_FAILED, {
          file_size: file.size,
          file_extension: file.extension,
          mime_type: file.type,
        });
      });

      setStatus(result.failed.length > 0 ? "error" : "ready");
    });

    uppy.on("restriction-failed", (file) => {
      track(FILE_ADD_FAILED, {
        file_size: file.size,
        file_extension: file.extension,
        mime_type: file.type,
      });
    });

    return uppy;
  });

  const reset = useCallback(() => {
    uppy.reset();
    setFiles([]);
  }, [uppy]);

  const addFile = useCallback(
    (args: { name: string; type: string; data: Blob }) => {
      uppy.addFile({
        name: args.name,
        type: args.type,
        data: args.data,
        meta: {
          key: uuid(),
          type: getFileExtension({ ...args }),
        },
      });
    },
    [uppy]
  );

  return { uppy, addFile, status, files, reset };
}
