import type { Entitlement } from "types/entitlements";
import { prependProxyBaseUrl, request } from "../request";

const PROXY_PREFIX = prependProxyBaseUrl("/api/entitlements");

const USER_ENTITLEMENTS_PATH = (userId: string) => `${PROXY_PREFIX}/entitlements/user/${userId}`;
const ORGANIZATION_ENTITLEMENTS_PATH = (organizationId: string) => `${PROXY_PREFIX}/entitlements/organization/${organizationId}`;
const PLAN_ENTITLEMENTS_PATH = (planCode: string) => `${PROXY_PREFIX}/entitlements/plan/${planCode}`;

export async function getUserEntitlements(userId: string, options?: { entitlementCodes: string[] }): Promise<Entitlement[]> {
  return await request()
    .url(USER_ENTITLEMENTS_PATH(userId))
    .query({ ...(options?.entitlementCodes && { entitlementCode: options.entitlementCodes }) })
    .get()
    .json();
}

export async function getOrganizationEntitlements(organizationId: string): Promise<Entitlement[]> {
  return await request().url(ORGANIZATION_ENTITLEMENTS_PATH(organizationId)).get().json();
}

export async function getPlanEntitlements(planCode: string): Promise<Entitlement[]> {
  return await request().url(PLAN_ENTITLEMENTS_PATH(planCode)).get().json();
}
