import { Flex, FormControl, FormErrorMessage, FormLabel, IconButton, Input } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import React from "react";
import { BiSave } from "react-icons/bi";

export function FormTextField({ errors = {}, control, label, keyField, atomic = false, disabled = false, onUpdate, required = false }) {
  return (
    <FormControl isInvalid={errors[keyField] !== undefined} pb="1px">
      <FormLabel fontSize={"sm"} htmlFor="user-intent">
        {label}
      </FormLabel>
      <Flex>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              id={keyField}
              type="text"
              borderColor={errors[keyField] ? "red.500" : "gray.200"}
              size="xs"
              boxShadow="none"
              disabled={disabled}
              required={required}
            />
          )}
          control={control}
          name={keyField}
        />
        {atomic && (
          <IconButton borderRadius={"sm"} aria-label="Save" size="xs" ml="1rem" icon={<BiSave fontSize="1rem" />} onClick={onUpdate} />
        )}
      </Flex>
      {errors[keyField]?.type === "required" && <FormErrorMessage>This field is required.</FormErrorMessage>}
      {errors[keyField]?.type === "minLength" && (
        <FormErrorMessage>This field requires at least {errors[keyField]?.count} characters.</FormErrorMessage>
      )}
      {errors[keyField]?.type === "invalidJSON" && <FormErrorMessage>This field requires a valid JSON string.</FormErrorMessage>}
    </FormControl>
  );
}
