import React from "react";
import { Center, Stack, Text } from "@chakra-ui/react";
import { Tooltip, YAxis, XAxis, ResponsiveContainer, Bar, BarChart } from "recharts";
import { CustomTooltipAreaChart, getShadesFromHexColor } from "./CustomChartUtils";
import type { DataType } from "hooks/useStats";
import { defaultProps } from "react-select/creatable";
import { useSumAllValuesByDate } from "../../operations/tabs/useWorkflowStats";
import uniq from "lodash/uniq";

interface Props {
  data: DataType[];
  width?: number | string;
  height?: string;
  chartHeight?: number;
  title?: string;
  chartId?: string;
}

export const CustomStackedBarChart: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  data,
  width,
  height = "13rem",
  chartHeight = 100,
  title,
  chartId,
}) => {
  const uniqueStates = uniq(data.map((item) => item.state));
  const dataByDate = useSumAllValuesByDate(data).reverse();
  const chartColors = getShadesFromHexColor("#4f7879", uniqueStates.length);

  return (
    <Stack height={height} width={width}>
      {data.every((item) => !item.value) ? (
        <Center height="13rem" pb="1rem" fontSize="xs">
          No Projects
        </Center>
      ) : (
        <Stack height="13rem">
          <ResponsiveContainer width={"100%"} height={chartHeight}>
            <BarChart id={chartId} data={dataByDate} margin={{ top: 0, right: 0, left: 0, bottom: 12 }}>
              <Tooltip
                isAnimationActive={false}
                content={
                  <CustomTooltipAreaChart active={defaultProps.active} payload={defaultProps.payload} baseColor="#72acad" prefix="" />
                }
              />
              <XAxis dataKey="name" fontSize={"10px"} interval={2} />
              <YAxis fontSize={"10px"} />
              {uniqueStates.map((state, index) => (
                <Bar key={state} stackId="a" dataKey={state || ""} fill={chartColors[index].color} />
              ))}
            </BarChart>
          </ResponsiveContainer>
          <Text mt="0!important" fontSize={"xs"} width={"100%"} align={"center"} pl="3rem">
            {title}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};
