import type { FunctionComponent } from "react";
import React from "react";
import { JsonItemView } from "screens/json/JsonItemView";

interface Props {
  json: string;
}

export const JsonView: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ json }) => {
  return <JsonItemView json={json} />;
};
