import type { FunctionComponent } from "react";
import React from "react";
import { PanelView } from "screens/panels/components/PanelView";
import { PanelStep } from "screens/panels/components/PanelStep";
import { Wizard } from "react-use-wizard";
import ReactJson from "react-json-view";
import { Box, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import { useContentDetails, useProjectParams } from "hooks";

interface Props {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ContentJsonViewPanel: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  isOpen,
  onClose,
  title,
}) => {
  const { projectId, contentId } = useProjectParams();
  const metadataId = contentId || projectId;
  const contentDetails = useContentDetails(metadataId);
  const getStringMaxLength = useBreakpointValue([50, 70, 50, 65, 180]);
  const { colorMode } = useColorMode();

  const jsonContentData = contentDetails?.dynamicData?.dynamic_data;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={title ? title : "JSON Content View"}>
      <Wizard>
        <PanelStep>
          {jsonContentData ? (
            <Box className="ch-react-json-view">
              <ReactJson
                quotesOnKeys={false}
                displayDataTypes={false}
                collapseStringsAfterLength={getStringMaxLength}
                src={jsonContentData || {}}
                theme={colorMode === "dark" ? "ashes" : "rjv-default"}
              />
            </Box>
          ) : (
            <React.Fragment />
          )}
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
