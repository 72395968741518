import { createAsyncThunk } from "@reduxjs/toolkit";
import type { AuthToken } from "types/auth";
import {
  authenticateIdp,
  authenticateIdToken,
  authenticateWithCredentials,
  restoreSession,
  signupWithCredentials as signupWithCredentialsApi,
  authenticateWithAstrellaAccessToken,
  restoreAstrellaSession,
  signUpIdentityProviderUser,
} from "api/auth";
import { updateUser as updateUserThunk, verifyEmail as verifyEmailThunk } from "api/user";
import type { RootState } from "state/rootReducer";
import { downloadSubscriptionDetails } from "state/subscription/operations";

export const loginWithRefreshToken = createAsyncThunk("session/restore-session", async (previousToken: AuthToken) => {
  return await restoreSession(previousToken);
});

export const loginWithCredentials = createAsyncThunk(
  "session/authenticate",
  async (credentials: { username: string; password: string }) => {
    return await authenticateWithCredentials(credentials.username, credentials.password);
  }
);

export const signupWithCredentials = createAsyncThunk(
  "session/signup-with-credentials",
  async (form: {
    firstName: string;
    lastName: string;
    username: string;
    organization: string;
    factset: boolean;
    role: string;
    password: string;
    marketingOptIn: boolean;
  }) => {
    return await signupWithCredentialsApi(
      form.firstName,
      form.lastName,
      form.username,
      form.organization,
      form.factset,
      form.role,
      form.password,
      form.marketingOptIn
    );
  }
);

export const loginWithIdentityProvider = createAsyncThunk(
  "session/authenticate-idp",
  async (params: { accessToken: string; issuer: string; avatarUrl?: string }) => {
    return await authenticateIdp(params.accessToken, params.issuer, params.avatarUrl);
  }
);

export const signupWithIdentityProvider = createAsyncThunk(
  "session/signup-with-idp",
  async (params: { accessToken: string; issuer: string; avatarUrl?: string; marketingOptIn?: boolean }) => {
    return await signUpIdentityProviderUser(params.accessToken, params.issuer, params.avatarUrl, params.marketingOptIn);
  }
);

export const loginWithApple = createAsyncThunk("session/authenticate-apple", async (params: { idToken: string }) => {
  return await authenticateIdToken(params.idToken, "apple", process.env.REACT_APP_CHARLI_CLIENT_ID!);
});

export const loginWithAstrellaAccessToken = createAsyncThunk("session/authenticate-astrella", async (accessToken: string) => {
  return await authenticateWithAstrellaAccessToken(accessToken);
});

export const refreshAstrellaKeycloakToken = createAsyncThunk("session/refresh-astrella-token", async (previousToken: AuthToken) => {
  return await restoreAstrellaSession(previousToken);
});

export const refreshToken = createAsyncThunk("session/refresh-token", async (previousToken: AuthToken) => {
  return await restoreSession(previousToken);
});

export const updateUser = createAsyncThunk("session/update-user", async (profile: { firstName: string; lastName: string }) => {
  return await updateUserThunk(profile);
});

export const verifyEmail = createAsyncThunk("session/verify-email", async (verificationKey: string, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;

  if (state.session.user?.id && state.session.user?.email) {
    const isEmailVerified = await verifyEmailThunk({
      userId: state.session.user.id,
      email: state.session.user.email,
      verificationKey,
    });

    if (isEmailVerified) {
      thunkAPI.dispatch(downloadSubscriptionDetails(true));
    }

    return isEmailVerified;
  } else {
    console.warn(`Tried to update user but no user id was available`);

    return false;
  }
});
