import { Button, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { IntegrationsPanel } from "screens/landing/tabs/integrations/components/IntegrationsPanel";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";

interface Props {
  category: string;
  message?: string;
  onClose?: () => void;
}

export const InstallIntegrationButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  category,
  message,
  onClose,
}) => {
  const { setIsIntegrationCategory, onIntegrationsPanelOpen } = useAddToCharliContext();

  const primaryTextColor = useColorModeValue("gray.600", "gray.300");

  return (
    <>
      <Button
        cursor="pointer"
        fontWeight="normal"
        size="xs"
        textDecoration="underline"
        color={primaryTextColor}
        variant="link"
        onClick={() => {
          setIsIntegrationCategory(category);
          onIntegrationsPanelOpen();
          onClose && onClose();
        }}>
        {message ? message : `You need to connect a ${category} integration to enable this feature. Click here to connect one.`}
      </Button>
      <IntegrationsPanel />
    </>
  );
};
