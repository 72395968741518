import { Box, Button, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import type { Research, SearchHistory } from "types/collection";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { formatDateWithOutputFormat } from "../common/modal/formatters";
import { getEntityValue } from "screens/panels/research/utils";
import { ResearchHistoryItemQuery } from "./researchHistoryItemQuery";
import { ResearchContext } from "screens/panels/research/ResearchContext";
import { useButtonProps } from "hooks";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";
import { TagInput } from "screens/common/components/Tags/TagInput";

interface Props {
  research?: Research; // TODO(CHARLI-2339): remove this when SearchHistory is fully ready (i.e., all projects have SearchHistory).
  topics?: string[]; // TODO(CHARLI-2339): remove this when SearchHistory is fully ready (i.e., all projects have SearchHistory).
  searchHistory?: SearchHistory;
  showUseResearchButton?: boolean;
  collection?: { id: string; name: string };
}

export const ResearchHistoryItem: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  research,
  topics,
  searchHistory,
  showUseResearchButton,
  collection,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const researchTopics = getEntityValue<string[]>("topic", searchHistory?.entities) || searchHistory?.topics || topics;
  const { setSelectedSearchHistory, setIsAddToResearch } = useContext(ResearchContext);
  const secondaryButtonStyle = useButtonProps("sm", "secondary");
  const { onAddNewProjectPanelOpen, setShouldCreateNewCollection } = useAddToCharliContext();
  const { setRequestEntities } = useContext(ConversationContext);

  const dateComponent = (date: string) => {
    return (
      <Stack direction="row">
        <Text fontWeight="bold" fontSize="sm" color={textColor}>
          Run:
        </Text>
        <Text fontSize="sm" color={textColor}>
          {formatDateWithOutputFormat(new Date(date), "do MMM yyyy hh:mm")}
        </Text>
      </Stack>
    );
  };

  const handleOnClick = () => {
    if (collection)
      updateBatchRequestEntities([{ entity: "collection_id", value: collection.id, source: "collection-selector" }], setRequestEntities);
    setSelectedSearchHistory(searchHistory);
    setIsAddToResearch(true);
    setShouldCreateNewCollection(false);
    onAddNewProjectPanelOpen();
  };

  return (
    <Stack spacing="1rem">
      {researchTopics && (
        <>
          <Stack direction="row" justifyContent={"space-between"} width="100%" pb=".5rem">
            <SectionHeader title="Research Topics" titleStyle={{ marginBottom: "0" }} />
            {searchHistory?.run && dateComponent(searchHistory.run)}
          </Stack>
          <TagInput tagType="topic" tags={researchTopics} hideInput allowUpdate={false} allowDelete={false} />
        </>
      )}
      <Box>
        {searchHistory && (
          <Stack direction="row" justifyContent={"space-between"} width="100%">
            <SectionHeader title="Research Criteria"></SectionHeader>
            {!researchTopics && dateComponent(searchHistory.run)}
          </Stack>
        )}
        {!searchHistory && <SectionHeader title="Research Criteria" />}
        <ResearchHistoryItemQuery research={research} searchHistory={searchHistory} />
        {showUseResearchButton && (
          <Box justifyContent="flex-end" display="flex" pt="1rem" width="100%">
            <Button className="ch-research-history-item-btn" {...secondaryButtonStyle} onClick={handleOnClick}>
              Use in New Research
            </Button>
          </Box>
        )}
      </Box>
    </Stack>
  );
};
