import { Stack, Center } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import type { Highlights } from "types/collection";
import { AiOutlineDelete } from "react-icons/ai";
import { TiThumbsDown, TiThumbsUp } from "react-icons/ti";
import { ConversationContext } from "screens/thread/ConversationContext";
import { DeleteReasonSelector } from "screens/content/contentCanvas/cell/DeleteReasonSelector";
import type { RequestEntities } from "types/charliui";
import { sendMessage } from "state/websocket/operations";
import { useDispatch } from "react-redux";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import type { StackProps } from "@chakra-ui/react";
import { useCollectionKey, useProjectParams } from "hooks";

interface Props {
  highlightRecord: Highlights;
  direction?: StackProps["direction"];
  showDeleteButton?: boolean;
}

export const HighlightItemActions: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  highlightRecord,
  direction = "row",
  showDeleteButton = false,
}) => {
  const { projectId = "", contentId } = useProjectParams();
  const { pathname } = useLocation();
  const isResource = pathname.includes("resource");
  const [hasConfirmedDelete, setHasConfirmedDelete] = useState(false);
  const [isDeletingItem, setIsDeletingItem] = useState(false);
  const dispatch = useDispatch();
  const { conversationId } = useContext(ConversationContext);
  const collectionConversationId = useCollectionKey(projectId, "conversationId");
  const currentConversationId = conversationId || collectionConversationId || contentId;

  const onHandleDelete = (reason?: string) => {
    if (!hasConfirmedDelete) {
      setHasConfirmedDelete(true);
    } else {
      onDeleteHighlightItem(reason);
    }
  };

  const onDeleteHighlightItem = (reason?: string) => {
    if (!currentConversationId) {
      return;
    }
    setIsDeletingItem(true);
    const entities: RequestEntities = [];
    entities.push({ entity: "highlight_id", value: highlightRecord.id });
    reason && entities.push({ entity: "highlight_deletion_reason", value: reason });
    entities.push({ entity: "metadata_id", value: contentId || projectId || "" });
    !isResource && entities.push({ entity: "collection_id", value: projectId });

    dispatch(
      sendMessage({
        conversationId: currentConversationId,
        intent: "/delete_collection_metadata",
        entities: entities,
      })
    );
    setHasConfirmedDelete(false);
  };

  return (
    <Stack direction={direction} justifyContent="space-between">
      {!hasConfirmedDelete ? (
        <Center>
          <Stack spacing={".5rem"} align="center">
            <Stack direction="row">
              <SmallActionButton iconSize="1.4rem" tooltip={"Thumbs up"} iconTypeName={TiThumbsUp} />
              <SmallActionButton iconSize="1.4rem" tooltip={"Thumbs down"} iconTypeName={TiThumbsDown} />
            </Stack>
            {highlightRecord.id && showDeleteButton && (
              <>
                {isDeletingItem ? (
                  <TypingIndicator size="small" />
                ) : (
                  <SmallActionButton
                    iconSize="1.4rem"
                    classNames="ch-delete-record"
                    iconTypeName={AiOutlineDelete}
                    onClick={() => onHandleDelete()}
                    tooltip={"Remove item"}
                  />
                )}
              </>
            )}
          </Stack>
        </Center>
      ) : (
        <DeleteReasonSelector onHandleDelete={(reason) => onHandleDelete(reason)} setHasConfirmedDelete={setHasConfirmedDelete} />
      )}
    </Stack>
  );
};
