import { IconButton, Select, Stack, Tooltip } from "@chakra-ui/react";
import sortBy from "lodash/sortBy";
import orderBy from "lodash/orderBy";
import React, { useEffect, useMemo, useState } from "react";
import startCase from "lodash/startCase";
import { BiSortUp, BiSortDown } from "react-icons/bi";

export function camelCaseToWords(camelCaseString: string) {
  const spacedString = camelCaseString.replace(/([a-z])([A-Z])/g, "$1 $2");
  return startCase(spacedString);
}

export function useSortList<T>(
  list: T[],
  sortByKeys: (keyof T | `${string}.${string}`)[],
  defaultSortByKey?: keyof T,
  defaultOrderBy: "asc" | "desc" = "desc"
) {
  const [sortByKey, setSortByKey] = useState<keyof T | "all">(defaultSortByKey ?? "all");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">(defaultOrderBy);

  const getToggleGroupByDirection = (currentDirection: "asc" | "desc") => {
    return currentDirection === "asc" ? "desc" : "asc";
  };

  const selectSortComponent = useMemo(() => {
    return (
      <Stack direction="row" justifyContent={"flex-end"} alignItems={"center"} width={"100%"}>
        <Select
          size="sm"
          width={"100%"}
          value={sortByKey as string}
          onChange={(evt) => {
            setSortByKey(evt.target.value as keyof T | "all");
          }}>
          <option value={"all"}>Sort by</option>
          {sortBy(sortByKeys).map((key) => (
            <option key={key as string} value={key as string}>
              {typeof key === "string" && key.includes(".")
                ? camelCaseToWords(key.split(".")[1])
                : camelCaseToWords(key as unknown as string)}
            </option>
          ))}
        </Select>
        <Tooltip label={sortOrder === "desc" ? "Sort Ascending" : "Sort Descending"}>
          <IconButton
            aria-label="Group By Direction"
            size="sm"
            icon={sortOrder === "desc" ? <BiSortUp /> : <BiSortDown />}
            onClick={() => {
              setSortOrder(getToggleGroupByDirection(sortOrder));
            }}
          />
        </Tooltip>
      </Stack>
    );
  }, [sortByKey, sortByKeys, sortOrder]);

  const sortedList = useMemo(() => {
    if (sortByKey === "all") {
      return list;
    }

    return orderBy(list, [sortByKey], [sortOrder]);
  }, [list, sortByKey, sortOrder]);

  useEffect(() => {
    setSortOrder("asc");
  }, [sortByKey]);

  useEffect(() => {
    setSortOrder(defaultOrderBy);
  }, [sortByKey, defaultOrderBy]);

  return { sortedList, selectSortComponent };
}
