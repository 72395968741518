import { Button, Stack, Text, useColorModeValue, Checkbox } from "@chakra-ui/react";
import React, { useMemo, useCallback, useEffect, createContext, useContext, useRef, useState } from "react";
import { Select as FilterSelect } from "chakra-react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { useButtonProps, useEntitlements } from "hooks";
import { getRegisteredHandlers } from "api/registeredHandlers";
import type { RegisteredHandler } from "api/registeredHandlers/models/RegisteredHandler";

interface IProps {
  isLoading: boolean;
  hideClearButton?: boolean;
  totalTasks?: number;
  filteredTasks?: number;
  refreshTasks?: () => void;
}

interface FilterOption {
  value: string;
  label: string;
}

interface NormalizedHandlers {
  order: string[];
  byId: Record<string, RegisteredHandler>;
}

export enum TaskQueryParamFilter {
  statusIn = "status",
  registeredIdIn = "registeredId",
  hideRetriedTasksIn = "hideRetriedTasks",
}

export const TASK_STATUS = [
  { value: "queued", label: "Queued" },
  { value: "in_progress", label: "In Progress" },
  { value: "failed", label: "Failed" },
  { value: "clarification_needed", label: "Clarification Needed" },
  { value: "complete", label: "Complete" },
  { value: "awaiting_async_task", label: "Awaiting Async Task" },
];

const TasksFiltersContext = createContext({
  status: "" as string,
  setStatus: (_: string) => {
    return;
  },
  registeredId: "" as string,
  setRegisteredId: (_: string) => {
    return;
  },
  hideRetriedTasks: false,
  setHideRetriedTasks: (_: boolean) => {
    return;
  },
  handlers: [] as RegisteredHandler[],
  getTaskName: (id: string) => {
    return id;
  },
  getIntegrationName: (id: string) => {
    return id;
  },
});

export const useTaskFiltersContext = () => {
  return useContext(TasksFiltersContext);
};

export const TasksFiltersContextProvider = ({ children }) => {
  const location = useLocation();
  const mounted = useRef(false);
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const statusInParam = searchParams.get(TaskQueryParamFilter.statusIn);
  const registeredIdInParam = searchParams.get(TaskQueryParamFilter.registeredIdIn);
  const hideRetriedTasksInParam = searchParams.get(TaskQueryParamFilter.hideRetriedTasksIn);

  const [statusIn, setStatusIn] = useState<string>(statusInParam ?? "");
  const [registeredId, setRegisteredId] = useState<string>(registeredIdInParam ?? "");
  const [hideRetriedTasks, setHideRetriedTasks] = useState<boolean>(hideRetriedTasksInParam === "true");
  const [normalizedHandlers, setNormalizedHandlers] = useState<NormalizedHandlers>({
    order: [],
    byId: {},
  });

  const handlers = useMemo(() => {
    return normalizedHandlers.order.map((id) => normalizedHandlers.byId[id]);
  }, [normalizedHandlers]);

  const getTaskName = useCallback(
    (registeredId: string) => {
      return normalizedHandlers.byId[registeredId]?.name ?? registeredId;
    },
    [normalizedHandlers]
  );

  const getIntegrationName = useCallback(
    (registeredId: string) => {
      return normalizedHandlers.byId[registeredId]?.integration ?? registeredId;
    },
    [normalizedHandlers]
  );

  const { read_workflows_admin: hasReadWorkflowAdmin } = useEntitlements();

  useEffect(() => {
    mounted.current = true;

    const statusInParam = searchParams.get(TaskQueryParamFilter.statusIn);
    const registeredIdInParam = searchParams.get(TaskQueryParamFilter.registeredIdIn);
    const hideRetriedTasksInParam = searchParams.get(TaskQueryParamFilter.hideRetriedTasksIn);

    if (mounted.current) {
      setStatusIn(statusInParam ?? "");
      setRegisteredId(registeredIdInParam ?? "");
      setHideRetriedTasks(hideRetriedTasksInParam === "true");
    }

    return () => {
      mounted.current = false;
    };
  }, [searchParams]);

  useEffect(() => {
    if (!hasReadWorkflowAdmin) {
      return;
    }

    getRegisteredHandlers()
      .then((res) => {
        setNormalizedHandlers(
          res.reduce(
            (acc: NormalizedHandlers, handler) => ({
              order: [...acc.order, handler.id],
              byId: { ...acc.byId, [handler.id]: handler },
            }),
            { order: [], byId: {} }
          )
        );
      })
      .catch((err) => console.error(err));
  }, [hasReadWorkflowAdmin]);

  return (
    <TasksFiltersContext.Provider
      value={{
        status: statusIn,
        setStatus: setStatusIn,
        registeredId,
        setRegisteredId,
        hideRetriedTasks: hideRetriedTasks,
        setHideRetriedTasks: setHideRetriedTasks,
        handlers,
        getTaskName,
        getIntegrationName,
      }}>
      {children}
    </TasksFiltersContext.Provider>
  );
};

export const TasksFiltersPanel = (props: IProps) => {
  const { isLoading, hideClearButton, filteredTasks, totalTasks, refreshTasks } = props;
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const navigate = useNavigate();
  const headingTextColor = useColorModeValue("gray.600", "gray.100");
  const secondaryButtonProps = useButtonProps("sm", "secondary");

  const { status, registeredId, hideRetriedTasks, handlers } = useTaskFiltersContext();

  const selectedStatus = useMemo(() => {
    const label = TASK_STATUS.find((taskStatus) => taskStatus.value === status)?.label ?? status;
    return [{ label, value: status }];
  }, [status]);

  const selectedRegisteredId = useMemo(() => {
    const label = handlers.find((handler) => handler.id === registeredId)?.name ?? registeredId;
    return [{ label, value: registeredId }];
  }, [registeredId, handlers]);

  const handleHideRetriedChange = (checked: boolean) => {
    searchParams.set(TaskQueryParamFilter.hideRetriedTasksIn, String(checked));
    navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  const handleFilter = (option: FilterOption | null, type: TaskQueryParamFilter) => {
    if (option === null) {
      searchParams.delete(type);
    } else {
      searchParams.set(type, option.value);
    }

    navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  const clearFilters = useCallback(() => {
    navigate({ pathname: location.pathname, search: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack width="100%" justifyContent="space-between" pb="1rem" spacing="0.2rem">
      <Stack direction="row" width="100%" spacing="1rem" justifyContent={"space-between"} alignContent="flex-start">
        <Stack width="100%">
          <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
            Filter by task status
          </Text>
          <FilterSelect
            className="ch-multi-select"
            useBasicStyles
            size="sm"
            selectedOptionStyle="check"
            options={TASK_STATUS}
            onChange={(newStatus) => {
              handleFilter(newStatus as FilterOption, TaskQueryParamFilter.statusIn);
            }}
            value={selectedStatus}
            isDisabled={isLoading}
            isClearable
          />
        </Stack>
        <Stack width="100%">
          <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
            Filter by registered handler
          </Text>
          <FilterSelect
            className="ch-multi-select"
            useBasicStyles
            size="sm"
            selectedOptionStyle="check"
            options={handlers.map((handler) => ({ value: handler.id, label: handler.name }))}
            onChange={(newStatus) => {
              handleFilter(newStatus as FilterOption, TaskQueryParamFilter.registeredIdIn);
            }}
            value={selectedRegisteredId}
            isDisabled={isLoading}
            isClearable
          />
        </Stack>
      </Stack>
      <Stack direction="row">
        <Checkbox
          type="checkbox"
          id="ch-hide-retried-tasks"
          borderColor="gray.400"
          boxShadow="none"
          disabled={isLoading}
          isChecked={hideRetriedTasks}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleHideRetriedChange(e.target.checked)}>
          <Text fontSize="sm" lineHeight="2rem">
            Exclude retried tasks
          </Text>
        </Checkbox>
      </Stack>

      <Stack direction="row" width="100%" spacing="1rem" justifyContent={refreshTasks ? "space-between" : "flex-end"}>
        {refreshTasks && (
          <Stack direction="row">
            <Button width="100%" {...secondaryButtonProps} isDisabled={isLoading} onClick={() => refreshTasks()}>
              Refresh Tasks
            </Button>
          </Stack>
        )}
        {filteredTasks && totalTasks && (
          <Stack direction="row" spacing="1rem">
            <Text fontSize="xs" color={headingTextColor} lineHeight="2rem">
              {`Filtering ${filteredTasks} of ${totalTasks} tasks`}
            </Text>
            {!hideClearButton && (
              <Button {...secondaryButtonProps} isDisabled={isLoading} onClick={() => clearFilters()}>
                Clear Filters
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
