import { prependProxyBaseUrl, request } from "api/request";
import { GetRegisteredHandlers } from "./models/GetRegisteredHandlers";

const REGISTERED_HANDLERS_ENDPOINT = prependProxyBaseUrl("/api/registered-handlers");

export async function getRegisteredHandlers(filter?: string): Promise<GetRegisteredHandlers> {
  const response = await request()
    .url(`${REGISTERED_HANDLERS_ENDPOINT}`)
    .query({ ...(filter && { filter }) })
    .get()
    .json();
  const validate = GetRegisteredHandlers.validate(response);

  if (validate.success) {
    return validate.value;
  } else {
    console.error(validate);
    throw new Error(validate.message);
  }
}
