import React from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Icon } from "@chakra-ui/react";
import type { IconProps } from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MdOutlineCircle, MdOutlineQuestionMark, MdOutlineRefresh } from "react-icons/md";

interface Props {
  status: string;
  boxSize?: string;
  altCheckmark?: boolean;
}

const iconProps: IconProps = {
  boxSize: "1rem",
  color: "white",
  fontWeight: "bold",
  p: "2px",
  borderRadius: "full",
  border: "none",
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "succeeded":
    case "complete":
      return "green";
    case "queued":
    case "in_progress":
    case "denied_intent_confirmation":
    case "failed_checkstop":
    case "awaiting_async_task": {
      return "orange";
    }
    case "failed":
    case "error":
      return "red";
    case "clarification_needed":
      return "blue";
    default:
      return "gray";
  }
};

export const WorkflowStatusIcon = ({ status, boxSize, altCheckmark }: Props) => {
  switch (status) {
    case "complete":
      return (
        <>
          {altCheckmark ? (
            <Icon
              as={IoMdCheckmarkCircleOutline}
              {...iconProps}
              color={`${getStatusColor(status)}.500`}
              bgColor={"white"}
              boxSize={boxSize}
              p="0"
            />
          ) : (
            <Icon as={FiCheck} bgColor={`${getStatusColor(status)}.500`} {...iconProps} boxSize={boxSize} />
          )}
        </>
      );
    case "queued":
      return <Icon as={BiDotsHorizontalRounded} bgColor={`${getStatusColor(status)}.400`} {...iconProps} boxSize={boxSize} />;
    case "in_progress":
      return <Icon as={BiDotsHorizontalRounded} bgColor={`${getStatusColor(status)}.400`} {...iconProps} boxSize={boxSize} />;
    case "clarification_needed":
      return <Icon as={MdOutlineQuestionMark} bgColor={`${getStatusColor(status)}.500`} {...iconProps} boxSize={boxSize} />;
    case "failed":
    case "error":
    case "denied_intent_confirmation":
      return <Icon as={MdOutlineRefresh} bgColor={`${getStatusColor(status)}.400`} {...iconProps} boxSize={boxSize} />;
    case "failed_checkstop":
      return <Icon as={MdOutlineRefresh} bgColor={`${getStatusColor(status)}.400`} {...iconProps} boxSize={boxSize} />;
    default:
      return <Icon as={MdOutlineCircle} bgColor={`${getStatusColor(status)}.400`} {...iconProps} boxSize={boxSize} />;
  }
};
