import React, { useCallback, useState, useEffect, useMemo, useRef } from "react";
import { PanelView } from "screens/panels/components/PanelView";
import { PanelStep } from "screens/panels/components/PanelStep";
import { Wizard } from "react-use-wizard";
import { TabTitle } from "screens/common/components/TabTitle";
import type { ChildWorkflowStep, ClarificationDetail } from "types/workflows/workflow";
import {
  Badge,
  Box,
  Center,
  Code,
  Divider,
  List,
  ListItem,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useOutsideClick,
} from "@chakra-ui/react";
import { formatDate } from "screens/common/modal/formatters";
import humanizeDuration from "humanize-duration";
import flatten from "lodash/flatten";
import { getStatusIcon } from "screens/common/components/WorkflowSummary/v2/ChildWorkflowSummary";
import { ChevronRightIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { useEntitlements, useTileProps, useUserProfile } from "hooks";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import { Number, String, Array, Unknown } from "runtypes";
import { getChildWorkflowSteps } from "api/workflows";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { WorkflowPayloadPanel } from "./WorkflowPayloadPanel";
import { useLastObserver } from "hooks/useLastObserver";
import { Popover } from "react-tiny-popover";

const compareTasks = (task1: ChildWorkflowStep, task2: ChildWorkflowStep) => {
  const task1Date = task1.type === "clarification" ? task1.completionDate : task1.creationDate;
  const task2Date = task2.type === "clarification" ? task2.completionDate : task2.creationDate;

  return new Date(task1Date).getTime() - new Date(task2Date).getTime();
};

const formatEntityValue = (value: unknown): string => {
  if (String.guard(value)) {
    return value;
  } else if (Number.guard(value)) {
    return value.toString();
  } else if (Array(String).guard(value)) {
    return `[${value.join(", ")}]`;
  } else if (Array(Number).guard(value)) {
    return `[${value.map((item) => item.toString()).join(", ")}]`;
  } else if (Array(Unknown).guard(value)) {
    return `Array of ${value.length} object${value.length === 1 ? "" : "s"}`;
  } else {
    return `Object value`;
  }
};

const getClarificationText = (clarificationTask: ClarificationDetail) => {
  if (clarificationTask.entity.entity === "auth_granted") {
    if (Array(String).guard(clarificationTask.entity.value)) {
      return `${clarificationTask.entity.value[1]}`;
    } else {
      return "User input something wrong";
    }
  } else {
    return formatEntityValue(clarificationTask.entity.value);
  }
};

const outputFormatWithSeconds = "do MMM yyyy 'at' h:mm:ss aa";

export const StepsPanelInner = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useUserProfile();
  const textColor = useColorModeValue("secondary.default", "gray300");
  const codeColor = useColorModeValue("blackAlpha", "white");
  const codeBgColor = useColorModeValue("gray.100", "#191f23");
  const errorBgColor = useColorModeValue("#FFEEF1", "gray.700");
  const errorStatusColor = useColorModeValue("#667E87", "#FFEEF1");
  const errorMessageColor = useColorModeValue("#212B34", "#FFEEF1");
  const [localSteps, setLocalSteps] = useState<ChildWorkflowStep[]>([]);
  const [popoverIndex, setPopoverIndex] = useState(-1);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const popRef = useRef<HTMLDivElement | null>(null);
  const commonTileProps = useTileProps();

  const { show_detailed_workflow_summary: showWorkflowDetails, read_workflows_payloads_admin: showWorkflowPayloadAdminLink } =
    useEntitlements();

  const {
    setTaskId,
    onWorkflowPayloadPanelOpen,
    isWorkflow: workflow,
    isStepsPanelOpen: isOpen,
    onStepsPanelClose: onClose,
    isChildWorkflow: childWorkflowId,
    setChildWorkflow,
    setSteps,
    steps,
    setPayloadType,
  } = useWorkflowContext();

  const sortedSteps = useMemo(() => [...steps].sort(compareTasks), [steps]);

  const lastObserverCallback = useCallback(() => {
    setLocalSteps((prev) => sortedSteps.slice(0, prev.length + 10));
  }, [sortedSteps]);

  const lastStepObserver = useLastObserver(lastObserverCallback);

  const handleViewTaskClick = useCallback(
    (taskId: string) => {
      setTaskId(taskId);
      setPayloadType("json");
      onWorkflowPayloadPanelOpen();
    },
    [onWorkflowPayloadPanelOpen, setPayloadType, setTaskId]
  );

  const handleViewErrorClick = useCallback(
    (taskId: string) => {
      setTaskId(taskId);
      setPayloadType("error");
      onWorkflowPayloadPanelOpen();
    },
    [onWorkflowPayloadPanelOpen, setPayloadType, setTaskId]
  );
  const handleStepsClose = () => {
    setChildWorkflow("");
    setSteps([]);
    onClose();
  };

  useOutsideClick({
    ref: popRef!,
    handler: () => {
      setPopoverIndex(-1);
    },
  });

  const renderStep = useCallback(
    (task: ChildWorkflowStep, taskIndex: number, tasksLength: number) => {
      let taskElementKey = "";
      const taskElement = (() => {
        if (task.type === "task") {
          taskElementKey = `child-workflow-task-${task.registeredTaskId}-${task.startDate}`;
          const requires = [
            ...(task.requires ?? []).map((item) => `${item}!`),
            ...(task.mustIncludeEntities ?? []).map((item) => `[${item}!]`),
            ...(task.optionalRequires ?? []),
            ...(task.shouldIncludeEntities ?? []).map((item) => `[${item}]`),
          ];

          const maybeFormattedStartDate = task.startDate ? [formatDate(new Date(task.startDate), outputFormatWithSeconds)] : [];
          const maybeFormattedDuration =
            task.startDate && task.completionDate
              ? [
                  humanizeDuration(Date.parse(task.completionDate) - Date.parse(task.startDate), {
                    maxDecimalPoints: 2,
                  }),
                ]
              : [];
          const maybeFormattedDurationWithStartDate = flatten([maybeFormattedStartDate, maybeFormattedDuration]).join(" - ");

          return (
            <>
              <Divider borderColor="gray.200" />
              <ListItem px="0!important" display="flex" alignItems="center" mt=".5rem" fontSize="xs">
                <Box display="flex" flexDirection="column" width="100%">
                  <Stack direction="row" justifyContent="space-between">
                    <Center ref={mainRef}>
                      <Stack direction="row" spacing=".5rem">
                        <Tooltip label={task.status}>
                          <Box>{getStatusIcon(task.status)}</Box>
                        </Tooltip>
                        <Badge variant={"outline"} colorScheme="gray">
                          {taskIndex + 1}
                        </Badge>
                        <Text fontSize="xs" as="b">
                          {task.name}
                        </Text>
                        {showWorkflowDetails && task.executionContext && task.executionContext.length > 0 && (
                          <Box>
                            <Popover
                              ref={popRef}
                              parentElement={mainRef?.current || undefined}
                              isOpen={popoverIndex === taskIndex}
                              positions={["top", "left"]}
                              padding={10}
                              reposition={false}
                              onClickOutside={() => setPopoverIndex(-1)}
                              content={() => (
                                <Box {...commonTileProps}>
                                  <Text fontWeight={"semibold"}>Why did this task run?</Text>
                                  {task.executionContext &&
                                    task.executionContext.map((reason, index) => (
                                      <Text key={`task-execution-context-${task.id}-${index}`}>
                                        {index > 0 ? "∟ " : ""}
                                        {reason}
                                      </Text>
                                    ))}
                                </Box>
                              )}>
                              <InfoOutlineIcon
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setPopoverIndex(popoverIndex === -1 ? taskIndex : -1);
                                }}
                                cursor="pointer"
                                height="1.5em"
                              />
                            </Popover>
                          </Box>
                        )}
                      </Stack>
                    </Center>
                    {(user.id === workflow?.userId || showWorkflowPayloadAdminLink) && (
                      <Stack
                        key={`workflow-summary-item-${task.id}`}
                        cursor="pointer"
                        direction="row"
                        onClick={() => handleViewTaskClick(task.id)}>
                        <Center>
                          <Text fontSize="xs">View Task Payload</Text>
                          <ChevronRightIcon color={textColor} boxSize="1.5rem" />
                        </Center>
                      </Stack>
                    )}
                  </Stack>
                  {showWorkflowDetails && (
                    <Box pl="1.3rem" py=".5rem">
                      <Box>{task.status === "in_progress" && <Text fontSize="xs" as="em">{`${task.status}`}</Text>}</Box>

                      {task.intent && <Text fontSize="xs" as="em">{`/${task.intent}`}</Text>}
                      {maybeFormattedDurationWithStartDate && <Text fontSize="xs">{maybeFormattedDurationWithStartDate}</Text>}
                      {requires.length > 0 && (
                        <Text fontSize="xs">
                          Requires:{" "}
                          {requires.map((require, i) => (
                            <React.Fragment key={`workflow-summary-task-requires-${task.registeredTaskId}-${task.startDate}-${i}`}>
                              {i > 0 && ", "}
                              <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                                {require}
                              </Code>
                            </React.Fragment>
                          ))}
                        </Text>
                      )}
                      {task.produces && task.produces.length > 0 && (
                        <Text fontSize="xs">
                          Produces:{" "}
                          {task.produces.map((produce, i) => (
                            <React.Fragment key={`workflow-summary-task-produces-${task.registeredTaskId}-${task.startDate}-${i}`}>
                              {i > 0 && ", "}
                              <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                                {produce}
                              </Code>
                            </React.Fragment>
                          ))}
                        </Text>
                      )}
                      {task.postExecutionIntents && task.postExecutionIntents.length > 0 && (
                        <Text fontSize="xs">
                          Post Execution Intents:{" "}
                          {task.postExecutionIntents.map((intent, i) => (
                            <React.Fragment key={`workflow-summary-task-pei-${task.registeredTaskId}-${task.startDate}-${i}`}>
                              {i > 0 && ", "}
                              <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                                {intent}
                              </Code>
                            </React.Fragment>
                          ))}
                        </Text>
                      )}
                      {task.error && (
                        <Box
                          className="ch-workflow-task-error"
                          data-task-name={task.name}
                          data-task-error-status={task.error.status}
                          data-task-error-message={task.error.message}
                          marginTop={"10px"}
                          backgroundColor={errorBgColor}
                          borderRadius="12px"
                          overflow="hidden"
                          position="relative"
                          borderWidth="1px">
                          <Stack direction="row" p="1rem" align="center">
                            <Stack align="flex-start">
                              <>
                                <Text fontSize="xs" color={errorStatusColor}>
                                  {task.error.status}
                                </Text>
                                <Text fontSize="xs" color={errorMessageColor} fontWeight="400" mt="0!important">
                                  {task.error.message}
                                </Text>
                                {task.error.details && (
                                  <Stack
                                    key={`workflow-summary-item-${task.id}`}
                                    cursor="pointer"
                                    direction="row"
                                    onClick={() => workflow && handleViewErrorClick(task.id)}>
                                    <Center>
                                      <Text fontSize="xs">View Error Details</Text>
                                      <ChevronRightIcon color={textColor} boxSize="1.5rem" />
                                    </Center>
                                  </Stack>
                                )}
                              </>
                            </Stack>
                          </Stack>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </ListItem>
            </>
          );
        } else if (task.type === "clarification") {
          taskElementKey = `child-workflow-clarification-${task.completionDate}`;

          return (
            <>
              <Divider borderColor="primary.lightGray" />
              <ListItem px="0!important" display="flex" alignItems="center" p={2} margin="2px" fontSize="xs">
                <Box display="flex" flexDirection="column">
                  <Stack direction="row" alignItems={"flex-start"} py=".5rem">
                    <Box pt="3px">{getStatusIcon("complete")}</Box>
                    <Badge variant={"solid"} colorScheme="gray">
                      {taskIndex + 1}
                    </Badge>
                    <Text fontSize="xs" as="b">
                      Clarification Provided
                    </Text>
                  </Stack>
                  <Text paddingLeft="1.3rem" fontSize="xs">
                    {task.entity.entity}:{" "}
                    <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                      {getClarificationText(task)}
                    </Code>
                  </Text>
                </Box>
              </ListItem>
            </>
          );
        } else if (task.type === "event") {
          taskElementKey = `child-workflow-clarification-${task.creationDate}`;

          return (
            <>
              <Divider borderColor="primary.lightGray" />
              <ListItem px="0!important" display="flex" alignItems="center" p={2} margin="2px" fontSize="xs">
                <Box display="flex" flexDirection="column">
                  <Stack direction="row" alignItems={"flex-start"} py=".5rem">
                    <Box pt="3px">{getStatusIcon("complete")}</Box>
                    <Badge variant={"outline"} colorScheme="gray">
                      {taskIndex + 1}
                    </Badge>
                    <Text fontSize="xs" as="b">
                      {task.title}
                    </Text>
                  </Stack>
                  <Text paddingLeft="1.3rem" fontSize="xs">
                    <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                      {task.description}
                    </Code>
                  </Text>
                </Box>
              </ListItem>
            </>
          );
        } else {
          // prompt
          taskElementKey = `child-workflow-prompt-${task.creationDate}`;
          return (
            <>
              <Divider borderColor="primary.lightGray" />
              <ListItem px="0!important" display="flex" alignItems="center" p={2} margin="2px" fontSize="xs">
                <Box display="flex" flexDirection="column">
                  <Stack direction="row" alignItems={"flex-start"} py=".5rem">
                    <Box pt="3px">{getStatusIcon("complete")}</Box>
                    <Badge variant={"solid"} colorScheme="gray">
                      {taskIndex + 1}
                    </Badge>
                    <Text fontSize="xs" as="b">
                      Input Requested
                    </Text>
                    {showWorkflowDetails && task.executionContext && task.executionContext.length > 0 && (
                      <Box ref={mainRef}>
                        <Popover
                          isOpen={popoverIndex === taskIndex}
                          positions={["bottom"]}
                          align="start"
                          transformMode="relative"
                          onClickOutside={() => setPopoverIndex(-1)}
                          content={() => (
                            <Box {...commonTileProps}>
                              <Text fontWeight={"semibold"}>Why was this prompt triggered?</Text>
                              {task.executionContext &&
                                task.executionContext.map((reason, index) => (
                                  <Text key={`task-execution-context-${task.creationDate}-${index}`}>
                                    {index > 0 ? "∟ " : ""}
                                    {reason}
                                  </Text>
                                ))}
                            </Box>
                          )}>
                          <InfoOutlineIcon onClick={() => setPopoverIndex(taskIndex)} cursor="pointer" height="1.5em" />
                        </Popover>
                      </Box>
                    )}
                  </Stack>
                  <Text paddingLeft="1.3rem" fontSize="xs">
                    {task.title}
                  </Text>
                </Box>
              </ListItem>
            </>
          );
        }
      })();

      return (
        <Box {...(tasksLength - 1 === taskIndex && { ref: lastStepObserver })} key={taskElementKey}>
          {taskElement}
        </Box>
      );
    },
    [
      lastStepObserver,
      showWorkflowDetails,
      popoverIndex,
      user.id,
      workflow,
      showWorkflowPayloadAdminLink,
      textColor,
      errorBgColor,
      errorStatusColor,
      errorMessageColor,
      commonTileProps,
      handleViewTaskClick,
      codeBgColor,
      codeColor,
      handleViewErrorClick,
    ]
  );

  const fetchSteps = useCallback(() => {
    const workflowId = workflow?.id;

    if (workflowId && childWorkflowId) {
      setIsLoading(true);
      getChildWorkflowSteps(workflowId, childWorkflowId)
        .then((steps) => setSteps(steps))
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [childWorkflowId, setSteps, workflow?.id]);

  useEffect(() => {
    fetchSteps();
  }, [fetchSteps]);

  useEffect(() => {
    setLocalSteps(sortedSteps.slice(0, 10));
  }, [sortedSteps]);
  return (
    <>
      <PanelView isOpen={isOpen} onClose={handleStepsClose} panelTitle={`Steps Detail`}>
        <Wizard>
          <PanelStep>
            <TabTitle title={`Steps Detail`} />
            {isLoading ? (
              <Box mt={"1rem"} mb={"1rem"}>
                <TypingIndicator size="small" />
              </Box>
            ) : (
              <List>{localSteps.map((task, taskIndex) => renderStep(task, taskIndex, localSteps.length))}</List>
            )}
          </PanelStep>
        </Wizard>
      </PanelView>

      <WorkflowPayloadPanel />
    </>
  );
};

export const StepsPanel = () => {
  const { isStepsPanelOpen: isOpen } = useWorkflowContext();

  if (!isOpen) {
    return null;
  }

  return <StepsPanelInner />;
};
