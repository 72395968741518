import { useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { downloadBillingPlans } from "state/billingPlans/operations";
import type { RootState } from "state/rootReducer";
import type { Plan } from "types/billingPlans/Plan";
import type { Entitlement } from "types/entitlements";
import { useEntitlements } from "./useEntitlements";

export function useBillingPlans(): Plan[] {
  const plans = useSelector((state: RootState) => {
    return (state.billingPlans.planCodes || []).map((code) => state.billingPlans.plans[code]);
  }, shallowEqual);

  const dispatch = useDispatch();
  const entitlements = useEntitlements();
  const { manage_billing_plans_read: hasBillingPlansRead } = entitlements;

  useEffect(() => {
    if (!hasBillingPlansRead) {
      return;
    }

    dispatch(downloadBillingPlans());
  }, [dispatch, hasBillingPlansRead]);

  return plans;
}

export function useBillingPlanCodes(excludedCodes?: string[]): string[] {
  return useSelector((state: RootState) => {
    return (state.billingPlans.planCodes || []).filter((code) => !excludedCodes?.includes(code));
  }, shallowEqual);
}

export function useBillingPlan(code: string | null): Plan | undefined {
  return useSelector((state: RootState) => (code ? state.billingPlans.plans[code] : undefined), shallowEqual);
}

export function useIsBillingPlanLoading(): boolean {
  return useSelector((root: RootState) => root.billingPlans.loading);
}

export function useBillingPlanEntitlements(planCode: string | null): { entitlements: Entitlement[]; isLoading: boolean } {
  return useSelector((root: RootState) => ({
    entitlements: !planCode ? [] : root.billingPlans.planEntitlements[planCode] ?? [],
    isLoading: root.billingPlans.loadingEntitlements,
  }));
}
