import React from "react";
import { Box } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useOrganization } from "hooks";
import { OrganizationLogs } from "./OrganizationLogs";

interface IProps {
  id: string | null;
  onClose: () => void;
}

export const OrganizationLogsPanel = (props: IProps) => {
  const { id, onClose } = props;
  const organization = useOrganization(id, { refreshNetwork: false });

  return (
    <PanelView isOpen onClose={onClose} panelTitle={`Organization Logs: ${organization?.name}`}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem">
            <OrganizationLogs id={id} />
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
