import React from "react";
import { IntegrationURN } from "types/integration";
import { request } from "api/request";
import { Button, useBreakpointValue } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { FiDownload } from "react-icons/fi";

interface Props {
  downloadUrl: string;
  integrationUrn: string;
  resourceName: string;
  isDisabled?: boolean;
  menuLabel?: string;
  showAsIconButton?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const ProjectReportPanelDownloadButton = ({
  downloadUrl,
  integrationUrn,
  resourceName,
  isDisabled = false,
  menuLabel = "Download Report",
  className = "ch-download-report",
  style,
  showAsIconButton,
}: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const buttonSize = isMobile ? "xs" : "sm";
  const secondaryButtonStyle = useButtonProps(buttonSize, "secondary");

  const onHandleDownload = () => {
    if (!downloadUrl) return;
    if (integrationUrn === IntegrationURN.charliCloudStorage) {
      request()
        .url(downloadUrl)
        .get()
        .blob((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", resourceName || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.open(downloadUrl, "_blank");
    }
  };

  return (
    <>
      {showAsIconButton ? (
        <SmallActionButton
          iconTypeName={FiDownload}
          onClick={() => {
            onHandleDownload();
          }}
          tooltip={"Download Report"}
        />
      ) : (
        <Button
          {...secondaryButtonStyle}
          style={style}
          className={className}
          isDisabled={isDisabled}
          onClick={(event) => {
            event.stopPropagation();
            onHandleDownload();
          }}>
          {menuLabel}
        </Button>
      )}
    </>
  );
};
