import type { TrackableProperties } from "types/analytics";

const PATH = "path";

export const track = (eventName: string, properties: TrackableProperties) => {
  if (window.analytics) {
    window.analytics.track(eventName, properties);
  }
};

export const identify = (userId: string, properties: TrackableProperties) => {
  if (window.analytics) {
    window.analytics.identify(userId, properties);
  }
};

export const reset = () => {
  if (window.analytics) {
    window.analytics.reset();
  }

  setTimeout(() => {
    if (window.mixpanel) {
      window.mixpanel.reset();
    }
  }, 5);
};

export const page = (pagePath: string, properties: TrackableProperties) => {
  if (properties) {
    properties[PATH] = pagePath;
  } else {
    properties = {};
    properties[PATH] = pagePath;
  }

  if (window.analytics) {
    window.analytics.page(properties);
  }
};
