import { FormControl, Input, Select, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent, SetStateAction } from "react";
import { useState } from "react";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import type { SearchHistory } from "../../../types/collection";
import { ResearchContext } from "./ResearchContext";
import { ContentFilterContext } from "screens/content";
import { iso31661 } from "iso-3166";
import { ResearchTopicsInput } from "./ResearchTopicsInput";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import { getEntityValue } from "./utils";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";
import { IntegrationSelector } from "screens/landing/components/IntegrationSelector";
import { useUserPreferences } from "hooks";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";

interface Props {
  showResearchTopics?: boolean;
  disableInputs?: boolean;
  copyResearchHistory?: boolean;
}

export const ResearchSearchInputs: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  showResearchTopics,
  disableInputs,
  copyResearchHistory,
}) => {
  const inputBgColor = useColorModeValue("white", "#191f23");
  const linkColor = useColorModeValue("gray.400", "gray.700");
  const { requestEntities, setRequestEntities } = useContext(ConversationContext);
  const { selectedSearchHistory } = useContext(ResearchContext);
  const { selectedTags } = useContext(ContentFilterContext);
  const { shouldCreateNewCollection } = useAddToCharliContext();
  const dispatch = useDispatch();
  const { userPreferences } = useUserPreferences();
  const userPreferenceUrn = userPreferences["research_country_code"];
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputQuery, setInputQuery] = useState(getRequestValue("query", requestEntities));
  const [region, setRegion] = useState(getRequestValue("region", requestEntities) || String(userPreferenceUrn) || "US");
  const [searchTimeframeType, setSearchTimeframeType] = useState(getRequestValue("timeframe", requestEntities).slice(0, 1) || "m");
  const [searchTimeframeLength, setSearchTimeframeLength] = useState(getRequestValue("timeframe", requestEntities).slice(1) || "6");
  const [searchSize, setSearchSize] = useState(getRequestValue("size", requestEntities) || "20");

  const updateRequestEntity = (entityName: string, entityValue: string) => {
    updateBatchRequestEntities([{ entity: entityName, value: entityValue, source: "research-inputs" }], setRequestEntities);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const regionOptions = useMemo(() => {
    return Object.entries(iso31661)
      .sort((a, b) => {
        return a[1].name.localeCompare(b[1].name);
      })
      .map(([code, country]) => {
        return (
          <option key={code} value={country.alpha2}>
            {country.name}
          </option>
        );
      });
  }, []);

  useEffect(() => {
    if (Number(searchSize) > 70) {
      setSearchSize("70");
    }
  }, [searchSize]);

  const handleUpdateRegion = (value: string) => {
    dispatch(
      updateTypedUserPreference({
        preferenceKey: "research_country_code",
        value: value,
      })
    );
    setRegion(value);
    updateRequestEntity("region", value as string);
  };

  useEffect(() => {
    if (region) {
      updateRequestEntity("region", region as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  useEffect(() => {
    if (searchSize) {
      updateRequestEntity("size", searchSize as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSize]);

  useEffect(() => {
    if (searchTimeframeLength && searchTimeframeType) {
      const timeframe = `${searchTimeframeType}${searchTimeframeLength}`;
      updateRequestEntity("timeframe", timeframe as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTimeframeLength, searchTimeframeType]);

  const getLatestQuery = (searchHistory: SearchHistory): string => {
    const query = getEntityValue<string>("query", searchHistory.entities) ?? searchHistory.query ?? "";
    return query === "" ? query : query.trim();
  };

  const handleUpdateQuery = (value: string) => {
    setInputQuery(value);
    updateRequestEntity("query", value as string);
  };

  useEffect(() => {
    if (shouldCreateNewCollection) {
      updateRequestEntity("collection_name", inputQuery as string);
      updateRequestEntity("name", inputQuery as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCreateNewCollection, inputQuery]);

  const copyFromResearchHistory = () => {
    if (selectedSearchHistory) {
      const selectedTagsString = selectedTags.map((tag) => `"${tag}"`).join(" ") || "";
      const latestQuery = getLatestQuery(selectedSearchHistory);
      const queryString = `${inputQuery} ${latestQuery} ${selectedTagsString}`;
      if (inputQuery.trim() !== queryString.trim()) {
        handleUpdateQuery(queryString.trim());
      }
      const region = getEntityValue<string>("region", selectedSearchHistory.entities) ?? selectedSearchHistory.region;
      handleUpdateRegion(region);
      const timeframe = getEntityValue<string>("timeframe", selectedSearchHistory.entities) ?? selectedSearchHistory.timeframe;
      if (timeframe) {
        setSearchTimeframeType(timeframe.slice(0, 1));
        setSearchTimeframeLength(timeframe.slice(1));
      }
      const size = getEntityValue<string>("size", selectedSearchHistory.entities) ?? selectedSearchHistory.size;
      setSearchSize(size);
    }
  };

  useEffect(() => {
    copyResearchHistory && copyFromResearchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyResearchHistory]);

  return (
    <Stack spacing="3rem">
      <FormControl isDisabled={disableInputs}>
        <SectionHeader title={`Search Criteria`} />
        <Input
          ref={inputRef}
          margin="0!important"
          background={inputBgColor}
          type="text"
          id="research-input-query"
          placeholder="Enter the keywords that will be used to gather content from the web"
          size="sm"
          rounded="10px"
          defaultValue={inputQuery}
          onBlur={(e) => {
            e.stopPropagation();
            handleUpdateQuery(e.currentTarget.value);
          }}
        />
      </FormControl>
      <Stack direction="row" justifyContent="space-between" spacing="4rem">
        <FormControl>
          <SectionHeader title="Search Integration" />
          <IntegrationSelector
            isDisabled={disableInputs}
            category="Search"
            categoryKey="integrations_search_provider"
            integrationUrn="activityhandler:v2:external:charli.ai:google_search"
          />
        </FormControl>
        <FormControl isDisabled={disableInputs}>
          <SectionHeader title="Search Origin" />
          <Select
            width={"100%"}
            id="research-country-code"
            size="sm"
            borderRadius="md"
            value={region}
            onChange={(e) => {
              handleUpdateRegion(e.target.value);
            }}>
            {regionOptions && regionOptions}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing="4rem">
        <FormControl isDisabled={disableInputs}>
          <SectionHeader title="Search Timeframe" />
          <Stack direction="row">
            <Input
              textAlign="center"
              maxWidth="3rem"
              background={inputBgColor}
              type="number"
              id="research-timeframe-length"
              placeholder="Length"
              size="sm"
              value={searchTimeframeLength}
              onChange={(e) => {
                setSearchTimeframeLength(e.target.value);
              }}
            />
            <Select
              id="timeframe-select"
              borderRadius="md"
              background={inputBgColor}
              size="sm"
              value={searchTimeframeType}
              onChange={(e: { target: { value: SetStateAction<string> } }) => {
                setSearchTimeframeType(e.target.value);
              }}>
              <option value="d">Days</option>
              <option value="w">Weeks</option>
              <option value="m">Months</option>
              <option value="y">Years</option>
            </Select>
          </Stack>
        </FormControl>
        <FormControl isDisabled={disableInputs}>
          <Stack direction="row" justifyContent="space-between">
            <SectionHeader title="Number of Sources" />
            <Text color={linkColor} pt="1rem" pr=".5rem" height="2rem" fontSize="xs">
              Max 70
            </Text>
          </Stack>
          <Input
            type="text"
            background={inputBgColor}
            id="research-sources-number"
            placeholder="How many sources do you want to gather?"
            size="sm"
            rounded="10px"
            value={searchSize}
            onChange={(e: { target: { value: SetStateAction<string> } }) => {
              setSearchSize(e.target.value);
            }}
          />
        </FormControl>
      </Stack>
      {showResearchTopics && (
        <FormControl isDisabled={disableInputs}>
          <SectionHeader title="Search Topics" />
          <ResearchTopicsInput shouldCopyResearchHistory />
        </FormControl>
      )}
    </Stack>
  );
};
