import React, { useState } from "react";
import type { FunctionComponent } from "react";
import { Stack, Icon, useColorModeValue, Text, Box, Center } from "@chakra-ui/react";

import { MdCancel } from "react-icons/md";
import { useConfigMap, useGetViewConfig } from "hooks";
import { useLocation } from "react-router-dom";

interface Props {
  onHandleDelete: (reason: string) => void;
  setHasConfirmedDelete?: (hasConfirmedDelete: boolean) => void;
}

export const DeleteReasonSelector: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  onHandleDelete,
  setHasConfirmedDelete,
}) => {
  const tileBorderColor = useColorModeValue("gray.400", "gray.600");
  const reasonBorderColor = useColorModeValue("gray.100", "gray.700");
  const reasonSelectedBorderColor = useColorModeValue("gray.200", "gray.600");
  const configMap = useConfigMap();
  const { pathname } = useLocation();
  const isProject = pathname.includes("project");
  const route = pathname.split("/")[isProject ? 2 : 1];
  const defaultReasons = ["duplicate", "irrelevant", "untrusted source", "other"];
  const reasons = useGetViewConfig("deleteReasons", route, configMap) || defaultReasons;
  const [selectedReason, setSelectedReason] = useState("");

  return (
    <Stack justifyContent="flex-end" width="100%" px=".5rem">
      {setHasConfirmedDelete && (
        <Icon
          cursor={"pointer"}
          as={MdCancel}
          color={tileBorderColor}
          boxSize="1rem"
          mt="2px"
          onClick={(event) => {
            setHasConfirmedDelete(false);
            event.stopPropagation();
          }}
        />
      )}
      {reasons.map((reason, index) => (
        <Box
          p="5px"
          borderRadius={"3px"}
          key={index}
          className={`ch-delete-${reason.replace(" ", "-")}`}
          bgColor={selectedReason === reason ? reasonSelectedBorderColor : reasonBorderColor}
          cursor="pointer"
          onClick={(event) => {
            onHandleDelete(reason);
            setSelectedReason(reason);
            event.stopPropagation();
          }}>
          <Center>
            <Text fontSize={"xs"} isTruncated>
              {reason}
            </Text>
          </Center>
        </Box>
      ))}
    </Stack>
  );
};
