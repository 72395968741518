import type { Preference } from "./models/Preference";
import { PreferenceListResponse } from "./models/PreferenceListResponse";
import { prependProxyBaseUrl, request } from "../request";
import { KeyListResponse } from "./models/KeyListResponse";
import type { UpdatePreference } from "types/systemPreferences/UpdatePreference";

export const PROXY_PREFIX = prependProxyBaseUrl("/api/user-preferences");

export async function listSystemPreferences(): Promise<Preference[]> {
  const preferences = await request().url(`${PROXY_PREFIX}/user-preferences/system?skipTests=true`).get().json();
  const validationResult = PreferenceListResponse.validate(preferences);

  if (validationResult.success) {
    return validationResult.value;
  } else {
    console.warn(`Could not fetch entitlements configs <- ${JSON.stringify(validationResult.details)}`);
    return [];
  }
}

export async function listSystemKeyPreferences(): Promise<string[]> {
  const keys = await request().url(`${PROXY_PREFIX}/user-preferences/keys`).get().json();
  const validationResult = KeyListResponse.validate(keys);

  if (validationResult.success) {
    return validationResult.value;
  } else {
    console.warn(`Could not fetch entitlements configs <- ${JSON.stringify(validationResult.details)}`);
    return [];
  }
}

export async function putSystemPreference(preference: UpdatePreference): Promise<void> {
  return await request().url(`${PROXY_PREFIX}/user-preferences/system/${preference.key}`).put({ value: preference.value }).res();
}

export async function deleteSystemPreference(preferenceKey: string): Promise<void> {
  return await request().url(`${PROXY_PREFIX}/user-preferences/system/${preferenceKey}`).delete().res();
}
