import { Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import type * as collection from "types/collection";
import { TagInput } from "screens/common/components/Tags/TagInput";
import type { ContentDetails } from "types/content/ContentDetails";
import { BlockSectionHeader } from "../previewSection/BlockSectionHeader";

interface Props {
  contentDetail: ContentDetails;
  deleteMetadata?: (text: string, type: "tag" | "note" | "highlight") => void;
}

export const ContentViewTags: FunctionComponent<
  React.PropsWithChildren<React.PropsWithChildren<Props & collection.CharliUIViewAsCollectionItem>>
> = ({ contentDetail, deleteMetadata }) => {
  return (
    <>
      {contentDetail.autoTags.length > 0 && (
        <Box className="ch-content-tags" mt="0!important">
          <BlockSectionHeader title={`AI Extracted Keywords`} />
          <TagInput
            tags={contentDetail.autoTags}
            tagType="auto"
            hideInput
            allowUpdate={false}
            allowDelete
            onDeleteTag={(tagValue) => deleteMetadata && deleteMetadata(tagValue, "tag")}
          />
        </Box>
      )}
    </>
  );
};
