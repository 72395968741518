import { Box } from "@chakra-ui/react";
import { ChatBubble, MessageContainer } from "./components";
import { WorkflowProgress } from "screens/landing/components/WorkflowProgress";
import React, { useState } from "react";
import type { FunctionComponent } from "react";
import { useWorkflowInProgressOrClarificationRequested } from "hooks/useCheckpoints";

interface Props {
  workflowId: string;
}

export const WorkflowProgressCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ workflowId }) => {
  const isInProgress = useWorkflowInProgressOrClarificationRequested(workflowId);
  const [isCancelling, setIsCancelling] = useState(false);

  if (!isInProgress || isCancelling) {
    return <React.Fragment />;
  }

  return (
    <>
      <MessageContainer minWidth="80%" initiator="charli">
        <Box position="relative" overflow="visible">
          <ChatBubble initiator="charli">
            <WorkflowProgress align="end" workflowId={workflowId} setIsCancelling={setIsCancelling} showCancelButton />
          </ChatBubble>
        </Box>
      </MessageContainer>
    </>
  );
};
