import { createAction, createSlice } from "@reduxjs/toolkit";
import type { Integration } from "api/integrations";
import { REHYDRATE } from "redux-persist";
import type { RootState } from "state/rootReducer";
import { downloadIntegrations } from "./operations";

const rehydrate = createAction<RootState>(REHYDRATE);

interface IntegrationsState {
  integrations: Integration[];
}

const initialState: IntegrationsState = {
  integrations: [] as Integration[],
};

export const { actions, reducer } = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (state, action) => {
      if (action.payload?.integrations) {
        return {
          ...initialState,
          ...action.payload.integrations,
          // below is to fix clients that might have an empty object {} as their value of `.integrations`
          integrations: Array.isArray(action.payload.integrations.integrations) ? action.payload.integrations.integrations : [],
        };
      } else {
        return initialState;
      }
    });
    builder.addCase(downloadIntegrations.fulfilled, (state, action) => {
      state.integrations = action.payload;
    });
  },
});

export default reducer;
