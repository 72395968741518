import React from "react";
import { Badge, Stack, Text, useColorModeValue } from "@chakra-ui/react";

export const DefaultChartColors = ["#72acad", "#ad729d", "#8f97a4", "#72ad91", "#6482C0"];

export function subtractDays(days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date;
}

export const chartItemColor = (status: string) => {
  switch (status) {
    case "succeeded":
      return "#72ad91";
    case "complete":
    case "high":
    case "high risk":
    case "0-5":
    case "ai_generated_cost":
      return "#72acad";
    case "queued":
      return "#ffdb7f";
    case "in_progress":
    case "in progress":
      return "#ecba5e";
    case "5-10":
    case "medium":
    case "medium risk":
      return "#6482C0";
    case "denied_intent_confirmation":
      return "#ffd466";
    case "failed":
    case "incomplete":
    case "human_resource_cost":
      return "#ff9b7f";
    case "error":
      return "#ff9d81";
    case "clarification_needed":
    case "clarification":
      return "#80B9E1";
    case "low":
    case "low risk":
    case "10+":
      return "#A0AEC0";
    default:
      return "#8295b5";
  }
};

export const getShadesFromHexColor = (hex: string, numColors: number): { color: string }[] => {
  const hexWithoutHash = hex.substring(1);
  const [r, g, b] = hexWithoutHash.match(/.{2}/g)?.map((c) => parseInt(c, 16)) ?? [];

  const colorStep = 16;
  const maxColorValue = 255;

  const shades: { color: string }[] = [];
  for (let i = 0; i < numColors; i++) {
    const shade = {
      color: `#${Math.min(r + colorStep * i, maxColorValue)
        .toString(16)
        .padStart(2, "0")}${Math.min(g + colorStep * i, maxColorValue)
        .toString(16)
        .padStart(2, "0")}${Math.min(b + colorStep * i, maxColorValue)
        .toString(16)
        .padStart(2, "0")}`,
    };

    // Remove dash from color string
    shade.color = shade.color.replace("-", "0");

    // Add check to ensure color is a valid hex code
    if (shade.color.length !== 7 || !shade.color.startsWith("#")) {
      break;
    }

    shades.push(shade);
  }
  return shades;
};

type RenderCustomizedLabelProps = {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: string;
};

export const renderCustomizedLabel = (props: RenderCustomizedLabelProps) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } = props;
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      id={`pie-segment-${index}`}
      cursor="pointer"
      style={{ pointerEvents: "none" }}
      x={x}
      y={y}
      fontSize="13px"
      fill="white"
      textAnchor="middle"
      dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const CustomTooltip = ({ active, payload, color, showState }) => {
  const tooltipBgColor = useColorModeValue("white", "gray.600");
  const payloadData = payload && payload.length && payload[0].payload;

  if (active && payloadData) {
    return (
      <Stack bgColor={tooltipBgColor} fontSize={"xs"} boxShadow="none" maxWidth="14rem" p=".5rem" borderRadius={"md"}>
        {showState && <Text>{`${payloadData.state}`}</Text>}
        <Stack direction="row">
          <Badge color={color || tooltipBgColor} bgColor={payloadData.state ? chartItemColor(payloadData.state) : "#8295b5"}>
            {payloadData.label}
          </Badge>
          <Text>{`${payloadData.value} ${payloadData.valueSuffix ? payloadData.valueSuffix : ""}`}</Text>
        </Stack>
      </Stack>
    );
  }

  return null;
};

export const CustomTooltipAreaChart = ({
  active,
  payload,
  baseColor,
  prefix,
}: {
  active: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any[];
  prefix?: string;
  baseColor?: string;
}) => {
  const tooltipBgColor = useColorModeValue("white", "gray.600");
  const payloadData = active && payload && payload.length > 0 && payload[0].payload;
  const orderPayloadByName = payload && payload.sort((a, b) => a.name.localeCompare(b.name));

  if (active && payload && payload.length > 0) {
    return (
      <Stack bgColor={tooltipBgColor} fontSize={"xs"} boxShadow="none" maxWidth="14rem" width="100%" p=".5rem" borderRadius={"md"}>
        <Text isTruncated width="100%">{`${payloadData.name}`}</Text>
        {orderPayloadByName.map((item) => (
          <Stack direction="row" key={item.dataKey} justifyContent="space-between" alignItems="center">
            <Badge isTruncated color={tooltipBgColor} bgColor={baseColor ? baseColor : item.name && chartItemColor(item.name)}>
              {item.name}
            </Badge>
            <Text key={item.dataKey} width="4rem" textAlign={"right"}>
              {`${prefix ? prefix : ""}${item.value} ${item.valueSuffix ? item.valueSuffix : ""}`}
            </Text>
          </Stack>
        ))}
      </Stack>
    );
  }

  return (
    <Stack bgColor={tooltipBgColor} fontSize={"xs"} boxShadow="none" maxWidth="14rem" width="100%" p=".5rem" borderRadius={"md"}>
      <Text isTruncated width="100%">{`${payloadData && payloadData.name}`}</Text>
      <Text>No Data Available</Text>
    </Stack>
  );
};
