import { createSlice } from "@reduxjs/toolkit";
import { downloadShareHistory } from "./operations";
import type { ShareHistoryResponseType } from "api/share/ShareHistoryResponse";

interface ShareHistoryState {
  shareHistory: { [metadataId: string]: ShareHistoryResponseType };
}

const initialState: ShareHistoryState = {
  shareHistory: {},
};

export const { actions, reducer } = createSlice({
  name: "share-history",
  initialState,
  reducers: {
    flush(state) {
      state.shareHistory = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadShareHistory.fulfilled, (state, action) => {
      state.shareHistory[action.meta.arg.metadataId] = action.payload;
    });
  },
});

export default reducer;
