import type { IconProps } from "@chakra-ui/react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Code,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import capitalize from "lodash/capitalize";
import React, { useState } from "react";
import { AiFillCheckCircle, AiFillQuestionCircle } from "react-icons/ai";
import { BsFillCircleFill } from "react-icons/bs";
import { HiDotsCircleHorizontal } from "react-icons/hi";
import { IoMdRefreshCircle } from "react-icons/io";
import { formatDate } from "screens/common/modal/formatters";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import type { Workflow } from "types/workflows/workflow";
import { ChildWorkflowSummary } from "./ChildWorkflowSummary";

interface Props {
  id: string;
  workflowSummary: Workflow;
}

const iconProps: IconProps = {
  pt: "0!important",
  boxSize: "1.2rem",
  backgroundColor: "gray.600",
  borderRadius: "full",
};

export const getStatusIcon = (status: string) => {
  switch (status) {
    case "succeeded":
    case "complete":
      return <Icon as={AiFillCheckCircle} color={`${getStatusColor(status)}.100`} {...iconProps} />;
    case "queued":
      return <Icon as={HiDotsCircleHorizontal} color={`${getStatusColor(status)}.100`} {...iconProps} />;
    case "in_progress":
      return <Icon as={HiDotsCircleHorizontal} color={`${getStatusColor(status)}.100`} {...iconProps} />;
    case "clarification_needed":
      return <Icon as={AiFillQuestionCircle} color={`${getStatusColor(status)}.100`} {...iconProps} />;
    case "failed":
    case "error":
    case "denied_intent_confirmation":
      return <Icon as={IoMdRefreshCircle} color={`${getStatusColor(status)}.100`} {...iconProps} />;
    default:
      return <Icon as={BsFillCircleFill} color={`${getStatusColor(status)}.200`} {...iconProps} />;
  }
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "succeeded":
    case "complete":
      return "green";
    case "queued":
    case "in_progress":
    case "denied_intent_confirmation":
      return "orange";
    case "failed":
    case "error":
      return "red";
    case "clarification_needed":
      return "blue";
    default:
      return "gray";
  }
};

const outputFormatWithSeconds = "do MMM yyyy 'at' h:mm:ss aa";

export const WorkflowSummary = ({ workflowSummary: workflow }: Props) => {
  const codeColor = useColorModeValue("blackAlpha", "white");
  const codeBgColor = useColorModeValue("gray.100", "#191f23");
  const [expandedByChildWorkflow, setExpandedByChildWorkflow] = useState<number[]>([]);

  const { workflowSummary } = useWorkflowContext();

  return (
    <Box pb="4rem">
      <Stack id="workflow-top" spacing={2} marginBottom="20px">
        <Stack direction="row" align="center">
          <Text fontSize="xs">ID</Text>
          <Code background={codeBgColor} colorScheme={codeColor} fontSize="xs">
            {workflow.id}
          </Code>
        </Stack>
        <Text fontSize="xs">
          Intent: <Text as="b">{workflow.intent}</Text>
        </Text>
        <Stack direction="row" align="center">
          <Text fontSize="xs">Status</Text>
          <Badge colorScheme={getStatusColor(workflow.status)}>{capitalize(workflow.status).split("_").join(" ")}</Badge>
        </Stack>
        <Stack borderLeft="1px solid gray">
          <Stack direction="row" align="center" pl="1rem">
            <Text fontSize="xs">Started</Text>
            <Text fontSize="xs" as="em">{`${formatDate(new Date(workflow.creationDate), outputFormatWithSeconds)}`}</Text>
          </Stack>
          {workflow.completionDate && (
            <Stack direction="row" align="center" pl="1rem">
              <Text fontSize="xs">Finished</Text>
              <Text fontSize="xs" as="em">{`${formatDate(new Date(workflow.completionDate), outputFormatWithSeconds)}`}</Text>
            </Stack>
          )}
        </Stack>
      </Stack>

      <Accordion
        onChange={(expandedIndex: number[]) => setExpandedByChildWorkflow(expandedIndex)}
        allowMultiple
        index={expandedByChildWorkflow}
        allowToggle
        pb="2rem">
        {workflowSummary.map(({ status, intent, childWorkflowCount }) => (
          <AccordionItem key={`ch-accordion-item-${status}-${intent}`}>
            {({ isExpanded }) => (
              <>
                <AccordionButton paddingInline="0!important">
                  <Box as="span" flex="1" textAlign="left" fontSize="xs" fontWeight="semibold">
                    <Stack spacing={2} marginBottom=".5rem">
                      <Text fontSize="xs">Total Child Workflows: {childWorkflowCount}</Text>
                      <Text fontSize="xs">Intent: {intent}</Text>
                      <Stack direction="row" align="center">
                        <Text fontSize="xs">Status: </Text>
                        <Badge colorScheme={getStatusColor(status)}>{capitalize(status).split("_").join(" ")}</Badge>
                      </Stack>
                    </Stack>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                {isExpanded ? (
                  <AccordionPanel p="0!important" paddingInline={"0!important"}>
                    <ChildWorkflowSummary workflowId={workflow.id} intent={intent} status={status} />
                  </AccordionPanel>
                ) : null}
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};
