import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createEntities,
  upsertResource,
  deleteEntity,
  deleteResource,
  getResources,
  updateEntity,
  updateResource,
} from "api/informationExtractionConfig";
import type { UpsertResourceRequest } from "api/informationExtractionConfig/models/api/CreateResourceRequest";
import type { UpsertTargetEntitiesRequest } from "api/informationExtractionConfig/models/api/CreateTargetEntitiesRequest";
import type { UpdateResourceRequest } from "api/informationExtractionConfig/models/api/UpdateResourceRequest";
import type { UpdateTargetEntityRequest } from "api/informationExtractionConfig/models/api/UpdateTargetEntityRequest";

export const downloadResources = createAsyncThunk("information-extraction-config/download-resources", async () => {
  return getResources();
});

export const addResource = createAsyncThunk("information-extraction-config/create-resource", async (payload: UpsertResourceRequest) => {
  return upsertResource(payload);
});

export const editResource = createAsyncThunk(
  "information-extraction-config/update-resource",
  async ({ id, payload }: { id: string; payload: UpdateResourceRequest }) => {
    return updateResource(id, payload);
  }
);

export const removeResource = createAsyncThunk("information-extraction-config/delete-resource", async (id: string) => {
  return deleteResource(id);
});

export const addTargetEntities = createAsyncThunk(
  "information-extraction-config/create-entities",
  async (payload: UpsertTargetEntitiesRequest) => {
    return createEntities(payload);
  }
);

export const editTargetEntity = createAsyncThunk(
  "information-extraction-config/update-entity",
  async ({ id, payload }: { id: string; payload: UpdateTargetEntityRequest }) => {
    return updateEntity(id, payload);
  }
);

export const removeTargetEntity = createAsyncThunk("information-extraction-config/delete-entity", (id: string) => {
  return deleteEntity(id);
});
