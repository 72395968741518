import React from "react";
import { Box, Button, FormControl, FormLabel, Icon, IconButton, Input, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { AiOutlineDelete } from "react-icons/ai";
import type { FormValues } from "./UpsertReportTemplate";

export const EntitiesFieldArray = () => {
  const { control } = useFormContext<FormValues>();
  const commonButtonProps = useButtonProps("sm", "primary");
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const { fields, append, remove } = useFieldArray({
    name: "entities",
    control,
  });

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" mb="1rem">
        <Text fontSize="md" mr="1rem">
          Entities
        </Text>
        <Button {...commonButtonProps} onClick={() => append({ name: "", value: "" })}>
          Add
        </Button>
      </Stack>
      <Stack overflow="auto" spacing={4} marginBottom="6">
        {fields.map((field, index) => (
          <Box borderWidth="2px" borderRadius="lg" padding="1rem" key={field.id}>
            <Stack direction="row" justifyContent="space-between" spacing={1} width="100%">
              <FormControl mr="0.5rem" width="100%">
                <FormLabel fontSize="md">Entity</FormLabel>
                <Controller
                  render={({ field }) => <Input {...field} type="text" mr="0.5rem" fontSize="md" boxShadow="none" required />}
                  name={`entities.${index}.name`}
                  control={control}
                />
              </FormControl>
              <FormControl mr="0.5rem" width="100%">
                <FormLabel fontSize="md">Value</FormLabel>
                <Controller
                  render={({ field }) => <Input {...field} type="text" mr="0.5rem" fontSize="md" boxShadow="none" required />}
                  name={`entities.${index}.value`}
                  control={control}
                />
              </FormControl>
              <Box pt="1.5rem">
                <IconButton
                  onClick={() => remove(index)}
                  aria-label="Delete"
                  backgroundColor="unset"
                  icon={<Icon as={AiOutlineDelete} color={buttonColor} boxSize="1.5rem" _hover={{ color: buttonHoverColor }} />}
                  size="lg"
                  _hover={{ backgroundColor: "unset" }}
                />
              </Box>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};
