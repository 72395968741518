import type { FunctionComponent } from "react";
import React from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import type { Message } from "types/conversation";
import { ChatBubble, MessageContainer } from "../components";
import type { JsonButton } from "types/charliui";
import { FaList } from "react-icons/fa";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useNavigate } from "react-router-dom";

interface Props {
  message: Message;
  button: JsonButton;
}

export const JsonCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ message, button }) => {
  const navigate = useNavigate();
  const buttonTitle = button.body.label;
  const icon = FaList;

  return (
    <MessageContainer initiator={message.senderId === "charli" ? "charli" : "user"} key={`${message.id}-button-${buttonTitle}`}>
      <ConversationContext.Consumer>
        {({ conversationId }) => (
          <ChatBubble
            initiator={message.senderId === "charli" ? "charli" : "user"}
            state={message.state}
            onClick={() => {
              navigate(`/json/${conversationId}/${button.body.buttonId ?? message.id}`);
            }}
            className={buttonTitle === "Resource Canvas" ? "ch-content-canvas-button" : undefined}>
            <Stack direction="row" alignItems="center" spacing="1rem">
              {icon && <Box as={icon} />}
              <Stack direction="row">
                <Text fontSize="sm" fontWeight="semibold">
                  {buttonTitle}
                </Text>
              </Stack>
              <ChevronRightIcon height="1.5rem" />
            </Stack>
          </ChatBubble>
        )}
      </ConversationContext.Consumer>
    </MessageContainer>
  );
};
