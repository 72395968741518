import React, { useMemo, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Select } from "chakra-react-select";
import {
  Stack,
  Text,
  IconButton,
  FormControl,
  Box,
  Button,
  Icon,
  useColorModeValue,
  SimpleGrid,
  Grid,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useButtonProps, useEntitlements } from "hooks";
import { useFieldArray, Controller } from "react-hook-form";
import type { Control } from "react-hook-form";
import { useIsUserSearching, useUsersSearch } from "hooks/useUsers";
import debounce from "lodash/debounce";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import type { FormValues } from "screens/landing/tabs/admin/configuredWorkflows/components/ConfiguredWorkflowUpsertModal";

const MIN_SEARCH_LENGTH = 3;

interface IProps {
  onAdd: (userId: string) => Promise<boolean>;
  onDelete: (userId: string) => Promise<boolean>;
  onCustomUsers?: (users: { value: string; label: string }[]) => { value: string; label: string; isDisabled?: boolean }[];
  canDelete?: boolean;
  control: Control<FormValues>;
  isLoading?: boolean;
}

export const AvailableUsersFieldArray = (props: IProps) => {
  const { canDelete = true, onCustomUsers, control, isLoading: isLoadingProp } = props;

  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const bgColor = useColorModeValue("white!important", "gray.700!important");
  const commonButtonProps = useButtonProps("sm", "primary");
  const {
    fields: unorderedFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "onlyAvailableToUserIds",
  });
  const { view_users: hasViewUsers } = useEntitlements();
  const isLoading = useIsUserSearching() || !!isLoadingProp;

  const fields = useMemo(() => orderBy(unorderedFields, "label"), [unorderedFields]);
  const fieldsIndexes = useMemo(() => new Map(unorderedFields.map(({ id }, index) => [id, index])), [unorderedFields]);

  const [searchUser, setSearchUser] = useState("");
  const [selectedUserId, setSelectedUserId] = useState<{ id: null | string; label: null | string }>({
    id: null,
    label: null,
  });
  const usersSearch = useUsersSearch(searchUser.length < MIN_SEARCH_LENGTH ? "" : searchUser).map((u) => ({
    value: u.id,
    label: `${(u.firstName ?? "")
      .concat(" ")
      .concat(u.lastName ?? "")
      .trim()} (${u.email})`,
  }));

  const filteredUsers = useMemo(() => {
    const hasFields = groupBy(fields, "value");

    return usersSearch.filter(({ value }) => !hasFields[value]);
  }, [usersSearch, fields]);

  const users = useMemo(() => {
    const tempUsers = (() => {
      if (onCustomUsers) {
        return onCustomUsers(filteredUsers);
      } else {
        return filteredUsers;
      }
    })();

    return orderBy(tempUsers, "label");
  }, [filteredUsers, onCustomUsers]);

  const changedUserSearch = debounce((target) => {
    setSearchUser(target);
  }, 500);

  const cleanUser = () => {
    setSearchUser("");
    setSelectedUserId({ id: null, label: null });
  };

  const handleAddUser = async () => {
    if (selectedUserId.id) {
      if (await props.onAdd(selectedUserId.id)) {
        append({ value: selectedUserId.id, label: selectedUserId.label || "" });
      }

      cleanUser();
    }
  };

  const selectedUser = (event) => {
    if (event === null) {
      cleanUser();
    } else {
      setSelectedUserId({ id: event.value, label: event.label });
    }
  };

  if (!hasViewUsers) {
    return <></>;
  }

  return (
    <Box backgroundColor={bgColor} mt="1rem">
      <FormControl>
        <FormLabel fontSize="md">Available for users</FormLabel>
        <Grid templateColumns="10fr 2fr" gap={4} pb="1rem">
          <Select
            className="ch-multi-select"
            useBasicStyles
            isLoading={isLoading}
            selectedOptionStyle="check"
            options={users}
            onInputChange={changedUserSearch}
            onChange={selectedUser}
            isClearable
            value={{ value: selectedUserId.id, label: selectedUserId.label }}
          />
          <Button {...commonButtonProps} onClick={handleAddUser} size="md" isLoading={isLoading} disabled={!selectedUserId.id}>
            Add
          </Button>
        </Grid>
      </FormControl>

      <SimpleGrid columns={3} spacingX="1rem" spacingY="1rem">
        {fields.map((field, index) => (
          <Box borderWidth="2px" borderRadius="lg" padding="1rem" key={field.id}>
            <Stack mb="1rem" direction="row" spacing={2} alignItems="center">
              <FormControl>
                <Controller
                  render={({ field }) => <Input {...field} type="hidden" mr="0.5rem" fontSize="md" boxShadow="none" required />}
                  name={`onlyAvailableToUserIds.${index}.value`}
                  control={control}
                />
                <Text>{field.label}</Text>
              </FormControl>
              {canDelete && (
                <IconButton
                  isLoading={isLoading}
                  onClick={async () => {
                    props
                      .onDelete(field.value)
                      .then((success) => {
                        if (success) {
                          const fieldIndex = fieldsIndexes.get(field.id);
                          remove(fieldIndex);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  aria-label="Delete"
                  backgroundColor="unset"
                  icon={<Icon as={AiOutlineDelete} color={buttonColor} boxSize="1.5rem" _hover={{ color: buttonHoverColor }} />}
                  size="lg"
                  _hover={{ backgroundColor: "unset" }}
                />
              )}
            </Stack>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};
