import type { FunctionComponent } from "react";
import { Box, Icon, IconButton, Stack, Tag, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import React, { useContext, useCallback } from "react";
import { ResearchContext } from "./ResearchContext";
import { ResearchSearchInputs } from "./ResearchSearchInputs";
import { SecSearchInputs } from "./SecSearchInputs";
import { DynamicForm } from "screens/common/components";
import { useConfigForm, useConfigMap, useUserPreference } from "hooks";
import { DynamicFormEditorPanel } from "./DynamicFormEditorPanel";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { AddMeetingDetails } from "../addToCharli/AddToCharliWizard";
import { AddValuationDetails } from "../addToCharli/AddToCharliWizard/AddValuationDetails";
import { useLocation } from "react-router-dom";
import { getViewConfig } from "configs/configMap";
import { BsFiletypeJson } from "react-icons/bs";
import { useConversationContext } from "screens/thread/ConversationContext";

interface Props {
  copyResearchHistory?: boolean;
  collectionType?: string;
}
export const ProjectCriteriaForm: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  copyResearchHistory,
  collectionType,
}) => {
  const { onDynamicFormEditorPanelToggle } = useContext(ResearchContext);
  const { pathname } = useLocation();
  const isProject = pathname.includes("project");
  const route = pathname.split("/").slice(isProject ? 2 : 1, isProject ? 3 : 2)[0];
  const configMap = useConfigMap();
  const { projectForm } = useConfigForm(collectionType || "", configMap);
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const titleColor = useColorModeValue("gray.500", "gray.600");
  const { calendarEvent } = useAddToCharliContext();
  const { isPortfolioModalOpen } = useConversationContext();

  const getSearchInputs = useCallback(() => {
    switch (collectionType) {
      case "research":
        return <ResearchSearchInputs showResearchTopics={collectionType === "research"} copyResearchHistory={copyResearchHistory} />;
      case "sec":
        return <SecSearchInputs />;
      case "meeting":
        return <AddMeetingDetails calendarEvent={calendarEvent} />;
      case "valuation":
        return <AddValuationDetails />;
      default: {
        if (!projectForm) {
          return (
            <Stack direction="row">
              <Tag colorScheme="gray">Note</Tag>
              <Text>{`No project criteria has been defined for ${getViewConfig(
                "title",
                route || collectionType || "",
                configMap
              )}. Please inform the project administrator.`}</Text>
            </Stack>
          );
        }

        return (
          <>
            {!isPortfolioModalOpen && injectDebugEntityPreference && projectForm && (
              <Tooltip label="Form Definition" aria-label="Debug Entity">
                <Box position="absolute" top="0rem" right="0rem" onClick={onDynamicFormEditorPanelToggle}>
                  <IconButton
                    size="sm"
                    color={titleColor}
                    width="2rem"
                    aria-label="Debug Entity"
                    variant="ghost"
                    icon={<Icon as={BsFiletypeJson} boxSize="1rem" />}
                    onClick={onDynamicFormEditorPanelToggle}
                  />
                  <DynamicFormEditorPanel formJson={JSON.stringify(projectForm)} />
                </Box>
              </Tooltip>
            )}
            <Stack mt="0!important" spacing="1rem">
              <DynamicForm {...projectForm} />
            </Stack>
          </>
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionType]);

  return <Box pt="1rem">{getSearchInputs()}</Box>;
};
