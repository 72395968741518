import { Box, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, useBreakpointValue } from "@chakra-ui/react";
import { useContents, useIsMainAppLoading, useItemSelector, useTabProps, useCollectionKey, useInitialSyncCompleted } from "hooks";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import React, { useContext, useEffect, useState } from "react";
import { ContentCanvasFilterOptions, ContentFilterContext, ContentRouter } from "screens/content";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { getItemTypeForContent } from "screens/content/common/utils";
import type { SupportedItemKeys } from "state/selection/reducer";
import { ProjectContentFilter } from "screens/content/contentCanvas/header/ProjectContentFilter";
import { ProjectDetailDefaultAIResults } from "./ProjectDetailDefaultAIResults";
import { ProjectDetailDefaultActionMenu } from "./ProjectDetailDefaultViewActionMenu";

export const PROJECT_DETAIL_SECTION_SELECTOR = "project-detail-section";

interface Props {
  collectionId: string;
}

export const ProjectDetailViewContent: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ collectionId }) => {
  const { setSelectedItems, resetSelectedItems } = useItemSelector();
  const { isFilterOpen, onFilterClose, resetFilter } = useContext(ContentFilterContext);
  const { isHighlightsPanelOpen, isAddToCollectionModalOpen } = useAddToCharliContext();
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const contentData = useContents(collectionMetadataIds || []);
  const [tabIndex, setTabIndex] = useState(0);
  const { defaultTabProps } = useTabProps();
  const isMainAppLoading = useIsMainAppLoading();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const hasInitialSyncCompleted = useInitialSyncCompleted();

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    if (index === 0) {
      onFilterClose();
    }
    resetFilter();
  };

  useEffect(() => {
    if (contentData?.length) {
      const items =
        contentData &&
        contentData.reduce((cells, cell) => {
          const itemType = getItemTypeForContent(cell.type);
          cells[cell.mediaId] = { type: itemType };
          return cells;
        }, {} as { [id: string]: { type: SupportedItemKeys } });
      if (items) setSelectedItems(items);
    } else {
      resetSelectedItems();
    }
  }, [contentData?.length, isHighlightsPanelOpen, isAddToCollectionModalOpen, contentData, setSelectedItems, resetSelectedItems]);

  const isLoadingContents = useMemo(() => {
    if (!collectionMetadataIds?.length) {
      return false;
    }

    return (contentData?.length ?? 0) < collectionMetadataIds?.length;
  }, [collectionMetadataIds?.length, contentData?.length]);

  return (
    <>
      {!isMainAppLoading && (
        <Tabs isFitted={isMobile} index={tabIndex} onChange={handleTabsChange} mt={isMobile ? "0" : "2.9rem!important"}>
          <Stack
            direction={isMobile ? "column" : "row"}
            justifyContent={"space-between"}
            width="100%"
            spacing={isMobile ? "1rem" : ".5rem"}
            alignItems="center">
            <TabList borderBottom={"none"} width="100%">
              <Tab {...defaultTabProps} className="ch-project-results">
                AI Results
              </Tab>
              <Tab {...defaultTabProps} className="ch-project-resources">
                Source Content
              </Tab>
              <Tab {...defaultTabProps} className="ch-project-resources" isDisabled>
                News and Updates
              </Tab>
            </TabList>
            <ProjectDetailDefaultActionMenu collectionId={collectionId} />
          </Stack>
          <TabPanels pb="4rem">
            <TabPanel pt=".65rem" px="0!important" className="ch-project-results-panel">
              <ProjectDetailDefaultAIResults isLoading={!hasInitialSyncCompleted || isLoadingContents} collectionId={collectionId} />
            </TabPanel>
            <TabPanel pt=".65rem" px="0!important" className="ch-project-resources-panel">
              <Box alignSelf={isMobile ? "space-between" : "flex-end"} width="100%" pt={isMobile ? "1rem" : "unset"} pb="1rem">
                <ProjectContentFilter />
              </Box>
              <Stack spacing="1rem">
                {isFilterOpen && <ContentCanvasFilterOptions />}
                <ContentRouter filterOutProjectOutput />
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};
