import { Box, Input, Stack, useColorModeValue } from "@chakra-ui/react";
import { useExternalIntegrations, useMeetings, useUserProfile, VIDEO_CONFERENCE_SYSTEM_TO_INTEGRATION_MAP } from "hooks";
import { useCallback, useContext, useMemo, useState } from "react";
import type { FunctionComponent } from "react";
import { useEffect } from "react";
import React from "react";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { useAddToCharliContext } from "./AddToCharliProvider";
import { shallowEqual, useSelector } from "react-redux";
import type { CalendarEvent } from "types/meetings";
import type { RootState } from "state/rootReducer";
import { TwoColumnTable } from "screens/content/common/TwoColumnTable";
import { format } from "date-fns";
import { InstallIntegrationButton } from "screens/panels/components/InstallIntegrationButton";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";
import { ResearchTopicsInput } from "screens/panels/research/ResearchTopicsInput";
import MarkdownEditor from "screens/content/common/notes/MarkdownEditor";

interface Props {
  calendarEvent?: CalendarEvent;
}

const DEFAULT_FETCHING_TXT = "Checking for meeting transcripts ...";

export const AddMeetingDetails: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ calendarEvent }) => {
  const { setCanSend, uppyFiles } = useAddToCharliContext();
  const inputBgColor = useColorModeValue("white", "gray.700");
  const { email } = useUserProfile();
  const { getFullVideoConferenceEvent } = useMeetings();
  const { requestEntities, setRequestEntities } = useContext(ConversationContext);
  const checkedVideoConferenceEvents = useSelector((state: RootState) => state.meetings.checkedVideoConferenceEvents);
  const [fetchingDetailsText, setFetchingDetailsText] = useState<string>(DEFAULT_FETCHING_TXT);
  const [canProcessTranscripts, setCanProcessTranscripts] = useState(false);
  const [transcriptResolutionId, setTranscriptResolutionId] = useState("");
  const [inputEmail, setInputEmail] = useState(getRequestValue("templateName", requestEntities) || email || "");
  const [collectionName, setCollectionName] = useState(getRequestValue("collection_name", requestEntities) || "");
  const [meetingAgenda, setMeetingAgenda] = useState(getRequestValue("meeting_agenda", requestEntities) || "");
  const { hasIntegration } = useExternalIntegrations("Reporting");
  const identityProvider = useSelector((state: RootState) => state.session.user?.identityProvider, shallowEqual);
  const isAstrella = identityProvider === "astrella";

  const updateRequestEntity = (entityName: string, entityValue: string) => {
    updateBatchRequestEntities([{ entity: entityName, value: entityValue, source: "meeting-detail-inputs" }], setRequestEntities);
  };

  const allowProcessingOfTranscript = useCallback(() => {
    setFetchingDetailsText("Found transcripts to process! Please enter details");
    setCanProcessTranscripts(true);
    setCanSend(true);
    if (email) {
      updateBatchRequestEntities(
        [
          { entity: "email_address", value: email, source: "meeting-detail-inputs" },
          { entity: "templateName", value: "MeetingReportTemplateAstrella.docx", source: "meeting-detail-inputs" },
        ],
        setRequestEntities
      );
    }
    updateBatchRequestEntities(
      [
        { entity: "collection_name", value: calendarEvent?.subject ?? "", source: "meeting-detail-inputs" },
        { entity: "name", value: calendarEvent?.subject ?? "", source: "meeting-detail-inputs" },
      ],
      setRequestEntities
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarEvent, isAstrella, email]);

  useEffect(() => {
    if (calendarEvent) {
      updateBatchRequestEntities(
        [
          { entity: "collection_name", value: calendarEvent?.subject ?? "", source: "meeting-detail-inputs" },
          { entity: "name", value: calendarEvent?.subject ?? "", source: "meeting-detail-inputs" },
        ],
        setRequestEntities
      );
      const resolutionId = getFullVideoConferenceEvent(calendarEvent);
      setTranscriptResolutionId(resolutionId);
    }
    if (calendarEvent != null) {
      updateRequestEntity("calendar_event_id", calendarEvent.id);
      if (VIDEO_CONFERENCE_SYSTEM_TO_INTEGRATION_MAP[calendarEvent.video_conference_event.video_conference_system]) {
        updateRequestEntity("video_conference_web_link", calendarEvent.video_conference_event.video_conference_web_link);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarEvent]);

  useEffect(() => {
    const videoConferenceResolution = checkedVideoConferenceEvents[transcriptResolutionId];
    if (videoConferenceResolution != null) {
      const hasNonEmptyTranscriptsArray =
        videoConferenceResolution.videoConferenceEvent?.video_transcripts != null &&
        videoConferenceResolution.videoConferenceEvent?.video_transcripts.length > 0;

      if (
        hasNonEmptyTranscriptsArray &&
        videoConferenceResolution.videoConferenceEvent?.video_transcripts.every((transriptObject) => transriptObject != null) &&
        !videoConferenceResolution.error &&
        videoConferenceResolution.videoConferenceEvent.web_link === calendarEvent?.video_conference_event.video_conference_web_link
      ) {
        // there is a transcript to process
        allowProcessingOfTranscript();
      } else {
        // either there was an error or no transcript was found
        setFetchingDetailsText("Unable to find any transcripts to process. Please check again later.");
        setCanProcessTranscripts(false);
        setCanSend(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedVideoConferenceEvents[transcriptResolutionId], allowProcessingOfTranscript]);

  useEffect(() => {
    if (email && calendarEvent == null) {
      updateRequestEntity("email_address", email);
      isAstrella && updateRequestEntity("templateName", "MeetingReportTemplateAstrella.docx");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, calendarEvent, isAstrella]);

  useEffect(() => {
    if (uppyFiles.length > 0 || (calendarEvent != null && canProcessTranscripts)) {
      setCanSend(true);
    } else {
      setCanSend(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarEvent, canProcessTranscripts, uppyFiles.length]);

  useEffect(() => {
    if (inputEmail) {
      updateRequestEntity("email_address", inputEmail.trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputEmail]);

  useEffect(() => {
    if (meetingAgenda) {
      updateRequestEntity("meeting_agenda", meetingAgenda.trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingAgenda]);

  useEffect(() => {
    if (collectionName) {
      updateBatchRequestEntities(
        [
          { entity: "collection_name", value: collectionName ?? "", source: "meeting-detail-inputs" },
          { entity: "name", value: collectionName ?? "", source: "meeting-detail-inputs" },
        ],
        setRequestEntities
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName]);

  const rows = useMemo(() => {
    if (calendarEvent != null) {
      const info = Object.keys(calendarEvent)
        .filter((key) => ["start", "end"].includes(key))
        .map((key) => {
          if (["start", "end"].includes(key)) {
            return { title: key[0].toUpperCase() + key.slice(1), value: format(new Date(calendarEvent[key]), "LLLL do y h:mm bbb") };
          }
          return { title: key[0].toUpperCase() + key.slice(1), value: calendarEvent[key] };
        });
      const status = { title: "Status", value: fetchingDetailsText };
      return [status, ...info];
    } else {
      return [];
    }
  }, [calendarEvent, fetchingDetailsText]);

  return (
    <Stack spacing="2.5rem" pt="1rem">
      {calendarEvent != null && (
        <Box>
          <TwoColumnTable rows={rows} />
        </Box>
      )}
      <Box>
        <SectionHeader title="Meeting Name (required)" subTitleStyle={{ paddingBottom: "0.5rem" }} />
        <Input
          size="sm"
          className={`ch-collection-name-input`}
          placeholder={`Enter a new name...`}
          defaultValue={calendarEvent?.subject || collectionName}
          disabled={calendarEvent != null && !canProcessTranscripts}
          onChange={(event) => setCollectionName(event.target.value)}
        />
      </Box>
      <Box>
        <SectionHeader title="Meeting Agenda" subTitleStyle={{ paddingBottom: "0.5rem" }} />
        <MarkdownEditor setNoteText={setMeetingAgenda} placeholderText="Add an agenda for this meeting..." />
      </Box>
      <ResearchTopicsInput />
      <Box>
        <SectionHeader title="Email Meeting Report To" subTitleStyle={{ marginBottom: "0.5rem" }} />
        {!hasIntegration && (
          <Box pb="1rem">
            <InstallIntegrationButton category="Reporting" />
          </Box>
        )}
        <Input
          type="email"
          background={inputBgColor}
          id="share-input-email"
          placeholder="Enter a comma separated list of email addresses"
          size="sm"
          value={email}
          rounded="10px"
          disabled={(calendarEvent != null && !canProcessTranscripts) || !hasIntegration}
          onChange={(event) => setInputEmail(event.target.value)}
        />
      </Box>
    </Stack>
  );
};
