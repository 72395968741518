import { useMenuConfig, useConfigMap, useUserPreference } from "hooks";
import { sortBy } from "lodash";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import React from "react";
import { AddNewMenuProjectItem } from "./AddNewMenuProjectItem";
import { Box } from "@chakra-ui/react";

interface Props {
  excludedMenuItems?: string[];
}

export const AddNewMenuProjects: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ excludedMenuItems }) => {
  const configMap = useConfigMap();
  const menuConfig = useMenuConfig(configMap, "all", true);
  const menuItemsOrderPreference = useUserPreference("ui_menu_order") as string;
  const menuItemOrder = useMemo(
    () => (menuItemsOrderPreference && menuItemsOrderPreference.split(",")) || menuConfig.map((menuItem) => menuItem.id),
    [menuConfig, menuItemsOrderPreference]
  );

  const reorderedMenuConfig = useMemo(() => {
    const reorderedMenuConfig = sortBy(menuConfig, (menuItem) => {
      const index = menuItemOrder.indexOf(menuItem.id);
      return index !== -1 ? index : menuItemOrder.length;
    });

    return reorderedMenuConfig;
  }, [menuConfig, menuItemOrder]);

  return (
    <Box className={"ch-menu-list"}>
      {reorderedMenuConfig.map((menuItem, index) => {
        return <AddNewMenuProjectItem key={index} menuItem={menuItem} />;
      })}
    </Box>
  );
};
