import React, { useContext, useEffect } from "react";
import { Box, Stack, useBreakpointValue } from "@chakra-ui/react";
import { Integrations } from "./components";
import { useParams } from "react-router-dom";
import { TabTitle } from "screens/common/components/TabTitle";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";

export const IntegrationsTab = React.memo(() => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { category } = useParams() as { category: string };
  const { setHeaderText } = useContext(SettingsProviderContext);

  useEffect(() => {
    setHeaderText("Integrations");
  }, [setHeaderText]);

  return (
    <Box px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"}>
      <TabTitle title="Charli > Integrations" />
      <Stack spacing="3rem" pb={isMobile ? "7rem" : "2rem"}>
        <Integrations category={category} />
      </Stack>
    </Box>
  );
});
