import type { ExternalLink } from "api/newsData/models/ExternalLink";
import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";

export const useNewsLinks = ({ limit }: { limit?: number } = {}): ExternalLink[] => {
  return useSelector((state: RootState) => {
    return limit !== undefined && limit > 0 ? state.newsInformation.externalLinks.slice(0, limit) : state.newsInformation.externalLinks;
  }, shallowEqual);
};
