import { Box } from "@chakra-ui/react";
import { useShareHistory } from "hooks";
import type { FunctionComponent } from "react";
import React, { useMemo } from "react";
import { formatDate } from "screens/common/modal/formatters";
import { TwoColumnTable } from "screens/content/common/TwoColumnTable";
import { SectionHeader } from "../../contentView/previewSection/SectionHeader";

interface Props {
  metadataId: string;
}

export const ShareHistorySection: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ metadataId }) => {
  const shareHistory = useShareHistory(metadataId);

  const rows = useMemo(() => {
    const sharedWithMeRows = shareHistory.sharedWithMe.map((sharedWithMe) => {
      return {
        title: `Received from ${sharedWithMe.senderName}`,
        value: sharedWithMe.acceptedAt ? `${formatDate(new Date(sharedWithMe.acceptedAt))}` : "Not yet accepted",
      };
    });
    const sharedByMeRows = shareHistory.sharedByMe.map((sharedByMe) => {
      return {
        title: `Shared with ${sharedByMe.recipientName}`,
        value: sharedByMe.acceptedAt ? `Accepted ${formatDate(new Date(sharedByMe.acceptedAt))}` : "Not yet accepted",
      };
    });

    return [...sharedWithMeRows, ...sharedByMeRows];
  }, [shareHistory]);

  return (
    <>
      {rows.length > 0 ? (
        <Box>
          <SectionHeader title="Share History" />
          <TwoColumnTable rows={rows} />
        </Box>
      ) : (
        <React.Fragment />
      )}
    </>
  );
};
