import React, { useRef, useState, forwardRef, useEffect, useCallback, useMemo } from "react";
import { Box, IconButton, Input, InputGroup, InputRightElement, useColorModeValue, useOutsideClick } from "@chakra-ui/react";
import { ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import { useButtonProps } from "hooks";
import type { InputProps } from "@chakra-ui/react";
import { TypingIndicator } from "screens/thread/components/cells/components";

interface Props {
  inputProps: InputProps;
  value: string;
  onChange: (suggestion: string, metadata?: unknown) => void;
  suggestions: { value: string; metadata?: unknown }[];
  width?: string;
  size?: "xs" | "sm" | "md";
  isIconButtonDisabled?: boolean;
  showButtons?: boolean;
  isLoading?: boolean;
}

export const InputWithSuggestions = forwardRef(
  (
    {
      inputProps,
      suggestions,
      width = "100%",
      size = "md",
      isIconButtonDisabled = false,
      showButtons = true,
      value,
      onChange,
      isLoading,
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const mainRef = useRef<HTMLDivElement>(null);
    const suggestionsRef = useRef<(HTMLDivElement | null)[]>([]);

    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(-1);

    const inputBgColor = useColorModeValue("white", "#191f23");
    const secondaryButtonStyle = useButtonProps("sm", "secondary");
    const commonButtonProps = useButtonProps("sm", "primary");
    const suggestionsBgColor = useColorModeValue("white", "gray.800");
    const suggestionsBgColorHover = useColorModeValue("gray.50", "gray.700");

    const filteredSuggestions = useMemo(() => {
      return suggestions.filter((suggestion) => suggestion.value.toLowerCase().includes(value.toLowerCase()));
    }, [suggestions, value]);

    const onSelectSuggestion = useCallback(
      (suggestion: string, metadata?: unknown) => {
        setSelectedSuggestionIndex(-1);
        onChange(suggestion, metadata);
      },
      [onChange]
    );

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === "Tab") {
          if (selectedSuggestionIndex && filteredSuggestions[selectedSuggestionIndex]) {
            onSelectSuggestion(filteredSuggestions[selectedSuggestionIndex].value, filteredSuggestions[selectedSuggestionIndex].metadata);
          }
          event.preventDefault();
        }
        if (event.key === "Enter") {
          if (selectedSuggestionIndex >= 0 && filteredSuggestions[selectedSuggestionIndex]) {
            onSelectSuggestion(filteredSuggestions[selectedSuggestionIndex].value, filteredSuggestions[selectedSuggestionIndex].metadata);
          } else {
            onSelectSuggestion(value);
          }
          setShowSuggestions(false);
          event.preventDefault();
        }
        if (event.key === "ArrowUp") {
          setSelectedSuggestionIndex((prev: number) => {
            if (prev === -1) {
              return prev;
            }
            return prev - 1;
          });
        }
        if (event.key === "ArrowDown") {
          setShowSuggestions(true);
          setSelectedSuggestionIndex((prev: number) => {
            if (prev === filteredSuggestions.length - 1) {
              return prev;
            }
            return prev + 1;
          });
        }
      },
      [value, onSelectSuggestion, selectedSuggestionIndex, filteredSuggestions]
    );

    useOutsideClick({
      ref: mainRef,
      handler: () => {
        setShowSuggestions(false);
      },
    });

    useEffect(() => {
      if (suggestionsRef.current[selectedSuggestionIndex]) {
        suggestionsRef.current[selectedSuggestionIndex]!.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, [selectedSuggestionIndex]);

    return (
      <Box ref={mainRef} width="100%" position="relative">
        <InputGroup minWidth={width} width={width} size={size}>
          <Input
            {...inputProps}
            value={value}
            onChange={({ target: { value } }) => {
              onSelectSuggestion(value, undefined);
            }}
            ref={ref}
            type="text"
            background={inputBgColor}
            size={size}
            rounded="10px"
            onFocus={() => {
              setShowSuggestions(true);
            }}
            onKeyDown={onKeyDown}
          />
          {showButtons && (
            <InputRightElement width="4rem">
              <IconButton
                className="ch-clear-tags"
                onClick={() => {
                  setShowSuggestions(false);
                }}
                disabled={isIconButtonDisabled}
                {...secondaryButtonStyle}
                marginRight="4px"
                boxSize={"1.75rem"}
                borderRadius="3px"
                size={size}
                aria-label="Close"
                icon={<CloseIcon boxSize=".7rem" />}
              />
              <IconButton
                {...commonButtonProps}
                boxSize={"1.75rem"}
                borderRadius="3px"
                onClick={() => {
                  if (inputProps.value) {
                    onChange(inputProps.value as string, -1);
                  } else if (selectedSuggestionIndex && suggestions[selectedSuggestionIndex]) {
                    onChange(suggestions[selectedSuggestionIndex].value, selectedSuggestionIndex);
                  }
                }}
                disabled={isIconButtonDisabled}
                size={size}
                aria-label="Add"
                icon={<ArrowForwardIcon boxSize="1.2rem" />}
              />
            </InputRightElement>
          )}
        </InputGroup>
        {showSuggestions && filteredSuggestions.length > 0 && (
          <Box
            tabIndex={0}
            boxShadow={"lg"}
            top="2rem"
            borderRadius="md"
            border="none"
            minWidth={"30%"}
            maxHeight={200}
            overflowY={"auto"}
            backgroundColor={suggestionsBgColor}
            position="absolute"
            zIndex={10}>
            {isLoading ? (
              <Box paddingBlock={2} paddingInline={5} display={"flex"} alignItems="center" justifyContent={"center"}>
                <TypingIndicator />
              </Box>
            ) : (
              filteredSuggestions.map((suggestion, index) => (
                <Box
                  onMouseEnter={() => {
                    setSelectedSuggestionIndex(index);
                  }}
                  backgroundColor={suggestionsBgColor}
                  _hover={{ cursor: "pointer", backgroundColor: suggestionsBgColorHover }}
                  fontSize={"0.875rem"}
                  paddingBlock={2}
                  paddingInline={5}
                  key={index}
                  ref={(el) => (suggestionsRef.current[index] = el)}
                  fontWeight={selectedSuggestionIndex === index ? "bold" : "normal"}
                  role={"button"}
                  onClick={() => {
                    onSelectSuggestion(suggestion.value, suggestion.metadata);
                    setShowSuggestions(false);
                  }}>
                  {suggestion.value}
                </Box>
              ))
            )}
          </Box>
        )}
      </Box>
    );
  }
);
