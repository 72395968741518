import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Stack, Text, useDisclosure } from "@chakra-ui/react";
import type { Entitlement } from "types/entitlements";
import { useDispatch } from "react-redux";
import { downloadPlanEntitlements } from "state/billingPlans/operations";
import { downloadOrganizationEntitlements } from "state/organization/operations";
import { downloadUserEntitlements } from "state/users/operations";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { EntitlementConfigModal } from "screens/landing/tabs/admin/entitlements/modals/EntitlementConfigModal";

interface IProps {
  entitlements: Entitlement[];
  isLoading: boolean;
  type: "plan" | "organization" | "user";
  entityId: string;
  maxHeight?: string;
}

export const EntitlementsList = ({ entitlements, isLoading, type, entityId, maxHeight = "40rem" }: IProps) => {
  const dispatch = useDispatch();
  const [selectedCode, setSelectedCode] = useState<string | null>(null);
  const { isOpen: isEntitlementPanelOpen, onOpen: onEntitlementPanelOpen, onClose: onEntitlementPanelClose } = useDisclosure();

  useEffect(() => {
    if (!entityId) {
      return;
    }

    switch (type) {
      case "plan":
        dispatch(downloadPlanEntitlements({ planCode: entityId }));
        break;
      case "organization":
        dispatch(downloadOrganizationEntitlements({ organizationId: entityId }));
        break;
      case "user":
        dispatch(downloadUserEntitlements(entityId));
        break;
      default:
        break;
    }
  }, [type, entityId, dispatch]);

  if (entitlements.length > 0) {
    return (
      <>
        <SimpleGrid maxHeight={maxHeight} overflowY="auto" columns={[1, 1, 1, 1, 2]} spacingX="1rem" spacingY="1rem" pr=".5rem">
          {entitlements.map((entitlement) => (
            <Box
              cursor={"pointer"}
              borderWidth="2px"
              borderRadius="lg"
              padding="1rem"
              key={entitlement.entitlementId}
              onClick={() => {
                onEntitlementPanelOpen();
                setSelectedCode(entitlement.entitlementId);
              }}>
              <Stack>
                <Text fontWeight={"semibold"} fontSize="md">
                  {entitlement.name}
                </Text>
                {entitlement.description && <Text fontSize="sm">{entitlement.description}</Text>}
              </Stack>
            </Box>
          ))}
        </SimpleGrid>
        <EntitlementConfigModal code={selectedCode} isOpen={isEntitlementPanelOpen} onClose={onEntitlementPanelClose} />
      </>
    );
  }

  if (isLoading) {
    return <TypingIndicator />;
  } else {
    return (
      <Box cursor={"pointer"} borderWidth="2px" borderRadius="lg" padding="1rem">
        <Text>No entitlements found.</Text>
      </Box>
    );
  }
};
