import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  useColorModeValue,
  Text,
  Heading,
  Badge,
  Button,
  Tooltip,
  IconButton,
  Select,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useButtonProps, useOrganization, useOrganizationIsLoading, useUserProfile } from "hooks";
import type { Organization } from "types/organization/organization";
import { Icon } from "@chakra-ui/icons";
import { AiOutlineDelete } from "react-icons/ai";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { ConfirmDeleteModal } from "screens/common/components/ConfirmDeleteModal";
import { removeMemberFromOrganization, updateMemberRole } from "state/organization/operations";
import capitalize from "lodash/capitalize";
import type { Role } from "types/organization/Role";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { InviteUsersPanel } from "screens/landing/tabs/admin/organizationsAdmin/modals/InviteUsersPanel";
import type { ModalKey } from "types/modal";

export const OrganizationAdminWrapper = () => {
  const { id } = useParams();
  const organization = useOrganization(id ?? null);

  if (!organization) {
    return <TypingIndicator color="transparent" />;
  } else {
    return <OrganizationAdmin organization={organization} />;
  }
};

export const OrganizationAdmin = ({ organization }: { organization: Organization }) => {
  const dispatch = useAppDispatch();
  const { setHeaderText } = useContext(SettingsProviderContext);
  const { id } = useUserProfile();
  const [isOpen, setIsOpen] = useState<ModalKey | null>();
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
  const textColor = useColorModeValue("gray.700", "gray.100");
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const commonButtonProps = useButtonProps("sm", "primary");
  const isLoading = useOrganizationIsLoading();

  const onClose = useCallback(() => setIsOpen(null), []);

  const onDeleteMember = useCallback(() => {
    if (!selectedMemberId) {
      return;
    }

    dispatch(removeMemberFromOrganization({ organizationId: organization.id, memberId: selectedMemberId }));

    onClose();
  }, [onClose, dispatch, organization, selectedMemberId]);

  const onChangeUserRole = async (userId: string, role: Role) => {
    await dispatch(updateMemberRole({ organizationId: organization.id, memberId: userId, role }));
  };

  useEffect(() => {
    setHeaderText("Organization");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box mb={8} color={textColor} borderRadius={"md"} padding={"4"} bgColor={bgColor}>
        <Box display={"flex"} gridGap={"10"}>
          <Box>
            <Text fontSize="sm">Organization name</Text>
            <Heading>{organization.name}</Heading>
          </Box>
          <Box>
            <Text fontSize="sm">Plan</Text>
            <Heading>
              <Badge colorScheme="green" fontSize="2xl">
                {organization.planCode}
              </Badge>
            </Heading>
          </Box>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button {...commonButtonProps} onClick={() => setIsOpen("invite-users")}>
          Invite new users
        </Button>
      </Box>

      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {(organization.members || [])
              .filter((member) => member.userId !== id)
              .map((member) => (
                <Tr key={member.userId}>
                  <Td>{member.userName}</Td>
                  <Td>{member.email}</Td>
                  <Td>
                    <Select
                      disabled={isLoading}
                      value={member.role}
                      onChange={(evt) => {
                        onChangeUserRole(member.userId, evt.target.value as Role);
                      }}>
                      {["member", "admin"].map((role) => (
                        <option key={role} value={role}>
                          {capitalize(role)}
                        </option>
                      ))}
                    </Select>
                  </Td>
                  <Td>
                    <Tooltip aria-label="Delete member" label="Delete member" placement="top" hasArrow>
                      <IconButton
                        colorScheme={"red"}
                        cursor="pointer"
                        icon={<Icon as={AiOutlineDelete} boxSize="1.5rem" />}
                        aria-label="Delete"
                        onClick={async (event: { stopPropagation: () => void }) => {
                          event.stopPropagation();

                          setSelectedMemberId(member.userId);
                          setIsOpen("delete");
                        }}
                      />
                    </Tooltip>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      {isOpen === "delete" && (
        <ConfirmDeleteModal
          title="Delete confirmation"
          body="Are you sure you want to remove this member?"
          isOpen
          onClose={onClose}
          onConfirm={onDeleteMember}
        />
      )}
      {isOpen === "invite-users" && <InviteUsersPanel onClose={onClose} id={organization.id} />}
    </>
  );
};
