import { createAction, createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import type { AuthToken, User } from "types/auth";
import type { RootState } from "../rootReducer";
import { errorReducer, pendingReducer, successReducer } from "./actionReducers";
import {
  loginWithAstrellaAccessToken,
  loginWithApple,
  loginWithCredentials,
  loginWithIdentityProvider,
  loginWithRefreshToken,
  refreshToken,
  signupWithCredentials,
  updateUser,
  verifyEmail,
  signupWithIdentityProvider,
} from "./operations";

export interface SessionState {
  isLoggedIn: boolean; // Is session active
  isAuthorized: boolean; // Is there a valid session + refresh token
  isLoading: boolean; // Is request with keycloak pending
  isProfileUpdatePending: boolean; // Is there are pending request to update the user's profile?
  token?: AuthToken; // Refresh + access token
  user?: User; // User identity
  lastError?: string; // Last exception
}

const initialState: SessionState = {
  isLoggedIn: false,
  isAuthorized: false,
  isLoading: false,
  isProfileUpdatePending: false,
};

const rehydrate = createAction<RootState>(REHYDRATE);

export const { actions, reducer } = createSlice({
  name: "session",
  initialState,
  reducers: {
    flush(state) {
      return {
        ...initialState,
        lastError: state.lastError,
      };
    },
    loginFailed(_, action) {
      return {
        ...initialState,
        lastError: action.payload.error,
      };
    },
    logout() {
      return initialState;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    for (const operation of [
      loginWithCredentials,
      loginWithIdentityProvider,
      loginWithRefreshToken,
      loginWithApple,
      loginWithAstrellaAccessToken,
      signupWithCredentials,
      signupWithIdentityProvider,
    ]) {
      builder.addCase(operation.pending, pendingReducer);
      builder.addCase(operation.fulfilled, successReducer);
      builder.addCase(operation.rejected, errorReducer);
    }
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.token = action.payload.token;
    });
    builder.addCase(refreshToken.rejected, errorReducer);
    builder.addCase(rehydrate, (_, action) => {
      if (action.payload?.session) {
        return {
          ...action.payload?.session,
          isLoggedIn: false,
          isLoading: false,
          lastError: undefined,
        };
      }
    });
    builder.addCase(updateUser.pending, (state, action) => {
      state.isProfileUpdatePending = true;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.isProfileUpdatePending = false;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (state.user) {
        return {
          ...state,
          isProfileUpdatePending: false,
          user: {
            ...state.user,
            firstName: action.meta.arg.firstName,
            lastName: action.meta.arg.lastName,
          },
        };
      }
      return state;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      if (state.user) {
        return {
          ...state,
          user: {
            ...state.user,
            isEmailVerified: action.payload,
          },
        };
      } else {
        return state;
      }
    });
  },
});

export default reducer;
