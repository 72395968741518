import React, { useCallback, useContext, useMemo } from "react";
import type { ReactNode } from "react";
import { Wizard } from "react-use-wizard";
import { Box, Center, Icon, Select, Stack, Switch, Text } from "@chakra-ui/react";
import { VscSettings } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useCollection, useEntitlementKey, useTileProps } from "hooks";
import { LandingTitle } from "screens/landing/components";
import { EditableInput } from "screens/content/common/TwoColumnTable/EditableInput";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { portfolioSelectors } from "screens/landing/components/popoverComponent/NewPortfolioModal";
import EditableQuestionList from "screens/common/EditableQuestionList";

const getPortfolioThemeName = (portfolioTheme: string | undefined) => {
  const selectedPortfolio = portfolioSelectors.find((selector) => selector.value === portfolioTheme);
  return selectedPortfolio ? selectedPortfolio.label : "Coming Soon";
};

export const PortfolioSettingsPanel = () => {
  const { projectFilter } = useParams();
  const portfolioProject = useCollection(projectFilter);
  const { isPortfolioSettingsOpen, onPortfolioSettingsClose } = useAddToCharliContext();
  const commonTileProps = useTileProps();
  const dispatch = useDispatch();
  const { setHeaderText } = useContext(SettingsProviderContext);
  const showPortfolioQuestions = useEntitlementKey("ui_enable_portfolio_questions");

  const onHandleEdit = useCallback(
    (entity: string, value: string) => {
      if (!portfolioProject) return;
      const entities = [
        { entity: "collection_id", value: portfolioProject.id },
        { entity, value },
      ];
      setHeaderText(value);
      dispatch(
        sendMessage({
          conversationId: portfolioProject.conversationId || "",
          intent: "/edit_collection",
          entities,
        })
      );
    },
    [dispatch, portfolioProject, setHeaderText]
  );

  const panelHeader: ReactNode = useMemo(
    () => (
      <Stack spacing="0" className="ch-verified-panel">
        <Box bgColor="#81c34c" height="1rem" width="100%" />
        <Stack direction="row" justifyContent="space-between" backgroundColor="gray.100" px="1.5rem" py="1rem">
          <Stack width="100%" justifyContent="space-between">
            <Text fontSize="md" fontWeight="semibold">
              Portfolio Settings
            </Text>
            <Text fontSize="sm" fontWeight="normal">
              This page provides configuration and setting information over all the projects in a portfolio and allows you to determine the
              primary theme as well as add default questions for the AI.
            </Text>
          </Stack>
          <Center>
            <Icon as={VscSettings} boxSize={["2rem", "2.5rem", "3rem"]} color="#81c34c" ml="1.5rem" />
          </Center>
        </Stack>
      </Stack>
    ),
    []
  );

  const settingItem = (label: string, content: ReactNode, index: number) => (
    <Stack key={index} direction="row" justifyContent="space-between" width="100%" spacing="1rem">
      <Text fontSize="sm" width="14rem">
        {label}
      </Text>
      <Box alignContent={"flex-start"} width="100%">
        {content}
      </Box>
    </Stack>
  );

  const optionOne = useMemo(
    () => [
      { label: "Monthly", value: "1" },
      { label: "3 Months", value: "2" },
      { label: "6 Months", value: "6" },
    ],
    []
  );

  const optionTwo = useMemo(
    () => [
      { label: "3 Months", value: "3" },
      { label: "6 Months", value: "6" },
      { label: "12 Months", value: "12" },
    ],
    []
  );

  const panelContent: ReactNode = useMemo(() => {
    const settings = [
      {
        label: "Name",
        content: (
          <EditableInput
            id={portfolioProject?.id || undefined}
            value={portfolioProject?.name}
            entity="name"
            onEdit={onHandleEdit}
            isEditEnabled
            size="sm"
            maxHeight="1.4rem"
          />
        ),
      },
      {
        label: "Theme",
        content: (
          <Text fontSize="sm" width="100%">
            {getPortfolioThemeName(portfolioProject?.portfolioSettings?.primaryTheme)}
          </Text>
        ),
      },
      {
        label: "Recurring",
        content: <Switch isDisabled width="100%" alignSelf="left" size="sm" colorScheme="primary" defaultChecked={false} />,
      },
      {
        label: "Frequency",
        content: (
          <Select isDisabled size="sm" width="10rem" defaultValue="1">
            {optionOne.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        ),
      },
      {
        label: "Timeseries Range",
        content: (
          <Select isDisabled size="sm" width="10rem" defaultValue="3">
            {optionTwo.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        ),
      },
      {
        label: "News Time Span",
        content: (
          <Select isDisabled size="sm" width="10rem" defaultValue="3">
            {optionOne.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        ),
      },
    ];

    return (
      <Stack spacing="1rem">
        <Stack {...commonTileProps} pb="1rem" spacing="1rem" cursor="default">
          <LandingTitle style={{ width: "15rem" }} underline text="Portfolio Settings" color="primary.default" />
          <Stack spacing="1rem">{settings.map(({ label, content }, index) => settingItem(label, content, index))}</Stack>
        </Stack>
        <Stack {...commonTileProps} pb="1rem" spacing="1rem" cursor="default" mb="1rem">
          <LandingTitle style={{ width: "22rem" }} underline text="Your Questions for this Portfolio" color="primary.default" />
          {showPortfolioQuestions && !!portfolioProject?.id ? (
            <EditableQuestionList portfolioId={portfolioProject.id} portfolioSettings={portfolioProject.portfolioSettings} />
          ) : (
            <Text fontSize="sm" color="primary.darkGray">
              Advanced Configurations Available in the Business and Premium Packages
            </Text>
          )}
        </Stack>
      </Stack>
    );
  }, [
    portfolioProject?.id,
    portfolioProject?.name,
    onHandleEdit,
    optionOne,
    optionTwo,
    commonTileProps,
    showPortfolioQuestions,
    portfolioProject?.portfolioSettings,
  ]);

  return (
    <PanelView
      maxWidth={["100vw", "80vw", "80vw", "50vw", "50vw"]}
      isOpen={isPortfolioSettingsOpen}
      onClose={onPortfolioSettingsClose}
      panelTitle="Portfolio Settings"
      className="ch-panel-portfolio-settings"
      panelHeader={panelHeader}>
      <Wizard>
        <PanelStep>{panelContent}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
