import { Box } from "@chakra-ui/react";
import { useTags } from "hooks";
import React, { useMemo } from "react";
import type { ReactSelectValues } from "types/tags";
import type { Tag } from "./Tags";
import { Tags } from "./Tags";

interface Props {
  setTags?: (tags: string[]) => void;
  onSaveTag?: (tag: string) => void;
  onDeleteTag?: (tag: string) => void;
  onUpdateTag?: (tags: Tag[]) => void;
  allowUpdate?: boolean;
  allowDelete?: boolean;
  tags: string[];
  tagType?: "manual" | "auto" | "category" | "topic";
  options?: NonNullable<ReactSelectValues>;
  placeholder?: string;
  hideInput?: boolean;
  size?: "xs" | "sm" | "md";
  searchPhrase?: string;
  onClickTag?: (tag: string) => void;
  maxTagsToShow?: number;
  allowFilter?: boolean;
}

export const TagInput = ({
  tags,
  setTags,
  placeholder = "Select or enter a tag...",
  tagType = "manual",
  options,
  onSaveTag,
  onDeleteTag,
  onUpdateTag,
  hideInput,
  allowUpdate = true,
  allowDelete = true,
  size = "sm",
  searchPhrase,
  onClickTag,
  maxTagsToShow,
  allowFilter,
}: Props) => {
  const { tagsAsSelectValues } = useTags({
    tags,
    setTags,
    normalizeTags: tagType !== "topic",
  });

  const suggestions = useMemo(() => {
    return options
      ? options.map((option) => ({ id: option.value, text: option.label }))
      : tagsAsSelectValues.map((tag) => ({ id: tag.value, text: tag.label }));
  }, [options, tagsAsSelectValues]);

  const selectedTags = useMemo(() => {
    return tags.map((tag) => ({ id: tag, text: tag }));
  }, [tags]);

  return (
    <Box mt="0!important" className="ch-tag-input">
      <Tags
        tagType={tagType}
        id="tags-input"
        tags={selectedTags}
        suggestions={suggestions}
        placeholder={placeholder}
        handleOnSaveTag={onSaveTag}
        handleOnDeleteTag={onDeleteTag}
        handleUpdate={onUpdateTag}
        hideInput={hideInput}
        allowUpdate={allowUpdate}
        allowDelete={allowDelete}
        size={size}
        searchPhrase={searchPhrase}
        onClickTag={onClickTag}
        maxTagsToShow={maxTagsToShow}
        allowFilter={allowFilter}
      />
    </Box>
  );
};
