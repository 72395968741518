import React, { useRef } from "react";
import { Box, Button, Flex, FormControl, FormHelperText, FormLabel, Input, Text, VisuallyHiddenInput } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useButtonProps } from "hooks";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export interface ImportTickersForm {
  file: File | undefined;
  exchange: string;
}

interface IProps {
  onSubmit: (values: ImportTickersForm) => void;
  isLoading?: boolean;
}

interface IPanelProps extends IProps {
  onClose: () => void;
  isOpen: boolean;
}

const validationSchema = yup.object().shape({
  file: yup
    .mixed<File>()
    // .required("You need to provide a file")
    .test("required", "You need to provide a file", (file) => {
      // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
      if (file) return true;
      return false;
    })
    .test("fileSize", "The file is too large", (file) => {
      return file && file.size <= 2000000;
    }),
  exchange: yup.string().required("Exchange is required"),
});

const ImportTickers = ({ onSubmit, isLoading }: IProps) => {
  const { handleSubmit, setValue, control } = useForm<ImportTickersForm>({
    defaultValues: {
      file: undefined,
      exchange: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const fileRef = useRef<HTMLInputElement>(null);
  const commonButtonProps = useButtonProps("sm", "primary");

  const file = useWatch({ control, name: "file" });

  const handleFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!evt.target.files || !evt.target.files[0]) {
      return;
    }

    const file = evt.target.files[0];
    setValue("file", file);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl mb={"1rem"}>
        <FormLabel htmlFor="file">Upload CSV File</FormLabel>
        <VisuallyHiddenInput onChange={handleFile} ref={fileRef} accept=".csv" type="file" />
        <Button
          isLoading={isLoading}
          onClick={() => {
            if (file?.name) {
              setValue("file", undefined);
            } else {
              fileRef.current?.click();
            }
          }}
          width="6rem"
          {...commonButtonProps}>
          {file?.name ? "Clear" : "Import"}
        </Button>
        {file?.name && <Text fontSize={"x-small"}>{file.name}</Text>}
      </FormControl>

      <Controller
        control={control}
        name="exchange"
        render={({ field, formState: { errors } }) => {
          return (
            <FormControl isInvalid={!!errors.exchange}>
              <FormLabel>Exchange</FormLabel>
              <Input {...field} type="text" placeholder="Type exchange value" isDisabled={isLoading} />
              {errors.exchange && <FormHelperText color="red">{errors.exchange.message}</FormHelperText>}
            </FormControl>
          );
        }}
      />

      <Flex mt={"1rem"} justifyContent={"flex-end"}>
        <Button isLoading={isLoading} type="submit" {...commonButtonProps}>
          Import tickers
        </Button>
      </Flex>
    </form>
  );
};

const ImportTickersPanel = (props: IPanelProps) => {
  const { onClose, isOpen, ...rest } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={"Import tickers from csv file"}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem">{isOpen && <ImportTickers {...rest} />}</Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};

export { ImportTickersPanel };
