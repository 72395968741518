import { prependProxyBaseUrl, request } from "api/request";
import { GetProjectConfigurationsResponse } from "./models/GetProjectConfigurationsResponse";
import { ProjectConfig } from "./models/ProjectConfig";

const BASE_URL = prependProxyBaseUrl("/api/project-configuration");
const DELETE_PROJECT_CONFIGURATION_URL = (id: string) => `${BASE_URL}/${id}`;

export const getProjectConfigurations = async (): Promise<GetProjectConfigurationsResponse> => {
  const json = await request().url(BASE_URL).get().json();

  return GetProjectConfigurationsResponse.check(json);
};

export const createProjectConfiguration = async (config: unknown): Promise<ProjectConfig> => {
  const json = await request().url(BASE_URL).post({ config }).json();

  return ProjectConfig.check(json);
};

export const updateProjectConfiguration = async (id: string, config: unknown): Promise<ProjectConfig> => {
  const json = await request().url(BASE_URL).put({ id, config }).json();

  return ProjectConfig.check(json);
};

export const deleteProjectConfiguration = async (id: string): Promise<void> => {
  await request().url(DELETE_PROJECT_CONFIGURATION_URL(id)).delete().res();
};

export const bulkUpsertProjectConfiguration = async (configs: { config: unknown }[]): Promise<GetProjectConfigurationsResponse> => {
  const json = await request().url(`${BASE_URL}/bulk`).post(configs).json();

  return GetProjectConfigurationsResponse.check(json);
};
