import { parseISO } from "date-fns";
import type { NormalizedConversations, NormalizedMessages } from "state/conversation/operations";

export const getMessagesForConversation = (
  conversations: NormalizedConversations,
  messages: NormalizedMessages,
  id: string,
  viewId?: string
) => {
  if (conversations[id] && messages) {
    const messageIds = conversations[id].messages;
    return Object.entries(messages)
      .filter((message) => messageIds.includes(message[0]))
      .map((message) => message[1])
      .filter((message) => (viewId ? message.viewId === viewId : !message.viewId))
      .sort((a, b) => (a.createdTime || parseISO(a.createdDate).getTime()) - (b.createdTime || parseISO(b.createdDate).getTime()));
  } else {
    return [];
  }
};
