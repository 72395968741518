import type { CouldNotChooseTask } from "./childWorkflowErrorDetails/CouldNotChooseTask";
import type { CouldNotResolveRequiredEntities } from "./childWorkflowErrorDetails/CouldNotResolveRequiredEntities";
import type { IntentNotFound } from "./childWorkflowErrorDetails/IntentNotFound";
import type { NoSuitableTaskFound } from "./childWorkflowErrorDetails/NoSuitableTaskFound";
import type { TaskWithIntentExistsButFailed } from "./childWorkflowErrorDetails/TaskWithIntentExistsButFailed";

export enum WorkflowTaskStatus {
  queued = "queued",
  inProgress = "in_progress",
  failed = "failed",
  incomplete = "incomplete",
  clarificationNeeded = "clarification_needed",
  complete = "complete",
  error = "error",
  cancelled = "cancelled",
  deniedIntentConfirmation = "denied_intent_confirmation",
  aborted = "aborted",
  awaitingAyncTask = "awaiting_async_task",
  failedCheckstop = "failed_checkstop",
  paused = "paused",
}

export interface Workflow {
  id: string;
  title: string;
  userId: string;
  userName?: string;
  conversationId: string | undefined;
  creationDate: string;
  intent: string;
  status: WorkflowTaskStatus;
  childWorkflows: ChildWorkflow[];
  completionDate?: string;
  loading?: boolean;
  dismissed: boolean;
  progress?: Progress;
  canBeRetried?: boolean;
  progressByChildWorkflow?: Record<string, { intent: string; progress: Progress }>;
  events: { type: "milestone"; label: string; creationDate: string; checkpointId: string; skipped?: boolean }[];
  startDate?: string;
}

export interface WorkflowWithOnlyId {
  id: string;
}

export type ChildWorkflowErrorDetails =
  | CouldNotChooseTask
  | IntentNotFound
  | NoSuitableTaskFound
  | CouldNotResolveRequiredEntities
  | TaskWithIntentExistsButFailed;

export interface ChildWorkflow {
  id: string;
  intent: string;
  inputEntities: Entity[] | undefined; // always provided by backend, but optional because of existing data stored in local storage
  creationDate: string;
  completionDate?: string;
  status: WorkflowTaskStatus;
  errorDetails?: ChildWorkflowErrorDetails;
  workflowSteps: ChildWorkflowStep[];
}

export enum CheckpointStatus {
  pending = "pending",
  complete = "complete",
  failed = "failed",
  progress = "in_progress",
  skipped = "skipped",
}

export interface Progress {
  task?: string;
  taskId?: string;
  completedStepCount: number;
  estimatedTotalStepCount: number;
}

export interface TaskUpdate {
  taskId?: string;
  workflowId: string;
  childWorkflowId: string;
  name: string;
  status?: WorkflowTaskStatus;
}

export type ChildWorkflowStep = ClarificationDetail | TaskDetail | PromptDetail | EventDetail;

export interface EventDetail {
  type: "event";
  title: string;
  description: string;
  creationDate: string;
}

export interface ClarificationDetail {
  type: "clarification";
  entity: Entity;
  completionDate: string;
}

export interface PromptDetail {
  type: "prompt";
  title: string;
  promptType: string;
  creationDate: string;
  executionContext: string[] | undefined; // always provided by backend, but optional because of existing data stored in local storage
}

export interface TaskDetail {
  type: "task";
  id: string;
  name: string;
  registeredTaskId: string;
  completionDate?: string;
  startDate?: string;
  status: WorkflowTaskStatus;
  urn?: string;
  intent?: string;
  requires?: string[];
  optionalRequires?: string[];
  requirements?: string[];
  produces?: string[];
  postExecutionIntents?: string[];
  creationDate: string;
  error?: {
    status: string;
    message: string;
    details?: unknown;
  };
  shouldIncludeEntities?: string[];
  mustIncludeEntities?: string[];
  executionContext: string[] | undefined; // always provided by backend, but optional because of existing data stored in local storage
}

export interface Entity {
  entity: string;
  value: unknown;
}
