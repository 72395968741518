import type { Static } from "runtypes";
import { Number, Record, String } from "runtypes";

export const ExtractedHighlightsDetail = Record({
  confidence: Number,
  record_id: String,
  id: String,
  highlight: String,
});

export type ExtractedHighlightsDetail = Static<typeof ExtractedHighlightsDetail>;
