import React, { useCallback, useEffect, useState } from "react";
import {
  Tag,
  Box,
  Button,
  Text,
  SimpleGrid,
  Stack,
  useToast,
  useColorModeValue,
  Input,
  InputGroup,
  InputLeftElement,
  useDisclosure,
} from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import type { User } from "types/auth";
import { fetchUserCredentialType, forceVerifyUser, sendPasswordResetEmail } from "api/user";
import { useButtonProps, useEntitlements } from "hooks";
import {
  useUserEntitlements,
  useOrganizationsLoading,
  useEntitlementsLoading,
  useUserBillingPlan,
  useBillingPlanLoading,
} from "hooks/useUsers";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { SearchIcon } from "@chakra-ui/icons";
import { OrganizationModal } from "../../organizationsAdmin/modals/OrganizationModal";
import { EntitlementConfigModal } from "../../entitlements/modals/EntitlementConfigModal";
import { LoadingGate } from "screens/common/components";
import { FilterIntegrationsView, IntegrationsFilterContextProvider } from "screens/landing/tabs/integrations/components";
import { IntegrationsRouter } from "screens/landing/tabs/integrations/components/IntegrationsRouter";
import type { Integration } from "api/integrations";
import { getSupportedIntegrationsByUser } from "api/integrations";
import { formatDate } from "screens/common/modal/formatters";

interface IProps {
  user: User;
  isOpen: boolean;
  onClose: () => void;
}

export const UserDetailModal = (props: IProps) => {
  const { isOpen, onClose, user } = props;
  const toast = useToast();
  const [credentialType, setCredentialType] = useState<string | null>(null);
  const userEntitlements = useUserEntitlements(user.id);
  const userPlan = useUserBillingPlan(user.id);
  const isOrgLoading = useOrganizationsLoading(user.id);
  const isEntLoading = useEntitlementsLoading(user.id);
  const isBillingLoading = useBillingPlanLoading(user.id);
  const [searchText, setSearchText] = useState("");
  const subTitleColor = useColorModeValue("charli.mediumGray", "gray.400");
  const titleColor = useColorModeValue("gray.900", "gray.500");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { isOpen: isOrgPanelOpen, onOpen: onOrgPanelOpen, onClose: onOrgPanelClose } = useDisclosure();
  const [selectedCode, setSelectedCode] = useState<string | null>(null);
  const { isOpen: isEntitlementPanelOpen, onOpen: onEntitlementPanelOpen, onClose: onEntitlementPanelClose } = useDisclosure();
  const { manage_billing_plans_read: hasPermissionForReadBillingPlan } = useEntitlements();
  const [userIntegrations, setUserIntegrations] = useState<Integration[]>([]);
  const [isAdminIntegrationsByUserLoading, setIsAdminIntegrationsByUserLoading] = useState<boolean>(false);

  useEffect(() => {
    setUserIntegrations([]);
    setIsAdminIntegrationsByUserLoading(true);
    getSupportedIntegrationsByUser(user.id)
      .then((res) => setUserIntegrations(res))
      .catch(() => setUserIntegrations([]))
      .finally(() => setIsAdminIntegrationsByUserLoading(false));
  }, [user.id]);

  const verifyUser = async () => {
    try {
      await forceVerifyUser(user.id, user.email);
      toast({
        title: "User verified!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      console.error("Failed to verify user", err);
      toast({
        title: "User could not be verified!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const sendResetPassword = async () => {
    const isSuccess = await sendPasswordResetEmail(user.email);

    if (isSuccess) {
      toast({
        title: "Password reset sent!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Password reset could not be sent.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchFederatedIdentity = useCallback(
    async (id: any) => {
      try {
        const federatedIdentity = await fetchUserCredentialType(id);

        setCredentialType(federatedIdentity.length > 0 ? federatedIdentity[0].identityProvider : "password");
      } catch (err: unknown) {
        const errorMessage = "Error when fetching user credential type: ";
        if (err instanceof Error) {
          toast({
            title: errorMessage + err.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

        console.error(errorMessage, err);
      }
    },
    [toast]
  );

  useEffect(() => {
    fetchFederatedIdentity(user.id);
  }, [user.id, fetchFederatedIdentity]);

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`User Details: ${user.firstName} ${user.lastName}`}>
      <Wizard>
        <PanelStep>
          <Stack spacing="1rem" my="1rem">
            {user.creationDate && (
              <Stack direction="row" alignItems="center">
                <Text color={titleColor} fontSize="md" minWidth="8rem">
                  Creation Date
                </Text>
                <Text color={subTitleColor} fontSize="md">
                  {formatDate(new Date(user.creationDate))}
                </Text>
              </Stack>
            )}
            <Stack direction="row" alignItems="center">
              <Text color={titleColor} fontSize="md" minWidth="8rem">
                ID
              </Text>
              <Tag bgColor={"gray.100"} size="md">
                {String(user.id)}
              </Tag>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Text color={titleColor} fontSize="md" minWidth="8rem">
                Name
              </Text>
              <Text color={subTitleColor} fontSize="md">
                {`${user.firstName} ${user.lastName}`.trim()}
              </Text>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Text color={titleColor} fontSize="md" minWidth="8rem">
                Email:
              </Text>
              <Text color={subTitleColor} fontSize="md">
                {user.email}
              </Text>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Text color={titleColor} fontSize="md" minWidth="8rem">
                Billing Plan:
              </Text>
              <Text color={subTitleColor} fontSize="md">
                {!hasPermissionForReadBillingPlan ? (
                  "You don't have the entitlement for reading user billing plan"
                ) : isBillingLoading ? (
                  <TypingIndicator />
                ) : (
                  userPlan?.name ?? "No billing plan assigned"
                )}
              </Text>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Text color={titleColor} fontSize="md" minWidth="8rem">
                Is verified:
              </Text>
              <Tag bgColor={!user.isEmailVerified ? "red.100" : "gray.100"} size="md">
                {String(user.isEmailVerified).toUpperCase()}
              </Tag>
            </Stack>
            {user.passwordResetCode && (
              <Stack direction="row" alignItems="center">
                <Text color={titleColor} fontSize="md" minWidth="8rem">
                  Reset link:
                </Text>
                <Tag bgColor={!user.isEmailVerified ? "red.100" : "gray.100"} size="md">
                  {`${window.location.origin}/reset?code=${user.passwordResetCode}`}
                </Tag>
              </Stack>
            )}
            <Stack direction="row" alignItems="center">
              <Text color={titleColor} fontSize="md" minWidth="8rem">
                Login type:
              </Text>
              {credentialType && credentialType?.length > 0 && <Tag size="md">{credentialType?.toUpperCase()}</Tag>}
            </Stack>
            <Stack direction="row" alignItems="center">
              <Text color={titleColor} fontSize="md" minWidth="8rem">
                Organizations:
              </Text>
              {isOrgLoading ? (
                <TypingIndicator />
              ) : user.organizations && user.organizations.length > 0 ? (
                <>
                  {user.organizations.map((org) => {
                    return (
                      <Button
                        color={subTitleColor}
                        fontSize="md"
                        fontWeight="normal"
                        variant="link"
                        key={org.organizationName}
                        onClick={() => {
                          onOrgPanelOpen();
                          setSelectedId(org.organizationId);
                        }}>
                        {org.organizationName}
                      </Button>
                    );
                  })}
                </>
              ) : (
                <Text color={subTitleColor}>No Organizations</Text>
              )}
            </Stack>

            <Stack direction="row" alignItems="center">
              <Text color={titleColor} fontSize="md" minWidth="8rem">
                Actions:
              </Text>
              <Button onClick={verifyUser} {...secondaryButtonProps} disabled={user.isEmailVerified}>
                Force verify user
              </Button>
              <Button onClick={sendResetPassword} {...secondaryButtonProps}>
                Send Reset Password
              </Button>
            </Stack>
            <Stack direction="column" pt="2rem">
              <Text color={titleColor} fontSize="md">
                Entitlements:
              </Text>
              {isEntLoading ? (
                <TypingIndicator color="transparent" />
              ) : userEntitlements && userEntitlements.length > 0 ? (
                <Stack>
                  <InputGroup size="sm" maxWidth="30rem" pb=".5rem">
                    <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
                    <Input
                      {...secondaryButtonProps}
                      defaultValue={searchText}
                      placeholder="Filter by entitlement name"
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </InputGroup>
                  <SimpleGrid columns={3} spacingX="1rem" spacingY="1rem" pb="0.5rem">
                    {userEntitlements
                      .filter((entitlement) => entitlement.name?.toLowerCase().includes(searchText.toLowerCase()))
                      .map((entitlement) => (
                        <Box
                          cursor={"pointer"}
                          borderWidth="2px"
                          borderRadius="lg"
                          padding="1rem"
                          key={entitlement.entitlementId}
                          onClick={() => {
                            onEntitlementPanelOpen();
                            setSelectedCode(entitlement.entitlementId);
                          }}>
                          <Stack mb="1rem" spacing={2}>
                            <Box overflow={"hidden"}>
                              <Text fontWeight={"semibold"} fontSize="md">
                                Entitlement:
                              </Text>
                              <Text fontSize="md">{entitlement.entitlementId}</Text>
                            </Box>
                            {entitlement.description && (
                              <Box overflow={"hidden"}>
                                <Text fontWeight={"semibold"} fontSize="md">
                                  Description:
                                </Text>
                                <Text fontSize="md">{entitlement.description}</Text>
                              </Box>
                            )}
                          </Stack>
                        </Box>
                      ))}
                  </SimpleGrid>
                </Stack>
              ) : (
                <Text>No entitlements</Text>
              )}
            </Stack>
            <Stack direction="column" pt="2rem">
              <Text color={titleColor} fontSize="md">
                Integrations:
              </Text>
              <LoadingGate isLoading={isAdminIntegrationsByUserLoading}>
                <Stack spacing="1rem" height="100%" width="100%">
                  <IntegrationsFilterContextProvider cells={userIntegrations}>
                    <FilterIntegrationsView />
                    <IntegrationsRouter disableTileActions />
                  </IntegrationsFilterContextProvider>
                </Stack>
              </LoadingGate>
            </Stack>
          </Stack>
          <EntitlementConfigModal code={selectedCode} isOpen={isEntitlementPanelOpen} onClose={onEntitlementPanelClose} />
          {isOrgPanelOpen && <OrganizationModal id={selectedId} onClose={onOrgPanelClose} />}
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
