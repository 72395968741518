import React from "react";
import { Text } from "@chakra-ui/react";
import { useEntitlements } from "hooks";
import type { EntitlementId } from "types/entitlements";

interface IProps {
  children: JSX.Element;
  entitlement: EntitlementId | EntitlementId[];
  headerMessageComponent?: JSX.Element;
}

export const RenderIfHasEntitlements = ({ children, entitlement, headerMessageComponent }: IProps) => {
  const entitlements = useEntitlements();

  if (Array.isArray(entitlement) ? entitlement.some((entitle) => !entitlements[entitle]) : !entitlements[entitlement]) {
    return (
      <>
        {headerMessageComponent}
        <Text>You have no permissions for viewing this section.</Text>
      </>
    );
  }

  return children;
};
