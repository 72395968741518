import React, { useMemo } from "react";
import type { Control } from "react-hook-form";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Box, FormControl, Text } from "@chakra-ui/react";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";
import { useConfiguredWorkflowsMostUsedEntities } from "hooks/useConfiguredWorkflows";
import {
  AutoComplete,
  AutoCompleteCreatable,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";

interface IProps {
  control?: Control<FormValues>;
  checkpointIndex: number;
}

export const IncludedEntitiesFieldArray = (props: IProps) => {
  const { checkpointIndex } = props;
  const { control } = useFormContext<FormValues>();
  const mostUsedEntities = useConfiguredWorkflowsMostUsedEntities();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `config.checkpoints.${checkpointIndex}.onlyIncludeTheseEntities`,
  });

  const onlyIncludeTheseEntities = useWatch({ control, name: `config.checkpoints.${checkpointIndex}.onlyIncludeTheseEntities` });

  const controlledFields = useMemo(
    () =>
      fields.map((field, index) => ({
        ...field,
        ...(onlyIncludeTheseEntities ? onlyIncludeTheseEntities[index] : {}),
      })),
    [fields, onlyIncludeTheseEntities]
  );

  const dedupedEntities = useMemo(() => {
    const entities = controlledFields.reduce((acc, { value }) => {
      acc.add(value);
      return acc;
    }, new Set<string>());

    return mostUsedEntities.filter((entity) => !entities.has(entity));
  }, [controlledFields, mostUsedEntities]);

  return (
    <Box backgroundColor={"transparent"}>
      <FormControl>
        <AutoComplete
          onSelectOption={({ item }) => {
            append({ value: item.value });
          }}
          filter={(inputValue, option) => option.toLowerCase().includes(inputValue.toLowerCase())}
          closeOnBlur
          multiple
          value={controlledFields.map(({ value }) => value)}
          creatable
          freeSolo
          openOnFocus>
          <AutoCompleteInput>
            {({ tags }) =>
              tags.map((tag, tid) => (
                <AutoCompleteTag
                  key={tid}
                  label={tag.label}
                  onRemove={() => {
                    remove(tid);
                  }}
                />
              ))
            }
          </AutoCompleteInput>
          <AutoCompleteList>
            {dedupedEntities.map((entity) => (
              <AutoCompleteItem key={`option-${entity}`} value={entity} label={entity} align="center" />
            ))}
            <AutoCompleteCreatable>{({ value }) => <Text as="span">Add {value} to entities list</Text>}</AutoCompleteCreatable>
          </AutoCompleteList>
        </AutoComplete>
      </FormControl>
    </Box>
  );
};
