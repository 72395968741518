import { prependProxyBaseUrl, request } from "api/request";
import { Array } from "runtypes";
import { ContentDetailsResponse } from "./models/ContentDetailsResponse";
import { RecentContentDetailsResponse } from "./models/RecentContentDetailsResponse";
import { RecentlyModifiedMetadataIds } from "./models/RecentlyModifiedMetadataIds";

const CONTENT_ENDPOINT = prependProxyBaseUrl("/api/contents/contents");
const RECENT_CONTENT_ENDPOINT = prependProxyBaseUrl("/api/contents/recent-content");
const RECENTLY_MODIFIED_METADATA_IDS_ENDPOINT = prependProxyBaseUrl("/api/contents/recently-modified-metadata-ids");

export async function getContent(metadataIds: string[], tags?: string[]) {
  const url = `${CONTENT_ENDPOINT}`;

  try {
    const contentsResponse = await request()
      .url(url)
      .query({ metadataId: metadataIds, ...(tags && { tags }) })
      .get()
      .json<{ content: unknown }>();

    const validationResult = Array(ContentDetailsResponse).validate(contentsResponse.content);

    if (validationResult.success) {
      return validationResult.value;
    } else {
      console.error(validationResult.details);
      return "error";
    }
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getRecentContents(limit = 50): Promise<RecentContentDetailsResponse> {
  try {
    const contentsResponse = await request().url(`${RECENT_CONTENT_ENDPOINT}`).query({ limit }).get().json();
    const areRecentContentDetails = RecentContentDetailsResponse.validate(contentsResponse);

    if (!areRecentContentDetails.success) {
      console.error(areRecentContentDetails);
      throw new Error(areRecentContentDetails.message);
    }

    return areRecentContentDetails.value;
  } catch (error) {
    return { totalCount: 0, metadataIds: [] };
  }
}

export async function getRecentlyModifiedMetadataIds(fromDate: string): Promise<RecentlyModifiedMetadataIds> {
  try {
    const contentsResponse = await request().url(RECENTLY_MODIFIED_METADATA_IDS_ENDPOINT).query({ fromDate }).get().json();

    const areRecentlyModifiedMetadataIds = RecentlyModifiedMetadataIds.validate(contentsResponse);

    if (!areRecentlyModifiedMetadataIds.success) {
      console.error(areRecentlyModifiedMetadataIds);
      throw new Error(areRecentlyModifiedMetadataIds.message);
    }

    return areRecentlyModifiedMetadataIds.value;
  } catch (error) {
    throw new Error((error as Error).message);
  }
}
