import { shallowEqual, useSelector } from "react-redux";
import { useMemo } from "react";
import type { RootState } from "state/rootReducer";
import type { WorkflowStatusStep } from "state/progressSteps/reducer";
import { useCollectionKey, useCollectionWorkflowsIds } from "./useCollections";
import type { WorkflowStatus } from "api/workflows/models/WorkflowStatus";
import uniq from "lodash/uniq";

export function useLoadingProgressSteps(workflowIds: string[]): boolean {
  const { isLoadingSteps } = useSelector((state: RootState) => state.progressSteps);

  return useMemo(() => (workflowIds.length > 0 ? workflowIds.every((id) => isLoadingSteps[id]) : false), [isLoadingSteps, workflowIds]);
}

export const useWorkflowsIntents = (collectionId: string, filterByIntents?: string[]): string[] => {
  const workflowIds = useCollectionWorkflowsIds(collectionId);

  return useSelector((state: RootState) => {
    const workflowIntentsIds = workflowIds.flatMap((id) =>
      state.progressSteps.stepsByWorkflow[id] ? state.progressSteps.stepsByWorkflow[id].workflowIntentIds : []
    );
    const workflowIntents = workflowIntentsIds.map((id) => state.progressSteps.stepsByWorkflowIntent[id].intent);

    return filterByIntents ? workflowIntents.filter((intent) => filterByIntents.includes(intent)) : workflowIntents;
  }, shallowEqual);
};

export const useCollectionWorkflowsNewestStatusByIntent = (collectionId: string, intent: string): WorkflowStatus | undefined => {
  const workflowIds = useCollectionKey(collectionId, "workflowIds");

  return useSelector((state: RootState) => {
    const workflowIntentsIds = (workflowIds ?? []).flatMap((id) =>
      state.progressSteps.stepsByWorkflow[id] ? state.progressSteps.stepsByWorkflow[id].workflowIntentIds : []
    );

    const workflowIntentStatusIds = workflowIntentsIds.flatMap((id) =>
      state.progressSteps.stepsByWorkflowIntent[id] && state.progressSteps.stepsByWorkflowIntent[id].intent === intent
        ? state.progressSteps.stepsByWorkflowIntent[id].workflowIntentStatusIds
        : []
    );

    if (workflowIntentStatusIds.length === 0) {
      return undefined;
    }

    const newestStatusId = workflowIntentStatusIds.reduce((prevId: string, currentId: string) => {
      const previousStatus = state.progressSteps.stepsByWorkflowIntentStatus[prevId];
      const currentStatus = state.progressSteps.stepsByWorkflowIntentStatus[currentId];

      if (new Date(previousStatus.latestCreationDate).getTime() > new Date(currentStatus.latestCreationDate).getTime()) {
        return prevId;
      } else {
        return currentId;
      }
    });

    if (newestStatusId) {
      return state.progressSteps.stepsByWorkflowIntentStatus[newestStatusId].status;
    } else {
      return undefined;
    }
  }, shallowEqual);
};

export const useCollectionWorkflowsIntentStatusList = (collectionId: string, intent: string): WorkflowStatus[] => {
  const workflowIds = useCollectionKey(collectionId, "workflowIds");

  return useSelector((state: RootState) => {
    const workflowIntentsIds = (workflowIds ?? []).flatMap((id) =>
      state.progressSteps.stepsByWorkflow[id] ? state.progressSteps.stepsByWorkflow[id].workflowIntentIds : []
    );

    const workflowIntentStatusIds = workflowIntentsIds.flatMap((id) =>
      state.progressSteps.stepsByWorkflowIntent[id] && state.progressSteps.stepsByWorkflowIntent[id].intent === intent
        ? state.progressSteps.stepsByWorkflowIntent[id].workflowIntentStatusIds
        : []
    );

    return uniq(workflowIntentStatusIds.map((id) => state.progressSteps.stepsByWorkflowIntentStatus[id].status));
  }, shallowEqual);
};

export const useAggregateCollectionWorkflowsIntentStatus = (
  collectionId: string,
  intent: string,
  status: WorkflowStatus | "not_started"
): WorkflowStatusStep[WorkflowStatus] => {
  const workflowIds = useCollectionKey(collectionId, "workflowIds");

  return useSelector((state: RootState) => {
    if (status === "not_started") {
      return {
        status: "not_started",
        count: 0,
        latestCreationDate: new Date().toISOString(),
        latestCompletionDate: new Date().toISOString(),
      };
    }

    const workflowIntentsIds = (workflowIds ?? []).flatMap((id) =>
      state.progressSteps.stepsByWorkflow[id] ? state.progressSteps.stepsByWorkflow[id].workflowIntentIds : []
    );

    const workflowIntentStatusIds = workflowIntentsIds.flatMap((id) =>
      state.progressSteps.stepsByWorkflowIntent[id] && state.progressSteps.stepsByWorkflowIntent[id].intent === intent
        ? state.progressSteps.stepsByWorkflowIntent[id].workflowIntentStatusIds
        : []
    );

    const statusSteps = workflowIntentStatusIds.flatMap((id) =>
      state.progressSteps.stepsByWorkflowIntentStatus[id] && state.progressSteps.stepsByWorkflowIntentStatus[id].status === status
        ? [state.progressSteps.stepsByWorkflowIntentStatus[id]]
        : []
    );

    if (statusSteps.length === 0) {
      return {
        status,
        count: 0,
        latestCreationDate: new Date().toISOString(),
        latestCompletionDate: new Date().toISOString(),
      };
    }

    const statusResponse = statusSteps.reduce((acc: WorkflowStatusStep[WorkflowStatus], current: WorkflowStatusStep[WorkflowStatus]) => {
      const isHigherCreationDate = new Date(current.latestCreationDate).getTime() > new Date(acc.latestCreationDate).getTime();

      return {
        status: status,
        count: acc.count + current.count,
        latestCreationDate: isHigherCreationDate ? current.latestCreationDate : acc.latestCreationDate,
        latestCompletionDate: isHigherCreationDate ? current.latestCompletionDate : acc.latestCompletionDate,
      };
    });

    return statusResponse;
  }, shallowEqual);
};
