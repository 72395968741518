import React, { useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { AiOutlineDelete } from "react-icons/ai";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";

export const EntitiesFieldArray = () => {
  const { control } = useFormContext<FormValues>();
  const commonButtonProps = useButtonProps("sm", "secondary");
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "config.defaultEntities",
  });

  const {
    formState: { errors },
  } = useFormContext<FormValues>();

  const getEntityValueError = useCallback(
    (index: number, name: "entity" | "value") => {
      const maybeDefaultEntity = errors.config?.defaultEntities && errors.config.defaultEntities[index];

      if (!maybeDefaultEntity) {
        return undefined;
      } else return maybeDefaultEntity[name];
    },
    [errors]
  );

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" mb="1rem">
        <Text fontSize="sm" mr="1rem">
          Default Entities
        </Text>
        <Button {...commonButtonProps} onClick={() => append({ entity: "", value: "" })}>
          Add Entity
        </Button>
      </Stack>
      <Stack maxH={300} overflow="auto" spacing={4} marginBottom="6">
        {fields.map((field, index) => (
          <Stack direction="row" justifyContent="space-between" spacing={1} key={field.id} width="100%">
            <FormControl isInvalid={!!getEntityValueError(index, "entity")} width="100%">
              <FormLabel fontSize="sm">Entity</FormLabel>
              <Controller
                render={({ field }) => <Input {...field} size="sm" type="text" />}
                name={`config.defaultEntities.${index}.entity`}
                control={control}
              />
              {getEntityValueError(index, "entity") && <FormErrorMessage>{getEntityValueError(index, "entity")?.message}</FormErrorMessage>}
            </FormControl>

            <FormControl isInvalid={!!getEntityValueError(index, "value")} width="100%">
              <FormLabel fontSize="sm">Value</FormLabel>
              <Controller
                render={({ field }) => (
                  <Input {...field} size="sm" value={String(field.value)} onChange={(e) => field.onChange(e.target.value)} type="text" />
                )}
                name={`config.defaultEntities.${index}.value`}
                control={control}
              />
              {getEntityValueError(index, "value") && <FormErrorMessage>{getEntityValueError(index, "value")?.message}</FormErrorMessage>}
            </FormControl>
            <Box pt="1.5rem">
              <IconButton
                onClick={() => remove(index)}
                aria-label="Delete"
                backgroundColor="unset"
                icon={<Icon as={AiOutlineDelete} color={buttonColor} boxSize="1rem" _hover={{ color: buttonHoverColor }} />}
                size="sm"
                _hover={{ backgroundColor: "unset" }}
              />
            </Box>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
