import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { Button, Stack, useColorModeValue, Text } from "@chakra-ui/react";
import type { FlexboxProps } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import { useCallback } from "react";
import React from "react";
import { useWizard } from "react-use-wizard";
import { ConversationContext } from "screens/thread/ConversationContext";

interface Props {
  onPreviousLabel?: string;
  onPreviousDisabled?: boolean;
  onNextLabel?: string;
  onNextDisabled?: boolean;
  onNextDisabledMessage?: string;
  onPreviousSubmit?: () => void;
  onSubmit?: () => void;
  justifyContent?: FlexboxProps["justifyContent"];
  onNextButtonId?: string;
  onNextButtonType?: "button" | "submit";
  onNextButtonForm?: string;
}

export const PanelStep: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  children,
  onPreviousLabel,
  onNextLabel,
  onSubmit,
  onPreviousSubmit,
  onNextDisabled,
  onNextDisabledMessage,
  onPreviousDisabled,
  justifyContent = "space-between",
  onNextButtonId = "save-button",
  onNextButtonType = "button",
  onNextButtonForm,
}) => {
  const { previousStep, nextStep } = useWizard();
  const buttonColor = useColorModeValue("secondaryButton.color", "secondaryButton.colorDarkMode");
  const commonButtonProps = useButtonProps("sm", "primary");
  const { hideConversationButton } = useContext(ConversationContext);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === "Enter" && onSubmit) {
        onSubmit();
      }
      return true;
    },
    [onSubmit]
  );

  return (
    <Stack justifyContent={justifyContent} position="relative">
      {children}
      {(onPreviousLabel || onNextLabel) && (
        <Stack
          position="fixed"
          bottom="0"
          zIndex={10000}
          width="100%"
          direction="row"
          justifyContent={onPreviousLabel ? "space-between" : "flex-end"}
          pb="1.5rem"
          pr={hideConversationButton ? "2rem" : "5.5rem}"}>
          {onPreviousLabel && (
            <Button
              leftIcon={<ChevronLeftIcon boxSize={"1rem"} />}
              isDisabled={onPreviousDisabled}
              marginInlineStart="0!important"
              {...commonButtonProps}
              id="publish-button"
              aria-label="previous-step"
              onClick={onPreviousSubmit || previousStep}>
              {onPreviousLabel}
            </Button>
          )}
          {onNextLabel && (
            <Stack>
              {onNextDisabledMessage && (
                <Text color={buttonColor} fontSize="xs" display={onNextDisabled ? "block" : "none"}>
                  {`* ${onNextDisabledMessage}`}
                </Text>
              )}
              <Button
                rightIcon={<ChevronRightIcon boxSize={"1rem"} />}
                isDisabled={onNextDisabled}
                {...commonButtonProps}
                id={onNextButtonId}
                type={onNextButtonType}
                form={onNextButtonForm}
                aria-label="next-step"
                onKeyDown={onKeyDown}
                onClick={onSubmit ? onSubmit : nextStep}
                marginInlineEnd="0!important">
                {onNextLabel}
              </Button>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};
