import React, { useContext, useMemo } from "react";
import { ContentFilterContext } from "./ContentFilterContext";
import type { ContentDetails } from "types/content/ContentDetails";
import { TagFilter } from "screens/landing/tabs/collections/TagFilter";

const extractAutoAndManualTagsFromContent = (content: ContentDetails) => {
  return [...content.autoTags, ...content.manualTags];
};

export const ContentCanvasFilterOptions = () => {
  const { selectedTags, setSelectedTags, availableContent } = useContext(ContentFilterContext);

  const distinctTags = useMemo(() => {
    const allTags = availableContent.reduce((accum, content) => {
      const tags = extractAutoAndManualTagsFromContent(content);

      tags.forEach((tag) => {
        if (accum[tag.replace('"', "").trim()]) {
          accum[tag.replace('"', "").trim()] = accum[tag.replace('"', "").trim()] + 1;
        } else {
          accum[tag.replace('"', "").trim()] = 1;
        }
      });

      return accum;
    }, {} as { [key: string]: number });

    return Object.entries(allTags)
      .map((tagPair) => {
        return { name: tagPair[0], occurrences: tagPair[1] };
      })
      .sort((a, b) => {
        return b.occurrences - a.occurrences;
      });
  }, [availableContent]);

  const presentedTags = useMemo(() => {
    // Concatenate active tag filters that don't match the filter being applied, so that they can be unselected if needed
    return distinctTags.concat(
      selectedTags
        .filter((tag) => !distinctTags.some((distinctTag) => tag === distinctTag.name))
        .map((tagName) => {
          return { name: tagName, occurrences: 0 };
        })
    );
  }, [distinctTags, selectedTags]);

  return (
    <TagFilter selectedTags={selectedTags} setSelectedTags={setSelectedTags} presentedTags={presentedTags} distinctTags={distinctTags} />
  );
};
