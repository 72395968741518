import { getEnvironment } from "screens/common/app";
import { validate } from "json-schema";
import type { JSONSchema7 } from "json-schema";

export function saveToFile(jsonContent: string, key: string, options?: { extension?: string }) {
  const blob = new Blob([jsonContent], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const date = new Date();
  const formattedDate = `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}`;

  link.setAttribute("download", `${getEnvironment().label}-${key}-${formattedDate}.${options?.extension || "json"}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const isJsonString = (value: string | undefined, schema: JSONSchema7) => {
  try {
    if (!value) {
      return true;
    }

    const jsonValue = JSON.parse(value);
    const isValid = validate(jsonValue, schema);

    if (!isValid.valid) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};
