import React, { useCallback } from "react";
import { Button, Center, Stack, Text, Image, Icon, Tbody, Td, Tr, Table, HStack, useBreakpointValue, Box } from "@chakra-ui/react";
import { useButtonProps, useUserProfile, useUserSubscriptionDetails, useUserSubscriptionDetailsDaysTillNextBilling } from "hooks";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import { FaCheck } from "react-icons/fa";
import { useWizard } from "react-use-wizard";
import { track } from "api/analytics";
import { USER_UPGRADE_PLAN_CONTINUE } from "api/analytics/events";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { Intent } from "types/intent";
import { useLocation } from "react-router-dom";
import type { Feature } from "hooks/useCopies";
import { useSubscriptionFeatures } from "hooks/useCopies";
import { BsDashLg } from "react-icons/bs";
import { SubscriptionUpgradeFeaturesHeader } from "./SubscriptionUpgradeFeaturesHeader";

const FeatureRow: React.FC<{ feature: Feature }> = React.memo(({ feature }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const renderFeatureCell = useCallback((value: string) => {
    if (value === "true") return <Icon as={FaCheck} color="green.500" />;
    if (value === "false") return <Icon as={BsDashLg} color="gray.700" />;

    const parts = value.split(" ");
    if (parts.length > 1) {
      return (
        <>
          <Stack spacing="0">
            <Text>{parts[0]}</Text>
            <Text lineHeight=".6rem" fontSize="10px">
              {parts[1]}
            </Text>
          </Stack>
        </>
      );
    }
    return value;
  }, []);

  return (
    <Tr fontSize="sm">
      <Td paddingInline="0" minWidth={isMobile ? "unset" : "28rem"}>
        {feature.name}
      </Td>
      {!isMobile && (
        <Td textAlign="center" width={isMobile ? "unset" : "8rem"} color="green.600">
          {renderFeatureCell(feature.trial)}
        </Td>
      )}
      <Td textAlign="center" width="8rem" color="green.600">
        {renderFeatureCell(feature.professional)}
      </Td>
      {!isMobile && (
        <Td textAlign="center" width="8rem" color="green.600">
          {renderFeatureCell(feature.business)}
        </Td>
      )}
      {!isMobile && (
        <Td textAlign="center" width="8rem" color="green.600">
          {renderFeatureCell(feature.premium)}
        </Td>
      )}
      {!isMobile && (
        <Td textAlign="center" width="8rem" color="green.600">
          {renderFeatureCell(feature.enterprise)}
        </Td>
      )}
    </Tr>
  );
});

FeatureRow.displayName = "FeatureRow";

export const SubscriptionUpgradeFeatures: React.FC = () => {
  const buttonStyle = useButtonProps("lg", "subscribe");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { nextStep } = useWizard();
  const { email, fullName } = useUserProfile();
  const subscriptionInfo = useUserSubscriptionDetails();
  const daysTillPlanExpiry = useUserSubscriptionDetailsDaysTillNextBilling();
  const answerUsage = useFeatureUsage(Intent.generateAnswer);
  const projectUsage = useFeatureUsage(Intent.createDueDiligenceProject);
  const { pathname } = useLocation();
  const featureList = useSubscriptionFeatures();

  const handleOnClick = useCallback(() => {
    track(USER_UPGRADE_PLAN_CONTINUE, {
      pageViewed: pathname,
      userName: fullName,
      userEmail: email,
      answersUsed: answerUsage?.used,
      dueDiligenceUsed: projectUsage?.used,
      currentPlan: subscriptionInfo.plan,
      planStatus: subscriptionInfo.status,
      nextBillingDate: subscriptionInfo.nextBillingDate,
      subscriptionId: subscriptionInfo.subscriptionId,
      daysTillPlanExpiry: daysTillPlanExpiry.daysTillNextBilling,
    });
    nextStep();
  }, [pathname, fullName, email, answerUsage, projectUsage, subscriptionInfo, daysTillPlanExpiry, nextStep]);

  const headerStyle = {
    paddingInline: "0",
    color: "gray.600",
    fontWeight: "semibold",
  };

  return (
    <Stack justifyContent="space-between" height="100%" minHeight="40rem" spacing="0" width="100%">
      <Stack spacing="0" height="100%" justifyContent="space-between">
        <SubscriptionUpgradeFeaturesHeader title="Step 1 of 2: Upgrade To Professional" />
        <Box p={isMobile ? "1rem" : "2rem"}>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr fontSize="sm">
                <Td {...headerStyle}>Feature</Td>
                {!isMobile && (
                  <Td textAlign="center" width="8rem" {...headerStyle}>
                    Free
                  </Td>
                )}
                <Td textAlign="center" width={isMobile ? "unset" : "8rem"} {...headerStyle}>
                  Professional
                </Td>
                {!isMobile && (
                  <Td textAlign="center" width="8rem" {...headerStyle}>
                    Business
                  </Td>
                )}
                {!isMobile && (
                  <Td textAlign="center" width="8rem" {...headerStyle}>
                    Premium
                  </Td>
                )}
                {!isMobile && (
                  <Td textAlign="center" width="8rem" {...headerStyle}>
                    Enterprise
                  </Td>
                )}
              </Tr>
              {featureList.map((feature, index) => (
                <FeatureRow key={index} feature={feature} />
              ))}
            </Tbody>
          </Table>
        </Box>
      </Stack>
      <Stack>
        <Center pb="2rem">
          <Button
            {...buttonStyle}
            color="white"
            fontWeight="normal"
            bgColor="#81c34b"
            borderColor="#81c34b"
            width="16rem"
            borderRadius="full"
            onClick={handleOnClick}
            aria-label="Continue to next step">
            Continue
          </Button>
        </Center>
      </Stack>
      {!isMobile && (
        <HStack position="absolute" bottom="2rem" right="2rem" align="center" spacing="1rem">
          <Image src={soc2logo} height="2.7rem" alt="SOC 2 Logo" />
          <Image src={verifiedShield} height="2.5rem" alt="Verified Shield" />
        </HStack>
      )}
    </Stack>
  );
};
