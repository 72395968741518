import { Array, Record, String } from "runtypes";
import { Activity } from "api/charliActivities/models/activities/Activity";
import type { Static } from "runtypes";

export const ActivitiesResponse = Record({
  structure: String,
  type: String,
  response: Record({ message: String, status: String }),
  system: String,
  data: Array(Activity),
});

export type ActivitiesResponse = Static<typeof ActivitiesResponse>;
