import { Stack, Text } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import ECHighlighter from "react-ec-highlighter";
import type { AssessmentResultsRiskAssessmentResult } from "types/collection";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";

export interface Props {
  assessmentResults: AssessmentResultsRiskAssessmentResult;
  searchText?: string;
  subSection?: boolean;
}

export const AssessmentResultsRiskAssessment: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  assessmentResults,
  searchText,
  subSection,
}) => {
  return (
    <Stack>
      <BlockSectionHeader title="Risk Assessment" subSection={subSection} />
      {assessmentResults.risk_assessment_report.length && (
        <Stack>
          {assessmentResults.risk_assessment_report.split("\n").map(
            (sentence, index) =>
              sentence.length > 0 && (
                <Text fontSize={"sm"} className="ch-assessment-result-risk-assessment" key={index} width="100%" pb=".5rem">
                  {searchText && searchText.length > 0 && sentence ? <ECHighlighter searchPhrase={searchText} text={sentence} /> : sentence}
                </Text>
              )
          )}
        </Stack>
      )}
    </Stack>
  );
};
