import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWorkflowCommands } from "api/command";
import type { Command } from "types/commands";
import type { MessageIntent } from "types/conversation";

export const downloadCommands = createAsyncThunk("command/download", async () => {
  let commands: Command[] = [];
  commands = await getWorkflowCommands();

  // Normalize by intent
  return commands.reduce((accum, command) => {
    accum[command.intent as MessageIntent] = command;
    return accum;
  }, {} as { [intent in MessageIntent]?: Command });
});
