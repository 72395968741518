import React, { useContext, useEffect } from "react";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import { ConversationContext } from "../ConversationContext";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { WorkflowPanel } from "./WorkflowPanel";
import { WorkflowPanel as WorkflowPanelV2 } from "screens/common/components/WorkflowSummary/v2/WorkflowPanel";
import { useFeatureFlags } from "hooks";

export const WorkflowPanelUser = () => {
  const { isWorkflow, isMessageId, onWorkflowPanelClose, isWorkflowPanelOpen, setWorkflowSummary, setWorkflow } = useWorkflowContext();
  const { onConversationOpen, conversationId } = useContext(ConversationContext);
  const { isContentViewOpen } = useAddToCharliContext();
  const { workflow_summary_2: hasWorkflowSummaryV2 } = useFeatureFlags();

  useEffect(() => {
    if (!isWorkflowPanelOpen) {
      setWorkflow();
      if (conversationId) onConversationOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorkflowPanelOpen, onConversationOpen, setWorkflow]);

  return hasWorkflowSummaryV2 ? (
    <WorkflowPanelV2
      isOpen={isWorkflowPanelOpen}
      onClose={() => {
        setWorkflow();
        setWorkflowSummary([]);

        onWorkflowPanelClose();
      }}
      workflow={isWorkflow}
      isContentViewOpen={isContentViewOpen}
      isLoadingWorkflow={false}
      isMessageId={isMessageId}
    />
  ) : (
    <WorkflowPanel
      isOpen={isWorkflowPanelOpen}
      onClose={() => {
        onWorkflowPanelClose();
      }}
      workflow={isWorkflow}
      isContentViewOpen={isContentViewOpen}
      isLoadingWorkflow={false}
      isMessageId={isMessageId}
    />
  );
};
