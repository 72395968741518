import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import type { FieldValues } from "react-hook-form";
import { Controller, useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { useController } from "react-hook-form";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import type { IFieldMultiSelectWithTags } from "../types";
import {
  AutoComplete,
  AutoCompleteCreatable,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";

export const MultiSelectWithTags = <T extends FieldValues>({
  entity,
  label,
  placeholder,
  suggestions,
  background,
  size = "sm",
}: IFieldMultiSelectWithTags<T>) => {
  const { control } = useFormContext<FieldValues>();
  const { field } = useController({ control, name: entity });

  const value = useWatch({ control, name: entity });

  const setTags = useCallback((tags: string[]) => field.onChange(tags.join(",")), [field]);
  const tags = useMemo(() => (value ? value.split(",") : []), [value]);

  return (
    <Controller
      name={entity}
      control={control}
      render={({ field: { name, onBlur }, fieldState: { error } }) => (
        <>
          <FormControl isInvalid={!!error}>
            <SectionHeader title={label} />
            <AutoComplete
              freeSolo
              listAllValuesOnFocus
              rollNavigation
              value={tags}
              onChange={(newTags: string[]) => setTags(newTags)}
              creatable
              openOnFocus
              multiple>
              <AutoCompleteInput onBlur={onBlur} name={name} size={size} backgroundColor={background} placeholder={placeholder}>
                {({ tags }) => tags.map((tag, tid) => <AutoCompleteTag key={tid} label={tag.label} onRemove={tag.onRemove} />)}
              </AutoCompleteInput>
              <AutoCompleteList>
                {suggestions.map((value, cid) => (
                  <AutoCompleteItem _selected={{ bg: "blue.50" }} key={`option-${cid}`} value={value}>
                    {value}
                  </AutoCompleteItem>
                ))}
                <AutoCompleteCreatable />
              </AutoCompleteList>
            </AutoComplete>

            {error?.message ? <FormErrorMessage>{error?.message as string}</FormErrorMessage> : null}
          </FormControl>
        </>
      )}
    />
  );
};
