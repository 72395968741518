import { Record, String, Array, Boolean } from "runtypes";
import type { Static } from "runtypes";
import { PlanDetails } from "types/billingPlans/PlanDetails";

export const Plan = Record({
  code: String,
  name: String,
  details: PlanDetails,
  isDefault: Boolean,
  isActive: Boolean,
  creationDate: String,
  lastUpdatedDate: String,
  lastUpdatedByUserId: String,
  plansCanSwitchFrom: Array(String).optional(),
});

export type Plan = Static<typeof Plan>;
