import type { ReactNode } from "react";
import React, { useMemo } from "react";
import { Button, FormControl, FormLabel, FormErrorMessage, Input, Box, Stack, Text } from "@chakra-ui/react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useButtonProps } from "hooks";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useEntityConfiguration, useEntityLoading } from "hooks/useEntityConfigurations";
import { EntityConfigFieldArray } from "./EntityConfigFieldArray";
import type { EntityConfigType, EntityConfiguration } from "api/entityConfigurations/models/EntityConfiguration";
import type { SerializedError } from "@reduxjs/toolkit";
import { formatDate } from "screens/common/modal/formatters";

export function isError(payload: unknown): payload is SerializedError {
  return (payload as SerializedError).message !== undefined;
}

function transformToFormValuesConfig(entityConfiguration: EntityConfiguration): FormConfig[] {
  return entityConfiguration.config.map((item) => {
    const { type, intent } = item;
    return {
      type,
      intent,
      ...(type === "trigger_child_workflow" && { entities: item.entitiesToInclude.map((entity) => ({ value: entity })) }),
    };
  });
}

interface FormConfig {
  type: EntityConfigType;
  intent: string;
  entities?: { value: string }[];
}

export interface FormValues {
  id?: string;
  entityName: string;
  config: FormConfig[];
}

interface ModalProps {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
}

interface IProps {
  id?: string;
  onSubmit: (values: FormValues) => void;
}

const UpsertEntityConfiguration = ({ id, onSubmit }: IProps) => {
  const entityConfiguration = useEntityConfiguration(id);

  const methods = useForm<FormValues>({
    defaultValues: {
      ...(entityConfiguration
        ? {
            entityName: entityConfiguration.entityName,
            config: transformToFormValuesConfig(entityConfiguration),
          }
        : {
            entityName: "",
            config: [],
          }),
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  return (
    <Box pt="1rem">
      {entityConfiguration && (
        <Stack mb="1.5rem">
          <Text fontStyle={"sm"} wordBreak="break-word" fontWeight="light" fontSize="xs">{`Created ${formatDate(
            new Date(entityConfiguration.creationDate)
          )} by ${entityConfiguration.creationByUserName}`}</Text>

          <Text fontStyle={"sm"} wordBreak="break-word" fontWeight="light" fontSize="xs">{`Modified ${formatDate(
            new Date(entityConfiguration.lastUpdatedDate)
          )} by ${entityConfiguration.lastUpdatedByUserName}`}</Text>
        </Stack>
      )}

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id="upsert-entity-configuration-form">
          <FormControl isInvalid={errors.entityName !== undefined} pb="1rem">
            <FormLabel fontSize={"sm"} htmlFor="entity-name">
              Entity Name
            </FormLabel>
            <Controller
              render={({ field }) => (
                <Input
                  size="xs"
                  id="entity-name"
                  type="text"
                  {...field}
                  borderColor={errors.entityName ? "red.500" : "gray.200"}
                  mb="0.5rem"
                  fontSize="sm"
                  boxShadow="none"
                  required
                  disabled={Boolean(id)}
                />
              )}
              name="entityName"
              control={control}
            />
            {errors.entityName && <FormErrorMessage fontSize={"sm"}>Entity name is required.</FormErrorMessage>}
          </FormControl>
          <EntityConfigFieldArray />
        </form>
      </FormProvider>
    </Box>
  );
};

export const UpsertEntityConfigurationModal = (props: ModalProps) => {
  const { id, isOpen, onSubmit, onClose } = props;
  const commonButtonProps = useButtonProps("sm", "primary");
  const isLoading = useEntityLoading();

  const panelFooter: ReactNode = useMemo(
    () => (
      <Button disabled={isLoading} {...commonButtonProps} type="submit" form="upsert-entity-configuration-form">
        Save Entity Configuration
      </Button>
    ),
    [commonButtonProps, isLoading]
  );

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${id ? "Update" : "Create"} entity configuration`} panelFooter={panelFooter}>
      <Wizard>
        <PanelStep>{isOpen && <UpsertEntityConfiguration id={id} onSubmit={onSubmit} />}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
