import React, { useMemo } from "react";
import { Box, Button } from "@chakra-ui/react";
import { DynamicForm } from "screens/common/components";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useButtonProps, useOrganizations } from "hooks";
import { Wizard } from "react-use-wizard";
import type { IField } from "screens/common/components";
import * as yup from "yup";
import type { Value } from "api/organizationsPreferences/models/Value";
import { useOrganizationPreference } from "hooks/useOrganizationsPreferences";

export interface FormValues {
  organization: string;
  key: string;
  value: Value;
}

interface IProps {
  id?: string;
  onSubmit: (values: FormValues) => void;
  initialValues?: FormValues;
}

interface PanelProps extends IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UpsertOrganizationPreference = ({ id, onSubmit }: IProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");
  const organizationPreference = useOrganizationPreference(id);
  const organizations = useOrganizations();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      organization: yup.string().not(["-1"], "Organization was not selected"),
      key: yup
        .string()
        .required("Key is required")
        .test("has-valid-format", "Key format should include only lowercase and underscores", (value) =>
          /^[a-z0-9]+(?:_+[a-z0-9]+)*$/.test(value)
        ),
      value: yup
        .string()
        .required("Value is required")
        .test("is-valid-json", "There was an error parsing the field. Try with another value.", (value) => {
          try {
            JSON.parse(value || "");
            return true;
          } catch (e) {
            return false;
          }
        }),
    });
  }, []);

  const fields: IField<FormValues>[] = useMemo(() => {
    return [
      {
        type: "select",
        label: "Organization",
        entity: "organization",
        defaultValue: "",
        options: [{ label: "Select organization..", value: "-1" }, ...organizations.map((org) => ({ label: org.name, value: org.id }))],
      },
      {
        type: "text",
        label: "Key",
        entity: "key",
        defaultValue: "",
      },
      {
        type: "textarea",
        label: "Value",
        entity: "value",
        defaultValue: "",
      },
    ];
  }, [organizations]);

  const defaultValues: FormValues = useMemo(() => {
    return organizationPreference
      ? {
          organization: organizationPreference.organizationId,
          key: organizationPreference.key,
          value: JSON.stringify(organizationPreference.value),
        }
      : {
          organization: "-1",
          key: "",
          value: JSON.stringify(""),
        };
  }, [organizationPreference]);

  return (
    <Box pl=".5rem">
      <DynamicForm<FormValues>
        title={`${id ? "Update" : "Create"} organization preference`}
        fields={fields}
        defaultValues={defaultValues}
        formId="upsert-organization-preference-form"
        onSubmit={(data) => onSubmit(data)}
        validationSchema={validationSchema}
      />
      <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
        <Button disabled={false} {...commonButtonProps} type="submit" form="upsert-organization-preference-form">
          Save Organization Preference
        </Button>
      </Box>
    </Box>
  );
};

export const UpsertOrganizationPreferencePanel = (props: PanelProps) => {
  const { id, isOpen, initialValues, onSubmit, onClose } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${id ? "Update" : "Create"} organization preference`}>
      <Wizard>
        <PanelStep>{isOpen && <UpsertOrganizationPreference id={id} onSubmit={onSubmit} initialValues={initialValues} />}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
