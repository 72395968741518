import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice, createAction } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import type { RootState } from "state/rootReducer";

interface WebsocketState {
  isConnected?: boolean;
  queue: Array<unknown>;
}

const initialState: WebsocketState = {
  isConnected: undefined,
  queue: [],
};

const rehydrate = createAction<RootState>(REHYDRATE);

export const { actions, reducer } = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
    connect(state) {
      state.isConnected = true;
    },
    disconnect(state, action: PayloadAction<{ userInitiated: boolean }>) {
      if (action.payload.userInitiated) {
        state.isConnected = undefined;
      } else {
        state.isConnected = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (state, action) => {
      if (action.payload?.websocket && action.payload.websocket.queue) {
        state.queue = action.payload.websocket.queue;
      }
      state.isConnected = undefined;
    });
  },
});

export default reducer;
