import { useToast } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useEffect, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import type { RouteProps } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastMessageContent } from "screens/common/components";
import type { RootState } from "state/rootReducer";
import { loginWithCredentials, signupWithCredentials } from "state/session/operations";
import { LoginForm, RegisterForm } from "./components";
import { useEntitlementsCount, useUserPreference } from "hooks";

import { LoginWrapper } from "./components/LoginWrapper";

export const Login: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props: RouteProps) => {
  const { isLoggedIn, isAuthorized } = useSelector((state: RootState) => state.session, shallowEqual);
  const navigate = useNavigate();
  const location = useLocation();
  const path = (props.path || document.location.pathname) as "/login" | "/login/email" | "/register" | "/reset";
  const lastError = useSelector((state: RootState) => state.session.lastError);
  const dispatch = useDispatch();
  const toast = useToast();
  const defaultHomePage = useUserPreference("ui_home_page");
  const entitlementsCount = useEntitlementsCount();

  useEffect(() => {
    if ((isLoggedIn || isAuthorized) && entitlementsCount > 0) {
      const maybeState = location.state as { path?: string; queryString?: string };
      const navigateToUrl = defaultHomePage ? `/${String(defaultHomePage)}` : "/";
      const redirectPath = maybeState?.path === "/logout" ? `${navigateToUrl}` : maybeState?.path ?? `${navigateToUrl}`;
      navigate(`${redirectPath}${maybeState?.queryString ?? ""}`, { replace: true });
    }
  }, [isLoggedIn, isAuthorized, path, navigate, location.state, defaultHomePage, entitlementsCount]);

  useEffect(() => {
    if (lastError) {
      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message={lastError}
            onClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 10000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [lastError, toast]);

  const getLoginForm = useCallback(() => {
    const onLoginSubmit = (fields: { username: string; password: string }) => {
      dispatch(
        loginWithCredentials({
          username: fields.username,
          password: fields.password,
        })
      );
    };

    const onRegisterSubmit = (fields: {
      firstName: string;
      lastName: string;
      username: string;
      organization: string;
      factset: boolean;
      role: string;
      password: string;
      marketingOptIn: boolean;
    }) => {
      dispatch(
        signupWithCredentials({
          firstName: fields.firstName,
          lastName: fields.lastName,
          username: fields.username,
          password: fields.password,
          organization: fields.organization,
          factset: fields.factset,
          role: fields.role,
          marketingOptIn: fields.marketingOptIn,
        })
      );
    };

    switch (location.pathname) {
      case "/register":
        return <RegisterForm onSubmit={onRegisterSubmit} />;
      default:
        return <LoginForm onSubmit={onLoginSubmit} showEmailForm={location.pathname === "/login/email"} />;
    }
  }, [dispatch, location.pathname]);

  return <LoginWrapper>{getLoginForm()}</LoginWrapper>;
};
