import { useState, useEffect } from "react";
import randomWords from "random-words";

const useMockData = (recordCount: number) => {
  const [mockDateData, setMockDateData] = useState<{ label: string; name: string; value: number }[]>([]);
  const [mockLabelData, setMockLabelData] = useState<{ label: string; name: string; value: number }[]>([]);

  useEffect(() => {
    const currentDate = new Date();
    const newMockDateData = Array.from({ length: recordCount }, (_, i) => {
      const date = new Date(currentDate);
      date.setDate(date.getDate() - i);
      const formattedDate = date.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" });
      return {
        label: formattedDate,
        name: formattedDate,
        value: Math.floor(Math.random() * (100 - 50 + 1)) + 50,
      };
    });

    setMockDateData(newMockDateData);

    const newMockData = Array.from({ length: recordCount }, () => {
      const randomLabel = randomWords({ exactly: 1 })[0];
      return {
        label: randomLabel,
        name: randomLabel,
        value: Math.floor(Math.random() * (95 - 30 + 1)) + 30,
      };
    });
    setMockLabelData(newMockData);
  }, [recordCount]);

  return { mockDateData, mockLabelData };
};

export default useMockData;
