import React from "react";
import {
  Stack,
  Text,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Highlight,
  Center,
} from "@chakra-ui/react";
import { useButtonProps } from "hooks";

import { sendMessage } from "state/websocket/operations";
import { useDispatch } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";

interface Props {
  parentCellTypeEntityName: string;
  collectionId: string;
  conversationId: string;
  mediaId: string;
  reason: string;
  onClose: () => void;
  isOpen: boolean;
  reportName?: string;
}

export const ProjectReportPanelDeleteModal = ({
  parentCellTypeEntityName,
  collectionId,
  conversationId,
  mediaId,
  reason,
  onClose,
  isOpen,
  reportName,
}: Props) => {
  const primaryButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const dispatch = useDispatch();

  const onDeleteCollectionItem = () => {
    dispatch(
      sendMessage({
        conversationId: conversationId,
        intent: "/remove_collection_content",
        entities: [
          { entity: parentCellTypeEntityName, value: mediaId },
          { entity: "collection_id", value: collectionId },
          { entity: "content_delete_reason", value: reason },
        ],
        datum: [
          {
            type: "update_data_callback",
            includeInResponse: true,
            body: { items: [{ type: "collection", id: collectionId }] },
          },
        ],
      })
    );
    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bgColor={"white"} maxHeight="10rem" display="flex" flexDirection="column" padding={0} borderTopRadius="lg">
          <Stack direction={"row"} fontSize="md" textAlign="center" pt="1rem" width="100%" justifyContent={"space-between"} px="1.5rem">
            <Text fontWeight={"semibold"} fontSize="lg">
              Confirm Delete
            </Text>
            <Center>
              <CloseIcon cursor={"pointer"} boxSize={".8rem"} onClick={onClose} />
            </Center>
          </Stack>
        </ModalHeader>
        <ModalBody fontSize={"sm"} pt="1rem">
          <Text fontWeight="normal">
            <Highlight query={reportName || ""} styles={{ py: "1", whiteSpace: "normal", fontWeight: "bold" }}>
              {`Please confirm that you would like to delete ${reportName}. This can not be undone.`}
            </Highlight>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Stack direction="row" spacing="1rem">
            <Button {...secondaryButtonProps} onClick={onClose} className="ch-delete-report-cancel">
              Cancel
            </Button>
            <Button {...primaryButtonProps} mr={3} onClick={() => onDeleteCollectionItem()} className="ch-delete-report-submit">
              Delete
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
