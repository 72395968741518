import { Record, Number, Array, String } from "runtypes";
import type { Static } from "runtypes";

export const CollectionQuery = Record({
  totalCount: Number,
  metadataIds: Array(String),
  nextPageToken: String.nullable(),
});

export type CollectionQuery = Static<typeof CollectionQuery>;
