import { request } from "api/request";
import { VersionResponse } from "./models/VersionResponse";

const VERSION_ENDPOINT = "/api/version";

export async function getVersion(): Promise<{ version: string | undefined }> {
  const versionResponse = await request().url(VERSION_ENDPOINT).get().json();

  const validationResult = VersionResponse.validate(versionResponse);

  if (validationResult.success) {
    return validationResult.value;
  } else {
    console.error("Invalid payload received when fetching version", validationResult.details);

    return { version: undefined };
  }
}
