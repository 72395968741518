import type { Middleware } from "redux";
import { downloadConversationHistory } from "state/conversation/operations";
import type { RootState } from "state/rootReducer";
import { actions as conversationActions } from "state/conversation/reducer";
import { getWebsocketClient } from "api/websocket/client";

const MAX_MESSAGE_AGE_IN_MS = 300000; // 5 minutes in milliseconds

export const unsentMessageMiddleware: Middleware = (store) => (next) => (action) => {
  next(action);

  if (action.type === downloadConversationHistory.fulfilled.type) {
    const messages = (store.getState() as RootState).conversation.messages;

    Object.entries(messages)
      .filter(([_, message]) => message.acknowledgmentStatus === "not_acknowledged")
      .forEach(([messageId, message]) => {
        if (new Date().getTime() > message.createdTime + MAX_MESSAGE_AGE_IN_MS) {
          // expired, mark as dead
          store.dispatch(conversationActions.setAcknowledgmentStatus({ messageId, acknowledgmentStatus: "will_not_retry" }));

          console.log(`Marked message with id ${messageId} and content [${message.content}] as dead, it will not be retried`);
        } else {
          const messageForBackend = {
            id: message.id,
            conversationId: message.conversationId,
            content: message.content,
            senderId: message.senderId,
            createdDate: message.createdDate,
            data: message.data,
          };

          getWebsocketClient().send(messageForBackend);

          console.log(`Re-sent message with id ${messageId} and content [${message.content}]`);
        }
      });
  }
};
