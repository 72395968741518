import { createSlice } from "@reduxjs/toolkit";
import type { MemorizedClarificationConfiguration } from "api/memorizedClarificationConfig/models/MemorizedClarificationConfiguration";
import {
  downloadMemorizedClarificationsConfigs,
  downloadMemorizedClarificationConfig,
  createMemorizedClarificationConfigAction,
  updateMemorizedClarificationConfigAction,
  deleteMemorizedClarificationConfigAction,
} from "./operations";

interface MemorizedClarificationConfigState {
  isLoading: boolean;
  memorizedClarificationConfigIds: string[];
  memorizedClarificationConfigByIds: Record<string, MemorizedClarificationConfiguration>;
}

const initialState: MemorizedClarificationConfigState = {
  isLoading: false,
  memorizedClarificationConfigIds: [],
  memorizedClarificationConfigByIds: {},
};

export const { actions, reducer } = createSlice({
  name: "memorizedClarificationConfig",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadMemorizedClarificationsConfigs.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(downloadMemorizedClarificationsConfigs.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(downloadMemorizedClarificationsConfigs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.memorizedClarificationConfigIds = action.payload.map((entityConfiguration) => entityConfiguration.id);

      state.memorizedClarificationConfigByIds = action.payload.reduce(
        (acc: Record<string, MemorizedClarificationConfiguration>, memorizedClarificationConfig) => {
          acc[memorizedClarificationConfig.id] = memorizedClarificationConfig;
          return acc;
        },
        {}
      );
    });

    builder.addCase(downloadMemorizedClarificationConfig.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(downloadMemorizedClarificationConfig.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(downloadMemorizedClarificationConfig.fulfilled, (state, action) => {
      state.isLoading = false;
      const memorizedClarificationConfig = action.payload;

      if (!state.memorizedClarificationConfigByIds[memorizedClarificationConfig.id]) {
        state.memorizedClarificationConfigIds.push(memorizedClarificationConfig.id);
      }

      state.memorizedClarificationConfigByIds[memorizedClarificationConfig.id] = memorizedClarificationConfig;
    });

    builder.addCase(createMemorizedClarificationConfigAction.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(createMemorizedClarificationConfigAction.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(createMemorizedClarificationConfigAction.fulfilled, (state, action) => {
      state.isLoading = false;
      const memorizedClarificationConfig = action.payload;

      state.memorizedClarificationConfigIds.push(memorizedClarificationConfig.id);
      state.memorizedClarificationConfigByIds[memorizedClarificationConfig.id] = memorizedClarificationConfig;
    });

    builder.addCase(updateMemorizedClarificationConfigAction.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(updateMemorizedClarificationConfigAction.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(updateMemorizedClarificationConfigAction.fulfilled, (state, action) => {
      state.isLoading = false;
      const memorizedClarificationConfig = action.payload;
      state.memorizedClarificationConfigByIds[memorizedClarificationConfig.id] = memorizedClarificationConfig;
    });

    builder.addCase(deleteMemorizedClarificationConfigAction.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMemorizedClarificationConfigAction.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(deleteMemorizedClarificationConfigAction.fulfilled, (state, action) => {
      state.isLoading = false;
      const memorizedClarificationConfigId = action.meta.arg;

      delete state.memorizedClarificationConfigByIds[memorizedClarificationConfigId];
      state.memorizedClarificationConfigIds = state.memorizedClarificationConfigIds.filter((id) => id !== memorizedClarificationConfigId);
    });
  },
});

export default reducer;
