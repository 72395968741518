import { prependProxyBaseUrl, request } from "api/request";
import { ActivitiesResponse } from "./models/activities/ActivitiesResponse";
import type { Activity } from "./models/activities/Activity";

const CHARLI_ACTIVITIES_ENDPOINT = prependProxyBaseUrl("/api/charli-activities");

export async function getCharliActivities(): Promise<Activity[]> {
  const response = await request().url(`${CHARLI_ACTIVITIES_ENDPOINT}/query/lookup?type=intents&structure=flat`).get().json();
  const maybeActivities = ActivitiesResponse.validate(response);

  if (maybeActivities.success) {
    return maybeActivities.value.data;
  } else {
    console.error(maybeActivities);
    throw new Error(maybeActivities.message);
  }
}
