import { ChevronRightIcon, ChevronUpIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import type { IconProps } from "@chakra-ui/react";
import { IconButton, useToast } from "@chakra-ui/react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Center,
  Code,
  Divider,
  Icon,
  List,
  ListItem,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
} from "@chakra-ui/react";
import { useEntitlementKey, useEntitlements, useTileProps, useUserProfile } from "hooks";
import humanizeDuration from "humanize-duration";
import filter from "lodash/filter";
import capitalize from "lodash/capitalize";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import React, { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { Array, Number, String, Unknown } from "runtypes";
import { formatDate } from "screens/common/modal/formatters";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import type { ChildWorkflowErrorDetails, ChildWorkflowStep, ClarificationDetail, Workflow } from "types/workflows/workflow";
import { FilterInput } from "./FilterInput";
import { useButtonProps } from "hooks";
import isArray from "lodash/isArray";
import mapValues from "lodash/mapValues";
import ECHighlighterLib from "react-ec-highlighter";
import { FiCheck } from "react-icons/fi";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MdOutlineQuestionMark, MdOutlineRefresh, MdOutlineCircle } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { WorkflowStatusIcon } from "./WorkflowStatusIcon";
import { retryWorkflow } from "api/workflowsAdmin";
import { getUsersByIds } from "api/user";
import type { User } from "@charliai/node-core-lib/lib/src/userService/models/User";
import { GanttChartModal } from "./GanttChartModal";
import type { GanttTask } from "./GanttChart";
import { v4 as uuid } from "uuid";

const MAX_NUMBER_DEFAULT_OPENED_STEPS = 500;

const ECHighlighter = ({ searchPhrase, text }: { searchPhrase: string; text: string }) => {
  if (!text) {
    return <Text fontSize={"small"}>{"N/A"}</Text>;
  }

  return <ECHighlighterLib searchPhrase={searchPhrase} text={text ?? ""} />;
};

interface Props {
  id: string;
  workflowSummary: Workflow;
  onClose: () => void;
}

const formatEntityValue = (value: unknown): string => {
  if (String.guard(value)) {
    return value;
  } else if (Number.guard(value)) {
    return value.toString();
  } else if (Array(String).guard(value)) {
    return `[${value.join(", ")}]`;
  } else if (Array(Number).guard(value)) {
    return `[${value.map((item) => item.toString()).join(", ")}]`;
  } else if (Array(Unknown).guard(value)) {
    return `Array of ${value.length} object${value.length === 1 ? "" : "s"}`;
  } else {
    return `Object value`;
  }
};

const getClarificationText = (clarificationTask: ClarificationDetail) => {
  if (clarificationTask.entity.entity === "auth_granted") {
    if (Array(String).guard(clarificationTask.entity.value)) {
      return `${clarificationTask.entity.value[1]}`;
    } else {
      return "User input something wrong";
    }
  } else {
    return formatEntityValue(clarificationTask.entity.value);
  }
};

const compareTasks = (task1: ChildWorkflowStep, task2: ChildWorkflowStep) => {
  const task1Date = task1.type === "clarification" ? task1.completionDate : task1.creationDate;
  const task2Date = task2.type === "clarification" ? task2.completionDate : task2.creationDate;

  return new Date(task1Date).getTime() - new Date(task2Date).getTime();
};

const iconProps: IconProps = {
  boxSize: "1rem",
  color: "white",
  fontWeight: "bold",
  p: "2px",
  borderRadius: "full",
  border: "none",
};

export const getStatusIcon = (status: string, boxSize?: string, altCheckmark?: boolean) => {
  switch (status) {
    case "succeeded":
    case "complete":
      return (
        <>
          {altCheckmark ? (
            <Icon as={IoMdCheckmarkCircleOutline} {...iconProps} color={`${getStatusColor(status)}.500`} boxSize={boxSize} p="0" />
          ) : (
            <Icon as={FiCheck} bgColor={`${getStatusColor(status)}.500`} {...iconProps} boxSize={boxSize} />
          )}
        </>
      );
    case "queued":
      return <Icon as={BiDotsHorizontalRounded} bgColor={`${getStatusColor(status)}.400`} {...iconProps} boxSize={boxSize} />;
    case "in_progress":
      return <Icon as={BiDotsHorizontalRounded} bgColor={`${getStatusColor(status)}.400`} {...iconProps} boxSize={boxSize} />;
    case "clarification_needed":
      return <Icon as={MdOutlineQuestionMark} bgColor={`${getStatusColor(status)}.500`} {...iconProps} boxSize={boxSize} />;
    case "failed":
    case "error":
    case "denied_intent_confirmation":
      return <Icon as={MdOutlineRefresh} bgColor={`${getStatusColor(status)}.400`} {...iconProps} boxSize={boxSize} />;
    case "failed_checkstop":
      return <Icon as={MdOutlineCircle} bgColor={`${getStatusColor(status)}.400`} {...iconProps} boxSize={boxSize} />;
    default:
      return <Icon as={MdOutlineCircle} bgColor={`${getStatusColor(status)}.400`} {...iconProps} boxSize={boxSize} />;
  }
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "succeeded":
    case "complete":
      return "green";
    case "queued":
    case "in_progress":
    case "denied_intent_confirmation":
    case "failed_checkstop":
    case "awaiting_async_task": {
      return "orange";
    }
    case "failed":
    case "error":
      return "red";
    case "clarification_needed":
      return "blue";
    default:
      return "gray";
  }
};

export const outputFormatWithSeconds = "do MMM yyyy 'at' h:mm:ss aa";

export const WorkflowSummary = ({ workflowSummary: workflow, id, onClose }: Props) => {
  const { show_detailed_workflow_summary: showWorkflowDetails, read_workflows_payloads_admin: showWorkflowPayloadAdminLink } =
    useEntitlements();
  const user = useUserProfile();
  const { setTaskId, setWorkflow, setChildWorkflow, onWorkflowPayloadPanelOpen, isLoadingChildWorkflows } = useWorkflowContext();
  const handleViewTaskClick = (taskId: string) => {
    setTaskId(taskId);
    onWorkflowPayloadPanelOpen();
  };
  const handleViewErrorClick = (workflow: Workflow, childWorkflowId: string, taskId: string) => {
    setTaskId(taskId);
    setWorkflow(workflow);
    setChildWorkflow(childWorkflowId);
    onWorkflowPayloadPanelOpen();
  };

  const textColor = useColorModeValue("secondary.default", "gray300");
  const codeColor = useColorModeValue("blackAlpha", "white");
  const codeBgColor = useColorModeValue("gray.200", "#191f23");
  const errorBgColor = useColorModeValue("#FFEEF1", "gray.700");
  const errorStatusColor = useColorModeValue("#667E87", "#FFEEF1");
  const errorMessageColor = useColorModeValue("#212B34", "#FFEEF1");
  const [searchText, setSearchText] = useState<string>("");
  const commonButtonProps = useButtonProps("sm", "secondary");
  const [expandedByChildWorkflow, setExpandedByChildWorkflow] = useState<Record<string, number>>({});
  const [isRetrying, setIsRetrying] = useState(false);
  const toast = useToast();
  const hasViewUsers = useEntitlementKey("view_users");
  const [workflowUser, setWorkflowUser] = useState<User | null>(null);
  const containerRefCallback = useCallback((ref: HTMLDivElement | null) => {
    if (!ref) {
      return;
    }
  }, []);
  const hasGanttChart = useEntitlementKey("ui_workflow_gantt_chart");
  const { isOpen: isOpenGantt, onClose: onCloseGantt, onOpen: onOpenGantt } = useDisclosure();

  const renderErrorDetails = (errorDetails: ChildWorkflowErrorDetails): JSX.Element => {
    const details = (() => {
      switch (errorDetails.type) {
        case "could_not_choose_task":
          return (
            <>
              Multiple tasks with intent{" "}
              <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                {errorDetails.intent}
              </Code>{" "}
              exist, could not pick one
            </>
          );
        case "could_not_resolve_required_entities":
          return (
            <>
              Could not resolve required entity{" "}
              <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                {errorDetails.entityName}
              </Code>{" "}
              which is necessary for intent{" "}
              <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                {errorDetails.intent}
              </Code>
            </>
          );
        case "intent_not_found":
          return (
            <>
              Intent{" "}
              <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                {errorDetails.intent}
              </Code>{" "}
              does not exist
            </>
          );
        case "no_suitable_task_found":
          return (
            <>
              Intent{" "}
              <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                {errorDetails.intent}
              </Code>{" "}
              exists, but requirements are not satisfied
            </>
          );
        case "task_with_intent_exists_but_failed":
          return (
            <>
              Intent{" "}
              <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                {errorDetails.intent}
              </Code>{" "}
              exists, but execution failed
            </>
          );
        default:
          return <>Unexpected reason</>;
      }
    })();

    return <Text fontSize="xs">Error details: {details}</Text>;
  };

  const allTasks: GanttTask[] = useMemo(() => {
    const groupedTasksCount: Record<string, number> = {};
    return workflow.childWorkflows.flatMap((childWorkflow) =>
      childWorkflow.workflowSteps.reduce((acc: GanttTask[], task) => {
        if (task.type !== "task" || !task.startDate) {
          return acc;
        }

        const startDate = new Date(task.startDate);
        const completionDate = task.completionDate ? new Date(task.completionDate) : new Date();

        if (completionDate.getTime() - startDate.getTime() < 2000) {
          const lastTask: GanttTask | undefined = acc[acc.length - 1];

          if (lastTask && lastTask.taskStatus === "grouped" && startDate.getTime() - lastTask.endDate.getTime() < 2000) {
            groupedTasksCount[lastTask.taskId] += 1;
            lastTask.endDate = completionDate;
            lastTask.taskName = `${groupedTasksCount[lastTask.taskId]} tasks`;
            return acc;
          } else {
            const tempId = uuid();
            groupedTasksCount[tempId] = 1;

            return [
              ...acc,
              {
                taskName: `${groupedTasksCount[tempId]} tasks`,
                taskStatus: "grouped",
                taskId: tempId,
                creationDate: new Date(task.creationDate),
                startDate: startDate,
                endDate: completionDate,
                childWorkflowId: childWorkflow.id,
                childWorkflowIntent: childWorkflow.intent,
                childWorkflowStatus: childWorkflow.status,
              },
            ];
          }
        } else {
          return [
            ...acc,
            {
              taskName: task.name,
              taskIntent: task.intent,
              taskStatus: task.status,
              taskId: task.id,
              creationDate: new Date(childWorkflow.creationDate),
              startDate: startDate,
              endDate: completionDate,
              childWorkflowId: childWorkflow.id,
              childWorkflowIntent: childWorkflow.intent,
              childWorkflowStatus: childWorkflow.status,
            },
          ];
        }
      }, [])
    );
  }, [workflow.childWorkflows]);

  const filteredChildWorkflows = useMemo(() => {
    if (searchText === "") {
      return workflow.childWorkflows;
    }

    return filter(workflow.childWorkflows, (childWorkflow) => {
      const { intent, inputEntities, status } = childWorkflow;

      const doesChildWorkflowMatches =
        intent.toLowerCase().includes(searchText.toLowerCase()) ||
        status.toLowerCase().includes(searchText.toLowerCase()) ||
        inputEntities?.some((entity) => entity.entity.toLowerCase().includes(searchText.toLowerCase()));

      if (doesChildWorkflowMatches) {
        return true;
      }

      const doesWorkflowStepMatches = childWorkflow.workflowSteps.some((task) => {
        if (task.type === "task") {
          const { intent, status, name } = task;

          return (
            intent?.toLowerCase().includes(searchText.toLowerCase()) ||
            status.toLowerCase().includes(searchText.toLowerCase()) ||
            name.toLowerCase().includes(searchText.toLowerCase())
          );
        } else if (task.type === "clarification") {
          const { entity } = task;

          return entity.entity.toLowerCase().includes(searchText.toLowerCase());
        } else if (task.type === "prompt") {
          const { title } = task;

          return title.toLowerCase().includes(searchText.toLowerCase());
        } else {
          return false;
        }
      });

      return doesWorkflowStepMatches;
    });
  }, [searchText, workflow.childWorkflows]);

  const nonCompletedChildWorkflows = useMemo(() => {
    return filteredChildWorkflows.filter((childWorkflow) => childWorkflow.status !== "complete");
  }, [filteredChildWorkflows]);

  useEffect(() => {
    const totalOfSteps = filteredChildWorkflows.reduce((acc, childWorkflow) => acc + childWorkflow.workflowSteps.length, 0);

    setExpandedByChildWorkflow(
      filteredChildWorkflows.reduce(
        (acc, childWorkflow) => ({
          ...acc,
          [childWorkflow.id]: totalOfSteps > MAX_NUMBER_DEFAULT_OPENED_STEPS ? -1 : 0,
        }),
        {}
      )
    );
  }, [filteredChildWorkflows]);

  const mainRef = useRef<HTMLDivElement | null>(null);
  const commonTileProps = useTileProps();

  const onRetry = () => {
    setIsRetrying(true);
    retryWorkflow(workflow.id)
      .then(() => {
        toast({
          title: "Workflow is being retried",
          description: `Workflow with id ${workflow.id} is being retried`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        onClose();
      })
      .catch((err) => {
        toast({
          title: "Failed to retry workflow",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => setIsRetrying(false));
  };

  useEffect(() => {
    if (!hasViewUsers) {
      return;
    }

    getUsersByIds([workflow.userId])
      .then((res) => {
        if (res.length > 0) {
          setWorkflowUser(res[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [workflow.userId, hasViewUsers]);

  return (
    <>
      <GanttChartModal isOpen={isOpenGantt} onClose={onCloseGantt} tasks={allTasks} width={document.body.clientWidth - 70} />
      <Box ref={containerRefCallback} pb="4rem">
        {hasGanttChart && (
          <Button
            isDisabled={allTasks.length === 0}
            isLoading={isLoadingChildWorkflows}
            mb={"1rem"}
            onClick={() => onOpenGantt()}
            {...commonButtonProps}>
            Open Gantt Chart
          </Button>
        )}
        <Stack id="workflow-top" spacing={2} marginBottom="20px">
          <Stack direction="row" align="center">
            <Text fontSize="xs">ID</Text>
            <Code background={codeBgColor} colorScheme={codeColor} fontSize="xs">
              {workflow.id}
            </Code>
          </Stack>
          {hasViewUsers && (
            <>
              <Stack direction="row" align="center">
                <Text fontSize="xs">User ID</Text>
                <Code background={codeBgColor} colorScheme={codeColor} fontSize="xs">
                  {workflow.userId}
                </Code>
              </Stack>
              <Stack direction="row" align="center">
                <Text fontSize="xs">User Name</Text>
                <Code background={codeBgColor} colorScheme={codeColor} fontSize="xs">
                  {workflowUser ? `${workflowUser.firstName} ${workflowUser.lastName}` : "Loading..."}
                </Code>
              </Stack>
              <Stack direction="row" align="center">
                <Text fontSize="xs">User Email</Text>
                <Code background={codeBgColor} colorScheme={codeColor} fontSize="xs">
                  {workflowUser ? workflowUser.email : "Loading..."}
                </Code>
              </Stack>
            </>
          )}

          <Text fontSize="xs">
            Intent: <Text as="b">{workflow.intent}</Text>
          </Text>
          <Stack direction="row" align="center">
            <Text fontSize="xs">Status</Text>
            <Badge colorScheme={getStatusColor(workflow.status)}>{capitalize(workflow.status).split("_").join(" ")}</Badge>
            {workflow.canBeRetried && (
              <Tooltip label={"Retry Workflow"} aria-label="Retry Workflow">
                <IconButton
                  disabled={isRetrying}
                  onClick={() => onRetry()}
                  {...commonButtonProps}
                  aria-label="Retry Workflow"
                  icon={<Icon as={MdOutlineRefresh} />}
                />
              </Tooltip>
            )}
          </Stack>
          <Stack borderLeft="1px solid gray">
            <Stack direction="row" align="center" pl="1rem">
              <Text fontSize="xs">Started</Text>
              <Text fontSize="xs" as="em">{`${formatDate(new Date(workflow.creationDate), outputFormatWithSeconds)}`}</Text>
            </Stack>
            {workflow.completionDate && (
              <Stack direction="row" align="center" pl="1rem">
                <Text fontSize="xs">Finished</Text>
                <Text fontSize="xs" as="em">{`${formatDate(new Date(workflow.completionDate), outputFormatWithSeconds)}`}</Text>
              </Stack>
            )}
          </Stack>
        </Stack>
        <>
          <Box paddingBottom="5px" display="flex" alignItems={"center"}>
            <Text fontSize="sm" fontWeight="bold" mr="0.5rem">
              {workflow.childWorkflows.length} Sub Workflow{workflow.childWorkflows.length > 1 && "s"}
            </Text>
          </Box>
          <Stack direction="row" justifyContent={"flex-start"} py="1rem" overflowY="auto" spacing="1rem">
            {workflow.childWorkflows.map((childWorkflow, i) => (
              <Box
                cursor="pointer"
                maxWidth={"4rem"}
                textAlign="left"
                width="100%"
                onClick={() => {
                  const element = document.getElementById(`child-workflow-${childWorkflow.id}`);
                  if (element) {
                    element.scrollIntoView();
                  }
                }}>
                <Stack direction="row" justifyContent="left" alignItems="left">
                  <Tooltip key={`step-${i}`} label={childWorkflow.status} aria-label="Workflow Step">
                    <Badge variant={"solid"} colorScheme="gray">
                      {i + 1}
                    </Badge>
                  </Tooltip>
                  <WorkflowStatusIcon status={childWorkflow.status} />
                </Stack>
                <Text pl="4px" borderLeft="1px solid gray" fontSize={"xs"}>
                  {childWorkflow.intent.replace(/_+/g, " ")}
                </Text>
              </Box>
            ))}
          </Stack>
        </>
        {workflow.status === "failed" && (
          <Box mt="1rem">
            <Box paddingBottom="5px" display="flex" alignItems={"center"}>
              <Text fontSize="sm" fontWeight="bold" mr="0.5rem">
                {nonCompletedChildWorkflows.length} Non-completed Sub Workflow{nonCompletedChildWorkflows.length > 1 && "s"}
              </Text>
            </Box>
            <Stack direction="row" justifyContent={"flex-start"} py="1rem" overflowY="auto" spacing="1rem">
              {nonCompletedChildWorkflows.map((childWorkflow, i) => (
                <Box
                  cursor="pointer"
                  maxWidth={"4rem"}
                  textAlign="left"
                  width="100%"
                  onClick={() => {
                    const element = document.getElementById(`child-workflow-${childWorkflow.id}`);
                    if (element) {
                      element.scrollIntoView();
                    }
                  }}>
                  <Stack direction="row" justifyContent="left" alignItems="left">
                    <Tooltip key={`step-${i}`} label={childWorkflow.status} aria-label="Workflow Step">
                      <Badge variant={"solid"} colorScheme="gray">
                        {i + 1}
                      </Badge>
                    </Tooltip>
                    <WorkflowStatusIcon status={childWorkflow.status} />
                  </Stack>
                  <Text pl="4px" borderLeft="1px solid gray" fontSize={"xs"}>
                    {childWorkflow.intent.replace(/_+/g, " ")}
                  </Text>
                </Box>
              ))}
            </Stack>
          </Box>
        )}

        <Stack py="1rem" direction="row" width="100%" justifyContent={"space-between"}>
          <Stack direction="row" width="100%" justifyContent={"space-between"} align="center">
            <Text fontSize="sm" fontWeight="bold" width="9rem">
              Filter Workflows
            </Text>
            <FilterInput searchText={searchText} setSearchText={setSearchText} placeholder={"filter by intent, entity or status"} />
          </Stack>
          <Stack direction="row">
            <Button onClick={() => setExpandedByChildWorkflow((prev) => mapValues(prev, () => 0))} {...commonButtonProps}>
              Expand All
            </Button>
            <Button onClick={() => setExpandedByChildWorkflow((prev) => mapValues(prev, () => -1))} {...commonButtonProps}>
              Close All
            </Button>
          </Stack>
        </Stack>

        {isLoadingChildWorkflows ? (
          <Box display={"flex"} justifyContent="center" alignItems={"center"}>
            <TypingIndicator />
          </Box>
        ) : (
          filteredChildWorkflows.map((childWorkflow, i) => (
            <Box id={`child-workflow-${childWorkflow.id}`} key={`${id}-child-workflow-${childWorkflow.id}`} paddingTop="10px">
              <Stack spacing={2} marginBottom=".5rem">
                <Stack direction="row" justifyContent={"space-between"} width="100%">
                  <Stack direction="row" align="center">
                    <Text fontSize="xs" fontWeight="semibold">
                      Workflow
                    </Text>
                    <Badge variant={"solid"} colorScheme="gray">
                      {i + 1}
                    </Badge>
                  </Stack>
                  <Stack
                    cursor="pointer"
                    direction="row"
                    onClick={() => {
                      const element = document.getElementById("workflow-top");
                      if (element) {
                        element.scrollIntoView();
                      }
                    }}>
                    <Center>
                      <Text fontSize="xs">Scroll to top</Text>
                      <ChevronUpIcon color={textColor} boxSize="1.5rem" />
                    </Center>
                  </Stack>
                </Stack>
                <Text fontSize="xs">
                  Intent: <ECHighlighter searchPhrase={searchText} text={childWorkflow.intent} />
                </Text>
                {childWorkflow.inputEntities && !isEmpty(childWorkflow.inputEntities) && (
                  <>
                    <Text fontSize="xs">Entities</Text>
                    <Stack borderLeft="1px solid gray">
                      {childWorkflow.inputEntities.map((entity, id) => (
                        <Text wordBreak={"break-all"} key={`${id}-entity-${entity}`} fontSize="xs" pl="1rem">
                          <ECHighlighter searchPhrase={searchText} text={entity.entity} />
                          <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                            {formatEntityValue(entity.value)}
                          </Code>
                        </Text>
                      ))}
                    </Stack>
                  </>
                )}
                <Stack direction="row" align="center">
                  <Text fontSize="xs">Status: </Text>
                  <Badge colorScheme={getStatusColor(childWorkflow.status)}>{capitalize(childWorkflow.status).split("_").join(" ")}</Badge>
                </Stack>
                <Stack borderLeft="1px solid gray">
                  {childWorkflow.errorDetails && (
                    <Stack direction="row" align="center" pl="1rem">
                      {renderErrorDetails(childWorkflow.errorDetails)}
                    </Stack>
                  )}
                  <Stack direction="row" align="center" pl="1rem">
                    <Text fontSize="xs">Started</Text>
                    <Text fontSize="xs">{`${formatDate(new Date(childWorkflow.creationDate), outputFormatWithSeconds)}`}</Text>
                  </Stack>
                  {childWorkflow.completionDate && (
                    <Stack direction="row" align="center" pl="1rem">
                      <Text fontSize="xs">Finished</Text>
                      <Text fontSize="xs">{`${formatDate(new Date(childWorkflow.completionDate), outputFormatWithSeconds)}`}</Text>
                    </Stack>
                  )}
                </Stack>
              </Stack>
              {childWorkflow.workflowSteps.length > 0 && (
                <Accordion
                  onChange={(expandedItem) => {
                    setExpandedByChildWorkflow((prev) => ({
                      ...prev,
                      [childWorkflow.id]: isArray(expandedItem) ? expandedItem[0] : expandedItem,
                    }));
                  }}
                  index={expandedByChildWorkflow[childWorkflow.id]}
                  allowToggle
                  pb="2rem">
                  <AccordionItem key={`ch-accordion-item-${childWorkflow.id}`}>
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton paddingInline="0!important">
                          <Box as="span" flex="1" textAlign="left" fontSize="xs" fontWeight="semibold">
                            {`Workflow Step${childWorkflow.workflowSteps.length !== 1 ? "s" : ""} [ ${
                              childWorkflow.workflowSteps.length
                            } ]`}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        {isExpanded ? (
                          <AccordionPanel p="0!important" paddingInline={"0!important"}>
                            <List>
                              {[...childWorkflow.workflowSteps].sort(compareTasks).map((task, taskIndex) => {
                                if (task.type === "task") {
                                  const requires = [
                                    ...(task.requires ?? []).map((item) => `${item}!`),
                                    ...(task.mustIncludeEntities ?? []).map((item) => `[${item}!]`),
                                    ...(task.optionalRequires ?? []),
                                    ...(task.shouldIncludeEntities ?? []).map((item) => `[${item}]`),
                                  ];

                                  const maybeFormattedStartDate = task.startDate
                                    ? [formatDate(new Date(task.startDate), outputFormatWithSeconds)]
                                    : [];
                                  const maybeFormattedDuration =
                                    task.startDate && task.completionDate
                                      ? [
                                          humanizeDuration(Date.parse(task.completionDate) - Date.parse(task.startDate), {
                                            maxDecimalPoints: 2,
                                          }),
                                        ]
                                      : [];
                                  const maybeFormattedDurationWithStartDate = flatten([
                                    maybeFormattedStartDate,
                                    maybeFormattedDuration,
                                  ]).join(" - ");

                                  return (
                                    <React.Fragment key={`${id}-child-workflow-task-${task.id}-${task.startDate}`}>
                                      <Divider borderColor="gray.200" />
                                      <ListItem px="0!important" display="flex" alignItems="center" mt=".5rem" fontSize="xs">
                                        <Box display="flex" flexDirection="column" width="100%">
                                          <Stack direction="row" justifyContent="space-between">
                                            <Center ref={mainRef}>
                                              <Tooltip label={task.status}>
                                                <Stack direction="row" spacing=".5rem">
                                                  <WorkflowStatusIcon status={task.status} />
                                                  <Badge variant={"outline"} colorScheme="gray">
                                                    {taskIndex + 1}
                                                  </Badge>
                                                  <Text fontSize="xs" as="b">
                                                    <ECHighlighter searchPhrase={searchText} text={task.name} />
                                                  </Text>
                                                  {showWorkflowDetails && task.executionContext && task.executionContext.length > 0 && (
                                                    <Box>
                                                      <Popover isLazy trigger="hover">
                                                        <PopoverTrigger>
                                                          <InfoOutlineIcon cursor="pointer" height="1.5em" />
                                                        </PopoverTrigger>
                                                        <PopoverContent>
                                                          <PopoverBody>
                                                            <Box {...commonTileProps}>
                                                              <Text fontWeight={"semibold"}>Why did this task run?</Text>
                                                              {task.executionContext &&
                                                                task.executionContext.map((reason, index) => (
                                                                  <Text key={`task-execution-context-${task.id}-${index}`}>
                                                                    {index > 0 ? "∟ " : ""}
                                                                    {reason}
                                                                  </Text>
                                                                ))}
                                                            </Box>
                                                          </PopoverBody>
                                                        </PopoverContent>
                                                      </Popover>
                                                    </Box>
                                                  )}
                                                </Stack>
                                              </Tooltip>
                                            </Center>
                                            {(user.id === workflow.userId || showWorkflowPayloadAdminLink) && (
                                              <Stack
                                                key={`workflow-summary-item-${task.id}`}
                                                cursor="pointer"
                                                direction="row"
                                                onClick={() => handleViewTaskClick(task.id)}>
                                                <Center>
                                                  <Text fontSize="xs">View Task Payload</Text>
                                                  <ChevronRightIcon color={textColor} boxSize="1.5rem" />
                                                </Center>
                                              </Stack>
                                            )}
                                          </Stack>
                                          {showWorkflowDetails && (
                                            <Box pl="1.3rem" py=".5rem">
                                              <Box>
                                                {task.status === "in_progress" && <Text fontSize="xs" as="em">{`${task.status}`}</Text>}
                                              </Box>

                                              {task.intent && <Text fontSize="xs" as="em">{`/${task.intent}`}</Text>}
                                              {maybeFormattedDurationWithStartDate && (
                                                <Text fontSize="xs">{maybeFormattedDurationWithStartDate}</Text>
                                              )}
                                              {requires.length > 0 && (
                                                <Text fontSize="xs">
                                                  Requires:{" "}
                                                  {requires.map((require, i) => (
                                                    <React.Fragment
                                                      key={`${id}-workflow-summary-task-requires-${task.registeredTaskId}-${task.startDate}-${i}`}>
                                                      {i > 0 && ", "}
                                                      <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                                                        {require}
                                                      </Code>
                                                    </React.Fragment>
                                                  ))}
                                                </Text>
                                              )}
                                              {task.produces && task.produces.length > 0 && (
                                                <Text fontSize="xs">
                                                  Produces:{" "}
                                                  {task.produces.map((produce, i) => (
                                                    <React.Fragment
                                                      key={`${id}-workflow-summary-task-produces-${task.registeredTaskId}-${task.startDate}-${i}`}>
                                                      {i > 0 && ", "}
                                                      <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                                                        {produce}
                                                      </Code>
                                                    </React.Fragment>
                                                  ))}
                                                </Text>
                                              )}
                                              {task.postExecutionIntents && task.postExecutionIntents.length > 0 && (
                                                <Text fontSize="xs">
                                                  Post Execution Intents:{" "}
                                                  {task.postExecutionIntents.map((intent, i) => (
                                                    <React.Fragment
                                                      key={`${id}-workflow-summary-task-pei-${task.registeredTaskId}-${task.startDate}-${i}`}>
                                                      {i > 0 && ", "}
                                                      <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                                                        <ECHighlighter searchPhrase={searchText} text={intent} />
                                                      </Code>
                                                    </React.Fragment>
                                                  ))}
                                                </Text>
                                              )}
                                              {task.error && (
                                                <Box
                                                  className="ch-workflow-task-error"
                                                  data-task-name={task.name}
                                                  data-task-error-status={task.error.status}
                                                  data-task-error-message={task.error.message}
                                                  marginTop={"10px"}
                                                  backgroundColor={errorBgColor}
                                                  borderRadius="12px"
                                                  overflow="hidden"
                                                  position="relative"
                                                  borderWidth="1px">
                                                  <Stack direction="row" p="1rem" align="center">
                                                    <Stack align="flex-start">
                                                      <>
                                                        <Text fontSize="xs" color={errorStatusColor}>
                                                          <ECHighlighter searchPhrase={searchText} text={task.error.status} />
                                                        </Text>
                                                        <Text fontSize="xs" color={errorMessageColor} fontWeight="400" mt="0!important">
                                                          {task.error.message}
                                                        </Text>
                                                        {task.error.details && (
                                                          <Stack
                                                            key={`workflow-summary-item-${task.id}`}
                                                            cursor="pointer"
                                                            direction="row"
                                                            onClick={() => handleViewErrorClick(workflow, childWorkflow.id, task.id)}>
                                                            <Center>
                                                              <Text fontSize="xs">View Error Details</Text>
                                                              <ChevronRightIcon color={textColor} boxSize="1.5rem" />
                                                            </Center>
                                                          </Stack>
                                                        )}
                                                      </>
                                                    </Stack>
                                                  </Stack>
                                                </Box>
                                              )}
                                            </Box>
                                          )}
                                        </Box>
                                      </ListItem>
                                    </React.Fragment>
                                  );
                                } else if (task.type === "clarification") {
                                  return (
                                    <React.Fragment key={`${id}-child-workflow-clarification-${task.completionDate}`}>
                                      <Divider borderColor="primary.lightGray" />
                                      <ListItem px="0!important" display="flex" alignItems="center" p={2} margin="2px" fontSize="xs">
                                        <Box display="flex" flexDirection="column">
                                          <Stack direction="row" alignItems={"flex-start"} py=".5rem">
                                            <Box pt="3px">{<WorkflowStatusIcon status={"complete"} />}</Box>
                                            <Badge variant={"solid"} colorScheme="gray">
                                              {taskIndex + 1}
                                            </Badge>
                                            <Text fontSize="xs" as="b">
                                              Clarification Provided
                                            </Text>
                                          </Stack>
                                          <Text paddingLeft="1.3rem" fontSize="xs">
                                            <ECHighlighter searchPhrase={searchText} text={task.entity.entity} />:{" "}
                                            <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                                              {getClarificationText(task)}
                                            </Code>
                                          </Text>
                                        </Box>
                                      </ListItem>
                                    </React.Fragment>
                                  );
                                } else if (task.type === "event") {
                                  return (
                                    <React.Fragment key={`${id}-child-workflow-clarification-${task.creationDate}`}>
                                      <Divider borderColor="primary.lightGray" />
                                      <ListItem px="0!important" display="flex" alignItems="center" p={2} margin="2px" fontSize="xs">
                                        <Box display="flex" flexDirection="column">
                                          <Stack direction="row" alignItems={"flex-start"} py=".5rem">
                                            <Box pt="3px">
                                              <WorkflowStatusIcon status={"complete"} />
                                            </Box>
                                            <Badge variant={"outline"} colorScheme="gray">
                                              {taskIndex + 1}
                                            </Badge>
                                            <Text fontSize="xs" as="b">
                                              {task.title}
                                            </Text>
                                          </Stack>
                                          <Text paddingLeft="1.3rem" fontSize="xs">
                                            <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                                              {task.description}
                                            </Code>
                                          </Text>
                                        </Box>
                                      </ListItem>
                                    </React.Fragment>
                                  );
                                } else {
                                  // prompt
                                  return (
                                    <React.Fragment key={`${id}-child-workflow-prompt-${task.creationDate}`}>
                                      <Divider borderColor="primary.lightGray" />
                                      <ListItem px="0!important" display="flex" alignItems="center" p={2} margin="2px" fontSize="xs">
                                        <Box display="flex" flexDirection="column">
                                          <Stack direction="row" alignItems={"flex-start"} py=".5rem">
                                            <Box pt="3px">
                                              <WorkflowStatusIcon status={"complete"} />
                                            </Box>
                                            <Badge variant={"solid"} colorScheme="gray">
                                              {taskIndex + 1}
                                            </Badge>
                                            <Text fontSize="xs" as="b">
                                              Input Requested
                                            </Text>
                                            {showWorkflowDetails && task.executionContext && task.executionContext.length > 0 && (
                                              <Box ref={mainRef}>
                                                <Popover isLazy trigger="hover">
                                                  <PopoverTrigger>
                                                    <InfoOutlineIcon cursor="pointer" height="1.5em" />
                                                  </PopoverTrigger>

                                                  <PopoverContent>
                                                    <PopoverBody>
                                                      <Box {...commonTileProps}>
                                                        <Text fontWeight={"semibold"}>Why was this prompt triggered?</Text>
                                                        {task.executionContext &&
                                                          task.executionContext.map((reason, index) => (
                                                            <Text key={`task-execution-context-${task.creationDate}-${index}`}>
                                                              {index > 0 ? "∟ " : ""}
                                                              {reason}
                                                            </Text>
                                                          ))}
                                                      </Box>
                                                    </PopoverBody>
                                                  </PopoverContent>
                                                </Popover>
                                              </Box>
                                            )}
                                          </Stack>
                                          <Text paddingLeft="1.3rem" fontSize="xs">
                                            {task.title}
                                          </Text>
                                        </Box>
                                      </ListItem>
                                    </React.Fragment>
                                  );
                                }
                              })}
                            </List>
                          </AccordionPanel>
                        ) : null}
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
              )}
            </Box>
          ))
        )}
      </Box>
    </>
  );
};
