import { Box, Center, Text, Popover, PopoverTrigger, PopoverContent, PopoverBody } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { MarkdownViewer } from "screens/markdown/MarkdownViewer";

interface ScaleBarProps {
  analyticalValue: number;
  sentimentValue: number;
  analyticalSummary?: string;
  sentimentSummary?: string;
}

const ScaleBar: React.FC<ScaleBarProps> = ({ analyticalValue, sentimentValue, analyticalSummary, sentimentSummary }) => {
  const clampValue = (value: number): number => Math.min(Math.max(value, 0), 100);

  const positions = useMemo(
    () => ({
      analytical: clampValue(analyticalValue),
      sentiment: clampValue(sentimentValue),
    }),
    [analyticalValue, sentimentValue]
  );

  const Indicator = ({
    position,
    label,
    summary,
    isTop,
    title,
  }: {
    position: number;
    label: string;
    summary?: string;
    isTop: boolean;
    title: string;
  }) => (
    <Popover placement="bottom-end" isLazy gutter={4} trigger="hover">
      <PopoverTrigger>
        <Box position="absolute" left={`${position}%`} transform="translateX(-50%)" {...(isTop ? { top: "-25px" } : { bottom: "-25px" })}>
          {isTop ? (
            <>
              <Text color="gray.600" fontSize="12px" textAlign="center">
                {label}
              </Text>
              <Center>
                <Box
                  width="0"
                  height="0"
                  borderLeft="12px solid transparent"
                  borderRight="12px solid transparent"
                  borderTop="16px solid"
                  borderTopColor="gray.600"
                />
              </Center>
            </>
          ) : (
            <>
              <Center>
                <Box
                  width="0"
                  height="0"
                  borderLeft="12px solid transparent"
                  borderRight="12px solid transparent"
                  borderBottom="16px solid"
                  borderBottomColor="gray.600"
                />
              </Center>
              <Text color="gray.600" fontSize="12px" textAlign="center">
                {label}
              </Text>
            </>
          )}
        </Box>
      </PopoverTrigger>
      {summary && (
        <PopoverContent width="100%" maxWidth="50rem" backgroundColor="gray.700" border="none">
          <PopoverBody px="1rem" pt="1rem" pb="0" boxShadow="2xl">
            <Text color="white" fontSize="md" fontWeight="bold" pb="1rem">
              {title}
            </Text>
            <MarkdownViewer fontColor="white" backgroundColor="gray.700" content={summary} fontSize="sm" />
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );

  return (
    <Box position="relative" width="100%">
      <Box height="2rem" bgGradient="linear(to-r, red.400, yellow.400, green.400)" position="relative" borderRadius="md" cursor="pointer">
        {analyticalValue > 0 && (
          <Indicator
            title="The Analytical Rating is based on financial fundamentals including regulatory filings and the financial data provided through trusted sources."
            position={positions.analytical}
            label="Analytical"
            summary={analyticalSummary}
            isTop={false}
          />
        )}
        {sentimentValue > 0 && (
          <Indicator
            title="The Sentiment Rating is based on market information such as news, analyst reports and financial information available through well known web sources."
            position={positions.sentiment}
            label="Sentiment"
            summary={sentimentSummary}
            isTop
          />
        )}
      </Box>
    </Box>
  );
};

export default ScaleBar;
