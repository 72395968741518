import type { Message } from "types/conversation";
import type { Workflow } from "types/workflows/workflow";

export const sortWorkflows = (workflow1: Workflow | undefined, workflow2: Workflow | undefined) => {
  if (!workflow1) {
    return 1;
  }

  if (!workflow2) {
    return 2;
  }

  return new Date(workflow1.creationDate).getTime() - new Date(workflow2.creationDate).getTime();
};

export const getMissingWorkflowIds = (messages: Message[], existingWorkflowsSet: Set<string>): string[] => {
  const workflowIds = messages.reduce((setIds: Set<string>, message) => {
    if (!message.data) {
      return setIds;
    }

    const data = message.data;

    const dataWorkflowsIds = (() => {
      return data.flatMap((dataValue) => {
        if (dataValue.type === "workflow_summary") {
          return dataValue.body.buttonElement.content.flatMap((workflow) =>
            existingWorkflowsSet.has(workflow.workflowId) ? [] : [workflow.workflowId]
          );
        }

        return [];
      });
    })();

    return new Set([...setIds, ...dataWorkflowsIds]);
  }, new Set([]));

  return Array.from(workflowIds);
};
