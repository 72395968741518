import { Literal, Union } from "runtypes";
import type { Static } from "runtypes";

// todo replace with String as the web-app does not need to verify this type
export const WorkflowStatus = Union(
  Literal("queued"),
  Literal("in_progress"),
  Literal("paused"),
  Literal("failed"),
  Literal("error"),
  Literal("intent_not_found"),
  Literal("no_suitable_task_found"),
  Literal("clarification_needed"),
  Literal("complete"),
  Literal("skipped_mandatory_clarification"),
  Literal("awaiting_async_task")
);

export type WorkflowStatus = Static<typeof WorkflowStatus>;
