import type { BoxProps } from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { Center } from "@chakra-ui/react";
import { Box, useColorModeValue, useDisclosure, useToast, Text } from "@chakra-ui/react";
import { useConfigForm, useConfigMap, useGetViewConfig, useProjectParams, useUppy, useUserProfile } from "hooks";
import type { ReactNode } from "react";
import { createRef } from "react";
import React, { createContext, useCallback, useState, useContext } from "react";
import type { DropzoneRef } from "react-dropzone";
import Dropzone from "react-dropzone";
import { AddNewProjectPanel } from "screens/panels/research/AddNewProjectPanel";
import { AddToCollectionPanel } from "./AddToCollectionPanel";
import type { ValueType } from "react-select";
import type { CalendarEvent } from "types/meetings";
import { ResearchContextProvider } from "screens/panels/research/ResearchContext";
import { ResearchHistoryPanel } from "screens/panels/research/ResearchHistoryPanel";
import { SettingsPanel } from "screens/panels/settings/SettingsPanel";
import { useLocation } from "react-router-dom";
import { BsPlusCircleDotted, BsDashCircleDotted } from "react-icons/bs";
import { ConversationContext } from "screens/thread/ConversationContext";
import { PortfolioSettingsPanel } from "screens/landing/tabs/project/projectLandingTileLayouts/components/PortfolioSettingsPanel";
const defaultThrowError = (): void => {
  throw new Error("Component must be nested inside <AddToCharliProvider />");
};

export const AddToCharliProviderContext = createContext({
  canSend: false,
  setCanSend: (value: boolean): void => {
    defaultThrowError();
  },
  isAdminPanelOpen: false,
  onAdminPanelClose: defaultThrowError,
  onAdminPanelOpen: defaultThrowError,
  isSettingsOpen: false,
  onSettingsOpen: defaultThrowError,
  onSettingsClose: defaultThrowError,
  isAddToCollectionModalOpen: false,
  onAddToCollectionModalClose: defaultThrowError,
  onAddToCollectionModalOpen: defaultThrowError,
  isProjectAddSourceOpen: false,
  onProjectAddSourceClose: defaultThrowError,
  onProjectAddSourceOpen: defaultThrowError,
  isShareModalOpen: false,
  onShareModalClose: defaultThrowError,
  onShareModalOpen: defaultThrowError,
  isVerifiedAIPanelOpen: false,
  onVerifiedAIPanelClose: defaultThrowError,
  onVerifiedAIPanelOpen: defaultThrowError,
  isPortfolioSettingsOpen: false,
  onPortfolioSettingsClose: defaultThrowError,
  onPortfolioSettingsOpen: defaultThrowError,
  isAddNewProjectPanelOpen: false,
  onAddNewProjectPanelClose: defaultThrowError,
  onAddNewProjectPanelOpen: defaultThrowError,
  isReportPanelOpen: false,
  onReportPanelClose: defaultThrowError,
  onReportPanelOpen: defaultThrowError,
  isProjectReportPanelOpen: false,
  onProjectReportPanelClose: defaultThrowError,
  onProjectReportPanelOpen: defaultThrowError,
  isProjectReportSelectorOpen: false,
  onProjectReportSelectorClose: defaultThrowError,
  onProjectReportSelectorOpen: defaultThrowError,
  isTagsPanelOpen: false,
  onTagsPanelClose: defaultThrowError,
  onTagsPanelOpen: defaultThrowError,
  isHighlightsPanelOpen: false,
  onHighlightsPanelClose: defaultThrowError,
  onHighlightsPanelOpen: defaultThrowError,
  isNotesPanelOpen: false,
  onNotesPanelClose: defaultThrowError,
  onNotesPanelOpen: defaultThrowError,
  isKeywordsNotesPanelOpen: false,
  onKeywordsNotesPanelClose: defaultThrowError,
  onKeywordsNotesPanelOpen: defaultThrowError,
  isSummarizePanelOpen: false,
  onSummarizePanelClose: defaultThrowError,
  onSummarizePanelOpen: defaultThrowError,
  onIntegrationsPanelOpen: (): void => {
    defaultThrowError();
  },
  isIntegrationsPanelOpen: false,
  onIntegrationsPanelClose: (): void => {
    defaultThrowError();
  },
  onContentViewOpen: (): void => {
    defaultThrowError();
  },
  isContentViewOpen: false,
  onContentViewClose: (): void => {
    defaultThrowError();
  },
  onContentJsonViewOpen: (): void => {
    defaultThrowError();
  },
  isContentJsonViewOpen: false,
  onContentJsonViewClose: (): void => {
    defaultThrowError();
  },
  actionView: undefined as AddToCharliAction | undefined,
  setActionView: defaultThrowError as (view: AddToCharliAction | undefined) => void,
  uppy: {} as ReturnType<typeof useUppy>["uppy"],
  uppyStatus: "ready" as ReturnType<typeof useUppy>["status"],
  uppyFiles: [] as ReturnType<typeof useUppy>["files"],
  resetFormState: defaultThrowError,
  notesText: "",
  setNotesText: (text: string): void => {
    defaultThrowError();
  },
  tags: [] as string[],
  setTags: (text: string[]): void => {
    defaultThrowError();
  },
  selectedCollection: undefined as CollectionValue | undefined,
  setSelectedCollection: (collection: CollectionValue | undefined): void => {
    defaultThrowError();
  },
  aliasText: "",
  setAliasText: (text: string): void => {
    defaultThrowError();
  },
  linkText: "",
  setLinkText: (text: string): void => {
    defaultThrowError();
  },
  isLinkHttps: false,
  setIsLinkHttps: (value: boolean): void => {
    defaultThrowError();
  },
  reportEmail: "",
  setReportEmail: (text: string): void => {
    defaultThrowError();
  },
  reportTemplate: "",
  setReportTemplate: (text: string): void => {
    defaultThrowError();
  },
  inputTopics: "" as string,
  setInputTopics: (text: string): void => {
    defaultThrowError();
  },
  showContentList: false,
  setShowContentList: (value: boolean): void => {
    defaultThrowError();
  },
  shouldCreateNewCollection: false,
  setShouldCreateNewCollection: (value: boolean): void => {
    defaultThrowError();
  },
  itemIdentifier: "" as string,
  setItemIdentifier: (text: string): void => {
    defaultThrowError();
  },
  calendarEvent: undefined as CalendarEvent | undefined,
  setCalendarEvent: (event?: CalendarEvent): void => {
    defaultThrowError();
  },
  notesInputState: "viewing" as NoteInputStates,
  setNotesInputState: (state: NoteInputStates): void => {
    defaultThrowError();
  },
  noteText: "" as string,
  setNoteText: (text: string): void => {
    defaultThrowError();
  },
  integrations: [] as string[],
  setIntegrations: (text: string[]): void => {
    defaultThrowError();
  },
  isIntegrationCategory: "",
  setIsIntegrationCategory: (text: string): void => {
    defaultThrowError();
  },
  isNewMenuAction: undefined as "resource" | "library" | "command" | undefined,
  setIsNewMenuAction: (value: "resource" | "library" | "command" | undefined): void => {
    defaultThrowError();
  },
  showHighlightsForm: false,
  setShowHighlightsForm: (value: boolean): void => {
    defaultThrowError();
  },
  hasParentPanel: false,
  setHasParentPanel: (value: boolean): void => {
    defaultThrowError();
  },
});

export const useAddToCharliContext = () => useContext(AddToCharliProviderContext);

export const AddToCharliActionValues = [
  "upload",
  "upload_file",
  "organize",
  "bookmark",
  "meeting",
  "valuation",
  "research",
  "memorandum",
] as const;
export type AddToCharliAction = typeof AddToCharliActionValues[number];
export type NoteInputStates = "viewing" | "editing" | "adding" | "deleting" | "updating";
export type CollectionValue = ValueType<
  {
    label: string;
    value?: string;
    alreadyExists?: boolean;
  },
  false
>;

export const AddToCharliProvider = ({ children, ...boxProps }: { children: ReactNode } & BoxProps) => {
  const { projectId } = useProjectParams();
  const { isOpen: isSettingsOpen, onOpen: onSettingsOpen, onClose: onSettingsClose } = useDisclosure();
  const { isOpen: isAdminPanelOpen, onOpen: onAdminPanelOpen, onClose: onAdminPanelClose } = useDisclosure();
  const { isOpen: isAddToCollectionModalOpen, onClose: onAddToCollectionModalClose, onOpen: onAddToCollectionModalOpen } = useDisclosure();
  const { isOpen: isShareModalOpen, onClose: onShareModalClose, onOpen: onShareModalOpen } = useDisclosure();
  const { isOpen: isVerifiedAIPanelOpen, onClose: onVerifiedAIPanelClose, onOpen: onVerifiedAIPanelOpen } = useDisclosure();
  const { isOpen: isPortfolioSettingsOpen, onClose: onPortfolioSettingsClose, onOpen: onPortfolioSettingsOpen } = useDisclosure();
  const { isOpen: isAddNewProjectPanelOpen, onClose: onAddNewProjectPanelClose, onOpen: onAddNewProjectPanelOpen } = useDisclosure();
  const { isOpen: isReportPanelOpen, onClose: onReportPanelClose, onOpen: onReportPanelOpen } = useDisclosure();
  const { isOpen: isProjectReportPanelOpen, onClose: onProjectReportPanelClose, onOpen: onProjectReportPanelOpen } = useDisclosure();
  const {
    isOpen: isProjectReportSelectorOpen,
    onClose: onProjectReportSelectorClose,
    onOpen: onProjectReportSelectorOpen,
  } = useDisclosure();
  const { isOpen: isProjectAddSourceOpen, onClose: onProjectAddSourceClose, onOpen: onProjectAddSourceOpen } = useDisclosure();
  const { isOpen: isHighlightsPanelOpen, onClose: onHighlightsPanelClose, onOpen: onHighlightsPanelOpen } = useDisclosure();
  const [showHighlightsForm, setShowHighlightsForm] = useState(false);
  const { isOpen: isNotesPanelOpen, onClose: onNotesPanelClose, onOpen: onNotesPanelOpen } = useDisclosure();
  const { isOpen: isKeywordsNotesPanelOpen, onClose: onKeywordsNotesPanelClose, onOpen: onKeywordsNotesPanelOpen } = useDisclosure();
  const { isOpen: isTagsPanelOpen, onClose: onTagsPanelClose, onOpen: onTagsPanelOpen } = useDisclosure();
  const { isOpen: isIntegrationsPanelOpen, onClose: onIntegrationsPanelClose, onOpen: onIntegrationsPanelOpen } = useDisclosure();
  const { isOpen: isContentViewOpen, onClose: onContentViewClose, onOpen: onContentViewOpen } = useDisclosure();
  const { isOpen: isContentJsonViewOpen, onClose: onContentJsonViewClose, onOpen: onContentJsonViewOpen } = useDisclosure();
  const [notesInputState, setNotesInputState] = useState<"viewing" | "editing" | "adding" | "deleting" | "updating">("viewing");
  const [noteText, setNoteText] = useState("");
  const { isOpen: isSummarizePanelOpen, onClose: onSummarizePanelClose, onOpen: onSummarizePanelOpen } = useDisclosure();
  const [canSend, setCanSend] = useState(false);
  const [actionView, setActionView] = useState<AddToCharliAction | undefined>(undefined);
  const { uppy, addFile, status: uppyStatus, files: uppyFiles, reset: resetUppyState } = useUppy();
  const toast = useToast();
  const dragAndDropHoverColor = useColorModeValue("white", "gray.400");
  const dragAndDropIconColor = useColorModeValue("charli.primaryBlue", "gray.300");
  const dragAndDropColor = useColorModeValue("rgb(255 255 255 / 70%)", "rgb(0 0 0 / 70%)");
  const [tags, setTags] = useState<string[]>([]);
  const [notesText, setNotesText] = useState("");
  const [selectedCollection, setSelectedCollection] = useState<CollectionValue | undefined>(undefined);
  const [aliasText, setAliasText] = useState("");
  const [linkText, setLinkText] = useState("");
  const [isLinkHttps, setIsLinkHttps] = useState(true);
  const { email } = useUserProfile();
  const [reportEmail, setReportEmail] = useState(email || "");
  const [reportTemplate, setReportTemplate] = useState(email || "");
  const [inputTopics, setInputTopics] = useState("");
  const [showContentList, setShowContentList] = useState(true);
  const [shouldCreateNewCollection, setShouldCreateNewCollection] = useState(false);
  const [isNewMenuAction, setIsNewMenuAction] = useState<"resource" | "library" | "command" | undefined>(undefined);
  const [itemIdentifier, setItemIdentifier] = useState("");
  const [calendarEvent, setCalendarEvent] = useState<CalendarEvent | undefined>(undefined);
  const [integrations, setIntegrations] = useState<string[]>([]);
  const [isIntegrationCategory, setIsIntegrationCategory] = useState("");
  const { pathname } = useLocation();
  const isProject = pathname.includes("project");
  const route = pathname.split("/").slice(isProject ? 2 : 1, isProject ? 3 : 2)[0];
  const configMap = useConfigMap();
  const collectionType = useGetViewConfig("collectionType", route, configMap);
  const { projectForm } = useConfigForm(collectionType || "research", configMap);
  const canOpenPanel =
    !isAddToCollectionModalOpen && (projectId || projectForm?.projectFormStepOrder || route === "library" || route === "dashboard");
  const { setConversationId, setProjectDetailViewTab } = useContext(ConversationContext);
  const [hasParentPanel, setHasParentPanel] = useState(false);

  const resetFormState = useCallback(() => {
    setCanSend(false);
    resetUppyState();
    setTags([]);
    setNotesText("");
    setAliasText("");
    setLinkText("");
    setIsLinkHttps(true);
    setReportEmail("");
    setInputTopics("");
    setItemIdentifier("");
    setCalendarEvent(undefined);
    setIntegrations([]);
    setReportTemplate("");
    setIsIntegrationCategory("");
    setSelectedCollection(undefined);
    setIsNewMenuAction(undefined);
  }, [resetUppyState]);

  const processFiles = useCallback(
    (files: File[]) => {
      const action = actionView ? actionView : "upload";
      toast.closeAll();

      if (canOpenPanel) {
        setConversationId("");
        for (const file of files) {
          try {
            setIsNewMenuAction(undefined);
            if (projectId) {
              setProjectDetailViewTab(1);
              onProjectAddSourceOpen();
              projectId && setShouldCreateNewCollection(false);
            } else {
              onAddNewProjectPanelOpen();
            }
            addFile({ name: file.name, type: file.type, data: file });
            setActionView(action);
          } catch (err) {
            toast({
              title: `Error adding ${file.name} to Charli`,
              description: err.toString(),
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }
      }
    },

    [
      actionView,
      toast,
      canOpenPanel,
      setConversationId,
      projectId,
      addFile,
      setProjectDetailViewTab,
      onProjectAddSourceOpen,
      onAddNewProjectPanelOpen,
    ]
  );

  const dropRef = createRef<DropzoneRef>();

  return (
    <Dropzone
      onDrop={processFiles}
      ref={dropRef}
      disabled={isAddToCollectionModalOpen || isAddNewProjectPanelOpen || isProjectAddSourceOpen}>
      {({ getRootProps, isDragActive }) => (
        <Box {...boxProps} {...getRootProps()} _focus={{ outline: 0 }}>
          <Box position="absolute" display={isDragActive && !isAddToCollectionModalOpen ? "block" : "none"} pointerEvents="none">
            <Center>
              <Box
                backgroundColor={dragAndDropColor}
                zIndex="100"
                width="calc(100vw - 2rem)"
                height="calc(100vh - 2rem)"
                border="dashed #009AD8"
                borderRadius={"lg"}
                margin="1rem">
                <Center height="100%" width="100%">
                  <Stack padding="2rem" borderRadius={"md"} spacing="1rem" alignContent={"center"} backgroundColor={dragAndDropHoverColor}>
                    <Center>
                      {canOpenPanel ? (
                        <Icon as={BsPlusCircleDotted} boxSize="2rem" color={dragAndDropIconColor} />
                      ) : (
                        <Icon as={BsDashCircleDotted} boxSize="2rem" color={dragAndDropIconColor} />
                      )}
                    </Center>
                    <Text fontWeight="semibold" fontSize={"md"}>{`${
                      canOpenPanel
                        ? `Drop file here ${projectId ? " to add it to this project." : "to create a new project."}`
                        : "File upload not used by this project"
                    }`}</Text>
                  </Stack>
                </Center>
              </Box>
            </Center>
          </Box>

          <ResearchContextProvider>
            <AddToCharliProviderContext.Provider
              value={{
                isAdminPanelOpen,
                onAdminPanelClose,
                onAdminPanelOpen,
                isNewMenuAction,
                setIsNewMenuAction,
                canSend,
                setCanSend,
                isSettingsOpen,
                onSettingsClose,
                onSettingsOpen,
                isAddToCollectionModalOpen,
                onAddToCollectionModalClose,
                onAddToCollectionModalOpen,
                isProjectAddSourceOpen,
                onProjectAddSourceClose,
                onProjectAddSourceOpen,
                isShareModalOpen,
                onShareModalClose,
                onShareModalOpen,
                isVerifiedAIPanelOpen,
                onVerifiedAIPanelClose,
                onVerifiedAIPanelOpen,
                isPortfolioSettingsOpen,
                onPortfolioSettingsClose,
                onPortfolioSettingsOpen,
                isAddNewProjectPanelOpen,
                onAddNewProjectPanelClose,
                onAddNewProjectPanelOpen,
                isReportPanelOpen,
                onReportPanelClose,
                onReportPanelOpen,
                isProjectReportPanelOpen,
                onProjectReportPanelClose,
                onProjectReportPanelOpen,
                isProjectReportSelectorOpen,
                onProjectReportSelectorClose,
                onProjectReportSelectorOpen,
                isTagsPanelOpen,
                onTagsPanelClose,
                onTagsPanelOpen,
                isSummarizePanelOpen,
                onSummarizePanelClose,
                onSummarizePanelOpen,
                isIntegrationsPanelOpen,
                onIntegrationsPanelClose,
                onIntegrationsPanelOpen,
                isContentViewOpen,
                onContentViewOpen,
                onContentViewClose,
                isContentJsonViewOpen,
                onContentJsonViewOpen,
                onContentJsonViewClose,
                actionView,
                setActionView,
                uppy,
                uppyStatus,
                uppyFiles,
                resetFormState,
                notesText,
                setNotesText,
                tags,
                setTags,
                selectedCollection,
                setSelectedCollection,
                aliasText,
                setAliasText,
                linkText,
                setLinkText,
                isLinkHttps,
                setIsLinkHttps,
                reportEmail,
                setReportEmail,
                reportTemplate,
                setReportTemplate,
                inputTopics,
                setInputTopics,
                showContentList,
                setShowContentList,
                shouldCreateNewCollection,
                setShouldCreateNewCollection,
                itemIdentifier,
                setItemIdentifier,
                calendarEvent,
                setCalendarEvent,
                notesInputState,
                setNotesInputState,
                noteText,
                setNoteText,
                isHighlightsPanelOpen,
                onHighlightsPanelClose,
                onHighlightsPanelOpen,
                integrations,
                setIntegrations,
                isIntegrationCategory,
                setIsIntegrationCategory,
                showHighlightsForm,
                setShowHighlightsForm,
                onNotesPanelClose,
                onNotesPanelOpen,
                isNotesPanelOpen,
                onKeywordsNotesPanelClose,
                onKeywordsNotesPanelOpen,
                isKeywordsNotesPanelOpen,
                hasParentPanel,
                setHasParentPanel,
              }}>
              {children}
              <ResearchHistoryPanel />
              <SettingsPanel />
              <PortfolioSettingsPanel />
              {route !== "project-config" && <AddNewProjectPanel isOpen={isAddNewProjectPanelOpen} onClose={onAddNewProjectPanelClose} />}
              {!isContentViewOpen && <AddToCollectionPanel isOpen={isAddToCollectionModalOpen} onClose={onAddToCollectionModalClose} />}
            </AddToCharliProviderContext.Provider>
          </ResearchContextProvider>
        </Box>
      )}
    </Dropzone>
  );
};
