import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import type { RootState } from "state/rootReducer";

/**
 * Where possible, navigation state should be tracked via routes.
 * For cases where that isn't possible (eg. the home screen sidebar navigation), this state container exists to track the user's navigation state.
 */

export type SidebarType = "desktop" | "mobile" | "hidden";
const rehydrate = createAction<RootState>(REHYDRATE);

interface NavigationState {
  selectedView: string;
  defaultView: string;
  setSidebarType: SidebarType;
  isLoading: boolean;
}

const initialState: NavigationState = {
  defaultView: "home",
  selectedView: "home",
  setSidebarType: "desktop",
  isLoading: false,
};

export const { actions, reducer } = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
    navigate(state, action: PayloadAction<string>) {
      state.selectedView = action.payload;
    },
    setDefault(state, action: PayloadAction<string>) {
      state.defaultView = action.payload;
    },
    setSelectedView(state, action: PayloadAction<string>) {
      state.selectedView = action.payload;
    },
    reset(state) {
      state.selectedView = "home";
    },
    setSidebarType(state, action: PayloadAction<SidebarType>) {
      state.setSidebarType = action.payload;
    },
    resetSideBar(state) {
      state.setSidebarType = "desktop";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (state, action) => {
      if (action.payload?.navigation) {
        state.isLoading = false;
        return {
          ...initialState,
          ...action.payload.navigation,
        };
      }
    });
  },
});

export default reducer;
