import { Record, String, Array, Number } from "runtypes";
import { Ticker } from "./Ticker";
import type { Static } from "runtypes";

export const PaginatedTickersResponse = Record({
  data: Array(Ticker),
  totalCount: Number,
  nextToken: String.nullable(),
});

export type PaginatedTickersResponse = Static<typeof PaginatedTickersResponse>;
