import type { CollectionButton, ShareDetailsBody } from "types/charliui";
import type { Message } from "types/conversation";

type Sender = "charli" | "user" | "any";

export const getEnvironment = () => {
  if (window.location.href.includes("localhost")) return { color: "#7d7d7d", label: "LOCAL" };
  switch (window.env.environment) {
    case "development":
      return { color: "#7d7d7d", label: "DEV" };
    case "staging":
      return { color: "#82bf95", label: "STAGE" };
    case "production":
      return { color: "#dd5a5a", label: "PROD" };
    default:
      return { color: "", label: "" };
  }
};

export const toTitleCase = (string: string) => {
  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const filterBySender = (message: Message, sender: Sender) => {
  switch (sender) {
    case "charli":
      return message.senderId === "charli";
    case "user":
      return message.senderId !== "charli";
    default:
      return true;
  }
};

/**
 * Returns the first message for a given conversation, optionally filtered by sender ID.
 * @param {Message[]} messages - Messages to query.
 * @param {Sender} sender - Sender to filter by. Defaults to "any".
 */
export function getFirstMessage(messages: Message[], sender: Sender = "any") {
  return messages.find((message) => filterBySender(message, sender) && !message.viewId);
}

export function getMessageWithProjectDetails(messages: Message[]) {
  for (const message of messages) {
    if (Array.isArray(message.data) && message.data.length > 0) {
      const projectMessageData = message.data!.find((datum) => datum.type === "collection_button");
      if (projectMessageData && projectMessageData.body) {
        return projectMessageData as CollectionButton;
      }
    }
  }
}

/**
 * Returns the last message for a given conversation, optionally filtered by sender ID.
 * @param {Message[]} messages - Messages to query.
 * @param {string} sender - Sender to filter by. Defaults to "any".
 */
export function getLastMessage(messages: Message[], sender: "charli" | "user" | "any" = "any") {
  const filteredMessages = messages.filter((message) => filterBySender(message, sender) && !message.viewId);

  if (!filteredMessages.length) {
    return null;
  } else {
    return filteredMessages[filteredMessages.length - 1];
  }
}

export function getShareDetailsBodyFromMessages(messages: Message[]) {
  for (const message of messages) {
    if (Array.isArray(message.data) && message.data.length > 0) {
      const shareDetails = message.data!.find((datum) => datum.type === "share_details");
      if (shareDetails && shareDetails.body) {
        return shareDetails.body as ShareDetailsBody;
      }
    }
  }
}
