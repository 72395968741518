import type { Dispatch } from "redux";
import chunk from "lodash/chunk";
import { downloadCollectionMetadata } from "./operations";

const CHUNK_SIZE = 50;

export const requestToDownloadCollectionMetadata = async (
  { metadataIds, collectionId }: { metadataIds: string[]; collectionId: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
): Promise<void> => {
  const chunksOfMetadataIds = chunk(metadataIds, CHUNK_SIZE);
  for (const chunk of chunksOfMetadataIds) {
    dispatch(downloadCollectionMetadata({ metadataIds: chunk, collectionId }));
  }
};
