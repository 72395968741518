import { useColorModeValue, Text, Stack, Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";

interface Props {
  title: string;
  subtitle?: string;
}

export const SimpleMetric: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ title, subtitle, children }) => {
  const titleColor = useColorModeValue("charli.primaryBlue", "gray.500");
  const subtitleColor = useColorModeValue("gray.400", "gray.200");

  return (
    <Stack width="100%" height="100%" justifyContent={"space-between"}>
      <Stack>
        <Text isTruncated fontSize="sm" fontWeight="semibold" color={titleColor} textAlign="center">
          {title}
        </Text>
        <Text isTruncated mt="0!important" fontSize="10px" fontWeight="light" color={subtitleColor} textAlign="left">
          {subtitle}
        </Text>
      </Stack>
      <Box>{children}</Box>
    </Stack>
  );
};
