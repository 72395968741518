import { createAction, createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import type { RootState } from "state/rootReducer";
import type { CalendarEvent, FullVideoConferenceEvent } from "types/meetings";
import uniqBy from "lodash/uniqBy";

const rehydrate = createAction<RootState>(REHYDRATE);

interface VideoConferenceEventResolution {
  error: boolean;
  videoConferenceEvent?: FullVideoConferenceEvent;
}

interface MeetingsSyncState {
  lastSynced: number;
  syncedMeetings: CalendarEvent[];
  currentVideoConferenceEvent?: VideoConferenceEventResolution;
  checkedVideoConferenceEvents: Record<string, VideoConferenceEventResolution>;
}

const initialState: MeetingsSyncState = {
  lastSynced: 0,
  syncedMeetings: [],
  currentVideoConferenceEvent: undefined,
  checkedVideoConferenceEvents: {},
};

export const { actions, reducer } = createSlice({
  name: "meetings",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
    syncMeetings(state, action) {
      const currentDateTime = Date.now();
      state.lastSynced = currentDateTime;
      state.syncedMeetings = uniqBy([...action.payload, ...state.syncedMeetings], (event) => event.id);
    },
    setCurrentVideoConferenceEvent(state, action) {
      state.currentVideoConferenceEvent = action.payload;
    },
    setCheckedVideoConferenceEvents(state, action) {
      const payload = action.payload;
      state.checkedVideoConferenceEvents[payload.conversationId] = payload.resolution;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (state, action) => {
      if (action.payload?.meetings) {
        const previousMeetingsState = action.payload.meetings;
        const syncedMeetings: CalendarEvent[] = [];
        if (previousMeetingsState.syncedMeetings["calendar_events"]) {
          syncedMeetings.push(...previousMeetingsState.syncedMeetings["calendar_events"]);
        } else {
          syncedMeetings.push(...previousMeetingsState.syncedMeetings);
        }

        return {
          ...initialState,
          syncedMeetings,
        };
      }
    });
  },
});

export default reducer;
