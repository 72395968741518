import { useDisclosure } from "@chakra-ui/react";
import type { ReactNode } from "react";
import React, { createContext, useState, useEffect, useContext } from "react";
import type { WorkflowSummary } from "types/workflows/summary";
import type { ChildWorkflowStep, Workflow } from "types/workflows/workflow";
import type { ChildWorkflow } from "types/workflows/workflow";

const defaultThrowError = (): void => {
  throw new Error("Component must be nested inside <WorkflowContextProvider />");
};

export const useWorkflowContext = () => {
  return useContext(WorkflowProviderContext);
};

export const WorkflowProviderContext = createContext({
  isWorkflow: undefined as Workflow | undefined,
  setWorkflow: defaultThrowError as (workflow?: Workflow) => void,
  childWorkflows: [] as ChildWorkflow[],
  setChildWorkflows: defaultThrowError as (childWorkflows: ChildWorkflow[]) => void,
  isChildWorkflow: "" as string,
  setChildWorkflow: defaultThrowError as (childWorkflowId: string) => void,
  isMessageId: "",
  setMessageId: defaultThrowError as (messageId: string) => void,
  isTaskId: "",
  setTaskId: defaultThrowError as (messageId: string) => void,
  isWorkflowPanelOpen: false,
  onWorkflowPanelOpen: defaultThrowError as () => void,
  onWorkflowPanelClose: defaultThrowError as () => void,
  isWorkflowPayloadPanelOpen: false,
  onWorkflowPayloadPanelOpen: defaultThrowError as () => void,
  onWorkflowPayloadPanelClose: defaultThrowError as () => void,
  isLoadingChildWorkflows: false,
  setIsLoadingChildWorkflows: defaultThrowError as (isLoading: boolean) => void,
  workflowSummary: [] as WorkflowSummary[],
  setWorkflowSummary: defaultThrowError as React.Dispatch<React.SetStateAction<WorkflowSummary[]>>,
  isStepsPanelOpen: false,
  onStepsPanelOpen: defaultThrowError as () => void,
  onStepsPanelClose: defaultThrowError as () => void,
  steps: [] as ChildWorkflowStep[],
  setSteps: defaultThrowError as React.Dispatch<React.SetStateAction<ChildWorkflowStep[]>>,
  payloadType: null as "error" | "json" | null,
  setPayloadType: defaultThrowError as (payloadType: "error" | "json" | null) => void,
});

export const WorkflowContextProvider = ({ children }: { children: ReactNode }) => {
  const [isWorkflow, setWorkflow] = useState<Workflow | undefined>();
  const [workflowSummary, setWorkflowSummary] = useState<WorkflowSummary[]>([]);
  const [childWorkflows, setChildWorkflows] = useState([] as ChildWorkflow[]);
  const [steps, setSteps] = useState<ChildWorkflowStep[]>([]);
  const [isLoadingChildWorkflows, setIsLoadingChildWorkflows] = useState(false);
  const [isChildWorkflow, setChildWorkflow] = useState("");
  const [isMessageId, setMessageId] = useState("");
  const [isTaskId, setTaskId] = useState("");
  const [payloadType, setPayloadType] = useState<"error" | "json" | null>(null);
  const { isOpen: isWorkflowPanelOpen, onOpen: onWorkflowPanelOpen, onClose: onWorkflowPanelClose } = useDisclosure();
  const { isOpen: isWorkflowPayloadPanelOpen, onOpen: onWorkflowPayloadPanelOpen, onClose: onWorkflowPayloadPanelClose } = useDisclosure();
  const { isOpen: isStepsPanelOpen, onOpen: onStepsPanelOpen, onClose: onStepsPanelClose } = useDisclosure();

  useEffect(() => {
    if (childWorkflows.length > 0) {
      setWorkflow((prev) => (prev ? { ...prev, childWorkflows } : prev));
    }
  }, [childWorkflows]);

  return (
    <WorkflowProviderContext.Provider
      value={{
        isWorkflow,
        setWorkflow,
        isChildWorkflow,
        setChildWorkflow,
        isMessageId,
        setMessageId,
        isTaskId,
        setTaskId,
        isWorkflowPanelOpen,
        onWorkflowPanelOpen,
        onWorkflowPanelClose,
        isWorkflowPayloadPanelOpen,
        onWorkflowPayloadPanelOpen,
        onWorkflowPayloadPanelClose,
        childWorkflows,
        setChildWorkflows,
        isLoadingChildWorkflows,
        setIsLoadingChildWorkflows,
        // new summary
        workflowSummary,
        setWorkflowSummary,
        isStepsPanelOpen,
        onStepsPanelClose,
        onStepsPanelOpen,
        steps,
        setSteps,
        payloadType,
        setPayloadType,
      }}>
      {children}
    </WorkflowProviderContext.Provider>
  );
};
