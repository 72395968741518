import React from "react";
import { useColorModeValue } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import type { Props as CreatableProps } from "react-select/creatable";
import type { Theme } from "react-select";

type Option = { label: string; value: string };

/**
 * Returns the `themes` and `styles` props for use with CreatableSelect, themed to match our Chakra UI theme.
 */
export function useComboBoxTheme(tagType?: "manual" | "auto" | "category" | "topic") {
  const controlBgColor = useColorModeValue("white", "#2C3748");
  const controlBorderColor = useColorModeValue("rgb(226, 232, 240)", "rgba(255, 255, 255, 0.16)");
  const hoveredControlBorderColor = useColorModeValue("rgb(203, 213, 224)", "rgba(255, 255, 255, 0.25)");
  const focusedControlBorderColor = useColorModeValue("#3182ce", "#63b3ed");
  const menuBgColor = useColorModeValue("#B2D4FF", "#19202C");
  const activeOptionBgColor = useColorModeValue("#718096", "#A0AEC0");
  const singleValueTextColor = useColorModeValue("#000", "#fff");
  const placeholderTextColor = useColorModeValue("rgb(160, 174, 192)", "rgba(255, 255, 255, 0.24)");
  const manualTagBgColor = useColorModeValue("#E2E8F0", "gray.700");
  const autoTagBgColor = useColorModeValue("#d4eef3", "gray.700");
  const categoryTagBgColor = useColorModeValue("#f0e2ff", "#A0AEC0");
  const topicBgColorManual = useColorModeValue("#cff3c8", "gray.600");
  const topicTextColor = useColorModeValue("gray.700", "gray.400");

  // use tagType to determine which color to use for the tag
  const tagBgColor = React.useMemo(() => {
    switch (tagType) {
      case "manual":
        return manualTagBgColor;
      case "auto":
        return autoTagBgColor;
      case "category":
        return categoryTagBgColor;
      case "topic":
        return topicBgColorManual;
      default:
        return manualTagBgColor;
    }
  }, [tagType, manualTagBgColor, autoTagBgColor, categoryTagBgColor, topicBgColorManual]);

  const stylingProps: Pick<React.ComponentProps<typeof CreatableSelect>, "theme" | "styles"> = {
    theme: (theme: Theme) => {
      return {
        ...theme,
        colors: {
          ...theme.colors,
          neutral0: controlBgColor,
          neutral30: hoveredControlBorderColor,
          primary25: menuBgColor,
          primary50: activeOptionBgColor,
          neutral20: controlBorderColor,
          primary: focusedControlBorderColor,
          danger: "black",
          dangerLight: "rgba(255, 255, 255, 0.5)",
        },
      };
    },
    styles: {
      menuPortal: (styles) => ({ ...styles, zIndex: 2000 }),
      container: (styles) => {
        return {
          ...styles,
          width: "100%",
        };
      },
      control: (styles) => {
        return {
          ...styles,
          backgroundColor: "transparent!important",
          borderColor: controlBorderColor,
          boxShadow: "transparent!important",
          borderRadius: "5px",
          border: "1 solid",
          zIndex: 1,
          minHeight: "30px",
        };
      },
      option: (styles) => {
        return {
          ...styles,
          fontSize: "0.875rem",
        };
      },
      valueContainer: (styles) => {
        return {
          ...styles,
          fontSize: "0.875rem",
          minWidth: "10rem",
          padding: "2px",
        };
      },
      indicatorsContainer: (styles) => {
        return {
          ...styles,
          padding: "0",
        };
      },
      dropdownIndicator: (styles) => {
        return {
          ...styles,
          padding: "0",
        };
      },
      loadingIndicator: (styles) => {
        return {
          ...styles,
          padding: "0",
        };
      },
      clearIndicator: (styles) => {
        return {
          ...styles,
          padding: "0",
        };
      },
      indicatorSeparator: (styles) => {
        return {
          ...styles,
          display: "none",
        };
      },
      placeholder: (styles) => {
        return {
          ...styles,
          color: placeholderTextColor,
          paddingLeft: "5px",
        };
      },
      multiValue: (styles) => {
        return {
          ...styles,
          backgroundColor: tagBgColor,
          color: topicTextColor,
          borderRadius: "30px",
          padding: "2px 5px",
        };
      },
      multiValueLabel: (styles) => ({
        ...styles,
        color: topicTextColor,
        fontWeight: 500,
        fontSize: "0.875rem",
      }),
      singleValue: (styles) => ({
        ...styles,
        color: singleValueTextColor,
      }),
      input: (styles) => ({
        ...styles,
        color: singleValueTextColor,
        minWidth: "5rem",
        paddingLeft: "5px",
      }),
    },
  };

  // CreatableSelect has different prop type signatures depending on the value of the isMulti prop, including `styles`.
  // This makes sense, as for example, multiValue styles don't apply to a ComboBox that only allows selecting a single value.
  // However, at runtime, supplying unused styles has no impact, therefore I'd prefer to return the same styles and themes objects for both ComboBox types.
  // To this end, I'm asserting the value of these props as `unknown` to opt-out of type checking.
  return stylingProps;
}

export const MultiComboBox = (props: Omit<CreatableProps<Option, true>, "isMulti">) => {
  const stylingProps = useComboBoxTheme(props.tagType);

  return <CreatableSelect menuPortalTarget={document.body} {...stylingProps} {...props} isMulti />;
};

export const ComboBox = (props: Omit<CreatableProps<Option, false>, "isMulti">) => {
  const stylingProps = useComboBoxTheme();

  return <CreatableSelect menuPortalTarget={document.body} {...stylingProps} {...props} isMulti={false} />;
};
