import React, { useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Stack, Text, Input, Button, useColorModeValue, Select, Switch, SimpleGrid, FormLabel } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import capitalize from "lodash/capitalize";
import type { FormValues } from "../modals/EntitlementConfigUpsertModal";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "api/apiResponseError";
import { RenderIfHasEntitlements } from "screens/common/components";

type ShortPlan = { code?: string; plan?: string; enabled?: boolean; limit?: number };

interface IProps {
  onAdd: (plan: ShortPlan) => Promise<void | { error?: unknown } | PayloadAction>;
  onUpdate: (plan: ShortPlan) => Promise<void | { error?: unknown } | PayloadAction>;
  disabled?: boolean;
  supportedPlans: string[];
}

export const PlansFieldArrayInner = (props: IProps) => {
  const { control, register } = useFormContext<FormValues>();
  const { supportedPlans } = props;
  const bgColor = useColorModeValue("white!important", "gray.700!important");
  const [selectedPlan, setSelectedPlan] = React.useState<string | "-1">("-1");
  const commonButtonProps = useButtonProps("sm", "secondary");

  const { fields, append } = useFieldArray({
    control,
    name: "plans",
  });

  const fieldPlans = useWatch({ name: "plans", control });

  const plans: ShortPlan[] = useMemo(
    () =>
      fieldPlans.map((fieldPlan) => ({
        code: fieldPlan.code,
        plan: fieldPlan.code,
        enabled: fieldPlan.enabled,
        limit: fieldPlan.limit,
      })),
    [fieldPlans]
  );

  const currentPlans = Object.values(plans).map((plan) => plan.code);

  const handleAppend = async () => {
    const plan = selectedPlan;
    setSelectedPlan("-1");
    if (!ApiResponseError.guard(await props.onAdd({ plan, enabled: true, limit: 0 }))) {
      append({ code: plan, enabled: true, limit: 0 });
    }
  };

  const handleUpdate = async ({ code, ...fields }: ShortPlan, field: string, value: string | number | boolean) => {
    if (!ApiResponseError.guard(await props.onUpdate({ ...fields, plan: code, [field]: value }))) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Stack spacing="1rem" backgroundColor={bgColor} pb="2rem">
      <Stack direction="row" justifyContent="space-between">
        <Text fontSize="sm">Plans configuration</Text>
        <Stack direction="row" alignItems={"center"}>
          <Select
            mr="0.5rem"
            size="sm"
            boxShadow="none"
            value={selectedPlan}
            onChange={(evt) => {
              setSelectedPlan(evt.target.value);
            }}>
            <option value={"-1"}>--Select plan--</option>
            {supportedPlans.map((plan) => (
              <option key={plan} value={plan} disabled={currentPlans.includes(plan)}>
                {capitalize(plan)}
              </option>
            ))}
          </Select>
          <Button
            {...commonButtonProps}
            width="10rem"
            onClick={handleAppend}
            disabled={fields.length >= supportedPlans.length || props.disabled || selectedPlan === "-1"}>
            Add plan
          </Button>
        </Stack>
      </Stack>

      <SimpleGrid columns={2} spacingX="3rem" spacingY="1.5rem">
        {fields.map((field, index) => (
          <Stack key={index} direction="row" width="100%" justifyContent={"space-between"}>
            <Stack width={"100%"}>
              <Text fontSize="xs">Plan</Text>
              <Input size="xs" mt="0!important" width="100%" disabled value={capitalize(field.code)} _disabled={{ opacity: 0.7 }} />
            </Stack>

            {(() => {
              const { onChange, ...registerSwitch } = register(`plans.${index}.enabled`);
              return (
                <Stack textAlign="center" width="5rem" spacing=".3rem">
                  <Text fontSize="xs">Enabled</Text>
                  <Switch
                    size="sm"
                    {...registerSwitch}
                    onChange={async (e) => {
                      const isChecked = e.target.checked;
                      if (await handleUpdate(plans[index], "enabled", isChecked)) {
                        onChange({ target: { value: isChecked } });
                      }
                    }}
                    disabled={props.disabled}
                    boxShadow="none"
                  />
                </Stack>
              );
            })()}
          </Stack>
        ))}
      </SimpleGrid>
    </Stack>
  );
};

export const PlansFieldArray = (props: IProps) => {
  return (
    <RenderIfHasEntitlements
      entitlement="manage_billing_plans_read"
      headerMessageComponent={
        <>
          <FormLabel fontSize="sm">Plans configuration</FormLabel>
        </>
      }>
      <PlansFieldArrayInner {...props} />
    </RenderIfHasEntitlements>
  );
};
