import React, { useMemo, memo, useCallback } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { CommonModal } from "./CommonModal";
import { Stack, ModalCloseButton, Center, useBreakpointValue, Text, Button, Image, Box } from "@chakra-ui/react";
import { useButtonProps, useCompanyStockFromCollectionContentsOrConversation, useProjectParams } from "hooks";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import { useConversationDialogSubmitFunctions } from "screens/thread/useConversationDialogSubmitFunctions";

const AIUpdatedModalComponent = () => {
  const { projectId } = useProjectParams();
  const { isAIUpdatedModalOpen, onAIUpdatedModalClose, selectedQuestionAndFocus } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const buttonProps = useButtonProps("sm", "primary");
  const secondaryProps = useButtonProps("sm", "cta");
  const { onSubmitDueDiligenceProject, onSubmitGenerateAnswer } = useConversationDialogSubmitFunctions({});
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(projectId);
  const memoizedValues = useMemo(() => {
    const { ticker = selectedQuestionAndFocus?.projectTicker, exchange = selectedQuestionAndFocus?.projectExchange } =
      companyStockData || {};
    const { question, focus } = selectedQuestionAndFocus || {};
    const selectedProjectId = projectId || selectedQuestionAndFocus?.projectId;

    return { ticker, exchange, question, focus, selectedProjectId };
  }, [companyStockData, projectId, selectedQuestionAndFocus]);
  const { ticker, exchange, question, focus, selectedProjectId } = memoizedValues;

  const onStartDueDiligence = useCallback(() => {
    onSubmitDueDiligenceProject(
      ticker || "",
      exchange,
      undefined,
      selectedProjectId,
      undefined,
      undefined,
      {
        question: question,
        focus: focus,
      },
      true
    );
    onAIUpdatedModalClose();
  }, [exchange, focus, onAIUpdatedModalClose, onSubmitDueDiligenceProject, selectedProjectId, question, ticker]);

  const handleGenerateAlternateAnswer = useCallback(() => {
    if (!selectedQuestionAndFocus?.question) {
      return;
    }
    onSubmitGenerateAnswer(selectedQuestionAndFocus.question, selectedQuestionAndFocus.focus, selectedQuestionAndFocus.answerLength);
    onAIUpdatedModalClose();
  }, [onAIUpdatedModalClose, onSubmitGenerateAnswer, selectedQuestionAndFocus]);

  const modalBody = useMemo(
    () => (
      <Stack justifyContent="space-between" spacing="0" height={isMobile ? "27rem" : "31rem"}>
        <ModalCloseButton color="gray.300" onClick={onAIUpdatedModalClose} />
        <Stack spacing="0" height="100%" justifyContent="space-between">
          <Stack height="100%" spacing="1rem" bgColor="primary.default" p={isMobile ? "0.5rem" : "2rem"} color="white">
            <Center>
              <Text fontWeight="semibold" fontSize="2xl">
                AI Models Updated
              </Text>
            </Center>
            <Text fontSize="lg" fontWeight="semibold" width="100%" textAlign="center" px={isMobile ? "0" : "4rem"}>
              Charli frequently updates its AI Models to improve analysis and expand its knowledge
            </Text>
            <Text fontSize="md" fontWeight="normal" width="100%" textAlign="left" px={isMobile ? "0" : "4rem"}>
              You can continue with the question and Charli will re-analyze all the existing content to preserve the records. This will
              cause a longer delay before the question is answered.
            </Text>
            <Text fontSize="md" fontWeight="normal" width="100%" textAlign="left" px={isMobile ? "0" : "4rem"}>
              It is recommended that you rerun the project so that Charli can fetch and analyze the latest data and update all the answers
              in the project with near real time information.
            </Text>
          </Stack>
          <Stack direction={"row"} justifyContent="center" spacing="0" height="14rem" width="100%" px="2rem">
            {!isMobile && (
              <Box position={"absolute"} left="2rem" bottom={"2rem"}>
                <Image src={verifiedShield} width={["2rem", "2.5rem", "3rem"]} />
              </Box>
            )}
            <Stack direction="row" justifyContent={"center"} width="100%" spacing="2rem">
              <Center>
                <Button
                  {...buttonProps}
                  isDisabled={!selectedQuestionAndFocus.question || selectedQuestionAndFocus.question.length === 0}
                  onClick={handleGenerateAlternateAnswer}>
                  Continue with Question
                </Button>
              </Center>
              <Center>
                <Button isDisabled={!selectedProjectId} {...secondaryProps} onClick={onStartDueDiligence}>
                  Rerun this Project
                </Button>
              </Center>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    ),
    [
      buttonProps,
      handleGenerateAlternateAnswer,
      isMobile,
      onAIUpdatedModalClose,
      onStartDueDiligence,
      secondaryProps,
      selectedQuestionAndFocus.question,
      selectedProjectId,
    ]
  );

  return (
    <CommonModal
      allowDismiss={false}
      borderColor="#4799d4"
      isOpen={isAIUpdatedModalOpen}
      onClose={onAIUpdatedModalClose}
      minWidth={["100%", "40rem", "40rem"]}
      modalBody={modalBody}
    />
  );
};

export const AIUpdatedModal = memo(AIUpdatedModalComponent);
