import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWorkflowsMilestones } from "api/workflows";
import { WorkflowFetchError } from "api/workflows/models/WorkflowFetchError";

export const downloadWorkflowsMilestones = createAsyncThunk(
  "milestones/download-workflows-milestones",
  async ({ workflowIds }: { workflowIds: string[] }, _) => {
    const response = await getWorkflowsMilestones(workflowIds);

    const filteredMilestones = response.flatMap((newWorkflow) => {
      return WorkflowFetchError.guard(newWorkflow) ? [] : [newWorkflow];
    });

    return filteredMilestones;
  }
);
