import type { FunctionComponent } from "react";
import React, { useMemo } from "react";
import type { Message } from "types/conversation";
import { MessageContainer } from "./components/MessageContainer";
import { ChatBubble } from "./components";
import type { Plan } from "types/charliui";
import { parseISO } from "date-fns";
import { List, ListItem, ListIcon, Text, Tooltip } from "@chakra-ui/react";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import { IoEllipsisHorizontalCircleSharp } from "react-icons/io5";

interface Props {
  message: Message;
  datum: Plan;
}

type PlanStep = {
  name: string;
  status: string;
};

const getStepStatusIcon = (stepStatus: string | undefined) => {
  switch (stepStatus) {
    case "completed":
      return <ListIcon as={MdCheckCircle} color="green.400" />;
    case "pending":
      return <ListIcon as={IoEllipsisHorizontalCircleSharp} color="orange.400" />;
    case "activity_exception":
      return <ListIcon as={MdCancel} color="red.400" />;
    default:
      return <ListIcon as={RiCheckboxBlankCircleFill} color="gray.400" />;
  }
};

const getStepStatusToolTip = (stepStatus: string | undefined) => {
  switch (stepStatus) {
    case "completed":
      return "All Done";
    case "pending":
      return "Still in progress";
    case "activity_exception":
      return "This step raised an exception";
    default:
      return "Not sure what happened to this step";
  }
};

export const PlanStepsCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ message, datum }) => {
  const planSteps = useMemo(
    () =>
      datum.body.plan.reduce((accum, currentStep, index, steps) => {
        const isLastMember = index === steps.length - 1;
        accum.push({ name: currentStep.if_intent, status: currentStep.status });
        if (isLastMember) {
          accum.push({ name: currentStep.then_activity, status: currentStep.status });
        }
        return accum as PlanStep[];
      }, [] as PlanStep[]),
    [datum.body.plan]
  );

  return (
    <MessageContainer initiator={message.senderId === "charli" ? "charli" : "user"} key={`${message.id}-plan`}>
      <ChatBubble
        key={message.id}
        initiator={message.senderId === "charli" ? "charli" : "user"}
        date={parseISO(message.createdDate) ?? new Date()}>
        <Text fontSize="sm" fontWeight="600" pb=".7rem">
          Steps I used to get this done:
        </Text>
        <List spacing="2">
          {planSteps.map((step, index) => (
            <Tooltip label={getStepStatusToolTip(step.status)} key={index}>
              <ListItem fontSize="sm" fontWeight="600">
                {getStepStatusIcon(step.status)}
                {step.name}
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </ChatBubble>
    </MessageContainer>
  );
};
