import React, { useContext, useEffect } from "react";
import { Box, Text, useBreakpointValue } from "@chakra-ui/react";
import { useConversation } from "hooks";
import { useParams } from "react-router-dom";
import { TabTitle } from "screens/common/components/TabTitle";
import { ConversationContext } from "screens/thread/ConversationContext";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import type { JsonButton } from "types/charliui";
import { JsonView } from "./body/JsonView";

export const JsonScreen = () => {
  // Type assertion is needed here as useParams assumes all parameters are present. contentId may be undefined, we want the type to accurately reflect this.
  const { conversationId, buttonId } = useParams() as { conversationId: string; buttonId: string };
  const conversation = useConversation(conversationId);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const maybeJsonButtonIfButtonId = conversation.messages
    .flatMap((message) => message.data ?? [])
    .find((uiElement): uiElement is JsonButton => uiElement.type === "json_button" && uiElement.body.buttonId === buttonId);

  // this is to support older conversations, in the future buttonId will always refer to buttonId
  const maybeJsonButton =
    maybeJsonButtonIfButtonId ??
    conversation.messages
      .find((message) => message.id === buttonId)
      ?.data?.find((uiElement): uiElement is JsonButton => uiElement.type === "json_button");

  const { setHeaderText, setIsHeaderTextEditable } = useContext(SettingsProviderContext);
  const { setConversationId, onConversationOpen } = useContext(ConversationContext);

  const canvasTitle = maybeJsonButton?.body.label ?? "JSON View";

  useEffect(() => {
    setConversationId(conversationId);
    onConversationOpen();
    setHeaderText("JSON View");
  }, [canvasTitle, conversationId, onConversationOpen, setConversationId, setHeaderText, setIsHeaderTextEditable]);

  return (
    <>
      <TabTitle title={`Charli > ${canvasTitle}`} />
      <Box width="100%" height="100%" className="canvas-view" px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"}>
        <Box width="100%" height="100%" className="canvas-modal">
          {maybeJsonButton ? <JsonView json={maybeJsonButton.body.json} /> : <Text>JSON content not found</Text>}
        </Box>
      </Box>
    </>
  );
};
