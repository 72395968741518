import { TriangleDownIcon } from "@chakra-ui/icons";
import { Button, Menu, MenuButton, MenuList, Box } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import React from "react";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";

export const SearchViewActionMenu = () => {
  const commonButtonProps = useButtonProps("sm", "primary");

  return (
    <Box>
      <Menu>
        <MenuButton
          {...commonButtonProps}
          className="ch-project-options"
          as={Button}
          aria-label="Options"
          rightIcon={<TriangleDownIcon />}
          onClick={(event) => event.stopPropagation()}>
          Actions
        </MenuButton>
        <MenuList minWidth={"unset"} zIndex={10}>
          <OptionsMenuItem menuLabel={"Search Settings"} className="ch-open-search-settings" isDisabled />
        </MenuList>
      </Menu>
    </Box>
  );
};
