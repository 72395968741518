import type { Middleware } from "redux";
import { downloadTags } from "state/conversation/operations";
import { requestToDownloadWorkflowsThatMayHaveChanged } from "state/workflow/utils";
import type { RootState } from "state/rootReducer";
import { v4 as uuid } from "uuid";
import { connect } from "../websocket/operations";
import { actions as websocketActions } from "../websocket/reducer";
import { getRecentlyModifiedMetadataIds } from "api/content";
import { downloadCollections } from "state/collection/operations";
import type { ContentDetails } from "types/content/ContentDetails";
import maxBy from "lodash/maxBy";
import { requestToDownloadContents } from "state/content/utils";
import { downloadCurrentUserFeatureUsage } from "state/usage/operations";
import { actions as conversationActions } from "state/conversation/reducer";
import { getWebsocketClient } from "api/websocket/client";

const MAX_RECOVERY_TIMEOUT_INTERVAL = 15000;
const RECOVERY_TIMEOUT_INTERVAL_INCREMENT = 1000;
const INITIAL_RECOVERY_TIMEOUT_INTERVAL = 0;
const MAX_MESSAGE_AGE_IN_MS = 300000; // 5 minutes in milliseconds

let websocketRecoveryTimeout: number | undefined | null;
let websocketRecoveryTimeoutInterval = INITIAL_RECOVERY_TIMEOUT_INTERVAL;
let isReconnecting = false;

function findLatestTimeLastModified(contents: ContentDetails[]): string | null {
  if (contents.length === 0) return null;

  const oldestContent = maxBy(contents, (content) => new Date(content.timeLastModified));
  return oldestContent?.timeLastModified ?? null;
}

export const websocketRecoveryMiddleware: Middleware = (store) => (next) => (action) => {
  next(action);

  const rootState = store.getState() as RootState;

  const clearTimeout = () => {
    if (websocketRecoveryTimeout) {
      window.clearTimeout(websocketRecoveryTimeout);
      websocketRecoveryTimeout = null;
    }
  };

  const reconnectRoutine = () => {
    isReconnecting = true;
    const isLoggedIn = rootState.session.isLoggedIn;

    if (isLoggedIn) {
      store.dispatch(connect(uuid()) as never);
    }
  };

  if (action.type === websocketActions.disconnect.type || action.type === connect.rejected.type) {
    // Clear any pending recovery timeouts

    // Start a timer to refresh the access token prior to its expiry
    if (document.visibilityState === "hidden") {
      // tab is no visible. Don't try to reconnect until it is
      console.warn("DISCONNECTED. browser tab is inactive. will try again once the tab is active again");
      const reconnectFn = () => {
        if (document.visibilityState === "hidden") return;

        reconnectRoutine();
        // set listener back to null to ensure the handler only runs once.
        document.onvisibilitychange = null;
      };

      // using addEventListener is not ideal because it could register more than one handler.
      // this way at most one event listener is set
      document.onvisibilitychange = reconnectFn;
    } else {
      clearTimeout();
      // else tab is visible. go through with regular recovery routine
      console.log("DISCONNECTED. setting timeout to retry");
      websocketRecoveryTimeout = setTimeout(
        reconnectRoutine,
        Math.min((websocketRecoveryTimeoutInterval += RECOVERY_TIMEOUT_INTERVAL_INCREMENT), MAX_RECOVERY_TIMEOUT_INTERVAL)
      ) as unknown as number;
    }
  }

  if (action.type === websocketActions.connect.type) {
    // Checks if connection is the result of a recovery action
    if (isReconnecting) {
      const { contentData } = rootState.content;
      const latestModifiedAt = findLatestTimeLastModified(Object.values(contentData));

      if (latestModifiedAt) {
        getRecentlyModifiedMetadataIds(latestModifiedAt)
          .then(({ metadataIds, collectionIds }) => {
            if (metadataIds.length > 0) {
              requestToDownloadContents({ metadataIds }, store.dispatch);
            }

            if (collectionIds.length > 0) {
              store.dispatch(downloadCollections({ ids: collectionIds }) as never);
            }
          })
          .catch((err) => console.error("Error fetching recently modified content", err));
      }

      store.dispatch(downloadTags() as never);
      requestToDownloadWorkflowsThatMayHaveChanged(
        rootState.workflow.workflowsById,
        rootState.workflow.isLoadingWorkflowMap,
        store.dispatch
      );
      store.dispatch(downloadCurrentUserFeatureUsage() as never);

      const { messages: normalizedMessages } = (store.getState() as RootState).conversation;

      Object.entries(normalizedMessages)
        .filter(([_, message]) => message.acknowledgmentStatus === "not_acknowledged")
        .forEach(([messageId, message]) => {
          if (new Date().getTime() > message.createdTime + MAX_MESSAGE_AGE_IN_MS) {
            // expired, mark as dead
            store.dispatch(conversationActions.setAcknowledgmentStatus({ messageId, acknowledgmentStatus: "will_not_retry" }));

            console.log(`Marked message with id ${messageId} and content [${message.content}] as dead, it will not be retried`);
          } else {
            const messageForBackend = {
              id: message.id,
              conversationId: message.conversationId,
              content: message.content,
              senderId: message.senderId,
              createdDate: message.createdDate,
              data: message.data,
            };

            getWebsocketClient().send(messageForBackend);

            console.log(`Re-sent message with id ${messageId} and content [${message.content}]`);
          }
        });
    }

    // Clear any pending recovery timeouts
    clearTimeout();
    isReconnecting = false;

    // Reset interval
    websocketRecoveryTimeoutInterval = INITIAL_RECOVERY_TIMEOUT_INTERVAL;
  }
};
