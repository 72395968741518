import React from "react";
import { useController } from "react-hook-form";
import type { Control } from "react-hook-form";
import type { FieldValues } from "react-hook-form";

import type { IFieldJSON } from "../types";
import { JSONEditor } from "screens/common/components";

export const JSONInput = <T extends FieldValues>({
  control,
  entity,
  label,
  helperText,
  height,
  jsonSchema,
  previewPanel,
  onChange,
}: IFieldJSON<T> & { control: Control<T>; onChange: (value: string) => void }) => {
  const { field } = useController({ control, name: entity });
  return (
    <>
      <JSONEditor
        helperText={helperText}
        height={height as string}
        value={field.value}
        jsonSchema={jsonSchema}
        previewPanel={previewPanel}
        onChange={(value) => {
          field.onChange({ target: { value } });
          onChange(value);
        }}
        title={label}
      />
    </>
  );
};
