import { prependProxyBaseUrl, request } from "api/request";
import { TaskLogResponse } from "./models/TaskLogResponse";

const TASKS_LOG_BASE_ENDPOINT = prependProxyBaseUrl("/api/tasks-log");

export async function getTasksLog(options?: {
  token?: string;
  limit?: number;
  filters?: {
    status?: string;
    registeredTaskId?: string;
    excludeRetriedTasks: boolean;
  };
}): Promise<TaskLogResponse> {
  const maybeTaskLogs = await request()
    .url(`${TASKS_LOG_BASE_ENDPOINT}/query`)
    .query({
      ...(options && {
        ...(options.token && { token: options.token }),
        ...(options.limit && { limit: options.limit }),
        ...(options.filters && {
          ...(options.filters.status && { status: options.filters.status }),
          ...(options.filters.registeredTaskId && { registeredTaskId: options.filters.registeredTaskId }),
          ...(options.filters.excludeRetriedTasks && { excludeRetriedTasks: options.filters.excludeRetriedTasks }),
        }),
      }),
    })
    .get()
    .json();

  const validateTaskLogsResponse = TaskLogResponse.validate(maybeTaskLogs);

  if (validateTaskLogsResponse.success) {
    return validateTaskLogsResponse.value;
  } else {
    console.error(validateTaskLogsResponse.message);
    throw new Error(validateTaskLogsResponse.message);
  }
}
