import { IconButton, Image, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { ImLinkedin2 } from "react-icons/im";
import charliLogo from "screens/common/static/logos/charli-logo-full.png";

const VERSION_NUMBER = process.env.REACT_APP_VERSION_NUMBER!;

const SocialButton = ({ icon, label, href }: { icon: JSX.Element; label: string; href: string }) => {
  return (
    <IconButton
      aria-label={label}
      icon={icon}
      backgroundColor="secondary.teal"
      colorScheme="teal"
      color="white"
      rounded="full"
      size="md"
      onClick={() => {
        window.open(href, "_blank");
      }}
    />
  );
};

export const About = () => {
  const textColor = useColorModeValue("gray.500", "gray.400");

  return (
    <Stack pt="2rem" spacing="3rem" justify="space-between" align="center" width="100%" textAlign="center">
      <Stack align="center">
        <Image src={charliLogo} height="2rem" />
        <Stack spacing="0" textColor={textColor}>
          <Text fontSize="xl" fontWeight="bold">
            Version {VERSION_NUMBER}
          </Text>
          {window.env.version && typeof window.env.version === "string" && (
            <Text fontSize="sm">Build {window.env.version.substring(0, 7)}</Text>
          )}
        </Stack>
      </Stack>
      <Stack direction="row">
        <SocialButton label="Facebook" icon={<FaFacebookF />} href="https://www.facebook.com/charliai" />
        <SocialButton label="Twitter" icon={<FaTwitter />} href="https://twitter.com/charliai" />
        <SocialButton label="LinkedIn" icon={<ImLinkedin2 />} href="https://www.linkedin.com/company/charliai/" />
        <SocialButton label="Instagram" icon={<FaInstagram />} href="https://www.instagram.com/justaskcharli/" />
      </Stack>
      <Stack fontSize={"sm"} color={textColor} textDecoration="underline">
        <Link href="https://www.charli.ai/privacy-policy/" isExternal>
          Privacy Policy
        </Link>
        <Link href="https://www.charli.ai/terms-of-service/" isExternal>
          Terms of Service
        </Link>
      </Stack>
    </Stack>
  );
};
