import React from "react";
import { Box, Center, useBreakpointValue } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { TabTitle } from "screens/common/components/TabTitle";
import { JsonView } from "./body/JsonView";
import { useTaskPayloads } from "hooks/useTasks";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import { TypingIndicator } from "screens/thread/components/cells/components";

export const TaskJsonPayload = () => {
  // Type assertion is needed here as useParams assumes all parameters are present. contentId may be undefined, we want the type to accurately reflect this.
  const { taskId } = useParams();
  const { isTaskId } = useWorkflowContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const task = useTaskPayloads(taskId ? taskId : isTaskId);

  const toStringOrDefault = (maybeJson: unknown | undefined, defaultMessage: string) => {
    const json = maybeJson ? maybeJson : { error: defaultMessage };
    return JSON.stringify(json);
  };

  return (
    <>
      <TabTitle title={`Charli > JSON View`} />
      <Box width="100%" height="100%" className="canvas-view" px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"}>
        <Box width="100%" height="100%" className="canvas-modal">
          {task ? (
            <>
              <JsonView json={toStringOrDefault(task.inputPayload, "input payload was undefined")} />
              <JsonView json={toStringOrDefault(task.outputPayload, "output payload was undefined")} />
            </>
          ) : (
            <Center height="80vh">
              <TypingIndicator />
            </Center>
          )}
        </Box>
      </Box>
    </>
  );
};
