import { createSlice } from "@reduxjs/toolkit";
import { getBillingPlan } from "./operations";
import type { BillingPlanType } from "types/billing/BillingPlan";

interface BillingState {
  currentPlan?: BillingPlanType;
}

const initialState: BillingState = {};

export const { actions, reducer } = createSlice({
  name: "billing",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBillingPlan.fulfilled, (state, action) => {
      state.currentPlan = action.payload;
    });
  },
});

export default reducer;
