import { Array, Record, String } from "runtypes";
import type { Static } from "runtypes";
import { MemorizedClarificationConfiguration } from "./MemorizedClarificationConfiguration";

export const GetMemorizedClarificationsConfigsResponse = Array(MemorizedClarificationConfiguration);
export const GetMemorizedClarificationConfigResponse = MemorizedClarificationConfiguration;

export const CreateMemorizedClarificationConfigRequest = Record({
  entityName: String,
  matchingEntityNames: Array(String),
});

export const UpdateMemorizedClarificationConfigRequest = Record({
  id: String,
  matchingEntityNames: Array(String),
});

export const CreateMemorizedClarificationConfigResponse = MemorizedClarificationConfiguration;
export const UpdateMemorizedClarificationConfigResponse = MemorizedClarificationConfiguration;

export type GetMemorizedClarificationsConfigsResponse = Static<typeof GetMemorizedClarificationsConfigsResponse>;
export type GetMemorizedClarificationConfigResponse = Static<typeof GetMemorizedClarificationConfigResponse>;

export type CreateMemorizedClarificationConfigRequest = Static<typeof CreateMemorizedClarificationConfigRequest>;
export type UpdateMemorizedClarificationConfigRequest = Static<typeof UpdateMemorizedClarificationConfigRequest>;

export type CreateMemorizedClarificationConfigResponse = Static<typeof CreateMemorizedClarificationConfigResponse>;
export type UpdateMemorizedClarificationConfigResponse = Static<typeof UpdateMemorizedClarificationConfigResponse>;
