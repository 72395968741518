import { Box, useBreakpointValue, Stack, useColorModeValue, Center, SimpleGrid, Skeleton } from "@chakra-ui/react";
import React from "react";
import { useSkeletonProps } from "hooks";

export const HomeSkeleton = () => {
  const skeletonStyle = useSkeletonProps();
  const bgColor = useColorModeValue("gray.200", "gray.800");
  const bgSectionsColor = useColorModeValue("white", "gray.900");
  const maxProjectTiles = useBreakpointValue([5, 5, 3, 6, 9]);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  return (
    <Box height="100%">
      <Center height={"28rem"} width="100%">
        <Skeleton {...skeletonStyle} borderRadius={"full"} width="40rem" height="3rem" />
      </Center>
      <Box bgColor={bgColor} height=".5rem" width="100%" />
      <Stack direction="row" justifyContent={"space-between"} bgColor={bgSectionsColor} spacing="0" height="100%">
        <Box height="100%" width="100%" bgColor={bgSectionsColor}>
          <Skeleton {...skeletonStyle} borderRadius={"md"} height="2rem" width="12rem" ml="1.5rem" mt="1rem" />
          <SimpleGrid spacing="1rem" columns={[1, 1, 1, 2, 3]} p="1.5rem">
            <>
              {[...Array(maxProjectTiles)].map((_, index) => (
                <Skeleton key={index} {...skeletonStyle} height="13rem" />
              ))}
            </>
          </SimpleGrid>
        </Box>
        {!isMobile && (
          <>
            <Box bgColor={bgColor} width="3rem" minHeight="50vh" />
            <Box height="100%" width={`30rem`} className="ch-home-recent-activity" bgColor={bgSectionsColor} p="1rem">
              <Skeleton {...skeletonStyle} borderRadius={"md"} height="2rem" width="12rem" />
              <Stack spacing="1rem" pt="1.5rem">
                {Array.from({ length: 9 }, (_, index) => (
                  <Stack key={index} direction="row" spacing="1rem" maxWidth="19rem">
                    <Skeleton {...skeletonStyle} width="6rem" height="4rem" borderRadius="md" />
                    <Stack width="11.5rem" height={"100%"} justifyContent="space-between" pt="5px">
                      <Skeleton {...skeletonStyle} height=".8rem" />
                      <Skeleton {...skeletonStyle} height=".8rem" />
                      <Skeleton {...skeletonStyle} height=".5rem" width="5rem" mt=".5rem" />
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
};
