import type { Static } from "runtypes";
import { Array, Record, String } from "runtypes";
import { Message } from "./Message";

export const Conversation = Record({
  id: String,
  messages: Array(Message),
});

export type Conversation = Static<typeof Conversation>;
