import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { TabTitle } from "screens/common/components/TabTitle";
import { ProjectLandingFilter } from "./ProjectLandingFilter";
import { useSettingContext } from "screens/panels/settings/SettingsProvider";
import { useConversationContext } from "screens/thread/ConversationContext";
import { getViewConfig } from "configs/configMap";
import { useConfigMap, useCollection, useProjectParams, useCollectionKey, useGetViewConfig } from "hooks";
import { downloadCollections, downloadCollectionsByType } from "state/collection/operations";
import { useDispatch } from "react-redux";

export const Projects = () => {
  const { projectFilter = "", isPortfolios } = useProjectParams();
  const collection = useCollection(projectFilter, { refreshFromNetwork: !!isPortfolios });
  const maybeCollectionType = useCollectionKey(collection?.id, "collectionType");
  const maybeMetadatIds = useCollectionKey(collection?.id, "metadataIds");
  const { setHeaderText } = useSettingContext();
  const { onConversationClose } = useConversationContext();
  const configMap = useConfigMap();
  const collectionType = useGetViewConfig("collectionType", projectFilter, configMap);
  const portfolioCollectionType = useGetViewConfig("portfolioProjectType", collection?.collectionType || "", configMap);
  const dispatch = useDispatch();

  useEffect(() => {
    onConversationClose();
    setHeaderText(collection ? collection.name : `${getViewConfig("title", projectFilter, configMap)}` || "Project");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectFilter, configMap]);

  useEffect(() => {
    if (collectionType) {
      dispatch(downloadCollectionsByType({ collectionType }));

      if (collectionType === "due_diligence") {
        dispatch(downloadCollectionsByType({ collectionType: "portfolio" }));
      }
    }
  }, [dispatch, collectionType]);

  useEffect(() => {
    if (maybeCollectionType === "portfolio" && maybeMetadatIds && maybeMetadatIds.length > 0) {
      dispatch(downloadCollections({ ids: maybeMetadatIds, downloadCollectionWorkflows: true }));
    }
  }, [dispatch, maybeCollectionType, maybeMetadatIds]);

  return (
    <Box width="100%" height="100%">
      <Box>
        <TabTitle
          title={collection ? `Charli > ${collection.name}` : `Charli > ${getViewConfig("title", projectFilter, configMap) || "Project"}`}
        />
        <ProjectLandingFilter
          collectionIds={collection && collection.metadataIds}
          collectionType={collectionType || portfolioCollectionType}
          loadTilesCount={20}
        />
      </Box>
    </Box>
  );
};
