import type { Static } from "runtypes";
import { String } from "runtypes";

export const CopyKey = String.withConstraint((str) => {
  if (!str.startsWith("copy_")) {
    return "Copy keys must start with 'copy_'";
  }

  return /^[a-z0-9]+(?:_+[a-z0-9]+)*$/.test(str);
});

export type CopyKey = Static<typeof CopyKey>;
