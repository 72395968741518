import type { FieldValues } from "react-hook-form";
import type { IField } from "screens/common/components";
import type { RequestEntities } from "types/charliui";

export type EntitySeparator = "," | ";" | " ";
export const DefaultEntitySeparator: EntitySeparator = ",";

export function extractSplitEntities(
  fields: IField<FieldValues>[],
  prevEntities: Record<string, EntitySeparator> = {}
): Record<string, EntitySeparator> {
  const currentEntities: Record<string, EntitySeparator> = { ...prevEntities };

  fields.forEach((field) => {
    if (field.type === "multi-select" && field.splitEntity) {
      currentEntities[field.entity] = field.entitySeparator || DefaultEntitySeparator;
    } else if (field.type === "stack" || field.type === "grid") {
      Object.assign(currentEntities, extractSplitEntities(field.fields, currentEntities));
    }
  });

  return currentEntities;
}

export function updateBatchRequestEntities(
  entities: RequestEntities,
  setRequestEntities: (entities: RequestEntities | ((prevEntities: RequestEntities) => RequestEntities)) => void
) {
  setRequestEntities((prevEntities: RequestEntities) => {
    const hashEntities: Record<string, { value: string; source?: string }> = prevEntities.reduce(
      (acc, { entity, value, source }) => ({ ...acc, [`${entity}:${source}`]: { value, source } }),
      {}
    );
    entities.forEach(({ value: entityValue, entity: entityName, source: entitySource }) => {
      const prevEntityData = hashEntities[`${entityName}:${entitySource}`];

      if (prevEntityData !== undefined) {
        // remove entity from requestEntities if entityValue is empty
        if (!entityValue || entityValue === "") {
          delete hashEntities[`${entityName}:${entitySource}`];
        } else if (entityValue !== prevEntityData.value) {
          // update entityValue and entitySource if entityName already exists in requestEntities
          hashEntities[`${entityName}:${entitySource}`] = { value: entityValue, source: entitySource };
        }
      } else if (entityValue && entityValue.length > 0) {
        // add entity to requestEntities if entityName does not exist in requestEntities
        hashEntities[`${entityName}:${entitySource}`] = { value: entityValue, source: entitySource };
      }
    });

    return Object.entries(hashEntities).map(([entity, { value, source }]) => {
      const entityName = entity.split(":")[0];
      return { entity: entityName, value, source };
    });
  });
}

export function getRequestValue(entityName: string, requestEntities: RequestEntities) {
  return requestEntities.find((item) => item.entity === entityName)?.value || "";
}
