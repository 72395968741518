import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";
import { selectCopyByKey, selectAllCopies, selectCopiesEntities } from "state/copies/reducer";
import type { RootState } from "state/rootReducer";
import type { CopyValueMap } from "types/copy";
import { defaultCopiesValues } from "types/copy";
import { useMemo } from "react";

export function useCopyValue<T extends keyof CopyValueMap>(key: T): CopyValueMap[T] {
  return useSelector((state: RootState) => {
    const value = selectCopyByKey(state, key)?.value;
    return value && value.length > 0 ? (value as CopyValueMap[T]) : defaultCopiesValues[key];
  }, shallowEqual);
}

export function useCopies() {
  return useSelector((state: RootState) => selectAllCopies(state), shallowEqual);
}

export function useCopiesDictionary() {
  return useSelector((state: RootState) => selectCopiesEntities(state), shallowEqual);
}

export function useIsLoadingCopies() {
  return useSelector((state: RootState) => state.copies.isLoading, shallowEqual);
}

export interface Feature {
  name: string;
  trial: string;
  professional: string;
  business: string;
  premium: string;
  enterprise: string;
}

export function useSubscriptionFeatures(): Feature[] {
  const rawCopySubscriptionFeatures = useCopyValue("copy_subscription_features");

  return useMemo(
    () =>
      rawCopySubscriptionFeatures?.flatMap((feature) => {
        const [name, trial, professional, business, premium, enterprise] = feature.split("|").map((part) => part.trim());
        return name && trial && professional && business && premium && enterprise
          ? [{ name, trial, professional, business, premium, enterprise }]
          : [];
      }) || [],
    [rawCopySubscriptionFeatures]
  );
}
