import React, { useRef, useState } from "react";
import type { Tag } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { TagItems } from "./TagItems";
import { TagsSelector } from "./TagSelector";

export interface Tag {
  id: string;
  text: string;
}

interface Props {
  tagType?: "manual" | "auto" | "category" | "topic";
  placeholder?: string;
  suggestions?: Tag[];
  handleOnSaveTag?: (tag: string) => void;
  handleOnDeleteTag?: (tag: string) => void;
  handleUpdate?: (tags: Tag[]) => void;
  allowUpdate?: boolean;
  allowDelete?: boolean;
  tags: Tag[];
  id?: string;
  width?: string;
  hideInput?: boolean;
  hideLocalTags?: boolean;
  size?: "xs" | "sm" | "md";
  onClickTag?: (tag: string) => void;
  maxTagsToShow?: number;
  searchPhrase?: string;
  allowFilter?: boolean;
}

export const Tags = ({
  tagType = "manual",
  placeholder = "Select or enter a tag...",
  suggestions,
  tags,
  allowUpdate = true,
  allowDelete = true,
  handleOnSaveTag,
  handleOnDeleteTag,
  handleUpdate,
  id,
  width = "100%",
  hideInput = false,
  hideLocalTags = false,
  size = "xs",
  onClickTag,
  maxTagsToShow = 5,
  searchPhrase,
  allowFilter,
}: Props) => {
  const tagsRef = useRef<HTMLDivElement>(null);
  const [showAllTags, setShowAllTags] = useState(tagType === "topic");
  const [localTags, setLocalTags] = useState<Tag[]>(tags);

  return (
    <Box ref={tagsRef} display="contents">
      {!hideInput && tagsRef && (
        <TagsSelector
          parentRef={tagsRef}
          replaceSpaceCharacter={"underscore"}
          placeholder={placeholder}
          suggestions={suggestions}
          handleOnSaveTag={handleOnSaveTag}
          id={id}
          width={width}
          size={size}
          localTags={localTags}
          setLocalTags={setLocalTags}
        />
      )}
      {!hideLocalTags && (
        <Box>
          <TagItems
            localTags={localTags}
            tagType={tagType}
            setLocalTags={setLocalTags}
            maxTagsToShow={maxTagsToShow}
            onClickTag={onClickTag}
            showAllTags={showAllTags}
            setShowAllTags={setShowAllTags}
            handleOnDeleteTag={handleOnDeleteTag}
            handleUpdate={handleUpdate}
            allowDelete={allowDelete}
            allowUpdate={allowUpdate}
            searchPhrase={searchPhrase}
            allowFilter={allowFilter}
          />
        </Box>
      )}
    </Box>
  );
};
