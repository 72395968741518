import { useState, useCallback, useRef, useEffect } from "react";

interface IProps<T> {
  loadItems: (token: string | null) => Promise<{ nextToken: string | null; totalCount?: number; data: T }>;
  onClear?: () => void;
}

export const useLoadItems = <T>({ loadItems, onClear }: IProps<T[]>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState<T[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<null | number>(null);
  const [error, setError] = useState<string | null>(null);
  const tokenRef = useRef<string | null>(null);
  const fetchInProgress = useRef(false);

  const loadMore = useCallback(async () => {
    if (fetchInProgress.current) {
      return;
    }

    fetchInProgress.current = true;
    setIsLoading(true);
    try {
      const { data, totalCount: localTotalCount, nextToken } = await loadItems(tokenRef.current);

      tokenRef.current = nextToken;
      setItems((current) => [...current, ...data]);
      setHasNextPage(!!nextToken);

      if (localTotalCount) {
        setTotalCount(localTotalCount);
      }
    } catch (err) {
      setError(err);
    } finally {
      fetchInProgress.current = false;
      setIsLoading(false);
    }
  }, [loadItems]);

  const clear = useCallback(() => {
    tokenRef.current = null;
    setItems([]);
    setTotalCount(null);
    setHasNextPage(true);
    setError(null);
  }, []);

  useEffect(() => {
    if (items.length === 0 && !totalCount && hasNextPage) {
      onClear && onClear();
    }
    // omit onClear
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, totalCount, hasNextPage]);

  return { loading: isLoading, items, hasNextPage, error, totalCount, loadMore, clear };
};
