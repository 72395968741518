import React, { useContext, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { TabTitle } from "screens/common/components/TabTitle";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { ConversationContext } from "screens/thread/ConversationContext";
import { Outlet } from "react-router-dom";

export const OperationsTabs = () => {
  const { setHeaderText } = useContext(SettingsProviderContext);
  const { onConversationClose, setConversationId } = useContext(ConversationContext);

  useEffect(() => {
    onConversationClose();
    setHeaderText("Operations");
  }, [onConversationClose, setConversationId, setHeaderText]);

  return (
    <Box width="100%" height={"100%"}>
      <TabTitle title="Charli > Operations" />
      <Outlet />
    </Box>
  );
};
