import { Box, useBreakpointValue } from "@chakra-ui/react";
import { useCollectionByTypeCount } from "hooks";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LatestContentList } from "screens/content/common/LatestContent/LatestContentList";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useSettingContext } from "screens/panels/settings/SettingsProvider";
import { useConversationContext } from "screens/thread/ConversationContext";
import { downloadCollectionsByType } from "state/collection/operations";

export const ResourceLibrary = () => {
  const { setHeaderText, setIsLoading } = useSettingContext();
  const { setActionView } = useAddToCharliContext();
  const { onConversationClose, setConversationId, setContentId } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const collectionByTypeCount = useCollectionByTypeCount("library");
  const dispatch = useDispatch();

  useEffect(() => {
    onConversationClose();
    setHeaderText("Resource Library");
    setActionView("upload");
  }, [onConversationClose, setActionView, setContentId, setConversationId, setHeaderText]);

  useEffect(() => {
    if (collectionByTypeCount === undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [collectionByTypeCount, setIsLoading]);

  useEffect(() => {
    dispatch(downloadCollectionsByType({ collectionType: "library" }));
  }, [dispatch]);

  return (
    <Box mt="1rem" px={isMobile ? ".5rem" : "1rem"}>
      <LatestContentList
        hideCheckbox
        showRecordCount
        showAddButton
        maxListHeight="unset"
        initialItemCount={50}
        maxRecentStuff={200}
        minChildWidth={130}
      />
    </Box>
  );
};
