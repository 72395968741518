import type { FunctionComponent } from "react";
import React, { useRef, useState } from "react";
import { Icon, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import type { Message } from "types/conversation";
import { MessageContainer } from "./components";
import { ChatBubble } from "./components";
import type { AuthRequest } from "types/charliui";
import { FaExchangeAlt, FaGoogle } from "react-icons/fa";
import { track } from "api/analytics";
import { GOOGLE_CALENDAR_AUTHORIZE_BUTTON_CLICKED, GOOGLE_DRIVE_AUTHORIZE_BUTTON_CLICKED } from "api/analytics/events";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { useConversation, useExternalIntegrations } from "hooks";
import { LinkLogo } from "screens/common/components";

interface Props {
  message: Message;
  datum: AuthRequest;
  isLastMessage: boolean;
}

enum AuthApp {
  googleDrive = "Google Drive",
  googleCalendar = "Google Calendar",
}

const getProvider = (authCodeUrl: string) => {
  const authUrlMapping = [
    { substring: "drive", provider: AuthApp.googleDrive },
    { substring: "calendar", provider: AuthApp.googleCalendar },
  ];

  for (const providerMap of authUrlMapping) {
    if (authCodeUrl.includes(providerMap.substring)) return providerMap.provider;
  }
};

const getIconForProvider = (provider?: AuthApp) => {
  if (provider && [AuthApp.googleDrive, AuthApp.googleCalendar].includes(provider)) {
    return FaGoogle;
  } else {
    return FaExchangeAlt;
  }
};

const getMissingAuthMessage = (provider?: AuthApp) => {
  if (provider) {
    return `To complete this, I'll need access to your ${provider}.`;
  } else {
    return "To complete this, I'll need access to the third-party application.";
  }
};

const trackButtonClick = (provider?: AuthApp) => {
  if (provider === AuthApp.googleDrive) {
    track(GOOGLE_DRIVE_AUTHORIZE_BUTTON_CLICKED);
  } else if (provider === AuthApp.googleCalendar) {
    track(GOOGLE_CALENDAR_AUTHORIZE_BUTTON_CLICKED);
  }
};

export const AuthCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ message, datum, isLastMessage }) => {
  const content = datum.body;
  const provider = getProvider(content.auth_code_url);
  const icon = getIconForProvider(provider);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const authWindow = useRef<Window | null>(null);
  const textColor = useColorModeValue("secondary.default", "gray300");
  const iconColor = useColorModeValue("gray.500", "gray.300");
  const charliBgColor = useColorModeValue("blue.100", "blue.600");
  const dispatch = useDispatch();
  const conversationId = message.conversationId;
  const { userMessageArray, authRequest } = useConversation(message.conversationId || "");
  const integrationIdFromMessage = userMessageArray?.filter((item) => item?.name === "integration_id")[0]?.value || undefined;

  const integrationId = authRequest?.body.integration_id ?? integrationIdFromMessage;
  const { getIntegration } = useExternalIntegrations(undefined, undefined, integrationId);

  const send = (authAccepted: boolean) => {
    if (!conversationId) return;
    const entities: {
      entity: string;
      value: string;
    }[] = [];
    entities.push({ entity: "auth_granted", value: authAccepted ? "true" : "false" });

    dispatch(
      sendMessage({
        conversationId: conversationId,
        message: `Authentication ${authAccepted ? "accepted" : "cancelled"}.`,
        entities: entities,
      })
    );
  };

  const integrationLogo = () => {
    if (getIntegration?.domain && getIntegration?.domain?.length > 0) {
      return <LinkLogo url={getIntegration.domain} boxSize="1.5rem" p="0" />;
    } else {
      return <Icon as={icon} color={iconColor} boxSize="1.5rem" />;
    }
  };

  const onMessage = (event: MessageEvent) => {
    if (event.data?.type?.endsWith("error")) {
      console.error("An error occured with the authorization popup.");
      closePopup();
    }
    if (event.data?.type?.endsWith("finish")) {
      const authId = event.data?.authId;
      configCallback(authId, true);
    }
    if (event.data?.type?.endsWith("cancel")) {
      configCallback();
    }
  };

  const onWindowClosed = () => {
    window.removeEventListener("message", onMessage);
    authWindow.current = null;
  };

  const closePopup = () => {
    authWindow.current?.close();
    onWindowClosed();
  };

  const configCallback = async (authId?: string, authAccepted?: boolean) => {
    send(authAccepted || false);
    if (authWindow) {
      closePopup();
    }
  };
  const createLoginPopup = (url: string) => {
    const height = 600;
    const width = 450;
    const targetWindow = window.top ?? window;

    // Center popup in parent window
    const y = targetWindow.outerHeight / 2 + targetWindow.screenY - height / 2;
    const x = targetWindow.outerWidth / 2 + targetWindow.screenX - width / 2;
    const authPopup = window.open(
      url,
      "activity-auth",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
    );
    authWindow.current = authPopup;
    window.addEventListener("message", onMessage);
    // This will check in intervals if the window was really closed or not.
    const timer = setInterval(() => {
      if (authPopup?.closed) {
        clearInterval(timer);
        onWindowClosed();
      }
    }, 1000);
    if (authWindow.current && authWindow.current?.focus) {
      authWindow.current.focus();
    }
  };

  return (
    <React.Fragment>
      <MessageContainer initiator={"charli"} key={`${message.id}-auth-1`}>
        <ChatBubble key={`${message.id}-auth-text`} initiator={"charli"} text={getMissingAuthMessage(provider)} />
      </MessageContainer>
      {provider === AuthApp.googleDrive && (
        <MessageContainer initiator={"charli"} key={`${message.id}-auth-2`}>
          <ChatBubble
            key={`${message.id}-auth-text`}
            initiator={"charli"}
            text={`Giving me access allows me to create two new folders, Charli Business and Charli Personal. I'll organize any content you upload or ask me to move into these folders.</br>Click on "Grant access to ${provider}" below to get started`}
          />
        </MessageContainer>
      )}
      <MessageContainer initiator={"charli"} key={`${message.id}-auth-button`}>
        {!isLastMessage ? (
          <ChatBubble initiator={"charli"} backgroundColor={charliBgColor}>
            <Stack direction="row" alignItems="center">
              {integrationLogo()}
              <Text pl="0.5rem" fontSize="sm" fontWeight="500" color={textColor} wordBreak={"break-word"}>
                Authorization processed.
              </Text>
            </Stack>
          </ChatBubble>
        ) : isPopupOpen ? (
          <ChatBubble
            initiator={"charli"}
            backgroundColor={charliBgColor}
            onClick={() => {
              setIsPopupOpen(false);
            }}>
            <Stack direction="row" alignItems="center">
              {integrationLogo()}
              <Text pl="0.5rem" fontSize="sm" fontWeight="500" color={textColor} wordBreak={"break-word"}>
                Waiting for authorization to complete, this may take a few moments. <br />
                If you've accidentally cancelled the request, click this message to restart authorization.
              </Text>
            </Stack>
          </ChatBubble>
        ) : (
          <ChatBubble
            initiator={"charli"}
            backgroundColor={charliBgColor}
            onClick={() => {
              trackButtonClick(provider);
              createLoginPopup(content.auth_code_url);
              setIsPopupOpen(true);
            }}
            className="ch-storage-provider-authorization-button">
            <Stack direction="row" alignItems="center">
              {integrationLogo()}
              <Text pl="0.5rem" color={textColor} fontSize="sm" fontWeight="500">
                {provider ? `Grant access to ${getIntegration?.name}` : `Click here to authorize ${getIntegration?.name}.`}
              </Text>
              <ChevronRightIcon color={textColor} boxSize="1.5rem" />
            </Stack>
          </ChatBubble>
        )}
      </MessageContainer>
    </React.Fragment>
  );
};
