import { createSlice } from "@reduxjs/toolkit";
import {
  addOrganizationEntitlementConfig,
  addPlanEntitlementConfig,
  createEntitlementConfig,
  downloadEntitlementConfigs,
  downloadEntitlements,
  removeEntitlementConfig,
  removeOrganizationEntitlementConfig,
  updateEntitlementConfig,
  updatePlanEntitlementConfig,
} from "./operations";
import type { EntitlementMap } from "types/entitlements";
import type { EntitlementConfig } from "api/entitlementConfigs/models/Entitlement";
import { setLoaderFalse, setLoaderTrue } from "state/utils";

const initialState = {
  isLoading: false,
  entitlementMap: {} as EntitlementMap,
  entitlementConfigs: [] as EntitlementConfig[],
};

export const { actions, reducer } = createSlice({
  name: "entitlement",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadEntitlements.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      const entitlements = action.payload.reduce((acc, currentEntitlement) => {
        acc[currentEntitlement.entitlementId] = currentEntitlement;

        return acc;
      }, {} as EntitlementMap);

      state.entitlementMap = entitlements;
    });
    builder.addCase(downloadEntitlementConfigs.fulfilled, (state, action) => {
      state.entitlementConfigs = action.payload;
    });

    builder.addCase(createEntitlementConfig.pending, setLoaderTrue);
    builder.addCase(createEntitlementConfig.fulfilled, setLoaderFalse);
    builder.addCase(createEntitlementConfig.rejected, setLoaderFalse);

    builder.addCase(updateEntitlementConfig.pending, setLoaderTrue);
    builder.addCase(updateEntitlementConfig.fulfilled, setLoaderFalse);
    builder.addCase(updateEntitlementConfig.rejected, setLoaderFalse);

    builder.addCase(addPlanEntitlementConfig.pending, setLoaderTrue);
    builder.addCase(addPlanEntitlementConfig.fulfilled, setLoaderFalse);
    builder.addCase(addPlanEntitlementConfig.rejected, setLoaderFalse);

    builder.addCase(updatePlanEntitlementConfig.pending, setLoaderTrue);
    builder.addCase(updatePlanEntitlementConfig.fulfilled, setLoaderFalse);
    builder.addCase(updatePlanEntitlementConfig.rejected, setLoaderFalse);

    builder.addCase(removeEntitlementConfig.pending, setLoaderTrue);
    builder.addCase(removeEntitlementConfig.fulfilled, setLoaderFalse);
    builder.addCase(removeEntitlementConfig.rejected, setLoaderFalse);

    builder.addCase(addOrganizationEntitlementConfig.pending, setLoaderTrue);
    builder.addCase(addOrganizationEntitlementConfig.fulfilled, setLoaderFalse);
    builder.addCase(addOrganizationEntitlementConfig.rejected, setLoaderFalse);

    builder.addCase(removeOrganizationEntitlementConfig.pending, setLoaderTrue);
    builder.addCase(removeOrganizationEntitlementConfig.fulfilled, setLoaderFalse);
    builder.addCase(removeOrganizationEntitlementConfig.rejected, setLoaderFalse);
  },
});

export default reducer;
