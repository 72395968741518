import type { FunctionComponent } from "react";
import React from "react";
import { Text, Box, useColorModeValue, Button, useBreakpointValue } from "@chakra-ui/react";
import { LandingTitle } from "screens/landing/components";
import { ActivityTilesSection } from "../activities/ActivityTilesSection";
import { useNavigate } from "react-router-dom";

interface Props {
  sidebarWidth: number;
}

export const HomeRightPanelActivities: FunctionComponent<Props> = ({ sidebarWidth }) => {
  const bgSectionsColor = useColorModeValue("white", "gray.900");
  const maxActivitiesShown = useBreakpointValue([4, 19, 19, 12, 10]);
  const navigate = useNavigate();

  return (
    <Box height="100%" width={`${sidebarWidth}rem`} className="ch-home-recent-activity" bgColor={bgSectionsColor}>
      <LandingTitle text="Recent Activity" underline color={"primary.default"} />
      <ActivityTilesSection
        maxActivities={maxActivitiesShown}
        states={["complete", "in_progress"]}
        blacklist={["cancel", "dismiss", "how"]}
        noItemsFoundMessage="Nothing currently in progress or requiring your attention"
        showAsList
      />
      <Button
        className="ch-home-my-activity-view-all"
        variant="link"
        mt="1rem"
        cursor="pointer"
        onClick={() => navigate(`/activities/all`)}
        key="show.needs_action"
        size="sm"
        color={"primary.default"}>
        <Text>View All</Text>
      </Button>
    </Box>
  );
};
