import { useEffect, useMemo } from "react";
import { useEntitlements } from "./useEntitlements";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { Organization } from "types/organization/organization";
import { downloadOrganization, downloadOrganizations } from "state/organization/operations";

export function useOrganizations(): Organization[] {
  const { organizations, members } = useSelector((store: RootState) => store.organizations);
  const entitlements = useEntitlements();
  const dispatch = useDispatch();
  const { manage_organizations_read: hasOrganizationsRead } = entitlements;

  const organizationsWithMembers = useMemo(() => {
    return Object.values(organizations).map((organization) => {
      const { memberIds, ...restOfOrganization } = organization;

      return {
        ...restOfOrganization,
        members: memberIds.map((id) => members[id]),
      };
    });
  }, [organizations, members]);

  useEffect(() => {
    if (!hasOrganizationsRead) {
      return;
    }

    dispatch(downloadOrganizations());
  }, [hasOrganizationsRead, dispatch]);

  return organizationsWithMembers;
}

export function useOrganization(
  id: string | null,
  options: { refreshNetwork: boolean } = { refreshNetwork: true }
): Organization | undefined {
  const dispatch = useDispatch();
  const { organizations, members } = useSelector((state: RootState) => state.organizations);

  const organization = useMemo(() => {
    if (!id) {
      return;
    }

    const maybeOrganization = organizations[id];

    if (!maybeOrganization) {
      return;
    }

    const { memberIds, ...restOfOrganization } = maybeOrganization;

    return {
      ...restOfOrganization,
      members: memberIds.map((id) => members[id]),
    };
  }, [organizations, members, id]);

  useEffect(() => {
    if (!id) {
      return;
    }

    if (!options?.refreshNetwork) {
      return;
    }

    dispatch(downloadOrganization({ organizationId: id }));
  }, [dispatch, options?.refreshNetwork, id]);

  return organization;
}

export function useOrganizationIsLoading() {
  return useSelector((store: RootState) => store.organizations.isLoading);
}

export function useOrganizationEntitlements(organizationId: string | null) {
  return useSelector((store: RootState) => ({
    entitlements: !organizationId ? [] : store.organizations.entitlements[organizationId] ?? [],
    isLoading: store.organizations.isLoadingEntitlements,
  }));
}
