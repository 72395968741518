import React, { useMemo } from "react";
import { Box, Button } from "@chakra-ui/react";
import { DynamicForm } from "screens/common/components";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useButtonProps } from "hooks";
import { Wizard } from "react-use-wizard";
import type { IField } from "screens/common/components";
import type { Visibility } from "api/informationExtractionConfig/models/Resource";
import { TargetEntitiesFieldArray } from "./TargetEntitiesFieldArray";
import { useResource } from "hooks/useInformationExtractionConfig";
import type { ResourceWithId } from "state/informationExtractionConfig/reducer";
import { DocumentConfigSchema } from "screens/landing/tabs/admin/informationExtractionConfig/schemas/DocumentConfig";
import { JSONConfigSchema } from "screens/landing/tabs/admin/informationExtractionConfig/schemas/JsonConfig";
import * as yup from "yup";
import store from "state/store";
import { defaultDocumentConfig } from "screens/landing/tabs/admin/informationExtractionConfig/utils/defaultDocumentConfig";
import { defaultJsonConfig } from "screens/landing/tabs/admin/informationExtractionConfig/utils/defaultJsonConfig";
import type { Occurrence } from "api/informationExtractionConfig/models/TargetEntity";
import { isJsonString } from "../../utils";

export interface TargetEntityFormValues {
  entityId?: string;
  entityName: string;
  entityType: string;
  keywords: string;
  pattern: string;
  categories: string;
  allowMultipleValues: boolean;
  occurrence: Occurrence;
  titleKeywords: string;
  lastUpdatedDate?: Date;
  lastUpdatedByUserName?: string;
  confidenceThreshold?: number;
  negativeKeywords: string;
  exactMatching: boolean;
}

export interface FormValues {
  id?: string;
  urn: string;
  category: string;
  keywords: string;
  visibility: Visibility;
  confidenceThreshold: number;
  documentConfig: string;
  jsonConfig: string;
  entities: TargetEntityFormValues[];
}

interface IProps {
  id: string | null;
  onSubmit: (values: FormValues, previous?: ResourceWithId) => void;
  initialValues?: FormValues;
}

interface PanelProps extends IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UpsertInformationExtractionConfig = ({ id, initialValues, onSubmit }: IProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");
  const resource = useResource(id);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      urn: yup.string().required("Field is required"),
      category: yup
        .string()
        .test("is-unique", "This category already exists. Type a different one.", (value) => {
          if (!value) {
            return true;
          }

          const currentCategories = [
            ...Object.values(store.getState().informationExtractionConfig.resources.byId).map((resource) => resource.category),
          ];

          if (value !== resource?.category && currentCategories.includes(value)) {
            return false;
          } else {
            return true;
          }
        })
        .required("Field is required"),
      keywords: yup.string().required("Field is required"),
      confidenceThreshold: yup.number().min(0).max(1).required("Field is required"),
      documentConfig: yup.string().test("is-json", "Invalid JSON", (value) => isJsonString(value, DocumentConfigSchema)),
      jsonConfig: yup.string().test("is-json", "Invalid JSON", (value) => isJsonString(value, JSONConfigSchema)),
    });
  }, [resource?.category]);

  const fields: IField<FormValues>[] = useMemo(() => {
    return [
      {
        type: "text",
        label: "URN",
        entity: "urn",
        defaultValue: "",
      },
      {
        type: "text",
        label: "Category",
        entity: "category",
        defaultValue: "",
      },
      {
        type: "textarea",
        label: "Keywords",
        entity: "keywords",
        defaultValue: "",
        placeholder: "Add keywords, separated by line breaks",
      },
      {
        type: "select",
        label: "Visibility",
        entity: "visibility",
        options: [
          { label: "Public", value: "public" },
          { label: "Private", value: "private" },
          { label: "Protected", value: "protected" },
        ],
        defaultValue: "public",
      },
      {
        type: "number",
        label: "Confidence threshold",
        entity: "confidenceThreshold",
        min: 0,
        max: 1,
        defaultValue: 0,
      },
      {
        type: "field-array",
        label: "Target entities",
        entity: "entities",
        render: () => <TargetEntitiesFieldArray resource={resource} />,
        defaultValue: [],
      },
      {
        type: "json",
        label: "Document config",
        entity: "documentConfig",
        defaultValue: "",
        jsonSchema: DocumentConfigSchema,
      },
      {
        type: "json",
        label: "JSON config",
        entity: "jsonConfig",
        defaultValue: "",
        jsonSchema: JSONConfigSchema,
      },
    ];
  }, [resource]);

  const defaultValues: FormValues = useMemo(() => {
    if (initialValues) {
      return initialValues;
    } else {
      return {
        urn: "",
        category: "",
        keywords: "",
        visibility: "public",
        confidenceThreshold: 0,
        documentConfig: JSON.stringify(defaultDocumentConfig, null, 2),
        jsonConfig: JSON.stringify(defaultJsonConfig, null, 2),
        entities: [],
      };
    }
  }, [initialValues]);

  return (
    <Box pl=".5rem">
      <DynamicForm<FormValues>
        title={`${id ? "Update" : "Create"} resource`}
        fields={fields}
        defaultValues={defaultValues}
        formId="upsert-information-extraction-config-form"
        onSubmit={(data) => onSubmit(data, resource)}
        validationSchema={validationSchema}
      />
      <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
        <Button disabled={false} {...commonButtonProps} type="submit" form="upsert-information-extraction-config-form">
          Save Resource
        </Button>
      </Box>
    </Box>
  );
};

export const UpsertInformationExtractionConfigPanel = (props: PanelProps) => {
  const { id, isOpen, initialValues, onSubmit, onClose } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${id ? "Update" : "Create"} information extraction config`}>
      <Wizard>
        <PanelStep>{isOpen && <UpsertInformationExtractionConfig id={id} onSubmit={onSubmit} initialValues={initialValues} />}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
