import type {
  CreateMemorizedClarificationConfigRequest,
  UpdateMemorizedClarificationConfigRequest,
} from "api/memorizedClarificationConfig/models/MemorizedClarificationConfigApi";
import type { MemorizedClarificationConfiguration } from "api/memorizedClarificationConfig/models/MemorizedClarificationConfiguration";
import { useAppDispatch } from "hooks";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadMemorizedClarificationConfig,
  downloadMemorizedClarificationsConfigs,
  updateMemorizedClarificationConfigAction,
  createMemorizedClarificationConfigAction,
} from "state/memorizedClarificationConfig/operations";
import type { RootState } from "state/rootReducer";
import type { SerializedError } from "@reduxjs/toolkit";

function isError(payload: unknown): payload is SerializedError {
  return (payload as SerializedError).message !== undefined;
}

export const useMemorizedClarificationConfigs = (): MemorizedClarificationConfiguration[] => {
  const { memorizedClarificationConfigByIds, memorizedClarificationConfigIds } = useSelector(
    (state: RootState) => state.memorizedClarificationConfigurations
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(downloadMemorizedClarificationsConfigs());
  }, [dispatch]);

  return useMemo(() => {
    return memorizedClarificationConfigIds.map((id) => memorizedClarificationConfigByIds[id]);
  }, [memorizedClarificationConfigByIds, memorizedClarificationConfigIds]);
};

export const useMemorizedClarificationConfig = (id: string | undefined): MemorizedClarificationConfiguration | undefined => {
  const { memorizedClarificationConfigByIds } = useSelector((state: RootState) => state.memorizedClarificationConfigurations);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(downloadMemorizedClarificationConfig(id));
    }
  }, [dispatch, id]);

  return id ? memorizedClarificationConfigByIds[id] : undefined;
};

export const useMemorizedClarificationConfigLoading = (): boolean => {
  return useSelector((state: RootState) => state.memorizedClarificationConfigurations.isLoading);
};

export const useCreateUpdateMemorizedClarificationConfig = () => {
  const dispatch = useAppDispatch();

  const create = useCallback(
    async (payload: CreateMemorizedClarificationConfigRequest): Promise<{ success: boolean; message?: string }> => {
      const response = await dispatch(createMemorizedClarificationConfigAction(payload));

      if (isError(response)) {
        return { success: false, message: response.message };
      } else {
        return { success: true };
      }
    },
    [dispatch]
  );

  const update = useCallback(
    async (payload: UpdateMemorizedClarificationConfigRequest): Promise<{ success: boolean; message?: string }> => {
      const response = await dispatch(updateMemorizedClarificationConfigAction(payload));

      if (isError(response)) {
        return { success: false, message: response.message };
      } else {
        return { success: true };
      }
    },
    [dispatch]
  );

  return {
    create,
    update,
  };
};
