import type { FunctionComponent } from "react";
import React from "react";
import { EditableInput } from "./EditableInput";

interface Props {
  id?: string;
  value?: string;
  entity: string;
  onEdit: (entity: string, value: string, id?: string) => void;
  isEditEnabled?: boolean;
  size?: "sm" | "md" | "lg";
  rowMaxHeight?: string;
  valueColumnAlignRight?: boolean;
  isTextTruncated?: boolean;
}

export const StringWithEditMode: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  value = "",
  entity,
  onEdit,
  isEditEnabled = false,
  size,
  id,
  rowMaxHeight,
  valueColumnAlignRight,
  isTextTruncated,
}) => {
  return (
    <EditableInput
      id={id || undefined}
      value={value}
      entity={entity}
      onEdit={onEdit}
      isEditEnabled={isEditEnabled}
      size={size}
      maxHeight={rowMaxHeight}
      valueColumnAlignRight={valueColumnAlignRight}
      isTextTruncated={isTextTruncated}
    />
  );
};
