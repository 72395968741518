import type { FunctionComponent } from "react";
import { useEffect } from "react";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { ContentCanvasFilterMenu } from "screens/content/contentCanvas/header/ContentCanvasFilterMenu";
import { ContentFilterContext } from "../body/ContentFilterContext";
import { useConfigMap, useGetViewConfig } from "hooks/useViewConfig";

interface Props {
  isShowingResources?: boolean;
}

export const ProjectContentFilter: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  isShowingResources = true,
}) => {
  const { isFilterOpen, onFilterToggle, onFilterOpen, onFilterClose, setHasEverySelectedTag, setSelectedTags } =
    useContext(ContentFilterContext);
  const { pathname } = useLocation();
  const isProject = pathname.includes("project");
  const route = pathname.split("/").slice(isProject ? 2 : 1, isProject ? 3 : 2)[0];
  const configMap = useConfigMap();
  const defaultFilterTags = useGetViewConfig("defaultFilterTags", route, configMap);

  useEffect(() => {
    if (defaultFilterTags) {
      setHasEverySelectedTag(false);
      setSelectedTags(defaultFilterTags);
    } else {
      setHasEverySelectedTag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilterTags]);

  return (
    <ContentCanvasFilterMenu
      isShowingResources={isShowingResources}
      onFilterToggle={onFilterToggle}
      onOpen={onFilterOpen}
      isOpen={isFilterOpen}
      onClose={onFilterClose}
    />
  );
};
