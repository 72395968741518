import { Array, Record, String, Union } from "runtypes";
import { EntityConfiguration } from "./EntityConfiguration";
import type { Static } from "runtypes";
import { TriggerChildWorkflow } from "./TriggerChildWorkflow";
import { RunTask } from "./RunTask";

export const GetEntityConfigurationsResponse = Array(EntityConfiguration);
export const GetEntityConfigurationResponse = EntityConfiguration;

export const CreateEntityConfigurationRequest = Record({
  entityName: String,
  config: Array(Union(TriggerChildWorkflow, RunTask)),
});

export const UpdateEntityConfigurationRequest = Record({
  id: String,
  config: Array(Union(TriggerChildWorkflow, RunTask)),
});

export const CreateEntityConfigurationResponse = EntityConfiguration;
export const UpdateEntityConfigurationResponse = EntityConfiguration;

export type GetEntityConfigurationsResponse = Static<typeof GetEntityConfigurationsResponse>;
export type GetEntityConfigurationResponse = Static<typeof GetEntityConfigurationResponse>;
export type CreateEntityConfigurationRequest = Static<typeof CreateEntityConfigurationRequest>;
export type UpdateEntityConfigurationRequest = Static<typeof UpdateEntityConfigurationRequest>;
export type CreateEntityConfigurationResponse = Static<typeof CreateEntityConfigurationResponse>;
export type UpdateEntityConfigurationResponse = Static<typeof UpdateEntityConfigurationResponse>;
