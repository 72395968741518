import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserWorkflows, getWorkflows } from "api/workflows";
import chunk from "lodash/chunk";
import uniq from "lodash/uniq";
import type { Workflow } from "types/workflows/workflow";
import { WorkflowFetchError } from "api/workflows/models/WorkflowFetchError";
import { requestToDownloadWorkflows } from "./utils";
import type { RootState } from "state/rootReducer";
import type { WorkflowStatus } from "api/tasks/models/WorkflowStatus";

const CHUNK_SIZE = 50;

export const downloadWorkflowsAfterLogin = createAsyncThunk(
  "workflow/download-workflows-after-login",
  async ({ status, limit = 30 }: { status: WorkflowStatus[]; limit?: number }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState;
    const userId = store.session.user?.id;

    if (userId) {
      // This endpoint just fetches the ids of the workflows, not the workflows themselves
      const { data: workflowsIds } = await getUserWorkflows(userId, { limit, filters: { inStatus: status } });

      if (workflowsIds.length > 0) {
        requestToDownloadWorkflows(workflowsIds, store.workflow.isLoadingWorkflowMap, thunkAPI.dispatch);
      }
    }
  }
);

export const downloadWorkflows = createAsyncThunk(
  "workflow/add-workflows",
  async (workflowIds: string[], thunkAPI): Promise<Workflow[]> => {
    const deduplicatedIds = uniq(workflowIds);
    const chunkedIds = chunk(deduplicatedIds, CHUNK_SIZE);

    const workflows = await chunkedIds.reduce(async (promise, workflowIdsChunk) => {
      const previousWorkflows = await promise;

      const newWorkflows = await getWorkflows(workflowIdsChunk);

      const filteredWorkflows = newWorkflows.flatMap((newWorkflow) => {
        return WorkflowFetchError.guard(newWorkflow) ? [] : [newWorkflow];
      });

      return [...previousWorkflows, ...filteredWorkflows];
    }, Promise.resolve([] as Workflow[]));

    return workflows;
  }
);
