import { Record, String, Array } from "runtypes";
import { FinancialMarket } from "./FinancialMarket";
import type { Static } from "runtypes";

export const CompanyExchangeResponse = Record({
  status: String,
  message: String,
  financial_market_data: Array(FinancialMarket),
});

export type CompanyExchangeResponse = Static<typeof CompanyExchangeResponse>;
