import { Center, Stack, useColorModeValue, Text } from "@chakra-ui/react";
import { track } from "api/analytics";
import { ACTIVITY_OPENED } from "api/analytics/events";
import type { FunctionComponent } from "react";
import { useMemo, useContext } from "react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IntentIcon } from "screens/common/components";
import { ConversationContext } from "screens/thread/ConversationContext";
import type { WorkflowTaskStatus } from "types/workflows/workflow";
import { ActivityActionButtons } from "./ActivityActionButtons";
import { useWorkflowCompletionDateOrNow, useWorkflowKey } from "hooks/useWorkflows";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { formatDistanceToNow } from "date-fns";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { useConversation } from "hooks";

interface Props {
  conversationId: string;
  initiatingMessage?: string;
  reply?: string;
  lastUpdated: Date;
  state: WorkflowTaskStatus;
  intent: string;
  selected?: boolean;
  dismissed?: boolean;
  hideStatusIcon?: boolean;
  tileLocation: "landing" | "activity_list_drawer";
  workflowId?: string;
  showAsList?: boolean;
}

export const ActivityTile: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tileBorderColor = useColorModeValue("#cbd5e0", "#2D3748");
  const canvasIntents = [
    "share",
    "share_resources",
    "accept_share",
    "find",
    "store_link",
    "add_link",
    "process_receipt",
    "find_archived",
    "organize",
    "publish_cap_table",
  ];
  const { setConversationId, onConversationOpen } = useContext(ConversationContext);

  const getPath = () => {
    if (props.intent && canvasIntents.includes(props.intent) && props.state === "complete") {
      return navigate(`/search/${props.conversationId}`);
    } else {
      setConversationId(props.conversationId);
      onConversationOpen();
    }
  };
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(props.workflowId);
  const tileCreationDate = useWorkflowKey(props.workflowId, "creationDate");

  const isWorkflowInProgress = useMemo(() => {
    return props.state === "in_progress" || props.state === "clarification_needed" || props.state === "queued";
  }, [props.state]);
  const { inlineActions } = useConversation(props.conversationId);
  const hasActionState = props.state === "in_progress" || props.state === "clarification_needed" || props.state === "error";

  return (
    <Stack
      className="ch-activity-tile"
      cursor={"pointer"}
      onClick={() => {
        track(ACTIVITY_OPENED, { intent: props.intent, state: props.state, tile_location: props.tileLocation });
        getPath();
      }}
      direction={pathname.includes("/home") ? "column" : "row"}
      spacing={pathname.includes("/home") ? "0" : "1rem"}
      height="100%"
      width="100%"
      justifyContent={"space-between"}
      borderBottom={`1px solid ${tileBorderColor}`}>
      <Stack direction="row" width={pathname.includes("/home") ? "60%" : "30rem"} spacing="1rem" justifyContent={"space-between"}>
        <Stack direction="row" spacing="1rem">
          <IntentIcon intent={props.intent} />
          <TextOverflowTooltip
            style={{ lineHeight: "2.5rem", height: "2.5rem" }}
            label={props.intent === "find" ? props.initiatingMessage?.replace("Find", "Search") || "" : props.initiatingMessage || ""}
            className="ch-activity-title"
          />
        </Stack>
        {(inlineActions || hasActionState) && pathname.includes("/home") && (
          <Center>
            <Stack direction="row" justifyContent="flex-end">
              <ActivityActionButtons {...props} hideActionButtons />
            </Stack>
          </Center>
        )}
      </Stack>
      <Stack direction="row" spacing="1rem" width={pathname.includes("/home") ? "60%" : "100%"} justifyContent={"space-between"}>
        {props.workflowId && isWorkflowInProgress && (
          <Center pb={pathname.includes("/home") ? "1rem" : "0"} width="100%">
            <WorkflowProgressBar
              maxWidth="100%"
              workflowId={props.workflowId}
              lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
                addSuffix: true,
                includeSeconds: false,
              })}
              size={pathname.includes("/home") ? "compact" : "full"}
              showInline={pathname.includes("/home") ? false : true}
            />
          </Center>
        )}
        {(inlineActions || hasActionState) && !pathname.includes("/home") && (
          <Center>
            <Stack direction="row" justifyContent="flex-end">
              <ActivityActionButtons {...props} hideActionButtons />
            </Stack>
          </Center>
        )}
      </Stack>
      {!pathname.includes("/home") && (
        <Center>
          <Text fontSize={"sm"} color="primary.darkGray" width="11rem">
            {tileCreationDate &&
              new Date(tileCreationDate).toLocaleDateString(undefined, {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
          </Text>
        </Center>
      )}
    </Stack>
  );
};
