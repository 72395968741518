export enum MessageType {
  workflowProgress = "WORKFLOW_PROGRESS",
  childWorkflowsProgress = "CHILD_WORKFLOWS_PROGRESS",
  workflowTaskStart = "WORKFLOW_TASK_START",
  conversations = "CONVERSATIONS",
  refreshContent = "REFRESH_CONTENT",
  deleteContent = "DELETE_CONTENT",
  refreshWorkflow = "REFRESH_WORKFLOW",
  childWorkflowStatusUpdate = "CHILD_WORKFLOW_STATUS_UPDATE",
  usageUpdate = "USAGE_UPDATE",
  workflowMilestone = "WORKFLOW_MILESTONE",
}
