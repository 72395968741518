import { useColorModeValue } from "@chakra-ui/react";
import { getTypeIcon, getViewConfig } from "configs/configMap";
import { useConfigMap, useGetViewConfig } from "hooks";
import type { FunctionComponent } from "react";
import React, { useContext } from "react";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ResearchContext } from "screens/panels/research/ResearchContext";
import { useConversationContext } from "screens/thread/ConversationContext";
import type { ProjectConfigState } from "state/config/reducer";
import { v4 as uuid } from "uuid";
import { MenuItemDefaultStyle } from "screens/common/components/MenuItemDefaultStyle";
import { useMaxAllowedProjectsReached } from "hooks/useStats";

interface Props {
  menuItem: ProjectConfigState;
}

export const AddNewMenuProjectItem: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ menuItem }) => {
  const { onAddNewProjectPanelOpen, setShouldCreateNewCollection, setSelectedCollection } = useAddToCharliContext();
  const { setConversationId } = useConversationContext();
  const { setProjectType } = useContext(ResearchContext);
  const menuHoverName = useColorModeValue("ch-menu-item-light", "ch-menu-item-dark");
  const configMap = useConfigMap();
  const collectionType = useGetViewConfig("collectionType", menuItem.id, configMap);
  const maxAllowedProjects = useGetViewConfig("maxActiveProjectsAllowed", menuItem.id || "", configMap);
  const hasExceededMaxProjects = useMaxAllowedProjectsReached(collectionType || "");

  return (
    <MenuItemDefaultStyle
      key={menuItem.id}
      isDisabled={hasExceededMaxProjects}
      className={`ch-project-${collectionType} ${menuHoverName}`}
      onClick={() => {
        setShouldCreateNewCollection(true);
        setProjectType(collectionType);
        setConversationId(uuid());
        setSelectedCollection(undefined);

        onAddNewProjectPanelOpen();
      }}
      tooltip={
        hasExceededMaxProjects
          ? `You can only have ${maxAllowedProjects} active ${collectionType?.replace(/_/g, " ")} projects at a time`
          : ""
      }
      menuLabel={getViewConfig("title", menuItem.id, configMap)}
      iconName={getTypeIcon(getViewConfig("icon", menuItem.id, configMap))}
    />
  );
};
