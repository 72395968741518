import { prependProxyBaseUrl, request } from "api/request";
import type { Workflow } from "types/workflows/workflow";

const WORKFLOWS_ADMIN_BASE_ENDPOINT = prependProxyBaseUrl("/api/admin-workflows");

export async function getWorkflowsAdmin(options?: {
  token?: string;
  limit?: number;
  filters?: {
    inUsersIds?: string[];
    outUsersIds?: string[];
    inStatus?: string[];
    outStatus?: string[];
    inIntents?: string[];
    collectionId?: string;
  };
}): Promise<{ data: Workflow[]; totalCount: number; nextToken: string | null }> {
  return await request()
    .url(`${WORKFLOWS_ADMIN_BASE_ENDPOINT}/query`)
    .query({
      ...(options && {
        ...(options.token && { token: options.token }),
        ...(options.limit && { limit: options.limit }),
        ...(options.filters && {
          ...(options.filters.collectionId && { collectionId: options.filters.collectionId }),
          ...(options.filters.inUsersIds && { filterInUsers: options.filters.inUsersIds }),
          ...(options.filters.outUsersIds && { filterOutUsers: options.filters.outUsersIds }),
          ...(options.filters.inStatus && { filterInStatus: options.filters.inStatus }),
          ...(options.filters.inIntents && { filterInIntents: options.filters.inIntents }),
        }),
      }),
    })
    .get()
    .json();
}

export async function retryWorkflow(workflowId: string): Promise<void> {
  await request().url(`${WORKFLOWS_ADMIN_BASE_ENDPOINT}/retry`).post({
    workflowId,
  });
}
