import React, { useImperativeHandle, useRef, useEffect } from "react";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import { DashboardModal, useUppy } from "@uppy/react";
import { v4 as uuid } from "uuid";
import { getAccessToken } from "api/auth";
import store from "state/store";
import { deleteFile } from "api/media";
import { track } from "api/analytics";
import { FILE_ADD_FAILED, FILE_UPLOAD_FAILED, FILE_UPLOADED } from "api/analytics/events";
import type { UppyFile } from "types/files";
import { useColorMode } from "@chakra-ui/react";
import { prependProxyBaseUrl } from "../../../../api/request";

const MAX_FILE_SIZE = 31457280; // 30MB
const UPLOAD_FILE_ENDPOINT = prependProxyBaseUrl("/api/media/internal/upload-file");

const getUserEmail = () => {
  const state = store.getState();
  return state.session.user!.email;
};

const getFileExtension = (file: { name: string; type: string }) => {
  const re = /(?:\.([^.]+))?$/;
  return re.exec(file.name)![1] || file.type;
};

interface Props {
  id: string;
  isOpen: boolean;
  onMediaChange: (files: UppyFile[]) => void;
  onClose: () => void;
  onUploadingStatusChange: (isUploadingComplete: boolean) => void;
  onFailedUploadsChange: (didUploadFail: boolean) => void;
}

export type InputBarAttachmentManagerRef = {
  reset: () => void;
  addFile: (args: { name: string; type: string; data: Blob | File }) => void;
};

export const InputBarAttachmentManager = React.forwardRef<InputBarAttachmentManagerRef, Props>(
  (props: Props, ref: React.Ref<InputBarAttachmentManagerRef>) => {
    const { id, isOpen, onMediaChange, onClose, onUploadingStatusChange, onFailedUploadsChange } = props;
    const { colorMode } = useColorMode();

    const filesRef = useRef<UppyFile[]>([]);

    const uppy = useUppy(() => {
      const uppy = Uppy({
        id: id,
        autoProceed: true,
        restrictions: {
          maxFileSize: MAX_FILE_SIZE,
        },
      }).use(XHRUpload, {
        endpoint: UPLOAD_FILE_ENDPOINT,
        metaFields: ["key", "from", "type"],
        fieldName: "file",
        timeout: 0,
        limit: 2,
        headers: { authorization: `Bearer ${getAccessToken()}` },
      });

      uppy.setMeta({ from: getUserEmail() });

      uppy.on("file-added", (file) => {
        const fileId = uuid();

        // Update metadata so that charli-media can accept it
        uppy.setFileMeta(file.id, {
          key: fileId,
          type: getFileExtension(file),
        });

        // Update our ref and inform subscribers
        filesRef.current.push(uppy.getFile(file.id));
        onMediaChange(filesRef.current);
      });

      uppy.on("file-removed", (removedFile, reason) => {
        filesRef.current = filesRef.current.filter((file) => file.meta.key !== removedFile.meta.key);
        onMediaChange(filesRef.current);

        // Note: is async, but is not awaited as should occur in parallel
        if (reason === "removed-by-user") deleteFile(removedFile.meta.key);

        if (uppy.getFiles().length === 0) {
          onUploadingStatusChange(true);
          onFailedUploadsChange(false);
        }
      });

      uppy.on("upload", () => {
        onUploadingStatusChange(false);

        // Retrieve the current token from state at the beginning of each upload in case it's been refreshed since Uppy was initialized
        uppy.getPlugin("XHRUpload").setOptions({ headers: { authorization: `Bearer ${getAccessToken()}` } });
      });

      uppy.on("complete", (result) => {
        onUploadingStatusChange(true);

        result.successful.forEach((file) => {
          track(FILE_UPLOADED, {
            file_size: file.size,
            file_extension: file.extension,
            mime_type: file.type,
          });
        });

        result.failed.forEach((file) => {
          track(FILE_UPLOAD_FAILED, {
            file_size: file.size,
            file_extension: file.extension,
            mime_type: file.type,
          });
        });

        onFailedUploadsChange(result.failed.length > 0);
      });

      uppy.on("restriction-failed", (file) => {
        track(FILE_ADD_FAILED, {
          file_size: file.size,
          file_extension: file.extension,
          mime_type: file.type,
        });
      });

      return uppy;
    });

    useEffect(() => {
      return () => {
        uppy.close();
      };
    }, [uppy]);

    useImperativeHandle(ref, () => ({
      reset: () => {
        uppy.reset();
      },
      addFile: (args: { name: string; type: string; data: Blob }) => {
        uppy.addFile({
          name: args.name,
          type: args.type,
          data: args.data,
          meta: {
            key: uuid(),
            type: getFileExtension({ ...args }),
          },
        });
      },
    }));

    return (
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside
        open={isOpen}
        onRequestClose={onClose}
        proudlyDisplayPoweredByUppy={false}
        showLinkToFileUploadResult={false}
        showRemoveButtonAfterComplete
        showProgressDetails
        closeAfterFinish
        target={document.body}
        theme={colorMode}
      />
    );
  }
);
