import React, { useEffect } from "react";
import { PanelView } from "screens/panels/components/PanelView";
import { PanelStep } from "screens/panels/components/PanelStep";
import { Wizard } from "react-use-wizard";
import { useWorkflowContext } from "../WorkflowContextProvider";
import { TaskJsonPayload } from "screens/content/contentCanvas/TaskJsonPayload";
import { WorkflowStepErrorDetails } from "screens/content/contentCanvas/WorkflowStepErrorDetails";

export const WorkflowPayloadPanel = () => {
  const { onWorkflowPayloadPanelClose, isWorkflowPayloadPanelOpen, setChildWorkflow, isChildWorkflow } = useWorkflowContext();

  useEffect(() => {
    !isWorkflowPayloadPanelOpen && setChildWorkflow("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorkflowPayloadPanelOpen]);

  return (
    <PanelView
      isOpen={isWorkflowPayloadPanelOpen}
      onClose={onWorkflowPayloadPanelClose}
      panelTitle={isChildWorkflow ? "Error Details" : "Task Payload"}>
      <Wizard>
        <PanelStep>{isChildWorkflow ? <WorkflowStepErrorDetails /> : <TaskJsonPayload />}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
