import { Box, FormControl, FormErrorMessage, Input, InputGroup, InputLeftAddon, Stack } from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { getWebsiteTitle } from "api/webScraper";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";

const PROTOCOL_REGEX = /^https?:\/\//i;
const SIZE = "sm";

export const AddToCharliBookmarkForm = () => {
  const [isLinkHttps, setIsLinkHttps] = useState(false);
  const [aliasPlaceholderText, setAliasPlaceholderText] = useState("Add an alias for this link");
  const { requestEntities, setRequestEntities } = useContext(ConversationContext);
  const [linkText, setLinkText] = useState(getRequestValue("link_url", requestEntities) || "");
  const [aliasText, setAliasText] = useState(getRequestValue("alias", requestEntities) || "");

  const updateRequestEntity = (entityName: string, entityValue: string) => {
    updateBatchRequestEntities([{ entity: entityName, value: entityValue, source: "bookmark-inputs" }], setRequestEntities);
  };

  const onLinkTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventText = event.target.value;

    // If link starts with http:// or https://
    if (PROTOCOL_REGEX.test(eventText)) {
      // Strip protocol from link and set isLinkHttps to match
      setIsLinkHttps(eventText.includes("https://"));
      setLinkText(eventText.replace(PROTOCOL_REGEX, ""));
    } else {
      setLinkText(eventText);
    }
  };

  useEffect(() => {
    async function fetchWebscaperAPI() {
      setAliasPlaceholderText("Attempting to generate an alias...");
      const scrapeWebsiteData = await getWebsiteTitle(linkText);
      if (scrapeWebsiteData) {
        setAliasText(scrapeWebsiteData["title"]);
      } else {
        setAliasText("");
        setAliasPlaceholderText("Add an alias for this link");
      }
    }

    fetchWebscaperAPI();
  }, [linkText, setAliasText]);

  useEffect(() => {
    if (aliasText.length > 0) {
      updateRequestEntity("alias", aliasText);
    } else {
      updateRequestEntity("alias", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliasText]);

  useEffect(() => {
    const linkTextWithProtocol = `${isLinkHttps ? "https://" : "http://"}${linkText.trim()}`;
    if (linkTextWithProtocol && linkText.length > 0) {
      updateRequestEntity("link_url", linkTextWithProtocol);
    } else {
      updateRequestEntity("link_url", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkText]);

  return (
    <Stack spacing="1rem" overflowY="auto" flexShrink={1}>
      <Box>
        <FormControl id="first-name" isRequired>
          <SectionHeader title="Link" />
          <InputGroup size={SIZE}>
            <InputLeftAddon
              children={isLinkHttps ? "https://" : "http://"}
              userSelect="none"
              onClick={() => {
                setIsLinkHttps(!isLinkHttps);
              }}
            />
            <Input
              id="bookmark-link-input"
              placeholder="mysite.com"
              rounded="10px"
              value={linkText}
              autoFocus
              onChange={onLinkTextChange}
              onBlur={(e) => {
                setLinkText(e.currentTarget.value);
              }}
            />
          </InputGroup>
          <FormErrorMessage>Form URL is required</FormErrorMessage>
        </FormControl>
      </Box>
      <Box>
        <SectionHeader title="Name" />
        <Input
          id="bookmark-name-input"
          placeholder={aliasPlaceholderText}
          size={SIZE}
          value={aliasText}
          onChange={(ev) => setAliasText(ev.target.value)}
        />
      </Box>
    </Stack>
  );
};
