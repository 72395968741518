import type { Static } from "runtypes";
import { Record, String, Union, Literal } from "runtypes";
import { Value } from "./Value";

export const Preference = Record({
  preferenceType: Union(Literal("user"), Literal("system")),
  entityId: String,
  key: String,
  value: Value,
  createdAt: String,
  updatedAt: String,
});

export type Preference = Static<typeof Preference>;
