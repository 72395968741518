import React from "react";
import type { FunctionComponent } from "react";
import { Icon, Stack, useColorModeValue, Text, Center, Tooltip } from "@chakra-ui/react";
import { MenuItem } from "@szhsin/react-menu";
import type { IconType } from "react-icons";

interface Props {
  menuLabel: string;
  className?: string;
  iconName?: IconType;
  isDisabled?: boolean;
  onClick?: () => void;
  tooltip?: string;
}

export const MenuItemDefaultStyle: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  menuLabel,
  className,
  iconName,
  onClick,
  isDisabled,
  tooltip,
}) => {
  const buttonColor = useColorModeValue("primaryButton.color", "gray.400");
  const buttonTextColor = useColorModeValue("gray.600", "gray.400");
  const textHoverColor = useColorModeValue("primary.hover", "gray.300");

  return (
    <MenuItem style={{ paddingLeft: "0.8rem", paddingRight: "0.8rem" }} className={className} onClick={isDisabled ? undefined : onClick}>
      <Tooltip label={tooltip}>
        <Stack cursor={isDisabled ? "not-allowed" : "pointer"} direction="row" spacing="1rem" opacity={isDisabled ? 0.5 : 1}>
          <Center>
            <Icon as={iconName} color={buttonColor} boxSize="1.1rem" />
          </Center>
          <Text fontSize="sm" color={buttonTextColor} _hover={{ color: textHoverColor }}>
            {menuLabel}
          </Text>
        </Stack>
      </Tooltip>
    </MenuItem>
  );
};
