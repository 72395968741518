import { Stack, useColorModeValue, Divider } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import type { Highlights } from "types/collection";
import { SmallActionConfidenceScoreBadge } from "screens/content/contentCanvas/cell/SmallActionConfidenceScoreBadge";
import { SmallActionVerificationBadge } from "screens/content/contentCanvas/cell/SmallActionVerificationBadge";
import { ContentFilterContext } from "screens/content";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { HighlightItemActions } from "./HighlightItemActions";
import { AnswerStatus } from "types/question";
import { MarkupTextViewer } from "screens/content/contentView/sections/MarkupTextViewer";

interface Props {
  highlightRecord: Highlights;
  topic?: string;
  searchText?: string;
  hideActions?: boolean;
  showDivider?: boolean;
}

export const HighlightsListItem: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  highlightRecord,
  topic,
  searchText,
  hideActions,
  showDivider,
}) => {
  const iconColor = useColorModeValue("gray.500", "gray.400");
  const { onVerifiedAIPanelOpen } = useAddToCharliContext();
  const { setSelectedResult } = useContext(ContentFilterContext);
  const confidenceScoreAdjusted = Math.min(Math.round(highlightRecord.confidence * 100) + 30, 99);

  const handleOnClick = () => {
    setSelectedResult({
      questionText: topic || "",
      resultId: highlightRecord.id,
      resultStatus: AnswerStatus.pinned,
      conversationId: "",
      collectionId: "",
      resultText: highlightRecord.highlight,
      confidence: confidenceScoreAdjusted / 100,
      attributionMetadataIds: [highlightRecord.record_id],
      isQuestion: false,
      verifiedStatus: "verified",
      resultType: "highlight",
      focus: "",
    });

    onVerifiedAIPanelOpen();
  };
  return (
    <Stack>
      <Stack direction="row" justifyContent={"flex-start"} spacing="1rem" width="100%">
        <Stack align={"center"} spacing="1rem">
          <SmallActionVerificationBadge shieldTooltip={`Verified by AI. Click to see the details.`} onClick={() => handleOnClick()} />
          {highlightRecord.confidence && (
            <SmallActionConfidenceScoreBadge
              score={confidenceScoreAdjusted}
              scoreTooltip={`Confidence score: ${confidenceScoreAdjusted}%`}
              scoreBgColor={iconColor}
              onClick={() => handleOnClick()}
            />
          )}
          <HighlightItemActions highlightRecord={highlightRecord} />
        </Stack>
        <MarkupTextViewer searchText={searchText} maxHeight="65rem" className="ch-highlight-value" markupText={highlightRecord.highlight} />
      </Stack>
      {showDivider && <Divider />}
    </Stack>
  );
};
