import type { JSONSchema7 } from "json-schema";

export const DocumentConfigSchema: JSONSchema7 = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "https://charli.ai/schemas/DocumentConfig.json",
  type: "object",
  properties: {
    max_entity_value_length: {
      type: "number",
      description: "",
    },
    extractor_scores: {
      type: "object",
      description: "",
      items: {
        type: "string",
      },
    },
    include_bottom_context: {
      type: ["boolean", "null"],
      description: "",
    },
    flair_extractor: {
      type: "boolean",
      description: "",
    },
    free_text_extractor: {
      type: ["boolean", "null"],
      description: "",
    },
    n_gram_categorical_extractor: {
      type: ["boolean", "null"],
      description: "",
    },
    unpack_address: {
      type: ["boolean", "null"],
      description: "",
    },
    parse_document_hierarchy_text: {
      type: ["boolean", "null"],
      description: "",
    },
    confidence_threshold_table: {
      type: ["number", "null"],
      description: "",
    },
    confidence_threshold_text_hierarchy: {
      type: ["number", "null"],
      description: "",
    },
    max_same_depth_elements: {
      type: ["number", "null"],
      description: "",
    },
    iterate_table_hierarchy: {
      type: ["boolean", "null"],
      description: "",
    },
    iterate_list: {
      type: ["boolean", "null"],
      description: "",
    },
    num_title_levels: {
      type: ["number", "null"],
      description: "",
    },
    parse_children_if_title_matched: {
      type: ["boolean", "null"],
      description: "",
    },
  },
  required: ["max_entity_value_length", "extractor_scores", "flair_extractor"],
};
