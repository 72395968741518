import React from "react";
import type { FunctionComponent } from "react";
import { Tooltip, Box, Image } from "@chakra-ui/react";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import verifiedShieldGray from "screens/common/static/misc/verified_shield_gray.svg";

interface Props {
  shieldColor?: "blue" | "gray";
  shieldTooltip?: string;
  boxSize?: string;
  onClick?: () => void;
  className?: string;
}

export const SmallActionVerificationBadge: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  shieldTooltip,
  shieldColor = "blue",
  onClick,
  className,
}) => {
  return (
    <Tooltip aria-label="" label={shieldTooltip} placement="left" hasArrow>
      <Box
        className={`ch-shield-verification ${className}`}
        onClick={onClick}
        cursor={onClick ? "pointer" : "default"}
        minWidth={["1.5rem", "2.3rem", "2.3rem"]}>
        <Image src={shieldColor === "blue" ? verifiedShield : verifiedShieldGray} boxSize={["1.5rem", "2.3rem", "2.3rem"]} />
      </Box>
    </Tooltip>
  );
};
