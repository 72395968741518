import React, { useMemo, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { ApiResponseError } from "api/apiResponseError";
import { Select } from "chakra-react-select";
import { Stack, Text, IconButton, FormControl, Box, Button, Icon, useColorModeValue, SimpleGrid, FormLabel, Input } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import { useIsUserSearching, useUsersSearch } from "hooks/useUsers";
import debounce from "lodash/debounce";
import type { PayloadAction } from "@reduxjs/toolkit";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import type { FormValues } from "../modals/EntitlementConfigUpsertModal";
import { RenderIfHasEntitlements } from "screens/common/components";

const MIN_SEARCH_LENGTH = 3;

interface IProps {
  onAdd: (userId: string) => Promise<void | { error?: unknown } | PayloadAction>;
  onDelete: (userId: string) => Promise<void | { error?: unknown } | PayloadAction>;
  canDelete?: boolean;
}

export const EntitlementUsersFieldArrayInner = (props: IProps) => {
  const { control } = useFormContext<FormValues>();
  const { canDelete = true } = props;

  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const commonButtonProps = useButtonProps("sm", "secondary");
  const {
    fields: unorderedFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "users",
  });
  const isLoading = useIsUserSearching();

  const fields = useMemo(() => orderBy(unorderedFields, "label"), [unorderedFields]);
  const fieldsIndexes = useMemo(() => new Map(unorderedFields.map(({ id }, index) => [id, index])), [unorderedFields]);

  const [searchUser, setSearchUser] = useState("");
  const [selectedUserId, setSelectedUserId] = useState<{ id: null | string; label: null | string }>({
    id: null,
    label: null,
  });
  const usersSearch = useUsersSearch(searchUser.length < MIN_SEARCH_LENGTH ? "" : searchUser).map((u) => ({
    value: u.id,
    label: `${(u.firstName ?? "")
      .concat(" ")
      .concat(u.lastName ?? "")
      .trim()} (${u.email})`,
  }));

  const filteredUsers = useMemo(() => {
    const hasFields = groupBy(fields, "value");

    return usersSearch.filter(({ value }) => !hasFields[value]);
  }, [usersSearch, fields]);

  const users = useMemo(() => {
    return orderBy(filteredUsers, "label");
  }, [filteredUsers]);

  const changedUserSearch = debounce((target) => {
    setSearchUser(target);
  }, 500);

  const cleanUser = () => {
    setSearchUser("");
    setSelectedUserId({ id: null, label: null });
  };

  const handleAddUser = async () => {
    if (selectedUserId.id) {
      if (!ApiResponseError.guard(await props.onAdd(selectedUserId.id))) {
        append({ value: selectedUserId.id, label: selectedUserId.label || "" });
      }

      cleanUser();
    }
  };

  const selectedUser = (event) => {
    if (event === null) {
      cleanUser();
    } else {
      setSelectedUserId({ id: event.value, label: event.label });
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent={"space-between"} width="100%" spacing={"1rem"}>
        <Box width="100%">
          <Select
            className="ch-multi-select"
            useBasicStyles
            isLoading={isLoading}
            selectedOptionStyle="check"
            options={users}
            onInputChange={changedUserSearch}
            onChange={selectedUser}
            isClearable
            value={{ value: selectedUserId.id, label: selectedUserId.label }}
          />
        </Box>
        <Button width="8rem" {...commonButtonProps} onClick={handleAddUser} isLoading={isLoading} disabled={!selectedUserId.id}>
          Add User
        </Button>
      </Stack>

      <SimpleGrid columns={3} spacingX="1rem" spacingY="1rem" pt="1rem">
        {fields.map((field, index) => (
          <Box borderWidth="2px" borderRadius="lg" px="1rem" py=".5rem" key={field.id}>
            <Stack direction="row" justifyContent={"space-between"} alignItems="center">
              <FormControl>
                <Controller
                  render={({ field }) => (
                    <Input {...field} type="hidden" mr="0.5rem" fontSize="md" boxShadow="none" defaultValue={field.value} required />
                  )}
                  name={`users.${index}.value`}
                  control={control}
                />
                <Text>{field.label}</Text>
              </FormControl>
              {canDelete && (
                <IconButton
                  onClick={async () => {
                    if (!ApiResponseError.guard(await props.onDelete(field.value))) {
                      const fieldIndex = fieldsIndexes.get(field.id);
                      remove(fieldIndex);
                    }
                  }}
                  aria-label="Delete"
                  backgroundColor="unset"
                  icon={<Icon as={AiOutlineDelete} color={buttonColor} boxSize="1.5rem" _hover={{ color: buttonHoverColor }} />}
                  size="lg"
                  _hover={{ backgroundColor: "unset" }}
                />
              )}
            </Stack>
          </Box>
        ))}
      </SimpleGrid>
    </>
  );
};

export const EntitlementUsersFieldArray = (props: IProps) => {
  const bgColor = useColorModeValue("white!important", "gray.700!important");

  return (
    <Box backgroundColor={bgColor}>
      <FormControl>
        <FormLabel fontSize="sm">Users</FormLabel>
        <RenderIfHasEntitlements entitlement={["view_users", "manage_entitlements"]}>
          <EntitlementUsersFieldArrayInner {...props} />
        </RenderIfHasEntitlements>
      </FormControl>
    </Box>
  );
};
