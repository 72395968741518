import type { Static } from "runtypes";
import { String, Array } from "runtypes";

/*
  The string must contain only:
  - lowercase characters or numbers
  - underscore between words
*/
export const KeyListResponse = Array(String.withConstraint((s) => /^[a-z0-9]+(?:_+[a-z0-9]+)*$/.test(s)));
export type KeyListResponse = Static<typeof KeyListResponse>;
