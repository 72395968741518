import { Box, useColorModeValue, Text } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useEffect } from "react";
import type { Highlights } from "types/collection";
import { ContentCheckbox } from "screens/common/components/ContentCheckbox";
import { useEntitlements, useItemSelector } from "hooks";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import ECHighlighter from "react-ec-highlighter";
import { HighlightItemActions } from "./HighlightItemActions";
import { MarkdownItemView } from "screens/markdown/MarkdownItemView";

interface Props {
  highlightRecord: Highlights;
  textTruncated?: boolean;
  textOnlyView?: boolean;
  showRecordLinkBtn?: boolean;
  showActionMenu?: boolean;
  onClose?: () => void;
  searchPhrase?: string;
  maxLines?: number;
}

export const HighlightItem: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  highlightRecord,
  textTruncated = false,
  textOnlyView = false,
  showRecordLinkBtn = false,
  showActionMenu = true,
  onClose,
  searchPhrase,
  maxLines = 4,
}) => {
  const secondaryTextColor = useColorModeValue("primary.darkGray", "gray.400");
  const borderColor = useColorModeValue("gray.300", "gray.700");
  const { selectedItems, addSelectedItem, removeSelectedItem } = useItemSelector();
  const { isHighlightsPanelOpen } = useAddToCharliContext();
  const { ui_share_highlights: isShareHighlights } = useEntitlements();

  useEffect(() => {
    if (isHighlightsPanelOpen) {
      !textTruncated && addSelectedItem({ id: highlightRecord.id, type: "highlight_id", value: highlightRecord.highlight });
    } else {
      !textTruncated && removeSelectedItem({ id: highlightRecord.id });
    }
  }, [addSelectedItem, highlightRecord.highlight, highlightRecord.id, isHighlightsPanelOpen, textTruncated, removeSelectedItem]);

  return (
    <Box
      textAlign={"left"}
      id={highlightRecord.id}
      position="relative"
      borderRadius={textTruncated || textOnlyView ? "0" : "xl"}
      boxShadow={textTruncated || textOnlyView ? "0" : "md"}
      p={textTruncated || textOnlyView ? "0" : "1rem"}
      borderWidth={textTruncated || textOnlyView ? "0" : "1px"}
      borderColor={borderColor}>
      {highlightRecord.id && !textTruncated && !textOnlyView && isShareHighlights && (
        <ContentCheckbox
          isChecked={!!selectedItems[highlightRecord.id]}
          onClick={() => {
            if (selectedItems[highlightRecord.id]) {
              removeSelectedItem({ id: highlightRecord.id });
            } else {
              addSelectedItem({ id: highlightRecord.id, type: "highlight_id", value: highlightRecord.highlight });
            }
          }}
        />
      )}
      {textTruncated ? (
        <Box fontWeight="normal" fontSize="xs" color={secondaryTextColor}>
          <MarkdownItemView markdown={highlightRecord.highlight} />
        </Box>
      ) : (
        <Text fontSize="sm" color={secondaryTextColor} fontWeight="normal">
          {searchPhrase && searchPhrase.length > 0 && highlightRecord.highlight ? (
            <ECHighlighter searchPhrase={searchPhrase} text={highlightRecord.highlight} />
          ) : (
            highlightRecord.highlight
          )}
        </Text>
      )}
      {showActionMenu && <HighlightItemActions highlightRecord={highlightRecord} />}
    </Box>
  );
};
