import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMemberOrganizations as getMemberOrganizationsAPI } from "api/organizations";
import type { RootState } from "state/rootReducer";

export const getMemberOrganizations = createAsyncThunk("memberOrganizations/download-all", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;

  if (state.session.user?.id) {
    return await getMemberOrganizationsAPI(state.session.user.id);
  } else {
    return thunkAPI.rejectWithValue("Tried to get user organizations but no user id was available");
  }
});
