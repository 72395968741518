import {
  Badge,
  Box,
  Button,
  Stack,
  Text,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import type { UserConfig } from "types/config";
import { useDispatch } from "react-redux";
import { bulkUpsertProjectConfig } from "state/config/operations";
import { CodeMergePanel, JsonEditorPanel } from "screens/common/components";
import { useButtonProps } from "hooks";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface UpdateProjectTypeModalProps {
  onClose: () => void;
  projectConfigs: UserConfig[];
  importedProjectConfigs: UserConfig[];
}

export const BulkUpsertProjectConfigs = (props: UpdateProjectTypeModalProps) => {
  const { onClose, importedProjectConfigs, projectConfigs } = props;
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const dispatch = useDispatch();
  const commonButtonProps = useButtonProps("sm", "secondary");
  const [isOpenPanel, setIsOpenPanel] = React.useState<"merge" | "config" | null>(null);
  const [selectedProjectConfig, setSelectedProjectConfig] = React.useState<{ imported: UserConfig; existing?: UserConfig } | null>(null);

  const projectsConfigsMap: Record<string, UserConfig> = useMemo(() => {
    return projectConfigs.reduce(
      (acc: Record<string, UserConfig>, config) => ({ ...acc, ...(config.route && { [config.route]: config }) }),
      {}
    );
  }, [projectConfigs]);

  const onClosePanel = () => {
    setTimeout(() => {
      setIsOpenPanel(null);
      setSelectedProjectConfig(null);
    });
  };

  const renderConfigStatus = useCallback((isNew: boolean) => {
    return <Badge colorScheme={isNew ? "green" : "blue"}>{isNew ? "New" : "Changed"}</Badge>;
  }, []);

  return (
    <PanelView isOpen onClose={onClose} panelTitle={"Import Project Configs"}>
      <Wizard>
        <PanelStep
          onNextLabel="Update Projects"
          onSubmit={() => {
            dispatch(bulkUpsertProjectConfig({ configs: importedProjectConfigs.map((config) => ({ config })) }));
            onClose();
          }}>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Intent</Th>
                  <Th>Route</Th>
                  <Th>Status</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {importedProjectConfigs.map((config, index) => (
                  <Tr key={index}>
                    <Td>{config.intent}</Td>
                    <Td>{config.route}</Td>
                    <Td>{config.route && renderConfigStatus(projectsConfigsMap[config.route] === undefined)}</Td>
                    <Td>
                      <Menu>
                        <MenuButton {...commonButtonProps} as={Button} rightIcon={<ChevronDownIcon />}>
                          Actions
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              setTimeout(() => {
                                setSelectedProjectConfig({
                                  imported: config,
                                });

                                setIsOpenPanel("config");
                              });
                            }}>
                            View config
                          </MenuItem>
                          <MenuItem
                            disabled={!config.route || !projectsConfigsMap[config.route]}
                            onClick={() => {
                              setTimeout(() => {
                                if (!config.route) {
                                  return;
                                }

                                setSelectedProjectConfig({
                                  imported: config,
                                  existing: projectsConfigsMap[config.route],
                                });

                                setIsOpenPanel("merge");
                              });
                            }}>
                            View merge diff
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Box my=".5rem" height={`calc(100vh - 10rem)`}>
            <Stack spacing=".5rem" mt="1rem" maxWidth={"80%"}>
              <Stack direction="row">
                <Box>
                  <Badge colorScheme={"red"}>WARNING</Badge>
                </Box>
                <Text fontSize="sm" color={textColor}>
                  You're entitled to edit ALL project configurations. Please verify your changes before saving them.
                </Text>
              </Stack>
            </Stack>
          </Box>
          {selectedProjectConfig?.existing && selectedProjectConfig?.imported && isOpenPanel === "merge" && (
            <CodeMergePanel
              panelTitle={`Merge ${selectedProjectConfig.imported.route} project config`}
              onClose={() => onClosePanel()}
              isLoading={false}
              isOpen
              source={selectedProjectConfig.existing}
              target={selectedProjectConfig.imported}
            />
          )}

          {selectedProjectConfig?.imported && isOpenPanel === "config" && (
            <JsonEditorPanel
              panelTitle={`View ${selectedProjectConfig.imported.route} project config`}
              height="100%"
              isOpen
              value={JSON.stringify(selectedProjectConfig.imported, null, 2)}
              onClose={() => onClosePanel()}
            />
          )}
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
