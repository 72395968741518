import { useCallback, useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { downloadConfiguredWorkflows } from "state/configuredWorkflow/operations";
import type { RootState } from "state/rootReducer";
import type { ConfiguredWorkflow } from "types/configuredWorkflows";
import { useEntitlements } from "./useEntitlements";

export const getActiveConfiguredWorkflowWithIntent = (userIntent: string, configuredWorkflows: ConfiguredWorkflow[]) => {
  return configuredWorkflows.find((cw) => cw.state === "active" && cw.userIntent === userIntent);
};

export function useConfiguredWorkflowsList() {
  return useSelector(
    (state: RootState) => state.configuredWorkflow.order.map((id) => state.configuredWorkflow.configuredWorkflows[id]),
    shallowEqual
  );
}

export function useGetWorkflowConfigByIntent() {
  const configuredWorkflows = useConfiguredWorkflowsList();
  const workflowConfigsMap = useMemo(
    () =>
      configuredWorkflows.reduce(
        (acc, cw) => ({ ...acc, ...(cw.state === "active" ? { [cw.userIntent]: cw } : {}) }),
        {} as Record<string, ConfiguredWorkflow>
      ),
    [configuredWorkflows]
  );

  return useCallback(
    (intent: string): ConfiguredWorkflow | undefined => {
      return workflowConfigsMap[intent];
    },
    [workflowConfigsMap]
  );
}

export function useConfiguredWorkflows() {
  const configuredWorkflows = useConfiguredWorkflowsList();
  const dispatch = useDispatch();
  const entitlements = useEntitlements();
  const { manage_configured_workflows_read: hasAdminConfiguredWorkflowRead } = entitlements;

  useEffect(() => {
    if (!hasAdminConfiguredWorkflowRead) {
      return;
    }

    dispatch(downloadConfiguredWorkflows());
  }, [dispatch, hasAdminConfiguredWorkflowRead]);

  return configuredWorkflows;
}

export function useConfiguredWorkflowsMostUsedEntities(limit = 20): string[] {
  const configuredWorkflows = useConfiguredWorkflowsList();

  return useMemo(() => {
    const entitiesCountMap = configuredWorkflows.reduce((acc, cw) => {
      if (cw.state !== "active") {
        return acc;
      }

      return cw.config.checkpoints.reduce((acc, cp) => {
        if (cp.type === "execute_intent") {
          cp.entitiesToInject?.forEach(({ entity }) => {
            acc[entity] = (acc[entity] || 0) + 1;
          });

          cp.entitiesToRename?.forEach(({ entityName, renameTo }) => {
            acc[entityName] = (acc[entityName] || 0) + 1;
            acc[renameTo] = (acc[renameTo] || 0) + 1;
          });

          cp.onlyIncludeTheseEntities?.forEach((entity) => {
            acc[entity] = (acc[entity] || 0) + 1;
          });

          cp.requirements?.forEach(({ entity }) => {
            acc[entity] = (acc[entity] || 0) + 1;
          });
        } else if (cp.type === "create_entities") {
          cp.entitiesToInject?.forEach(({ entity }) => {
            acc[entity] = (acc[entity] || 0) + 1;
          });
        }

        return acc;
      }, acc);
    }, {} as Record<string, number>);

    return Object.entries(entitiesCountMap)
      .sort(([, countA], [, countB]) => countB - countA)
      .slice(0, limit)
      .map(([entity]) => entity);
  }, [configuredWorkflows, limit]);
}

export function useConfiguredWorkflow(id: string | null | undefined): ConfiguredWorkflow | undefined {
  const configuredWorkflow = useSelector(
    (state: RootState) => (id ? state.configuredWorkflow.configuredWorkflows[id] : undefined),
    shallowEqual
  );

  return configuredWorkflow;
}

export function useLoadingConfiguredWorkflows(): boolean {
  return useSelector((state: RootState) => state.configuredWorkflow.isLoading);
}

export function useLoadingConfiguredWorkflow(id: string | undefined): boolean {
  return useSelector((state: RootState) =>
    id && state.configuredWorkflow.isLoadingWorkflowConfig ? state.configuredWorkflow.isLoadingWorkflowConfig[id] || false : false
  );
}

export function useActiveConfiguredWorkflowWithIntent(userIntent: string | undefined): ConfiguredWorkflow | undefined {
  const configuredWorkflows = useConfiguredWorkflowsList();

  return useMemo(
    () => (!userIntent ? undefined : getActiveConfiguredWorkflowWithIntent(userIntent, configuredWorkflows)),
    [configuredWorkflows, userIntent]
  );
}
