import type { FunctionComponent } from "react";
import React, { createContext, useMemo, useState } from "react";
import type { WorkflowTaskStatus } from "types/workflows/workflow";
import { useWorkflowsMap } from "hooks/useWorkflows";
import { ManualTags } from "screens/collection/components/utils";
import type { CollectionWithAdditionalProps } from "types/collection";
import { parseISO } from "date-fns";

export const CollectionsFilterContext = createContext({
  selectedTags: [] as string[],
  setSelectedTags: (tags: string[]): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  searchText: "",
  setSearchText: (text: string): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  endDate: new Date() as Date | null,
  setEndDate: (date: Date | null): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  startDate: new Date() as Date | null,
  setStartDate: (date: Date | null): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  hasReportFilter: undefined as boolean | undefined,
  setHasReportFilter: (value: boolean | undefined): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  collectionCount: 10,
  setCollectionCount: (() => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  }) as React.Dispatch<React.SetStateAction<number>>,
  totalCollectionCount: 0,
  filteredCollections: [] as CollectionWithAdditionalProps[],
  areImagesLoading: true as boolean,
  setAreImagesLoading: (value: boolean): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  selectedStates: [] as { label: string; value: string }[],
  setSelectedStates: (states: { label: string; value: string }[]): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  selectedWorkflowIds: [] as string[],
  setSelectedWorkflowIds: (ids: string[]): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
});

interface Props {
  collections?: CollectionWithAdditionalProps[];
}

export const CollectionsFilterContextProvider: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  children,
  collections,
}) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [hasReportFilter, setHasReportFilter] = useState<boolean | undefined>(undefined);
  const [areImagesLoading, setAreImagesLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [collectionCount, setCollectionCount] = useState<number>(15);
  const [selectedStates, setSelectedStates] = useState<{ label: string; value: string }[]>([]);
  const [selectedWorkflowIds, setSelectedWorkflowIds] = useState<string[]>([]);
  const workflowsById = useWorkflowsMap();

  const passesFilters = (collection: CollectionWithAdditionalProps) => {
    const tags = collection.metadata.autoTags.concat(collection.metadata.manualTags);
    const workflowIds: string[] | undefined = collection.workflowIds!;

    const isMatchingSelectedTags = () => {
      if (selectedTags.length === 0) return true;
      return selectedTags.every((item) => tags.includes(item));
    };

    const isSelectedWorkflowIds = () => {
      if (selectedWorkflowIds.length === 0) return true;
      return selectedWorkflowIds.some((item) => workflowIds && workflowIds.includes(item));
    };

    const isSelectedStates = () => {
      if (!workflowIds) return true;
      const selectedWorkflows = workflowIds.map((id) => workflowsById[id]).filter(Boolean);

      if (selectedWorkflows.length === 0 || selectedStates.length === 0) return true;

      const updatedSelectedStates = selectedStates.map((state) => {
        if (state.value === "incomplete") {
          return "failed";
        }
        return state.value;
      });

      const workflowStatuses = selectedWorkflows.map((workflow) => workflow.status);
      return updatedSelectedStates.some((stateValue) => workflowStatuses.includes(stateValue as WorkflowTaskStatus));
    };

    const isReport = () => hasReportFilter === undefined || (hasReportFilter && tags.includes(ManualTags.projectOutput));

    const isMatchingSearchText = () => {
      if (!searchText || searchText.trim() === "") return true;
      const searchTokens = searchText.toLowerCase().trim().split(" ");

      const cellTokens = [collection.name];
      return searchTokens.every((searchToken) => cellTokens.join(" ").toLowerCase().includes(searchToken));
    };

    const isMatchingDateRange = () => {
      if (!startDate && !endDate) return true;

      const createdTime = collection.metadata.createdTime ? parseISO(collection.metadata.createdTime).getTime() : undefined;
      if (startDate && createdTime && createdTime < startDate.getTime()) return false;
      if (endDate && createdTime && createdTime > endDate.getTime()) return false;
      return true;
    };

    return (
      isMatchingSelectedTags() &&
      isReport() &&
      isMatchingSearchText() &&
      isMatchingDateRange() &&
      isSelectedStates() &&
      isSelectedWorkflowIds()
    );
  };

  const filteredCollections = useMemo(() => {
    if (!collections) return [];

    return collections.reduce((accum, cell) => {
      if (passesFilters(cell)) accum.push(cell);
      return accum;
    }, [] as typeof collections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections, selectedTags, hasReportFilter, searchText, startDate, endDate, selectedStates, selectedWorkflowIds]);

  return (
    <CollectionsFilterContext.Provider
      value={{
        selectedTags,
        setSelectedTags,
        searchText,
        setSearchText,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        filteredCollections,
        hasReportFilter,
        setHasReportFilter,
        collectionCount,
        setCollectionCount,
        totalCollectionCount: collections ? collections.length : 0,
        areImagesLoading,
        setAreImagesLoading,
        selectedStates,
        setSelectedStates,
        selectedWorkflowIds,
        setSelectedWorkflowIds,
      }}>
      {children}
    </CollectionsFilterContext.Provider>
  );
};
