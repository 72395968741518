import React, { useContext, useMemo, useRef, useEffect } from "react";
import { CollectionsFilterContext } from "./CollectionsFilterContext";
import { TagFilter } from "./TagFilter";

export const CollectionsFilterTagOptions = () => {
  const { selectedTags, setSelectedTags, filteredCollections } = useContext(CollectionsFilterContext);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const distinctTags = useMemo(() => {
    const allTags = filteredCollections.reduce((accum, collection) => {
      const tags = collection.metadata.autoTags || [];
      tags.forEach((tag) => {
        if (accum[tag]) {
          accum[tag] = accum[tag] + 1;
        } else {
          accum[tag] = 1;
        }
      });

      return accum;
    }, {} as { [key: string]: number });

    return Object.entries(allTags)
      .map((tagPair) => {
        return { name: tagPair[0], occurrences: tagPair[1] };
      })
      .sort((a, b) => {
        return b.occurrences - a.occurrences;
      });
  }, [filteredCollections]);

  const presentedTags = useMemo(() => {
    // Concatenate active tag filters that don't match the filter being applied, so that they can be unselected if needed
    return distinctTags.concat(
      selectedTags
        .filter((tag) => !distinctTags.some((distinctTag) => tag === distinctTag.name))
        .map((tagName) => {
          return { name: tagName, occurrences: 0 };
        })
    );
  }, [distinctTags, selectedTags]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <TagFilter selectedTags={selectedTags} setSelectedTags={setSelectedTags} presentedTags={presentedTags} distinctTags={distinctTags} />
  );
};
