import React, { useEffect } from "react";
import { PanelView } from "screens/panels/components/PanelView";
import { PanelStep } from "screens/panels/components/PanelStep";
import { Wizard } from "react-use-wizard";
import { WorkflowSummary } from "screens/common/components/WorkflowSummary";
import { Box, Stack } from "@chakra-ui/react";
import { TabTitle } from "screens/common/components/TabTitle";
import type { Workflow } from "types/workflows/workflow";
import { TypingIndicator } from "./cells/components";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import { getChildWorkflows } from "api/workflows";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  workflow?: Workflow;
  isContentViewOpen: boolean;
  isLoadingWorkflow: boolean;
  isMessageId: string;
}

export const WorkflowPanel = ({ isOpen, workflow, isLoadingWorkflow, isMessageId, onClose }: Props) => {
  const { setChildWorkflows, setIsLoadingChildWorkflows } = useWorkflowContext();

  useEffect(() => {
    const id = workflow?.id;
    if (isOpen && id) {
      setIsLoadingChildWorkflows(true);
      getChildWorkflows(id)
        .then((childWorkflowsResponse) => {
          setChildWorkflows(childWorkflowsResponse);
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoadingChildWorkflows(false));
    }
  }, [isOpen, setChildWorkflows, setIsLoadingChildWorkflows, workflow?.id]);

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`Workflow Details ${workflow?.title ? ": " + workflow.title : ""}`}>
      <Wizard>
        <PanelStep>
          <TabTitle title={`Charli > Workflow Detail`} />
          <Stack>
            {isLoadingWorkflow ? (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box flex="1" textAlign="left" fontSize="sm" fontWeight="semibold">
                  Loading workflow...
                </Box>
                <TypingIndicator size="small" />
              </Box>
            ) : workflow ? (
              <WorkflowSummary onClose={onClose} workflowSummary={workflow} id={isMessageId} />
            ) : (
              <Box flex="1" textAlign="left" fontSize="sm" fontWeight="semibold">
                Workflow not found.
              </Box>
            )}
          </Stack>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
