import { Flex, FormControl, FormErrorMessage, Icon, IconButton, Input, useColorModeValue } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import React, { useCallback } from "react";
import { BiSave } from "react-icons/bi";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { useButtonProps } from "hooks";

export function FormTextField({
  errors = {},
  control,
  label,
  keyField,
  atomic = false,
  disabled = false,
  isRequired = false,
  hasChanged = true,
  onUpdate,
}) {
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const tileBorderColor = useColorModeValue("gray.400", "gray.600");
  const secondaryButtonStyle = useButtonProps("sm", "secondary");

  const renderErrors = useCallback(() => {
    if (!errors[keyField]) {
      return <React.Fragment />;
    }

    const { type } = errors[keyField];

    switch (type) {
      case "required":
        return <FormErrorMessage>This field is required.</FormErrorMessage>;
      case "minLength": {
        const { count } = errors[keyField];
        return <FormErrorMessage>This field requires at least {count} characters.</FormErrorMessage>;
      }

      default:
        return <React.Fragment />;
    }
  }, [errors, keyField]);

  return (
    <FormControl isInvalid={errors[keyField] !== undefined} pb="1px">
      <SectionHeader title={label} />
      <Flex>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              color={textColor}
              size="sm"
              id={keyField}
              type="text"
              borderColor={errors[keyField] ? "red.500" : "gray.200"}
              mb="0.5rem"
              fontSize="md"
              boxShadow="none"
              disabled={disabled}
              isRequired={isRequired}
            />
          )}
          name={keyField}
          control={control}
        />
        {atomic && (
          <IconButton
            {...secondaryButtonStyle}
            color={tileBorderColor}
            disabled={!hasChanged}
            backgroundColor="transparent"
            aria-label="Save"
            size="sm"
            ml="1rem"
            icon={<Icon as={BiSave} color={tileBorderColor} boxSize="1.5rem" />}
            onClick={onUpdate}
          />
        )}
      </Flex>
      {renderErrors()}
    </FormControl>
  );
}
