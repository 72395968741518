import { prependProxyBaseUrl, request } from "api/request";
import type { SubscriptionDetails } from "./models/SubscriptionDetails";
import type { PlanName } from "./models/PlanName";
import type { CustomerUpdateDetails } from "./models/CustomerUpdateDetails";

const PROXY_PREFIX = prependProxyBaseUrl("/api/billing-plan");

const USER_SUBSCRIPTION_ENDPOINT = (userId: string) => `${PROXY_PREFIX}/subscription/${userId}`;
const CUSTOMER_UPDATE_ENDPOINT = (userId: string) => `${PROXY_PREFIX}/subscription/customer/${userId}`;

const SECURITY_TOKEN_ENDPOINT = (userId: string) => `${PROXY_PREFIX}/subscriptions/tokens/${userId}`;

const api = (url: string, injectToken = false) => request(injectToken, false).url(url);

export async function getUserSubscription(userId: string, accessToken?: string): Promise<SubscriptionDetails> {
  if (accessToken) {
    return await api(USER_SUBSCRIPTION_ENDPOINT(userId)).auth(`Bearer ${accessToken}`).get().json<SubscriptionDetails>();
  }

  return await request().url(USER_SUBSCRIPTION_ENDPOINT(userId)).get().json<SubscriptionDetails>();
}

export async function subscribeToPlan(
  userId: string,
  planName: PlanName | "",
  promoCode?: string,
  creditCardDetails?: {
    token?: string;
    gatewayHandle?: string;
  }
): Promise<SubscriptionDetails> {
  return await request()
    .url(USER_SUBSCRIPTION_ENDPOINT(userId))
    .post({
      planName,
      couponCode: promoCode,
      creditCardDetails,
    })
    .json<SubscriptionDetails>();
}

export async function updateCustomerDetails(userId: string, details: CustomerUpdateDetails): Promise<void> {
  await request().url(CUSTOMER_UPDATE_ENDPOINT(userId)).put(details);
}

export async function getCreditCardSecurityToken(userId: string): Promise<string> {
  const response = await request().url(SECURITY_TOKEN_ENDPOINT(userId)).get().json();

  return response.token;
}
