import React, { useEffect } from "react";
import { Box, Button, SimpleGrid, Stack, useToast, Text, useColorModeValue } from "@chakra-ui/react";
import { FormTextField } from "screens/common/components/FormTextField";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useAppDispatch, useButtonProps, useOrganization, useOrganizationEntitlements, useOrganizationIsLoading } from "hooks";
import { FormProvider, useForm } from "react-hook-form";
import { Wizard } from "react-use-wizard";
import { useBillingPlanEntitlements, useBillingPlans } from "hooks/useBillingPlans";
import {
  addOrganizationMembers,
  createOrganization,
  removeMemberFromOrganization,
  updateMemberRole,
  updateOrganization,
} from "state/organization/operations";
import { isMember, isMembers, isOrganization } from "../utils/typeGuards";
import type { Role } from "types/organization/Role";
import { FormSelectField } from "screens/common/components/FormSelectField";
import { OrganizationUsersFieldArray } from "./OrganizationUsersFieldArray";
import { RenderIfHasEntitlements, EntitlementsList } from "screens/common/components";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";

export interface OrganizationFormValues {
  id?: string;
  name: string;
  planCode: string;
  members: { label: string; value: string; id: string; role: Role }[];
}

type OrganizationFormType = keyof OrganizationFormValues;

interface IProps {
  id: string | null;
  isOpen: boolean;
  hasWriteOrganizationEntitlement: boolean;
  onClose: () => void;
}

export const UpsertOrganizationModal = (props: IProps) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const commonButtonProps = useButtonProps("sm", "primary");
  const { id, isOpen, hasWriteOrganizationEntitlement, onClose } = props;
  const userOrganizationsMap = useSelector((state: RootState) => state.memberOrganizations.memberOrganizations);

  const organization = useOrganization(id);
  const { entitlements, isLoading: isLoadingEntitlements } = useOrganizationEntitlements(organization ? organization.id : null);
  const { entitlements: billingPlanEntitlements, isLoading: isLoadingBillingPlanEntitlements } = useBillingPlanEntitlements(
    organization ? organization.planCode : null
  );
  const titleColor = useColorModeValue("gray.900", "gray.500");
  const billingPlans = useBillingPlans();
  const isLoading = useOrganizationIsLoading();

  const isUpdate = !!id;

  const methods = useForm<OrganizationFormValues>({
    defaultValues: organization
      ? {
          id: organization.id,
          name: organization.name,
          planCode: organization.planCode,
          members: organization.members
            ? organization.members.map((member) => ({
                label: `${member.userName} (${member.email || "n/a"})`,
                value: member.userId,
                role: member.role,
              }))
            : [],
        }
      : {
          name: "",
          planCode: "",
          members: [],
        },
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
    control,
  } = methods;

  const watchEditableFields = watch(["name", "planCode"]);

  const onSubmit = async (formValues: OrganizationFormValues) => {
    try {
      const { name, planCode, members } = formValues;
      const orgResponse = await dispatch(createOrganization({ name, planCode }));

      if (!isOrganization(orgResponse.payload)) {
        const errorMessage = (orgResponse as { error: { message: string } }).error?.message;

        toast({
          title: "Organization error",
          description: errorMessage,
          status: "error",
          duration: 10000,
          isClosable: true,
        });

        return;
      }

      if (members && members.length > 0) {
        const membersResp = await dispatch(
          addOrganizationMembers({
            organizationId: orgResponse.payload.id,
            members: members.map((member) => ({ userId: member.value, role: member.role ?? "member" })),
          })
        );

        if (!isMembers(membersResp.payload)) {
          const errorMessage = (orgResponse as { error: { message: string } }).error?.message;

          toast({
            title: "Organization error",
            description: errorMessage,
            status: "error",
            duration: 10000,
            isClosable: true,
          });

          return;
        }
      }

      toast({
        title: "Organization",
        description: `Successfully ${isUpdate ? "updated" : "created"}.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      onClose();
      console.error(error);
    }
  };

  const onChangeUserRole = async (userId: string, role: Role) => {
    if (!isUpdate) {
      return false;
    }

    const response = await dispatch(updateMemberRole({ organizationId: id, memberId: userId, role }));

    if (isMember(response.payload)) {
      return true;
    } else {
      return false;
    }
  };

  const onUpdateOrganizationField = async (fieldName: OrganizationFormType) => {
    if (!organization) {
      return;
    }

    const { [fieldName]: fieldValue } = getValues();

    dispatch(updateOrganization({ organizationId: organization.id, payload: { [fieldName]: fieldValue } }));
  };

  const hasFieldChanged = (formField: string) => {
    if (!organization) {
      return false;
    }

    return organization[formField] !== watchEditableFields[formField];
  };

  useEffect(() => {
    if (isUpdate) {
      return;
    }

    if (billingPlans.length > 0) {
      setValue("planCode", billingPlans[0].code);
    }
  }, [isUpdate, billingPlans, setValue]);

  useEffect(() => {
    // Resets the form when the organization changes
    if (isUpdate && organization) {
      reset({
        id: organization.id,
        name: organization.name,
        planCode: organization.planCode,
        members: organization.members
          ? organization.members.map((member) => ({
              label: `${member.userName} (${member.email || "n/a"})`,
              value: member.userId,
              role: member.role,
            }))
          : [],
      });
    }
  }, [isUpdate, organization, reset]);

  return (
    <PanelView
      isOpen={isOpen}
      onClose={onClose}
      panelTitle={`${isUpdate ? "Update" : "Create"} Organization${organization?.name ? `: ${organization.name}` : ""}`}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem" height="100%">
            <FormProvider {...methods}>
              <Stack justifyContent="space-between" height="100%">
                <form id="upsert-organization-form" onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing="1.5rem" pb="3rem">
                    <FormTextField
                      isRequired
                      control={control}
                      errors={errors}
                      keyField="id"
                      label="ID"
                      atomic={false}
                      onUpdate={() => null}
                      disabled
                    />

                    <FormTextField
                      isRequired
                      control={control}
                      errors={errors}
                      keyField="name"
                      label="Name"
                      atomic={isUpdate}
                      onUpdate={() => {
                        onUpdateOrganizationField("name");
                      }}
                      hasChanged={hasFieldChanged("name")}
                      disabled={isLoading}
                    />

                    <RenderIfHasEntitlements
                      entitlement={"manage_billing_plans_read"}
                      headerMessageComponent={
                        <>
                          <SectionHeader title={"Plan"} />
                        </>
                      }>
                      <FormSelectField
                        keyField="planCode"
                        id="plan-code"
                        label="Plan"
                        atomic={isUpdate}
                        onUpdate={() => {
                          onUpdateOrganizationField("planCode");
                        }}
                        hasChanged={hasFieldChanged("planCode")}
                        disabled={isLoading}>
                        {billingPlans.map((plan) => (
                          <option key={plan.code} value={plan.code}>
                            {plan.name}
                          </option>
                        ))}
                      </FormSelectField>
                    </RenderIfHasEntitlements>

                    <OrganizationUsersFieldArray
                      organizationId={organization?.id}
                      isLoadingOrganization={isLoading}
                      canDelete={hasWriteOrganizationEntitlement}
                      onDelete={(memberId) => {
                        return organization
                          ? dispatch(removeMemberFromOrganization({ organizationId: organization.id, memberId }))
                          : Promise.resolve({});
                      }}
                      onChangeUserRole={onChangeUserRole}
                      currentUserRole={
                        organization && userOrganizationsMap[organization.id] ? userOrganizationsMap[organization.id].memberRole : undefined
                      }
                    />
                    {!organization ? null : (
                      <SimpleGrid overflowY="auto" columns={2} spacing=".5rem">
                        <Box marginBottom={"10"}>
                          <Text fontWeight={"bold"} color={titleColor} fontSize="md" marginBottom={4}>
                            Organization Entitlements
                          </Text>
                          <RenderIfHasEntitlements entitlement="manage_entitlements">
                            <EntitlementsList
                              type="organization"
                              entityId={organization.id}
                              entitlements={entitlements}
                              isLoading={isLoadingEntitlements}
                            />
                          </RenderIfHasEntitlements>
                        </Box>
                        <Box marginBottom={"10"}>
                          <Text fontWeight={"bold"} color={titleColor} fontSize="md" marginBottom={4}>
                            Plan Entitlements
                          </Text>
                          <RenderIfHasEntitlements entitlement="manage_entitlements">
                            <EntitlementsList
                              type="plan"
                              entityId={organization.planCode}
                              entitlements={billingPlanEntitlements}
                              isLoading={isLoadingBillingPlanEntitlements}
                            />
                          </RenderIfHasEntitlements>
                        </Box>
                      </SimpleGrid>
                    )}
                  </Stack>
                </form>

                {!isUpdate && (
                  <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem" pb="5rem">
                    <Button disabled={isLoading} {...commonButtonProps} type="submit" form="upsert-organization-form">
                      Save Organization
                    </Button>
                  </Box>
                )}
              </Stack>
            </FormProvider>
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
