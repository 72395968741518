import { prependProxyBaseUrl, request } from "api/request";
import { ConversationsResponse } from "./models/ConversationsResponse";

const HISTORY_ENDPOINT = prependProxyBaseUrl("/api/conversation/services/conversations/v2");
const CONVERSATION_ENDPOINT = (conversationId: string) => `${HISTORY_ENDPOINT}/${conversationId}`;

export async function getConversationHistory(start = 0, limit = 30) {
  const history = await request().url(HISTORY_ENDPOINT).query({ start, limit }).get().json();

  const validationResult = ConversationsResponse.validate(history);

  if (validationResult.success) {
    return validationResult.value.conversations;
  } else {
    console.error("Invalid payload received when fetching conversations", validationResult.details);

    return [];
  }
}

export async function getConversationById(conversationId: string) {
  const response = await request().url(CONVERSATION_ENDPOINT(conversationId)).get().json();

  const validationResult = ConversationsResponse.validate(response);

  if (validationResult.success) {
    return validationResult.value.conversations;
  } else {
    console.error("Invalid payload received when fetching conversion " + conversationId, validationResult.details);

    return [];
  }
}
