import { prependProxyBaseUrl, request } from "api/request";

const CHAT_INTEGRATION_ENDPOINT = prependProxyBaseUrl("/api/chat-integration");

export async function linkCharliUserToChatUser(chatUserId: string, teamId: string, chatService: string) {
  await request().url(CHAT_INTEGRATION_ENDPOINT).post({ chatUserId, teamId, chatService }).res();
}

export async function getChatIntegrationsForUser() {
  return (await request().url(CHAT_INTEGRATION_ENDPOINT).get().json()) as { chatUserId: string; chatService: string }[];
}
