import { useCallback, useEffect, useState } from "react";
import type { CSSObject } from "@emotion/react";

type ScrollbarStyle = CSSObject;

export const useCustomScrollbar = (ref: React.RefObject<HTMLElement>) => {
  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = useCallback(() => {
    setIsScrolling(true);
    const scrollTimer = setTimeout(() => setIsScrolling(false), 600);
    return () => clearTimeout(scrollTimer);
  }, []);

  useEffect(() => {
    const container = ref.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll, ref]);

  const scrollbarStyle: ScrollbarStyle = {
    "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: isScrolling ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.05)",
      borderRadius: "5px",
      backgroundClip: "padding-box",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "rgba(0, 0, 0, 0.2)",
      borderRadius: "5px",
      backgroundClip: "padding-box",
    },
  };

  return { scrollbarStyle, isScrolling };
};
