import { InstanceOf, Record, String, Union, Array } from "runtypes";
import { RunTask } from "./RunTask";
import { TriggerChildWorkflow } from "./TriggerChildWorkflow";
import type { Static } from "runtypes";

export const EntityConfigTypes = ["run_task", "trigger_child_workflow"] as const;
export type EntityConfigType = typeof EntityConfigTypes[number];

export const EntityConfig = Union(TriggerChildWorkflow, RunTask);
export type EntityConfig = Static<typeof EntityConfig>;

export const EntityConfiguration = Record({
  id: String,
  entityName: String,
  config: Array(EntityConfig),
  creationByUserId: String,
  creationByUserName: String.optional(),
  creationDate: Union(InstanceOf(Date), String),
  lastUpdatedByUserId: String,
  lastUpdatedByUserName: String.optional(),
  lastUpdatedDate: Union(InstanceOf(Date), String),
});

export type EntityConfiguration = Static<typeof EntityConfiguration>;
