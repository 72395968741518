import type { FunctionComponent } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import React from "react";
import { Box, Grid, GridItem, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { Workflow } from "types/workflows/workflow";
import { useTileProps, useMetricProps } from "hooks";
import { CustomBarChart } from "screens/landing/tabs/collections/chartComponents/CustomBarChart";
import { SimpleMetric } from "./SimpleMetric";
import useMockData from "hooks/useMockData";
import { CustomLineChart } from "../collections/chartComponents/CustomLineChart";
import { useDebouncedCallback } from "use-debounce";
import { ComingSoonOverlay } from "screens/common/components/ComingSoonOverlay";

interface Props {
  filteredWorkflows: Workflow[];
}

export const HomeMetrics: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ filteredWorkflows }) => {
  const filteredWorkflowIds = useMemo(() => filteredWorkflows.map((workflow) => workflow.id), [filteredWorkflows]);
  const commonTileProps = useTileProps();
  const commonMetricProps = useMetricProps();
  const mockData = useMockData(2).mockLabelData;
  const mockDateData = useMockData(12).mockDateData;
  const [fontSize, setFontSize] = useState("2.5rem");
  const [columns, setColumns] = useState(6);
  const bgColor = useColorModeValue("gray.200", "gray.800");
  const chartColor = useColorModeValue("#009AD8", "gray.300");

  const debouncedSetWidth = useDebouncedCallback(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 900 && windowWidth > 0) {
      setFontSize("1.5rem");
      setColumns(2);
    } else if (windowWidth < 1100) {
      setFontSize("1.5rem");
      setColumns(4);
    } else {
      setFontSize("2.5rem");
      setColumns(6);
    }
  }, 30);
  useEffect(() => {
    window.addEventListener("resize", debouncedSetWidth);
    debouncedSetWidth();
    return () => {
      window.removeEventListener("resize", debouncedSetWidth);
    };
  }, [debouncedSetWidth]);

  return (
    <Box position={"relative"} height="100%" pl="1rem" pr=".5rem" bgColor={bgColor} pt="1rem" pb=".5rem">
      <ComingSoonOverlay message="Project Metrics" height="calc(100% - 1.5rem)" width="calc(100% - 1.5rem)" />
      <Grid
        templateColumns={`repeat(${columns}, 1fr)`}
        templateRows={`repeat(${columns === 2 ? 7 : columns === 4 ? 7 : 3}, 1fr)`}
        gap={3}
        height={columns === 2 ? "75rem" : columns === 4 ? "60rem" : "26rem"}>
        <GridItem colSpan={2} rowSpan={1}>
          <Stack direction="row" width="100%" height="100%" spacing={3}>
            <Box {...commonTileProps}>
              <SimpleMetric title="Total AI Tasks" subtitle="TODAY">
                <Text {...commonMetricProps} fontSize={fontSize}>
                  232.2k
                </Text>
              </SimpleMetric>
            </Box>
            <Box {...commonTileProps}>
              <SimpleMetric title="Total AI Workers" subtitle="TODAY">
                <Text {...commonMetricProps} fontSize={fontSize}>
                  63.4k
                </Text>
              </SimpleMetric>
            </Box>
          </Stack>
        </GridItem>
        <GridItem colSpan={2} rowSpan={1}>
          <Stack direction="row" width="100%" height="100%" spacing={3}>
            <Box {...commonTileProps} maxHeight={"8.5rem"}>
              <SimpleMetric title="Total AI Completed" subtitle="IN THE LAST 5 MINUTES">
                <CustomBarChart
                  chartId="bar-chart-ai-completed"
                  data={mockData}
                  selectedWorkflowIds={filteredWorkflowIds}
                  showVertical
                  barColor={chartColor}
                  yAxisType="category"
                  height="6.5rem"
                />
              </SimpleMetric>
            </Box>
            <Box {...commonTileProps} maxHeight={"8.5rem"}>
              <SimpleMetric title="Total AI In Progress" subtitle="TODAY">
                <CustomBarChart
                  chartId="bar-chart-ai-in-progress"
                  data={mockData}
                  selectedWorkflowIds={filteredWorkflowIds}
                  showVertical
                  barColor={chartColor}
                  yAxisType="category"
                  height="6.5rem"
                />
              </SimpleMetric>
            </Box>
          </Stack>
        </GridItem>
        <GridItem colSpan={columns === 4 ? 4 : 2} rowSpan={columns < 6 ? 1 : 2}>
          <Box {...commonTileProps}>
            <SimpleMetric title="Weekly Average" subtitle="FOR PAST 4 WEEKS">
              <CustomLineChart
                lineColor={chartColor}
                chartId="area-chart-weekly-average"
                data={mockDateData}
                height="14rem"
                chartHeight={230}
                showXAxis
                showYAxis
              />
            </SimpleMetric>
          </Box>
        </GridItem>
        <GridItem colSpan={columns === 4 ? 4 : 2} rowSpan={columns < 6 ? 1 : 2}>
          <Box {...commonTileProps}>
            <SimpleMetric title="AI Worker by Task Type" subtitle="FOR PAST 4 WEEKS">
              <CustomBarChart
                chartId="bar-chart-operations-by-user"
                data={mockDateData}
                height="14rem"
                chartHeight={230}
                barColor={chartColor}
                showStateTitle
              />
            </SimpleMetric>
          </Box>
        </GridItem>
        <GridItem colSpan={2} rowSpan={1}>
          <Stack direction="row" width="100%" height="100%" spacing={3}>
            <Box {...commonTileProps}>
              <SimpleMetric title="Daily AI Workers" subtitle="TODAY">
                <Text {...commonMetricProps} fontSize={fontSize}>
                  55
                </Text>
              </SimpleMetric>
            </Box>
            <Box {...commonTileProps}>
              <SimpleMetric title="Daily AI Workers Average" subtitle="TODAY">
                <Text {...commonMetricProps} fontSize={fontSize}>
                  30.8k
                </Text>
              </SimpleMetric>
            </Box>
          </Stack>
        </GridItem>
        <GridItem colSpan={2} rowSpan={1}>
          <Stack direction="row" width="100%" height="100%" spacing={3}>
            <Box {...commonTileProps}>
              <SimpleMetric title="Due Diligence Projects" subtitle="TODAY">
                <Text {...commonMetricProps} fontSize={fontSize}>
                  57
                </Text>
              </SimpleMetric>
            </Box>
            <Box {...commonTileProps}>
              <SimpleMetric title="ESG Projects" subtitle="TODAY">
                <Text {...commonMetricProps} fontSize={fontSize}>
                  32.6k
                </Text>
              </SimpleMetric>
            </Box>
          </Stack>
        </GridItem>
        <GridItem colSpan={2} rowSpan={1}>
          <Stack direction="row" width="100%" height="100%" spacing={3}>
            <Box {...commonTileProps}>
              <SimpleMetric title="Contract Projects" subtitle="TODAY">
                <Text {...commonMetricProps} fontSize={fontSize}>
                  112.1k
                </Text>
              </SimpleMetric>
            </Box>
            <Box {...commonTileProps}>
              <SimpleMetric title="Records Management Projects" subtitle="TODAY">
                <Text {...commonMetricProps} fontSize={fontSize}>
                  120.2k
                </Text>
              </SimpleMetric>
            </Box>
          </Stack>
        </GridItem>
      </Grid>
    </Box>
  );
};
