import type { JSONSchema7 } from "json-schema";

export const CategoriesSchema: JSONSchema7 = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "https://charli.ai/schemas/DocumentConfig.json",
  type: "object",
  additionalProperties: {
    type: "array",
    items: {
      type: "string",
    },
  },
};
