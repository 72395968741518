import React, { useContext, useEffect } from "react";
import { Box, Container, useBreakpointValue, useColorModeValue, Text, Center } from "@chakra-ui/react";
import PricingTable from "./PricingTable";
import { TabTitle } from "screens/common/components/TabTitle";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { useUserPreference, useSidebarNavigation } from "hooks";
import { DEFAULT_SIDEBAR_WIDTH } from "../SidebarNavigation";

const PricingPage: React.FC = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const { setHeaderText } = useContext(SettingsProviderContext);
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const { currentSidebarType } = useSidebarNavigation();
  const isMobile = useBreakpointValue({ base: true, md: false }) || false;

  useEffect(() => {
    setHeaderText("Charli Pricing Plans");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box bg={bgColor} minH="100vh" py={"1rem"}>
      <TabTitle title="Charli > Pricing" />
      <Container maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`}>
        {isMobile && (
          <Center py="1rem">
            <Text color="charli.primaryBlue" fontSize={"3xl"} fontWeight={"semibold"}>
              Charli Pricing
            </Text>
          </Center>
        )}
        <Box px={isMobile ? "1rem" : "unset"}>
          <PricingTable />
        </Box>
      </Container>
    </Box>
  );
};

export default PricingPage;
