import type { FunctionComponent, SetStateAction } from "react";
import { useMemo } from "react";
import React, { useContext, useEffect, useState } from "react";
import { Box, Select, Stack } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { ConversationContext } from "screens/thread/ConversationContext";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { useUserPreference, useUserProfile } from "hooks";
import { useReportTemplates } from "hooks/useReportTemplates";
import type { ReportTemplateGrouping } from "types/reportTemplates/ReportTemplateGrouping";
import type { ReportTemplate } from "../../../types/reportTemplates/ReportTemplate";

interface Props {
  styles?: React.CSSProperties;
  disabled?: boolean;
  collectionName?: string;
  isReportPanel?: boolean;
  defaultReport?: string;
  label?: string;
  defaultProjectReport?: string;
}

interface ReportTemplateSectionProps {
  grouping: ReportTemplateGrouping;
  defaultProjectReport?: string;
}

export const ReportTemplateGroupingSection: FunctionComponent<
  React.PropsWithChildren<React.PropsWithChildren<ReportTemplateSectionProps>>
> = ({ grouping, defaultProjectReport }) => {
  const keyFor = (reportTemplate: ReportTemplate): string => `${reportTemplate.id}-${reportTemplate.name}`;

  return (
    <optgroup label={`${grouping.organizationName} Reports`}>
      {grouping.reportTemplates.map((reportTemplate) => {
        return (
          <option
            disabled={defaultProjectReport ? reportTemplate.id !== defaultProjectReport : false}
            key={keyFor(reportTemplate)}
            value={reportTemplate.id}>
            {reportTemplate.name}
          </option>
        );
      })}
    </optgroup>
  );
};

const ENTITIES_TO_INCLUDE_WITH_TEMPLATE_ID = ["integration"];

const mapTemplatesToEntities = (reportTemplateGroupings: ReportTemplateGrouping[]): Record<string, { name: string; value: string }[]> => {
  return reportTemplateGroupings.reduce((accumulator, grouping) => {
    grouping.reportTemplates.forEach((template) => {
      accumulator[template.id.trim()] = template.entities;
    });

    return accumulator;
  }, {} as Record<string, { name: string; value: string }[]>);
};

export const ReportSelector = ({
  styles,
  disabled = false,
  isReportPanel = false,
  defaultReport = "BasicReportTemplate.docx",
  defaultProjectReport,
  label,
}: Props) => {
  const { pathname } = useLocation();
  const route = pathname.split("/")[1];
  const identityProvider = useSelector((state: RootState) => state.session.user?.identityProvider, shallowEqual);
  const isAstrella = identityProvider === "astrella";
  const { requestEntities, setRequestEntities } = useContext(ConversationContext);
  const defaultReportTemplate = useUserPreference("default_report_template") || defaultReport;
  const [reportTemplate, setReportTemplate] = useState(getRequestValue("templateName", requestEntities) || String(defaultReportTemplate));
  const { email } = useUserProfile();
  const reportTemplateGroupings = useReportTemplates({ refreshFromNetwork: true });
  const templateToEntitiesMap = useMemo(() => mapTemplatesToEntities(reportTemplateGroupings), [reportTemplateGroupings]);

  useEffect(() => {
    if (defaultProjectReport) {
      setReportTemplate(defaultProjectReport);
    }
  }, [defaultProjectReport]);

  useEffect(() => {
    if (route === "meeting" && isReportPanel) {
      setReportTemplate(isAstrella ? "MeetingReportTemplateAstrella.docx" : "MeetingReportTemplate.docx");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetEntities = () => {
    updateBatchRequestEntities(
      [
        { entity: "templateName", value: "", source: "report-selector" },
        { entity: "report_template_id", value: "", source: "report-selector" },
        { entity: "outputFormat", value: "", source: "report-selector" },
        { entity: "email", value: "", source: "report-selector" },
      ],
      setRequestEntities
    );
  };

  useEffect(() => {
    if (!reportTemplate || !email) {
      resetEntities();
      return;
    }

    const hashEntities: {
      entity: string;
      value: string;
      source: string;
    }[] = [];

    hashEntities.push({
      entity: "email",
      value: email,
      source: "report-selector",
    });

    const trimmedTemplate = reportTemplate.trim();
    if (route === "meeting" && isReportPanel) {
      hashEntities.push({
        entity: "templateName",
        value: `${isAstrella ? "MeetingReportTemplateAstrella.docx" : "MeetingReportTemplate.docx"}`,
        source: "report-selector",
      });
    } else {
      hashEntities.push({
        entity: "report_template_id",
        value: trimmedTemplate,
        source: "report-selector",
      });
      const entities = templateToEntitiesMap[trimmedTemplate] ?? [];
      entities
        .filter(({ name }) => ENTITIES_TO_INCLUDE_WITH_TEMPLATE_ID.includes(name))
        .forEach(({ name: entity, value }) =>
          hashEntities.push({
            entity,
            value,
            source: "report-selector",
          })
        );
    }

    updateBatchRequestEntities(Object.values(hashEntities), setRequestEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTemplate]);

  return (
    <Stack direction="row" spacing="1.5rem" width={"100%"} justifyContent="space-between">
      <Box width="100%">
        <SectionHeader title={label && label.length > 0 ? label : "Report Template"} />
        <Select
          id="ch-test-report"
          disabled={disabled}
          style={styles}
          borderRadius="5px"
          mt="0!important"
          size="sm"
          value={reportTemplate}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e: { target: { value: SetStateAction<string> } }) => {
            if (route === "meeting" && isReportPanel) {
              setReportTemplate(isAstrella ? "MeetingReportTemplateAstrella.docx" : "MeetingReportTemplate.docx");
            } else {
              setReportTemplate(e.target.value);
            }
          }}>
          <option value="">No Report Required</option>
          {route === "meeting" && isReportPanel ? (
            <option value={isAstrella ? "MeetingReportTemplateAstrella.docx" : "MeetingReportTemplate.docx"}>
              Meeting Report Template
            </option>
          ) : (
            <>
              {reportTemplateGroupings &&
                reportTemplateGroupings.map((grouping) => (
                  <ReportTemplateGroupingSection
                    key={`${grouping.organizationName}`}
                    grouping={grouping}
                    defaultProjectReport={defaultProjectReport}></ReportTemplateGroupingSection>
                ))}
            </>
          )}
        </Select>
      </Box>
    </Stack>
  );
};
