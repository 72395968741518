import { prependProxyBaseUrl, request } from "api/request";
import type { Command } from "types/commands";

const WORKFLOW_COMMAND_ENDPOINT = prependProxyBaseUrl("/api/commands");

export async function getWorkflowCommands() {
  const commands = (await request().url(WORKFLOW_COMMAND_ENDPOINT).get().json()) as Command[];

  return commands;
}
