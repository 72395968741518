import { Box } from "@chakra-ui/react";
import DOMPurify from "dompurify";
import marked from "marked";
import type { FunctionComponent } from "react";
import React from "react";

marked.setOptions({
  gfm: true,
  breaks: true,
  mangle: false,
  silent: true,
});

interface Props {
  markdown: string;
}

export const MarkdownItemView: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ markdown }) => {
  const parsedMarkdown = marked(markdown);
  // Sanitize generated HTML of any XSS vectors
  const purifiedHtml = DOMPurify.sanitize(parsedMarkdown);

  return (
    <Box
      dangerouslySetInnerHTML={{ __html: purifiedHtml }}
      css={{
        blockquote: {
          background: "#f9f9f9",
          marginTop: "1.25rem !important",
          marginBottom: "1.25rem !important",
        },
        "blockquote p": {
          display: "inline",
          color: "#555555",
          fontStyle: "italic",
        },
      }}></Box>
  );
};
