import type { FunctionComponent } from "react";
import React, { useState } from "react";
import { Box, Image, Skeleton, useColorModeValue } from "@chakra-ui/react";
import { getAccessToken } from "api/auth";
import { TypingIndicator } from ".";

interface Props {
  src: string;
  initiator: "charli" | "user";
}

const SIZE = "7rem";

export const MessageImage: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ src, initiator }) => {
  const [state, setState] = useState<"loading" | "done" | "failed">("loading");
  const [key, setKey] = useState(0);
  const accessToken = getAccessToken();
  const startColor = useColorModeValue("gray.300", "gray.900");
  const endColor = useColorModeValue("gray.100", "gray.800");

  return (
    <Box position="relative" display={state !== "failed" ? "block" : "none"} overflow="hidden" borderRadius="md">
      {state === "loading" && (
        <Skeleton
          position="absolute"
          width={SIZE}
          height={SIZE}
          borderRadius="md"
          fadeDuration={3}
          speed={1}
          startColor={startColor}
          endColor={endColor}>
          <TypingIndicator />
        </Skeleton>
      )}

      <Image
        key={key}
        boxSize={SIZE}
        objectFit="cover"
        objectPosition={`${initiator === "charli" ? "0%" : "100%"} 0%`}
        src={`${src}?access_token=${accessToken}`}
        visibility={state !== "done" ? "hidden" : "visible"}
        onLoad={() => {
          setState("done");
        }}
        onError={() => {
          if (key === 0) {
            // Image failed to load during first attempt.
            // This occasionally happens when the web app requests the file before charli-media has finished processing the file.
            // Force remount by mutating key (ie. attempt to load the image again)
            setTimeout(() => {
              setKey(1);
            }, 1000);
          } else {
            // Image load failed on 2nd attempt. Give up.
            setState("failed");
          }
        }}
      />
    </Box>
  );
};
