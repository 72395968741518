import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWorkflowsStats } from "api/workflows";
import { WorkflowFetchError } from "api/workflows/models/WorkflowFetchError";

export const downloadWorkflowsStats = createAsyncThunk(
  "workflow/download-workflows-stats",
  async ({ workflowsId }: { workflowsId: string[] }, thunkAPI) => {
    const workflowsResponse = await getWorkflowsStats(workflowsId);

    const filteredWorkflows = workflowsResponse.flatMap((newWorkflow) => {
      return WorkflowFetchError.guard(newWorkflow) ? [] : [newWorkflow];
    });

    return filteredWorkflows;
  }
);
