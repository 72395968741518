export const FeatureFlags = [
  "show_maintenance_screen",
  "show_maintenance_banner",
  "workflow_summary_2",
  "enable_redux_logger",
  "enable_admin_questions",
  "enable_credit_card_processing",
  "disable_stock_equity",
  "show_modal_update_banner",
  "enable_scalebar",
  "new_progress_stepper",
  "enable_new_pricing",
  "buy_sell_tag",
  "enable_rerun_all_projects_button",
] as const;
