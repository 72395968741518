import type { FunctionComponent } from "react";
import React, { useContext } from "react";
import { Box, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { Message } from "types/conversation";
import { ChatBubble, MessageContainer } from "../components";
import type { CollectionButton } from "types/charliui";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useCollectionKey, useConfigMap, useGetViewConfig } from "hooks";

interface Props {
  message: Message;
  button: CollectionButton;
}

export const CollectionCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ message, button }) => {
  const configMap = useConfigMap();
  const navigate = useNavigate();
  const collectionId = button.body.collectionId;
  const { onConversationClose } = useContext(ConversationContext);
  const collectiontype = useCollectionKey(collectionId, "collectionType");
  const buttonTitle = useCollectionKey(collectionId, "name") ?? "Resource Collection";
  const projectRoute = useGetViewConfig("route", collectiontype, configMap);
  const projectType = useGetViewConfig("title", collectiontype, configMap);
  const bgColorBadge = useColorModeValue("white", "gray.600");

  const onClick = () => {
    onConversationClose();

    if (projectRoute && projectRoute.length > 0) {
      return navigate(`/${projectRoute}/${collectionId}`);
    } else {
      return undefined;
    }
  };

  return (
    <MessageContainer initiator={message.senderId === "charli" ? "charli" : "user"} key={`${message.id}-collection-${collectionId}`}>
      <Box position="relative">
        <Box
          isTruncated
          maxWidth={"80%"}
          position="absolute"
          right="5px"
          bottom="-8px"
          fontSize="0.7rem"
          borderWidth="1px"
          borderTop="none"
          px="0.6rem"
          bgColor={bgColorBadge}
          borderBottomRadius="md">
          {projectType}
        </Box>
        <ChatBubble
          initiator={message.senderId === "charli" ? "charli" : "user"}
          state={message.state}
          onClick={onClick}
          className="ch-collection-button">
          <Stack direction="row" alignItems="center" p="1">
            <Text wordBreak="break-all" whiteSpace="break-spaces" fontSize="md" fontWeight={600}>
              {buttonTitle}
            </Text>
            <ChevronRightIcon boxSize="1.5rem" />
          </Stack>
        </ChatBubble>
      </Box>
    </MessageContainer>
  );
};
