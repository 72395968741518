import { prependProxyBaseUrl, request } from "api/request";
import { CompanyExchangeResponse } from "./models/CompanyExchangeResponse";
import type { FinancialMarket } from "./models/FinancialMarket";

const FINANCIAL_MARKET_ENDPOINT = prependProxyBaseUrl("/api/company-exchange ");

export const fetchCompanyExchangeData = async (): Promise<FinancialMarket[]> => {
  try {
    const response = await request().url(`${FINANCIAL_MARKET_ENDPOINT}`).get().json();

    const validate = CompanyExchangeResponse.validate(response);

    if (!validate.success) {
      throw new Error(validate.message);
    } else if (validate.value.status !== "success") {
      throw new Error(validate.value.message);
    } else {
      return validate.value.financial_market_data;
    }
  } catch (error) {
    throw new Error(`Failed to get company exchange data: ${error}`);
  }
};
