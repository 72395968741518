import { useEffect, useMemo, useState } from "react";
import type { Workflow } from "types/workflows/workflow";
import { WorkflowTaskStatus } from "types/workflows/workflow";
import { useDispatch } from "react-redux";
import { downloadWorkflowsStats } from "state/workflowStats/operations";
import { chartItemColor } from "screens/landing/tabs/collections/chartComponents/CustomChartUtils";
import { useConfigMap, useMenuConfig } from "hooks";
import type { DataType, DateType } from "hooks/useStats";

export const getConfidenceLevel = (score: number) => {
  if (score === 0) return null;
  if (score > 94) {
    return "high";
  } else if (score > 79) {
    return "medium";
  } else {
    return "low";
  }
};

export const scoreColor = (score: number): string => {
  const confidenceLevel = getConfidenceLevel(score);
  return (confidenceLevel && chartItemColor(confidenceLevel)) || "#ad729d";
};

export const calculateWorkflowDuration = (workflow: Workflow) => {
  const createdDate = new Date(workflow.creationDate);
  const completionDate = workflow.completionDate ? new Date(workflow.completionDate) : new Date();
  const differenceInMilliseconds = new Date(completionDate).getTime() - new Date(createdDate).getTime();
  const differenceInMinutes = Math.floor(differenceInMilliseconds / 1000 / 60);
  if (differenceInMilliseconds > 0 && differenceInMinutes < 1) {
    return { differenceInHours: 0, differenceInMinutes: 1 };
  }
  const differenceInHours = Math.floor(differenceInMilliseconds / 1000 / 60 / 60) || 0;
  return { differenceInHours, differenceInMinutes };
};

export const mapWorkflowStatesLabel = (workflowTaskStatus: WorkflowTaskStatus) => {
  switch (workflowTaskStatus) {
    case "queued": {
      return {
        label: "Queued",
      };
    }
    case "in_progress": {
      return {
        label: "In Progress",
      };
    }
    case "incomplete":
    case "failed": {
      return {
        label: "Incomplete",
      };
    }
    case "clarification_needed": {
      return {
        label: "Clarification",
      };
    }
    case "complete": {
      return {
        label: "Completed",
      };
    }
    case "error": {
      return {
        label: "Errors",
      };
    }
    case "cancelled": {
      return {
        label: "Cancelled",
      };
    }
    case "denied_intent_confirmation": {
      return {
        label: "Denied",
      };
    }

    default: {
      return {
        label: "Unknown",
      };
    }
  }
};

export const useWorkflowStatesByDay = (props: { workflows: Workflow[]; statesToFilter?: WorkflowTaskStatus[]; limitDate?: Date }) => {
  const { workflows, statesToFilter: statesToFilterProp, limitDate } = props;
  const [statesToFilter] = useState<WorkflowTaskStatus[] | undefined>(statesToFilterProp);

  return useMemo(() => {
    const defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() - 7);
    const limitDateProp = limitDate ? limitDate : defaultDate;
    limitDateProp.setHours(0, 0, 0, 0);

    // If statesToFilter is defined, we only want to return those states, otherwise return all states
    const statesToReturn = (() => {
      if (statesToFilter) {
        return statesToFilter;
      } else {
        return Object.values(WorkflowTaskStatus);
      }
    })();

    /* 
    The purpose of this code is to process a list of workflows and accumulate status counts 
    for each unique date in workflowDate, based on certain filtering conditions. 
    The result, totalStatusByDate, will be an object where keys are dates, and values are records 
    that represent the counts of different workflow statuses for each date. 
    */
    const totalStatusByDate = workflows.reduce((acc: Record<number, Partial<Record<WorkflowTaskStatus, number>>>, workflow) => {
      const workflowDate = new Date(workflow.creationDate).setHours(0, 0, 0, 0);

      if (workflowDate < limitDateProp.getTime() || (statesToFilter && !statesToFilter.includes(workflow.status))) {
        return acc;
      }

      if (acc[workflowDate]) {
        const statusCount = acc[workflowDate][workflow.status];
        if (statusCount) {
          acc[workflowDate][workflow.status] = statusCount + 1;
        } else {
          acc[workflowDate][workflow.status] = 1;
        }
      } else {
        acc[workflowDate] = {};
        statesToReturn.forEach((status) => {
          acc[workflowDate][status] = status === workflow.status ? 1 : 0;
        });
      }

      return acc;
    }, {});

    const dayInMs = 1000 * 3600 * 24;
    const daysOfDifference = -Math.ceil((limitDateProp.getTime() - new Date().getTime()) / dayInMs);
    const datesArray: DateType[] = [];

    const tempDate = new Date(new Date().getTime() - dayInMs * daysOfDifference);
    tempDate.setHours(0, 0, 0, 0);

    for (let index = daysOfDifference; index >= 0; index--) {
      const tempTime = tempDate.getTime();
      const workflowStatusMap = totalStatusByDate[tempTime];

      if (!workflowStatusMap) {
        datesArray.push(
          ...statesToReturn.map((status) => ({
            name: new Date(tempTime).toDateString(),
            value: 0,
            state: status,
            ...mapWorkflowStatesLabel(status),
          }))
        );
        tempDate.setDate(tempDate.getDate() + 1);

        continue;
      }

      Object.entries(workflowStatusMap).forEach(([status, value]) => {
        if (statesToReturn.includes(status as WorkflowTaskStatus)) {
          datesArray.push({
            name: new Date(tempTime).toDateString(),
            value,
            state: status,
            ...mapWorkflowStatesLabel(status as WorkflowTaskStatus),
          });
        }
      });

      tempDate.setDate(tempDate.getDate() + 1);
    }

    return datesArray;
  }, [limitDate, workflows, statesToFilter]);
};

export const useWorkflowTotalDuration = (props: {
  workflows: Workflow[];
  statesToFilter?: WorkflowTaskStatus[];
  limitDate?: Date;
  shouldReturnRangeData?: boolean;
}) => {
  const { workflows, statesToFilter: statesToFilterProp, limitDate, shouldReturnRangeData } = props;
  const [statesToFilter] = useState<WorkflowTaskStatus[] | undefined>(statesToFilterProp);

  return useMemo(() => {
    const defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() - 7);
    const limitDateProp = limitDate ? limitDate : defaultDate;
    limitDateProp.setHours(0, 0, 0, 0);
    const filteredByProps = workflows.reduce(
      (acc: Record<string, { duration: number; title: string; date: number; state: WorkflowTaskStatus }>, workflow) => {
        const workflowDate = new Date(workflow.creationDate).setHours(0, 0, 0, 0);
        const workflowTitle = workflow.title;

        if (workflowDate < limitDateProp.getTime() || (statesToFilter && !statesToFilter.includes(workflow.status))) {
          return acc;
        }

        acc[workflow.id] = {
          duration: calculateWorkflowDuration(workflow as Workflow).differenceInMinutes,
          title: workflowTitle,
          date: workflowDate,
          state: workflow.status === "failed" ? WorkflowTaskStatus.incomplete : workflow.status,
        };

        return acc;
      },
      {}
    );

    const dataArray: DateType[] = [];
    const defaultLabels = ["0-5", "5-10", "10+"];

    if (shouldReturnRangeData) {
      Object.entries(filteredByProps).forEach(([key, { title, duration }]) => {
        let value = "";
        if (duration >= 0 && duration <= 5) {
          value = "0-5";
        } else if (duration > 5 && duration <= 10) {
          value = "5-10";
        } else {
          value = "10+";
        }
        dataArray.push({
          name: title,
          value: 1,
          label: `${value} mins`,
          state: value,
        });
      });
      defaultLabels.forEach((label) => {
        const hasDefault = dataArray.some((data) => data.state === label);
        if (!hasDefault) {
          dataArray.push({
            name: "No Projects",
            value: 0,
            label: `${label} mins`,
            state: label,
          });
        }
      });
    } else {
      Object.entries(filteredByProps).forEach(([workflowId, { duration, title, date, state }]) => {
        dataArray.push({
          workflowId,
          name: title,
          value: duration,
          label: new Date(date).toDateString(),
          valueSuffix: "minutes",
          state,
        });
      });
    }

    return dataArray;
  }, [limitDate, workflows, shouldReturnRangeData, statesToFilter]);
};

export const useGroupWorkflowByIntent = (props: { workflows: Workflow[]; statesToFilter?: WorkflowTaskStatus[]; limitDate?: Date }) => {
  const { workflows, statesToFilter: statesToFilterProp, limitDate } = props;
  const [statesToFilter] = useState<WorkflowTaskStatus[] | undefined>(statesToFilterProp);

  return useMemo(() => {
    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);
    const defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() - 7);
    const limitDateProp = limitDate ? limitDate : defaultDate;
    limitDateProp.setHours(0, 0, 0, 0);

    const defaultDatesInRange = (): string[] => {
      const dates: string[] = [];

      while (todaysDate > limitDateProp) {
        dates.push(limitDateProp.toDateString());
        limitDateProp.setDate(limitDateProp.getDate() + 1);
      }
      return dates;
    };

    const filteredByProps = workflows.reduce(
      (acc: Record<string, { date: number; state: WorkflowTaskStatus; intent: string }>, workflow) => {
        const workflowDate = new Date(workflow.creationDate).setHours(0, 0, 0, 0);

        if (workflowDate < limitDateProp.getTime() || (statesToFilter && !statesToFilter.includes(workflow.status))) {
          return acc;
        }

        acc[workflow.id] = {
          date: workflowDate,
          state: workflow.status === "failed" ? WorkflowTaskStatus.incomplete : workflow.status,
          intent: workflow.intent || "No Intent",
        };

        return acc;
      },
      {}
    );

    const dataArray: DateType[] = [];

    Object.entries(filteredByProps).forEach(([workflowId, { date, state, intent }]) => {
      dataArray.push({
        name: new Date(date).toDateString(),
        value: 1,
        label: intent,
        state: intent,
      });
    });
    const dates = defaultDatesInRange();
    dates.forEach((date) => {
      const hasDefault = dataArray.some((data) => data.label === date);
      if (!hasDefault) {
        dataArray.push({
          name: new Date(date).toDateString(),
          value: 0,
          label: "No Records",
          state: "No Records",
        });
      }
    });
    // order dataArray by date
    dataArray.sort((a, b) => {
      const dateA = new Date(a.label);
      const dateB = new Date(b.label);

      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });

    return dataArray.reverse();
  }, [limitDate, workflows, statesToFilter]);
};

export const useWorkflowByUser = (props: { workflows: Workflow[]; statesToFilter?: WorkflowTaskStatus[]; limitDate?: Date }) => {
  const { workflows, statesToFilter: statesToFilterProp, limitDate } = props;
  const [statesToFilter] = useState<WorkflowTaskStatus[] | undefined>(statesToFilterProp);

  return useMemo(() => {
    const defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() - 7);
    const limitDateProp = limitDate ? limitDate : defaultDate;
    limitDateProp.setHours(0, 0, 0, 0);

    const filteredByProps = workflows.reduce(
      (acc: Record<string, { duration: number; title: string; date: number; state: WorkflowTaskStatus; userName: string }>, workflow) => {
        const workflowDate = new Date(workflow.creationDate).setHours(0, 0, 0, 0);
        const workflowTitle = workflow.title;

        if (workflowDate < limitDateProp.getTime() || (statesToFilter && !statesToFilter.includes(workflow.status))) {
          return acc;
        }

        acc[workflow.id] = {
          duration: calculateWorkflowDuration(workflow as Workflow).differenceInMinutes,
          title: workflowTitle,
          date: workflowDate,
          state: workflow.status === "failed" ? WorkflowTaskStatus.incomplete : workflow.status,
          userName: workflow.userName || "No User",
        };

        return acc;
      },
      {}
    );

    const dataArray: DateType[] = [];

    Object.entries(filteredByProps).forEach(([key, { state, userName }]) => {
      dataArray.push({
        name: "Projects",
        value: 1,
        label: "Projects",
        state: userName,
      });
    });

    return dataArray;
  }, [limitDate, workflows, statesToFilter]);
};

export const useSumAllValuesByLabel = (data: DataType[]) => {
  return useMemo(() => {
    const result: { [key: string]: { value: number; label: string } } = data.reduce(
      (acc: { [key: string]: { value: number; label: string } }, item: DataType) => {
        if (!item.state) {
          return acc;
        }
        if (acc[item.state]) {
          acc[item.state].value += item.value;
        } else {
          acc[item.state] = { value: item.value, label: item.label };
        }
        return acc;
      },
      {}
    );

    const aggregateData = Object.entries(result).map(([state, { value, label }]) => ({ state, value, label }));

    return aggregateData;
  }, [data]);
};

export const useSumAllValuesByDate = (data: DataType[]) => {
  return useMemo(() => {
    const statesByDate: {
      [date: string]: {
        [state: string]: number;
      };
    } = {};

    data?.forEach(({ name, label, value }) => {
      if (!name || !label) return;
      if (!statesByDate[name]) {
        statesByDate[name] = {};
      }
      if (statesByDate[name][label]) {
        statesByDate[name][label] += value;
      } else {
        statesByDate[name][label] = value;
      }
    });

    const aggregateData = Object.keys(statesByDate).map((date) => ({
      name: date,
      ...statesByDate[date],
    }));

    return aggregateData;
  }, [data]);
};

export const useCostsByDate = (data: DataType[]) => {
  const configMap = useConfigMap();
  const projectConfig = useMenuConfig(configMap, undefined, true);

  const projectCostPerProjectByIntents = useMemo(() => {
    const projectCostsByIntent: {
      [intent: string]: {
        humanResourceCost: number;
        aiGeneratedCost: number;
      };
    } = {};

    Object.keys(projectConfig).forEach((project) => {
      const intent = projectConfig[project].config.intent;

      if (intent) {
        const humanResourceCost = projectConfig[project].config?.costPerProject?.humanResourceCost || 0;
        const aiCost = projectConfig[project].config?.costPerProject?.aiGeneratedCost || 0;

        projectCostsByIntent[intent] = {
          humanResourceCost: humanResourceCost,
          aiGeneratedCost: aiCost,
        };
      }
    });

    return projectCostsByIntent;
  }, [projectConfig]);

  return useMemo(() => {
    const statesByDate: {
      [date: string]: {
        totalProjects: number;
        human_resource_cost: number;
        ai_generated_cost: number;
      };
    } = {};

    data?.forEach(({ name, label, value }) => {
      if (!name || !label) return;
      if (!statesByDate[name]) {
        statesByDate[name] = {
          totalProjects: 0,
          human_resource_cost: 0,
          ai_generated_cost: 0,
        };
      }
      statesByDate[name].totalProjects += value;
      if (projectCostPerProjectByIntents) {
        statesByDate[name].human_resource_cost += value * (projectCostPerProjectByIntents[label]?.humanResourceCost || 0);
        statesByDate[name].ai_generated_cost += value * (projectCostPerProjectByIntents[label]?.aiGeneratedCost || 0);
      }
    });

    const aggregateData = Object.keys(statesByDate).map((date) => ({
      name: date,
      ...statesByDate[date],
    }));

    return aggregateData;
  }, [data, projectCostPerProjectByIntents]);
};

export const useDownloadWorkflowStats = (props: { workflowIds: string[] }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.workflowIds.length > 0) {
      dispatch(downloadWorkflowsStats({ workflowsId: props.workflowIds }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
