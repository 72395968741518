import { Box, Stack, useToast } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import type { Organization } from "types/organization/organization";
import { useOrganization } from "hooks/useOrganizations";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { addOrganizationMembers, createAndAddOrganizationMembers } from "state/organization/operations";
import { useAppDispatch } from "hooks";
import { OrganizationUsersAddFieldArray } from "./OrganizationUsersAddFieldArray";
import { ToastMessageContent } from "screens/common/components";
import { OrganizationLogsPanel } from "./OrganizationLogsPanel";
import type { ModalKey } from "types/modal";
import { AddNewUsers } from "screens/landing/tabs/admin/common";
import type { User } from "screens/landing/tabs/admin/common";

interface IProps {
  organization: Organization;
}

export const AddUsers = (props: IProps) => {
  const { organization } = props;
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<ModalKey | undefined>();

  const isValidUserMember = (user: User) => !!user.role && user.role.length > 0 && organization.id;

  const orgUser = useCallback(
    (user: User, orgId: string) => ({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      orgId: orgId,
      orgRole: user.role,
      planId: user.planId,
    }),
    []
  );

  const onClose = useCallback(() => {
    setIsOpen(undefined);
  }, []);

  const onSubmitNewUsers = async (newUsers: User[]) => {
    if (!newUsers || !organization) {
      return false;
    }

    const newUsersArray = newUsers.filter(isValidUserMember).map((user) => orgUser(user, organization.id));

    if (newUsersArray.length > 0) {
      dispatch(createAndAddOrganizationMembers({ createRequests: newUsersArray }));
    }

    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`Adding ${newUsers.filter(isValidUserMember).length} member${
            newUsers.filter(isValidUserMember).length > 1 ? "'s" : ""
          } to organization ${organization.name} now. Click here to open the logs and verify the members were added successfully.`}
          onClick={() => {
            setIsOpen("logs");
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });

    return true;
  };

  return (
    <Stack spacing="3rem" pt="1rem">
      <Box>
        <SectionHeader title="Add an existing user" />
        <OrganizationUsersAddFieldArray
          onAdd={(memberId) => {
            return organization
              ? dispatch(addOrganizationMembers({ organizationId: organization.id, members: [{ userId: memberId, role: "member" }] }))
              : Promise.resolve({});
          }}
        />
      </Box>

      <AddNewUsers onSubmitNewUsers={(users) => onSubmitNewUsers(users)} />
      {isOpen === "logs" && <OrganizationLogsPanel id={organization.id} onClose={onClose} />}
    </Stack>
  );
};

export const AddUsersPanel = (props: { onClose: () => void; id: string | null }) => {
  const { id, onClose } = props;
  const organization = useOrganization(id);

  if (!organization) {
    return <React.Fragment />;
  }

  return (
    <PanelView isOpen onClose={onClose} panelTitle={`Add Users to ${organization.name}`}>
      <Wizard>
        <PanelStep>
          <AddUsers organization={organization} />
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
