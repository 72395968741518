import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { track } from "api/analytics";
import { SETTINGS_DEEP_LINK_CLICKED } from "api/analytics/events";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

export const SettingsLinkListener = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { setActiveSettingsTab } = useContext(SettingsProviderContext);
  const { onSettingsOpen } = useAddToCharliContext();

  useEffect(() => {
    const maybeSettingsQueryParam = (() => {
      const urlSearchParams = Object.fromEntries(new URLSearchParams(search)) || {};

      if (urlSearchParams.settings) {
        return urlSearchParams.settings;
      } else {
        return undefined;
      }
    })();

    if (maybeSettingsQueryParam) {
      switch (maybeSettingsQueryParam) {
        case "notifications":
          setActiveSettingsTab("notifications");
          onSettingsOpen();
          break;
        default:
          onSettingsOpen();
          break;
      }

      track(SETTINGS_DEEP_LINK_CLICKED, { screen: maybeSettingsQueryParam });

      navigate(`/`, { replace: true });
    }
  }, [navigate, onSettingsOpen, search, setActiveSettingsTab]);

  return <React.Fragment />;
};
