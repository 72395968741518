import React, { useEffect, useMemo, useState } from "react";
import { Select } from "chakra-react-select";
import debounce from "lodash/debounce";
import { listUsers } from "api/user";
import { useAppDispatch, useEntitlements } from "hooks";

const MIN_SEARCH_LENGTH = 3;

type SelectedUsers = { value: string; label: string }[];

interface IProps {
  onCustomUsers?: (users: { value: string; label: string }[]) => { value: string; label: string; isDisabled?: boolean }[];
  selectedUsers: SelectedUsers;
  handleUsers: (users: SelectedUsers) => void;
  isDisabled?: boolean;
}

export const UsersSelect = (props: IProps) => {
  const { onCustomUsers, selectedUsers, handleUsers, isDisabled } = props;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userEntitlements = useEntitlements();
  const { view_users: hasViewUsers } = userEntitlements;

  const [searchUserText, setSearchUserText] = useState("");
  const [usersResult, setUsersResult] = useState<{ value: string; label: string }[]>([]);

  const changedUserSearch = debounce((target) => {
    setSearchUserText(target);
  }, 500);

  const users = useMemo(() => {
    if (onCustomUsers) {
      return onCustomUsers(usersResult);
    } else {
      return usersResult;
    }
  }, [usersResult, onCustomUsers]);

  useEffect(() => {
    if (!hasViewUsers) {
      return;
    }

    const target = searchUserText.length < MIN_SEARCH_LENGTH ? "" : searchUserText;

    if (target.length > 0) {
      setIsLoading(true);
      listUsers(target)
        .then((users) => {
          setUsersResult(
            users.map((u) => ({
              value: u.id,
              label: `${(u.firstName ?? "")
                .concat(" ")
                .concat(u.lastName ?? "")
                .trim()} (${u.email})`,
            }))
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, searchUserText, hasViewUsers]);

  return (
    <Select
      className="ch-multi-select"
      useBasicStyles
      size="sm"
      isLoading={isLoading}
      selectedOptionStyle="check"
      options={users}
      onInputChange={changedUserSearch}
      onChange={(usersEvent) => {
        handleUsers(usersEvent.map((user) => ({ label: user.label, value: user.value })));
      }}
      isMulti
      isClearable
      isDisabled={isDisabled ?? false}
      value={selectedUsers}
    />
  );
};
