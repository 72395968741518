import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCreditCardSecurityToken, getUserSubscription } from "api/subscription";
import type { RootState } from "state/rootReducer";
import { getAccessToken } from "api/auth";
import { downloadCurrentUserFeatureUsage } from "state/usage/operations";

export const downloadSubscriptionDetails = createAsyncThunk("subscription/download", async (forceFetch: boolean | undefined, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const accessToken = getAccessToken();

  if (state.session.user?.id) {
    const response = await getUserSubscription(state.session.user.id, accessToken);
    thunkAPI.dispatch(downloadCurrentUserFeatureUsage());

    return response;
  } else {
    console.warn(`Tried to fetch user subscription but no user id was available`);
    throw new Error("No user id available");
  }
});

export const generateSecurityToken = createAsyncThunk("subscription/token/download", (arg: void, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  if (!state.session.user?.id) {
    return;
  }

  return getCreditCardSecurityToken(state.session.user.id);
});
