import type { FunctionComponent } from "react";
import React, { useMemo, memo } from "react";
import { Stack, Text, Badge, Box, useColorModeValue } from "@chakra-ui/react";
import { ResponsiveContainer, ComposedChart, Tooltip, XAxis, YAxis, Bar, CartesianGrid, Area } from "recharts";
import type { StockEquityData } from "types/stock";
import { useProjectParams } from "hooks";

interface Props {
  height?: string;
  hideAxis?: boolean;
  stockEquityData: StockEquityData | null;
  usingMockData?: boolean;
  width?: number | string;
}

interface ChartDataPoint {
  label: string;
  date: string;
  close: number;
  low: number;
  high: number;
  volume: number;
}

const formatPrice = (price: number | string) => Number(price).toFixed(2);
const formatNumber = (num: number | string): string => {
  return Number(num).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

// Memoized tooltip component to prevent unnecessary re-renders
const CustomTooltip = memo(({ active = false, payload = [] }: any) => {
  if (active && payload.length) {
    const data = payload[0].payload;
    return (
      <Stack spacing="0" bgColor="white" fontSize="xs" boxShadow="md" width="100%" borderRadius="md">
        <Box bgColor="gray.50" p="10px" borderTopRadius="md">
          <Text isTruncated width="100%" fontWeight="semibold">
            {data.date}
          </Text>
        </Box>
        <Stack spacing="5px" p="10px" borderBottomRadius="md">
          {data.high && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color="white" bgColor="#007560">
                High
              </Badge>
              <Text width="5rem" textAlign="right">
                ${formatPrice(data.high)}
              </Text>
            </Stack>
          )}
          {data.low && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color="white" bgColor="#f56565">
                Low
              </Badge>
              <Text width="5rem" textAlign="right">
                ${formatPrice(data.low)}
              </Text>
            </Stack>
          )}
          {data.close && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color="white" bgColor="#3d9484">
                Close
              </Badge>
              <Text width="5rem" textAlign="right">
                ${formatPrice(data.close)}
              </Text>
            </Stack>
          )}
          {data.volume && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color="white" bgColor="#93abcc">
                Volume
              </Badge>
              <Text width="5rem" textAlign="right">
                {formatNumber(data.volume)}
              </Text>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }
  return null;
});

CustomTooltip.displayName = "CustomTooltip";

const sampleData = (data: ChartDataPoint[], sampleSize: number): ChartDataPoint[] => {
  if (data.length <= sampleSize) return data;

  const sampledData: ChartDataPoint[] = [];
  const step = Math.floor(data.length / sampleSize);

  for (let i = 0; i < data.length; i += step) {
    sampledData.push(data[i]);
  }

  if (sampledData[sampledData.length - 1] !== data[data.length - 1]) {
    sampledData.push(data[data.length - 1]);
  }

  return sampledData;
};

export const StockEquityChart: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = memo(
  ({ height = "20rem", hideAxis = false, stockEquityData, usingMockData = false, width = "100%" }) => {
    const reformattedStockEquityData = useMemo(() => {
      if (!stockEquityData?.data.chart?.result?.[0]) {
        return [];
      }

      const result = stockEquityData.data.chart.result[0];
      const { timestamp, indicators } = result;
      const quote = indicators.quote[0];

      if (!timestamp) return [];

      const formattedData: ChartDataPoint[] = timestamp.map((ts, index) => ({
        label: new Date(ts * 1000).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
        date: new Date(ts * 1000).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
        close: Number(quote.close?.[index]?.toFixed(2) ?? 0),
        low: Number(quote.low?.[index]?.toFixed(2) ?? 0),
        high: Number(quote.high?.[index]?.toFixed(2) ?? 0),
        volume: Math.round(quote.volume?.[index] ?? 0),
      }));

      return sampleData(formattedData, 100);
    }, [stockEquityData]);

    const { minClose, maxClose, minVolume, maxVolume } = useMemo(() => {
      if (!reformattedStockEquityData.length) {
        return { minClose: 0, maxClose: 0, minVolume: 0, maxVolume: 0 };
      }

      return reformattedStockEquityData.reduce(
        (acc, item) => ({
          minClose: Math.min(acc.minClose, item.close),
          maxClose: Math.max(acc.maxClose, item.close),
          minVolume: Math.min(acc.minVolume, item.volume),
          maxVolume: Math.max(acc.maxVolume, item.volume),
        }),
        {
          minClose: Number.MAX_VALUE,
          maxClose: Number.MIN_VALUE,
          minVolume: Number.MAX_VALUE,
          maxVolume: Number.MIN_VALUE,
        }
      );
    }, [reformattedStockEquityData]);

    const tileBorderColor = useColorModeValue("gray.300", "gray.700");
    const { projectId, parentRoute } = useProjectParams();

    return (
      <Box
        height={height}
        width="100%"
        className="ch-project-tile-stock-chart"
        opacity={usingMockData ? 0.2 : 1}
        border={projectId || parentRoute === "home" ? "none" : "1px solid"}
        borderColor={tileBorderColor}
        borderRadius=".375rem">
        <ResponsiveContainer width={width} height="100%">
          <ComposedChart
            width={500}
            height={400}
            data={reformattedStockEquityData}
            margin={{
              top: 0,
              right: hideAxis ? 0 : 10,
              bottom: 0,
              left: hideAxis ? 0 : -20,
            }}>
            <defs>
              <linearGradient id="colorClose" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#007560" stopOpacity={0.4} />
                <stop offset="95%" stopColor="#007560" stopOpacity={0} />
              </linearGradient>
            </defs>
            {!hideAxis && <CartesianGrid stroke="#f5f5f5" />}
            <XAxis hide={hideAxis} dataKey="date" fontSize="10px" interval="preserveStartEnd" />
            <YAxis
              hide={hideAxis}
              yAxisId="left"
              domain={[Math.floor(minClose * 0.95), Math.ceil(maxClose * 1.02)]}
              allowDataOverflow
              fontSize="10px"
            />
            <YAxis
              hide
              yAxisId="right"
              orientation="right"
              domain={[minVolume, maxVolume * 2]}
              allowDataOverflow
              fontSize="10px"
              tickLine={false}
            />
            {!hideAxis && <Tooltip isAnimationActive={false} content={<CustomTooltip />} />}
            <Area yAxisId="left" fill="url(#colorClose)" dataKey="close" stroke="#007560" activeDot={{ r: 8 }} isAnimationActive={false} />
            <Bar yAxisId="right" dataKey="volume" fill="#93abcc" isAnimationActive={false} />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    );
  }
);

StockEquityChart.displayName = "StockEquityChart";
