import type { ButtonProps } from "@chakra-ui/react";
import { Switch } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { Box, Button, Input, Stack, Textarea, useColorModeValue } from "@chakra-ui/react";
import React, { useState, useContext, useEffect } from "react";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import {
  getEntitiesForFocusedItem,
  getEntitiesForSelectedItems,
  getSelectedItemsByType,
  getSelectedItemValueStringByType,
  useEntitlements,
  useExternalIntegrations,
  useItemSelector,
} from "hooks";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useLocation } from "react-router";
import { LinkLogo } from "screens/common/components";
import { RiMailSendLine } from "react-icons/ri";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";

interface Props {
  setTitle: (title: string) => void;
  setShareTypeIntent: (intent: "share_resources" | "publish_chat") => void;
}

export const ShareContent = ({ setTitle, setShareTypeIntent }: Props) => {
  const [isShareTags, setIsShareTags] = useState<false | true>(true);
  const [isShareResourcesOnly, setIsShareResourcesOnly] = useState<false | true>(false);
  const [isShareVia, setIsShareVia] = useState("email");
  const [shareDestination, setShareDestination] = useState("");
  const [inputSubject, setInputSubject] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const { selectedItems, focusedItem } = useItemSelector();
  const { setRequestEntities } = useContext(ConversationContext);
  const { isHighlightsPanelOpen } = useAddToCharliContext();
  const { pathname } = useLocation();
  const route = pathname.split("/")[1];
  const { availableIntegrations: messagingIntegrations } = useExternalIntegrations("Team Communications");
  const { availableIntegrations: projectManagementIntegrations } = useExternalIntegrations("Project Management");
  const { availableIntegrations: ticketManagementIntegrations } = useExternalIntegrations("Ticket Management");
  const integrations = [...messagingIntegrations, ...projectManagementIntegrations, ...ticketManagementIntegrations];
  const { ui_share_highlights: isShareHighlights } = useEntitlements();
  const selectedHighlights = getSelectedItemsByType(selectedItems, "highlight_id");
  const buttonColor = useColorModeValue("gray.300", "gray.700");
  const bgColor = useColorModeValue("white", "gray.600");

  useEffect(() => {
    if (isShareVia === "Slack Communications") {
      setShareTypeIntent("publish_chat");
      updateBatchRequestEntities([{ entity: "channel", value: shareDestination.trim(), source: "share-inputs" }], setRequestEntities);
    } else {
      setShareTypeIntent("share_resources");
    }
    if (isHighlightsPanelOpen && selectedHighlights) {
      setTitle("Share Selected Highlights");
      const selectedEntities = getEntitiesForSelectedItems(selectedHighlights);
      const uniqueEntities = [...new Set(selectedEntities.map((item) => item.entity))].map((entity) => {
        const values = selectedEntities.filter((item) => item.entity === entity).map((item) => item.value);
        return { entity, value: values.join(", ") };
      });

      uniqueEntities.forEach((item) =>
        updateBatchRequestEntities([{ entity: item.entity, value: item.value, source: "share-inputs" }], setRequestEntities)
      );
    }
    if (isShareResourcesOnly && !isHighlightsPanelOpen) {
      setTitle("Share Selected Resources");
      const selectedEntities = getEntitiesForSelectedItems(selectedItems);

      const uniqueEntities = [...new Set(selectedEntities.map((item) => item.entity))].map((entity) => {
        const values = selectedEntities.filter((item) => item.entity === entity).map((item) => item.value);
        return { entity, value: values.join(", ") };
      });
      // clear the collection_id if user only wants to share selected resources
      updateBatchRequestEntities([{ entity: "collection_id", value: "", source: "collection-selector" }], setRequestEntities);
      uniqueEntities.forEach((item) =>
        updateBatchRequestEntities([{ entity: item.entity, value: item.value, source: "selection" }], setRequestEntities)
      );
    }
    if (focusedItem && !isShareResourcesOnly && !isHighlightsPanelOpen) {
      setRequestEntities([]);
      setTitle(`Share`);
      const selectedEntities = getEntitiesForFocusedItem(focusedItem);
      updateBatchRequestEntities(
        [{ entity: selectedEntities[0].entity, value: selectedEntities[0].value, source: "selection" }],
        setRequestEntities
      );
    }
    if (shareDestination.trim().length > 0 && isShareVia === "email") {
      updateBatchRequestEntities([{ entity: "email_address", value: shareDestination.trim(), source: "share-inputs" }], setRequestEntities);
    }
    if (inputSubject.trim().length > 0) {
      updateBatchRequestEntities([{ entity: "subject", value: inputSubject.trim(), source: "share-inputs" }], setRequestEntities);
    }
    if (inputMessage.trim().length > 0) {
      updateBatchRequestEntities([{ entity: "message", value: inputMessage.trim(), source: "share-inputs" }], setRequestEntities);
    }
    updateBatchRequestEntities(
      [{ entity: "confirmed_inquiry", value: isShareTags ? "True" : "False", source: "share-inputs" }],
      setRequestEntities
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShareVia, isShareResourcesOnly, shareDestination, inputSubject, inputMessage, isShareTags]);

  const iconProps: ButtonProps = {
    backgroundColor: bgColor,
    border: "1px solid",
    borderColor: buttonColor,
    _active: {
      backgroundColor: buttonColor,
    },
  };

  useEffect(() => {
    isShareVia === "Slack Communications"
      ? !selectedHighlights
        ? setInputMessage(`Here's a link to Charli I'd like to share with you, <${window.location.href}>`)
        : setInputMessage(getSelectedItemValueStringByType(selectedItems, "highlight_id"))
      : setInputMessage("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShareVia]);

  useEffect(() => {
    if (route === "canvas") {
      setIsShareResourcesOnly(true);
    }
  }, [route]);

  return (
    <Stack spacing="1.5rem">
      <Stack>
        <SectionHeader title="Share via" titleStyle={{ marginBottom: "0", lineHeight: "2rem" }} />
        <Stack direction="row" spacing={4}>
          <Button
            leftIcon={<Icon as={RiMailSendLine} boxSize="1rem" color="blue.500" />}
            {...iconProps}
            size="sm"
            isActive={isShareVia === "email"}
            onClick={() => setIsShareVia("email")}
            colorScheme="gray">
            Email
          </Button>
          {integrations.map((integration) => {
            return (
              <Button
                key={integration.integrationId}
                isDisabled={!isShareHighlights || integration.name !== "Slack Communications"} // We only support Slack for now
                leftIcon={integration.domain ? <LinkLogo url={integration.domain} height="1rem" maxWidth="1rem" p="0" /> : undefined}
                {...iconProps}
                size="sm"
                isActive={isShareVia === integration.name}
                onClick={() => setIsShareVia(integration.name)}
                colorScheme="gray">
                {integration.name}
              </Button>
            );
          })}
        </Stack>
      </Stack>
      <Box>
        <SectionHeader title="Share with" />
        <Input
          type="email"
          id="share-input-email"
          placeholder={`${isShareVia === "email" ? "Enter comma separated email addresses" : "Enter a channel name"}`}
          size="sm"
          rounded="10px"
          onBlur={(ev) => setShareDestination(ev.target.value)}
        />
      </Box>
      {isShareVia === "email" && (
        <Box>
          <SectionHeader title="Subject" />
          <Input
            id="share-input-subject"
            placeholder="Add the subject"
            size="sm"
            rounded="10px"
            onBlur={(ev) => setInputSubject(ev.target.value)}
          />
        </Box>
      )}
      <Box>
        <SectionHeader title="Message" />
        <Textarea
          id="share-input-message"
          placeholder="Add a message to go with the content"
          size="sm"
          minHeight="6rem"
          resize="none"
          rounded="5px"
          defaultValue={inputMessage}
          onBlur={(ev) => setInputMessage(ev.target.value)}
        />
      </Box>
      <Stack direction="row" justifyContent="space-between" width="20rem">
        <SectionHeader title="Share the tags and notes?" />
        <Switch
          size="sm"
          isDisabled={isHighlightsPanelOpen}
          id="share-tags-toggle"
          onChange={(event) => setIsShareTags(event.target.checked)}
          isChecked={isShareTags && !isHighlightsPanelOpen}
          colorScheme="teal"
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="20rem">
        <SectionHeader title="Share selected resources only?" />
        <Switch
          size="sm"
          isDisabled={isHighlightsPanelOpen || route === "canvas"}
          id="share-resources-toggle"
          onChange={(event) => setIsShareResourcesOnly(event.target.checked)}
          isChecked={isShareResourcesOnly}
          colorScheme="teal"
        />
      </Stack>
    </Stack>
  );
};
