import { Record, String } from "runtypes";
import type { Static } from "runtypes";

export const WorkflowConfigEventPayload = Record({
  workflowConfigId: String,
  intent: String,
  userId: String,
  userName: String,
});

export type WorkflowConfigEventPayload = Static<typeof WorkflowConfigEventPayload>;
