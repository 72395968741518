import type { JSONSchema7 } from "json-schema";

export const JSONConfigSchema: JSONSchema7 = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "https://charli.ai/schemas/JsonConfig.json",
  type: "object",
  properties: {
    synonym_dict: {
      type: ["object", "null"],
      description: "",
    },
    num_json_level: {
      type: "number",
      description: "",
    },
    json_level_weights: {
      type: "string",
      description: "",
    },
    substitute_by_parent: {
      type: ["array", "string", "null"],
      description: "",
    },
    related_fields: {
      type: ["object", "null"],
      description: "",
    },
  },
  required: ["num_json_level", "json_level_weights"],
};
