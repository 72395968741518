import React from "react";
import { Badge, Box, Heading, Stack, Text } from "@chakra-ui/react";

import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useBillingPlan, useBillingPlanEntitlements } from "hooks/useBillingPlans";
import { EntitlementsList, RenderIfHasEntitlements } from "screens/common/components";
import type { Plan } from "types/billingPlans/Plan";

interface IProps {
  code: string;
  isOpen: boolean;
  onClose: () => void;
}

interface IBillingPlanResumeProps {
  billingPlan: Plan;
}

export const BillingPlanResume = (props: IBillingPlanResumeProps) => {
  const { billingPlan } = props;
  const { entitlements, isLoading } = useBillingPlanEntitlements(billingPlan.code);

  return (
    <Stack spacing={8} px="1rem">
      <Stack my="0.5rem">
        <Stack direction="row" alignItems="center">
          <Heading fontSize="md">Code:</Heading>
          <Badge>{billingPlan.code}</Badge>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Heading fontSize="md">Name:</Heading>
          <Text fontSize="md">{billingPlan.name}</Text>
        </Stack>
      </Stack>

      <Box>
        <Heading marginBottom={4} fontSize="md">
          Entitlements
        </Heading>
        <RenderIfHasEntitlements entitlement="manage_entitlements">
          <EntitlementsList type="plan" entityId={billingPlan.code} entitlements={entitlements} isLoading={isLoading} />
        </RenderIfHasEntitlements>
      </Box>
    </Stack>
  );
};

export const BillingPlanModal = (props: IProps) => {
  const { code, isOpen, onClose } = props;
  const billingPlan = useBillingPlan(code);

  if (!billingPlan) return <></>;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${billingPlan.name} plan details`}>
      <Wizard>
        <PanelStep>
          <BillingPlanResume billingPlan={billingPlan} />
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
