import React, { useState } from "react";
import { Select } from "chakra-react-select";
import { FormControl, Box, Button, useColorModeValue, Stack } from "@chakra-ui/react";
import { useButtonProps, useEntitlements } from "hooks";
import { useIsUserSearching, useUsersSearch } from "hooks/useUsers";
import debounce from "lodash/debounce";
import type { PayloadAction } from "@reduxjs/toolkit";

const MIN_SEARCH_LENGTH = 3;

interface IProps {
  onAdd: (userId: string) => Promise<void | { error?: unknown } | PayloadAction>;
}

export const OrganizationUsersAddFieldArray = (props: IProps) => {
  const [searchText, setSearchText] = useState("@charli.ai");
  const [selectedUserId, setSelectedUserId] = useState<null | { label: string; value: string }>(null);
  const isLoading = useIsUserSearching();
  const { view_users: hasViewUsers } = useEntitlements();

  const bgColor = useColorModeValue("white!important", "gray.700!important");
  const commonButtonProps = useButtonProps("sm", "primary");

  const changedUserSearch = debounce((target) => {
    setSearchText(target);
  }, 500);

  const users = useUsersSearch(searchText.length < MIN_SEARCH_LENGTH ? "" : searchText).map((u) => ({
    value: u.id,
    label: `${(u.firstName ?? "")
      .concat(" ")
      .concat(u.lastName ?? "")
      .trim()} (${u.email})`,
  }));

  const cleanUser = () => {
    setSearchText("");
    setSelectedUserId(null);
  };

  const handleAddUser = async () => {
    if (selectedUserId) {
      props.onAdd(selectedUserId.value);
      cleanUser();
    }
  };

  const selectedUser = (event: { value?: string; label?: string } | null) => {
    if (event === null || !event.label || !event.value) {
      cleanUser();
    } else {
      setSelectedUserId({ value: event.value, label: event.label });
    }
  };

  if (!hasViewUsers) {
    return <></>;
  }

  return (
    <Box backgroundColor={bgColor}>
      <FormControl>
        <Stack direction="row">
          <Box width="100%">
            <Select
              className="ch-multi-select"
              size="sm"
              useBasicStyles
              isLoading={isLoading}
              selectedOptionStyle="check"
              options={users}
              onInputChange={changedUserSearch}
              onChange={(newVal) => selectedUser({ label: newVal?.label, value: newVal?.value })}
              isClearable
              value={{ value: selectedUserId?.value, label: selectedUserId?.label }}
            />
          </Box>
          <Button width="6rem" {...commonButtonProps} onClick={handleAddUser} size="sm" isLoading={isLoading} disabled={!selectedUserId}>
            Add User
          </Button>
        </Stack>
      </FormControl>
    </Box>
  );
};
