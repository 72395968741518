import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import type { RequestEntities } from "types/charliui";

export const SearchParamListener = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const maybeSettingsQueryParam = (() => {
      const urlSearchParams = new URLSearchParams(location.search);

      if (urlSearchParams.has("q")) {
        return urlSearchParams.get("q");
      } else {
        return undefined;
      }
    })();

    if (maybeSettingsQueryParam) {
      onSubmit(maybeSettingsQueryParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const onSubmit = useCallback(
    (searchQuery?: string) => {
      if (!searchQuery) return;
      const newConversationId = uuid();
      const entities: RequestEntities = [];

      searchQuery.split(" ").forEach((word) => {
        if (word.length === 0) return;
        if (word.startsWith("#")) {
          entities.push({ entity: "tag", value: word.replace("#", "").trim() });
        } else {
          entities.push({ entity: "keywords", value: word.trim() });
        }
      });
      dispatch(
        sendMessage({
          conversationId: newConversationId,
          intent: `/find`,
          entities: entities,
        })
      );
      navigate(`/search/${newConversationId}`);
    },
    [dispatch, navigate]
  );

  return <React.Fragment />;
};
