import { createAction, createSlice } from "@reduxjs/toolkit";
import type { UserConfig } from "types/config";
import {
  bulkUpsertProjectConfig,
  createProjectConfig,
  deleteProjectConfig,
  downloadProjectConfigurations,
  updateProjectConfig,
} from "./operations";
import { REHYDRATE } from "redux-persist";
import sortBy from "lodash/sortBy";
import type { RootState } from "state/rootReducer";

const rehydrate = createAction<RootState>(REHYDRATE);

export interface ProjectConfigState {
  id: string;
  config: UserConfig;
  creationDate: string;
  lastModifiedDate: string;
  creationByUserId: string;
  lastModifiedByUserId?: string;
  lastImportedDate: string | null;
  lastImportedByUserId: string | null;
}

interface ConfigState {
  loading: boolean;
  defaultConfigById: Record<string, ProjectConfigState>;
  order: string[];
  hasInitialSyncCompleted: boolean;
}

const initialState: ConfigState = {
  loading: false,
  defaultConfigById: {},
  order: [],
  hasInitialSyncCompleted: false,
};

export const { actions, reducer } = createSlice({
  name: "config",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(rehydrate, (_, action) => {
      if (action.payload?.configMap) {
        return {
          ...initialState,
          defaultConfigById: action.payload.configMap.defaultConfigById,
          order: action.payload.configMap.order,
          hasInitialSyncCompleted: action.payload.configMap.order.length > 0,
        };
      }
    });
    builder.addCase(downloadProjectConfigurations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadProjectConfigurations.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadProjectConfigurations.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.order = sortBy(payload, (data) => (data.config as UserConfig).route !== "dashboard").map((projectConfig) => projectConfig.id);

      const projectsConfigs = payload.reduce(
        (acc: Record<string, ProjectConfigState>, projectConfig) => ({
          ...acc,
          [projectConfig.id]: {
            id: projectConfig.id,
            config: projectConfig.config as UserConfig,
            creationDate: projectConfig.creationDate,
            lastModifiedDate: projectConfig.lastModifiedDate,
            creationByUserId: projectConfig.creationByUserId,
            lastModifiedByUserId: projectConfig.lastModifiedByUserId,
            lastImportedDate: projectConfig.lastImportedDate,
            lastImportedByUserId: projectConfig.lastImportedByUserId,
          },
        }),
        {}
      );

      state.defaultConfigById = projectsConfigs;
      state.hasInitialSyncCompleted = true;
    });
    builder.addCase(updateProjectConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProjectConfig.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateProjectConfig.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.defaultConfigById[payload.id] = {
        id: payload.id,
        config: payload.config as UserConfig,
        creationDate: payload.creationDate,
        lastModifiedDate: payload.lastModifiedDate,
        creationByUserId: payload.creationByUserId,
        lastModifiedByUserId: payload.lastModifiedByUserId,
        lastImportedDate: payload.lastImportedDate,
        lastImportedByUserId: payload.lastImportedByUserId,
      };
    });

    builder.addCase(createProjectConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createProjectConfig.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createProjectConfig.fulfilled, (state, action) => {
      const payload = action.payload;
      state.loading = false;

      state.order = [...state.order, payload.id];

      state.defaultConfigById[payload.id] = {
        id: payload.id,
        config: payload.config as UserConfig,
        creationDate: payload.creationDate,
        lastModifiedDate: payload.lastModifiedDate,
        creationByUserId: payload.creationByUserId,
        lastModifiedByUserId: payload.lastModifiedByUserId,
        lastImportedDate: payload.lastImportedDate,
        lastImportedByUserId: payload.lastImportedByUserId,
      };
    });

    builder.addCase(deleteProjectConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteProjectConfig.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteProjectConfig.fulfilled, (state, action) => {
      state.loading = false;
      const id = action.meta.arg.id;

      state.order = state.order.filter((currentId) => currentId !== id);
      delete state.defaultConfigById[id];
    });

    builder.addCase(bulkUpsertProjectConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bulkUpsertProjectConfig.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(bulkUpsertProjectConfig.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.order = sortBy(payload, (data) => (data.config as UserConfig).route !== "dashboard").map((projectConfig) => projectConfig.id);

      const projectsConfigs = payload.reduce(
        (acc: Record<string, ProjectConfigState>, projectConfig) => ({
          ...acc,
          [projectConfig.id]: {
            id: projectConfig.id,
            config: projectConfig.config as UserConfig,
            creationDate: projectConfig.creationDate,
            lastModifiedDate: projectConfig.lastModifiedDate,
            creationByUserId: projectConfig.creationByUserId,
            lastModifiedByUserId: projectConfig.lastModifiedByUserId,
            lastImportedDate: projectConfig.lastImportedDate,
            lastImportedByUserId: projectConfig.lastImportedByUserId,
          },
        }),
        {}
      );

      state.defaultConfigById = projectsConfigs;
    });
  },
});

export default reducer;
