import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { useCollectionKey, useConfigMap, useContentDetails, useGetViewConfig } from "hooks";
import capitalize from "lodash/capitalize";
import truncate from "lodash/truncate";
import type { FunctionComponent } from "react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationContext } from "screens/thread/ConversationContext";
import type { Message } from "types/conversation";
import { ChatBubble, MessageContainer } from "../components";

interface Props {
  message: Message;
  label: string;
  metadataId: string;
}

export const SingleItemCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  message,
  label,
  metadataId,
}) => {
  const { onConversationClose } = useContext(ConversationContext);
  const navigate = useNavigate();

  const configMap = useConfigMap();
  const contentDetails = useContentDetails(metadataId);
  const maybeContentId = contentDetails?.id;
  const maybeContentType = contentDetails?.type;

  const collectiontype = useCollectionKey(maybeContentId, "collectionType");
  const projectRoute = useGetViewConfig("route", collectiontype, configMap);
  const projectTitle = useGetViewConfig("title", collectiontype, configMap);

  const { buttonLabel, buttonSubText } = (() => {
    if (collectiontype) {
      // this is not actually possible right now, but just in case, we handle collections
      return {
        buttonLabel: projectTitle,
        buttonSubText: undefined,
      };
    } else {
      if (label !== "Canvas") {
        return {
          buttonLabel: label,
          buttonSubText: contentDetails?.name,
        };
      } else if (contentDetails) {
        if (contentDetails.urls) {
          return {
            buttonLabel: contentDetails.name,
            buttonSubText: truncate(contentDetails.urls.url !== null ? contentDetails.urls.url : undefined, { length: 35 }),
          };
        } else {
          return {
            buttonLabel: contentDetails.name,
            buttonSubText: maybeContentType ? capitalize(maybeContentType) : undefined,
          };
        }
      } else {
        return {
          buttonLabel: maybeContentType ? capitalize(maybeContentType) : label,
          buttonSubText: undefined,
        };
      }
    }
  })();

  const onClick = () => {
    onConversationClose();

    if (projectRoute && projectRoute.length > 0) {
      return navigate(`/${projectRoute}/${maybeContentId}`);
    } else if (maybeContentId) {
      return navigate(`/library/resource/${maybeContentId}`);
    } else {
      return undefined;
    }
  };

  return (
    <MessageContainer initiator={message.senderId === "charli" ? "charli" : "user"} key={`${message.id}-button-${label}`}>
      <Box position="relative">
        <ChatBubble
          className="ch-single-item-cell"
          initiator={message.senderId === "charli" ? "charli" : "user"}
          state={message.state}
          onClick={onClick}>
          <Stack direction="row" alignItems="center" p="1">
            <Flex flexDirection="column">
              <Text fontSize="sm" fontWeight={600}>
                {buttonLabel}
              </Text>
              {buttonSubText && <Text fontSize="sm">{buttonSubText}</Text>}
            </Flex>
            <ChevronRightIcon boxSize="1.5rem" />
          </Stack>
        </ChatBubble>
      </Box>
    </MessageContainer>
  );
};
