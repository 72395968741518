import { Stack } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useMemo, useCallback } from "react";
import type { ExtractedHighlights, Highlights } from "types/collection";
import orderBy from "lodash/orderBy";
import { HighlightsListItem } from "./HighlightsListItem";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { BiCopy } from "react-icons/bi";

interface Props {
  extractedHighlights?: ExtractedHighlights[];
  textTruncated?: boolean;
  onClose?: () => void;
  maxHighlights?: number;
  searchPhrase?: string;
}

export const HighlightsSimpleList: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  extractedHighlights,
  textTruncated = false,
  onClose,
  maxHighlights,
  searchPhrase,
}) => {
  const getHighlightsForTopic = (selectedTopic?: string) => {
    let filtered = extractedHighlights;
    if (selectedTopic) filtered = extractedHighlights?.filter((topics) => topics.topic === selectedTopic);

    const selectedTopicHighlights = filtered
      ?.map((topics) => {
        let topicSection = topics.highlights.length ? `Topic ${topics.topic} \r\n` : "";
        topics.highlights.map((highlights) => (topicSection += highlights.highlight + "\r\n"));
        return topicSection + "\r\n";
      })
      .join("");
    return selectedTopicHighlights || "";
  };

  const filteredHighlights = useMemo(() => {
    const orderedHighlights = orderBy(extractedHighlights || [], ["creationDate"], ["desc"]);

    if (searchPhrase && searchPhrase.length > 0) {
      return orderedHighlights.filter(
        (highlight) =>
          highlight.topic.toLowerCase().includes(searchPhrase.toLowerCase()) ||
          highlight.highlights.filter((highlightItem) => highlightItem.highlight.toLowerCase().includes(searchPhrase.toLowerCase()))
            .length > 0
      );
    }

    return orderedHighlights.slice(0, maxHighlights ? maxHighlights : orderedHighlights.length);
  }, [extractedHighlights, searchPhrase, maxHighlights]);

  const getHighlightsToRender = useCallback(
    (highlights: Highlights[]) => {
      return highlights
        .slice(0, textTruncated ? 1 : maxHighlights ? maxHighlights : highlights.length)
        .flatMap((highlightRecord) => (highlightRecord.highlight.length > 0 ? [highlightRecord] : []));
    },
    [textTruncated, maxHighlights]
  );

  return (
    <>
      {filteredHighlights.map((topicRecord, index) =>
        topicRecord.highlights.length > 0 ? (
          <Stack key={topicRecord.topic} spacing="1rem" width="100%" pb="1rem">
            <BlockSectionHeader title={`AI Highlights: ${topicRecord.topic}`} className="ch-highlight-topic" direction="row">
              <SmallActionButton
                classNames="ch-copy-record"
                iconTypeName={BiCopy}
                onClick={() => navigator.clipboard.writeText(getHighlightsForTopic(topicRecord.topic))}
                tooltip={"Copy Highlights"}
              />
            </BlockSectionHeader>
            <Stack spacing="1rem" cursor={textTruncated ? "pointer" : "unset"} width="100%">
              {getHighlightsToRender(topicRecord.highlights).map((highlightRecord, index) => (
                <Stack direction="row" key={highlightRecord.id} width="100%">
                  <HighlightsListItem
                    highlightRecord={highlightRecord}
                    topic={topicRecord.topic.length > 0 ? `AI Highlight: ${topicRecord.topic}` : undefined}
                    searchText={searchPhrase}
                    showDivider={topicRecord.highlights.length > 1}
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>
        ) : null
      )}
    </>
  );
};
