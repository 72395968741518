import { Box, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useCollection, useConfigForm, useConfigMap, useGetViewConfig, useItemSelector, useProjectParams, useTabProps } from "hooks";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LatestContentList } from "screens/content/common/LatestContent/LatestContentList";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { PanelView } from "screens/panels/components/PanelView";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useAddToCharliContext } from "./AddToCharliProvider";
import { CollectionSelector } from "screens/landing/components";
import { AddToCharliUploadForm } from "./AddToCharliUploadForm";
import { AddToCharliBookmarkForm } from "./AddtoCharliBookmarkForm";
import { useSendToCharli } from "./useSendToCharli";
import { getRequestValue } from "screens/conversation/components/RequestEntitiesUtils";
import { AddTagNoteDetails } from "./AddTagNoteDetails";
import { ResearchContext } from "screens/panels/research/ResearchContext";
import { AddTagDetails } from "./AddTagDetails";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AddToCollectionPanel = ({ isOpen, onClose }: Props) => {
  const { projectId } = useProjectParams();
  const { resetItems, items } = useItemSelector();
  const [panelTitle, setPanelTitle] = useState("");
  const { setRequestEntities, requestEntities, onConversationOpen } = useContext(ConversationContext);
  const { uppyFiles, resetFormState, shouldCreateNewCollection, showContentList, isNewMenuAction } = useAddToCharliContext();
  const { sendRequest, useCharliRequestLog } = useSendToCharli();
  const [selectedTabIntent, setSelectedTabIntent] = useState<"add_resources" | "add_link" | "add_collection_content" | "store_collection">(
    "add_resources"
  );
  const { pathname } = useLocation();
  const isProject = pathname.includes("project");
  const route = pathname.split("/").slice(isProject ? 2 : 1, isProject ? 3 : 2)[0];
  const configMap = useConfigMap();
  const { projectType } = useContext(ResearchContext);
  const collectionType = useGetViewConfig("collectionType", projectType || route, configMap);
  const { projectForm } = useConfigForm(collectionType || "", configMap);
  const uploadStepTags = projectForm?.uploadStepTags;
  const showUploadMaxFiles = projectForm?.showUploadMaxFiles;
  const [selectedCollectionName, setSelectedCollectionName] = useState("");
  const [selectedCollectionId, setSelectedCollectionId] = useState("");
  const navigate = useNavigate();
  const { defaultTabProps } = useTabProps();

  useEffect(() => {
    getRequestValue("collection_name", requestEntities) && setSelectedCollectionName(getRequestValue("collection_name", requestEntities));
    getRequestValue("collection_id", requestEntities) && setSelectedCollectionId(getRequestValue("collection_id", requestEntities));
  }, [requestEntities]);

  const collection = useCollection(projectId);

  const getNavigation = useMemo(() => {
    return () => {
      const projectPath =
        collection && collection.collectionType === "library" ? "library" : `projects/${collection && collection.collectionType}`;
      collection && navigate(`/${projectPath}/${collection.id}`);
    };
  }, [collection, navigate]);

  const getCommand = useMemo(() => {
    return selectedTabIntent === "add_collection_content" && collection ? getNavigation : () => onConversationOpen();
  }, [selectedTabIntent, collection, getNavigation, onConversationOpen]);

  const send = () => {
    selectedTabIntent &&
      sendRequest(
        selectedTabIntent,
        onClose,
        `${
          selectedTabIntent === "add_collection_content"
            ? "I'll send the selected resource to the project now. Click here if you want to open the project."
            : "I'll add that now. You can open the conversation I started about it by clicking this message"
        }`,
        getCommand
      );
    resetFormState();
  };

  useEffect(() => {
    if (isNewMenuAction === "resource") {
      setPanelTitle("Add new Resource to the Library");
    } else {
      setPanelTitle(`${!shouldCreateNewCollection ? "Add a New Resource to the Project" : "Create a new Project"}`);
    }

    if (uppyFiles.length > 0 && Object.keys(items).length === 0) {
      setSelectedTabIntent("add_resources");
    } else if (selectedCollectionId && !selectedCollectionName) {
      setSelectedTabIntent("add_collection_content");
    } else if (shouldCreateNewCollection || selectedCollectionName.length > 0) {
      setSelectedTabIntent("store_collection");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    items,
    isNewMenuAction,
    shouldCreateNewCollection,
    uppyFiles.length,
    route,
    collection,
    selectedCollectionId,
    selectedCollectionName,
  ]);

  const resetPanel = () => {
    resetFormState();
    resetItems();
    setRequestEntities([]);
    setSelectedCollectionName("");
    setSelectedCollectionId("");
    setSelectedTabIntent("add_resources");
  };

  useEffect(() => {
    if (!isOpen) {
      resetPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useCharliRequestLog(isOpen);

  const handleTabIntent = (index) => {
    switch (index) {
      case 0:
        setSelectedTabIntent("add_resources");
        break;
      case 1:
        setSelectedTabIntent("add_link");
        break;
      case 2:
        setSelectedTabIntent(`${!isNewMenuAction && collection?.id ? "add_collection_content" : "store_collection"}`);
        break;
    }
  };

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={panelTitle}>
      <Wizard>
        <PanelStep
          onNextLabel={`${isNewMenuAction ? "Resource Details" : !shouldCreateNewCollection ? "Add to Project" : "Create Project"}`}
          onSubmit={isNewMenuAction ? undefined : send}>
          <Stack spacing="2rem" pt="1.5rem">
            {(!showContentList || shouldCreateNewCollection) && isNewMenuAction !== "resource" && (
              <Box>
                <SectionHeader title="Project Name" />
                <CollectionSelector shouldCreateNewCollection={shouldCreateNewCollection} collectionType="all" />
              </Box>
            )}
            {(isNewMenuAction === "resource" || showContentList) && (
              <Tabs isFitted onChange={handleTabIntent}>
                <TabList>
                  <Tab className="ch-add-to-charli-option-upload" {...defaultTabProps}>
                    Local Resource
                  </Tab>
                  <Tab className="ch-add-to-charli-option-bookmark" {...defaultTabProps}>
                    Add a link
                  </Tab>
                  {isNewMenuAction !== "resource" && (
                    <Tab className="ch-add-to-charli-option-existing" {...defaultTabProps}>
                      Add existing Resource
                    </Tab>
                  )}
                </TabList>
                <TabPanels>
                  <TabPanel px="0" pt="1rem">
                    <AddToCharliUploadForm height="calc(100vh - 16rem)" maxFiles={showUploadMaxFiles} />
                  </TabPanel>
                  <TabPanel px="0">
                    <AddToCharliBookmarkForm />
                  </TabPanel>
                  {isNewMenuAction !== "resource" && (
                    <TabPanel px="0">
                      <LatestContentList
                        maxListHeight={shouldCreateNewCollection ? "calc(100vh - 20rem)" : "calc(100vh - 16rem)"}
                        minChildWidth={130}
                      />
                    </TabPanel>
                  )}
                </TabPanels>
              </Tabs>
            )}
            {(uploadStepTags || uploadStepTags === "") && !isNewMenuAction && <AddTagDetails defaultTags={uploadStepTags} />}
          </Stack>
        </PanelStep>
        {isNewMenuAction && (
          <PanelStep
            onPreviousLabel="Select Resource"
            onNextLabel={
              isNewMenuAction === "resource"
                ? "Add Resource"
                : route === "library" || !shouldCreateNewCollection
                ? "Add to Project"
                : "Create Project"
            }
            onSubmit={send}>
            <Box pt="1.5rem">
              <AddTagNoteDetails collectionEntitySet={isNewMenuAction === "resource" && !projectId} />
            </Box>
          </PanelStep>
        )}
      </Wizard>
    </PanelView>
  );
};
