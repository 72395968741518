import React from "react";
import { Center, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { Tooltip, YAxis, ResponsiveContainer, LineChart, Line, XAxis } from "recharts";
import type { DataType } from "hooks/useStats";
import { defaultProps } from "react-select/creatable";
import { CustomTooltipAreaChart } from "./CustomChartUtils";

interface Props {
  data: DataType[];
  width?: number | string;
  height?: number | string;
  chartHeight?: number;
  title?: string;
  chartId?: string;
  showXAxis?: boolean;
  showYAxis?: boolean;
  xAxisWidth?: number;
  lineColor?: string;
}

export const CustomLineChart: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  data,
  width = "100%",
  height = "13rem",
  chartHeight = 100,
  title,
  chartId,
  showXAxis = false,
  showYAxis = false,
  xAxisWidth = 30,
  lineColor,
}) => {
  const defaultLineColor = useColorModeValue("#8295b5", "gray.300");

  return (
    <Stack height={height} width={width}>
      {data.every((item) => !item.value) ? (
        <Center height={height || "13rem"} pb="1rem" fontSize="xs">
          No Information
        </Center>
      ) : (
        <Stack height={height} width={width}>
          <ResponsiveContainer width={"100%"} height={chartHeight}>
            <LineChart id={chartId} data={data} margin={{ top: 0, right: 0, left: 0, bottom: 12 }}>
              <Tooltip
                isAnimationActive={false}
                content={<CustomTooltipAreaChart active={defaultProps.active} payload={defaultProps.payload} />}
              />
              {showXAxis && <XAxis dataKey="name" fontSize={"10px"} interval={2} width={xAxisWidth} />}
              {showYAxis && <YAxis axisLine={false} tickLine={false} width={15} fontSize={"10px"} />}
              <Line strokeWidth={3} type="monotone" dataKey="value" stroke={lineColor || defaultLineColor} />
            </LineChart>
          </ResponsiveContainer>
          {title && (
            <Text mt="0!important" fontSize={"xs"} width={"100%"} align={"center"} pl="3rem">
              {title}
            </Text>
          )}
        </Stack>
      )}
    </Stack>
  );
};
