import { prependProxyBaseUrl, request } from "../request";
import type { ShareHistoryResponseType } from "./ShareHistoryResponse";
import { ShareHistoryResponse } from "./ShareHistoryResponse";

const PROXY_PREFIX = prependProxyBaseUrl("/api/share");
const SHARE_HISTORY_ENDPOINT_PATH = (metadataId: string) => `${PROXY_PREFIX}/metadata/${metadataId}/share-history`;

export async function getShareHistory(metadataId: string): Promise<ShareHistoryResponseType> {
  const shareHistory = await (async () => {
    try {
      return await request()
        .url(`${SHARE_HISTORY_ENDPOINT_PATH(metadataId)}`)
        .get()
        .json();
    } catch (error) {
      return undefined;
    }
  })();

  const validationResult = ShareHistoryResponse.validate(shareHistory);

  if (validationResult.success) {
    return validationResult.value;
  } else {
    console.warn(`Could not fetch share history <- ${JSON.stringify(validationResult.details)}`);

    return {
      sharedByMe: [],
      sharedWithMe: [],
    };
  }
}
