import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../state/rootReducer";
import { useEffect } from "react";
import { useEntitlements } from "./useEntitlements";
import { downloadSystemPreferences } from "state/systemPreference/operations";
import type { Preference } from "api/systemPreferences/models/Preference";

export function useSystemPreferences(): Preference[] {
  const dispatch = useDispatch();
  const preferences = useSelector((state: RootState) => state.systemPreference.systemPreferences);
  const userEntitlements = useEntitlements();
  const { manage_system_preferences_read: hasManageSystemPreferences } = userEntitlements;

  useEffect(() => {
    if (!hasManageSystemPreferences) {
      return;
    }

    dispatch(downloadSystemPreferences());
  }, [dispatch, hasManageSystemPreferences]);

  return preferences;
}

export function useSystemPreference(key: string | null): Preference | undefined {
  return useSelector((state: RootState) => state.systemPreference.systemPreferences.find((e) => e.key === key));
}

export function useSystemKeyPreferences(): string[] {
  return useSelector((state: RootState) => state.systemPreference.keys);
}

export function useIsSystemPreferenceLoading(): boolean {
  return useSelector((state: RootState) => state.systemPreference.isLoading);
}
