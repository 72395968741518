import type { Middleware } from "redux";
import { downloadCollections } from "state/collection/operations";
import { actions as conversationActions } from "state/conversation/reducer";
import type { Message } from "types/conversation";
import { downloadIntegrations } from "state/integrations/operations";
import { actions as meetingSyncActions } from "state/meetings/reducer";
import isEmpty from "lodash/isEmpty";
import { updateUserPreference, deleteUserPreference, updateTypedUserPreference } from "state/userPreference/operations";
import { getMemberOrganizations } from "state/memberOrganizations/operations";
import { downloadEntitlements } from "state/entitlements/operations";
import { requestToDownloadContents } from "state/content/utils";

export const refreshUserDataMiddleware: Middleware = (store) => (next) => (action) => {
  next(action);
  if (action.type === updateUserPreference.fulfilled.type || action.type === updateTypedUserPreference.fulfilled.type) {
    const userPreference = action.payload as string;
    // check that userPreference is a string
    if (typeof userPreference !== "string") {
      return;
    }
    if (userPreference.includes("activityhandler:v2:external:") || userPreference.includes("activityhandler:v2:internal:")) {
      store.dispatch(downloadIntegrations() as never);
    }
  }

  if (action.type === deleteUserPreference.fulfilled.type) {
    store.dispatch(downloadIntegrations() as never);
  }

  if (action.type === conversationActions.addMessage.type) {
    const message = action.payload as Message;
    const contentsToDownload = new Set<string>();

    if (message.data) {
      message.data.forEach((uiElement) => {
        if (uiElement.type === "collection_button") {
          store.dispatch(downloadCollections({ ids: [uiElement.body.collectionId] }) as never);
        } else if (uiElement.type === "canvas_button") {
          uiElement.body.metadataIds.forEach((metadataId) => contentsToDownload.add(metadataId));
        } else if (uiElement.type === "refresh_organization") {
          // reload page for getting data updated based on new organization
          store.dispatch(getMemberOrganizations() as never);
          store.dispatch(downloadEntitlements() as never);
        }
      });
    }

    if (message.state === "task_in_progress") {
      switch (message.intent) {
        case "sync_meetings":
          if (message.data) {
            message.data.forEach((uiElement) => {
              if (uiElement.type === "unprocessed_calendar_events") {
                if (!isEmpty(uiElement.body.calendar_events)) {
                  const meetingData = uiElement.body.calendar_events.flatMap((meeting) => [meeting]);
                  store.dispatch(meetingSyncActions.syncMeetings(meetingData));
                }
              }
            });
          }
          break;
        default:
          break;
      }
    }

    if (message.state === "task_error" && message.intent === "resolve_video_conference_event") {
      store.dispatch(
        meetingSyncActions.setCheckedVideoConferenceEvents({
          conversationId: message.conversationId,
          resolution: { error: true },
        })
      );
    }

    if (message.state === "task_in_progress" && message.intent === "resolve_video_conference_event") {
      if (message.data) {
        message.data.forEach((uiElement) => {
          if (uiElement.type === "exposed_entity") {
            // store.dispatch(meetingSyncActions.setCurrentVideoConferenceEvent({ error: false, videoConferenceEvent: uiElement.body }));
            store.dispatch(
              meetingSyncActions.setCheckedVideoConferenceEvents({
                conversationId: message.conversationId,
                resolution: { error: false, videoConferenceEvent: uiElement.body },
              })
            );
          }
        });
      }
    }

    if (
      (message.state === "task_complete" || message.state === "task_in_progress") &&
      (message.intent === "find" ||
        message.intent === "store_link" ||
        message.intent === "add_link" ||
        message.intent === "file_document" ||
        message.intent === "upload_new_file" ||
        message.intent === "share" ||
        message.intent === "share_resources" ||
        message.intent === "share_documents" ||
        message.intent === "process_receipt" ||
        message.intent === "organize")
    ) {
      message.data?.forEach((values) => {
        if (values.type === "charli-ui" && values.body.buttonElement.content.metadataIds) {
          values.body.buttonElement.content.metadataIds.forEach((metadataId: string) => contentsToDownload.add(metadataId));
        }
      });
    }

    if (message.state === "task_complete") {
      store.dispatch(downloadIntegrations() as never); // this is a workaround for the fact that the integrations do not download after a remove integration action
    }

    if (contentsToDownload.size > 0) {
      requestToDownloadContents({ metadataIds: Array.from(contentsToDownload) }, store.dispatch);
    }
  }
};
