import { prependProxyBaseUrl, request } from "api/request";

const ASTRELLA_REFRESH_ENDPOINT = prependProxyBaseUrl("/api/astrella/refresh");

export async function refreshAstrellaToken() {
  try {
    await request().url(ASTRELLA_REFRESH_ENDPOINT).post().res();
  } catch (err) {
    console.warn("Failed to refresh Astrella access token", err);
  }
}
