import React from "react";
import type { FunctionComponent } from "react";
import { ContentImage } from "./ContentImage";
import { ContentImageGrid } from "./ContentImageGrid";
import { useContentFromCollectionOrContents } from "hooks";

export interface Props {
  thumbnailWidth?: string;
  thumbnailHeight?: string;
  fit?: "cover" | "fill" | "contain" | "none" | "scale-down";
  thumbnailFit?: "cover" | "fill" | "contain";
  thumbnailPosition?: "top" | "center";
  thumbnailBorderRadius?: string;
  onClick?: () => void;
  metadataId: string;
  bgColor?: string;
  maxHeight?: string;
}

export const ContentImageWrapper: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
  const { metadataId } = props;
  const contentData = useContentFromCollectionOrContents(metadataId);

  if (contentData && (contentData.type === "collection" || contentData.urls?.url?.includes("/collection/"))) {
    return <ContentImageGrid {...props} />;
  } else {
    return <ContentImage {...props} />;
  }
};
