import { Record, String, Number, InstanceOf, Union, Array } from "runtypes";
import type { Static } from "runtypes";

export const RelevantIntent = Record({
  intent: String,
  intentCount: Union(String, Number),
  lastCompletionDate: Union(InstanceOf(Date), String),
});

export type RelevantIntent = Static<typeof RelevantIntent>;

export const GetRelevantIntents = Array(RelevantIntent);

export type GetRelevantIntents = Static<typeof GetRelevantIntents>;
