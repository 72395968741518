import { Box, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef } from "react";
import type { FunctionComponent } from "react";
import { Rnd } from "react-rnd";
import { useOutlet } from "react-router-dom";
import { debounce } from "ts-debounce";
import { ConversationContext } from "screens/thread/ConversationContext";

const DEFAULT_WIDTH = 550;

export const FloatingRouterOutlet: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = ({ children }) => {
  const outlet = useOutlet();
  const ref = useRef<Rnd>(null);
  const canvasThreadContainerRef = useRef<HTMLDivElement>(null);
  const defaultWidth = Math.min(DEFAULT_WIDTH, window.innerWidth * 0.5);
  const defaultHeight = window.innerHeight * 0.7;
  const defaultX = window.innerWidth - defaultWidth - 40; // Aligned horizontally to just below the command bar
  const defaultY = window.innerHeight - defaultHeight - 40; //  Aligned vertically to the edge of the command bar
  const isWide = useBreakpointValue({ base: false, md: true });
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("lightgray", "#4A5568");
  const boxShadow = useColorModeValue(
    "rgb(0 0 0 / 20%) -2px 1px 20px 0px, rgb(0 0 0 / 5%) 8px 7px 6px 3px",
    "0 10px 15px -3px rgba(115, 115, 115, 0.2), 0 4px 6px -2px rgba(115, 115, 115, 0.05)"
  );
  const { isConversationOpen } = useContext(ConversationContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  // Listen for window resize events, and move the window back into frame as needed
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      if (!ref.current) return;
      const selfElement = ref.current.getSelfElement();
      if (!selfElement) return;

      const windowPosition = ref.current.getDraggablePosition();
      const width = selfElement.offsetWidth;
      const height = selfElement.offsetHeight;

      // Is the window now out of the browser's viewport?
      const isHorizontallyOutOfViewport = windowPosition.x + width > window.innerWidth;
      const isVerticallyOutOfViewport = windowPosition.y + height > window.innerHeight;

      if (isHorizontallyOutOfViewport || isVerticallyOutOfViewport) {
        // Reset position and size
        ref.current.updatePosition({
          x: Math.max(window.innerWidth - width - 30, 25),
          y: Math.max(window.innerHeight - height - 75, 50),
        });
        ref.current.updateSize({
          width: Math.min(DEFAULT_WIDTH, window.innerWidth * 0.5),
          height: window.innerHeight * 0.7,
        });
      }
    }, 250);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [ref, defaultHeight, defaultWidth]);

  // Prevents to render the component twice
  if (isWide === undefined) {
    return <React.Fragment />;
  }

  /*
   * Note: Safari will offset an element's children if it's both position:fixed and overflow:hidden. Therefore, <Rnd /> has the honor of setting the position tag,
   * while its child <Box /> sets the overflow. Don't combine them.
   */
  if ((children || outlet) && isConversationOpen) {
    if (isWide) {
      // If user is on a desktop sized viewport, show the router outlet as moveable & resizable window.
      return (
        <Rnd
          ref={ref}
          default={{ width: defaultWidth, height: defaultHeight, x: defaultX, y: defaultY }}
          minWidth={400}
          minHeight={400}
          dragHandleClassName="titleBar"
          bounds="#app-container"
          style={{
            zIndex: 10000,
            border: `1.5px solid ${borderColor}`,
            backgroundColor: bgColor,
            borderRadius: isMobile ? "0" : "1rem",
            margin: 0,
            padding: 0,
            boxShadow: boxShadow,
          }}>
          <Box borderRadius="1rem" height="100%">
            <Box height="100%" ref={canvasThreadContainerRef} id="canvasThreadContainer">
              {children || outlet}
            </Box>
          </Box>
        </Rnd>
      );
    } else {
      // Otherwise, show the outlet fullscreen.
      return (
        <Box
          position="absolute"
          zIndex={1500}
          width="100vw"
          backgroundColor={bgColor}
          height="-webkit-fill-available"
          minHeight="100vh"
          top={0}
          left={0}>
          {children || outlet}
        </Box>
      );
    }
  }

  return <React.Fragment />;
};
