import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";

/**
 * Returns feature flag values
 * If a user is logged in, the values returned will include any flags states specific to that user
 * If no user is logged in, the values returned will be the global feature flag values
 */
export function useFeatureFlags() {
  return useSelector((state: RootState) => state.flag.flags);
}
