import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizationsPreferences, putOrganizationPreference, deleteOrganizationPreference } from "api/organizationsPreferences";
import type { Value } from "api/organizationsPreferences/models/Value";

export const downloadOrganizationsPreferences = createAsyncThunk("organizations-preferences/download", async () => {
  return await getOrganizationsPreferences();
});

export const upsertOrganizationPreference = createAsyncThunk(
  "organizations-preferences/upsert",
  async ({ organizationId, key, value }: { organizationId: string; key: string; value: Value }) => {
    return putOrganizationPreference(organizationId, key, value);
  }
);

export const removeOrganizationPreference = createAsyncThunk(
  "organizations-preferences/remove",
  async ({ organizationId, key }: { organizationId: string; key: string }) => {
    return await deleteOrganizationPreference(organizationId, key);
  }
);
