import { Box, Divider, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { useTileProps } from "hooks";
import React from "react";
import { Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, LineChart, Legend } from "recharts";
import { ProjectHeaderImage } from "screens/landing/tabs/project/projectLandingTileLayouts/components/ProjectHeaderImage";

export interface ReturnOnEquityChartData {
  quarter: string;
  [key: string]: { value: number; projectId: string } | string;
}

interface ReturnOnEquityChartDataProps {
  data: ReturnOnEquityChartData[];
}

const ReturnOnEquityChart: React.FC<ReturnOnEquityChartDataProps> = ({ data }) => {
  const commonTileProps = useTileProps();

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Stack {...commonTileProps} bg="white" p={2} borderRadius="md" boxShadow="md">
          <Text fontWeight="bold">{label}</Text>
          <Divider />
          {payload.map((entry: any, index: number) => {
            const dataPoint = data[0][entry.name];
            const projectId = typeof dataPoint === "object" ? dataPoint.projectId : undefined;
            return (
              <Stack direction={"row"} key={index} spacing={"1rem"}>
                {projectId && <ProjectHeaderImage maxHeight="1rem" width="7rem" collectionId={projectId} logoOnly />}
                <Text fontSize={"sm"} color={entry.color}>
                  {entry.value.toFixed(2)}
                </Text>
              </Stack>
            );
          })}
        </Stack>
      );
    }
    return null;
  };

  const dataKeys = Object.keys(data[0]).filter((key) => key !== "quarter");
  const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#0088FE"];
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });

  return (
    <Box height="19rem" width={isMobile ? "4/3" : "31rem"}>
      <ResponsiveContainer>
        <LineChart data={data} margin={{ top: 0, right: 0, left: -25, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" vertical horizontal />
          <XAxis dataKey="quarter" fontSize="10px" angle={-45} textAnchor="end" height={60} />
          <YAxis fontSize="10px" tickFormatter={(value) => `${value}%`} />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ fontSize: "10px" }} />
          {dataKeys.map((key, index) => (
            <Line
              isAnimationActive={false}
              key={key}
              type="monotone"
              name={key}
              dataKey={(item: any) => item[key]?.value}
              stroke={colors[index % colors.length]}
              activeDot={{ r: 8 }}
              dot={{ r: 4 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ReturnOnEquityChart;
