import type { FunctionComponent } from "react";
import React, { useState } from "react";
import { Stack, Text, Tooltip, useColorModeValue, useToast } from "@chakra-ui/react";
import { ChevronRightIcon, DownloadIcon } from "@chakra-ui/icons";
import type { Message } from "types/conversation";
import { ChatBubble, MessageContainer, TypingIndicator } from "./components";
import type { File } from "types/charliui";
import { request } from "api/request";
import { getAccessToken } from "api/auth";

interface Props {
  message: Message;
  datum: File;
}

const getDownloadLink = (id: string) => {
  const relativeUrl = `api/media/internal/download-file/${id}`;

  if (window.location.hostname === "localhost") {
    return `http://localhost:3010/${relativeUrl}`;
  } else {
    return `/${relativeUrl}`;
  }
};

const downloadFile = async (id: string) => {
  const url = getDownloadLink(id);
  // // Perform a HEAD request to see if the file exists - this will throw if it doesn't
  await request().url(url).head().res();

  // Redirect the user to the validated URL
  const accessToken = getAccessToken();
  if (accessToken) {
    const options = {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    fetch(url, options)
      .then((res) => res.blob())
      .then((blob) => {
        const file = window.URL.createObjectURL(blob);
        window.open(file, "_blank");
      });
  }
};

export const FileCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ message, datum }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const defaultCharliTextColor = useColorModeValue("black", "white");
  const foregroundColor = message.senderId === "charli" ? defaultCharliTextColor : "white";

  return (
    <MessageContainer initiator={message.senderId === "charli" ? "charli" : "user"} key={`${message.id}-${datum.body.file_id}`}>
      <ChatBubble
        initiator={message.senderId === "charli" ? "charli" : "user"}
        onClick={async () => {
          try {
            setIsLoading(true);
            await downloadFile(datum.body.file_id);
          } catch (err) {
            console.error(err);
            toast({
              title: "I can't find that file",
              description: `The file ${datum.body.file_name} no longer exists. It may have been deleted from Google Drive.`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
          } finally {
            setIsLoading(false);
          }
        }}
        acknowledgmentStatus={message.acknowledgmentStatus}>
        <Tooltip aria-label={datum.body.file_name} label={datum.body.file_name} openDelay={1000}>
          <Stack direction="row" alignItems="center">
            {isLoading ? <TypingIndicator /> : <DownloadIcon color={foregroundColor} />}
            <Text
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="sm"
              fontFamily="Menlo, Monaco, Consolas, 'Courier New',
                          monospace"
              color={foregroundColor}
              style={{ textOverflow: "ellipsis" }}>
              {datum.body.file_name}
            </Text>
            <ChevronRightIcon color="white" boxSize="1.5rem" />
          </Stack>
        </Tooltip>
      </ChatBubble>
    </MessageContainer>
  );
};
