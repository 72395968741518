import React from "react";
import { Center, Stack, Text, Circle, useColorModeValue } from "@chakra-ui/react";
import { Tooltip, ResponsiveContainer, Pie, PieChart, Cell } from "recharts";
import { CustomTooltip, renderCustomizedLabel, chartItemColor } from "./CustomChartUtils";
import type { DataType } from "hooks/useStats";
import { defaultProps } from "react-select/creatable";

interface Props {
  data: DataType[];
  width?: string;
  height?: string;
  chartHeight?: number;
  title?: string;
  onClick?: (data: { label: string; state: string }) => void;
  selectedWorkflowIds?: string[];
  color?: string;
  chartId?: string;
  pieColors?: string[];
}

export const CustomPieChart: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  data,
  width = "100%",
  height = "13rem",
  chartHeight = 100,
  title,
  onClick,
  selectedWorkflowIds,
  color,
  chartId,
  pieColors,
}) => {
  const metricBgColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Stack height={height} width={width}>
      {data.every((item) => !item.value) ? (
        <Center height="13rem" pb="1rem">
          <Circle size="8rem" borderWidth="1px" borderColor={metricBgColor} fontSize="xs">
            No Data
          </Circle>
        </Center>
      ) : (
        <Stack height={height} width={width}>
          <ResponsiveContainer width={"100%"} height={chartHeight}>
            <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 22 }} id={chartId}>
              <Pie
                id="test"
                cursor={onClick ? "pointer" : "default"}
                dataKey="value"
                isAnimationActive={false}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={30}
                outerRadius={70}
                fill={color}
                stroke="#A0AEC0"
                label={renderCustomizedLabel}
                labelLine={false}
                color={color}
                fontSize={"10px"}>
                {data.map((entry, index) => (
                  <Cell
                    onClick={() => onClick && entry.label && entry.state && onClick({ label: entry.label, state: entry.state })}
                    key={`cell-${index}`}
                    style={{ outline: "none" }}
                    fill={
                      pieColors
                        ? pieColors[index]
                        : selectedWorkflowIds && entry.workflowId && selectedWorkflowIds.includes(entry.workflowId)
                        ? "#4A5568"
                        : entry.state && chartItemColor(entry.state)
                    }
                  />
                ))}
              </Pie>
              <Tooltip
                isAnimationActive={false}
                content={<CustomTooltip active={defaultProps.active} payload={defaultProps.payload} color={color} showState={false} />}
              />
            </PieChart>
          </ResponsiveContainer>
        </Stack>
      )}

      <Text mt="0!important" fontSize={"xs"} width={width} align={"center"}>
        {title}
      </Text>
    </Stack>
  );
};
