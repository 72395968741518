import type { Static } from "runtypes";
import { Literal, Union } from "runtypes";

export const MessageState = Union(
  Literal("action_required"),
  Literal("task_complete"),
  Literal("task_error"),
  Literal("task_in_progress"),
  Literal("out_of_credits")
);

export type MessageState = Static<typeof MessageState>;
