import { Box, useBreakpointValue, useToast } from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import { getVersion } from "api/version/version";
import { VersionTile } from "screens/landing/tabs/notifications/VersionTile";
import { captureException } from "@sentry/react";
import useNetworkStatus from "./useNetworkStatus";

const VERSION_TOAST_ID = "web-app-version-toast";

export const useVersionTile = () => {
  const [newVersionDate, setNewVersionDate] = useState<number | null>(null);
  const toast = useToast();
  const versionsMapRef = useRef<Set<string>>(new Set<string>());
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { isOnline } = useNetworkStatus();

  useEffect(() => {
    let versionInterval: NodeJS.Timeout;

    if (isOnline) {
      versionInterval = setInterval(() => {
        getVersion()
          .then((response) => {
            const serverVersion = response.version;

            const versionsMap = versionsMapRef.current;
            if (serverVersion && serverVersion !== window.env.version && !versionsMap.has(serverVersion)) {
              versionsMap.add(serverVersion);
              setNewVersionDate(new Date().getTime());
            }
          })
          .catch((error) => {
            captureException(new Error("Failed to get version"), {
              extra: {
                error,
              },
            });
          });
      }, 15000);
    }

    return () => {
      if (versionInterval) {
        clearInterval(versionInterval);
      }
    };
  }, [isOnline]);

  useEffect(() => {
    if (newVersionDate) {
      toast.close(VERSION_TOAST_ID);
      toast({
        id: VERSION_TOAST_ID,
        position: isMobile ? "top" : "top-right",
        duration: null,
        isClosable: true,
        render: ({ onClose }) => {
          return (
            <Box
              width={isMobile ? "100%" : "18rem"}
              px={isMobile ? ".5rem" : "unset"}
              pr={isMobile ? "unset" : "0"}
              pt={isMobile ? "1.4rem" : "0"}>
              <VersionTile
                newVersionDate={newVersionDate}
                onDismiss={() => {
                  setNewVersionDate(null);
                  onClose();
                }}
              />
            </Box>
          );
        },
      });
    }
  }, [isMobile, newVersionDate, toast]);
};
