import type { BoxProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import type { FunctionComponent } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import type { UppyFile } from "types/files";
import type { InputBarAttachmentManagerRef } from "./InputBarAttachmentManager";
import { InputBarAttachmentManager } from "./InputBarAttachmentManager";
import { InputBarDropzoneContext } from "./InputBarDropzoneContext";

interface Props {
  attachmentId: string;
  onDropOverride?: (files: File[]) => void;
  files?: File[];
  showAttachmentModalWhenMounted?: boolean;
}

export const InputBarDropzone: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<BoxProps & Props>>> = ({
  children,
  attachmentId,
  onDropOverride,
  files: initialFiles,
  showAttachmentModalWhenMounted,
  ...boxProps
}) => {
  const attachmentManagerRef = useRef<InputBarAttachmentManagerRef>(null);
  const [files, setFiles] = useState<UppyFile[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [didUploadFail, setDidUploadFail] = useState(false);
  const toast = useToast();

  const processFiles = useCallback(
    (files: File[]) => {
      if (onDropOverride) {
        onDropOverride(files);
      } else {
        for (const file of files) {
          try {
            attachmentManagerRef.current?.addFile({ name: file.name, type: file.type, data: file });
          } catch (err) {
            toast({
              title: "Upload failed",
              description: err.toString(),
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }
      }
    },
    [onDropOverride, toast]
  );

  useEffect(() => {
    // Upload any files passed to this route on open
    if (initialFiles) processFiles(initialFiles);
  }, [processFiles, initialFiles]);

  useEffect(() => {
    if (showAttachmentModalWhenMounted) {
      setModalOpen(true);
    }
  }, [showAttachmentModalWhenMounted]);

  const resetFiles = useCallback(() => {
    attachmentManagerRef.current?.reset();
    setFiles([]);
  }, [attachmentManagerRef]);

  const openFilesModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  return (
    <>
      {!onDropOverride && (
        <InputBarAttachmentManager
          ref={attachmentManagerRef}
          id={attachmentId}
          isOpen={isModalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          onMediaChange={(media) => {
            setFiles(media);
          }}
          onUploadingStatusChange={(isUploadingComplete) => {
            setIsUploadInProgress(!isUploadingComplete);
          }}
          onFailedUploadsChange={(didUploadFail) => {
            setDidUploadFail(didUploadFail);
          }}
        />
      )}
      <Dropzone noDragEventsBubbling onDrop={processFiles}>
        {({ getRootProps, isDragActive }) => (
          <Box {...boxProps} {...getRootProps()} _focus={{ outline: 0 }} opacity={isDragActive ? 0.5 : 1}>
            <Box
              position="absolute"
              left="0"
              right="0"
              bottom="0"
              top="0"
              margin="0 auto!important"
              display={isDragActive ? "block" : "none"}
              pointerEvents="none">
              <Box width="100%" height="100%" border="dashed #1251AD" p="4" />
            </Box>
            <InputBarDropzoneContext.Provider value={{ files, resetFiles, openFilesModal, isUploadInProgress, didUploadFail }}>
              {children}
            </InputBarDropzoneContext.Provider>
          </Box>
        )}
      </Dropzone>
    </>
  );
};
