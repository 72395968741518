import { Badge, Box, Text, useColorModeValue, Button, Stack } from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import type { FunctionComponent } from "react";
import React, { useMemo } from "react";
import type { Notification } from "types/notifications";
import { track } from "api/analytics";
import { NOTIFICATION_CLICKED, NOTIFICATION_DISMISSED } from "api/analytics/events";
import { useButtonProps } from "hooks";
import { AcceptShareButton } from "screens/landing/components/AcceptShareButton";

interface Props {
  notification: Notification;
  onDismiss?: (id: string) => void;
  onIgnore?: (id: string) => void;
  onAction: (id: string) => void;
  source: "notifications_menu" | "notifications_screen" | "toast";
}

const getShareDescription = (notification: Notification) => {
  const getItemDescription = (itemCount: number) => {
    return `${itemCount} ${itemCount > 1 ? "items" : "item"}`;
  };

  const senderName = typeof notification.derivedReference?.senderName === "string" ? notification.derivedReference.senderName : "A user";
  const contentDescription =
    typeof notification.reference?.itemCount === "number" ? getItemDescription(notification.reference.itemCount as number) : "some content";

  if (notification.followOnActionEnabled) {
    return `${senderName} asked me to share ${contentDescription} with you.`;
  } else {
    return `${senderName} shared ${contentDescription} with you.`;
  }
};

const getNotificationStateLabel = (notification: Notification) => {
  if (notification.followOnActionEnabled && !notification.hasBeenDismissed) {
    return "New";
  } else if (notification.followOnActionEnabled && notification.hasBeenDismissed) {
    return "Dismissed";
  } else if (!notification.followOnActionEnabled && notification.hasBeenDismissed) {
    return "Accepted";
  } else if (!notification.followOnActionEnabled && !notification.hasBeenDismissed) {
    return "New No Action";
  } else {
    return "No Action";
  }
};

export const NotificationTile: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  notification,
  onDismiss,
  onAction,
  onIgnore,
  source,
}) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const bgColorToast = useColorModeValue("gray.50", "gray.700");
  const blockquoteBgColor = useColorModeValue("#f7f7f7", "gray.600");
  const blockquoteBgColorToast = useColorModeValue("gray.100", "gray.600");
  const blockquoteTextColor = useColorModeValue("#777777", "#f9f9f9");
  const blockquoteAccentColor = useColorModeValue("#ccc", "#718096");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const secondaryTextColor = useColorModeValue("primary.darkGray", "gray.200");
  const newTextColor = useColorModeValue("teal.700", "teal.200");
  const newBadgeBackgroundColor = useColorModeValue("teal.100", "teal.700");
  const commonButtonProps = useButtonProps("sm", "primary");
  const notificationTitle = useMemo(() => {
    switch (notification.type) {
      case "NEW_SHARE":
        return "Shared content";
      default:
        return "Notification";
    }
  }, [notification.type]);

  const notificationBody = useMemo(() => {
    switch (notification.type) {
      case "NEW_SHARE": {
        const notificationText: string = (() => {
          return getShareDescription(notification);
        })();
        const message =
          notification.reference && notification.reference.message && typeof notification.reference.message === "string"
            ? notification.reference.message
            : undefined;
        const subject =
          notification.reference && notification.reference.subject && typeof notification.reference.subject === "string"
            ? notification.reference.subject
            : undefined;
        return (
          <Stack>
            <Box pb=".5rem">
              {notificationText}{" "}
              {(message || subject) && (
                <Box
                  as="blockquote"
                  background={source === "notifications_menu" || source === "toast" ? blockquoteBgColorToast : blockquoteBgColor}
                  borderLeft={`4px solid ${blockquoteAccentColor}`}
                  p="6px"
                  mt="0.5rem!important">
                  {subject && (
                    <Text fontWeight="600" color={blockquoteTextColor} marginBottom="0.25rem">
                      {subject}
                    </Text>
                  )}
                  {message && (
                    <Text fontStyle="italic" color={blockquoteTextColor} className="ch-notification-message">
                      {message}
                    </Text>
                  )}
                </Box>
              )}
            </Box>
            <Stack direction="row" justifyContent={notification.followOnActionEnabled ? "space-between" : "flex-end"} width="100%">
              {notification.followOnActionEnabled && <AcceptShareButton notification={notification} />}
              {onDismiss && !notification.hasBeenDismissed && (
                <Button
                  className="ch-notification-message-close"
                  aria-label="Dismiss"
                  {...commonButtonProps}
                  onClick={(event) => {
                    track(NOTIFICATION_DISMISSED, { notification_type: notification.type, source });
                    onDismiss(notification.id);
                    // Block propagation so the follow on action isn't triggered by dismissal
                    event.stopPropagation();
                  }}>
                  Dismiss
                </Button>
              )}
            </Stack>
          </Stack>
        );
      }
      default:
        // This property is only temporary so this logic will be replaced shortly.
        return <>{notification.text}</>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockquoteAccentColor, blockquoteBgColor, blockquoteTextColor, notification, onIgnore]);

  return (
    <Box
      className="notification-tile"
      backgroundColor={source === "toast" ? bgColorToast : bgColor}
      borderRadius={source === "notifications_menu" ? "none" : "xl"}
      overflow="hidden"
      boxShadow={source === "notifications_menu" ? "unset" : "md"}
      borderColor={source === "notifications_menu" ? "none" : borderColor}
      borderWidth={source === "notifications_menu" ? "none" : "1px"}
      borderTop={source === "notifications_menu" ? `1px solid #d5d5d5` : "original"}
      onClick={() => {
        if (notification.followOnActionEnabled) {
          track(NOTIFICATION_CLICKED, { notification_type: notification.type, source });
          onAction(notification.id);
        }
      }}
      cursor={notification.followOnActionEnabled ? "pointer" : "unset"}>
      <Stack justifyContent="space-between" height="100%" pt="1rem">
        <Stack direction="row" justifyContent="space-between" spacing="0.5rem">
          <Text fontSize="md" color={secondaryTextColor} fontWeight="700" mb="0.2rem">
            {notificationTitle}
            <Badge
              className="ch-notification-badge"
              px="5px"
              marginLeft="1rem"
              marginBottom="0.5"
              color={newTextColor}
              backgroundColor={getNotificationStateLabel(notification) === "New" ? newBadgeBackgroundColor : "gray.200"}>
              {getNotificationStateLabel(notification)}
            </Badge>
          </Text>
          <Text fontSize="xs" color="gray.500" fontWeight="400">
            {formatDistanceToNow(notification.creationDateEpoch, {
              addSuffix: true,
              includeSeconds: true,
            })}
          </Text>
        </Stack>
        <Box fontSize="0.85rem" color={secondaryTextColor} fontWeight="400">
          {notificationBody}
        </Box>
      </Stack>
    </Box>
  );
};
