import DeferredPromise from "@charliai/node-core-lib/lib/src/DeferredPromise";
import type { IMessage } from "@stomp/stompjs";
import { Client } from "@stomp/stompjs";
import { getAccessToken, getUserInfo } from "api/auth";
import { prependProxyBaseUrl, request } from "api/request";
import type { IRealTimeClient, NewMessage } from "./client";
import { ContentDestinationMapping, UsageDestinationMapping, WorkflowDestinationMapping } from "./mapping/DestinationMapping";
import { MessageType } from "./MessageType";
import type { User } from "types/auth";
import type { TopicSubscription } from "./TopicSubscription";

const STOMP_ENDPOINT = prependProxyBaseUrl("/api/chat/url");

/**
 * Gets the ActiveMQ broker URL for this environment.
 * Implicitly refreshes the user's token to ensure the access token used for the websocket connection is valid.
 */
async function getBrokerUrl() {
  const urlPayload = (await request().url(STOMP_ENDPOINT).get().json()) as {
    url: string;
  };

  return urlPayload.url;
}

const DEFAULT_INBOUND_CONVERSATION_TOPIC = "charliai/inbound/conversations";
const PUBLIC_OUTBOUND_NON_USER_TARGETED_TOPIC_PREFIX = "charliai/public/outbound";
export const OUTBOUND_TOPIC_PREFIX = "charliai/outbound";

const createUserOutboundTopic = (userId: string, topic: string): string => `${OUTBOUND_TOPIC_PREFIX}/${userId}/${topic}`;
const createPublicOutboundTopic = (topic: string): string => `${PUBLIC_OUTBOUND_NON_USER_TARGETED_TOPIC_PREFIX}/${topic}`;

export default class StompWebsocketClient implements IRealTimeClient {
  private deferredConnectionPromise = new DeferredPromise<void>();
  private client?: Client;
  private INBOUND_CONVERSATION_TOPIC: string;
  private user: User | undefined;

  onConnect = this.deferredConnectionPromise.promise;

  constructor() {
    this.INBOUND_CONVERSATION_TOPIC =
      localStorage.getItem("inbound-conversation-topic") ?? localStorage.getItem("inbound-topic") ?? DEFAULT_INBOUND_CONVERSATION_TOPIC;
  }

  async connect(
    clientId: string,
    onConnect: () => void,
    onDisconnect: () => void,
    onMessage: (message: string, messageType?: MessageType) => void
  ): Promise<StompWebsocketClient> {
    const url = await getBrokerUrl();
    const token = getAccessToken()!;

    const user = getUserInfo()!;

    const client = new Client({
      brokerURL: url,

      // UNCOMMENT BELOW to debug stomp connection
      // debug: function (str) {
      //   console.log("Debug?? : ", str);
      // },

      connectHeaders: {
        login: user.email,
        passcode: token,
        charliUserId: user.id,
        charliUserEmail: user.email,
      },
      reconnectDelay: 0, // setting to 0 prevents automatic reconnects
      connectionTimeout: 1500,
      heartbeatIncoming: 2500,
      heartbeatOutgoing: 4000,
      discardWebsocketOnCommFailure: true,
      splitLargeFrames: true,
    });

    client.onConnect = (receipt) => {
      client.subscribe(
        createUserOutboundTopic(user.id, "conversations"),
        (message) => {
          onMessage(message.body, MessageType.conversations);
        },
        {
          "subscription-type": "MULTICAST",
        }
      );

      client.subscribe(
        createUserOutboundTopic(user.id, "content/#"),
        (message) => {
          const maybeMessageType = this.mapToMessageType(message, "content/", ContentDestinationMapping);
          if (maybeMessageType) {
            onMessage(message.body, maybeMessageType);
          }
        },
        {
          "subscription-type": "MULTICAST",
        }
      );

      client.subscribe(
        createUserOutboundTopic(user.id, "workflow/#"),
        (message) => {
          const maybeMessageType = this.mapToMessageType(message, "workflow/", WorkflowDestinationMapping);
          if (maybeMessageType) {
            onMessage(message.body, maybeMessageType);
          }
        },
        {
          "subscription-type": "MULTICAST",
        }
      );

      client.subscribe(
        createUserOutboundTopic(user.id, "usage/#"),
        (message) => {
          const maybeMessageType = this.mapToMessageType(message, "usage/", UsageDestinationMapping);

          if (maybeMessageType) {
            onMessage(message.body, maybeMessageType);
          }
        },
        {
          "subscription-type": "MULTICAST",
        }
      );

      onConnect();
      this.deferredConnectionPromise.resolve();
    };

    client.onStompError = (frame) => {
      console.error("Broker reported error: " + frame.headers["message"]);
      console.error("Additional details: " + frame.body);
      onDisconnect();
      this.client?.deactivate();
    };

    client.onWebSocketClose = onDisconnect;

    client.onWebSocketError = (e) => {
      console.error("Websocket error detected: ", e);
      this.deferredConnectionPromise.reject();
      onDisconnect();
    };

    this.client = client;
    this.user = user;
    client.activate();

    return this;
  }

  /**
   * Subscribe to events specifically intended for user coming from backend services.
   * @param topic topic to listen for. For example, to listen to workflow service events it might be workflow/events. NEVER prefix events with '/'
   * @param handler handler that will receive the message for that topic
   * @param isEventTargetedToUser whether or not the event is targeted to this specific user
   * @returns an object that can be used to unsubscribe from the subscription
   */
  subscribeToEvent<TPayloadBody>(
    topic: string,
    handler: (messageBody: TPayloadBody, destination: string) => void,
    isEventTargetedToUser = true
  ): TopicSubscription {
    if (!this.user) {
      throw new Error("No valid user that we can use for subscription");
    }

    const fullTopic = isEventTargetedToUser ? createUserOutboundTopic(this.user.id, topic) : createPublicOutboundTopic(topic);
    const subscription = this.client?.subscribe(
      fullTopic,
      (message) => {
        const payload: TPayloadBody = JSON.parse(message.body);
        handler(payload, message.headers.destination);
      },
      {
        "subscription-type": "MULTICAST",
      }
    );

    return {
      topic: fullTopic,
      unsubscribe: () => subscription?.unsubscribe(),
    };
  }

  async send(message: NewMessage): Promise<void> {
    try {
      await this.onConnect;

      const user = getUserInfo()!;
      const interceptId = localStorage.getItem("x-telepresence-intercept-id") || localStorage.getItem("interceptId");
      const headers = {
        charliUserId: user.id,
        charliUserEmail: user.email,
        "destination-type": "ANYCAST",
      };

      if (interceptId) {
        headers["x-telepresence-intercept-id"] = interceptId;
      }

      this.client?.publish({
        destination: this.INBOUND_CONVERSATION_TOPIC,
        skipContentLengthHeader: true,
        body: JSON.stringify(message),
        headers,
      });
    } catch (error) {
      console.error("Unable to send message at this time. Connection failed ", error);
    }
  }

  close(code?: number | undefined): void {
    this.client?.deactivate();
    this.client = undefined;
  }

  private mapToMessageType(message: IMessage, topic: string, destinationToMessageType: Map<string, MessageType>): MessageType | undefined {
    const fullDestination = message.headers.destination;
    const topicIndex = fullDestination.lastIndexOf(topic);
    const topicDestination = fullDestination.substring(topicIndex);
    return destinationToMessageType.get(topicDestination);
  }
}
