import { useCallback } from "react";
import { useJWT, useUserProfile } from "hooks";
import isNil from "lodash/isNil";
import { prependProxyBaseUrl } from "api/request";
import type { ContentDetails } from "types/content/ContentDetails";

export function useThumbnail() {
  const { accessToken } = useJWT();
  const { id } = useUserProfile();

  const thumbnailUrlForFile = useCallback(
    (
      fileId: string,
      width?: string,
      height?: string,
      fit?: "cover" | "fill" | "contain",
      position?: "top" | "center",
      metadataId?: string
    ) => {
      const params = {
        access_token: accessToken,
        width: width ? width.replace("px", "") : undefined,
        height: height ? height.replace("px", "") : undefined,
        fit,
        position,
        metadataId: metadataId,
      };

      const formattedQueryString = new URLSearchParams(filterParams(params)).toString();

      return prependProxyBaseUrl(`/api/thumbnail/file/${fileId}?${formattedQueryString}`);
    },
    [accessToken]
  );

  const thumbnailUrlForLink = useCallback(
    (
      linkId: string,
      url: string,
      width?: string,
      height?: string,
      fit?: "cover" | "fill" | "contain" | "none" | "scale-down" | undefined,
      position?: "top" | "center",
      metadataId?: string
    ) => {
      if (url in hardcodedUrlToThumbnailMap) {
        return hardcodedUrlToThumbnailMap[url];
      } else {
        const params = {
          userId: id,
          url,
          width: width,
          height: height,
          fit,
          position,
          metadataId: metadataId,
        };

        const formattedQueryString = new URLSearchParams(filterParams(params)).toString();

        return prependProxyBaseUrl(`/api/thumbnails/links/${linkId}?${formattedQueryString}`);
      }
    },
    [id]
  );

  const getThumbnailUrl = (item: ContentDetails, thumbnailWidth, thumbnailHeight, thumbnailFit, thumbnailPosition) => {
    const roundedThumbnailWidth = String(Math.round(Number(thumbnailWidth.replace("px", ""))));
    const roundedThumbnailHeight = String(Math.round(Number(thumbnailHeight.replace("px", ""))));
    if (item.type === "link" && item.urls) {
      return {
        id: item.mediaId,
        extension: "lnk",
        url: item.urls.url
          ? thumbnailUrlForLink(
              item.mediaId,
              item.urls.url,
              roundedThumbnailWidth,
              roundedThumbnailHeight,
              thumbnailFit as "cover" | "fill" | "contain" | "none" | "scale-down",
              thumbnailPosition as "top" | "center",
              item.id
            )
          : undefined,
      };
    } else if (item.type === "dynamic_data") {
      return {
        id: item.mediaId,
        extension: "data",
        url: thumbnailUrlForFile(item?.mediaId, roundedThumbnailWidth, roundedThumbnailHeight, thumbnailFit, thumbnailPosition, item.id),
      };
    } else {
      return {
        id: item?.mediaId,
        extension: item?.extension,
        url: thumbnailUrlForFile(item?.mediaId, roundedThumbnailWidth, roundedThumbnailHeight, thumbnailFit, thumbnailPosition, item.id),
      };
    }
  };

  return { thumbnailUrlForFile, thumbnailUrlForLink, getThumbnailUrl };
}

const hardcodedUrlToThumbnailMap: { [url: string]: string } = {
  "https://calendly.com/devin-charli/charli-onboarding-consultation": "/assets/thumbnails/book-a-call.png",
  "https://drive.google.com/file/d/1LtFiljqcmH3WjOabw5TWqwamyopc3WNE/view": "/assets/thumbnails/get-started.png",
  "https://support.charli.ai/hc/en-us/requests/new": "/assets/thumbnails/support-request.png",
  "https://drive.google.com/file/d/13RTQOQ8entaHxRhJz_Hol48qrjIcLuYM/view": "/assets/thumbnails/behind-the-intelligence.png",
  "https://drive.google.com/file/d/1l8eJuy-HQsE-s2NAI_SqfDsPkRbsZ2jQ/view": "/assets/thumbnails/quick-start-guide.png",
  "https://youtu.be/VbzUQXm6vMM": "/assets/thumbnails/charli-101.png",
  "https://youtu.be/-VzxKlr23hQ": "/assets/thumbnails/finance-use-case.png",
  "https://youtu.be/IxpbWvE2juM": "/assets/thumbnails/marketing-use-case.png",
  "https://youtu.be/SZ6ODvmOrcA": "/assets/thumbnails/hr-use-case.png",
  "https://get.charli.ai/sign-up-for-free-today/?utm_source=app_charli&utm_medium=charli_web_app&utm_campaign=onboarding_collections":
    "/assets/thumbnails/charli-website.png",
};

const filterParams = (params: { [key: string]: string | undefined }): { [key: string]: string } => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (!isNil(value)) {
      acc[key] = value;

      return acc;
    } else {
      return acc;
    }
  }, {} as { [key: string]: string });
};
