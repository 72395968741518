import { Box } from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { hasProjectOutput } from "screens/collection/components/utils";
import { ContentCanvasLayout } from "./ContentCanvasLayout";
import { ContentFilterContext } from "./ContentFilterContext";

export const ContentRouter = React.memo(({ filterOutProjectOutput }: { filterOutProjectOutput: boolean }) => {
  const { availableContent } = useContext(ContentFilterContext);

  //  This is a temporary fix to exclude content from the report that is tagged with "project_output"
  const filterAvailableContent = useMemo(() => {
    return availableContent.filter((content) => {
      if (filterOutProjectOutput) {
        return !hasProjectOutput(content);
      } else {
        return true;
      }
    });
  }, [availableContent, filterOutProjectOutput]);

  return (
    <Box width="100%" height="100%">
      <ContentCanvasLayout availableContent={filterAvailableContent} />
    </Box>
  );
});
