import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import React, { useCallback } from "react";
import type { Control, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import { AddTagDetails } from "screens/panels/addToCharli/AddToCharliWizard/AddTagDetails";
import type { IFieldTagsInput } from "../types";

// const FormTags = () => {

// }

export const TagsInput = <T extends FieldValues>({
  entity,
  control,
  label,
  placeholder,
  suggestions,
  defaultValue,
  usesEntities = true,
}: IFieldTagsInput<T> & { control: Control<T> }) => {
  const {
    field,
    formState: { errors },
  } = useController({ control, name: entity });

  const setTags = useCallback((tags: any) => field.onChange(tags), [field]);

  return (
    <FormControl isInvalid={!!errors[entity]}>
      <AddTagDetails
        title={label}
        placeholder={placeholder}
        defaultTags={field.value ?? defaultValue}
        onChangeTags={setTags}
        suggestedTags={suggestions}
        usesEntities={usesEntities}
      />
      {errors[entity]?.message ? <FormErrorMessage>{errors[entity]?.message as string}</FormErrorMessage> : null}
    </FormControl>
  );
};
