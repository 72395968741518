import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useProjectParams } from "./useCollections";
import { useUserPreference } from "./useUserPreferences";
import { useConfigMap, useGetViewConfig } from "./useViewConfig";

export const useAnswerFocusOptions = (collectionType: string | undefined) => {
  const { pathname } = useLocation();
  const { projectFilter } = useProjectParams();
  const isHome = pathname.includes("/home");

  const configMap = useConfigMap();
  const answerFocusOptions = useGetViewConfig(
    "answerFocusOptions",
    collectionType ?? projectFilter ?? isHome ? "due_diligence" : "",
    configMap
  );

  const userPreferenceSourceWeight = useUserPreference("source_weights_index") as 0 | 1;
  const defaultSourceWeight = answerFocusOptions && (answerFocusOptions.findIndex((sourceWeight) => sourceWeight.default) as 0 | 1);
  const [selectedSourceWeightsIndex, setSelectedSourceWeightsIndex] = useState<0 | 1>(
    userPreferenceSourceWeight ?? defaultSourceWeight ?? 0
  );

  return {
    answerFocusOptions,
    selectedSourceWeightsIndex,
    setSelectedSourceWeightsIndex,
  };
};
