import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Center,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { getEntitiesForSelectedItems, useContents, useConversation, useInitialSyncCompleted, useItemSelector, useTabProps } from "hooks";
import { LoadingGate } from "screens/common/components";
import type { SupportedItemKeys } from "state/selection/reducer";
import type { GroupByOption, GroupBySortDirection, SortDirection, SortOption } from "types/SortingAndGroupingPreferences";
import { ContentCanvasFilterOptions, ContentFilterContextProvider, ContentRouter } from "..";
import { ContentSortingGroupingContext } from "./body/ContentSortingGroupingContext";
import { getItemTypeForContent } from "../common/utils";
import { TabTitle } from "screens/common/components/TabTitle";
import { ConversationContext } from "screens/thread/ConversationContext";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { ContentCanvasFilterMenu } from "./header/ContentCanvasFilterMenu";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ContentViewPanel } from "../contentView/ContentViewPanel";
import { SharePanel } from "screens/panels/share/SharePanel";
import { NotesPanel } from "../common/notes/NotesPanel";
import { AddToCollectionPanel } from "screens/panels/addToCharli/AddToCharliWizard/AddToCollectionPanel";
import { VerifiedAIPanel } from "../contentView/VerifiedAIPanel";
import { ProjectHeaderImage } from "screens/landing/tabs/project/projectLandingTileLayouts/components/ProjectHeaderImage";
import { SearchViewActionMenu } from "./body/SearchViewActionMenu";
import { useParams } from "react-router-dom";

export const ContentCanvas = () => {
  // Type assertion is needed here as useParams assumes all parameters are present. contentId may be undefined, we want the type to accurately reflect this.
  const { conversationId: currentRouteId, contentId } = useParams() as { conversationId: string; contentId: string | undefined };
  const { setConversationId, setRequestEntities } = useContext(ConversationContext);
  const conversation = useConversation(currentRouteId);
  const { isOpen: isFilterOpen, onToggle: onFilterToggle, onOpen: onFilterOpen, onClose: onFilterClose } = useDisclosure();
  const [sortName] = useState<SortOption>("Date");
  const [sortDirection] = useState<SortDirection>("DESC");
  const [groupByName] = useState<GroupByOption>("");
  const [groupByDirection] = useState<GroupBySortDirection>("ASC");
  const { setHeaderText } = useContext(SettingsProviderContext);
  const {
    onContentViewOpen,
    onContentViewClose,
    isShareModalOpen,
    isHighlightsPanelOpen,
    isAddToCollectionModalOpen,
    onAddToCollectionModalClose,
    isContentViewOpen,
  } = useAddToCharliContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const textColor = useColorModeValue("gray.700", "gray.100");
  // const { hasInitialSyncCompleted } = useSelector((state: RootState) => state.conversation, shallowEqual);
  const hasInitialSyncCompleted = useInitialSyncCompleted();
  const { selectedItems, setSelectedItems, resetSelectedItems } = useItemSelector();
  const canvasItems = useContents(conversation.metadataIds ?? []);
  const initialRequest = conversation?.initialText?.replace("Find", "Search") || "Search Results";
  const { defaultTabProps } = useTabProps();

  useEffect(() => {
    contentId ? setConversationId(contentId) : setConversationId(currentRouteId);
    setHeaderText(initialRequest.trim().toLowerCase() === "find :" ? "Search Results" : initialRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId, initialRequest]);

  useEffect(() => {
    if (contentId) {
      onContentViewOpen();
    } else {
      onContentViewClose();
    }
  }, [contentId, onContentViewClose, onContentViewOpen]);

  useEffect(() => {
    if (canvasItems?.length) {
      const items =
        canvasItems &&
        canvasItems.reduce((cells, cell) => {
          const itemType = getItemTypeForContent(cell.type);
          cells[cell.mediaId] = { type: itemType };
          return cells;
        }, {} as { [id: string]: { type: SupportedItemKeys } });
      if (items) setSelectedItems(items);
    } else {
      resetSelectedItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasItems?.length, contentId, isShareModalOpen, isHighlightsPanelOpen]);

  useEffect(() => {
    if (!selectedItems) return;

    const selectedEntities = getEntitiesForSelectedItems(selectedItems);
    const uniqueEntitiesTypes = [
      ...new Set(selectedEntities.filter((entity) => entity.entity !== "collection_id").map((item) => item.entity)),
    ];
    const uniqueEntities = uniqueEntitiesTypes.map((entity) => {
      const values = selectedEntities.filter((item) => item.entity === entity).map((item) => item.value);
      return { entity, value: values.join(", ") };
    });

    uniqueEntities.forEach((item) =>
      updateBatchRequestEntities([{ entity: item.entity, value: item.value, source: "selection" }], setRequestEntities)
    );
  }, [selectedItems, setRequestEntities]);

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (conversation.metadataIds === undefined) {
      setShowLoader(true);
    } else {
      if (conversation.metadataIds.length > 0 && (canvasItems ?? []).length === 0) {
        setShowLoader(true);
      } else {
        setShowLoader(false);
      }
    }
  }, [canvasItems, conversation.metadataIds]);

  return (
    <ContentFilterContextProvider contentItems={canvasItems}>
      <TabTitle title={`Charli > ${initialRequest}`} />
      <ContentSortingGroupingContext.Provider value={{ sortName, sortDirection, groupByName, groupByDirection }}>
        <Box
          width="100%"
          height="100%"
          className="canvas-view search-view"
          px={isMobile ? ".5rem" : "1rem"}
          pt={isMobile ? ".5rem" : "1rem"}>
          <Stack direction="row" className="project-detail-section" width="100%" justifyContent={"space-between"} pr="1rem">
            <ProjectHeaderImage projectType={"search"} width="20rem" />
            <Stack align="flex-end" spacing="0">
              <Stack direction="row" alignItems="center" spacing="1rem">
                <Stack spacing="0" width="100%">
                  <Text textAlign={"right"} fontSize="md" color={textColor} fontWeight="semibold">
                    {initialRequest}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Tabs isFitted={false} mt="2rem!important">
            <Stack direction="row" justifyContent={"space-between"} width="100%" alignItems="center">
              <TabList borderBottom={"none"}>
                <Tab {...defaultTabProps} className="ch-search-results">
                  Search Results
                </Tab>
              </TabList>
              <SearchViewActionMenu />
            </Stack>
            <TabPanels pb="4rem">
              <TabPanel px="0!important" className="ch-project-results-panel">
                {!isMobile && (
                  <Stack direction="row" spacing={"1rem"} pb="1rem" width={"100%"} justifyContent="flex-end">
                    <ContentCanvasFilterMenu
                      isShowingResources
                      onFilterToggle={onFilterToggle}
                      onOpen={onFilterOpen}
                      isOpen={isFilterOpen}
                      onClose={onFilterClose}
                      placeholderText="Filter search results..."
                    />
                  </Stack>
                )}
                <LoadingGate isLoading={!hasInitialSyncCompleted || showLoader} height="100%">
                  <Box width="100%" height="100%" className="canvas-modal">
                    {canvasItems ? (
                      <Stack height="100%">
                        {isFilterOpen && <ContentCanvasFilterOptions />}
                        <ContentRouter filterOutProjectOutput={false} />
                      </Stack>
                    ) : (
                      <Center height="100%">
                        <Stack align="center" spacing="1rem" justifyContent="center" mt="3rem">
                          <Text color={textColor}>I couldn't find any resources related to your search.</Text>
                        </Stack>
                      </Center>
                    )}
                  </Box>
                </LoadingGate>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <ContentViewPanel />
          {isContentViewOpen && (
            <>
              <SharePanel />
              <NotesPanel />
              <AddToCollectionPanel isOpen={isAddToCollectionModalOpen} onClose={onAddToCollectionModalClose} />
              <VerifiedAIPanel />
            </>
          )}
        </Box>
      </ContentSortingGroupingContext.Provider>
    </ContentFilterContextProvider>
  );
};
