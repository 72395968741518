import React from "react";
import { Box, Stack, Button, FormControl, FormLabel, Input, IconButton, Icon, useColorModeValue } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import type { FormValues } from "./UpsertEntityConfiguration";
import { AiOutlineDelete } from "react-icons/ai";

interface IProps {
  configIndex: number;
}

export const EntityFieldArray = (props: IProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const { control } = useFormContext<FormValues>();
  const { configIndex } = props;
  const { fields, append, remove } = useFieldArray({ control, name: `config.${configIndex}.entities` });

  return (
    <Box>
      {fields.map((field, index) => (
        <Stack direction="row" spacing="0.5rem" justifyContent="space-between" width="100%" key={field.id} mt="1rem" height="100%">
          <FormControl>
            <FormLabel fontSize="sm">Entity</FormLabel>
            <Controller
              render={({ field }) => <Input {...field} size="xs" type="text" mr="0.5rem" fontSize="md" boxShadow="none" required />}
              name={`config.${configIndex}.entities.${index}.value`}
              control={control}
            />
          </FormControl>

          <Box pt="1.5rem">
            <IconButton
              onClick={() => remove(index)}
              aria-label="Delete"
              backgroundColor="unset"
              icon={<Icon as={AiOutlineDelete} color={buttonColor} boxSize="1rem" _hover={{ color: buttonHoverColor }} />}
              size="sm"
              _hover={{ backgroundColor: "unset" }}
            />
          </Box>
        </Stack>
      ))}
      <Stack direction="row" justifyContent="space-between" mt="1rem">
        <Button onClick={() => append({ value: "" })} {...commonButtonProps}>
          Add Entity
        </Button>
      </Stack>
    </Box>
  );
};
