import type { ReactNode } from "react";
import React, { useMemo } from "react";
import { PanelView } from "screens/panels/components/PanelView";
import { PanelStep } from "screens/panels/components/PanelStep";
import { Settings } from "./Settings";
import { Wizard } from "react-use-wizard";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { Stack, Center, Icon, Text, Box } from "@chakra-ui/react";
import { VscSettings } from "react-icons/vsc";

export const SettingsPanel = () => {
  const { isSettingsOpen, onSettingsClose } = useAddToCharliContext();

  const panelHeader: ReactNode = useMemo(
    () => (
      <Stack spacing="0" className="ch-verified-panel">
        <Box bgColor="#81c34c" height="1rem" width="100%" />
        <Stack direction="row" justifyContent="space-between" backgroundColor="gray.100" px="1.5rem" py="1rem">
          <Stack width="100%" justifyContent="space-between">
            <Text fontSize="md" fontWeight="semibold">
              Account Settings
            </Text>
            <Text fontSize="sm" fontWeight="normal">
              Manage your account including name, subscription and default settings. Certain advanced settings are also available including
              project views and project sorting.
            </Text>
          </Stack>
          <Center>
            <Icon as={VscSettings} boxSize={["2rem", "2.5rem", "3rem"]} color="#81c34c" ml="1.5rem" />
          </Center>
        </Stack>
      </Stack>
    ),
    []
  );

  return (
    <PanelView isOpen={isSettingsOpen} onClose={onSettingsClose} panelTitle="Account Settings" panelHeader={panelHeader}>
      <Wizard>
        <PanelStep>
          <Settings />
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
