import { useCharliActivitiesIntegrations } from "hooks/useCharliActivities";
import React from "react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Flex, Text } from "@chakra-ui/react";
import { AdminTiles } from "screens/common/components";
import { useFilterInput } from "hooks/useFilterInput";
import type { Handler } from "state/charliActivities/reducer";
import type { IntegrationWithHandlers } from "hooks/useCharliActivities";

export const CharliActivities = () => {
  const integrationsWithHandlers = useCharliActivitiesIntegrations(true);

  const { filteredList, renderFilterInputComponent } = useFilterInput<IntegrationWithHandlers>(["urn", "name"], integrationsWithHandlers);

  return (
    <>
      <Box mb={"1rem"}>{renderFilterInputComponent("Filter by integration name or intent")}</Box>
      <Accordion>
        {filteredList.map(({ urn, name, handlers, ...integration }) => {
          return (
            <AccordionItem key={urn}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {name} ({urn}) - {handlers.length} handlers
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  {isExpanded ? (
                    <AccordionPanel pb={4}>
                      <Flex mb={"1rem"} justifyContent={"space-between"} borderWidth="2px" borderRadius="md" padding="1rem">
                        <Box>
                          <Text as="b">System: </Text>
                          <Text>{integration.system ?? "n/a"}</Text>
                        </Box>
                        <Box>
                          <Text as="b">Provider: </Text>
                          <Text>{integration.provider ?? "n/a"}</Text>
                        </Box>
                        <Box>
                          <Text as="b">External ID: </Text>
                          <Text>{integration.externalId ?? "n/a"}</Text>
                        </Box>
                        <Box>
                          <Text as="b">Category: </Text>
                          <Text>{integration.category ?? "n/a"}</Text>
                        </Box>
                        <Box>
                          <Text as="b">Authentication Type: </Text>
                          <Text>{integration.authenticationType ?? "n/a"}</Text>
                        </Box>
                        <Box>
                          <Text as="b">Developer ID: </Text>
                          <Text>{integration.developerId ?? "n/a"}</Text>
                        </Box>
                        <Box>
                          <Text as="b">Visibility: </Text>
                          <Text>{integration.visibility}</Text>
                        </Box>
                      </Flex>
                      <AdminTiles<Handler & { lastUpdatedDate?: Date; lastUpdatedByUserName?: string }>
                        items={handlers}
                        tileTitle="intent"
                        customFields={[
                          { field: "Url", value: (item) => item.url },
                          { field: "Requirements", value: (item) => item.requirements.join(", ") },
                          {
                            field: "Requires",
                            value: (item) => (
                              <Flex flexDirection={"column"}>
                                {item.requires.map((require) => (
                                  <Box key={require}>
                                    <Text>{require}!</Text>
                                  </Box>
                                ))}
                              </Flex>
                            ),
                          },
                          {
                            field: "Optional",
                            value: (item) => (
                              <Flex flexDirection={"column"}>
                                {item.optionalRequires.map((require) => (
                                  <Box key={require}>
                                    <Text>{require}</Text>
                                  </Box>
                                ))}
                              </Flex>
                            ),
                          },
                          {
                            field: "Should Include",
                            value: (item) => (
                              <Flex flexDirection={"column"}>
                                {item.shouldIncludeEntities.map((require) => (
                                  <Box key={require}>
                                    <Text>{`[${require}]`}</Text>
                                  </Box>
                                ))}
                              </Flex>
                            ),
                          },
                          {
                            field: "Must Include",
                            value: (item) => (
                              <Flex flexDirection={"column"}>
                                {item.mustIncludeEntities.map((require) => (
                                  <Box key={require}>
                                    <Text>{`[${require}!]`}</Text>
                                  </Box>
                                ))}
                              </Flex>
                            ),
                          },
                          {
                            field: "Exhaust all resolvers",
                            value: (item) => (
                              <Flex flexDirection={"column"}>
                                {item.exhaustAllResolversEntities.map((require) => (
                                  <Box key={require}>
                                    <Text>{require}</Text>
                                  </Box>
                                ))}
                              </Flex>
                            ),
                          },
                          {
                            field: "Produces",
                            value: (item) => (
                              <Flex flexDirection={"column"}>
                                {item.produces.map((produce) => (
                                  <Box key={produce}>
                                    <Text>{produce}</Text>
                                  </Box>
                                ))}
                              </Flex>
                            ),
                          },
                        ]}
                        fieldsToRender={["name", "cost"]}
                        filterByFields={["intent", "name"]}
                        inputFilterPlaceholder="Filter by intent or name"
                        defaultSortByKey="lastUpdatedDate"
                        hasWrite
                        hasRead
                        tileTitleKey="intent"
                        keyName="id"
                        showUpdatedInfo={false}
                        showViewType={false}
                        viewType="tiles"
                      />
                    </AccordionPanel>
                  ) : null}
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
};
