import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAdminUsers, fetchUserCredentialType, getUsersByIds, listUsers } from "api/user";
import { getMemberOrganizations } from "api/organizations";
import { getUserEntitlements } from "api/entitlements";
import { getBillingStatus } from "api/billingPlan";

export const searchUsers = createAsyncThunk(
  "users/all",
  async ({ search, returnOrganizations }: { search?: string; returnOrganizations?: boolean }) => {
    return await listUsers(search, returnOrganizations);
  }
);

export const downloadUsers = createAsyncThunk("users/download", async (ids: string[]) => {
  return await getUsersByIds(ids);
});

export const getUserCredentialsType = createAsyncThunk("users/credentials-type", async (userId: string) => {
  return await fetchUserCredentialType(userId);
});

export const downloadUserOrganizations = createAsyncThunk("users/download-organizations", async (userId: string) => {
  return await getMemberOrganizations(userId);
});

export const downloadUserEntitlements = createAsyncThunk("users/download-entitlements", async (userId: string) => {
  return await getUserEntitlements(userId);
});

export const downloadUserBillingPlan = createAsyncThunk("users/download-billing-plan", async (userId: string) => {
  return await getBillingStatus(userId);
});

export const createUsersFromAdmin = createAsyncThunk(
  "users/create-admin",
  async (users: { firstName: string; lastName: string; email: string }[]) => {
    return await createAdminUsers(users);
  }
);
