import type { Static } from "runtypes";
import { Record, String } from "runtypes";

export const ContentUrls = Record({
  url: String.nullable().optional(),
  parentUrl: String.nullable().optional(),
  downloadUrl: String.nullable().optional(),
});

export type ContentUrls = Static<typeof ContentUrls>;
