import { requestToDownloadWorkflows } from "state/workflow/utils";
import { getMissingWorkflowIds } from "screens/thread/components/cells/workflowSummaryCell/utils";
import { useConversation } from "./useConversation";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { WorkflowTaskStatus } from "types/workflows/workflow";
import type { Workflow } from "types/workflows/workflow";
import type { RootState } from "state/rootReducer";

export function useWorkflowsMap(): Record<string, Workflow> {
  return useSelector((state: RootState) => state.workflow.workflowsById);
}

export const useFilterWorkflowsIdsByIntents = (workflowsIds: string[], intents?: string[]): string[] => {
  return useSelector((state: RootState) => {
    return intents
      ? workflowsIds.flatMap((id) => {
          const workflow = state.workflow.workflowsById[id];

          if (!workflow) {
            return [];
          }

          return intents.includes(workflow.intent) ? [workflow.id] : [];
        })
      : workflowsIds;
  }, shallowEqual);
};

export function useWorkflowKey<T extends keyof Workflow>(workflowId: string | undefined, key: T): Workflow[T] | undefined {
  return useSelector(
    (state: RootState) =>
      workflowId && state.workflow.workflowsById[workflowId] ? state.workflow.workflowsById[workflowId][key] : undefined,
    shallowEqual
  );
}

export function useWorkflowCompletionDateOrNow(workflowId: string | undefined): Date {
  const completionDate = useWorkflowKey(workflowId, "completionDate");
  return useMemo(() => (completionDate ? new Date(completionDate) : new Date()), [completionDate]);
}

export function useWorkflowStatus(workflowId: string | undefined): WorkflowTaskStatus | undefined {
  const status = useWorkflowKey(workflowId, "status");
  return status?.toLocaleLowerCase() === "failed" || status?.toLocaleLowerCase() === "error" ? WorkflowTaskStatus.incomplete : status;
}

export function useWorkflows(): Workflow[] {
  const { workflowsById, workflowsIds } = useSelector((state: RootState) => state.workflow);

  return useMemo(() => {
    return workflowsIds.map((workflowId) => workflowsById[workflowId]);
  }, [workflowsById, workflowsIds]);
}

export function useWorkflowsByIds(workflowsIds: string[]): Workflow[] {
  return useSelector((state: RootState) => {
    return workflowsIds.flatMap((id) => {
      const workflow = state.workflow.workflowsById[id];

      if (!workflow) {
        return [];
      } else {
        return workflow;
      }
    });
  }, shallowEqual);
}

export function useWorkflowFiltered(intents?: string[], status?: string[], blacklist?: string[]): Workflow[] {
  const workflows = useWorkflows();
  return useMemo(
    () =>
      workflows.filter(
        (workflow) =>
          (intents?.length ? intents.includes(workflow.intent) : true) &&
          (status?.length ? status.includes(workflow.status) : true) &&
          (blacklist?.length ? !blacklist.includes(workflow.intent) : true) &&
          (blacklist?.length ? !blacklist.includes(workflow.status) : true) &&
          (blacklist?.length && blacklist.includes("dismissed") ? !workflow.dismissed : true)
      ),
    [workflows, intents, status, blacklist]
  );
}

export function useDownloadWorkflowsByConversationId(conversationId: string): void {
  const dispatch = useDispatch();
  const { workflowsIds, isLoadingWorkflows } = useSelector(
    (state: RootState) => ({
      isLoadingWorkflows: state.workflow.isLoadingWorkflowMap,
      workflowsIds: state.workflow.workflowsIds,
    }),
    shallowEqual
  );
  const { messages } = useConversation(conversationId);

  useEffect(() => {
    const workflowIds = getMissingWorkflowIds(messages, new Set(workflowsIds));
    if (workflowIds.length > 0) {
      requestToDownloadWorkflows(workflowIds, isLoadingWorkflows, dispatch);
    }
  }, [dispatch, workflowsIds, messages, isLoadingWorkflows]);
}
