import { CopyIcon } from "@chakra-ui/icons";
import { Badge, Box, Stack, Tooltip, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useState, useCallback } from "react";
import type { ExtractedHighlights, Highlights as HighlightsType } from "types/collection";
import { HighlightItem } from "./HighlightsItem";
import ECHighlighter from "react-ec-highlighter";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";

interface Props {
  extractedHighlights?: ExtractedHighlights[];
  textOnlyView?: boolean;
  textTruncated?: boolean;
  showCopyButton?: boolean;
  showRecordLinkBtn?: boolean;
  showActionMenu?: boolean;
  onClose?: () => void;
  maxHighlights?: number;
  maxLines?: number;
  searchPhrase?: string;
}

export const Highlights: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  extractedHighlights,
  textOnlyView = false,
  textTruncated = false,
  showCopyButton = false,
  showRecordLinkBtn = false,
  showActionMenu,
  onClose,
  maxHighlights,
  maxLines,
  searchPhrase,
}) => {
  const topicColor = useColorModeValue("gray.500", "gray.200");
  const [isCopied, setIsCopied] = useState(false);
  const [isSelectedTopic, setSelectedTopic] = useState("");

  const topicCopied = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
      setSelectedTopic("");
    }, 1500);
  };

  const getHighlightsForTopic = (selectedTopic?: string) => {
    let filtered = extractedHighlights;
    if (selectedTopic) filtered = extractedHighlights?.filter((topics) => topics.topic === selectedTopic);

    const selectedTopicHighlights = filtered
      ?.map((topics) => {
        let topicSection = topics.highlights.length ? `Topic: ${topics.topic} \r\n` : "";
        topics.highlights.map((highlights) => (topicSection += highlights.highlight + "\r\n"));
        return topicSection + "\r\n";
      })
      .join("");
    topicCopied();
    return selectedTopicHighlights || "";
  };

  const getSlicedExtractedHighlights = useCallback(
    (extractedHighlights: ExtractedHighlights[]) => {
      return extractedHighlights
        .slice(0, maxHighlights ? maxHighlights : extractedHighlights.length)
        .flatMap((topicRecord) => (topicRecord.highlights.length > 0 ? [topicRecord] : []));
    },
    [maxHighlights]
  );

  const getSlicedHighlights = useCallback(
    (highlights: HighlightsType[]) => {
      return highlights
        .slice(0, maxHighlights ? maxHighlights : highlights.length)
        .flatMap((topicRecord) => (topicRecord.highlight.length > 0 ? [topicRecord] : []));
    },
    [maxHighlights]
  );

  return (
    <>
      {extractedHighlights && extractedHighlights[0]?.highlights[0]?.highlight?.length > 0 && (
        <Stack justifyContent="space-between" spacing={textOnlyView || textTruncated ? "unset" : "3rem"} mt="0!important">
          {getSlicedExtractedHighlights(extractedHighlights).map((topicRecord, index) => (
            <Stack key={topicRecord.topic}>
              {!textTruncated && (
                <BlockSectionHeader
                  subSection
                  direction="row"
                  title={`Topic: ${
                    searchPhrase && searchPhrase.length > 0 && topicRecord.topic ? (
                      <ECHighlighter searchPhrase={searchPhrase} text={topicRecord.topic} />
                    ) : (
                      topicRecord.topic
                    )
                  }`}>
                  {showCopyButton && (
                    <Box
                      cursor="pointer"
                      onClick={() => {
                        setSelectedTopic(topicRecord.topic);
                        navigator.clipboard.writeText(getHighlightsForTopic(topicRecord.topic));
                      }}>
                      <Tooltip label={`Copy ${topicRecord.topic} highlights`}>
                        <CopyIcon color={topicColor} boxSize=".9rem" />
                      </Tooltip>
                      {isCopied && isSelectedTopic === topicRecord.topic && <Badge ml="0.5rem">Copied</Badge>}
                    </Box>
                  )}
                </BlockSectionHeader>
              )}
              <Stack
                justifyContent="space-between"
                spacing="1rem"
                mt={textOnlyView ? "0!important" : "unset"}
                cursor={textTruncated ? "pointer" : "unset"}>
                {getSlicedHighlights(topicRecord.highlights).map((highlightRecord, index) => (
                  <Stack direction="row" key={highlightRecord.id}>
                    <HighlightItem
                      textOnlyView={textOnlyView}
                      highlightRecord={highlightRecord}
                      textTruncated={textTruncated}
                      showRecordLinkBtn={showRecordLinkBtn}
                      showActionMenu={showActionMenu}
                      onClose={onClose}
                      searchPhrase={searchPhrase}
                      maxLines={maxLines}
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          ))}
        </Stack>
      )}
    </>
  );
};
