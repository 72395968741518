import type { JSONSchema7 } from "json-schema";

export const NewProjectFormSchema: JSONSchema7 = {
  $id: "http://json-schema.org/draft-07/schema#",
  type: ["object", "null"],
  properties: {
    title: {
      type: "string",
      description: "The title of the panel that is shown when the user creates a new project of this type",
    },
    fields: {
      type: "array",
      items: { $ref: "#/definitions/IField" },
      description: "The fields of the form. The available fields are defined in src/screens/common/components/DynamicForm.tsx",
    },
    entitySource: {
      type: "string",
      description:
        "The entity source is the identifier of the entity group for the form. To make sure entities with the same name can be over written or unique. Must be lowercase letters and dashes",
      examples: ["my-entity-source"],
    },
    formId: {
      type: "string",
      description: "The id of the form. Used for testing. Must be lowercase letters and dashes.",
      examples: ["my-form-id"],
    },
    showUploadMaxFiles: {
      type: "number",
      description:
        "Optional. This is the maximum number of files that can be uploaded at one time. If this is not set then there is no limit.",
      examples: [5],
    },
    uploadStepTags: {
      type: "string",
      description:
        "A comma separated string of tags used to pre-populate the tag input under the file dropzone when creating a new project of this type",
      examples: ["my-tag, my-other-tag"],
    },
    projectFormStepOrder: {
      type: "array",
      items: {
        type: "string",
      },
      description:
        "One or more of the following step options, 'upload', 'criteria', 'report'. If a step is omitted then the step will not be displayed in the new project panel. If this is not set then the default order is ['upload', 'criteria', 'report']",
      default: ["upload", "criteria", "report"],
    },
  },
  description: "The definition of the panel and form that is shown in a panel when the user creates a new project of this type",
  required: ["intent", "title", "route"],
  definitions: {
    UserIcon: {
      type: "string",
    },
    IField: {
      $id: "/schemas/field",
      type: "object",
      description: "The definition of inputs for the new project form",
      oneOf: [
        { $ref: "#/definitions/IFieldText" },
        { $ref: "#/definitions/IFieldSelect" },
        { $ref: "#/definitions/IFieldMultiSelect" },
        {
          $ref: "#/definitions/IFieldSwitch",
        },
        {
          $ref: "#/definitions/IDivider",
        },
        {
          $ref: "#/definitions/IStack",
        },
        {
          $ref: "#/definitions/IDuplicateStack",
        },
        {
          $ref: "#/definitions/IFieldIntegrationSelect",
        },
        {
          $ref: "#/definitions/IFieldCollectionSelect",
        },
        {
          $ref: "#/definitions/IFieldTagsInput",
        },
      ],
      definitions: {
        IFieldInput: {
          type: "object",
          properties: {
            background: {
              type: "string",
            },
            borderRadius: {
              type: "string",
            },
            className: {
              type: "string",
            },
            entity: {
              type: "string",
            },
            entityFieldSource: {
              type: "string",
            },
            id: {
              type: "string",
            },
            isRequired: {
              type: "boolean",
            },
            label: {
              type: "string",
            },
            placeholder: {
              type: "string",
            },
            rounded: {
              type: "string",
            },
            size: {
              enum: ["sm", "md", "lg"],
              type: "string",
            },
            userPreferenceKey: {
              type: "string",
            },
          },
          required: ["label", "entity"],
        },
        IFieldText: {
          type: "object",
          description: "Adds a text field to the form",
          properties: {
            type: {
              type: "string",
              enum: ["text"],
            },
            defaultValue: {
              type: "string",
            },
            background: {
              type: "string",
            },
            borderRadius: {
              type: "string",
            },
            className: {
              type: "string",
            },
            entity: {
              type: "string",
            },
            entityFieldSource: {
              type: "string",
            },
            id: {
              type: "string",
            },
            isRequired: {
              type: "boolean",
            },
            label: {
              type: "string",
            },
            placeholder: {
              type: "string",
            },
            rounded: {
              type: "string",
            },
            size: {
              enum: ["sm", "md", "lg"],
              type: "string",
            },
            userPreferenceKey: {
              type: "string",
            },
          },
          required: ["type", "defaultValue", "label", "entity"],
        },
        IFieldSelect: {
          type: "object",
          description: "Adds a select field to the form",
          properties: {
            type: {
              type: "string",
              enum: ["select"],
            },
            options: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  label: {
                    type: "string",
                  },
                  value: {
                    type: "string",
                  },
                },
                required: ["label", "value"],
              },
            },
            optionsFunction: {
              type: "string",
            },
            defaultValue: {
              type: "object",
              properties: {
                label: {
                  type: "string",
                },
                value: {
                  type: "string",
                },
              },
              required: ["label", "value"],
            },
            entitySeparator: {
              type: "string",
            },
            splitEntity: {
              type: "boolean",
            },
            background: {
              type: "string",
            },
            borderRadius: {
              type: "string",
            },
            className: {
              type: "string",
            },
            entity: {
              type: "string",
            },
            entityFieldSource: {
              type: "string",
            },
            id: {
              type: "string",
            },
            isRequired: {
              type: "boolean",
            },
            label: {
              type: "string",
            },
            placeholder: {
              type: "string",
            },
            rounded: {
              type: "string",
            },
            size: {
              enum: ["sm", "md", "lg"],
              type: "string",
            },
            userPreferenceKey: {
              type: "string",
            },
          },
          required: ["type", "options", "defaultValue", "label", "entity"],
        },
        IFieldMultiSelect: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["multi-select"],
            },
            options: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  label: {
                    type: "string",
                  },
                  value: {
                    type: "string",
                  },
                },
                required: ["label", "value"],
              },
            },
            optionsFunction: {
              type: "string",
            },
            defaultValue: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  label: {
                    type: "string",
                  },
                  value: {
                    type: "string",
                  },
                },
                required: ["label", "value"],
              },
            },
            entitySeparator: {
              type: "string",
            },
            splitEntity: {
              type: "boolean",
            },
            background: {
              type: "string",
            },
            borderRadius: {
              type: "string",
            },
            className: {
              type: "string",
            },
            entity: {
              type: "string",
            },
            entityFieldSource: {
              type: "string",
            },
            id: {
              type: "string",
            },
            isRequired: {
              type: "boolean",
            },
            label: {
              type: "string",
            },
            placeholder: {
              type: "string",
            },
            rounded: {
              type: "string",
            },
            size: {
              enum: ["sm", "md", "lg"],
              type: "string",
            },
            userPreferenceKey: {
              type: "string",
            },
          },
          required: ["type", "label", "entity"],
        },
        IFieldSwitch: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["switch"],
            },
            trueValue: {
              type: "string",
            },
            colorScheme: {
              type: "string",
            },
            defaultValue: {
              type: "boolean",
            },
            background: {
              type: "string",
            },
            borderRadius: {
              type: "string",
            },
            className: {
              type: "string",
            },
            entity: {
              type: "string",
            },
            entityFieldSource: {
              type: "string",
            },
            id: {
              type: "string",
            },
            isRequired: {
              type: "boolean",
            },
            label: {
              type: "string",
            },
            placeholder: {
              type: "string",
            },
            rounded: {
              type: "string",
            },
            size: {
              enum: ["sm", "md", "lg"],
              type: "string",
            },
            userPreferenceKey: {
              type: "string",
            },
          },
          required: ["type", "defaultValue", "label", "entity"],
        },
        IDescription: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["description"],
            },
            text: {
              type: "string",
            },
            backgroundColor: {
              type: "string",
            },
            fontColor: {
              type: "string",
            },
            id: {
              type: "string",
            },
            fontSize: {
              type: "string",
            },
            isBold: {
              type: "boolean",
            },
          },
          required: ["type"],
        },
        IDivider: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["divider"],
            },
          },
          required: ["type"],
        },
        ISpace: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["space"],
            },
          },
          required: ["type"],
        },
        IStack: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["stack"],
            },
            fields: {
              type: "array",
              items: {},
            },
            isInline: {
              type: "boolean",
            },
            justifyContent: {
              type: "string",
              enum: ["flex-start", "center", "flex-end", "space-between", "space-around", "space-evenly"],
            },
            spacing: {
              type: "string",
            },
          },
          required: ["type", "fields"],
        },
        ITabs: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["tabs"],
            },
            fields: {
              type: "array",
              items: {},
            },
          },
          required: ["type", "fields"],
        },
        ITab: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["tab"],
            },
            fields: {
              type: "array",
              items: {},
            },
          },
          required: ["type", "fields"],
        },
        IDuplicateStack: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["duplicate-stack"],
            },
            fields: {
              type: "array",
              items: {},
            },
            entity: {
              type: "string",
            },
            isInline: {
              type: "boolean",
            },
            justifyContent: {
              type: "string",
              enum: ["flex-start", "center", "flex-end", "space-between", "space-around", "space-evenly"],
            },
            spacing: {
              type: "string",
            },
          },
          required: ["type", "fields"],
        },
        IFieldIntegrationSelect: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["integration-select"],
            },
            category: {
              type: "string",
            },
            categoryKey: {
              type: "string",
            },
            defaultValue: {
              type: "string",
            },
            background: {
              type: "string",
            },
            borderRadius: {
              type: "string",
            },
            className: {
              type: "string",
            },
            entity: {
              type: "string",
            },
            entityFieldSource: {
              type: "string",
            },
            id: {
              type: "string",
            },
            isRequired: {
              type: "boolean",
            },
            label: {
              type: "string",
            },
            placeholder: {
              type: "string",
            },
            rounded: {
              type: "string",
            },
            size: {
              enum: ["sm", "md", "lg"],
              type: "string",
            },
            userPreferenceKey: {
              type: "string",
            },
          },
          required: ["type", "category", "label", "entity"],
        },
        IFieldCollectionSelect: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["collection-select"],
            },
            collectionType: {
              type: "string",
            },
            shouldCreateNewCollection: {
              type: "boolean",
            },
            defaultValue: {
              type: "string",
            },
            background: {
              type: "string",
            },
            borderRadius: {
              type: "string",
            },
            className: {
              type: "string",
            },
            entity: {
              type: "string",
            },
            entityFieldSource: {
              type: "string",
            },
            id: {
              type: "string",
            },
            isRequired: {
              type: "boolean",
            },
            label: {
              type: "string",
            },
            placeholder: {
              type: "string",
            },
            rounded: {
              type: "string",
            },
            size: {
              enum: ["sm", "md", "lg"],
              type: "string",
            },
            userPreferenceKey: {
              type: "string",
            },
          },
          required: ["type", "label", "entity"],
        },
        IFieldProjectSelect: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["project-select"],
            },
            defaultValue: {
              type: "string",
            },
            background: {
              type: "string",
            },
            borderRadius: {
              type: "string",
            },
            className: {
              type: "string",
            },
            entity: {
              type: "string",
            },
            entityFieldSource: {
              type: "string",
            },
            id: {
              type: "string",
            },
            isRequired: {
              type: "boolean",
            },
            label: {
              type: "string",
            },
            placeholder: {
              type: "string",
            },
            rounded: {
              type: "string",
            },
            size: {
              enum: ["sm", "md", "lg"],
              type: "string",
            },
            userPreferenceKey: {
              type: "string",
            },
          },
          required: ["type", "category", "label", "entity"],
        },
        IFieldTagsInput: {
          type: "object",
          properties: {
            type: {
              type: "string",
              enum: ["tags-input"],
            },
            defaultValue: {
              type: "string",
            },
            background: {
              type: "string",
            },
            borderRadius: {
              type: "string",
            },
            className: {
              type: "string",
            },
            entity: {
              type: "string",
            },
            entityFieldSource: {
              type: "string",
            },
            id: {
              type: "string",
            },
            isRequired: {
              type: "boolean",
            },
            label: {
              type: "string",
            },
            placeholder: {
              type: "string",
            },
            rounded: {
              type: "string",
            },
            size: {
              enum: ["sm", "md", "lg"],
              type: "string",
            },
            userPreferenceKey: {
              type: "string",
            },
          },
          required: ["type", "label", "entity"],
        },
      },
    },
  },
  additionalProperties: false,
};
