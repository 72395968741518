import { Badge, Box, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { JSONEditor } from "screens/common/components";
import type { ResourceWithId } from "state/informationExtractionConfig/reducer";
import type { UpsertResourceRequest } from "api/informationExtractionConfig/models/api/CreateResourceRequest";
import { useResourcesMap } from "hooks/useInformationExtractionConfig";
import { v4 } from "uuid";

interface Props {
  onClose: () => void;
  onSubmit: (payload: UpsertResourceRequest) => void;
  resource: ResourceWithId;
}

const mapResourceToCreateResourceRequest = (resource: ResourceWithId): UpsertResourceRequest => {
  return {
    urn: resource.urn,
    category: resource.category,
    keywords: resource.keywords,
    visibility: resource.visibility,
    confidenceThreshold: resource.confidenceThreshold,
    documentConfig: resource.documentConfig,
    jsonConfig: resource.jsonConfig,
    ...(resource.entities && {
      entities: resource.entities.map((entity) => ({
        id: v4(),
        resourceUrn: resource.urn,
        entityName: entity.entityName,
        entityType: entity.entityType,
        ...(entity.keywords && { keywords: entity.keywords }),
        ...(entity.categories && { categories: entity.categories }),
        ...(entity.pattern && { pattern: entity.pattern }),
        allowMultipleValues: entity.allowMultipleValues,
        occurrence: entity.occurrence,
        ...(entity.titleKeywords && { titleKeywords: entity.titleKeywords }),
        ...(entity.confidenceThreshold && { confidenceThreshold: entity.confidenceThreshold }),
        ...(entity.negativeKeywords && { negativeKeywords: entity.negativeKeywords }),
        ...(entity.exactMatching && { exactMatching: entity.exactMatching }),
      })),
    }),
  };
};

export const ImportInformationExtraction = (props: Props) => {
  const { onClose, onSubmit, resource } = props;
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const resourcesMap = useResourcesMap();

  const createResourceObj = useMemo(() => mapResourceToCreateResourceRequest(resource), [resource]);

  return (
    <PanelView isOpen onClose={onClose} panelTitle={"Import Information Extraction Config"}>
      <Wizard>
        <PanelStep
          onNextLabel="Save"
          onSubmit={() => {
            onSubmit(createResourceObj);
          }}>
          <Box my=".5rem" height={`calc(10vh - 15rem)`}>
            <Stack direction="row">
              <Box flex={"1"}>
                <JSONEditor height="calc(100vh - 15rem)" value={JSON.stringify(createResourceObj, null, 2)} editable={false} />
              </Box>
            </Stack>

            {resourcesMap[resource.urn] && (
              <Stack spacing=".5rem" mt=".5rem" maxWidth={"80%"}>
                <Stack direction="row">
                  <Box>
                    <Badge fontSize={"medium"} colorScheme={"red"}>
                      WARNING
                    </Badge>
                  </Box>
                  <Text fontSize="medium" color={textColor}>
                    The resource with urn{" "}
                    <Text as={"span"} fontWeight={"bold"}>
                      {resource.urn}
                    </Text>{" "}
                    already exists. If you import this resource, the existing resource will be overwritten.
                  </Text>
                </Stack>
              </Stack>
            )}
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
