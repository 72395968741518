import React from "react";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import type { FieldValues } from "react-hook-form";
import type { IChildPanel } from "../types";
import { PanelView } from "screens/panels/components/PanelView";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { useButtonProps } from "hooks";

export const ChildPanel = <T extends FieldValues>(props: IChildPanel<T> & { iteration?: number; renderFields: () => JSX.Element[] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const commonButtonProps = useButtonProps("sm", "primary");

  return (
    <>
      <Box>
        <Button {...commonButtonProps} onClick={onOpen}>
          {props.buttonTitle}
        </Button>
      </Box>

      <PanelView isOpen={isOpen} onClose={onClose} panelTitle={props.panelTitle}>
        <Wizard>
          <PanelStep>{props.renderFields()}</PanelStep>
        </Wizard>
      </PanelView>
    </>
  );
};
