import { String, InstanceOf, Record, Union } from "runtypes";
import type { Static } from "runtypes";

export const Organization = Record({
  id: String,
  name: String,
  planCode: String,
  creationDate: Union(InstanceOf(Date), String),
  lastUpdatedDate: Union(InstanceOf(Date), String),
  creationByUserId: String,
  creationByUserName: String,
  lastUpdatedByUserId: String,
  lastUpdatedByUserName: String,
});

export type Organization = Static<typeof Organization>;
