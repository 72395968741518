import { createAction, createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import type { RootState } from "state/rootReducer";
import type { Notification } from "types/notifications";
import { downloadNotifications, dismissNotification, dismissNotifications, disableNotificationFollowOnAction } from "./operations";

const rehydrate = createAction<RootState>(REHYDRATE);
export interface NotificationState {
  notifications: { [key: string]: Notification };
  hasInitialSyncCompleted: boolean;
}

const initialState: NotificationState = {
  notifications: {},
  hasInitialSyncCompleted: false,
};

export const { actions, reducer } = createSlice({
  name: "notification",
  initialState,
  reducers: {
    flush(state) {
      state.notifications = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (state, action) => {
      if (action.payload?.notification) {
        return { ...action.payload.notification, hasInitialSyncCompleted: false };
      }
    });
    builder.addCase(downloadNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
      state.hasInitialSyncCompleted = true;
    });
    builder.addCase(dismissNotification.pending, (state, action) => {
      const notificationId = action.meta.arg;
      if (state.notifications[notificationId]) {
        state.notifications[notificationId].hasBeenDismissed = true;
      }
    });
    builder.addCase(dismissNotification.rejected, (state, action) => {
      const notificationId = action.meta.arg;
      if (state.notifications[notificationId]) {
        state.notifications[notificationId].hasBeenDismissed = false;
      }
    });
    builder.addCase(disableNotificationFollowOnAction.pending, (state, action) => {
      const notificationId = action.meta.arg;
      if (state.notifications[notificationId]) {
        state.notifications[notificationId].followOnActionEnabled = false;
      }
    });
    builder.addCase(disableNotificationFollowOnAction.rejected, (state, action) => {
      const notificationId = action.meta.arg;
      if (state.notifications[notificationId]) {
        state.notifications[notificationId].followOnActionEnabled = true;
      }
    });
    builder.addCase(dismissNotifications.pending, (state) => {
      Object.keys(state.notifications).forEach((id) => {
        state.notifications[id].hasBeenDismissed = true;
      });
    });
  },
});

export default reducer;
