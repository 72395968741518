import { createAsyncThunk } from "@reduxjs/toolkit";
import type { CreateReportTemplateRequest } from "api/reportTemplates/models/CreateReportTemplateRequest";
import {
  getReportTemplatesForUser,
  createReportTemplate,
  updateReportTemplate as updateReportTemplateAPI,
  deleteReportTemplate,
} from "api/reportTemplates/reportTemplates";
import type { UpdateReportTemplateRequest } from "../../api/reportTemplates/models/UpdateReportTemplateRequest";

export const downloadReportTemplates = createAsyncThunk("report-templates/download-by-user-id", () => {
  return getReportTemplatesForUser();
});

export const addReportTemplate = createAsyncThunk(
  "report-templates/create",
  async (reportTemplate: CreateReportTemplateRequest, thunkAPI) => {
    await createReportTemplate(reportTemplate);
    thunkAPI.dispatch(downloadReportTemplates());
  }
);

export const updateReportTemplate = createAsyncThunk(
  "report-templates/update",
  async ({ id, reportTemplate }: { id: string; reportTemplate: UpdateReportTemplateRequest }, thunkAPI) => {
    await updateReportTemplateAPI(id, reportTemplate);
    thunkAPI.dispatch(downloadReportTemplates());
  }
);

export const removeReportTemplate = createAsyncThunk("report-templates/delete", async (reportTemplateId: string, thunkAPI) => {
  await deleteReportTemplate(reportTemplateId);
  thunkAPI.dispatch(downloadReportTemplates());
});
