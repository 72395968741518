import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  FormControl,
  FormLabel,
  Stack,
  Text,
  Code,
  useColorModeValue,
  Divider,
  Box,
  Tooltip,
  Center,
  useOutsideClick,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { InputWithSuggestions } from "screens/common/components/InputWithSuggestions";
import { useConfiguredWorkflowsList } from "hooks/useConfiguredWorkflows";
import type { Control } from "react-hook-form";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";
import { getRegisteredHandlers } from "api/registeredHandlers";
import { RegisteredHandler } from "api/registeredHandlers/models/RegisteredHandler";
import sortBy from "lodash/sortBy";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useDebouncedCallback } from "use-debounce";
import { useCheckpointIntentContext } from "../context/CheckpointIntentContext";
import uniqBy from "lodash/uniqBy";
import { Popover } from "react-tiny-popover";
import { useEntitlements, useTileProps, useUserProfile } from "hooks";
import filter from "lodash/filter";

interface Props {
  control?: Control<FormValues>;
  index: number;
}

export const CheckpointUserIntentField = ({ index }: Props) => {
  const { control } = useFormContext<FormValues>();
  const { handlersMap, setHandlersMap } = useCheckpointIntentContext();
  const [handlers, setHandlers] = useState<RegisteredHandler[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentHandlers, setCurrentHandlers] = useState<RegisteredHandler[] | null>(null);
  const checkpointIntent = useWatch({ control, name: `config.checkpoints.${index}.intent` });
  const [showPopover, setShowPopover] = useState(false);
  const codeBgColor = useColorModeValue("gray.200", "#191f23");
  const codeColor = useColorModeValue("blackAlpha", "white");
  const mainRef = useRef<HTMLDivElement | null>(null);
  const commonTileProps = useTileProps();
  const { read_workflows_admin: hasWorkflowsAdminEntitlement } = useEntitlements();
  const { id: userId } = useUserProfile();

  const {
    formState: { errors },
  } = useFormContext<FormValues>();

  const configuredWorkflows = useConfiguredWorkflowsList();

  const suggestions = useMemo(() => {
    return sortBy(
      [
        ...uniqBy(
          filter(configuredWorkflows, (cw) => {
            if (
              cw.state === "backup" ||
              cw.state === "deleted" ||
              (cw.state === "draft" && userId && !cw?.approvedByUsers?.find(({ id }) => id === userId))
            ) {
              return false;
            } else {
              return true;
            }
          }),
          (h) => h.userIntent
        ).map((cw) => ({ value: cw.userIntent, metadata: cw })),
        ...uniqBy(handlers, (h) => h.intent).map((h) => ({
          value: h.intent,
          metadata: h,
        })),
      ],
      "value"
    );
  }, [configuredWorkflows, userId, handlers]);

  const debounceGetRegisteredHandlers = useDebouncedCallback((filter: string) => {
    if (filter.length < 3) {
      return;
    }

    if (handlersMap[filter]) {
      setHandlers(handlersMap[filter]);
      const maybeHandler = handlersMap[filter].filter((h) => h.intent === filter);
      if (maybeHandler) {
        setCurrentHandlers(maybeHandler);
      }
    } else if (hasWorkflowsAdminEntitlement) {
      setIsLoading(true);
      getRegisteredHandlers(filter)
        .then((res) => {
          setHandlersMap((prev) => ({ ...prev, [filter]: res }));
          setHandlers(res);
          const maybeHandler = res.filter((h) => h.intent === filter);
          if (maybeHandler) {
            setCurrentHandlers(maybeHandler);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, 1000);

  useEffect(() => {
    debounceGetRegisteredHandlers(checkpointIntent ?? "");
  }, [checkpointIntent, debounceGetRegisteredHandlers]);

  useOutsideClick({
    ref: mainRef!,
    handler: () => {
      setShowPopover(false);
    },
  });

  return (
    <>
      <FormControl
        isInvalid={!!(errors.config?.checkpoints && errors.config.checkpoints[index]?.intent)}
        ref={mainRef}
        mr="0.5rem"
        width="100%">
        <Popover
          parentElement={mainRef?.current || undefined}
          isOpen={showPopover}
          positions={["top"]}
          align="start"
          reposition={false}
          onClickOutside={() => setShowPopover(false)}
          content={() => (
            <Box {...commonTileProps} padding="1rem" cursor={"default"} boxShadow="md" maxH={200} overflowX="auto">
              {currentHandlers &&
                currentHandlers.map((currentHandler) => (
                  <Stack key={currentHandler.id}>
                    <Text fontSize="xs">
                      ID:{" "}
                      <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                        {currentHandler.id}
                      </Code>
                    </Text>
                    <Text fontSize="xs">
                      Name:{" "}
                      <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                        {currentHandler.name}
                      </Code>
                    </Text>
                    <Text fontSize="xs">
                      Integration:{" "}
                      <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                        {currentHandler.integration}
                      </Code>
                    </Text>
                    {(() => {
                      const requires = [
                        ...(currentHandler.requires ?? []).map((item) => `${item}!`),
                        ...(currentHandler.mustIncludeEntities ?? []).map((item) => `[${item}!]`),
                        ...(currentHandler.optionalRequires ?? []),
                        ...(currentHandler.shouldIncludeEntities ?? []).map((item) => `[${item}]`),
                      ];
                      return requires.length > 0 ? (
                        <Text fontSize="xs">
                          Requires:{" "}
                          {requires.map((require, i) => (
                            <React.Fragment key={`require-${require}`}>
                              {i > 0 && ", "}
                              <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                                {require}
                              </Code>
                            </React.Fragment>
                          ))}
                        </Text>
                      ) : null;
                    })()}
                    {currentHandler.requirements.length > 0 && (
                      <Text fontSize="xs">
                        Requirements:{" "}
                        {currentHandler.requirements.map((field, i) => (
                          <React.Fragment key={`requirement-${field}`}>
                            {i > 0 && ", "}
                            <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                              {field}
                            </Code>
                          </React.Fragment>
                        ))}
                      </Text>
                    )}
                    {currentHandler.produces.length > 0 && (
                      <Text fontSize="xs">
                        Produces:{" "}
                        {currentHandler.produces.map((produce, i) => (
                          <React.Fragment key={`produce-${produce}`}>
                            {i > 0 && ", "}
                            <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                              {produce}
                            </Code>
                          </React.Fragment>
                        ))}
                      </Text>
                    )}
                    {currentHandlers.length > 1 && <Divider />}
                  </Stack>
                ))}
            </Box>
          )}>
          <Stack direction="row">
            <FormLabel fontSize="sm">Intent</FormLabel>
            {currentHandlers && currentHandlers.length > 0 ? (
              <Center>
                <Box>
                  <Tooltip label="More information" aria-label="More information">
                    <InfoOutlineIcon cursor="pointer" height="1.5em" onClick={() => setShowPopover(!showPopover)} />
                  </Tooltip>
                </Box>
              </Center>
            ) : null}
          </Stack>
        </Popover>
        <Controller
          render={({ field }) => (
            <InputWithSuggestions
              ref={field.ref}
              value={field.value ?? ""}
              onChange={(value, metadata) => {
                const validateHandler = RegisteredHandler.validate(metadata);
                const maybeHandlers = handlers.filter((h) => h.intent === value);

                if (validateHandler.success) {
                  setCurrentHandlers(maybeHandlers);
                } else if (maybeHandlers.length > 0) {
                  setCurrentHandlers(maybeHandlers);
                } else {
                  setCurrentHandlers(null);
                }

                field.onChange(value);
              }}
              suggestions={suggestions}
              size="sm"
              inputProps={{
                size: "sm",
                type: "text",
                name: field.name,
                isDisabled: isLoading,
              }}
              showButtons={false}
              isLoading={isLoading}
            />
          )}
          name={`config.checkpoints.${index}.intent`}
          control={control}
        />
        {errors.config?.checkpoints && errors.config.checkpoints[index]?.intent && (
          <FormErrorMessage>{errors.config.checkpoints[index]?.intent?.message}</FormErrorMessage>
        )}
      </FormControl>
    </>
  );
};
