import { Stack, Text, useColorModeValue, Box, ListItem, UnorderedList } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useContext } from "react";
import ECHighlighter from "react-ec-highlighter";
import { ContentFilterContext } from "screens/content";
import type { GapAnalysisSuggestedQuestions } from "types/collection";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { BiCopy } from "react-icons/bi";
import { SmallActionConfidenceScore } from "screens/content/contentCanvas/cell/SmallActionConfidenceScore";

export interface Props {
  gapAnalysisQuestions: GapAnalysisSuggestedQuestions[];
  subSection?: boolean;
}

export const SuggestedQuestionsResults: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  gapAnalysisQuestions,
  subSection,
}) => {
  const iconColor = useColorModeValue("gray.500", "gray.400");
  const { searchText } = useContext(ContentFilterContext);

  const copyQuestions = () => {
    const questionsString = gapAnalysisQuestions.map((question) => question.question).join("\n");

    return questionsString;
  };

  return (
    <Stack>
      <BlockSectionHeader title="Suggested Questions" subSection={subSection} direction="row">
        <SmallActionButton
          classNames="ch-copy-record"
          iconTypeName={BiCopy}
          onClick={() => {
            navigator.clipboard.writeText(copyQuestions());
          }}
          tooltip={"Copy suggested questions"}
        />
      </BlockSectionHeader>
      <Box>
        <UnorderedList spacing={".5rem"} pl=".5rem">
          {gapAnalysisQuestions.map((questionRecord, index) => (
            <ListItem key={index}>
              <Stack direction="row" width="100%" justifyContent={"space-between"}>
                <Text fontSize="sm" key={index} pl=".2rem" width="100%">
                  {searchText && searchText.length > 0 && questionRecord ? (
                    <ECHighlighter searchPhrase={searchText} text={questionRecord.question} />
                  ) : (
                    questionRecord.question
                  )}
                </Text>
                {questionRecord.confidence && (
                  <SmallActionConfidenceScore
                    score={Math.round(questionRecord.confidence * 100)}
                    tooltip={`Confidence score ${Math.round(questionRecord.confidence * 100)}`}
                    borderColor={iconColor}
                  />
                )}
              </Stack>
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
    </Stack>
  );
};
