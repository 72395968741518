import { Box, Stack, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useCallback } from "react";
import type { SearchHistory } from "types/collection";
import { ResearchHistoryItem } from "./ResearchHistoryItem";

interface Props {
  searchHistories?: SearchHistory[];
  collection: { id: string; name: string };
}

export const ResearchHistory: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  searchHistories,
  collection,
}) => {
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const bgColor = useColorModeValue("white", "gray.800");

  const historyPanes = useCallback(() => {
    return (
      <Stack justifyContent={"space-between"} spacing={"1rem"}>
        {searchHistories?.map((searchHistory) => {
          return (
            <Box
              key={`research-ran-at-${searchHistory.run}`}
              borderRadius="md"
              boxShadow="none"
              p="1.5rem"
              borderWidth="1px"
              borderColor={borderColor}
              backgroundColor={bgColor}>
              <ResearchHistoryItem
                searchHistory={searchHistory}
                showUseResearchButton
                collection={{ id: collection.id, name: collection.name }}
              />
            </Box>
          );
        })}
      </Stack>
    );
  }, [bgColor, borderColor, collection.id, collection.name, searchHistories]);

  return <Stack>{historyPanes()}</Stack>;
};
