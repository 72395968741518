import { createSlice } from "@reduxjs/toolkit";
import { downloadNewsInformation } from "./operations";
import type { ExternalLink } from "api/newsData/models/ExternalLink";

interface NewsInformationState {
  externalLinks: ExternalLink[];
  isLoading: boolean;
}

const initialState: NewsInformationState = {
  externalLinks: [],
  isLoading: false,
};

export const { actions, reducer } = createSlice({
  name: "newsInformation",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadNewsInformation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadNewsInformation.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadNewsInformation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.externalLinks = action.payload;
    });
  },
});

export default reducer;
