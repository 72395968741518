import { Stack, Box, Center, Circle, Text, useColorModeValue, SimpleGrid } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useCallback } from "react";
import React from "react";
import { ContentImageWrapper } from "screens/content/common/ContentImage/ContentImageWrapper";

interface Props {
  metadataIds?: string[];
  thumbnailHeight?: string;
  thumbnailWidth?: string;
  thumbnailBorderRadius?: string;
  contentCount?: number;
}

export const ProjectThumbnailsGrid: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  metadataIds,
  thumbnailHeight = "50px",
  thumbnailWidth = "70px",
  thumbnailBorderRadius = "5px",
  contentCount,
}) => {
  const thumbnailBorderColor = useColorModeValue("gray.200", "gray.700");
  const additionalThumbnailCountBgColor = useColorModeValue("white", "gray.500");
  const additionalThumbnailCountBorderColor = useColorModeValue("gray.200", "gray.700");
  const additionalThumbnailCountTextColor = useColorModeValue("gray.500", "gray.400");

  const contentLength = contentCount || metadataIds?.length || 0;
  const renderContentImageWrapper = useCallback(() => {
    return metadataIds ? (
      metadataIds.map((id, index) => {
        return (
          <ContentImageWrapper
            key={`${id}-${index}`}
            metadataId={id}
            thumbnailWidth={thumbnailWidth}
            thumbnailHeight={thumbnailHeight}
            thumbnailBorderRadius={thumbnailBorderRadius}
            fit="cover"
            thumbnailFit="cover"
            thumbnailPosition="center"
            bgColor={"gray.100"}
          />
        );
      })
    ) : (
      <React.Fragment />
    );
  }, [metadataIds, thumbnailBorderRadius, thumbnailHeight, thumbnailWidth]);

  return (
    <Box position={"relative"} width="6.9rem" className="ch-project-thumbnail-grid">
      {contentLength > 2 && (
        <Stack direction="row" width="100%" justifyContent={"flex-end"}>
          <Circle
            position={"absolute"}
            top="2px"
            right="2px"
            zIndex={3}
            size="1.3rem"
            bgColor={additionalThumbnailCountBgColor}
            borderWidth={"1px"}
            borderColor={additionalThumbnailCountBorderColor}>
            <Text fontSize={"10px"} textColor={additionalThumbnailCountTextColor}>
              +{contentLength - 2}
            </Text>
          </Circle>
        </Stack>
      )}
      <SimpleGrid columns={2} spacing={1}>
        {renderContentImageWrapper()}
        {contentLength === 0 && (
          <Center
            width={thumbnailWidth}
            height={thumbnailHeight}
            maxWidth="100%"
            borderRadius={thumbnailBorderRadius}
            borderColor={thumbnailBorderColor}
            borderStyle="solid"
            borderWidth="1px"
            backgroundColor={additionalThumbnailCountBgColor}
            flexShrink={0}>
            <Text fontSize={"10px"} textAlign="center" p="5px" lineHeight={"12px"} textColor={additionalThumbnailCountTextColor}>
              No Content
            </Text>
          </Center>
        )}
      </SimpleGrid>
    </Box>
  );
};
