import { useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import type { RootState } from "state/rootReducer";
import { ToastMessageContent } from "screens/common/components";
import { ConversationContext } from "screens/thread/ConversationContext";
import { downloadConversationById } from "state/conversation/operations";

export const Conversation = () => {
  const { slug: conversationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [hasFetchedSpecificConversation, setHasFetchedSpecificConversation] = useState(false);
  const { conversations, hasInitialSyncCompleted, isLoading } = useSelector((state: RootState) => state.conversation, shallowEqual);
  const { setConversationId, onConversationOpen, onConversationClose } = useContext(ConversationContext);

  useEffect(() => {
    if (conversationId && !hasFetchedSpecificConversation) {
      dispatch(downloadConversationById({ conversationId }));
      setHasFetchedSpecificConversation(true);
    }
  }, [conversationId, hasFetchedSpecificConversation, dispatch]);

  useEffect(() => {
    if (hasInitialSyncCompleted) {
      if (conversationId && !conversations[conversationId] && !isLoading && hasFetchedSpecificConversation) {
        onConversationClose();
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={"Unfortunately I can't find that conversation, please ensure that you are logged in with the correct account."}
              onClose={onClose}
            />
          ),
          duration: 10000,
          isClosable: true,
          position: "top-right",
        });
      } else if (conversationId) {
        setConversationId(conversationId);
        onConversationOpen();
      }
    }
  }, [
    hasInitialSyncCompleted,
    hasFetchedSpecificConversation,
    conversations,
    conversationId,
    navigate,
    toast,
    setConversationId,
    onConversationOpen,
    onConversationClose,
    isLoading,
  ]);

  return <React.Fragment />;
};
