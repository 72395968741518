import React from "react";
import { PanelView } from "screens/panels/components/PanelView";
import { PanelStep } from "screens/panels/components/PanelStep";
import { Wizard } from "react-use-wizard";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import { TaskJsonPayload } from "./TaskJsonPayload";
import { WorkflowStepErrorDetails } from "./WorkflowStepErrorDetails";

export const WorkflowPayloadPanel = () => {
  const { onWorkflowPayloadPanelClose, setPayloadType, setTaskId, isWorkflowPayloadPanelOpen, payloadType } = useWorkflowContext();

  const handleClose = () => {
    setTaskId("");
    setPayloadType(null);
    onWorkflowPayloadPanelClose();
  };

  return (
    <PanelView
      isOpen={isWorkflowPayloadPanelOpen}
      onClose={handleClose}
      panelTitle={payloadType === "error" ? "Error Details" : "Task Payload"}>
      <Wizard>
        <PanelStep>
          {payloadType === "error" && <WorkflowStepErrorDetails />}
          {payloadType === "json" && <TaskJsonPayload />}
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
