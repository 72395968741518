import type { Static } from "runtypes";
import { Array, Record, String } from "runtypes";
import { MessageState } from "./MessageState";
import { DataElement } from "./DataElement";

export const Message = Record({
  id: String,
  conversationId: String.optional(),
  senderId: String,
  recipientId: String,
  createdDate: String,
  intent: String.optional(),
  content: String.nullable().optional(),
  state: MessageState.nullable().optional(),
  data: Array(DataElement).optional(),
});

export type Message = Static<typeof Message>;
