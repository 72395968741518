import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getConfiguredWorkflows,
  deleteConfiguredWorkflow,
  postConfiguredWorkflow,
  putConfiguredWorkflow,
  approveConfiguredWorkflow as approveConfiguredWorkflowAPI,
  unapproveConfiguredWorkflow as unapproveConfiguredWorkflowAPI,
  updateWorkflowState,
  getConfiguredWorkflow,
  updateWorkflowConfigAvailableToUsers,
} from "api/configuredWorkflows";
import { getUsersByIds } from "api/user";
import type { RootState } from "state/rootReducer";
import type { ConfiguredWorkflowCreationPayload } from "types/configuredWorkflows";

export const downloadConfiguredWorkflows = createAsyncThunk("configured-workflow/download-all", () => {
  return getConfiguredWorkflows();
});

export const downloadConfiguredWorkflow = createAsyncThunk("configured-workflow/download-one", ({ id }: { id: string }) => {
  return getConfiguredWorkflow(id);
});

export const addApprovedConfiguredWorkflow = createAsyncThunk(
  "configured-workflow/add-approved-by-user",
  async ({ workflowConfigId, userId, userName }: { workflowConfigId: string; userId: string; userName?: string }) => {
    if (!userName) {
      const users = await getUsersByIds([userId]);

      if (users.length > 0) {
        return { workflowConfigId, userId, userName: `${users[0].firstName} ${users[0].lastName}` };
      } else {
        throw new Error("User not found");
      }
    } else {
      return { workflowConfigId, userId, userName };
    }
  }
);

export const removeApprovedConfiguredWorkflow = createAsyncThunk(
  "configured-workflow/remove-approved-by-user",
  ({ workflowConfigId, userId }: { workflowConfigId: string; userId: string }) => {
    return { workflowConfigId, userId };
  }
);

export const createConfiguredWorkflow = createAsyncThunk(
  "configured-workflow/create",
  (configuredWorkflow: ConfiguredWorkflowCreationPayload) => {
    return postConfiguredWorkflow(configuredWorkflow);
  }
);

export const updateConfiguredWorkflow = createAsyncThunk(
  "configured-workflow/update",
  (configuredWorkflow: ConfiguredWorkflowCreationPayload) => {
    return putConfiguredWorkflow(configuredWorkflow);
  }
);

export const deleteConfiguredWorkflowAction = createAsyncThunk(
  "configured-workflow/delete",
  ({ id, skipServerDelete }: { id: string; skipServerDelete?: boolean }) => {
    if (skipServerDelete) {
      return { id };
    }

    return deleteConfiguredWorkflow(id);
  }
);

export const approveConfiguredWorkflow = createAsyncThunk("configured-workflow/approve", (configuredWorkflowId: string, thunkAPI) => {
  const {
    session: { user: { id: userId } = { id: undefined } },
  } = thunkAPI.getState() as RootState;

  if (userId) {
    return approveConfiguredWorkflowAPI(configuredWorkflowId, userId);
  }
});

export const unapproveConfiguredWorkflow = createAsyncThunk("configured-workflow/unapprove", (configuredWorkflowId: string, thunkAPI) => {
  const {
    session: { user: { id: userId } = { id: undefined } },
  } = thunkAPI.getState() as RootState;

  if (userId) {
    return unapproveConfiguredWorkflowAPI(configuredWorkflowId, userId);
  }
});

export const updateConfiguredWorkflowState = createAsyncThunk(
  "configured-workflow/update-state",
  ({ configuredWorkflowId, state, fromVersion }: { configuredWorkflowId: string; state: "draft" | "active"; fromVersion?: string }) => {
    return updateWorkflowState(configuredWorkflowId, state, fromVersion);
  }
);

export const updateAvailableToUsers = createAsyncThunk(
  "configured-workflow/available-to-users",
  ({ configuredWorkflowId, onlyAvailableToUserIds }: { configuredWorkflowId: string; onlyAvailableToUserIds: string[] }) => {
    return updateWorkflowConfigAvailableToUsers(configuredWorkflowId, onlyAvailableToUserIds);
  }
);
