import { shallowEqual, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { burgundyTheme, defaultTheme } from "theme";

export function useCustomTheme() {
  const identityProvider = useSelector((state: RootState) => state.session.user?.identityProvider, shallowEqual);
  const isAstrella = identityProvider === "astrella";

  return {
    theme: isAstrella ? burgundyTheme : defaultTheme,
    isEmbeddedApp: isAstrella,
  };
}
