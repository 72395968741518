import type { Static } from "runtypes";
import { Number, Record, String } from "runtypes";

export const PlanComponent = Record({
  componentHandle: String,
  quantityToAllocate: Number,
  pricePointHandle: String.optional(),
});

export type PlanComponent = Static<typeof PlanComponent>;
