import type { UserConfig, UserConfigProperty } from "types/config";
import { AiOutlineDatabase, AiOutlineHome } from "react-icons/ai";
import {
  HiOutlineBookOpen,
  HiOutlineClipboardList,
  HiOutlineFolderDownload,
  HiOutlineLightningBolt,
  HiOutlineVideoCamera,
} from "react-icons/hi";
import { RiMapPinLine, RiFileList3Line, RiMailSendLine, RiShieldCrossLine } from "react-icons/ri";
import { TbFileShredder, TbFileStack, TbReportMoney } from "react-icons/tb";
import { MdOutlinePolicy } from "react-icons/md";
import { BsPersonAdd } from "react-icons/bs";
import type { ProjectConfigState } from "state/config/reducer";
import { GrLineChart } from "react-icons/gr";
import { PiStack } from "react-icons/pi";

export const IconsList = [
  "chart-line",
  "home",
  "book-open",
  "clipboard-list",
  "folder-download",
  "lightning-bolt",
  "map-pin-line",
  "file-list-3",
  "video-camera",
  "report-money",
  "shredder",
  "file-stack",
  "email-send",
  "policy",
  "ethics",
  "database",
  "person",
  "stack",
] as const;

export const getTypeIcon = (type: typeof IconsList[number]) => {
  switch (type) {
    case "chart-line": {
      return GrLineChart;
    }
    case "stack": {
      return PiStack;
    }
    case "home": {
      return AiOutlineHome;
    }
    case "book-open": {
      return HiOutlineBookOpen;
    }
    case "clipboard-list": {
      return HiOutlineClipboardList;
    }
    case "folder-download": {
      return HiOutlineFolderDownload;
    }
    case "email-send": {
      return RiMailSendLine;
    }
    case "lightning-bolt": {
      return HiOutlineLightningBolt;
    }
    case "map-pin-line": {
      return RiMapPinLine;
    }
    case "file-list-3": {
      return RiFileList3Line;
    }
    case "video-camera": {
      return HiOutlineVideoCamera;
    }
    case "report-money": {
      return TbReportMoney;
    }
    case "shredder": {
      return TbFileShredder;
    }
    case "file-stack": {
      return TbFileStack;
    }
    case "policy": {
      return MdOutlinePolicy;
    }
    case "ethics": {
      return RiShieldCrossLine;
    }
    case "database": {
      return AiOutlineDatabase;
    }
    case "person": {
      return BsPersonAdd;
    }
    default:
      return AiOutlineHome;
  }
};

export function getTypeFromRoute(route?: string) {
  if (!route) return "home";

  const splittedRoute = route.split("/");

  return splittedRoute[1] ?? splittedRoute[0];
}

export function getViewConfig<K extends UserConfigProperty>(
  type: K,
  configId: string,
  configMap: Record<string, ProjectConfigState>
): UserConfig[K] {
  const userConfig = Object.values(configMap).find(
    (projectConfig) =>
      projectConfig.config.collectionType === configId || projectConfig.config.route === configId || projectConfig.id === configId
  )?.config;

  if (!userConfig || !userConfig[type]) return "" as never;

  return userConfig[type];
}
