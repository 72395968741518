import type { FunctionComponent } from "react";
import React from "react";
import { Box, Center } from "@chakra-ui/react";
import { TypingIndicator } from "screens/thread/components/cells/components";

interface Props {
  isLoading: boolean;
  height?: string;
  loadingComponent?: React.ReactNode;
}

export const LoadingGate: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  isLoading,
  height = "100%",
  children,
  loadingComponent,
}) => {
  if (isLoading) {
    return (
      <Center width="100%" flexGrow={1} height={height}>
        <Box p="4" alignSelf="center" pb="6">
          {loadingComponent ?? <TypingIndicator />}
        </Box>
      </Center>
    );
  } else {
    return <>{children}</>;
  }
};
