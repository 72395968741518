import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { downloadShareHistory as downloadShareHistoryHunk } from "state/shareHistory/operations";
import type { RootState } from "state/rootReducer";
import type { ShareHistoryResponseType } from "api/share/ShareHistoryResponse";

export function useDownloadShareHistory() {
  const dispatch = useDispatch();

  return useCallback(
    (metadataId: string) => {
      dispatch(downloadShareHistoryHunk({ metadataId }));
    },
    [dispatch]
  );
}

export function useShareHistory(metadataId: string): ShareHistoryResponseType {
  const { shareHistory } = useSelector((state: RootState) => state.shareHistory);

  if (shareHistory[metadataId]) {
    return shareHistory[metadataId];
  } else {
    return { sharedByMe: [], sharedWithMe: [] };
  }
}
