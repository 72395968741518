import React, { useCallback } from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import { Stack, Input, IconButton, FormControl, FormLabel, Box, Button, Icon, useColorModeValue, FormErrorMessage } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { AiOutlineDelete } from "react-icons/ai";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";

interface IProps {
  checkpointIndex: number;
}

export const EntitiesToRenameFieldArray = (props: IProps) => {
  const { checkpointIndex } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>();
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const commonButtonProps = useButtonProps("sm", "secondary");
  const { fields, append, remove } = useFieldArray({
    control,
    name: `config.checkpoints.${checkpointIndex}.entitiesToRename`,
  });

  const getEntityError = useCallback(
    (index: number, name: "entityName" | "renameTo") => {
      const maybeEntitiesToRenameErrors = errors.config?.checkpoints && errors.config.checkpoints[checkpointIndex]?.entitiesToRename;

      if (!maybeEntitiesToRenameErrors) {
        return undefined;
      } else return maybeEntitiesToRenameErrors[index]?.[name];
    },
    [errors, checkpointIndex]
  );

  return (
    <Box bgColor={"transparent"}>
      <Button
        {...commonButtonProps}
        onClick={() =>
          append({
            entityName: "",
            renameTo: "",
          })
        }>
        Add Entity to Rename
      </Button>
      {fields.map((field, index) => (
        <Stack direction="row" spacing="0.5rem" justifyContent="space-between" width="100%" key={field.id} mt="1rem" height="100%">
          <FormControl isInvalid={!!getEntityError(index, "entityName")}>
            <FormLabel fontSize="sm">Entity Name</FormLabel>
            <Controller
              render={({ field }) => <Input {...field} size="sm" type="text" />}
              name={`config.checkpoints.${checkpointIndex}.entitiesToRename.${index}.entityName`}
              control={control}
            />
            {getEntityError(index, "entityName") && <FormErrorMessage>{getEntityError(index, "entityName")?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!getEntityError(index, "renameTo")}>
            <FormLabel fontSize="sm">Rename To</FormLabel>
            <Controller
              render={({ field }) => <Input {...field} size="sm" type="text" />}
              name={`config.checkpoints.${checkpointIndex}.entitiesToRename.${index}.renameTo`}
              control={control}
            />
            {getEntityError(index, "renameTo") && <FormErrorMessage>{getEntityError(index, "renameTo")?.message}</FormErrorMessage>}
          </FormControl>
          <Box pt="1.5rem">
            <IconButton
              onClick={() => remove(index)}
              aria-label="Delete"
              backgroundColor="unset"
              icon={<Icon as={AiOutlineDelete} color={buttonColor} boxSize="1rem" _hover={{ color: buttonHoverColor }} />}
              size="sm"
              _hover={{ backgroundColor: "unset" }}
            />
          </Box>
        </Stack>
      ))}
    </Box>
  );
};
