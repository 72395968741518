import {
  Box,
  Stack,
  useColorModeValue,
  Text,
  AccordionButton,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import type { ExtractedTableHighlights } from "types/content/ExtractedTableHighlights";

interface Props {
  extractedTableHighlights: ExtractedTableHighlights[];
  bgColor?: string;
}

export const ExtractedTableHighlightSection: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  extractedTableHighlights,
  bgColor,
}) => {
  const topicColor = useColorModeValue("primary.darkGray", "gray.200");
  const secondaryTextColor = useColorModeValue("primary.darkGray", "gray.200");

  return extractedTableHighlights.length > 0 ? (
    <Box backgroundColor={bgColor}>
      {extractedTableHighlights.map(
        (topicRecord, index) =>
          topicRecord.highlights.length > 0 && (
            <Accordion allowToggle>
              <AccordionItem border="none">
                <AccordionButton
                  paddingInline={0}
                  outline="0 none transparent"
                  padding="0"
                  _hover={{ background: "transparent" }}
                  width="100%">
                  <Stack direction="row" justifyContent="space-between" width="100%">
                    <Stack direction="row">
                      <Text color={topicColor} pb=".5rem" fontSize="sm" fontWeight="500">
                        {topicRecord.topic}
                      </Text>
                    </Stack>
                    <AccordionIcon />
                  </Stack>
                </AccordionButton>
                <AccordionPanel paddingInline={0} padding={0}>
                  {topicRecord.highlights.map(
                    (highlightRecord, index) =>
                      highlightRecord.highlight.length > 0 && (
                        <Text fontSize="sm" color={secondaryTextColor} fontWeight="400" cursor="text" userSelect="text" pb=".5rem">
                          {highlightRecord.highlight}
                        </Text>
                      )
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )
      )}
    </Box>
  ) : (
    <></>
  );
};
