import { Box, ListItem, UnorderedList, Text, Stack } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import ECHighlighter from "react-ec-highlighter";
import type { AssessmentResultsFollowUpQuestions } from "types/collection";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";
import { BiCopy } from "react-icons/bi";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";

export interface Props {
  assessmentResults: AssessmentResultsFollowUpQuestions[];
  searchText?: string;
  subSection?: boolean;
}

export const AssessmentResultsFollowupQuestions: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  assessmentResults,
  searchText,
  subSection,
}) => {
  const copyQuestions = () => {
    const questionsString = assessmentResults.map((question) => question.question).join("\n");

    return questionsString;
  };

  return (
    <>
      {assessmentResults.length > 0 && (
        <Stack>
          <BlockSectionHeader title="Followup Questions" subSection={subSection} direction="row">
            <SmallActionButton
              classNames="ch-copy-record"
              iconTypeName={BiCopy}
              onClick={() => {
                navigator.clipboard.writeText(copyQuestions());
              }}
              tooltip={"Copy suggested questions"}
            />
          </BlockSectionHeader>
          <Box>
            <UnorderedList spacing={".5rem"} pl=".5rem">
              {assessmentResults.map((record, index) => (
                <ListItem key={index} className="ch-assessment-result-followup-question">
                  <Text fontSize="sm" key={index} width="100%">
                    {searchText && searchText.length > 0 && record.question ? (
                      <ECHighlighter searchPhrase={searchText} text={record.question} />
                    ) : (
                      record.question
                    )}
                  </Text>
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        </Stack>
      )}
    </>
  );
};
