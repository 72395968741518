import React from "react";
import { Stack, Text, Center, Box } from "@chakra-ui/react";
import { chartItemColor } from "./CustomChartUtils";

interface Props {
  data: { label: string; value: number; state: string };
  width?: string;
  onClick?: (data: { label: string; state: string }) => void;
}

export const CustomSimpleMetric: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  data,
  width,
  onClick,
}) => {
  return !width ? (
    <React.Fragment />
  ) : (
    <Center
      maxWidth={width}
      cursor="pointer"
      onClick={(event) => {
        onClick && data.label && data.state && onClick({ label: data.label, state: data.state });
        event.stopPropagation();
      }}>
      <Stack>
        <Box borderRadius="md" borderWidth="1px" bgColor={chartItemColor(data.state)} color="white">
          <Center>
            <Text fontSize={"2rem"} align={"center"} margin="0!important" padding="0">
              {data.value}
            </Text>
          </Center>
        </Box>
        <Center>
          <Text fontSize={"xs"} align={"center"} margin="0!important" padding="0">
            {data.label}
          </Text>
        </Center>
      </Stack>
    </Center>
  );
};
