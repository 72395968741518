import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { ContextCoordinatesDetail } from "types/contextCoordinates";

interface ContextCoordinatesState {
  coordinatesByAnswerId: Record<string, ContextCoordinatesDetail[]>;
}

const initialState: ContextCoordinatesState = {
  coordinatesByAnswerId: {},
};

export const { actions, reducer } = createSlice({
  name: "contextCoordinates",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
    setCoordinates: (state, action: PayloadAction<Record<string, ContextCoordinatesDetail[]>>) => {
      Object.entries(action.payload).forEach(([answerId, coordinates]) => {
        state.coordinatesByAnswerId[answerId] = coordinates;
      });
    },
  },
});

export default reducer;
