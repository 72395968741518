import { Record, String, Array, Literal } from "runtypes";
import type { Static } from "runtypes";

export const TriggerChildWorkflow = Record({
  type: Literal("trigger_child_workflow"),
  intent: String,
  entitiesToInclude: Array(String),
});

export type TriggerChildWorkflow = Static<typeof TriggerChildWorkflow>;
