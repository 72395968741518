import { Stack, Switch } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useContext, useState, useEffect } from "react";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";
import { ReportSelector } from "screens/panels/researchReport/ReportSelector";

export const AddValuationDetails: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const { setRequestEntities } = useContext(ConversationContext);
  const [shouldPullLtsa, setShouldPullLtsa] = useState(false);

  const updateRequestEntity = (entityName: string, entityValue: string) => {
    updateBatchRequestEntities([{ entity: entityName, value: entityValue, source: "valuation-inputs" }], setRequestEntities);
  };

  useEffect(() => {
    updateRequestEntity("confirmed_intent", shouldPullLtsa ? "order_title" : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldPullLtsa]);

  return (
    <Stack spacing="1.5rem" overflowY="auto" flexShrink={1}>
      <ReportSelector isReportPanel />
      <Stack direction="row" justifyContent="space-between" width="15rem">
        <SectionHeader title="Automatically order land title" />
        <Switch
          size="sm"
          className="land-title-toggle"
          onChange={(event) => setShouldPullLtsa(event.target.checked)}
          isChecked={shouldPullLtsa}
          colorScheme="teal"
        />
      </Stack>
    </Stack>
  );
};
