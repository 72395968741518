import { Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext, useState, useMemo, useEffect } from "react";
import React from "react";
import { TagInput } from "screens/common/components/Tags/TagInput";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useTags } from "hooks";
import uniq from "lodash/uniq";

interface Props {
  collectionEntitySet?: boolean;
  defaultTags?: string;
  onChangeTags?: (commaSeparatedTags: string) => void;
  title?: string;
  placeholder?: string;
  usesEntities?: boolean;
  suggestedTags?: string[];
}

export const AddTagDetails: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  onChangeTags,
  defaultTags,
  title = "Tags",
  placeholder,
  usesEntities = true,
  suggestedTags: suggestedTagsProp,
}) => {
  const { requestEntities, setRequestEntities } = useContext(ConversationContext);
  const [inputTags, setInputTags] = useState(defaultTags ?? getRequestValue("tag", requestEntities));
  const { tags: suggestedTags } = useTags({ hideDefaultTags: true });
  const suggestions = useMemo(
    () => uniq((suggestedTagsProp || suggestedTags).map((tag) => tag.replace(/(?<!,)\s+/g, "-"))),
    [suggestedTags, suggestedTagsProp]
  );

  const updateRequestEntity = (entityName: string, entityValue: string) => {
    updateBatchRequestEntities([{ entity: entityName, value: entityValue, source: "tag-note-inputs" }], setRequestEntities);
  };

  useEffect(() => {
    if (defaultTags) {
      updateRequestEntity("tag", `${defaultTags.length > 0 ? defaultTags : ""}` as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTags]);

  const setNewTags = (tags: string) => {
    const newTags = inputTags.length > 0 ? `${inputTags},${tags}` : tags;
    setInputTags(newTags);

    if (usesEntities) {
      updateRequestEntity("tag", `${newTags.length > 0 ? newTags : ""}` as string);
    }
  };

  const deleteTags = (tags: string) => {
    const currentTags = inputTags;
    const newTags = currentTags
      .split(",")
      .filter((tag: string) => tag !== tags)
      .join(",");
    setInputTags(newTags);

    if (usesEntities) {
      updateRequestEntity("tag", `${newTags.length > 0 ? newTags : ""}` as string);
    }
  };

  const tags = useMemo(() => {
    return inputTags.length > 0 ? inputTags.split(",") : [];
  }, [inputTags]);

  useEffect(() => {
    onChangeTags && onChangeTags(inputTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTags]);

  return (
    <Box>
      <SectionHeader title={title} />
      <TagInput
        options={suggestions.map((option) => ({ label: option, value: option }))}
        tags={tags}
        tagType="manual"
        allowUpdate={false}
        onSaveTag={(tagValue) => setNewTags(tagValue)}
        onDeleteTag={(tagValue) => deleteTags(tagValue)}
        {...(placeholder && { placeholder })}
      />
    </Box>
  );
};
