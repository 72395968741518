import React, { useEffect } from "react";
import type { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { page } from "api/analytics";

const cleanPath = (path: string) => {
  if (path.startsWith("/conversation/")) {
    return "/conversation/[conversation-id-redacted]";
  } else {
    return path;
  }
};

export const Analytics: React.FC<React.PropsWithChildren<React.PropsWithChildren<PropsWithChildren>>> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const cleanedPath = cleanPath(location.pathname + location.search);

    page(cleanedPath);
  }, [location.pathname, location.search]);

  return <>{children}</>;
};
