import React, { useCallback, useMemo, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import {
  Stack,
  Text,
  FormControl,
  Box,
  Icon,
  useColorModeValue,
  SimpleGrid,
  Input,
  Select as ChakraSelect,
  Button,
  useDisclosure,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useButtonProps, useEntitlements } from "hooks";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import capitalize from "lodash/capitalize";
import type { PayloadAction } from "@reduxjs/toolkit";
import orderBy from "lodash/orderBy";
import type { OrganizationFormValues } from "./UpsertOrganizationModal";
import type { Role } from "types/organization/Role";
import { SearchIcon } from "@chakra-ui/icons";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { AddUsersPanel } from "./AddUsersPanel";
import { UserDetailModal } from "../../usersAdmin/modals/UserDetailModal";
import { useUsersSearch } from "hooks/useUsers";
import { ConfirmDeleteModal } from "screens/common/components/ConfirmDeleteModal";
import type { ModalKey } from "types/modal";
import { RenderIfHasEntitlements } from "screens/common/components";

interface IProps {
  organizationId?: string;
  onDelete: (userId: string) => Promise<void | { error?: unknown } | PayloadAction>;
  canDelete?: boolean;
  onChangeUserRole: (userId: string, role: Role) => Promise<boolean>;
  isLoadingOrganization: boolean;
  currentUserRole?: string;
}

export const OrganizationUsersFieldArrayInner = (props: IProps) => {
  const { isOpen: isOpenAddUser, onClose: onCloseAddUser, onOpen: onOpenAddUser } = useDisclosure();
  const { control } = useFormContext<OrganizationFormValues>();
  const { canDelete = true, isLoadingOrganization, onChangeUserRole, organizationId, onDelete, currentUserRole } = props;
  const [isOpen, setIsOpen] = useState<ModalKey | null>();

  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const bgColor = useColorModeValue("white!important", "gray.700!important");
  const commonButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const searchInput = React.createRef<HTMLInputElement>();
  const [searchText, setSearchText] = useState("");
  const [selectedUserRecord, setSelectedUserRecord] = useState<{ id: string; recordId?: string }>({ id: "", recordId: "" });

  const onClose = useCallback(() => {
    setIsOpen(null);
    setSelectedUserRecord({ id: "", recordId: "" });
  }, []);

  const users = useUsersSearch(searchText);
  const selectedUser = users.find((user) => user.id === selectedUserRecord.id);

  const { fields: unorderedFields, remove } = useFieldArray({
    control,
    name: "members",
  });
  const { view_users: hasViewUsers, manage_organizations_write: hasOrgWrite } = useEntitlements();

  const fields = useMemo(() => orderBy(unorderedFields, "label"), [unorderedFields]);
  const fieldsIndexes = useMemo(() => new Map(unorderedFields.map(({ id }, index) => [id, index])), [unorderedFields]);
  const userFilter = fields.filter((user) => {
    return user.label.toLowerCase().includes(searchText.toLowerCase());
  });

  const onDeleteMember = useCallback(async () => {
    if (!selectedUserRecord.id || !organizationId) {
      return;
    }

    const onDeleteResponse = await onDelete(selectedUserRecord.id);

    if (Object.prototype.hasOwnProperty.call(onDeleteResponse, "error")) {
      // Error removing member
      return;
    }

    const fieldIndex = fieldsIndexes.get(selectedUserRecord.id);
    remove(fieldIndex);

    onClose();
  }, [fieldsIndexes, organizationId, selectedUserRecord.id, onClose, onDelete, remove]);

  if (!hasViewUsers) {
    return <></>;
  }

  const onButtonAddClick = () => {
    onOpenAddUser();
  };

  return (
    <Box backgroundColor={bgColor} pb="2rem">
      <Stack direction="row" width="100%" justifyContent="space-between" pb="1rem">
        <Stack direction="row" width="100%">
          <Box width="4rem" pt="4px">
            <SectionHeader title="Users" />
          </Box>
          {fields.length > 0 && (
            <InputGroup size="sm" width="20rem">
              <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
              <Input
                {...secondaryButtonProps}
                placeholder={"Filter by name or email..."}
                ref={searchInput}
                defaultValue={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </InputGroup>
          )}
        </Stack>
        <Button
          isDisabled={!organizationId || !hasOrgWrite}
          {...commonButtonProps}
          px="1.5rem"
          size="sm"
          className={`ch-new-collection-btn`}
          onClick={onButtonAddClick}>
          Add Users
        </Button>
      </Stack>
      {!organizationId && (
        <Stack direction="row" width="100%" pt="2rem" justifyContent="center">
          <Text>You have to save the new Organization before adding users to it.</Text>
        </Stack>
      )}
      <SimpleGrid maxHeight={500} overflowY="auto" columns={[1, 1, 2, 3, 4]} spacingX="1.5rem" spacingY="1.5rem">
        {userFilter.map((field) => {
          const originalIndex = fieldsIndexes.get(field.id);

          if (originalIndex === undefined) {
            return <React.Fragment key={field.id} />;
          }

          return (
            <Stack
              justifyContent={"space-between"}
              cursor={"pointer"}
              borderWidth="2px"
              borderRadius="lg"
              padding="1rem"
              key={field.id}
              onClick={(event) => {
                event.stopPropagation();
                setSelectedUserRecord({ id: field.value, recordId: field.id });
                setIsOpen("detail");
              }}>
              <FormControl>
                <Controller
                  render={({ field }) => <Input {...field} type="hidden" mr="0.5rem" fontSize="md" boxShadow="none" required />}
                  name={`members.${originalIndex}.value`}
                  control={control}
                />
                <Text>{field.label}</Text>
              </FormControl>
              <Stack direction="row" width="100%" justifyContent="space-between" pt="1rem">
                <Controller
                  control={control}
                  name={`members.${originalIndex}.role`}
                  render={({ field: controlField }) => {
                    return (
                      <ChakraSelect
                        {...controlField}
                        size="sm"
                        disabled={isLoadingOrganization || !hasOrgWrite || currentUserRole !== "admin"}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) => {
                          const value = event.target.value as Role;
                          onChangeUserRole(field.value, value).then((isSuccess) => {
                            if (isSuccess) {
                              controlField.onChange(value);
                            }
                          });
                        }}
                        fontSize="md"
                        required>
                        {["member", "admin"].map((role) => (
                          <option key={role} value={role}>
                            {capitalize(role)}
                          </option>
                        ))}
                      </ChakraSelect>
                    );
                  }}
                />
                {canDelete && (
                  <Box pt="4px">
                    <Icon
                      cursor={"pointer"}
                      as={AiOutlineDelete}
                      color={buttonColor}
                      boxSize="1.5rem"
                      _hover={{ color: buttonHoverColor }}
                      onClick={(event) => {
                        setSelectedUserRecord({ id: field.value, recordId: field.id });
                        setIsOpen("delete");
                        event.stopPropagation();
                      }}
                    />
                  </Box>
                )}
              </Stack>
            </Stack>
          );
        })}
      </SimpleGrid>
      {isOpen === "delete" && (
        <ConfirmDeleteModal
          title="Remove Member"
          body="Are you sure you want to remove this member? This will remove the user from the organization but not remove them from Charli. The user will no longer have the entitlements associated to this organisation but will still be able to login to Charli with any personal entitlements they have."
          buttonLabel="Remove"
          isOpen
          onClose={onClose}
          onConfirm={onDeleteMember}
        />
      )}
      {isOpenAddUser && organizationId && <AddUsersPanel id={organizationId} onClose={onCloseAddUser} />}
      {selectedUser && isOpen === "detail" && <UserDetailModal user={selectedUser} isOpen={!!selectedUser} onClose={onClose} />}
    </Box>
  );
};

export const OrganizationUsersFieldArray = (props: IProps) => {
  return (
    <RenderIfHasEntitlements entitlement={"view_users"} headerMessageComponent={<SectionHeader title="Users" />}>
      <OrganizationUsersFieldArrayInner {...props} />
    </RenderIfHasEntitlements>
  );
};
