import type { Middleware } from "redux";
import type { RootState } from "state/rootReducer";

export const loggerMiddleware: Middleware = (store) => (next) => (action) => {
  const state = store.getState() as RootState;

  if (state.flag.flags.enable_redux_logger) {
    console.info("Action: ", action);
    const result = next(action);
    console.info("Next State:", state);

    return result;
  } else {
    next(action);
  }
};
