import React from "react";
import { Box } from "@chakra-ui/react";
import marked from "marked";
import DOMPurify from "dompurify";
import parse from "html-react-parser";

interface MarkdownViewerProps {
  content: string;
  fontSize?: string;
  fontColor?: string;
  backgroundColor?: string;
}

export const MarkdownViewer: React.FC<MarkdownViewerProps> = ({
  content,
  fontSize = "sm",
  fontColor = "gray.800",
  backgroundColor = "white",
}) => {
  const parsedMarkdown = marked(content);
  const sanitizedHtml = DOMPurify.sanitize(parsedMarkdown);

  return (
    <Box
      className="markdown-content"
      fontSize={fontSize}
      color={fontColor}
      bg={backgroundColor}
      sx={{
        "& h1, & h2, & h3, & h4, & h5, & h6": {
          fontWeight: "bold",
          marginBottom: "0.5rem",
        },
        "& p": {
          marginBottom: "1rem",
        },
        "& ul, & ol": {
          marginLeft: "1.5rem",
          marginBottom: "1rem",
        },
        "& blockquote": {
          borderLeft: "4px solid",
          borderColor: "gray.200",
          paddingLeft: "1rem",
          marginY: "1rem",
        },
        "& code": {
          bg: "gray.100",
          padding: "0.2rem",
          borderRadius: "0.2rem",
        },
        "& pre": {
          bg: "gray.100",
          padding: "1rem",
          borderRadius: "0.5rem",
          marginY: "1rem",
          overflowX: "auto",
        },
      }}>
      {parse(sanitizedHtml)}
    </Box>
  );
};
