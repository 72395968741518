import { Flex, FormControl, Icon, IconButton, Select, useColorModeValue } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import React from "react";
import { BiSave } from "react-icons/bi";
import type { OrganizationFormValues } from "screens/landing/tabs/admin/organizationsAdmin/modals/UpsertOrganizationModal";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { useButtonProps } from "hooks";

interface IProps {
  label: string;
  keyField: "name" | "planCode";
  id: string;
  atomic?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  hasChanged?: boolean;
  onUpdate?: () => void;
  children: JSX.Element[];
}

export const FormSelectField = ({
  label,
  keyField,
  id,
  atomic = false,
  disabled = false,
  isRequired = false,
  hasChanged = false,
  onUpdate,
  children,
}: IProps) => {
  const { control } = useFormContext<OrganizationFormValues>();
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const tileBorderColor = useColorModeValue("gray.400", "gray.600");
  const secondaryButtonStyle = useButtonProps("sm", "secondary");

  return (
    <FormControl pb="1px">
      <SectionHeader title={label} />
      <Flex>
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              size="sm"
              color={textColor}
              value={field.value || ""}
              id={id}
              mb="0.5rem"
              fontSize="md"
              boxShadow="none"
              disabled={disabled}
              isRequired={isRequired}>
              {children}
            </Select>
          )}
          control={control}
          name={keyField}
        />
        {atomic && (
          <IconButton
            {...secondaryButtonStyle}
            color={tileBorderColor}
            disabled={!hasChanged}
            aria-label="Save"
            size="sm"
            ml="1rem"
            icon={<Icon as={BiSave} color={tileBorderColor} boxSize="1.5rem" />}
            onClick={onUpdate}
          />
        )}
      </Flex>
    </FormControl>
  );
};
