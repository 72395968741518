import { Box, Stack, useColorModeValue, usePrevious } from "@chakra-ui/react";
import { useCellsForMessage } from "./cells/utils";
import { useDownloadWorkflowsByConversationId } from "hooks/useWorkflows";
import { WorkflowProgressCell } from "./cells/WorkflowProgressCell";
import { TypingIndicatorCell } from "./cells";
import type { FunctionComponent } from "react";
import { useRef } from "react";
import React, { useCallback, useEffect, useMemo } from "react";
import type { Message } from "types/conversation";
import find from "lodash/find";
import last from "lodash/last";

type Props = {
  messages: Message[];
  conversationId: string;
};

export const MessagesView: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ conversationId, messages }) => {
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const bgColor = useColorModeValue("white", "gray.800");
  const getCellsForMessage = useCellsForMessage();
  const observer = useRef<IntersectionObserver | null>(null);
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const loadingObserver = useCallback((node: HTMLDivElement | null) => {
    if (!node) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsIntersecting(true);
      } else {
        setIsIntersecting(false);
      }
    });

    observer.current.observe(node);
  }, []);

  const filteredMessages = useMemo(() => {
    return messages.filter((message) => {
      // filter out action_required messages that have no content
      const hasAuthData = (message.data ?? []).some((datum) => datum.type === "auth_request");
      const hasContent = message.content && message.content.trim().length > 0;

      return message.state === "action_required" ? hasAuthData || hasContent : true;
    });
  }, [messages]);
  const previousCountOfMessages = usePrevious(filteredMessages.length);
  const lastMessage = useMemo(() => last(filteredMessages), [filteredMessages]);

  const findLastWorkflowCreated = (allMessages: Message[]) => {
    const reversedMessages = allMessages.slice().reverse();
    for (const message of reversedMessages) {
      if (!message.data) {
        continue;
      }

      const lastCreatedWorkflow = find(message.data, { type: "workflow_created" });

      if (lastCreatedWorkflow && lastCreatedWorkflow.type === "workflow_created") {
        return lastCreatedWorkflow.body.id;
      }
    }
  };

  const lastWorkflowId = useMemo(() => findLastWorkflowCreated(filteredMessages), [filteredMessages]);

  const renderMessages = useCallback(() => {
    return messages.map((message) => getCellsForMessage(message, message.id === lastMessage?.id));
  }, [messages, getCellsForMessage, lastMessage]);

  const renderProgressBarCell = useCallback(() => {
    if (!lastWorkflowId || lastMessage?.state === "action_required") return null;

    return <WorkflowProgressCell key={`workflow-progress-indicator`} workflowId={lastWorkflowId} />;
  }, [lastMessage, lastWorkflowId]);

  const renderEndConversationMessage = useCallback(() => {
    if (!lastMessage) return null;

    const isCharliTaskInProgress = lastMessage.senderId === "charli" && lastMessage.state === "task_in_progress";
    const isOtherAcknowledged = lastMessage.senderId !== "charli" && lastMessage.acknowledgmentStatus === "acknowledged";

    if (isCharliTaskInProgress || isOtherAcknowledged) {
      return (
        <Box display={"flex"} ref={loadingObserver} key={"typing-indicator"}>
          <TypingIndicatorCell />
        </Box>
      );
    }

    return null;
  }, [lastMessage, loadingObserver]);

  const renderStoppedConversationSelector = useCallback(() => {
    if (lastMessage?.state) {
      const isTaskInProgress = lastMessage.state === "task_in_progress";
      const keyId = isTaskInProgress ? "task-in-progress" : "task-stopped";

      return <Box width={0} height={0} key={keyId} id={keyId} />;
    }

    return null;
  }, [lastMessage]);

  useEffect(() => {
    if ((filteredMessages.length !== previousCountOfMessages || lastWorkflowId) && isIntersecting) {
      const ref = messagesEndRef.current;
      if (ref) {
        ref.scrollIntoView();

        setTimeout(() => {
          // Do it twice, to workaround a Firefox bug
          ref.scrollIntoView();
        }, 100);
      }
    }
  }, [filteredMessages.length, previousCountOfMessages, lastWorkflowId, isIntersecting]);

  useDownloadWorkflowsByConversationId(conversationId);

  return (
    <Stack direction="column-reverse" overflow="auto" backgroundColor={bgColor} borderTopLeftRadius={["0", "0", "10px"]} height="100%">
      <div ref={messagesEndRef} />
      <Stack spacing="1rem" px="1rem" pt="1rem">
        {renderMessages()}
        {renderProgressBarCell()}
        {renderEndConversationMessage()}
        {renderStoppedConversationSelector()}
      </Stack>
    </Stack>
  );
};
