import { Array, Record, String } from "runtypes";
import type { Static } from "runtypes";
import { TaskLog } from "./TaskLog";

export const TaskLogResponse = Record({
  data: Array(TaskLog),
  nextToken: String.nullable(),
});

export type TaskLogResponse = Static<typeof TaskLogResponse>;
