import type { RequestEntities } from "types/charliui";
import type { useItemSelector } from "hooks";
import type { ItemIdentifier } from "types/items";

export type CurrentViewIcon = { type: "file" | "drive"; extension?: string } | undefined;

const mapItemsToEntities = (items: ReturnType<typeof useItemSelector>["items"]) => {
  return Object.entries(items).map(([id, itemProperties]) => {
    return { entity: itemProperties.type, value: id };
  });
};

export const getEntitiesForSelectedItems = (selectedItems?: ReturnType<typeof useItemSelector>["items"]): RequestEntities => {
  if (selectedItems) {
    return mapItemsToEntities(selectedItems);
  } else {
    return [];
  }
};

export const getEntitiesForFocusedItem = (focusedItem?: ReturnType<typeof useItemSelector>["focusedItem"]): RequestEntities => {
  if (focusedItem) {
    return mapItemsToEntities({
      [focusedItem.id]: { type: focusedItem.type },
    });
  } else {
    return [];
  }
};

export const getEntitiesForModalSelections = (selectedItems: ItemIdentifier[]): RequestEntities => {
  return selectedItems.map((item) => {
    return {
      entity: item.type,
      value: item.id,
    };
  });
};

export const getEntitiesForCanvasSelections = (
  selectedItems: ReturnType<typeof useItemSelector>["items"],
  focusedItem: ReturnType<typeof useItemSelector>["focusedItem"]
): RequestEntities => {
  if (focusedItem) {
    return mapItemsToEntities({
      [focusedItem.id]: { type: focusedItem.type },
    });
  } else {
    return mapItemsToEntities(selectedItems);
  }
};

const mapItemsToEntitiesString = (items: ReturnType<typeof useItemSelector>["items"]) => {
  return Object.entries(items)
    .map(([id, itemProperties]) => `>${itemProperties.type} ${id.trim()}`)
    .join(" ");
};

export const getEntitiesStringForSelectedItems = (selectedItems?: ReturnType<typeof useItemSelector>["items"]): string => {
  if (selectedItems) {
    return mapItemsToEntitiesString(selectedItems);
  } else {
    return "";
  }
};

export const getEntitiesStringForFocusedItem = (focusedItem?: ReturnType<typeof useItemSelector>["focusedItem"]): string => {
  if (focusedItem) {
    return mapItemsToEntitiesString({
      [focusedItem.id]: { type: focusedItem.type },
    });
  } else {
    return "";
  }
};

export const getSelectedItemsByType = (
  selectedItems: ReturnType<typeof useItemSelector>["items"],
  itemType: string
): ReturnType<typeof useItemSelector>["items"] | undefined => {
  const filterByType = Object.entries(selectedItems).filter(([id, itemProperties]) => itemProperties.type === itemType);
  return filterByType.length ? Object.fromEntries(filterByType) : undefined;
};

export const getSelectedItemValueStringByType = (selectedItems: ReturnType<typeof useItemSelector>["items"], itemType: string): string => {
  const filterByType = Object.entries(selectedItems).filter(([id, itemProperties]) => itemProperties.type === itemType);
  return filterByType.map(([id, itemProperties]) => itemProperties.value).join("\r\r\n");
};
