import React from "react";
import CodeMirrorMerge from "react-codemirror-merge";
import { EditorView, lineNumbers } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { PanelView } from "screens/panels/components/PanelView";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { bracketMatching } from "@codemirror/language";
import { autocompletion, closeBrackets } from "@codemirror/autocomplete";
import { history } from "@codemirror/commands";
import { lintGutter } from "@codemirror/lint";
import { json } from "@codemirror/lang-json";
import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { useButtonProps } from "hooks";

interface IProps {
  isOpen: boolean;
  source: unknown;
  target: unknown;
  onClose: () => void;
  onSubmit?: () => void;
  isLoading: boolean;
  sourceText?: string;
  targetText?: string;
  footerComponent?: React.ReactNode;
  panelTitle: string;
}

export const CodeMergePanel = ({
  isOpen,
  source,
  target,
  isLoading,
  sourceText,
  targetText,
  footerComponent,
  panelTitle,
  onClose,
  onSubmit,
}: IProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={panelTitle}>
      <Wizard>
        <PanelStep justifyContent={"flex-start"}>
          <CodeMirrorMerge>
            <Stack direction="row" justifyContent={"space-between"}>
              <Text fontWeight="bold" as="span">
                {sourceText ?? "Source"}
              </Text>
              <Text fontWeight="bold" as="span">
                {targetText ?? "Target"}
              </Text>
            </Stack>
            <CodeMirrorMerge.Original
              value={JSON.stringify(source, null, 2)}
              extensions={[
                bracketMatching(),
                closeBrackets(),
                history(),
                autocompletion(),
                lineNumbers(),
                lintGutter(),
                json(),
                EditorView.editable.of(false),
                EditorState.readOnly.of(true),
                EditorView.lineWrapping,
                EditorState.tabSize.of(2),
              ]}
            />
            <CodeMirrorMerge.Modified
              value={JSON.stringify(target, null, 2)}
              extensions={[
                bracketMatching(),
                closeBrackets(),
                history(),
                autocompletion(),
                lineNumbers(),
                lintGutter(),
                json(),
                EditorView.editable.of(false),
                EditorState.readOnly.of(true),
                EditorView.lineWrapping,
                EditorState.tabSize.of(2),
              ]}
            />
          </CodeMirrorMerge>

          {onSubmit && (
            <>
              {footerComponent && <Box>{footerComponent}</Box>}
              <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
                <Button disabled={isLoading} onClick={() => onSubmit()} {...commonButtonProps} type="button">
                  Confirm
                </Button>
              </Box>
            </>
          )}
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
