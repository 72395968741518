import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as navigationActions } from "state/navigation/reducer";
import type { RootState } from "state/rootReducer";

export function useSidebarNavigation() {
  const selectedView = useSelector((state: RootState) => state.navigation.selectedView);
  const defaultView = useSelector((state: RootState) => state.navigation.defaultView);
  const currentSidebarType = useSelector((state: RootState) => state.navigation.setSidebarType);
  const dispatch = useDispatch();

  const setDefaultSidebar = useCallback(
    (view: typeof defaultView) => {
      dispatch(navigationActions.setDefault(view));
    },
    [dispatch]
  );

  const setSelectedSidebar = useCallback(
    (view: typeof defaultView) => {
      dispatch(navigationActions.setSelectedView(view));
    },
    [dispatch]
  );

  const setSidebarType = useCallback(
    (sidebarType: typeof currentSidebarType) => {
      dispatch(navigationActions.setSidebarType(sidebarType));
    },
    [dispatch]
  );

  return { selectedView, setSelectedSidebar, currentSidebarType, setSidebarType, defaultView, setDefaultSidebar };
}
