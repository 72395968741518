import { createAction, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import orderBy from "lodash/orderBy";
import { REHYDRATE } from "redux-persist";
import type { ContentDetails } from "types/content/ContentDetails";
import type { RecentContent } from "types/content/RecentContentDetails";
import { downloadContents, downloadRecentContents } from "./operations";

interface ContentState {
  isLoading: boolean;
  contentData: Record<string, ContentDetails>;
  recentContent: RecentContent;
  order: string[];
}

const initialState: ContentState = {
  isLoading: false,
  contentData: {},
  recentContent: { totalCount: 0, metadataIds: [] },
  order: [],
};

const rehydrate = createAction<ContentState>(REHYDRATE);

export const { actions, reducer } = createSlice({
  name: "content",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
    removeContent(state, action: PayloadAction<string>) {
      const id = action.payload;
      delete state.contentData[id];
      state.order = state.order.filter((contentId) => contentId !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (_, action) => {
      if (action.payload?.recentContent) {
        return {
          contentData: action.payload.contentData ?? {},
          isLoading: false,
          recentContent: action.payload.recentContent,
          order: action.payload.order ?? [],
        };
      }
    });

    builder.addCase(downloadRecentContents.fulfilled, (state, action) => {
      const { payload: recentContents } = action;
      state.recentContent = recentContents;
    });

    builder.addCase(downloadContents.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(downloadContents.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(downloadContents.fulfilled, (state, action) => {
      state.isLoading = false;

      const contentsKeys = Object.keys(action.payload);
      for (let i = 0; i < contentsKeys.length; i++) {
        const key = contentsKeys[i];
        const content = action.payload[key];

        if (!state.contentData[key]) {
          state.order.push(key);
        }

        state.contentData[key] = content;
      }

      state.order = orderBy(state.order, (id) => state.contentData[id].timeLastModified, "desc");
    });
  },
});

export default reducer;
