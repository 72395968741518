import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { AcknowledgmentStatus, Message } from "types/conversation";
import type { Tag } from "types/tags";
import type { NormalizedConversations, NormalizedMessages } from "./operations";
import { downloadTags, downloadConversationById } from "./operations";

const getMessageCompanyTicker = (message: Message | undefined): string | undefined => {
  if (message && message.senderId !== "charli" && message.content) {
    const messageText = message.content.trim();
    const tokens = messageText.split(" ");

    for (let i = 0; i < tokens.length; i++) {
      if (tokens[i] === ">company_ticker") {
        const value = tokens[i + 1] || "";
        if (value) {
          return value;
        }
      }
    }
  }
};

const setConversationsData = (
  { payloadConversations, payloadMessages }: { payloadConversations: NormalizedConversations; payloadMessages: NormalizedMessages },
  state: ConversationState
) => {
  // Merge new conversation and message entries with existing store
  Object.entries(payloadConversations).forEach(([conversationId, conversation]) => {
    const existingMessages = state.conversations[conversationId] ? state.conversations[conversationId].messages : [];

    state.conversations[conversationId] = {
      id: conversationId,
      messages: Array.from(new Set(existingMessages.concat(conversation.messages))),
    };
  });

  Object.entries(payloadMessages).forEach(([messageId, message]) => {
    state.messages[messageId] = message;
  });
};

interface ConversationState {
  conversations: NormalizedConversations;
  messages: NormalizedMessages;
  tags: Tag[];
  isLoading: boolean; // Are we waiting for the history endpoint to return?
  hasInitialSyncCompleted: boolean; // Have we synced with conversation history at any time?
  isLoadingConversationById: Record<string, boolean>;
  conversationTicker: Record<string, string>;
}

const initialState: ConversationState = {
  conversations: {},
  messages: {},
  tags: [],
  isLoading: false,
  hasInitialSyncCompleted: false,
  isLoadingConversationById: {},
  conversationTicker: {},
};

export const { actions, reducer } = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    addMessage(state, action: PayloadAction<Message>) {
      // Normalised conversation
      if (action.payload.conversationId) {
        // if the conversation already exists in state, append the new message to the end of the array
        if (state.conversations[action.payload.conversationId]) {
          state.conversations[action.payload.conversationId].messages.push(action.payload.id);
        }
        // Otherwise, create the normalized conversation structure from scratch
        else {
          state.conversations[action.payload.conversationId] = {
            id: action.payload.conversationId,
            messages: [action.payload.id],
          };

          const maybeMessageCompanyTicker = getMessageCompanyTicker(action.payload);

          if (maybeMessageCompanyTicker) {
            state.conversationTicker[action.payload.conversationId] = maybeMessageCompanyTicker;
          }
        }
        // Normalised message
        state.messages[action.payload.id] = action.payload;

        // Replace parent messages data array on edit only if it contains a CharliUI stack
        if (
          action.payload.parentMessageId &&
          action.payload.data &&
          action.payload.data.some((el) => el.type === "charli-ui") &&
          state.messages[action.payload.parentMessageId]
        ) {
          state.messages[action.payload.parentMessageId].data = action.payload.data;
        }
      }
    },
    flush() {
      return initialState;
    },
    setAcknowledgmentStatus(
      state,
      action: PayloadAction<{
        messageId: string;
        acknowledgmentStatus: AcknowledgmentStatus;
      }>
    ) {
      const maybeMessage = state.messages[action.payload.messageId];

      if (maybeMessage) {
        maybeMessage.acknowledgmentStatus = action.payload.acknowledgmentStatus;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadTags.fulfilled, (state, action) => {
      state.tags = action.payload;
    });
    builder.addCase(downloadConversationById.pending, (state, action) => {
      state.isLoadingConversationById[action.meta.arg.conversationId] = true;
    });
    builder.addCase(downloadConversationById.rejected, (state, action) => {
      state.isLoadingConversationById[action.meta.arg.conversationId] = false;
    });
    builder.addCase(downloadConversationById.fulfilled, (state, action) => {
      setConversationsData(
        {
          payloadConversations: action.payload.conversations || {},
          payloadMessages: action.payload.messages || {},
        },
        state
      );

      if (action.payload.conversations && action.payload.conversations[action.meta.arg.conversationId]?.messages?.length > 0) {
        const firstMessageId = action.payload.conversations[action.meta.arg.conversationId]?.messages[0];
        const firstMessage = firstMessageId ? state.messages[firstMessageId] : undefined;

        const maybeMessageCompanyTicker = getMessageCompanyTicker(firstMessage);

        if (maybeMessageCompanyTicker) {
          state.conversationTicker[action.meta.arg.conversationId] = maybeMessageCompanyTicker;
        }
      }

      state.isLoadingConversationById[action.meta.arg.conversationId] = false;
    });
  },
});

export default reducer;
