import { Record, String } from "runtypes";
import { TaskError } from "api/tasks/models/TaskError";
import type { Static } from "runtypes";
import { TaskLogStatus } from "./TaskLogStatus";

export const TaskLog = Record({
  id: String,
  registeredTaskId: String,
  childWorkflowId: String,
  creationDate: String,
  startDate: String.optional(),
  completionDate: String.optional(),
  status: TaskLogStatus,
  errorPayload: TaskError.optional(),
  intent: String.optional(),
  workflowId: String,
});

export type TaskLog = Static<typeof TaskLog>;
