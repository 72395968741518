import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { TaskResponse } from "api/tasks/models/TaskResponse";
import { downloadTask } from "./operations";

export type NormalizedTasks = Record<string, TaskResponse>;

interface TaskState {
  tasks: NormalizedTasks;
}

const initialState: TaskState = {
  tasks: {},
};

export const { actions, reducer } = createSlice({
  name: "task",
  initialState,
  reducers: {
    flush(state) {
      state.tasks = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadTask.fulfilled, (state, action: PayloadAction<NormalizedTasks>) => {
      state.tasks = Object.assign(state.tasks, action.payload);
    });
  },
});

export default reducer;
