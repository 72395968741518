import { Box, Skeleton, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useMemo, useState, useEffect } from "react";
import { ActivityTile } from "./ActivityTile";
import { useWorkflowFiltered } from "hooks/useWorkflows";
import { sortWorkflows } from "screens/thread/components/cells/workflowSummaryCell/utils";
import { useSelector, shallowEqual } from "react-redux";
import type { RootState } from "state/rootReducer";

const DEFAULT_ACTIVITY_SIZE = 10;
const ACTIVITY_SIZE_INTERVAL = 10;

interface Props {
  noItemsFoundMessage?: string;
  selectedConversation?: string;
  intentFilter?: string | "allPerType" | "";
  states?: string[];
  blacklist?: string[];
  maxActivities?: number;
  hasInfiniteScroll?: boolean;
  showAsList?: boolean;
}

export const ActivityTilesSectionList: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  noItemsFoundMessage,
  selectedConversation,
  intentFilter,
  states,
  blacklist,
  maxActivities,
  showAsList = true,
}) => {
  const [activitiesSize, setActivitiesSize] = useState(maxActivities ?? DEFAULT_ACTIVITY_SIZE);
  const workflows = useWorkflowFiltered(!intentFilter || intentFilter === "allPerType" ? [] : [intentFilter], states, blacklist);
  const sortedWorkflows = useMemo(() => [...workflows].sort(sortWorkflows).reverse(), [workflows]);
  const { isLoading: isWorkflowLoading } = useSelector((state: RootState) => state.workflow, shallowEqual);
  const startColor = useColorModeValue("gray.100", "gray.900");
  const endColor = useColorModeValue("gray.50", "gray.800");

  const workflowsToRender = useMemo(() => sortedWorkflows.slice(0, activitiesSize), [sortedWorkflows, activitiesSize]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    const checkIfProjectsExist = () => {
      setIsPageLoading(false);
    };
    const timer = setTimeout(checkIfProjectsExist, 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const activityListLoader = document.querySelector(".activity-list-loader");
    const options = {
      rootMargin: "0px",
      threshold: 0,
    };

    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActivitiesSize((size) => size + ACTIVITY_SIZE_INTERVAL);
        }
      });
    }, options);

    if (activityListLoader) {
      intersectionObserver.observe(activityListLoader);
    }

    return () => {
      if (activityListLoader) {
        intersectionObserver.unobserve(activityListLoader);
      }

      intersectionObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    setActivitiesSize(maxActivities ?? DEFAULT_ACTIVITY_SIZE);
  }, [intentFilter, maxActivities, states, blacklist]);

  return (
    <Stack>
      {workflows.length === 0 && !isWorkflowLoading && !isPageLoading ? (
        noItemsFoundMessage && (
          <Text fontSize="sm" align="start" color="gray.500" fontWeight="400">
            {noItemsFoundMessage}
          </Text>
        )
      ) : (
        <Stack pt="1.2rem">
          {workflowsToRender.map((tileData, i) => {
            if (tileData.conversationId) {
              return (
                <ActivityTile
                  key={tileData.id}
                  initiatingMessage={tileData.title}
                  conversationId={tileData.conversationId}
                  state={tileData.status}
                  dismissed={tileData.dismissed}
                  intent={tileData.intent.replace("/", "")}
                  lastUpdated={new Date(tileData.creationDate)}
                  selected={selectedConversation === tileData.id}
                  tileLocation="activity_list_drawer"
                  workflowId={tileData.id}
                  showAsList={showAsList}
                />
              );
            } else {
              return <React.Fragment key={`unknown-${i}`} />;
            }
          })}
          {isWorkflowLoading &&
            activitiesSize - workflowsToRender.length > 0 &&
            Array.from({ length: activitiesSize - workflowsToRender.length }).map((_, i) => (
              <Box key={`unknown-${i}`}>
                <Skeleton
                  isLoaded={false}
                  height="3rem"
                  width="100%"
                  borderRadius="md"
                  flex={"1"}
                  fadeDuration={0.5}
                  speed={0.8}
                  startColor={startColor}
                  endColor={endColor}
                />
              </Box>
            ))}
        </Stack>
      )}
    </Stack>
  );
};
