import React from "react";
import { Box, Center, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { useSidebarNavigation, useUserPreference } from "hooks";
import { DEFAULT_SIDEBAR_WIDTH } from "screens/landing/components";

interface IProps {
  message: string;
  height: string;
  width: string;
}

export const ComingSoonOverlay = ({ message, width, height }: IProps) => {
  const fontColor = useColorModeValue("gray.500", "gray.400");
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const { currentSidebarType } = useSidebarNavigation();

  return (
    <>
      <Box position={"absolute"} opacity="0.7" zIndex="5" borderRadius={"md"} width={width} height={height} bgColor="white"></Box>
      <Center
        position={"absolute"}
        pr="2rem"
        zIndex="6"
        borderRadius={"md"}
        width={width}
        height={height}
        bgColor="transparent"
        maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`}>
        <Stack spacing="0" maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`}>
          {message && (
            <Text px="2rem" textAlign={"center"} color={fontColor} fontWeight={"semibold"} fontSize="xl">
              {message}
            </Text>
          )}
        </Stack>
      </Center>
    </>
  );
};
