import React, { useMemo } from "react";
import { Image } from "@chakra-ui/react";
import type { ImageProps } from "@chakra-ui/react";
import charliLogo from "screens/common/static/logos/charli-logo-small.svg";
import gmailLogo from "screens/common/static/logos/gmail-logo.png";
import googleCalendarLogo from "screens/common/static/logos/gcal-icon.svg";
import googleDriveLogo from "screens/common/static/logos/gdrive-icon.svg";
import chromeStoreLogo from "screens/common/static/logos/chrome-store-icon.png";
import jiraLogo from "screens/common/static/logos/jira-logo.png";
import webexLogo from "screens/common/static/logos/webex-logo.png";
import hubspotLogo from "screens/common/static/logos/hubspot-logo.png";
import edgarLogo from "screens/common/static/logos/edgar-sec-logo.png";
import { prependProxyBaseUrl } from "api/request";

interface LinkLogoProps extends ImageProps {
  url: string;
}

const getLogo = (domain: string | undefined) => {
  switch (domain?.toLowerCase()) {
    case "jira.net":
      return jiraLogo;
    case "gmail.com":
      return gmailLogo;
    case "calendar.google.com":
      return googleCalendarLogo;
    case "chromestore.google.com":
      return chromeStoreLogo;
    case "drive.google.com":
      return googleDriveLogo;
    case "webex.com":
      return webexLogo;
    case "hubspot.com":
      return hubspotLogo;
    case "sec.gov":
      return edgarLogo;
    default:
      return undefined;
  }
};

export const LinkLogo: React.FC<React.PropsWithChildren<React.PropsWithChildren<LinkLogoProps>>> = ({ url, ...imageProps }) => {
  const logoUrl = useMemo(() => {
    const sanitizedUrl = url ? (url.startsWith("http") ? url : `http://${url}`) : undefined;
    if (sanitizedUrl) {
      try {
        const params = new URLSearchParams({ url: sanitizedUrl }).toString();
        return prependProxyBaseUrl(`/api/web-scraping/logo?${params}`);
      } catch (err) {
        console.warn(`Unable to parse "${sanitizedUrl}" as a URL, no logo will be rendered as a result`);
      }
    }
  }, [url]);

  const logoSrc = url === "charli.ai" ? charliLogo : getLogo(url) || logoUrl || charliLogo;

  return <Image className="link-logo" src={logoSrc} zIndex={logoUrl ? "2" : undefined} fallback={<React.Fragment />} {...imageProps} />;
};
