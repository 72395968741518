import { Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useState } from "react";
import React from "react";
import type { ContentDetails } from "types/content/ContentDetails";
import { Highlights } from "screens/panels/highlights/Highlights";
import { BlockSectionHeader } from "./BlockSectionHeader";
import { useCollectionKey, useContentDetails, useProjectParams } from "hooks";
import { BiCopy } from "react-icons/bi";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { HighlightsButtonMenuItem } from "screens/panels/highlights/HighlightsButtonMenuItem";

interface Props {
  itemContent: ContentDetails;
  hasHighlightsFunction: boolean;
  hideTitle?: boolean;
  bgColor?: string;
}

export const ContentHighlightData: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  itemContent,
  hasHighlightsFunction = false,
  hideTitle = false,
  bgColor = "transparent",
}) => {
  const { projectId, contentId } = useProjectParams();
  const collectionExtractedHighlights = useCollectionKey(projectId, "extractedHighlights");
  const collectionConversationId = useCollectionKey(projectId, "conversationId");
  const getContentDetails = useContentDetails(contentId ? contentId : projectId);
  const allHighlights = getContentDetails?.extractedHighlights || collectionExtractedHighlights;
  const hasHighlights = allHighlights && allHighlights.length > 0;
  const [isCopied, setIsCopied] = useState(false);

  const topicCopied = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const getAllHighlights = () => {
    const highlights = allHighlights
      ?.map((topics) => {
        let topicSection = topics.highlights.length ? `Topic: ${topics.topic} \r\n` : "";
        topics.highlights.map((highlights) => (topicSection += highlights.highlight + "\r\n"));
        return topicSection + "\r\n";
      })
      .join("");
    topicCopied();
    highlights && navigator.clipboard.writeText(highlights);
  };

  return (
    <>
      {hasHighlightsFunction && (
        <Box backgroundColor={bgColor}>
          {!hideTitle && (
            <BlockSectionHeader title={"AI Generated Highlights"} direction="row">
              {hasHighlights && (
                <SmallActionButton
                  classNames="ch-copy-highlights"
                  iconTypeName={BiCopy}
                  onClick={getAllHighlights}
                  tooltip={isCopied ? "Copied to Clipboard" : "Copy Highlights"}
                />
              )}
            </BlockSectionHeader>
          )}
          {itemContent?.extractedHighlights.length > 0 && collectionConversationId ? (
            <Highlights extractedHighlights={itemContent?.extractedHighlights} showCopyButton showActionMenu={false} textOnlyView />
          ) : (
            <HighlightsButtonMenuItem showAsButton />
          )}
        </Box>
      )}
    </>
  );
};
