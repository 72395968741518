import { Record, String, Array, Number } from "runtypes";
import { ExternalLink } from "./ExternalLink";
import type { Static } from "runtypes";

export const NewsInformationResponse = Record({
  status: String,
  message: String,
  external_links: Array(ExternalLink),
  number_of_links: Number,
});

export type NewsInformationResponse = Static<typeof NewsInformationResponse>;
