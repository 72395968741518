import { prependProxyBaseUrl, request } from "api/request";
import {
  GetEntityConfigurationResponse,
  GetEntityConfigurationsResponse,
  CreateEntityConfigurationResponse,
  UpdateEntityConfigurationResponse,
} from "./models/EntityConfigurationApi";
import type { CreateEntityConfigurationRequest, UpdateEntityConfigurationRequest } from "./models/EntityConfigurationApi";

const ENTITY_CONFIGURATIONS_ENDPOINT = prependProxyBaseUrl("/api/entity-configurations");

export const getAllEntityConfigurations = async (): Promise<GetEntityConfigurationsResponse> => {
  const response = await request().url(`${ENTITY_CONFIGURATIONS_ENDPOINT}`).get().json();
  const maybeEntityConfigurations = GetEntityConfigurationsResponse.validate(response);

  if (maybeEntityConfigurations.success) {
    return maybeEntityConfigurations.value;
  } else {
    console.error(maybeEntityConfigurations);
    throw new Error(maybeEntityConfigurations.message);
  }
};

export const getEntityConfiguration = async (entityName: string): Promise<GetEntityConfigurationResponse> => {
  const response = await request()
    .url(`${ENTITY_CONFIGURATIONS_ENDPOINT}/${entityName}`)
    .query({ includeUser: true, caching: false })
    .get()
    .json();
  const maybeEntityConfiguration = GetEntityConfigurationResponse.validate(response);

  if (maybeEntityConfiguration.success) {
    return maybeEntityConfiguration.value;
  } else {
    console.error(maybeEntityConfiguration);
    throw new Error(maybeEntityConfiguration.message);
  }
};

export const createEntityConfiguration = async (payload: CreateEntityConfigurationRequest): Promise<CreateEntityConfigurationResponse> => {
  const response = await request().url(`${ENTITY_CONFIGURATIONS_ENDPOINT}`).query({ includeUser: true }).post(payload).json();
  const maybeEntityConfiguration = CreateEntityConfigurationResponse.validate(response);

  if (maybeEntityConfiguration.success) {
    return maybeEntityConfiguration.value;
  } else {
    console.error(maybeEntityConfiguration);
    throw new Error(maybeEntityConfiguration.message);
  }
};

export const updateEntityConfiguration = async (
  entityName: string,
  payload: UpdateEntityConfigurationRequest
): Promise<UpdateEntityConfigurationResponse> => {
  const response = await request().url(`${ENTITY_CONFIGURATIONS_ENDPOINT}/${entityName}`).query({ includeUser: true }).put(payload).json();
  const maybeEntityConfiguration = UpdateEntityConfigurationResponse.validate(response);

  if (maybeEntityConfiguration.success) {
    return maybeEntityConfiguration.value;
  } else {
    console.error(maybeEntityConfiguration);
    throw new Error(maybeEntityConfiguration.message);
  }
};

export const deleteEntityConfiguration = async (entityId: string): Promise<void> => {
  await request().url(`${ENTITY_CONFIGURATIONS_ENDPOINT}/${entityId}`).delete().res();
};
