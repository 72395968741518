import type { FunctionComponent } from "react";
import { useMemo } from "react";
import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import {
  useGroupWorkflowByIntent,
  useSumAllValuesByLabel,
  useWorkflowByUser,
  useWorkflowStatesByDay,
  useWorkflowTotalDuration,
} from "screens/landing/tabs/operations/tabs/useWorkflowStats";
import type { Workflow } from "types/workflows/workflow";
import { WorkflowTaskStatus } from "types/workflows/workflow";
import { useConfigMap, useMenuConfig } from "hooks";
import { CustomBarChart } from "screens/landing/tabs/collections/chartComponents/CustomBarChart";
import { CustomPieChart } from "screens/landing/tabs/collections/chartComponents/CustomPieChart.";
import { CustomSimpleMetric } from "screens/landing/tabs/collections/chartComponents/CustomSimpleMetric";
import { CustomAreaChart } from "../../collections/chartComponents/CustomAreaChart";
import { CustomStackedBarChart } from "../../collections/chartComponents/CustomStackedBarChart";
import { CustomLineAndBarChart } from "../../collections/chartComponents/CustomLineAndBarChart";

interface Props {
  filteredWorkflows: Workflow[];
}

export const WorkflowOperationsCharts: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  filteredWorkflows,
}) => {
  const filteredWorkflowIds = useMemo(() => filteredWorkflows.map((workflow) => workflow.id), [filteredWorkflows]);
  const configMap = useConfigMap();
  const projectConfig = useMenuConfig(configMap, undefined, true);
  const projectIntents = projectConfig.map((item) => item.config.intent || "");

  const filteredWorkflowsByProject = useMemo(
    () => filteredWorkflows.filter((workflow) => projectIntents.includes(workflow.intent || "")),
    [filteredWorkflows, projectIntents]
  );

  const statusChartData = useWorkflowStatesByDay({
    workflows: filteredWorkflows,
  });
  const statusChartDataTotals = useSumAllValuesByLabel(statusChartData);

  const userChartData = useWorkflowByUser({
    workflows: filteredWorkflows,
  });
  const userChartDataTotals = useSumAllValuesByLabel(userChartData);

  const metricsData = useWorkflowStatesByDay({
    workflows: filteredWorkflows,
    statesToFilter: [WorkflowTaskStatus.inProgress, WorkflowTaskStatus.failed],
  });
  const metricsDataTotals = useSumAllValuesByLabel(metricsData);

  const executionRangeData = useWorkflowTotalDuration({
    workflows: filteredWorkflows,
    shouldReturnRangeData: true,
  });
  const executionRangeDataTotals = useSumAllValuesByLabel(executionRangeData);

  const labelOrder = ["0-5", "5-10", "10+"];
  executionRangeDataTotals.sort((a, b) => {
    const indexA = labelOrder.indexOf(a.label);
    const indexB = labelOrder.indexOf(b.label);

    if (indexA < indexB) {
      return -1;
    }
    if (indexA > indexB) {
      return 1;
    }
    return 0;
  });

  const progressChartData = useWorkflowStatesByDay({
    workflows: filteredWorkflowsByProject,
    statesToFilter: [WorkflowTaskStatus.inProgress, WorkflowTaskStatus.clarificationNeeded],
  });

  const projectIntentChartData = useGroupWorkflowByIntent({
    workflows: filteredWorkflowsByProject,
    statesToFilter: [WorkflowTaskStatus.complete],
  });

  return (
    <Stack justifyContent="center" align="center" pb="4rem" pt=".5rem" width="100%" spacing="2rem">
      <Stack direction="row" spacing="5rem" justifyContent="center" width="100%">
        <CustomPieChart
          chartId="pie-chart-status"
          data={statusChartDataTotals.filter((item) => item.value !== 0)}
          width="10rem"
          title={`Operations by Status`}
          selectedWorkflowIds={filteredWorkflowIds}
        />
        <CustomBarChart
          chartId="bar-chart-execution-range"
          data={executionRangeDataTotals}
          width="17rem"
          title={`Operations by Execution ranges`}
          selectedWorkflowIds={filteredWorkflowIds}
        />
        <CustomBarChart
          chartId="bar-chart-operations-by-user"
          data={userChartDataTotals}
          width="30rem"
          title={`Operations by User`}
          showStateTitle
          showXAxis={false}
        />
        <Stack height="13rem" justifyContent="space-between" pl="2rem" alignContent={"center"}>
          <CustomSimpleMetric data={metricsDataTotals[0]} />
          <CustomSimpleMetric data={metricsDataTotals[1]} />
          <Text mt="1.6rem!important" fontSize={"xs"} align={"center"}>
            Running Operations
          </Text>
        </Stack>
      </Stack>

      <Stack direction="row" spacing="5rem" justifyContent="center" width="100%">
        <CustomAreaChart chartId="bar-chart-projects-progress" data={progressChartData} width="30%" title={`Projects Executing`} />
        <CustomStackedBarChart
          chartId="bar-chart-projects-by-date"
          data={projectIntentChartData}
          width="30%"
          title={`Projects Completed`}
        />
        <CustomLineAndBarChart
          chartId="bar-chart-projects-by-date"
          data={projectIntentChartData}
          width="30%"
          title={`Historical Spend and ROI`}
        />
      </Stack>
    </Stack>
  );
};
