import { Record, String, Literal, Union, InstanceOf, Unknown, Dictionary, Array } from "runtypes";
import type { Static } from "runtypes";

export const EventType = Union(Literal("update"), Literal("create"), Literal("delete"), Literal("invite"));
export const TableName = Union(Literal("organization"), Literal("organization_member"), Literal("organization_invites"));

export const OrganizationLog = Record({
  id: String,
  tableName: TableName,
  tableId: String,
  tableRecordName: String,
  eventType: EventType,
  modifiedAt: Union(String, InstanceOf(Date)),
  modifiedByUserId: String,
  modifiedByUserIdName: String,
  eventObject: Dictionary(Unknown, String).optional(),
  objectChanges: Dictionary(Record({ old: Unknown, new: Unknown }), String).optional(),
});

export const GetOrganizationLog = Array(OrganizationLog);

export type OrganizationLog = Static<typeof OrganizationLog>;
export type GetOrganizationLog = Static<typeof GetOrganizationLog>;
export type EventType = Static<typeof EventType>;
export type TableName = Static<typeof TableName>;
