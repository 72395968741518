import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { ItemIdentifier } from "types/items";

export type SupportedItemKeys = ItemIdentifier["type"];

interface SelectionState {
  isSharingEnabled: boolean;
  items: { [id: string]: { type: SupportedItemKeys; value?: string | undefined } }; // One or more items ready for action (share, archive etc)
  selectedItems: { [id: string]: { type: SupportedItemKeys; value?: string | undefined } }; // One or more items ready for action (share, archive etc)
  availableItems: { [id: string]: { type: SupportedItemKeys } }; // The pool of available items to action
  focusedItem?: { id: string; type: SupportedItemKeys; extension?: string | undefined }; // Single item currently being viewed
}

const initialState: SelectionState = {
  isSharingEnabled: false,
  items: {},
  selectedItems: {},
  availableItems: {},
};

export const { actions, reducer } = createSlice({
  name: "selection",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
    addItem(state, action: PayloadAction<{ id: string; type: SupportedItemKeys; value?: string }>) {
      state.items[action.payload.id] = { type: action.payload.type, value: action.payload.value };
    },
    removeItem(state, action: PayloadAction<{ id: string }>) {
      delete state.items[action.payload.id];
    },
    setItems(state, action: PayloadAction<{ [id: string]: { type: SupportedItemKeys } }>) {
      state.items = action.payload;
      state.availableItems = action.payload;
      state.isSharingEnabled = Object.keys(action.payload).length > 0;
    },
    deselectAll(state) {
      state.items = {};
    },
    resetItems(state) {
      state.items = {};
      state.availableItems = {};
      state.isSharingEnabled = false;
    },
    addAllToSelectedItems(state, action: PayloadAction<{ [id: string]: { type: SupportedItemKeys } }>) {
      state.availableItems = action.payload;
      state.selectedItems = action.payload;
    },
    addSelectedItem(state, action: PayloadAction<{ id: string; type: SupportedItemKeys; value?: string }>) {
      state.selectedItems[action.payload.id] = { type: action.payload.type, value: action.payload.value };
    },
    removeSelectedItem(state, action: PayloadAction<{ id: string }>) {
      delete state.selectedItems[action.payload.id];
    },
    resetSelectedItems(state) {
      state.selectedItems = {};
    },
    setFocusedItem(state, action: PayloadAction<{ id: string; type: SupportedItemKeys; extension?: string }>) {
      state.focusedItem = action.payload;
    },
    setSharingEnabled(state, action: PayloadAction<{ state: boolean }>) {
      state.isSharingEnabled = action.payload.state;
    },
    clearFocusedItem(state) {
      delete state.focusedItem;
    },
  },
});

export default reducer;
