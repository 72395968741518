import React, { useContext } from "react";
import { Wizard } from "react-use-wizard";
import { ConversationContext } from "screens/thread/ConversationContext";
import { PanelStep } from "../components/PanelStep";
import { PanelView } from "../components/PanelView";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { toTitleCase } from "screens/common/app";
import { useContentDetails } from "hooks";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import DOMPurify from "dompurify";
import marked from "marked";
import { SummarizeButton } from "./SummarizeButton";

export const SummarizePanel = () => {
  const { pathname } = useLocation();
  const isResource = pathname.includes("resource");
  const route = pathname.split("/")[1];

  const { isSummarizePanelOpen, onSummarizePanelClose } = useAddToCharliContext();
  const { contentId } = useContext(ConversationContext);
  const itemContent = useContentDetails(contentId);
  const parsedMarkdown = marked(itemContent?.summaryDetails?.data[0].value || "");
  const purifiedHtml = DOMPurify.sanitize(parsedMarkdown);

  return (
    <PanelView
      isOpen={isSummarizePanelOpen}
      onClose={onSummarizePanelClose}
      panelTitle={`${toTitleCase(route === "canvas" || isResource ? "resource" : route)} Summary`}>
      <Wizard>
        <PanelStep>
          <SummarizeButton />
          <Box
            height="100%"
            dangerouslySetInnerHTML={{ __html: purifiedHtml.replace(/<br>/g, "<p>") }}
            css={{
              fontSize: "0.875rem",
              blockquote: {
                background: "#f9f9f9",
                marginTop: "1.25rem !important",
                marginBottom: "1.25rem !important",
              },
              p: {
                display: "block",
                textAlign: "left",
                marginBottom: "20px",
              },
              "blockquote p": {
                display: "inline",
                color: "#555555",
                fontStyle: "italic",
              },
            }}></Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
