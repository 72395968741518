import type { FunctionComponent, CSSProperties } from "react";
import React, { useEffect, useCallback, useRef, useState, useMemo } from "react";
import { Text, Tooltip } from "@chakra-ui/react";
import Highlighter from "react-highlight-words";

interface Props {
  label: string;
  tooltip?: string;
  hideTooltip?: boolean;
  noOfLines?: number;
  className?: string;
  color?: string;
  fontSize?: string;
  style?: CSSProperties;
  onClick?: () => void;
  onMouseEnter?: () => void;
  searchText?: string;
  highlightBackground?: boolean;
}

export const TextOverflowTooltip: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  label,
  tooltip,
  hideTooltip,
  noOfLines = 1,
  className,
  color = "primary.darkGray",
  fontSize = "sm",
  style,
  onClick,
  onMouseEnter,
  searchText,
  highlightBackground = true,
}) => {
  const textElementRef = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  const compareSize = useCallback(() => {
    const element = textElementRef.current;
    if (element) {
      const doesCompare = element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight;
      setIsOverflown(doesCompare);
    } else {
      setIsOverflown(false);
    }
  }, []);

  useEffect(() => {
    const element = textElementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver(() => {
      compareSize();
    });

    resizeObserver.observe(element);

    // Initial check
    compareSize();

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [compareSize]);

  const tooltipOrLabel = useMemo(() => {
    return tooltip
      ? tooltip.split(".").map((item, index, arr) => (
          <React.Fragment key={index}>
            {item}
            {index < arr.length - 1 && (
              <>
                <br />
                <br />
              </>
            )}{" "}
            {/* Don't add <br /> after the last item */}
          </React.Fragment>
        ))
      : label;
  }, [label, tooltip]);

  return (
    <Tooltip label={hideTooltip ? "" : tooltipOrLabel} isDisabled={!isOverflown} placement="top">
      <Text
        className={className}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        color={color}
        fontSize={fontSize}
        as="span"
        textOverflow="ellipsis"
        wordBreak="break-word"
        whiteSpace="normal"
        overflow="hidden"
        noOfLines={noOfLines}
        style={style}
        ref={textElementRef}>
        {searchText && searchText.length > 0 && label ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: `${highlightBackground ? "#ffff00" : "transparent"}`,
              fontWeight: highlightBackground ? "normal" : "bold",
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={label}
          />
        ) : (
          label
        )}
      </Text>
    </Tooltip>
  );
};
