// Session lifecycle
export const EMAIL_WAS_VERIFIED = "Email Was Verified";
export const USER_LOGGED_IN = "User Logged In";
export const USER_LOGGED_OUT = "User Logged Out";
export const GOOGLE_SIGN_UP_BUTTON_CLICKED = "Google Sign Up Button Clicked";
export const GOOGLE_SIGN_IN_BUTTON_CLICKED = "Google Sign In Button Clicked";
export const GOOGLE_SIGN_IN_FAILED = "Google Sign In Failed";
export const GOOGLE_SIGN_IN_SUCCEEDED = "Google Sign In Succeeded";
export const GOOGLE_DRIVE_AUTHORIZE_BUTTON_CLICKED = "Google Drive Authorize Button Clicked";
export const GOOGLE_CALENDAR_AUTHORIZE_BUTTON_CLICKED = "Google Calendar Authorize Button Clicked";
export const WEBSOCKET_DISCONNECTED = "Websocket Disconnected for more then a minute";

// Onboarding
export const ONBOARDING_OPENED = "Onboarding Modal Opened";
export const ONBOARDING_TICKER_SENT = "Onboarding Step 1 Ticker Submitted";
export const ONBOARDING_CLOSED = "Onboarding Closed";
export const ONBOARDING_VIDEO_PLAYED = "Onboarding Video Played";

// User Upgrade and Onboarding lifecycle
export const USER_UPGRADE_PLAN_CLICKED = "Upgrade Plan button Clicked";
export const USER_UPGRADE_PLAN_CONTACT_US = "Upgrade Plan Contact Us Button Clicked";
export const USER_UPGRADE_PLAN_SELF_SERVE = "Upgrade Plan Self Serve Button Clicked";
export const USER_UPGRADE_PLAN_CONTINUE = "Continue with Upgrade Clicked";
export const USER_UPGRADE_PLAN_CONFIRM = "Confirm Upgrade Clicked";

// Actions
export const FILE_UPLOADED = "File Uploaded";
export const FILE_UPLOAD_FAILED = "File Upload Failed";
export const FILE_ADD_FAILED = "File Add Failed";
export const ACTIVITY_OPENED = "Activity Opened";
export const USER_TICKER_PRIORTY = "User Requested Ticker Prioritization";

// Views
export const TABLE_VIEW_OPENED = "Table View Opened";
export const MARKDOWN_VIEW_OPENED = "Markdown View Opened";
export const FILE_VIEW_OPENED = "File View Opened";
export const EXPENSE_VIEW_OPENED = "Expense View Opened";
export const LINK_VIEW_OPENED = "Link View Opened";

// Buttons
export const UPLOAD_STUFF_CLICKED = "Upload Stuff Clicked";
export const FIND_STUFF_CLICKED = "Find Stuff Clicked";
export const ORGANIZE_STUFF_CLICKED = "Organize Stuff Clicked";
export const ANALYZE_CLICKED = "Analyze Clicked";
export const LINK_STUFF_CLICKED = "Link Stuff Clicked";
export const COMMAND_LINE_CLICKED = "Command Line Clicked";
export const MODAL_CLOSED = "Canvas Close Button Clicked";
export const FLOATING_WINDOW_CLOSE_BUTTON_CLICKED = "Floating Window Close Button Clicked";
export const DOCUMENT_VIEW_BACK_BUTTON_CLICKED = "Document View Back Button Clicked";
export const CONVERSATION_CLOSE_BUTTON_CLICKED = "Conversation Close Button Clicked";
export const SUGGESTED_TAG_CLICKED = "Suggested Tag Clicked";
export const ADD_TAG_CLICKED = "Add Tag Clicked";
export const ADD_NOTE_CLICKED = "Add Note Clicked";
export const ORGANIZE_SELECTED_FILES_CLICKED = "Organize Selected Files Clicked";
export const IGNORE_SELECTED_FILES_CLICKED = "Ignore Selected Files Clicked";
export const SELECT_ALL_FILES_CLICKED = "Select All Files Clicked";

// Menu buttons
export const SIDEBAR_HOME_BUTTON_CLICKED = "Sidebar Home Button Clicked";
export const SIDEBAR_COLLECTIONS_BUTTON_CLICKED = "Sidebar Collections Button Clicked";
export const SIDEBAR_MEETINGS_BUTTON_CLICKED = "Sidebar Meetings Button Clicked";
export const SIDEBAR_LIBRARY_BUTTON_CLICKED = "Sidebar Collections Button Clicked";
export const SIDEBAR_ACTIVITIES_BUTTON_CLICKED = "Sidebar Activities Button Clicked";
export const SIDEBAR_NOTIFICATIONS_BUTTON_CLICKED = "Sidebar Notifications Button Clicked";
export const SIDEBAR_HELP_BUTTON_CLICKED = "Sidebar Help Button Clicked";
export const SIDEBAR_SETTINGS_BUTTON_CLICKED = "Sidebar Settings Button Clicked";
export const SIDEBAR_MEMORANDUM_BUTTON_CLICKED = "Sidebar Memorandum Button Clicked";
export const SIDEBAR_INTEGRATIONS_BUTTON_CLICKED = "Sidebar Integrations Button Clicked";
export const SIDEBAR_ADMIN_BUTTON_CLICKED = "Sidebar Admin Button Clicked";
export const SIDEBAR_ORGANIZATION_BUTTON_CLICKED = "Sidebar Organization Button Clicked";
export const SETTINGS_PROFILE_BUTTON_CLICKED = "Settings Profile Button Clicked";
export const SETTINGS_CONFIG_BUTTON_CLICKED = "Settings Config Button Clicked";
export const SETTINGS_INTEGRATIONS_BUTTON_CLICKED = "Settings Integrations Button Clicked";
export const SETTINGS_THEMES_BUTTON_CLICKED = "Settings Themes Button Clicked";
export const SETTINGS_SUBSCRIPTION_BUTTON_CLICKED = "Settings Subscription Button Clicked";
export const SETTINGS_ENTITLEMENT_BUTTON_CLICKED = "Settings Entitlement Button Clicked";
export const SETTINGS_CHROME_EXTENSION_BUTTON_CLICKED = "Settings Chrome Extension Button Clicked";
export const SETTINGS_GMAIL_EXTENSION_BUTTON_CLICKED = "Settings Gmail Extension Button Clicked";
export const SETTINGS_SLACK_EXTENSION_BUTTON_CLICKED = "Settings Slack Extension Button Clicked";
export const SETTINGS_ABOUT_CHARLI_BUTTON_CLICKED = "Settings Help Button Clicked";
export const SETTINGS_HELP_CHARLI_BUTTON_CLICKED = "Settings About Charli Extension Button Clicked";
export const DARK_MODE_BUTTON_CLICKED = "Dark Mode Button Clicked";
export const LIGHT_MODE_BUTTON_CLICKED = "Light Mode Button Clicked";
export const STAY_ORGANIZED_IN_APP_NOTIFICATION_TOGGLED = "Stay Organized In App Notification Toggled";
export const STAY_ORGANIZED_EMAIL_NOTIFICATION_TOGGLED = "Stay Organized Email Notification Toggled";
export const SETTINGS_SHOW_COMMAND_LINE_SETTING_TOGGLED = "Settings Show Command Line Toggled";
export const SETTINGS_INTEGRATIONS_TAB_BUTTON_CLICKED = "Settings Integrations Tab Button Clicked";

// Help Menu
export const SHARE_IDEA_CLICKED = "Share Idea Clicked";
export const RAISE_ISSUE_CLICKED = "Raise Issue Clicked";
export const FAQ_CLICKED = "FAQ Clicked";
export const NEED_HELP_ADDING_CONTENT_CLICKED = "Need Help Adding Content Clicked";
export const CHARLI_CHANGES_VIDEO_CLICKED = "Charli Changes Video Clicked";

// Canvas
export const OPEN_BUTTON_CLICKED = "Open Button Clicked";
export const REMOVE_ITEM_BUTTON_CLICKED = "Remove Item Button Clicked";
export const DOWNLOAD_BUTTON_CLICKED = "Download Button Clicked";
export const CANVAS_SHARE_BUTTON_CLICKED = "Canvas Share Button Clicked";
export const CANVAS_ARCHIVE_BUTTON_CLICKED = "Canvas Archive Button Clicked";
export const CANVAS_COMMAND_BUTTON_CLICKED = "Canvas Command Button Clicked";
export const CANVAS_SHARE_SUBMITTED = "Canvas Share Submitted";
export const COPY_LINK_BUTTON_CLICKED = "Copy Button Clicked";
export const SUMMARIZE_CLICKED = "Summarize Button Clicked";
export const HIGHLIGHT_CLICKED = "Highlight Button Clicked";
export const REPORT_CLICKED = "Collection Report Button Clicked";

// Conversation
export const SUGGESTED_COMMAND_CLICKED = "Suggested Command Clicked";
export const SUGGESTED_COMMAND_ENTITY_CLICKED = "Suggested Command Entity Clicked";

// Share Invites
export const SHARE_INVITE_ACCEPTED = "Share Invite Accepted";

// Notifications
export const NOTIFICATION_CLICKED = "Notification Clicked";
export const NOTIFICATION_DISMISSED = "Notification Dismissed";

// URL Listeners
export const SLACK_ACCOUNT_LINKED = "Slack Account Linked";
export const STAY_ORGANIZED_EMAIL_LINK_CLICKED = "Stay Organized Email Link Clicked";
export const SETTINGS_DEEP_LINK_CLICKED = "Settings Deep Link Clicked";
export const PLAN_LINK_CLICKED = "Subscribe Link Clicked";

// Organization Invites
export const ORGANIZATION_INVITE_ACCEPTED = "Organization Invite Accepted";
