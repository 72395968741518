import React, { useContext } from "react";
import type { FunctionComponent } from "react";
import { MenuItem, useDisclosure } from "@chakra-ui/react";
import { FiDownload } from "react-icons/fi";
import { sendMessage } from "state/websocket/operations";
import { useDispatch } from "react-redux";
import { ConversationContext } from "screens/thread/ConversationContext";
import { AiOutlineDelete } from "react-icons/ai";
import { useCollectionKey, useProjectParams } from "hooks";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { request } from "api/request";
import { IntegrationURN } from "types/integration";
import { ThumbsUpDown } from "screens/common/components/ThumbsUpDown";
import { VerifyDeleteModal } from "screens/content/contentView/VerifyDeleteModal";

interface Props {
  openLink?: string;
  openTooltip?: string;
  downloadLink?: string | null;
  downloadTooltip?: string;
  itemId?: string;
  parentCellType: string;
  allowDelete?: boolean;
  fileName?: string;
  integrationUrn?: string;
}

export const ContentCellFooterMenu: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  openLink,
  openTooltip = "Open in new tab",
  downloadLink,
  downloadTooltip = "Download file",
  itemId,
  parentCellType,
  allowDelete = true,
  fileName,
  integrationUrn,
}) => {
  const dispatch = useDispatch();
  const { projectId } = useProjectParams();
  const { conversationId } = useContext(ConversationContext);
  const collectionConversationId = useCollectionKey(projectId, "conversationId");
  const currentConversationId = collectionConversationId || conversationId;
  const { isOpen: isOpenConfirmDelete, onOpen: onOpenConfirmDelete, onClose: onCloseConfirmDelete } = useDisclosure();

  const onDeleteCollectionItem = (reason?: string) => {
    if (!projectId || !itemId) {
      return;
    }
    const parentCellTypeEntityName = parentCellType + "_id";

    dispatch(
      sendMessage({
        conversationId: currentConversationId,
        intent: "/remove_collection_content",
        entities: [
          { entity: parentCellTypeEntityName, value: itemId },
          { entity: "collection_id", value: projectId },
          { entity: "content_delete_reason", value: reason },
        ],
        datum: [
          {
            type: "update_data_callback",
            includeInResponse: true,
            body: { items: [{ type: "collection", id: projectId }] },
          },
        ],
      })
    );
  };

  return (
    <>
      <ThumbsUpDown isMenuItem />
      {downloadLink && (
        <MenuItem>
          <SmallActionButton
            iconTypeName={FiDownload}
            onClick={() => {
              if (integrationUrn === IntegrationURN.charliCloudStorage) {
                request()
                  .url(downloadLink)
                  .get()
                  .blob((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName || "file");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  })

                  .catch((error) => {
                    console.error(error);
                  });
              } else {
                window.open(downloadLink, "_blank");
              }
            }}
            tooltip={downloadTooltip}
          />
        </MenuItem>
      )}
      {projectId && allowDelete && (
        <MenuItem>
          <SmallActionButton
            classNames="ch-delete-record"
            iconTypeName={AiOutlineDelete}
            onClick={() => onOpenConfirmDelete()}
            tooltip={"Remove item"}
            iconSize="1.3rem"
          />
        </MenuItem>
      )}
      <VerifyDeleteModal
        title={`Remove ${fileName ? fileName : "item"}`}
        message={`Please confirm that you would like to remove ${
          fileName ? fileName : "this"
        } item and select one of the reasons below. This can not be undone.`}
        onHandleConfirmDelete={(reason: string) => onDeleteCollectionItem(reason)}
        onClose={onCloseConfirmDelete}
        isOpen={isOpenConfirmDelete}
      />
    </>
  );
};
