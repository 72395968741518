import { useColorModeValue } from "@chakra-ui/react";
import uniq from "lodash/uniq";
import { useMemo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { Tag } from "types/tags";
import type { ReactSelectValues } from "types/tags";

type TagHookParams = {
  normalizeTags?: boolean;
  hideDefaultTags?: boolean;
  tags?: string[];
  setTags?: (tags: string[]) => void;
  tagsState?: [string[], (tags: string[]) => void];
};

/**
 * Removes whitespace and leading # if present. Intended to be used inside an Array.flatMap()
 */
const normalizeTag = (value: Tag) => {
  const normalizedValue = value.name.replace(/#/g, "").trim();

  return normalizedValue.length > 0 ? [normalizedValue] : [];
};

/**
 * Converts a string array into a list of select options for use in a react-select component
 */
const tagsToSelectValues = (tags: string[]): NonNullable<ReactSelectValues> => {
  return tags.map((tag) => {
    return { label: tag, value: tag };
  });
};

/**
 * A hook which returns the list of available tags, with utility functions for handling stateful tag inputs.
 *
 * @param {{ hideDefaultTags?: boolean }} - If true, hides "default" tags intended for new users that don't have any tags of their own, eg. #personal, #business, etc. See DEFAULT_TAGS in `src/api/tags.ts`.
 * @param {{ tagsState?: TagHookParams }} - If set, this hook will use the provided state variable and setter in place of its own internal state. This is used if you're using `useTags` with a controlled input.
 */
export function useTags({ hideDefaultTags, tags: tagsProps, setTags, normalizeTags }: TagHookParams = {}) {
  const unfilteredTags = useSelector((state: RootState) => state.conversation.tags);

  // Internal selection state
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_selectedTags, _setSelectedTags] = useState<string[]>([]);

  // Public selection state getters and setters
  const selectedTags = useMemo(() => {
    return tagsProps ? uniq(tagsProps) : uniq(_selectedTags);
  }, [_selectedTags, tagsProps]);

  const setSelectedTags = useCallback(
    (values: string[]) => {
      const normalizedValues = values.map((value) => value.trim().replace(/#/g, ""));

      if (setTags) {
        setTags(normalizeTags ? normalizedValues : values);
      } else {
        _setSelectedTags(normalizeTags ? normalizedValues : values);
      }
    },
    [normalizeTags, setTags]
  );

  // All tags, optionally excluding default tags
  const tags = useMemo(() => {
    if (hideDefaultTags) {
      return unfilteredTags.flatMap((tag) => {
        if (tag.origin === "user") {
          return normalizeTag(tag);
        } else {
          return [];
        }
      });
    } else {
      return unfilteredTags.flatMap((tag) => normalizeTag(tag));
    }
  }, [unfilteredTags, hideDefaultTags]);

  // Tags represented as valid react-select options
  const tagsAsSelectValues: ReactSelectValues = useMemo(() => {
    return tagsToSelectValues(tags);
  }, [tags]);

  // Selected tags represented as valid react-select options
  const selectedTagsToSelectValues: ReactSelectValues = useMemo(() => {
    return tagsToSelectValues(selectedTags);
  }, [selectedTags]);

  // Set tags state from a react-select
  const setTagsFromSelectValues = useCallback(
    (values: ReactSelectValues) => {
      if (values) {
        setSelectedTags(values.map((values) => values.value));
      }
    },
    [setSelectedTags]
  );

  const setTagFromTagsComponent = useCallback(
    (tag: { id: string; text: string }) => {
      const normalizedValue = tag.id.trim().replace(/#/g, "");
      if (setTags && tagsProps) {
        setTags([...tagsProps, ...(normalizeTags ? [normalizedValue] : [tag.id])]);
      } else {
        _setSelectedTags((prev) => [...prev, ...(normalizeTags ? [normalizedValue] : [tag.id])]);
      }
    },
    [normalizeTags, setTags, tagsProps]
  );

  const deleteTagFromTagsComponent = useCallback(
    (index: any) => {
      if (setTags && tagsProps) {
        setTags(tagsProps.filter((_, i) => i !== index));
      } else {
        _setSelectedTags((prev) => prev.filter((_, i) => i !== index));
      }
    },
    [setTags, tagsProps]
  );

  const dragTagFromTagsComponent = useCallback(
    (tag: { id: string; text: string }, currPos: number, newPos: number) => {
      const newTags = tagsProps ? tagsProps.slice() : _selectedTags.slice();

      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag.id);

      if (setTags) {
        setTags(newTags);
      } else {
        _setSelectedTags(newTags);
      }
    },
    [_selectedTags, setTags, tagsProps]
  );

  const updateTagFromTagsComponent = useCallback(
    (i: any, tag: { id: string; text: string }) => {
      const newTags = tagsProps ? tagsProps.slice() : _selectedTags.slice();
      const normalizedValue = tag.text.trim().replace(/#/g, "");

      newTags[i] = normalizeTags ? normalizedValue : tag.text;

      if (setTags) {
        setTags(newTags);
      } else {
        _setSelectedTags(newTags);
      }
    },
    [_selectedTags, normalizeTags, setTags, tagsProps]
  );

  const clearAllFromTagsComponent = useCallback(() => {
    if (setTags) {
      setTags([]);
    } else {
      _setSelectedTags([]);
    }
  }, [setTags]);

  return {
    tags,
    selectedTags,
    setSelectedTags,
    tagsAsSelectValues,
    selectedTagsToSelectValues,
    setTagsFromSelectValues,
    setTagFromTagsComponent,
    deleteTagFromTagsComponent,
    dragTagFromTagsComponent,
    updateTagFromTagsComponent,
    clearAllFromTagsComponent,
  };
}

export function TagBackgroundColor(tagType: string) {
  const autoTagBgColor = useColorModeValue("tags.auto", "teal.500");
  const categoryTagBgColor = useColorModeValue("tags.category", "teal.600");
  const topicBgColor = useColorModeValue("#cff3c8", "gray.600");
  const tagBgColor = useColorModeValue("tags.manual", "gray.600");

  if (tagType === "auto") return autoTagBgColor;
  if (tagType === "category") return categoryTagBgColor;
  if (tagType === "topic") return topicBgColor;
  return tagBgColor;
}
