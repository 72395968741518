import { Stack } from "@chakra-ui/react";
import React from "react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { AddNewUsers } from "screens/landing/tabs/admin/common";
import type { User } from "screens/landing/tabs/admin/common";

interface IProps {
  onSubmitNewUsers: (users: User[]) => Promise<boolean>;
  isLoadingUsers: boolean;
}

interface PanelProps extends IProps {
  onClose: () => void;
  isOpen: boolean;
}

const AddUsers = ({ onSubmitNewUsers, isLoadingUsers }: IProps) => {
  return (
    <Stack spacing="3rem" pt="1rem">
      <AddNewUsers showRole={false} isLoading={isLoadingUsers} onSubmitNewUsers={(users) => onSubmitNewUsers(users)} />
    </Stack>
  );
};

export const CreateUsersPanel = (props: PanelProps) => {
  const { onClose, onSubmitNewUsers, isOpen, isLoadingUsers } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`Add Users`}>
      <Wizard>
        <PanelStep>{isOpen && <AddUsers isLoadingUsers={isLoadingUsers} onSubmitNewUsers={onSubmitNewUsers} />}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
