import type { JSONSchema7 } from "json-schema";

export const OnBoardingStepsSchema: JSONSchema7 = {
  $id: "http://json-schema.org/draft-07/schema#",
  type: ["array", "null"],
  examples: ["[{ stepNumber: 1, stepColor: 'blue', stepText: 'Step 1', stepTitle: 'Create a new project' } ...]"],
  items: {
    type: "object",
    properties: {
      stepNumber: {
        type: "number",
        description: "The number of the step",
      },
      stepColor: {
        type: "string",
        description: "The color of the step",
      },
      stepText: {
        type: "string",
        description: "The text of the step",
      },
      stepTitle: {
        type: "string",
        description: "The title of the step",
      },
      thumbnailSource: {
        type: "string",
        description: "The source of the thumbnail",
      },
      url: {
        type: "string",
        description: "The url of the video",
      },
      tooltip: {
        type: "string",
        description: "The tooltip of the video",
      },
    },
    required: [],
  },
  description: "The steps that will be displayed in the onboarding message",
};
