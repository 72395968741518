import {
  Progress,
  useColorModeValue,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  Portal,
  HStack,
  PopoverAnchor,
  Box,
  IconButton,
} from "@chakra-ui/react";
import React, { useCallback, forwardRef, useEffect, useState } from "react";
import {
  useWorkflowInProgressOrClarificationRequested,
  useChildWorkflowsProgress,
  useWorkflowChildWorkflowsIds,
  useChildWorkflowActiveTask,
} from "hooks/useCheckpoints";
import { TextOverflowTooltip } from "./TextOverflowTooltip";
import { FaChevronDown } from "react-icons/fa";
import capitalize from "lodash/capitalize";

interface Props {
  workflowId: string;
  size?: "compact" | "full";
  showInline?: boolean;
  maxWidth?: string;
}

interface InnerProps {
  id: string;
  size?: "compact" | "full";
  showInline?: boolean;
}

const ChildProgress = ({ showInline, size, id }: InnerProps) => {
  const progressBgColor = useColorModeValue("gray.300", "gray.700");
  const progressTextColor = useColorModeValue("gray.600", "gray.300");
  const progressColor = useColorModeValue("teal", "blue");
  const progressOpacity = useColorModeValue(".4", ".3");
  const childWorkflowProgress = useChildWorkflowsProgress(id);
  const [task, setTask] = useState("");
  const activeTask = useChildWorkflowActiveTask(id);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!activeTask) {
      timeout = setTimeout(() => {
        setTask("Waiting for next task to start.");
      }, 500);
    } else {
      setTask(activeTask);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [activeTask]);

  if (!childWorkflowProgress) {
    return null;
  } else {
    const { intent, completedStepCount, estimatedTotalStepCount } = childWorkflowProgress;

    return (
      <Stack mb={"0.5rem"}>
        <Text fontSize={"xs"} fontWeight="semibold">
          {capitalize(intent.replaceAll("_", " "))}
        </Text>
        <Box pl="1rem" borderLeft="1px solid #718096">
          <TextOverflowTooltip
            label={`${task} (${completedStepCount} of ~${estimatedTotalStepCount})`}
            fontSize={"xs"}
            style={{ paddingBottom: "0" }}
            color={progressTextColor}
          />
          <Progress
            width="10rem"
            mt={size === "compact" ? "0!important" : ".5rem!important"}
            mb={showInline ? ".5rem!important" : "0!important"}
            size={"xs"}
            opacity={progressOpacity}
            backgroundColor={progressBgColor}
            borderRadius="full"
            isAnimated
            value={Math.floor((completedStepCount / estimatedTotalStepCount) * 100)}
            colorScheme={progressColor}
          />
        </Box>
      </Stack>
    );
  }
};

const ForwardedBox = forwardRef<HTMLDivElement, any>((props, ref) => <Box ref={ref} {...props} />);

export const ChildWorkflowProgressBar = (props: Props) => {
  const { workflowId } = props;
  const isInProgress = useWorkflowInProgressOrClarificationRequested(workflowId);
  const childWorkflowsIds = useWorkflowChildWorkflowsIds(workflowId);

  const renderChildWorkflowsProgress = useCallback(() => {
    return childWorkflowsIds.slice(0, 10).map((id) => {
      return <ChildProgress key={id} id={id} {...props} />;
    });
  }, [childWorkflowsIds, props]);

  if (!isInProgress) {
    return <React.Fragment />;
  } else {
    return (
      <Popover placement="bottom-start" isLazy lazyBehavior="keepMounted">
        <HStack>
          <PopoverAnchor>
            <ForwardedBox />
          </PopoverAnchor>
          <PopoverTrigger>
            <IconButton
              size={"xxs"}
              backgroundColor="transparent"
              color={"#A0AEC0"}
              icon={<FaChevronDown />}
              aria-label="Child Workflows"
              onClick={(evt) => evt.stopPropagation()}
            />
          </PopoverTrigger>
        </HStack>
        <Portal>
          <PopoverContent maxH={200} overflow="auto">
            <PopoverBody>{renderChildWorkflowsProgress()}</PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  }
};
