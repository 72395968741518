import { Box, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { useTileProps } from "hooks";
import React from "react";
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from "recharts";
import { ProjectHeaderImage } from "screens/landing/tabs/project/projectLandingTileLayouts/components/ProjectHeaderImage";

export interface StockMarketChartData {
  projectId?: string;
  ticker: string;
  currentPrice: number;
  fairValueLow: number;
  fairValueHigh: number;
}

interface StockMarketChartProps {
  data: StockMarketChartData[];
}

const StockMarketChart: React.FC<StockMarketChartProps> = ({ data }) => {
  const commonTileProps = useTileProps();
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });

  const transformedData = data.map((item) => ({
    projectId: item.projectId,
    name: item.ticker,
    currentPrice: item.currentPrice,
    fairValue: [item.fairValueHigh, item.fairValueLow],
    fairValueBase: item.fairValueLow,
  }));

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const fairValueLow = payload[0].payload.fairValue[1];
      const fairValueHigh = payload[0].payload.fairValue[0];

      return (
        <Stack {...commonTileProps}>
          <ProjectHeaderImage maxHeight="1.5rem" width="8rem" collectionId={payload[0].payload.projectId} logoOnly />
          <Text fontSize={"sm"}>{`Ticker: ${payload[0].payload.name}`}</Text>
          <Text fontSize={"sm"}>{`Current Price: $${payload[1].value}`}</Text>
          <Text fontSize={"sm"}>{`Fair Value High: $${fairValueHigh}`}</Text>
          <Text fontSize={"sm"}>{`Fair Value Low: $${fairValueLow}`}</Text>
        </Stack>
      );
    }
    return null;
  };

  return (
    <Box height="19rem" width={isMobile ? "4/3" : "31rem"}>
      <ResponsiveContainer>
        <ComposedChart data={transformedData} margin={{ top: 0, right: 0, left: -35, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" vertical horizontal={false} />
          <XAxis dataKey="name" fontSize={"10px"} />
          <YAxis fontSize={"10px"} />
          <Tooltip content={<CustomTooltip />} />
          <Bar isAnimationActive={false} dataKey="fairValue" fill="#90caf9" fillOpacity={0.3} barSize={40} />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="currentPrice"
            stroke="#ff4d4f"
            dot={{ fill: "#ff4d4f", r: 4 }}
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default StockMarketChart;
