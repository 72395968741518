import { Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import type * as collection from "types/collection";
import { TagInput } from "screens/common/components/Tags/TagInput";
import uniq from "lodash/uniq";
import { useTags } from "hooks";
import type { ContentDetails } from "types/content/ContentDetails";
import { BlockSectionHeader } from "../previewSection/BlockSectionHeader";

interface Props {
  contentDetail: ContentDetails;
  deleteMetadata?: (text: string, type: "tag" | "note" | "highlight") => void;
  sendMetadata?: (text: string, type: string) => void;
}

export const ContentViewManualTags: FunctionComponent<
  React.PropsWithChildren<React.PropsWithChildren<Props & collection.CharliUIViewAsCollectionItem>>
> = ({ contentDetail, deleteMetadata, sendMetadata }) => {
  const { tags: suggestedTags } = useTags({ hideDefaultTags: true });
  const suggestions = uniq(suggestedTags.map((tag) => tag.replace(/(?<!,)\s+/g, "-")));

  return (
    <Box className="ch-content-tags-manual">
      <BlockSectionHeader title={`Resource Tags`} subSection />
      <TagInput
        options={suggestions.map((option: string) => ({ label: option, value: option }))}
        tags={contentDetail.manualTags || []}
        tagType="manual"
        onSaveTag={(tagValue) => sendMetadata && sendMetadata(tagValue, "tag")}
        onDeleteTag={(tagValue) => deleteMetadata && deleteMetadata(tagValue, "tag")}
        placeholder="Add new tag..."
        allowUpdate={false}
        allowFilter
      />
    </Box>
  );
};
