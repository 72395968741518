import { Literal, Record, String, Union, Array, Number } from "runtypes";
import type { Static } from "runtypes";

export const Requirement = Record({
  entity: String,
  operator: Union(Literal("equal"), Literal("not_equal"), Literal("in"), Literal("not_in")).nullable().optional(),
  value: Union(String, Array(String), Number).nullable().optional(),
});

export type Requirement = Static<typeof Requirement>;
