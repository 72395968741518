import { prependProxyBaseUrl, request } from "api/request";
import { FeatureFlags } from "types/flag";
import { getAccessToken } from "api/auth";

const FLAGS_ENDPOINT = prependProxyBaseUrl("/api/flags");

export async function getFlags() {
  const isLoggedIn = !!getAccessToken();

  const flags = (await request()
    .url(FLAGS_ENDPOINT)
    .query(isLoggedIn ? { authenticated: true } : {})
    .get()
    .json()) as {
    [key: string]: boolean;
  };

  return FeatureFlags.reduce((accum, flag) => {
    accum[flag] = !!flags[flag];
    return accum;
  }, {} as { [intent in typeof FeatureFlags[number]]: boolean });
}
