import { Box, useColorModeValue, Stack, Text } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useRef } from "react";
import parse from "html-react-parser";
import React from "react";
import ECHighlighter from "react-ec-highlighter";

interface Props {
  note: string;
  index?: number;
  onClick?: () => void;
  isDisabled?: boolean;
  isEditEnabled?: boolean;
  size?: "sm" | "md" | "lg";
  searchPhrase?: string;
}

export const Note: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  note,
  onClick,
  size = "sm",
  isDisabled = false,
  index,
  isEditEnabled,
  searchPhrase,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const borderColor = useColorModeValue("#cbd5e0", "darkgray");
  const sectionRef = useRef<HTMLDivElement>(null);

  const stripHtmlFromNote = (note: string) => {
    return note.replace(/(<([^>]+)>)/gi, "");
  };

  return (
    <Stack direction="row" position="relative">
      <Box
        ref={sectionRef}
        maxHeight="100%"
        width="100%"
        cursor={isDisabled ? "not-allowed" : "pointer"}
        key={index}
        className="ch-note"
        fontSize={size}
        fontWeight="400"
        color={textColor}
        _hover={{ borderBottom: isEditEnabled ? "1px solid" : "none" }}
        border="0"
        borderColor={`${borderColor}!important`}
        onClick={() => {
          onClick && !isDisabled && onClick();
        }}>
        {searchPhrase && searchPhrase.length > 0 ? (
          <ECHighlighter searchPhrase={searchPhrase} text={stripHtmlFromNote(note)} />
        ) : (
          parse(note)
        )}
      </Box>
      {sectionRef.current?.offsetHeight === 83 && (
        <Text
          position="absolute"
          right={isEditEnabled ? "3px" : "8px"}
          bottom="-5px"
          color={textColor}
          fontWeight="light"
          backgroundColor="white">
          ...
        </Text>
      )}
    </Stack>
  );
};
