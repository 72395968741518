import { createContext } from "react";
import type { UppyFile } from "types/files";

const ERROR_MESSAGE_SUFFIX = "not set in context, did you forget to nest your component inside <InputBarDropzone />?";

export const InputBarDropzoneContext = createContext({
  files: [] as UppyFile[],
  resetFiles: (): void => {
    throw new Error(`resetFiles ${ERROR_MESSAGE_SUFFIX}`);
  },
  openFilesModal: (): void => {
    throw new Error(`openFilesModal ${ERROR_MESSAGE_SUFFIX}`);
  },
  isUploadInProgress: false,
  didUploadFail: false,
});
