import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { shallowEqual } from "react-redux";
import type { FeatureUsage } from "api/usage/models/FeatureUsage";

export const useFeatureUsage = (intent: string): FeatureUsage | undefined => {
  return useSelector((state: RootState) => {
    return state.usage.featureUsage[intent];
  }, shallowEqual);
};
