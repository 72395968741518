import { prependProxyBaseUrl, request } from "api/request";
import { Array } from "runtypes";
import type { Tag } from "types/tags";
import { v4 } from "uuid";
import { TagsResponse } from "./content/models/TagsResponse";

const TAGS_ENDPOINT = prependProxyBaseUrl("/api/contents/tags");
const DEFAULT_TAGS = [
  "personal",
  "supplier",
  "client",
  "employee",
  "marketing",
  "expenses",
  "articles",
  "vendor",
  "client",
  "finance",
  "taxes",
  "product",
];

export async function getTags(limit = 200) {
  const maybeTagsResponse = await request().url(TAGS_ENDPOINT).query({ limit }).get().json();
  const validationResult = Array(TagsResponse).validate(maybeTagsResponse);

  if (!validationResult.success) {
    console.error(validationResult.details);
    return [];
  }

  const userTags: Tag[] = validationResult.value.map((tag) => {
    return {
      id: v4(),
      name: tag.value,
      origin: "user",
    };
  });

  DEFAULT_TAGS.forEach((defaultTag) => {
    const isDefaultTagInUserTags = userTags.some((tag) => tag.name === defaultTag);
    if (!isDefaultTagInUserTags) {
      userTags.push({ id: v4(), name: defaultTag, origin: "default" });
    }
  });

  return userTags;
}
