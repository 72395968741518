import type { WretcherError } from "wretch";

class WretcherErrorWrapper extends Error {
  underlyingError: WretcherError;

  constructor(message: string, underlyingError: WretcherError) {
    try {
      const jsonMessage = JSON.parse(message).error;
      super(jsonMessage);
    } catch (err) {
      // eslint-disable-next-line constructor-super
      super(message);
    }
    this.underlyingError = underlyingError;
  }
}

export class BadRequestError extends WretcherErrorWrapper {
  public static INVALID_CLIENT = "This web client has not been authorized to communicate with Charli.";
  public static USER_SESSION_NOT_FOUND = "Your session has expired. Please login again.";
  public static USER_ALREADY_EXISTS =
    "Your account was created with a different social provider. Please log in with the social provider you first logged in with.";
  public static USER_NONEXISTENT = "We couldn't find a user associated with your social provider";
  public static USER_ALREADY_CREATED = "A user already exists with this e-mail.";
  public static TOKEN_CLIENT_MISMATCH = "Your session has expired. Please login again."; // Not actually true, but this is how it should appear for users.
  public static STALE_TOKEN = "Your session has expired. Please login again.";

  constructor(error: WretcherError) {
    if (error.message.includes("unauthorized_client")) {
      super(BadRequestError.INVALID_CLIENT, error);
    } else if (error.message.includes("user session not found")) {
      super(BadRequestError.USER_SESSION_NOT_FOUND, error);
    } else if (error.message.includes("User already exists")) {
      super(BadRequestError.USER_ALREADY_EXISTS, error);
    } else if (error.message.includes("User doesn't exists")) {
      super(BadRequestError.USER_NONEXISTENT, error);
    } else if (error.message.includes("User exists with same username")) {
      super(BadRequestError.USER_ALREADY_CREATED, error);
    } else if (error.message.includes("Token client and authorized client don't match")) {
      super(BadRequestError.TOKEN_CLIENT_MISMATCH, error);
    } else if (error.message.includes("Stale token")) {
      super(BadRequestError.STALE_TOKEN, error);
    } else {
      super(error.message, error);
    }
    Object.setPrototypeOf(this, BadRequestError.prototype);
  }
}

export class UnauthorizedError extends WretcherErrorWrapper {
  public static BAD_CREDENTIALS = "The username or password is incorrect.";

  constructor(error: WretcherError) {
    if (error.message.includes("invalid_grant") && error.message.includes("user credentials")) {
      super(UnauthorizedError.BAD_CREDENTIALS, error);
    } else {
      super(error.message, error);
    }
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

export class ForbiddenError extends WretcherErrorWrapper {
  constructor(error: WretcherError) {
    super(error.message, error);
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export class ServerError extends WretcherErrorWrapper {
  public static UNREACHABLE = "Charli cannot be reached at this time, please try again later.";
  public static INTERNAL_ERROR = "Charli was unable to process your request, please try again later.";

  constructor(error: WretcherError) {
    if (error.status === 500) {
      try {
        const parsedError = JSON.parse(error.message) as Error;

        if (parsedError.name === "error") {
          super(ServerError.INTERNAL_ERROR, error);
        } else {
          super(parsedError.message, error);
          this.name = parsedError.name;
        }
      } catch (err) {
        super(ServerError.INTERNAL_ERROR, error);
      }
    } else {
      super(ServerError.UNREACHABLE, error);
    }
    Object.setPrototypeOf(this, ServerError.prototype);
  }
}

export function translateError(error: WretcherError) {
  // Not intended to be exhaustive (yet)
  switch (error.status) {
    case 400:
    case 409:
      throw new BadRequestError(error);
    case 401:
      throw new UnauthorizedError(error);
    case 403:
      throw new ForbiddenError(error);
    default:
      throw new ServerError(error);
  }
}
