import { useCollections, useUserPreference } from "hooks";
import type { FunctionComponent } from "react";
import React, { useContext, useState, useEffect } from "react";
import { ComboBox } from "screens/common/components";
import { v4 as uuid } from "uuid";
import { Input } from "@chakra-ui/react";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";

interface Props {
  collectionType?: string;
  shouldCreateNewCollection?: boolean;
}

export const CollectionSelector: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionType = "project",
  shouldCreateNewCollection = false,
}) => {
  const collections = useCollections();
  const filteredCollections =
    collectionType === "all" ? collections : collections.filter((collection) => collection.collectionType === collectionType);
  const { requestEntities, setRequestEntities, setConversationId } = useContext(ConversationContext);
  const [selectedCollectionName, setSelectedCollectionName] = useState(getRequestValue("collection_name", requestEntities) || "");
  const [selectedCollectionId, setSelectedCollectionId] = useState(getRequestValue("collection_id", requestEntities) || "");
  const [isSelectedCollectionNew, setSelectedCollectionNew] = useState(false);
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");

  useEffect(() => {
    injectDebugEntityPreference && console.log(requestEntities);
  }, [injectDebugEntityPreference, requestEntities]);
  const getCollectionTypeLabel = () => {
    switch (collectionType) {
      case "research":
        return "Research Project ";
      case "meeting":
        return "Meeting Project ";
      case "library":
        return "Collection ";
      default:
        return "Collection or Project ";
    }
  };

  const resetSelectedCollection = () => {
    updateBatchRequestEntities(
      [
        { entity: "collection_name", value: "", source: "collection-selector" },
        { entity: "name", value: "", source: "collection-selector" },
        { entity: "collection_id", value: "", source: "collection-selector" },
        { entity: "metadata_id", value: "", source: "collection-selector" },
      ],
      setRequestEntities
    );
  };

  useEffect(() => {
    if (selectedCollectionId === "" && selectedCollectionName === "") {
      resetSelectedCollection();
      return;
    }
    if (!isSelectedCollectionNew && selectedCollectionId && selectedCollectionId !== "") {
      updateBatchRequestEntities(
        [
          { entity: "collection_name", value: "", source: "collection-selector" },
          { entity: "name", value: "", source: "collection-selector" },
          { entity: "collection_id", value: selectedCollectionId, source: "collection-selector" },
        ],
        setRequestEntities
      );
    } else if (isSelectedCollectionNew && selectedCollectionName && selectedCollectionName !== "") {
      updateBatchRequestEntities(
        [
          { entity: "collection_name", value: selectedCollectionName, source: "collection-selector" },
          { entity: "name", value: selectedCollectionName, source: "collection-selector" },
          { entity: "collection_id", value: "", source: "collection-selector" },
          { entity: "metadata_id", value: "", source: "collection-selector" },
        ],
        setRequestEntities
      );
    }
    const getConversationId = collections.find((collection) => collection.id === selectedCollectionId)?.conversationId;
    getConversationId && setConversationId(getConversationId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollectionId, selectedCollectionName, isSelectedCollectionNew]);

  if (!shouldCreateNewCollection && collectionType !== "meeting") {
    return (
      <ComboBox
        size="xs"
        noOptionsMessage={() =>
          `This is your first ${getCollectionTypeLabel()}. Enter a new ${
            collectionType === "library" ? "collection" : collectionType
          } name to continue.`
        }
        inputId={`ch-collection-name-input`}
        formatCreateLabel={(inputValue) => `Click here or hit enter to create ${getCollectionTypeLabel()} "${inputValue}"`}
        isClearable
        className={`ch-collection-name-combo`}
        value={{ label: selectedCollectionName, value: selectedCollectionId }}
        onCreateOption={(event) => {
          if (!event) {
            setSelectedCollectionId("");
            setSelectedCollectionName("");
          } else {
            setSelectedCollectionId(uuid());
            setSelectedCollectionName(event);
            setSelectedCollectionNew(true);
          }
        }}
        onChange={(event: { label: string; value: string; alreadyExists: boolean } | null) => {
          if (event === null) {
            setSelectedCollectionId("");
            setSelectedCollectionName("");
          } else {
            setSelectedCollectionId(event.value);
            setSelectedCollectionName(event.label);
            setSelectedCollectionNew(false);
          }
        }}
        // onBlur={(event) => {
        //   setSelectedCollectionName(event.label);
        // }}
        placeholder={`Select or enter a ${getCollectionTypeLabel()} name...`}
        options={filteredCollections.map((collection) => {
          return { label: collection.name, value: collection.id };
        })}
      />
    );
  } else {
    return (
      <Input
        defaultValue={selectedCollectionName}
        size="xs"
        className={`ch-collection-name-input`}
        placeholder={`Enter a name...`}
        onBlur={(event) => {
          const labelText = event.target.value;
          if (labelText === "" || labelText === undefined) {
            setSelectedCollectionId("");
            setSelectedCollectionName("");
          } else {
            setSelectedCollectionId(uuid());
            setSelectedCollectionName(labelText);
            setSelectedCollectionNew(true);
          }
        }}
      />
    );
  }
};
