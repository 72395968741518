import React from "react";
import type { FunctionComponent } from "react";
import { Button, Stack, Text } from "@chakra-ui/react";
import type { MergeStrategy } from "hooks";

interface Props {
  onSuggestionClick: (text: string, strategy: MergeStrategy) => void;
  suggestions: string[];
  messageText: string;
}

const MAX_SUGGESTIONS = 10;

export const InputBarInlineSuggestions: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  suggestions,
  messageText,
  onSuggestionClick,
}) => {
  const filteredSuggestions = suggestions.filter((suggestion) => suggestion.startsWith(messageText)).slice(0, MAX_SUGGESTIONS);
  const isSuggestionFolders = suggestions.some((suggestion) => suggestion.includes("/"));

  if (!filteredSuggestions.length) return <React.Fragment />;

  return (
    <Stack spacing="0.2rem">
      <Text casing="uppercase" fontWeight="500" fontSize="xs" color="gray.500" fontFamily="heading">
        {isSuggestionFolders ? "FOLDERS" : "SUGGESTIONS"}
      </Text>
      <Stack id="chat-suggestions" direction="row">
        {filteredSuggestions.map((text) => {
          return (
            <Button
              key={text}
              size="sm"
              colorScheme="blue"
              onClick={() => {
                onSuggestionClick(text, "replace");
              }}>
              {text}
            </Button>
          );
        })}
      </Stack>
    </Stack>
  );
};
