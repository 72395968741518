import { Record, Number } from "runtypes";
import type { Static } from "runtypes";

export const SourceWeights = Record({
  factset: Number,
  sec: Number,
  google_search: Number,
  google_news: Number,
  bing: Number,
  duckduckgo: Number,
  user_uploaded: Number,
}).withConstraint((sourceWeights) => Object.values(sourceWeights).every((value) => value >= 0.7 && value <= 1.5));

export type SourceWeights = Static<typeof SourceWeights>;
