import { Box } from "@chakra-ui/react";
import { useCollectionTags } from "hooks";
import type { FunctionComponent } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import React from "react";
import { TagInput } from "screens/common/components/Tags/TagInput";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";
import { ResearchContext } from "./ResearchContext";
import { getEntityValue } from "./utils";

interface Tag {
  id: string;
  text: string;
}

interface Props {
  shouldCopyResearchHistory?: boolean;
}

export const ResearchTopicsInput: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  shouldCopyResearchHistory,
}) => {
  const { requestEntities, setRequestEntities } = useContext(ConversationContext);
  const [inputTopics, setInputTopics] = useState(getRequestValue("topic", requestEntities));
  const { selectedSearchHistory } = useContext(ResearchContext);

  const updateRequestEntity = (entityName: string, entityValue: string) => {
    updateBatchRequestEntities([{ entity: entityName, value: entityValue, source: "topic-inputs" }], setRequestEntities);
  };
  const suggestions = useCollectionTags("topics");
  const historyTopics = getEntityValue<string[]>("topic", selectedSearchHistory?.entities)?.join(",");

  useEffect(() => {
    if (selectedSearchHistory?.entities && shouldCopyResearchHistory) {
      if (historyTopics) {
        setInputTopics(historyTopics);
        updateRequestEntity("topic", `${historyTopics.length > 0 ? historyTopics : ""}` as string);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearchHistory]);

  const addTopics = (topics: string) => {
    const newTopics = inputTopics.length > 0 ? `${inputTopics},${topics}` : topics;
    setInputTopics(newTopics);
    updateRequestEntity("topic", `${newTopics.length > 0 ? newTopics : ""}` as string);
  };

  const deleteTopics = (topics: string) => {
    const currentTopics = inputTopics;
    const newTopics = currentTopics
      .split(",")
      .filter((topic: string) => topic !== topics)
      .join(",");
    setInputTopics(newTopics);
    updateRequestEntity("topic", `${newTopics.length > 0 ? newTopics : ""}` as string);
  };

  const updateTopics = (updatedTopic: Tag[]) => {
    const updatedTopics = updatedTopic.map((topic: Tag) => topic.text).join(",");
    setInputTopics(updatedTopics);
    updateRequestEntity("topic", `${updatedTopics.length > 0 ? updatedTopics : ""}` as string);
  };

  const tags = useMemo(() => {
    const topics = inputTopics || historyTopics || "";
    return topics.length > 0 ? topics.split(",") : [];
  }, [historyTopics, inputTopics]);

  return (
    <Box width="100%" minWidth="16rem" className="ch-research-topics">
      <TagInput
        size="sm"
        tags={tags}
        options={suggestions}
        tagType="topic"
        onSaveTag={(tagValue) => addTopics(tagValue)}
        onDeleteTag={(tagValue) => deleteTopics(tagValue)}
        onUpdateTag={(tags) => updateTopics(tags)}
        placeholder="Enter Topics for Highlight Extraction..."
      />
    </Box>
  );
};
