import React, { useContext, useEffect } from "react";
import { Box, Text, useBreakpointValue } from "@chakra-ui/react";
import { useConversation } from "hooks";
import { useParams } from "react-router-dom";
import { TabTitle } from "screens/common/components/TabTitle";
import { ConversationContext } from "screens/thread/ConversationContext";
import { MarkdownView } from "./body/MarkdownView";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import type { MarkdownButton } from "types/charliui";

export const MarkdownScreen = () => {
  // Type assertion is needed here as useParams assumes all parameters are present. contentId may be undefined, we want the type to accurately reflect this.
  const { conversationId, buttonId } = useParams() as { conversationId: string; buttonId: string };
  const conversation = useConversation(conversationId);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const maybeMarkdownButtonIfButtonId = conversation.messages
    .flatMap((message) => message.data ?? [])
    .find((uiElement): uiElement is MarkdownButton => uiElement.type === "markdown_button" && uiElement.body.buttonId === buttonId);

  // this is to support older conversations, in the future buttonId will always refer to buttonId
  const maybeMarkdownButton =
    maybeMarkdownButtonIfButtonId ??
    conversation.messages
      .find((message) => message.id === buttonId)
      ?.data?.find((uiElement): uiElement is MarkdownButton => uiElement.type === "markdown_button");

  const maybeMarkdownItems = maybeMarkdownButton
    ? maybeMarkdownButton.body.items.map((item) => {
        return { title: item.title, markdown: item.text };
      })
    : undefined;

  const { setHeaderText } = useContext(SettingsProviderContext);
  const { setConversationId, onConversationOpen } = useContext(ConversationContext);

  const canvasTitle = maybeMarkdownButton?.body.label ?? "Markdown View";

  useEffect(() => {
    setConversationId(conversationId);
    onConversationOpen();
    setHeaderText("Markdown View");
  }, [canvasTitle, conversationId, onConversationOpen, setConversationId, setHeaderText]);

  return (
    <>
      <TabTitle title={`Charli > ${canvasTitle}`} />
      <Box width="100%" height="100%" className="canvas-view" px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"}>
        <Box width="100%" height="100%" className="canvas-modal">
          {maybeMarkdownButton ? (
            <MarkdownView markdownData={maybeMarkdownItems} markdownTitle={maybeMarkdownButton.body.label} />
          ) : (
            <Text>Markdown content not found</Text>
          )}
        </Box>
      </Box>
    </>
  );
};
