import {
  loginWithCredentials,
  loginWithIdentityProvider,
  loginWithRefreshToken,
  loginWithApple,
  loginWithAstrellaAccessToken,
  signupWithCredentials,
  signupWithIdentityProvider,
} from "../session/operations";
import { actions as sessionActions } from "../session/reducer";

export const loginActions = [
  loginWithRefreshToken.fulfilled.type,
  loginWithIdentityProvider.fulfilled.type,
  loginWithCredentials.fulfilled.type,
  loginWithApple.fulfilled.type,
  loginWithAstrellaAccessToken.fulfilled.type,
  signupWithCredentials.fulfilled.type,
  signupWithIdentityProvider.fulfilled.type,
];

export const logoutActions = [
  sessionActions.logout.type,
  sessionActions.loginFailed.type,
  loginWithRefreshToken.rejected.type,
  loginWithIdentityProvider.rejected.type,
  loginWithCredentials.rejected.type,
  loginWithAstrellaAccessToken.rejected.type,
  loginWithApple.rejected.type,
  signupWithIdentityProvider.rejected.type,
];
