import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import type { ReactDatePickerProps } from "react-datepicker";

export const DatePickerWrapper: React.FC<ReactDatePickerProps> = ({ ...props }) => {
  // This custom styling applies to most case we use this DatePicker. It is overriden for the details screens.

  return <DatePicker {...props} />;
};
