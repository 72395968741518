import { parse, format, formatRelative } from "date-fns";

export const formatCurrency = (amount: number | undefined, currency = "USD"): string => {
  if (typeof amount === "number") {
    try {
      // Try with the user specified currency code
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
      });

      return formatter.format(amount);
    } catch (err) {
      // Retry with default currency
      return formatCurrency(amount, "USD");
    }
  } else {
    return "";
  }
};

export const formatBytes = (bytes: number) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  return +(bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
};

export const formatDateRelative = (date: number) => {
  return formatRelative(new Date(date), new Date());
};

export const formatDateWithOutputFormat = (date: Date, outputFormat: string): string => {
  return format(date, outputFormat);
};

export const formatDate = (date: string | number | Date | undefined | null, outputFormat: string | undefined = undefined) => {
  if (!date) return;

  try {
    if (typeof date === "string") {
      const rawDate = parse(date, "yyyy-MM-dd", new Date());
      return formatDateWithOutputFormat(rawDate, outputFormat ?? "do MMM yyyy");
    } else if (typeof date === "number") {
      const rawDate = new Date(date);
      return formatDateWithOutputFormat(rawDate, outputFormat ?? "do MMM yyyy 'at' h:mm aa");
    } else if (date instanceof Date) {
      return formatDateWithOutputFormat(date, outputFormat ?? "do MMM yyyy 'at' h:mm aa");
    }
  } catch (err) {
    console.warn("Encountered date that could not be parsed: ", date);
  }
};
