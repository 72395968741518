import { prependProxyBaseUrl, request } from "api/request";
import type { Notification } from "types/notifications";

const NOTIFICATION_ENDPOINT = prependProxyBaseUrl("/api/notifications");
type UpdateNotificationParameters = Partial<Pick<Notification, "hasBeenDismissed" | "followOnActionEnabled">> & Pick<Notification, "id">;

export async function getNotifications() {
  const notifications = (await request().url(NOTIFICATION_ENDPOINT).get().json()) as Notification[];
  return notifications.reduce((accum, notification) => {
    accum[notification.id] = notification;
    return accum;
  }, {} as { [key: string]: Notification });
}

export async function updateNotification({ id, hasBeenDismissed, followOnActionEnabled }: UpdateNotificationParameters) {
  await request().url(`${NOTIFICATION_ENDPOINT}/${id}`).json({ hasBeenDismissed, followOnActionEnabled }).put().res();
}

export async function dismissAllNotifications() {
  await request().url(`${NOTIFICATION_ENDPOINT}`).json({ hasBeenDismissed: true }).put().res();
}
