import React, { createContext, useCallback, useContext, useRef, useState } from "react";
import type { StockTickerData } from "api/stockTickerData";
import { getStockTickerData } from "api/stockTickerData";

export const CollectionsStocksContext = createContext({
  fetchStockData: (() => {
    throw new Error("Component must be nested in <CollectionsStocksContext.Provider />");
  }) as (companyTicker: string, companyStockExchange: string) => Promise<void>,
  getStockData: (() => {
    throw new Error("Component must be nested in <CollectionsStocksContext.Provider />");
  }) as (companyTicker: string, companyStockExchange: string) => StockTickerData | undefined,
  // getStockData: (() => {} as StockTickerData) as (companyTicker: string, companyStockExchange: string) => StockTickerData,
});

export const useCollectionsStocksContext = () => {
  return useContext(CollectionsStocksContext);
};

export const CollectionsStocksProvider = ({ children }) => {
  // Memoize by company ticker and company stock exchange
  const [memoizedStocks, setMemoizedStocks] = useState<Record<`${string}-${string}`, StockTickerData>>({});
  const isFetchingStocks = useRef<Record<string, boolean>>({});

  const fetchStockData = useCallback(
    async (companyTicker: string, companyStockExchange: string): Promise<void> => {
      const composeKey = `${companyTicker}-${companyStockExchange}`;

      if (memoizedStocks[composeKey]) {
        return;
      }

      if (isFetchingStocks.current[composeKey]) {
        return;
      }

      isFetchingStocks.current[composeKey] = true;

      try {
        const fetchStockData = await getStockTickerData(companyTicker, companyStockExchange);
        if (fetchStockData) {
          setMemoizedStocks((prev) => ({ ...prev, [composeKey]: fetchStockData }));
        }
      } catch (error) {
        console.error(`Failed to fetch data: ${error}`);
      } finally {
        isFetchingStocks.current[composeKey] = false;
      }
    },
    [memoizedStocks]
  );

  const getStockData = useCallback(
    (companyTicker: string, companyStockExchange: string): StockTickerData | undefined => {
      const composeKey = `${companyTicker}-${companyStockExchange}`;
      return memoizedStocks[composeKey];
    },
    [memoizedStocks]
  );

  return (
    <CollectionsStocksContext.Provider
      value={{
        fetchStockData,
        getStockData,
      }}>
      {children}
    </CollectionsStocksContext.Provider>
  );
};
