import React, { useContext, useState } from "react";
import { Wizard } from "react-use-wizard";
import { ConversationContext } from "screens/thread/ConversationContext";
import { PanelStep } from "../components/PanelStep";
import { PanelView } from "../components/PanelView";
import { ShareContent } from "./ShareContent";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useSendToCharli } from "../addToCharli/AddToCharliWizard/useSendToCharli";

export const SharePanel = () => {
  const { setRequestEntities } = useContext(ConversationContext);
  const { isContentViewOpen, isShareModalOpen, onShareModalClose } = useAddToCharliContext();
  const [shareIntent, setShareIntent] = useState<"share_resources" | "publish_chat">("share_resources");
  const [title, setTitle] = useState("Share Charli Resources");
  const { sendRequest } = useSendToCharli();

  const resetRequest = () => {
    setRequestEntities([]);
    onShareModalClose();
  };

  return (
    <PanelView
      isOpen={isShareModalOpen}
      onClose={() => {
        onShareModalClose();
        !isContentViewOpen && resetRequest();
      }}
      panelTitle={title}>
      <Wizard>
        <PanelStep
          onNextLabel={title}
          onSubmit={() =>
            sendRequest(
              shareIntent,
              resetRequest,
              "I'll share that content right away. You can open the conversation I started about it by clicking this message"
            )
          }>
          <ShareContent setTitle={setTitle} setShareTypeIntent={(intent) => setShareIntent(intent)} />
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
