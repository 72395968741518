import React, { useMemo } from "react";
import { Box, Button } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { MatchingEntityNamesFieldArray } from "./MatchingEntityNamesFieldArray";
import type { SerializedError } from "@reduxjs/toolkit";
import { DynamicForm } from "screens/common/components";
import type { IField } from "screens/common/components";
import { useButtonProps } from "hooks";
import { useMemorizedClarificationConfig, useMemorizedClarificationConfigLoading } from "hooks/useMemorizedClarificationConfig";

export function isError(payload: unknown): payload is SerializedError {
  return (payload as SerializedError).message !== undefined;
}

export interface FormValues {
  id?: string;
  memorizedEntityName: string;
  matchingEntityNames: { value: string }[];
}

interface ModalProps {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
}

interface IProps {
  id?: string;
  onSubmit: (values: FormValues) => void;
}

const UpsertMemorizedClarificationConfig = ({ id, onSubmit }: IProps) => {
  const memorizedConfig = useMemorizedClarificationConfig(id);
  const commonButtonProps = useButtonProps("sm", "primary");
  const isLoading = useMemorizedClarificationConfigLoading();

  const fields: IField<FormValues>[] = useMemo(() => {
    return [
      {
        type: "text",
        label: "Memorized entity name",
        entity: "memorizedEntityName",
        defaultValue: "",
      },
      {
        type: "field-array",
        label: "Matching entity names",
        entity: "matchingEntityNames",
        render: () => <MatchingEntityNamesFieldArray />,
        defaultValue: [],
      },
    ];
  }, []);

  const defaultValues: FormValues = useMemo(() => {
    return !memorizedConfig
      ? {
          memorizedEntityName: "",
          matchingEntityNames: [],
        }
      : {
          memorizedEntityName: memorizedConfig.memorizedEntityName,
          matchingEntityNames: memorizedConfig.matchingEntityNames.map((name) => ({ value: name })),
        };
  }, [memorizedConfig]);

  return (
    <Box pl=".5rem">
      <DynamicForm<FormValues>
        title={`${id ? "Update" : "Create"} memorized clarification configuration`}
        fields={fields}
        defaultValues={defaultValues}
        formId="upsert-memorized-clarification-form"
        onSubmit={onSubmit}
      />
      <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
        <Button disabled={isLoading} {...commonButtonProps} type="submit" form="upsert-memorized-clarification-form">
          Save Memorized Clarification Configuration
        </Button>
      </Box>
    </Box>
  );
};

export const UpsertMemorizedClarificationConfigModal = (props: ModalProps) => {
  const { id, isOpen, onSubmit, onClose } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${id ? "Update" : "Create"} memorized clarification configuration`}>
      <Wizard>
        <PanelStep>{isOpen && <UpsertMemorizedClarificationConfig id={id} onSubmit={onSubmit} />}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
