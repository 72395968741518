import { Stack } from "@chakra-ui/react";
import { useExternalIntegrations } from "hooks";
import type { FunctionComponent } from "react";
import React from "react";
import { LoadingGate } from "screens/common/components";
import { FilterIntegrationsView, IntegrationsFilterContextProvider } from ".";
import { IntegrationsRouter } from "./IntegrationsRouter";

interface Props {
  category?: string;
}

export const Integrations: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ category }) => {
  const { availableIntegrations } = useExternalIntegrations();

  return (
    <LoadingGate isLoading={!availableIntegrations?.length}>
      <Stack spacing="1rem" height="100%" width="100%">
        <IntegrationsFilterContextProvider cells={availableIntegrations}>
          <FilterIntegrationsView category={category} />
          <IntegrationsRouter />
        </IntegrationsFilterContextProvider>
      </Stack>
    </LoadingGate>
  );
};
