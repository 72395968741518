import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  useColorModeValue,
  Icon,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import type { UpsertCopyForm } from "./UpsertCopy";

export const CopiesFieldArray = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<UpsertCopyForm>();

  const commonButtonProps = useButtonProps("sm", "secondary");
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "listValue",
  });

  return (
    <>
      <Button {...commonButtonProps} mb={"1rem"} onClick={() => append({ value: "" })}>
        Add Value
      </Button>

      <Box boxShadow="base" p="6" rounded="md" bg="white">
        {errors.listValue?.message && (
          <Alert status="error">
            <AlertIcon />
            {errors.listValue?.message}
          </Alert>
        )}
        {fields.map((field, index) => (
          <Flex alignItems={"center"} key={field.id}>
            <Controller
              name={`listValue.${index}.value`}
              control={control}
              render={({ field }) => (
                <FormControl isInvalid={!!(errors.listValue && errors.listValue[index])} pb="1rem">
                  <InputGroup size="sm">
                    <Input {...field} placeholder="Write copy here..." size="sm" pr="4.5rem" type="text" />
                    <InputRightElement width="4.5rem">
                      <Icon
                        cursor="pointer"
                        as={BiTrash}
                        color={buttonColor}
                        boxSize="1rem"
                        _hover={{ color: buttonHoverColor }}
                        onClick={() => remove(index)}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.listValue && errors.listValue[index]?.value?.message && (
                    <FormErrorMessage>{errors.listValue[index]?.value?.message}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            />
          </Flex>
        ))}
      </Box>
    </>
  );
};
