import React from "react";
import CodeMirrorMerge from "react-codemirror-merge";
import { EditorView, lineNumbers } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import type { Config } from "types/configuredWorkflows";
import { PanelView } from "screens/panels/components/PanelView";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { bracketMatching } from "@codemirror/language";
import { autocompletion, closeBrackets } from "@codemirror/autocomplete";
import { history } from "@codemirror/commands";
import { lintGutter } from "@codemirror/lint";
import { json } from "@codemirror/lang-json";
import { Badge, Box, Button, Stack, Text } from "@chakra-ui/react";
import { useButtonProps } from "hooks";

interface IProps {
  isOpen: boolean;
  originalConfig: Config;
  modifiedConfig: Config;
  userIntent: string;
  onClose: () => void;
  onSubmit?: () => void;
  isLoading: boolean;
  sourceText?: string;
  targetText?: string;
}

export const ConfigDiffPanel = ({
  isOpen,
  originalConfig,
  modifiedConfig,
  userIntent,
  isLoading,
  sourceText,
  targetText,
  onClose,
  onSubmit,
}: IProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`Configs diff`}>
      <Wizard>
        <PanelStep justifyContent={"flex-start"}>
          <CodeMirrorMerge>
            <Stack direction="row" justifyContent={"space-between"}>
              <Text fontWeight="bold" as="span">
                {sourceText ?? "Current"}
              </Text>
              <Text fontWeight="bold" as="span">
                {targetText ?? "Modified"}
              </Text>
            </Stack>
            <CodeMirrorMerge.Original
              value={JSON.stringify(originalConfig, null, 2)}
              extensions={[
                bracketMatching(),
                closeBrackets(),
                history(),
                autocompletion(),
                lineNumbers(),
                lintGutter(),
                json(),
                EditorView.editable.of(false),
                EditorState.readOnly.of(true),
                EditorView.lineWrapping,
                EditorState.tabSize.of(2),
              ]}
            />
            <CodeMirrorMerge.Modified
              value={JSON.stringify(modifiedConfig, null, 2)}
              extensions={[
                bracketMatching(),
                closeBrackets(),
                history(),
                autocompletion(),
                lineNumbers(),
                lintGutter(),
                json(),
                EditorView.editable.of(false),
                EditorState.readOnly.of(true),
                EditorView.lineWrapping,
                EditorState.tabSize.of(2),
              ]}
            />
          </CodeMirrorMerge>

          {onSubmit && (
            <>
              <Stack spacing=".5rem" mt=".5rem" maxWidth={"80%"}>
                <Stack direction="row">
                  <Box>
                    <Badge fontSize={"medium"} colorScheme={"red"}>
                      WARNING
                    </Badge>
                  </Box>
                  <Text fontSize="medium">
                    An active workflow config with user intent{" "}
                    <Text as={"span"} fontWeight={"bold"}>
                      {userIntent}
                    </Text>{" "}
                    already exists. Confirm the changes to the config before saving.
                  </Text>
                </Stack>
              </Stack>

              <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
                <Button disabled={isLoading} onClick={() => onSubmit()} {...commonButtonProps} type="button">
                  Confirm
                </Button>
              </Box>
            </>
          )}
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
