import { Box, Center, Stack, Text, useColorModeValue, Image, useBreakpointValue, Divider, Button, Icon } from "@chakra-ui/react";
import type { ReactNode } from "react";
import React, { useEffect, useMemo } from "react";
import { useButtonProps, useCollection, useContents, useConversation, useProjectParams } from "hooks";
import { useConversationContext } from "screens/thread/ConversationContext";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "../components/PanelStep";
import { PanelView } from "../components/PanelView";
import { useLocation, useNavigate } from "react-router-dom";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import reportsPanelImage from "screens/common/static/misc/reports_panel_image.png";
import { LandingTitle } from "screens/landing/components";
import { ProjectReportPanelLatestReport } from "./ProjectReportPanelLatestReport";
import { hasProjectOutput } from "screens/collection/components/utils";
import type { ContentDetails } from "types/content/ContentDetails";
import { AiOutlineFileText } from "react-icons/ai";

export const ProjectReportPanel = () => {
  const { projectFilter, projectId } = useProjectParams();
  const { setRequestEntities } = useConversationContext();
  const { onProjectReportSelectorOpen, isProjectReportPanelOpen, onProjectReportPanelClose } = useAddToCharliContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const collection = useCollection(projectId);
  const bgColor = useColorModeValue("white", "#191f23");
  const { pathname } = useLocation();
  const hasPanel = pathname.includes("/panel");
  const navigate = useNavigate();
  const { contentId } = useProjectParams();
  const currentConversationId = contentId ? contentId : collection?.conversationId;
  const conversationState = useConversation(currentConversationId || "", ["generate_collection_report", "cancel"]).conversationState;
  const buttonSize = isMobile ? "xs" : "sm";
  const primaryButtonProps = useButtonProps(buttonSize, "primary");

  const panelHeader: ReactNode = useMemo(
    () => (
      <Stack spacing="0">
        <Box bgColor={"#81c34c"} height="1rem" width="100%" />
        <Stack direction="row" justifyContent={"space-between"} backgroundColor={"gray.100"} px={"1.5rem"} py="1rem">
          <Stack width="100%">
            <Text className="ch-panel-title" fontSize={"md"} fontWeight="semibold">
              {`AI Generated Report`}
            </Text>
            <Text fontSize={"sm"} fontWeight="normal">
              Charli AI provides standard reports for projects as well as download and editable files such as DOCX and XLSX for use in
              customer applications and database systems.
            </Text>
          </Stack>
          {!isMobile && (
            <Center>
              <Image src={reportsPanelImage} height={["2rem", "2.5rem", "5rem"]} ml="1.5rem" />
            </Center>
          )}
        </Stack>
      </Stack>
    ),
    [isMobile]
  );

  useEffect(() => {
    if (!isProjectReportPanelOpen) {
      setRequestEntities([]);
    } else {
      projectId && updateBatchRequestEntities([{ entity: "collection_id", value: projectId, source: "report-panel" }], setRequestEntities);
    }
  }, [projectId, isProjectReportPanelOpen, projectFilter, setRequestEntities]);

  const handleOnClose = () => {
    onProjectReportPanelClose();
    if (hasPanel) {
      navigate(-1);
    }
  };

  const collectionMetadataIds = useMemo(() => collection?.metadataIds && collection?.metadataIds, [collection?.metadataIds]);
  const contentData = useContents(collectionMetadataIds || [], { refreshFromNetwork: true });
  const contentDataFiltered = useMemo(() => {
    const filtered = contentData?.filter((content) => {
      return hasProjectOutput(content);
    });
    const sorted = filtered?.sort((a: ContentDetails, b: ContentDetails) => {
      return new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime();
    });
    const groupByName = sorted?.reduce((acc, content) => {
      const nameParts = content.name ? content.name.split(" - ") : ["AI Generated Report"];
      const name = nameParts.slice(0, -1).join(" : ").trim() || "AI Generated Report";
      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push(content);
      return acc;
    }, {} as { [name: string]: ContentDetails[] });
    return groupByName ? groupByName : [];
  }, [contentData]);

  return (
    <PanelView isOpen={isProjectReportPanelOpen} onClose={handleOnClose} panelTitle="Reports" panelHeader={panelHeader}>
      <Wizard>
        <PanelStep>
          <Stack spacing="2rem" backgroundColor={bgColor} pb="2rem">
            {contentDataFiltered &&
              Object.keys(contentDataFiltered).length > 0 &&
              Object.keys(contentDataFiltered).map((key, index) => {
                return (
                  <Box key={key}>
                    <LandingTitle text={`${key}`} color="primary.default">
                      {index === 0 && (
                        <Button
                          {...primaryButtonProps}
                          isDisabled={conversationState === "in_progress"}
                          onClick={onProjectReportSelectorOpen}
                          className="ch-content-report-panel"
                          leftIcon={<Icon as={AiOutlineFileText} boxSize={isMobile ? "1rem" : "1.3rem"} />}>
                          Send Report
                        </Button>
                      )}
                    </LandingTitle>
                    <Divider
                      marginTop={"0!important"}
                      borderColor={"primary.default"}
                      width={index === 0 ? "calc(100% - 10rem)" : "100%"}
                    />
                    <ProjectReportPanelLatestReport key={index} contentData={contentDataFiltered[key]} />
                  </Box>
                );
              })}
          </Stack>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
