import { prependProxyBaseUrl, request } from "../request";
import type { ReportTemplateGrouping } from "../../types/reportTemplates/ReportTemplateGrouping";
import type { UpdateReportTemplateRequest } from "api/reportTemplates/models/UpdateReportTemplateRequest";
import type { CreateReportTemplateRequest } from "./models/CreateReportTemplateRequest";

const REPORT_TEMPLATES_ENDPOINT = prependProxyBaseUrl("/api/report-template-service");

export const getReportTemplatesForUser = (): Promise<ReportTemplateGrouping[]> => {
  return request().url(`${REPORT_TEMPLATES_ENDPOINT}/templates`).get().json();
};

export const createReportTemplate = (reportTemplate: CreateReportTemplateRequest): Promise<ReportTemplateGrouping> => {
  return request().url(`${REPORT_TEMPLATES_ENDPOINT}/templates`).post(reportTemplate).json();
};

export const updateReportTemplate = (id: string, reportTemplate: UpdateReportTemplateRequest): Promise<ReportTemplateGrouping> => {
  return request().url(`${REPORT_TEMPLATES_ENDPOINT}/templates/${id}`).put(reportTemplate).json();
};

export const deleteReportTemplate = (reportTemplateId: string): Promise<void> => {
  return request().url(`${REPORT_TEMPLATES_ENDPOINT}/templates/${reportTemplateId}`).delete().res();
};
