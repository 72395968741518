import { Tooltip, useColorModeValue, Center, Icon } from "@chakra-ui/react";
import { useProjectParams } from "hooks";
import type { FunctionComponent } from "react";
import React from "react";
import { AiOutlineFileText } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

interface Props {
  collectionId: string;
  size?: string;
}

export const ProjectReportPanelButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
  size = ".9rem",
}) => {
  const tileBorderColor = useColorModeValue("gray.500", "gray.600");
  const additionalThumbnailCountTextColor = useColorModeValue("gray.500", "gray.400");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { onProjectReportPanelOpen } = useAddToCharliContext();
  const route = pathname.split("/").slice(1, 3).join("/");
  const { projectId } = useProjectParams();

  return (
    <Tooltip aria-label="project report" label="Open Reports" placement="top" className="ch-open-report-panel">
      <Center>
        <Icon
          className="ch-open-report-panel-icon"
          as={AiOutlineFileText}
          color={tileBorderColor}
          boxSize={size}
          _hover={{ color: additionalThumbnailCountTextColor }}
          onClick={(event) => {
            event.stopPropagation();
            navigate(`/${route}/${collectionId || projectId}/panel`);
            onProjectReportPanelOpen();
          }}
        />
      </Center>
    </Tooltip>
  );
};
