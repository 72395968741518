import React, { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";

import { useButtonProps } from "hooks";

interface IProps {
  isOpen: boolean;
  title: string;
  body: string;
  buttonLabel?: string;
  onConfirm?: () => void;
  onClose: () => void;
  isLoading?: boolean;
}

export const ConfirmDeleteModal = (props: IProps) => {
  const { isOpen, body, title, buttonLabel = "Delete", isLoading, onClose, onConfirm } = props;
  const commonButtonProps = useButtonProps("sm", "primary");
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="sm" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody fontSize="sm">{body}</AlertDialogBody>

          <AlertDialogFooter>
            <Button isLoading={isLoading} {...commonButtonProps} ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button isLoading={isLoading} {...commonButtonProps} onClick={onConfirm} ml={3}>
              {buttonLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
