import { Box, Stack } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useEffect } from "react";
import React, { useContext } from "react";
import { PanelView } from "screens/panels/components/PanelView";
import { ResearchHistory } from "screens/research/ResearchHistory";
import { useCollection, useProjectParams } from "hooks";
import type { Collection } from "types/collection";
import { ResearchContext } from "./ResearchContext";

const sortSearchHistoryDesc = (collection?: Collection) => {
  const searchHistory = [...(collection?.searchHistory ?? [])];
  return searchHistory.sort((a, b) => new Date(b.run).valueOf() - new Date(a.run).valueOf());
};

export const ResearchHistoryPanel: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const { projectId = "" } = useProjectParams();
  const collection = useCollection(projectId);
  const { isResearchHistoryPanelOpen, onResearchHistoryPanelClose } = useContext(ResearchContext);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (isResearchHistoryPanelOpen) {
          onResearchHistoryPanelClose();
        }
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isResearchHistoryPanelOpen, onResearchHistoryPanelClose]);

  return (
    <PanelView isOpen={isResearchHistoryPanelOpen} onClose={onResearchHistoryPanelClose} panelTitle={`Project Research History`}>
      <Stack>
        <Box mt="1rem">
          {collection && (
            <ResearchHistory
              searchHistories={sortSearchHistoryDesc(collection)}
              collection={{ id: collection.id, name: collection.name }}
            />
          )}
        </Box>
      </Stack>
    </PanelView>
  );
};
