import type { RegisteredHandler } from "api/configuredWorkflows/models/registeredHandlers/RegisteredHandler";
import React, { useState, createContext, useContext } from "react";

interface CheckpointIntentContextProps {
  handlersMap: Record<string, RegisteredHandler[]>;
  setHandlersMap: React.Dispatch<React.SetStateAction<Record<string, RegisteredHandler[]>>>;
}

const CheckpointIntentContext = createContext<CheckpointIntentContextProps>({
  handlersMap: {},
  setHandlersMap: () => {
    return;
  },
});

export const useCheckpointIntentContext = () => {
  return useContext(CheckpointIntentContext);
};

export const CheckpointIntentContextProvider = ({ children }) => {
  const [handlersMap, setHandlersMap] = useState<Record<string, RegisteredHandler[]>>({});

  return (
    <CheckpointIntentContext.Provider
      value={{
        handlersMap,
        setHandlersMap,
      }}>
      {children}
    </CheckpointIntentContext.Provider>
  );
};
