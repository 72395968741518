import type { FunctionComponent, RefObject } from "react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import type { Tag } from "@chakra-ui/react";
import { useOutsideClick } from "@chakra-ui/react";
import { Box, IconButton, Input, InputGroup, InputRightElement, useColorModeValue, Stack } from "@chakra-ui/react";
import { ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import { useButtonProps } from "hooks";
import { Popover } from "react-tiny-popover";

export interface Tag {
  id: string;
  text: string;
}

interface Props {
  replaceSpaceCharacter?: "underscore" | "hyphen" | "remove";
  placeholder?: string;
  suggestions?: Tag[];
  handleOnSaveTag?: (tag: string) => void;
  id?: string;
  width?: string;
  size?: string;
  parentRef?: RefObject<HTMLElement>;
  localTags?: Tag[];
  setLocalTags?: (tags: Tag[]) => void;
}
const delimiters = ["Enter"];

export const TagsSelector: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  replaceSpaceCharacter,
  placeholder = "Select or enter a tag...",
  suggestions = [],
  handleOnSaveTag,
  id,
  width = "100%",
  size = "xs",
  parentRef,
  localTags = [],
  setLocalTags,
}) => {
  const suggestionsRef = useRef<(HTMLDivElement | null)[]>([]);
  const tagInputRef = useRef<HTMLInputElement>(null);
  const [tagInput, setTagInput] = useState("");
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const inputBgColor = useColorModeValue("white", "#191f23");
  const secondaryButtonStyle = useButtonProps("sm", "secondary");
  const suggestionsBgColor = useColorModeValue("white", "gray.800");
  const suggestionsBgColorHover = useColorModeValue("gray.50", "gray.700");
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(-1);
  const commonButtonProps = useButtonProps("sm", "primary");

  enum SpaceCharacter {
    underscore = "_",
    hyphen = "-",
    remove = "",
  }

  useOutsideClick({
    ref: parentRef!,
    handler: () => {
      setShowSuggestions(false);
    },
  });

  useEffect(() => {
    setShowButtons(tagInput.length > 0);
  }, [tagInput]);

  const handleAddTag = (addedTag?: string) => {
    const selectedSuggestionValue =
      selectedSuggestionIndex < 0 ? null : suggestions[selectedSuggestionIndex] && suggestions[selectedSuggestionIndex].text;
    const currentTagInput = addedTag || selectedSuggestionValue || tagInput;

    if (!currentTagInput) {
      return;
    }
    setLocalTags &&
      currentTagInput.split(",").forEach((itemValue) => {
        setLocalTags([...localTags, { id: itemValue, text: itemValue }]);
      });
    handleOnSaveTag && handleOnSaveTag(currentTagInput);
    setTagInput("");
    setShowSuggestions(false);
    setSelectedSuggestionIndex(-1);
  };

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Tab") {
        if (selectedSuggestionIndex && suggestions[selectedSuggestionIndex]) {
          handleAddTag(suggestions[selectedSuggestionIndex].text);
        }
        event.preventDefault();
      }
      if (event.key === "Enter") {
        if (selectedSuggestionIndex && suggestions[selectedSuggestionIndex]) {
          handleAddTag(suggestions[selectedSuggestionIndex].text);
        } else {
          handleAddTag();
        }
      }
      if (event.key === "ArrowUp") {
        setSelectedSuggestionIndex((prev: number) => {
          if (prev === -1) {
            return prev;
          }
          return prev - 1;
        });
      }
      if (event.key === "ArrowDown") {
        setShowSuggestions(true);
        setSelectedSuggestionIndex((prev: number) => {
          if (prev === suggestions.length - 1) {
            return prev;
          }
          return prev + 1;
        });
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAddTag]
  );

  useEffect(() => {
    if (suggestionsRef.current[selectedSuggestionIndex]) {
      suggestionsRef.current[selectedSuggestionIndex]!.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [selectedSuggestionIndex]);

  return (
    <Popover
      parentElement={parentRef?.current || undefined}
      isOpen={showSuggestions && suggestions.length > 0}
      positions={["bottom"]}
      align="start"
      transformMode="relative"
      onClickOutside={() => setShowSuggestions(false)}
      content={() => (
        <Box maxHeight={"10rem"} overflowY="scroll" boxShadow={"md"} background={inputBgColor}>
          {suggestions.map((suggestion, index) => (
            <Box
              onMouseEnter={() => {
                setSelectedSuggestionIndex(index);
              }}
              backgroundColor={selectedSuggestionIndex === index ? suggestionsBgColorHover : suggestionsBgColor}
              _hover={{ cursor: "pointer", backgroundColor: suggestionsBgColorHover }}
              fontSize={"xs"}
              px="1rem"
              py=".5rem"
              key={index}
              ref={(el) => (suggestionsRef.current[index] = el)}
              role={"button"}
              onKeyDown={onKeyDown}
              onClick={() => {
                const tagValue =
                  replaceSpaceCharacter && suggestion.text
                    ? suggestion.text
                    : suggestion.text.replace(/(?<!,)\s+/g, `${SpaceCharacter[replaceSpaceCharacter as keyof typeof SpaceCharacter]}`);
                handleAddTag(tagValue);
              }}>
              {replaceSpaceCharacter && suggestion.text
                ? suggestion.text.replace(/(?<!,)\s+/g, SpaceCharacter[replaceSpaceCharacter as keyof typeof SpaceCharacter])
                : suggestion.text}
            </Box>
          ))}
        </Box>
      )}>
      <InputGroup minWidth={width} width={width} size={size} pb=".5rem">
        <Input
          {...(id && { id })}
          ref={tagInputRef}
          value={tagInput}
          onChange={({ target: { value } }) => {
            setTagInput(
              replaceSpaceCharacter
                ? value.replace(/(?<!,)\s+/g, `${SpaceCharacter[replaceSpaceCharacter as keyof typeof SpaceCharacter]}`)
                : value
            );
            setShowSuggestions(value.length > 0);
          }}
          type="text"
          background={inputBgColor}
          placeholder={placeholder}
          size={size}
          rounded="10px"
          onKeyDown={onKeyDown}
          onKeyPress={(evt) => {
            if (delimiters.includes(evt.key)) {
              handleAddTag();
            }
          }}
        />
        {showButtons && (
          <InputRightElement width="3.3rem">
            <Stack direction="row" spacing="5px" width="100%">
              <IconButton
                className="ch-clear-tags"
                onClick={() => {
                  setTagInput("");
                  setShowSuggestions(false);
                }}
                disabled={!tagInput}
                {...secondaryButtonStyle}
                size={"xs"}
                aria-label="Close"
                icon={<CloseIcon boxSize=".7rem" />}
              />
              <IconButton
                {...commonButtonProps}
                borderRadius="3px"
                onClick={() => {
                  handleAddTag();
                }}
                disabled={!tagInput}
                size={"xs"}
                aria-label="Add"
                icon={<ArrowForwardIcon boxSize="1.2rem" />}
              />
            </Stack>
          </InputRightElement>
        )}
      </InputGroup>
    </Popover>
  );
};
