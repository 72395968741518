import { createAction, createSlice } from "@reduxjs/toolkit";
import {
  createUsersFromAdmin,
  downloadUserBillingPlan,
  downloadUserEntitlements,
  downloadUserOrganizations,
  downloadUsers,
  searchUsers,
} from "./operations";
import type { User } from "@charliai/node-core-lib/lib/src/userService/models/User";
import type { User as UserAuth } from "types/auth";
import { REHYDRATE } from "redux-persist";
import type { RootState } from "state/rootReducer";
import type { MemberOrganization } from "types/organization/MemberOrganization";
import type { Entitlement } from "types/entitlements";
import type { BillingPlanType } from "types/billing/BillingPlan";

const rehydrate = createAction<RootState>(REHYDRATE);

const initialState = {
  usersMap: {} as Record<string, User>,
  users: [] as UserAuth[],
  isLoading: false,
  userOrganizations: {} as Record<string, MemberOrganization[]>,
  userEntitlements: {} as Record<string, Entitlement[]>,
  userPlan: {} as Record<string, BillingPlanType | undefined>,
  isOrganizationsLoading: false,
  isEntitlementsLoading: false,
  isBillingPlanLoading: false,
};

export const { actions, reducer } = createSlice({
  name: "users",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (state, action) => {
      return {
        ...state,
        ...(!action.payload?.users?.usersMap ? { usersMap: {} } : {}),
        ...(!action.payload?.users?.userOrganizations ? { userOrganizations: {} } : {}),
        ...(!action.payload?.users?.userEntitlements ? { userEntitlements: {} } : {}),
        ...(!action.payload?.users?.userPlan ? { userPlan: {} } : {}),
      };
    });
    builder.addCase(downloadUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      action.payload.forEach((user) => {
        state.usersMap[user.id] = user;
      });
    });
    builder.addCase(downloadUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadUsers.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(searchUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    });
    builder.addCase(searchUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchUsers.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(downloadUserOrganizations.fulfilled, (state, action) => {
      state.isOrganizationsLoading = false;
      state.userOrganizations[action.meta.arg] = action.payload;
    });
    builder.addCase(downloadUserOrganizations.pending, (state) => {
      state.isOrganizationsLoading = true;
    });
    builder.addCase(downloadUserOrganizations.rejected, (state) => {
      state.isOrganizationsLoading = false;
    });

    builder.addCase(downloadUserEntitlements.fulfilled, (state, action) => {
      state.isEntitlementsLoading = false;
      state.userEntitlements[action.meta.arg] = action.payload;
    });
    builder.addCase(downloadUserEntitlements.pending, (state) => {
      state.isEntitlementsLoading = true;
    });
    builder.addCase(downloadUserEntitlements.rejected, (state) => {
      state.isEntitlementsLoading = false;
    });

    builder.addCase(downloadUserBillingPlan.fulfilled, (state, action) => {
      state.isBillingPlanLoading = false;
      state.userPlan[action.meta.arg] = action.payload;
    });
    builder.addCase(downloadUserBillingPlan.pending, (state) => {
      state.isBillingPlanLoading = true;
    });
    builder.addCase(downloadUserBillingPlan.rejected, (state) => {
      state.isBillingPlanLoading = false;
    });

    builder.addCase(createUsersFromAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUsersFromAdmin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createUsersFromAdmin.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default reducer;
