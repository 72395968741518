import { Record, String, Array, InstanceOf, Union } from "runtypes";
import type { Static } from "runtypes";

export const MemorizedClarificationConfiguration = Record({
  id: String,
  memorizedEntityName: String,
  matchingEntityNames: Array(String),
  creationByUserId: String,
  creationDate: Union(String, InstanceOf(Date)),
  lastUpdatedByUserId: String,
  lastUpdatedDate: Union(String, InstanceOf(Date)),
  creationByUserName: String.optional(),
  lastUpdatedByUserName: String.optional(),
});

export type MemorizedClarificationConfiguration = Static<typeof MemorizedClarificationConfiguration>;
