import React, { useEffect, useMemo, memo } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { CommonModal } from "./CommonModal";
import { Wizard } from "react-use-wizard";
import { OnboardingNewProjectStep } from "./OnboardingNewProjectStep";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { v4 as uuid } from "uuid";
import { useEquityPortfolioId, usePortfolioCollections, useUserProfile } from "hooks";
import { track } from "api/analytics";
import { ONBOARDING_OPENED } from "api/analytics/events";

export const ONBOARDING_MODEL_HEIGHT = "39rem";

const OnboardingModalComponent = () => {
  const { isOnboardingModalOpen, onOnboardingModalClose } = useConversationContext();
  const dispatch = useDispatch();
  const portfolioCollections = usePortfolioCollections();
  const equityPortfolioId = useEquityPortfolioId(portfolioCollections, "My Equities");
  const conversationId = useMemo(() => uuid(), []);
  const { fullName = "", email = "" } = useUserProfile();

  useEffect(() => {
    track(ONBOARDING_OPENED, { userName: fullName, email });
  }, [email, fullName, isOnboardingModalOpen]);

  useEffect(() => {
    if (isOnboardingModalOpen && !equityPortfolioId) {
      dispatch(
        sendMessage({
          conversationId,
          intent: "/post_registration_setup",
        })
      );
    }
  }, [conversationId, dispatch, equityPortfolioId, isOnboardingModalOpen]);

  const modalBody = useMemo(
    () => (
      <Wizard>
        <OnboardingNewProjectStep equityPortfolioId={equityPortfolioId} />
      </Wizard>
    ),
    [equityPortfolioId]
  );

  return (
    <CommonModal
      allowDismiss={false}
      borderColor="#4799d4"
      isOpen={isOnboardingModalOpen}
      onClose={onOnboardingModalClose}
      minWidth={["100%", "40rem", "40rem"]}
      modalBody={modalBody}
    />
  );
};

export const OnboardingModal = memo(OnboardingModalComponent);
