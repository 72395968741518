import { Box, Stack, Switch, Text } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useMemo, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { useCollectionKey, useProjectParams, useTags, useTileProps } from "hooks";
import { ContentFilterContext } from "screens/content";
import uniq from "lodash/uniq";
import { TagInput } from "screens/common/components/Tags/TagInput";
import { CollectionsFilterContext } from "screens/landing/tabs";
import WordCloud from "screens/common/components/Tags/TagCloud/WordCloud";
import { LandingTitle } from "screens/landing/components";
interface Props {
  collectionId: string;
  maxTagsToShow?: number;
  allowOnClickTag?: boolean;
}

export const CollectionDetailTags: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  maxTagsToShow,
  collectionId,
  allowOnClickTag = true,
}) => {
  const { projectId } = useProjectParams();
  const dispatch = useDispatch();
  const { setSelectedTags, selectedTags } = useContext(ContentFilterContext);
  const { tags: suggestedTags } = useTags({ hideDefaultTags: true });
  const suggestions = uniq(suggestedTags.map((tag) => tag.replace(/(?<!,)\s+/g, "-")));
  const { searchText } = useContext(CollectionsFilterContext);
  const parentBoxRef = useRef<HTMLDivElement>(null);
  const commonTileProps = useTileProps();
  const collectionMetadata = useCollectionKey(collectionId || projectId, "metadata");
  const collectionConversationId = useCollectionKey(projectId, "conversationId");

  const tags = useMemo(() => {
    return {
      autoTags: collectionMetadata?.autoTags || [],
      manualTags: collectionMetadata?.manualTags || [],
    };
  }, [collectionMetadata]);

  const sendEditMessage = ({
    entity,
    value,
    action,
    reason,
  }: {
    entity: string;
    value: string;
    action: "add" | "delete";
    reason?: string;
  }) => {
    if (!collectionMetadata?.id || !collectionConversationId) {
      return;
    }

    const entities: {
      entity: string;
      value: string;
    }[] = [];
    entities.push({ entity: "collection_id", value: collectionId });
    entities.push({ entity: "metadata_id", value: collectionMetadata?.id });
    if (entity === "name") {
      entities.push({ entity: "collection_name", value: value });
    }
    if (reason) {
      entities.push({ entity: "highlight_deletion_reason", value: reason });
    }

    if (entity === "tag") {
      value.split(",").forEach((itemValue) => {
        entities.push({ entity: entity, value: itemValue.trim() });
      });
    } else {
      entities.push({ entity: entity, value: value });
    }

    dispatch(
      sendMessage({
        conversationId: collectionConversationId,
        intent: action === "add" ? "/edit_collection" : "/delete_collection_metadata",
        message: "Edit this collection",
        entities: entities,
      })
    );
  };

  const sendMetadata = (value: string, entity: string) => {
    sendEditMessage({ entity, value, action: "add" });
  };

  const deleteMetadata = (value: string, entity: string, reason?: string) => {
    sendEditMessage({ entity, value, action: "delete", reason });
  };
  const [showWordCloud, setShowWordCloud] = React.useState(false);

  return (
    <Stack justify="space-between" spacing="2rem">
      {tags?.autoTags && tags.autoTags.length > 0 && (
        <Stack {...commonTileProps} overflow="unset" cursor={"default"} pb="1rem" spacing="1rem" className="ch-collection-detail-tags">
          <LandingTitle underline text="AI Extracted Keywords" color="primary.default">
            <Stack direction="row">
              <Text fontSize="xs">{`${showWordCloud ? "Hide" : "Show"} Tag Cloud`}</Text>
              <Switch size="sm" isChecked={showWordCloud} onChange={() => setShowWordCloud(!showWordCloud)} />
            </Stack>
          </LandingTitle>
          <Box ref={parentBoxRef}>
            {showWordCloud ? (
              <WordCloud height={200} width={parentBoxRef.current ? parentBoxRef.current.clientWidth : 100} tags={tags.autoTags} />
            ) : (
              <TagInput
                size="sm"
                tags={tags?.autoTags}
                tagType="auto"
                hideInput
                allowUpdate={false}
                onDeleteTag={(tagValue) => deleteMetadata(tagValue, "tag")}
                onClickTag={allowOnClickTag ? (tag) => setSelectedTags(selectedTags.length > 0 ? [] : [tag]) : undefined}
                maxTagsToShow={maxTagsToShow}
                searchPhrase={searchText}
              />
            )}
          </Box>
        </Stack>
      )}
      <Stack {...commonTileProps} overflow="unset" cursor={"default"} pb="1rem" spacing="1rem" className="ch-collection-detail-manual-tags">
        <LandingTitle style={{ width: "20rem" }} underline text="Project Tags" color="primary.default" />
        <Box>
          <TagInput
            size="sm"
            options={suggestions.map((option) => ({ label: option, value: option }))}
            tags={tags?.manualTags}
            tagType="manual"
            onSaveTag={(tagValue) => sendMetadata(tagValue, "tag")}
            onDeleteTag={(tagValue) => deleteMetadata(tagValue, "tag")}
            placeholder="Add new tag..."
            allowUpdate={false}
            onClickTag={allowOnClickTag ? (tag) => setSelectedTags(selectedTags.length > 0 ? [] : [tag]) : undefined}
            maxTagsToShow={maxTagsToShow}
            searchPhrase={searchText}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
