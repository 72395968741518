import {
  Button,
  Divider,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { track } from "api/analytics";
import { SLACK_ACCOUNT_LINKED } from "api/analytics/events";
import { linkCharliUserToChatUser } from "api/chatIntegration";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BsArrowLeftRight } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import charliLogo from "screens/common/static/logos/charli-logo-small.png";
import slackLogo from "screens/common/static/logos/slack-logo.png";

const supportedClientId = "slack";

export const ChatIntegrationCodeListener = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const [isSubmitPending, setIsSubmitPending] = useState(false);

  const primaryTextColor = useColorModeValue("gray.700", "gray.200");
  const secondaryTextColor = useColorModeValue("gray.600", "gray.300");

  const chatUserToCharliUserLinkRequest: { linkToChatUserId: string; teamId: string; clientId: string } | undefined = useMemo(() => {
    const urlSearchParams = Object.fromEntries(new URLSearchParams(search)) || {};

    if (urlSearchParams.linkToChatUserId && urlSearchParams.teamId && urlSearchParams.clientId) {
      return {
        linkToChatUserId: urlSearchParams.linkToChatUserId,
        teamId: urlSearchParams.teamId,
        clientId: urlSearchParams.clientId,
      };
    }
  }, [search]);

  useEffect(() => {
    if (!chatUserToCharliUserLinkRequest) return;

    if (chatUserToCharliUserLinkRequest.clientId !== supportedClientId) {
      // If the query string contains an unsupported client ID, clear the query string
      navigate("/");
    } else {
      // We have a valid request, present the form.
      onOpen();
    }
  }, [chatUserToCharliUserLinkRequest, navigate, onOpen]);

  const onClose = useCallback(() => {
    navigate("/");
    onModalClose();
  }, [navigate, onModalClose]);

  const submitLinkUserRequest = useCallback(async () => {
    if (!chatUserToCharliUserLinkRequest) return;

    setIsSubmitPending(true);

    try {
      await linkCharliUserToChatUser(
        chatUserToCharliUserLinkRequest.linkToChatUserId,
        chatUserToCharliUserLinkRequest.teamId,
        chatUserToCharliUserLinkRequest.clientId
      );
      track(SLACK_ACCOUNT_LINKED);
      toast({
        title: "Link successful",
        description: "Your Slack account has been linked with Charli.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (err) {
      toast({
        title: "Link unsuccessful",
        description:
          "An error occurred when linking your Slack account with Charli. Please try again later, or contact support@charli.ai for assistance.",
        status: "error",
        duration: 12000,
        isClosable: true,
      });
    } finally {
      setIsSubmitPending(false);
    }
  }, [chatUserToCharliUserLinkRequest, onClose, toast]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="50rem" maxWidth={["98vw", "95vw", "90vw"]}>
        <ModalBody p="3rem">
          <Stack align="center" spacing={["1rem", "1rem", "2rem"]}>
            <Stack direction="row" align="center">
              <Image height={["3rem", "4rem"]} src={slackLogo} pr="1.5rem" />
              <Icon as={BsArrowLeftRight} boxSize="2rem" color="gray.400" pr="-1rem !important" />
              <Image height={["3rem", "4rem"]} src={charliLogo} pl="1.2rem" />
            </Stack>
            <Text fontWeight="500" textColor={primaryTextColor} fontSize={["1rem", "1.25rem", "1.5rem"]} textAlign="center">
              Slack is requesting access to your Charli account
            </Text>
            <Divider />
            <Text textColor={secondaryTextColor} fontSize={["0.85rem", "1rem"]} textAlign="center">
              <b>Slack for Charli</b> will be able to file new content on your behalf.
            </Text>
            <Divider />
            <Stack spacing="1rem">
              <Button colorScheme="blue" px="3rem" onClick={submitLinkUserRequest} isLoading={isSubmitPending} isDisabled={isSubmitPending}>
                Authorize
              </Button>
              <Button variant="link" onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
