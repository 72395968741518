import React, { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Tag,
  Box,
  Text,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Stack,
  useColorModeValue,
  Input,
  InputGroup,
  InputLeftElement,
  Divider,
} from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useButtonProps, useOrganization, useOrganizationEntitlements } from "hooks";
import orderBy from "lodash/orderBy";
import type { Organization } from "types/organization/organization";
import { useBillingPlanEntitlements } from "hooks/useBillingPlans";
import { EntitlementsList, RenderIfHasEntitlements } from "screens/common/components";
import { getOrganizationLogs } from "api/organizations";
import type { EventType, OrganizationLog, TableName } from "api/organizations/models/GetOrganizationLog";
import { formatDate } from "screens/common/modal/formatters";
import { TypingIndicator } from "screens/thread/components/cells/components";
import capitalize from "lodash/capitalize";
import { SearchIcon } from "@chakra-ui/icons";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { UserDetailModal } from "../../usersAdmin/modals/UserDetailModal";
import { getUsersByIds } from "api/user";
import type { User } from "types/auth";

interface IOrganizationModalProps {
  id: string | null;
  onClose: () => void;
}

interface IProps {
  organization: Organization;
}

const formatTable = (tableName: TableName) => {
  switch (tableName) {
    case "organization":
      return "organization";
    case "organization_member":
      return "member";
    case "organization_invites":
      return "email";
    default:
      return tableName;
  }
};

const formatEventType = (eventType: EventType, tableName: TableName) => {
  switch (eventType) {
    case "create":
      if (tableName.includes("member")) {
        return "added";
      } else {
        return "created";
      }
    case "update":
      return "updated";
    case "delete":
      if (tableName.includes("member")) {
        return "removed";
      } else {
        return "deleted";
      }
    case "invite":
      return "sent invitation to";
    default:
      return eventType;
  }
};

export const OrganizationResume = ({ organization }: IProps) => {
  const { entitlements, isLoading: isLoadingEntitlements } = useOrganizationEntitlements(organization.id);
  const { entitlements: billingPlanEntitlements, isLoading: isLoadingBillingPlanEntitlements } = useBillingPlanEntitlements(
    organization.planCode
  );
  const [logs, setLogs] = useState<OrganizationLog[]>([]);
  const [isLoadingLogs, setIsLoadingLogs] = useState<boolean>(false);
  const titleColor = useColorModeValue("gray.900", "gray.500");
  const searchInput = React.createRef<HTMLInputElement>();
  const [searchText, setSearchText] = useState("");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const onClose = useCallback(() => {
    setSelectedUser(undefined);
  }, []);
  const [selectedUser, setSelectedUser] = useState<null | undefined | User>();
  const textColor = useColorModeValue("primary.darkGray", "gray.300");

  const formatLog = useCallback((log: OrganizationLog) => {
    const formatCreateEventObject = (tableRecordName: string, eventObject: Record<string, unknown>) => {
      return (
        <span>
          {" "}
          <Badge>{tableRecordName} </Badge>
          {eventObject.role ? (
            <span>
              with role <Badge>{eventObject.role as string}</Badge>
            </span>
          ) : (
            ""
          )}
        </span>
      );
    };

    const formatUpdateObject = (tableRecordName: string, objectChanges: Record<string, { old: unknown; new: unknown }>) => {
      return (
        <span>
          <Badge>{tableRecordName}</Badge> fields:{" "}
          {Object.entries(objectChanges).map(([key, value]) => {
            return (
              <span key={key}>
                {" "}
                <Badge>{key}</Badge> from <Badge>{JSON.stringify(value.old)}</Badge> to <Badge>{JSON.stringify(value.new)}</Badge>
              </span>
            );
          })}{" "}
        </span>
      );
    };

    return (
      <Box>
        <Badge>{formatDate(new Date(log.modifiedAt))}</Badge>: {log.modifiedByUserIdName}
        <Badge>{formatEventType(log.eventType, log.tableName)}</Badge>
        {formatTable(log.tableName)}
        {log.eventType === "create" && log.eventObject && formatCreateEventObject(log.tableRecordName, log.eventObject)}
        {log.eventType === "update" && log.objectChanges && formatUpdateObject(log.tableRecordName, log.objectChanges)}
        {(log.eventType === "delete" || log.eventType === "invite") && <Badge>{log.tableRecordName}</Badge>}
      </Box>
    );
  }, []);

  const getSelectedUser = useCallback((userId: string) => {
    setSelectedUser(undefined);
    getUsersByIds([userId])
      .then((users) => {
        const user = users[0];

        if (user) {
          setSelectedUser({
            id: user.id,
            ...(user.firstName && { firstName: user.firstName }),
            ...(user.lastName && { lastName: user.lastName }),
            email: user.email,
            isEmailVerified: user.isEmailVerified,
            organizations: user.organizations,
            passwordResetCode: user.passwordResetCode,
          });
        } else {
          setSelectedUser(null);
        }
      })
      .catch((err) => {
        setSelectedUser(undefined);
        console.error(err);
      });
  }, []);

  const renderMembers = useCallback(() => {
    if (!organization.members) {
      return <React.Fragment />;
    }

    if (organization.members.length === 0) {
      return <Text>No members added to this organization.</Text>;
    } else {
      return orderBy(organization.members, "userName")
        .filter((member) => {
          const lowerCaseSearchText = searchText.toLowerCase();
          return (
            member.userName.toLowerCase().includes(lowerCaseSearchText) ||
            (member.email && member.email.toLowerCase().includes(lowerCaseSearchText))
          );
        })
        .map((member) => (
          <Box
            cursor={"pointer"}
            borderWidth="2px"
            borderRadius="lg"
            padding="1rem"
            key={member.userId}
            onClick={() => getSelectedUser(member.userId)}>
            <Stack direction="row" justifyContent={"space-between"} width="100%">
              <Text isTruncated fontWeight={"semibold"}>
                {member.userName}
              </Text>
              <Text isTruncated width="50%" textAlign={"right"}>
                {member.email || "n/a"}
              </Text>
            </Stack>
          </Box>
        ));
    }
  }, [organization.members, searchText, getSelectedUser]);

  const renderEventLogs = useCallback(() => {
    if (isLoadingLogs) {
      return <TypingIndicator />;
    }

    if (logs.length === 0) {
      return <Text>No logs found for this organization.</Text>;
    }

    return (
      <UnorderedList>
        {logs.map((log) => (
          <ListItem key={log.id}>{formatLog(log)}</ListItem>
        ))}
      </UnorderedList>
    );
  }, [logs, isLoadingLogs, formatLog]);

  useEffect(() => {
    setIsLoadingLogs(true);
    getOrganizationLogs(organization.id)
      .then((response) => setLogs(response))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoadingLogs(false));
  }, [organization.id]);

  return (
    <Stack spacing="2rem">
      <Stack spacing="1rem">
        <Stack direction="row">
          <Text fontWeight={"bold"} color={textColor} fontSize="md" minWidth="8rem">
            Name
          </Text>
          <Text color={titleColor} fontSize="md">
            {organization.name}
          </Text>
        </Stack>
        <Stack direction="row">
          <Text fontWeight={"bold"} color={textColor} fontSize="md" minWidth="8rem">
            Plan
          </Text>
          <Text fontSize="md">
            <Tag>{organization.planCode.toUpperCase()}</Tag>
          </Text>
        </Stack>
        <Box marginBottom={"10"}>
          <Stack direction="row" width="100%" pb=".5rem">
            <Box width="4rem" pt="4px">
              <SectionHeader title="Users" />
            </Box>
            <InputGroup size="sm" width="20rem">
              <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
              <Input
                {...secondaryButtonProps}
                placeholder={"Filter by name or email..."}
                ref={searchInput}
                defaultValue={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </InputGroup>
          </Stack>
          <SimpleGrid maxHeight={500} overflowY="auto" columns={3} spacingX="1rem" spacingY="1rem">
            {renderMembers()}
          </SimpleGrid>
        </Box>
      </Stack>
      <Divider />
      <SimpleGrid overflowY="auto" columns={2} spacing=".5rem">
        <Box marginBottom={"10"}>
          <Text fontWeight={"bold"} color={titleColor} fontSize="md" marginBottom={4}>
            Organization Entitlements :
          </Text>
          <RenderIfHasEntitlements entitlement="manage_entitlements">
            <EntitlementsList
              type="organization"
              entityId={organization.id}
              entitlements={entitlements}
              isLoading={isLoadingEntitlements}
            />
          </RenderIfHasEntitlements>
        </Box>
        <Box marginBottom={"10"}>
          <Text fontWeight={"bold"} color={titleColor} fontSize="md" marginBottom={4}>
            {`${capitalize(organization.planCode)} Plan Entitlements`}
          </Text>
          <RenderIfHasEntitlements entitlement="manage_entitlements">
            <EntitlementsList
              type="plan"
              entityId={organization.planCode}
              entitlements={billingPlanEntitlements}
              isLoading={isLoadingBillingPlanEntitlements}
            />
          </RenderIfHasEntitlements>
        </Box>
      </SimpleGrid>
      <Divider />
      <Box>
        <Text fontWeight={"bold"} color={titleColor} fontSize="md" pb="1rem">
          Organization Logs
        </Text>
        {renderEventLogs()}
      </Box>

      <Box height={50} />
      {selectedUser && <UserDetailModal user={selectedUser} isOpen={!!selectedUser} onClose={onClose} />}
    </Stack>
  );
};

export const OrganizationModal = (props: IOrganizationModalProps) => {
  const { id, onClose } = props;
  const organization = useOrganization(id);

  if (!organization) return <></>;

  return (
    <PanelView isOpen onClose={onClose} panelTitle={`Organization Details: ${organization.name}`}>
      <Wizard>
        <PanelStep>
          <Box my=".5rem">
            <OrganizationResume organization={organization} />
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
