import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
  ModalCloseButton,
} from "@chakra-ui/react";
import type { FC, PropsWithChildren } from "react";

interface Props {
  modalHeader?: JSX.Element;
  modalBody: JSX.Element;
  modalFooter?: JSX.Element;
  borderColor?: string;
  onClose: () => void;
  isOpen: boolean;
  allowDismiss?: boolean;
  minWidth?: string | string[] | number | number[];
  showCloseButton?: boolean;
}

export const CommonModal: FC<PropsWithChildren<Props>> = ({
  modalHeader,
  borderColor,
  modalBody,
  modalFooter,
  onClose,
  isOpen,
  allowDismiss = true,
  minWidth,
  showCloseButton = false,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnEsc={allowDismiss} closeOnOverlayClick={allowDismiss}>
      <ModalOverlay />
      <ModalContent
        borderRadius={isMobile ? "0" : "xl"}
        minWidth={minWidth ? minWidth : ["90%", "40rem", "40rem"]}
        borderWidth="4px"
        borderColor={borderColor}>
        {modalHeader && (
          <ModalHeader bgColor="white" display="flex" flexDirection="column" padding={0} borderTopRadius={isMobile ? "0" : "lg"}>
            {modalHeader}
          </ModalHeader>
        )}
        {showCloseButton && <ModalCloseButton color={"gray.300"} />}
        <ModalBody p="0" paddingInline={"0"}>
          {modalBody}
        </ModalBody>
        {modalFooter && (
          <ModalFooter p="0" paddingInline={"0"}>
            {modalFooter}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
