import { Array } from "runtypes";
import { prependProxyBaseUrl, request } from "../request";
import { Question } from "./models/Question";
import type { QuestionKey } from "./models/QuestionKey";

const ADMIN_QUESTIONS_ENDPOINT = () => prependProxyBaseUrl(`/api/user-preferences/questions`);

export async function getAdminQuestions(options: { type: QuestionKey } = { type: "due_diligence_default_questions" }): Promise<Question[]> {
  const response = await request().url(ADMIN_QUESTIONS_ENDPOINT()).query({ type: options.type }).get().json();

  const validate = Array(Question).validate(response);

  if (validate.success) {
    return validate.value;
  } else {
    throw new Error(validate.message);
  }
}

export async function upsertAdminQuestion(
  question: Question,
  options: { type: QuestionKey } = { type: "due_diligence_default_questions" }
): Promise<void> {
  await request().url(ADMIN_QUESTIONS_ENDPOINT()).query({ type: options.type }).post(question).json();
}

export async function deleteAdminQuestion(
  questionId: string,
  options: { type: QuestionKey } = { type: "due_diligence_default_questions" }
): Promise<void> {
  await request().url(`${ADMIN_QUESTIONS_ENDPOINT()}/${questionId}`).query({ type: options.type }).delete().res();
}
