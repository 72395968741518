import { useContext } from "react";
import { GoogleApiProviderContext } from "screens/common/app";

/**
 * A hook which returns an instance of Google API when available.
 *
 * Components using this hook must be nested in a <GoogleApiProvider> component.
 */
export function useGoogleApi() {
  const { gapi } = useContext(GoogleApiProviderContext);

  return gapi;
}
