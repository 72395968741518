import React from "react";
import { Text, Stack, useColorModeValue } from "@chakra-ui/react";
import { LinkLogo } from "screens/common/components";
import { useExternalIntegrations } from "hooks";
import type { FunctionComponent } from "react";

interface Props {
  sourceIntegrationUrn: string;
}

export const VerifiedAIPanelAttribution: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  sourceIntegrationUrn,
}) => {
  const { getIntegration } = useExternalIntegrations(undefined, undefined, undefined, sourceIntegrationUrn);
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  return (
    <Stack direction="row" align={"center"}>
      <Text className="ch-qa-result-answer" fontSize="sm" fontWeight="normal" color={textColor}>
        Source:
      </Text>
      {getIntegration?.domain && <LinkLogo url={getIntegration?.domain} height="1.5rem" maxWidth="1.5rem" p="0" />}
      <Text className="ch-qa-result-answer" fontSize="sm" fontWeight="normal" color={textColor}>
        {getIntegration?.name || "unknown"}
      </Text>
    </Stack>
  );
};
