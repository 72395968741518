import type { ReactElement } from "react";
import React, { useCallback } from "react";
import type { Message } from "types/conversation";
import { AuthCell, ButtonCell, FileCell, ImageCell, ListCell, PlanStepsCell, TextCell, WorkflowSummaryCell } from ".";
import { CollectionCell } from "./buttons/CollectionCell";
import { MarkdownCell } from "./buttons/MarkdownCell";
import { CanvasCell } from "./buttons/CanvasCell";
import { SingleItemCell } from "./buttons/SingleItemCell";
import { JsonCell } from "./buttons/JsonCell";

export const useCellsForMessage = () => {
  return useCallback((message: Message, isLastMessage = false) => {
    const messageElements: ReactElement[] = [];
    // Text bubble
    if (message.content && message.content.trim().length > 0 && message.state !== "action_required") {
      messageElements.push(
        <TextCell
          message={message}
          key={`${message.id}-text`}
          isLastMessage={isLastMessage}
          acknowledgmentStatus={message.acknowledgmentStatus}
        />
      );
    }

    if (message.data) {
      // Message data (files, CharliUI, etc)
      message.data.forEach((datum) => {
        // Image
        if (datum.type === "file" && datum.body.file_id && datum.body.file_mime_type.startsWith("image")) {
          messageElements.push(<ImageCell message={message} datum={datum} key={`${message.id}-${datum.body.file_id}`} />);
        }

        // File
        if (datum.type === "file" && datum.body.file_id && !datum.body.file_mime_type.startsWith("image")) {
          messageElements.push(<FileCell message={message} datum={datum} key={`${message.id}-${datum.body.file_id}`} />);
        }

        // Plan
        if (datum.type === "plan" && datum.body.plan.length > 0) {
          messageElements.push(<PlanStepsCell message={message} datum={datum} key={`${message.id}-plan`} />);
        }

        if (datum.type === "workflow_summary") {
          const workflowsIds = datum.body.buttonElement.content.map((workflow) => workflow.workflowId);
          messageElements.push(
            <WorkflowSummaryCell message={message} workflowsIds={workflowsIds} key={`${message.id}-workflow-summary-cell`} />
          );
        }

        // List
        if (datum.type === "charli-ui" && datum.body.inlineListElement) {
          messageElements.push(<ListCell message={message} datum={datum} key={`${message.id}-list`} />);
        }

        // Button
        if (datum.type === "charli-ui" && datum.body.buttonElement) {
          messageElements.push(<ButtonCell message={message} datum={datum} key={`${message.id}-button`} />);
        }

        if (datum.type === "collection_button") {
          messageElements.push(<CollectionCell message={message} button={datum} key={`${message.id}-collection-button`} />);
        }

        if (datum.type === "canvas_button") {
          if (datum.body.metadataIds.length === 1) {
            messageElements.push(
              <SingleItemCell
                message={message}
                label={datum.body.label}
                metadataId={datum.body.metadataIds[0]}
                key={`${message.id}-${datum.body.label}-canvas-button`}
              />
            );
          } else {
            messageElements.push(
              <CanvasCell
                message={message}
                label={datum.body.label}
                metadataIds={datum.body.metadataIds}
                key={`${message.id}-${datum.body.label}-canvas-button`}
              />
            );
          }
        }

        if (datum.type === "markdown_button") {
          messageElements.push(<MarkdownCell message={message} button={datum} key={`${datum.body.buttonId}-markdown-button`} />);
        }

        if (datum.type === "json_button") {
          messageElements.push(<JsonCell message={message} button={datum} key={`${datum.body.buttonId}-json-button`} />);
        }
      });
    }

    if (message.state === "action_required") {
      if (message.content && message.content.trim().length > 0) {
        messageElements.push(
          <TextCell message={message} key={`${message.id}-text`} isLastMessage={isLastMessage} acknowledgmentStatus="acknowledged" />
        );
      } else {
        const authDatum = (() => {
          for (const value of message?.data ?? []) {
            if (value.type === "auth_request") {
              return value;
            }
          }
        })();

        if (authDatum) {
          messageElements.push(<AuthCell message={message} datum={authDatum} isLastMessage={isLastMessage} key={`${message.id}-auth`} />);
        }
      }
    }

    return messageElements;
  }, []);
};
