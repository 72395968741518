import React, { useMemo } from "react";
import { Box, Button } from "@chakra-ui/react";
import { DynamicForm } from "screens/common/components";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useButtonProps } from "hooks";
import { Wizard } from "react-use-wizard";
import type { IField } from "screens/common/components";
import type { TargetEntityFormValues, FormValues } from "./UpsertInformationExtractionConfig";
import * as yup from "yup";
import { useTargetEntities } from "hooks/useInformationExtractionConfig";
import { Occurrence } from "api/informationExtractionConfig/models/TargetEntity";
import capitalize from "lodash/capitalize";
import { useFormContext, useWatch } from "react-hook-form";
import { CategoriesSchema } from "../schemas/CategoriesSchema";
import { uniq } from "lodash";

interface IProps {
  id: string | null;
  onSubmit: (values: TargetEntityFormValues) => void;
  initialValues?: TargetEntityFormValues;
}

interface PanelProps extends IProps {
  isOpen: boolean;
  onClose: () => void;
}

const validationSchema = yup.object().shape({
  entityName: yup.string().required("Entity name is required"),
  entityType: yup.string().required("Entity type is required"),
});

export const UpsertTargetEntity = ({ id, initialValues, onSubmit }: IProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");
  const targetEntities = useTargetEntities();
  const { control } = useFormContext<FormValues>();

  const globalConfidenceThreshold = useWatch({ name: "confidenceThreshold", control });

  const fields: IField<TargetEntityFormValues>[] = useMemo(() => {
    return [
      {
        type: "text",
        label: "Entity Name",
        entity: "entityName",
        placeholder: "Add entity name",
        defaultValue: "",
      },
      {
        type: "multi-select-with-tags",
        label: "Entity Type",
        entity: "entityType",
        placeholder: "Add entity type",
        suggestions: uniq(targetEntities.flatMap((targetEntity) => targetEntity.entityType)),
      },
      {
        type: "switch",
        label: "Is Exact Matching",
        entity: "exactMatching",
        defaultValue: true,
        onHide: ({ entityType }) => !entityType?.toLowerCase().includes("categorical"),
      },
      {
        type: "textarea",
        label: "Keywords",
        entity: "keywords",
        defaultValue: "",
        placeholder: "Add keywords, separated by line breaks",
      },
      {
        type: "text",
        label: "Pattern",
        entity: "pattern",
        placeholder: "Add regex pattern",
        defaultValue: "",
      },
      {
        type: "json",
        label: "Categories",
        entity: "categories",
        defaultValue: JSON.stringify({}, null, 2),
        placeholder: "Add categories",
        jsonSchema: CategoriesSchema,
      },
      {
        type: "textarea",
        label: "Title Keywords",
        entity: "titleKeywords",
        defaultValue: "",
        placeholder: "Add title keywords, separated by line breaks",
      },
      {
        type: "textarea",
        label: "Negative Keywords",
        entity: "negativeKeywords",
        defaultValue: "",
        placeholder: "Add negative keywords, separated by line breaks",
      },
      {
        type: "select",
        label: "Occurrence",
        entity: "occurrence",
        options: Occurrence.alternatives.map((occurrence) => ({
          label: capitalize(occurrence.value.replaceAll(/_/g, " ")),
          value: occurrence.value,
        })),
        defaultValue: "auto",
      },
      {
        type: "number",
        label: "Confidence Threshold",
        entity: "confidenceThreshold",
        min: 0,
        max: 1,
        defaultValue: globalConfidenceThreshold,
      },
      {
        type: "switch",
        label: "Allow multiple values",
        entity: "allowMultipleValues",
        defaultValue: false,
      },
    ];
  }, [targetEntities, globalConfidenceThreshold]);

  const defaultValues: TargetEntityFormValues = useMemo(() => {
    return initialValues
      ? { ...initialValues, confidenceThreshold: initialValues.confidenceThreshold || globalConfidenceThreshold }
      : {
          entityName: "",
          entityType: "",
          exactMatching: true,
          keywords: "",
          pattern: "",
          categories: JSON.stringify({}, null, 2),
          allowMultipleValues: false,
          occurrence: "auto",
          titleKeywords: "",
          confidenceThreshold: globalConfidenceThreshold,
          negativeKeywords: "",
        };
  }, [initialValues, globalConfidenceThreshold]);

  return (
    <Box pl=".5rem">
      <DynamicForm<TargetEntityFormValues>
        title={`${id ? "Update" : "Create"} target entity`}
        fields={fields}
        defaultValues={defaultValues}
        formId="upsert-target-entity-form"
        onSubmit={(data) => onSubmit(data)}
        validationSchema={validationSchema}
      />
      <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
        <Button disabled={false} {...commonButtonProps} type="submit" form="upsert-target-entity-form">
          Save Target Entity
        </Button>
      </Box>
    </Box>
  );
};

export const UpsertTargetEntityPanel = (props: PanelProps) => {
  const { onClose, isOpen, ...rest } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${rest.id ? "Update" : "Create"} target entity`}>
      <Wizard>
        <PanelStep>{isOpen && <UpsertTargetEntity {...rest} />}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
