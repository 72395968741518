import type { FunctionComponent } from "react";
import React from "react";
import { OptionsMenuItem } from "../../components/OptionsMenuItem";

interface Props {
  isDisabled?: boolean;
  onClick: () => void;
}

export const ProjectMoveButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  isDisabled = false,
  onClick,
}) => {
  return (
    <OptionsMenuItem
      isDisabled={isDisabled}
      menuLabel="Move to Portfolio"
      className="ch-move-project"
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    />
  );
};
