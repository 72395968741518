import { AttachmentIcon, WarningIcon } from "@chakra-ui/icons";
import { Badge, IconButton, Box, Tooltip, Stack } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useContext } from "react";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { InputBarDropzoneContext } from "./InputBarDropzoneContext";

type Size = "md" | "lg";

interface Props {
  size: Size;
}

export const InputBarAttachmentButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ size }) => {
  return <FilesAttachmentButton size={size} />;
};

const FilesAttachmentButton = ({ size }: { size: Size }) => {
  const { files, openFilesModal, isUploadInProgress, didUploadFail } = useContext(InputBarDropzoneContext);

  return (
    <React.Fragment>
      {isUploadInProgress && (
        <Box position="absolute">
          <TypingIndicator size="small" />
        </Box>
      )}
      <Tooltip
        label={didUploadFail ? "Upload failed" : isUploadInProgress ? "Uploading..." : ""}
        aria-label="file"
        visibility={files.length > 0 && (didUploadFail || isUploadInProgress) ? "unset" : "hidden"}>
        <Stack direction="row" spacing={0}>
          <IconButton
            paddingInline={"5px"}
            minWidth={"unset"}
            aria-label="Attach file"
            size={size}
            icon={
              didUploadFail ? (
                <WarningIcon width={size === "lg" ? "1.5rem" : "1.2rem"} height={size === "lg" ? "1.5rem" : "1.2rem"} color="gray.500" />
              ) : (
                <AttachmentIcon width={size === "lg" ? "1.5rem" : "1.2rem"} height={size === "lg" ? "1.5rem" : "1.2rem"} color="gray.500" />
              )
            }
            _hover={{}}
            _active={{}}
            opacity={isUploadInProgress ? 0 : 1}
            backgroundColor="transparent"
            onClick={() => {
              openFilesModal();
            }}
          />
          {!isUploadInProgress && !didUploadFail && files.length > 0 && (
            <Badge
              borderRadius={"full"}
              variant="solid"
              colorScheme="gray"
              backgroundColor="gray.500"
              bottom={"1.3rem"}
              marginLeft="-1rem"
              boxSize="1.2rem"
              textAlign="center"
              fontSize=".8rem"
              zIndex={1}
              pointerEvents="none">
              {files.length}
            </Badge>
          )}
        </Stack>
      </Tooltip>
    </React.Fragment>
  );
};
