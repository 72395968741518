import React, { useMemo } from "react";
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, useToast } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useButtonProps } from "hooks";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { downloadSystemPreferences, updateSystemPreference } from "state/systemPreference/operations";
import type { Preference } from "api/systemPreferences/models/Preference";
import { JSONEditor } from "screens/common/components";
import { PreferenceValueSchema } from "../schemas/PreferenceValueSchema";
import { Value } from "api/systemPreferences/models/Value";
import { useSystemPreferences } from "hooks/useSystemPreferences";

interface IProps {
  preference: Preference | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
}

export interface FormValues {
  key: string;
  value: string;
}

const isValidValue = (val: string | undefined) => {
  try {
    if (!val) {
      return false;
    }

    const parsedValue = JSON.parse(val);

    if (!parsedValue || parsedValue === "") {
      return false;
    }

    return Value.validate(parsedValue).success;
  } catch (e) {
    return false;
  }
};

export const PreferenceUpsertModal = (props: IProps) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const commonButtonProps = useButtonProps("sm", "primary");
  const systemPreferences = useSystemPreferences();

  const { preference, isOpen, onClose, isLoading } = props;
  const isUpdate = !!preference;

  const keysWithoutCurrentPreference = useMemo(
    () => systemPreferences.map((pref) => pref.key).filter((key) => key !== preference?.key),
    [systemPreferences, preference?.key]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        key: yup.string().required("Key is required").notOneOf(keysWithoutCurrentPreference, "Key already exists. Type a different one."),
        value: yup
          .string()
          .required("Value is required")
          .test("is-valid-value", "Invalid value. Check on the schema definition.", (val) => isValidValue(val)),
      }),
    [keysWithoutCurrentPreference]
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    // TODO: form validation with yup
    resolver: yupResolver(validationSchema),
    defaultValues: preference
      ? {
          key: preference.key,
          value: JSON.stringify(preference.value),
        }
      : {
          key: "",
          value: '""',
        },
  });

  const onSubmit = async (formValues: FormValues) => {
    const { key, value } = formValues;
    const response = await dispatch(updateSystemPreference({ key: key, value: JSON.parse(value) }));

    if (!response.payload) {
      const errorMessage = (response as { error: { message: string } }).error?.message;

      toast({
        title: "System preference error",
        description: errorMessage,
        status: "error",
        duration: 10000,
        isClosable: true,
      });

      return;
    }

    toast({
      title: "Preference configuration",
      description: "Successful operation",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    dispatch(downloadSystemPreferences());
    onClose();
  };

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${isUpdate ? "Update" : "Create"} preference`}>
      <Wizard>
        <PanelStep>
          <Box pl=".5rem">
            <form id="upsert-preference-form" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                render={({ field }) => (
                  <FormControl pb="1rem" isDisabled={isUpdate || isLoading} isInvalid={errors[field.name] !== undefined}>
                    <FormLabel htmlFor={field.name}>Key</FormLabel>
                    <Input
                      {...field}
                      onChange={(evt) =>
                        field.onChange(
                          evt.target.value
                            .trim()
                            .normalize()
                            .replace(/[^\w-]+/g, "_")
                            .toLowerCase()
                        )
                      }
                      placeholder="Write a key"
                    />
                    {errors[field.name]?.message && <FormErrorMessage>{errors[field.name]?.message}</FormErrorMessage>}
                  </FormControl>
                )}
                name="key"
                control={control}
              />

              <Controller
                render={({ field }) => (
                  <FormControl pb="1rem" isDisabled={isLoading} isInvalid={errors[field.name] !== undefined}>
                    <FormLabel htmlFor={field.name}>Value</FormLabel>
                    <JSONEditor jsonSchema={PreferenceValueSchema} value={field.value} onChange={(newVal) => field.onChange(newVal)} />
                    {errors[field.name]?.message && <FormErrorMessage>{errors[field.name]?.message}</FormErrorMessage>}
                  </FormControl>
                )}
                name="value"
                control={control}
              />

              {/* <FormTextField control={control} errors={errors} keyField="value" label="Value" onUpdate={null} disabled={isLoading} /> */}
            </form>

            <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
              <Button {...commonButtonProps} type="submit" form="upsert-preference-form" isLoading={isLoading}>
                Save preference
              </Button>
            </Box>
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
