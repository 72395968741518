import {
  Box,
  Input,
  Stack,
  Divider,
  Text,
  useColorModeValue,
  Button,
  Center,
  Icon,
  Select,
  HStack,
  UnorderedList,
  ListItem,
  Flex,
} from "@chakra-ui/react";
import React, { useState, useCallback, useMemo } from "react";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { useButtonProps } from "hooks";
import Dropzone from "react-dropzone";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { IoMdHelpCircleOutline } from "react-icons/io";
import capitalize from "lodash/capitalize";
import { Popover } from "react-tiny-popover";
import { useBillingPlans } from "hooks/useBillingPlans";
import type { Plan } from "types/billingPlans/Plan";

interface IProps {
  showRole?: boolean;
  onSubmitNewUsers: (users: User[]) => Promise<boolean>;
  isLoading?: boolean;
}

const Roles = ["member", "admin"] as const;
type Role = typeof Roles[number] | "";

// Find plan with code "free" or first plan in the list
const getDefaultPlan = (plans: Plan[]) => {
  const defaultPlan = plans.find((plan) => plan.code === "free");
  return defaultPlan?.code ?? plans[0]?.code ?? "";
};

export type User = {
  email: string;
  firstName: string;
  lastName: string;
  planId: string;
  role: Role;
};

const defaultUser: User = {
  email: "",
  firstName: "",
  lastName: "",
  planId: "",
  role: "",
};

export const AddNewUsers = ({ showRole = true, isLoading = false, onSubmitNewUsers }: IProps) => {
  const [hasDroppedFile, setHasDroppedFile] = useState<boolean>(false);
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const commonButtonProps = useButtonProps("sm", "primary");
  const billingPlans = useBillingPlans();

  const defaultPlan = useMemo(() => getDefaultPlan(billingPlans), [billingPlans]);

  const [newUsers, setNewUsers] = useState<User[]>([{ ...defaultUser, planId: defaultPlan }]);

  const isValidUser = useCallback(
    (user: User) =>
      !!user.email && user.email.length > 0 && !!user.firstName && user.firstName.length > 0 && !!user.lastName && user.lastName.length > 0,
    []
  );

  const handleSubmitNewUsers = async () => {
    const isSuccess = await onSubmitNewUsers(newUsers);

    if (isSuccess) {
      setHasDroppedFile(false);
      setNewUsers([{ ...defaultUser, planId: defaultPlan }]);
    }
  };

  const csvFileToArray = (fileData: string) => {
    const csvRows = fileData.split("\n");

    const parsedRecords = csvRows.map((row) => {
      const values = row.replace(/\r/g, "").split(",");
      if (values.length < 4) {
        return {
          firstName: "",
          lastName: "",
          email: "",
          role: "" as Role,
          planId: defaultPlan,
        };
      }

      const [firstName, lastName, email, role] = values;

      return {
        firstName: firstName ?? "n/a",
        lastName: lastName ?? "n/a",
        email: email ?? "n/a",
        role: (role === "admin" || role === "member" ? role : "") as Role,
        planId: defaultPlan,
      };
    });

    setNewUsers([
      ...newUsers.filter(isValidUser),
      ...parsedRecords.filter((record) => {
        return Object.keys(record).length !== 0;
      }),
    ]);
  };

  const processFiles = (files: File[]) => {
    for (const file of files) {
      setHasDroppedFile(true);
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const records = reader.result as string;
        csvFileToArray(records);
      };
    }
  };

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const values = [...newUsers];
    values[index][event.target.name] = event.target.value;
    setNewUsers(values);
  };

  return (
    <Stack spacing="1.5rem">
      <Divider />
      <SectionHeader title="Add new users" />
      <Flex justifyContent={"space-between"}>
        <Text fontSize="sm" color={textColor} flex={1}>
          First Name
        </Text>
        <Text fontSize="sm" color={textColor} flex={1}>
          Last Name
        </Text>
        <Text fontSize="sm" color={textColor} flex={1}>
          Email Address
        </Text>
        <Text fontSize="sm" color={textColor} flex={1}>
          Billing Plan
        </Text>
        {showRole && (
          <Text fontSize="sm" color={textColor} flex={1}>
            Role
          </Text>
        )}
        <Box width={50}></Box>
      </Flex>

      {newUsers.length > 0 &&
        newUsers.map((user, index) => {
          return (
            <Flex alignItems={"center"} justifyContent={"space-between"} key={index}>
              <Box flex={1}>
                <Input
                  width={"95%"}
                  disabled={isLoading}
                  value={user.firstName}
                  name="firstName"
                  size="sm"
                  rounded="10px"
                  fontSize="sm"
                  color={textColor}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </Box>

              <Box flex={1}>
                <Input
                  width={"95%"}
                  disabled={isLoading}
                  value={user.lastName}
                  name="lastName"
                  size="sm"
                  rounded="10px"
                  fontSize="sm"
                  color={textColor}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </Box>

              <Box flex={1}>
                <Input
                  width={"95%"}
                  disabled={isLoading}
                  value={user.email}
                  name="email"
                  size="sm"
                  rounded="10px"
                  fontSize="sm"
                  color={textColor}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </Box>

              <Box flex={1}>
                <Select
                  width={"95%"}
                  value={user.planId}
                  color={textColor}
                  name="planId"
                  size="sm"
                  onChange={(event) => handleInputChange(index, event)}>
                  {billingPlans.map((plan) => (
                    <option key={plan.code} value={plan.code}>
                      {plan.name}
                    </option>
                  ))}
                </Select>
              </Box>

              {showRole && (
                <Box flex={1}>
                  <Select
                    width={"95%"}
                    value={user.role}
                    color={textColor}
                    name="role"
                    size="sm"
                    onChange={(event) => handleInputChange(index, event)}>
                    <option value=""></option>
                    {Roles.map((role) => (
                      <option key={role} value={role}>
                        {capitalize(role)}
                      </option>
                    ))}
                  </Select>
                </Box>
              )}

              <Box width={50}>
                {index !== 0 && (
                  <Icon
                    pt="4px"
                    cursor="pointer"
                    as={AiOutlineDelete}
                    color={buttonColor}
                    boxSize="1.5rem"
                    _hover={{ color: buttonHoverColor }}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }
                      setNewUsers(newUsers.filter((_, i) => i !== index));
                    }}
                  />
                )}
              </Box>
            </Flex>
          );
        })}

      <Stack mt="1rem!important" direction="row" justifyContent={"flex-end"} width="100%">
        <Icon
          cursor="pointer"
          as={AiOutlinePlus}
          color={buttonColor}
          boxSize="1.5rem"
          _hover={{ color: buttonHoverColor }}
          onClick={() => {
            if (isLoading) {
              return;
            }
            setNewUsers([...newUsers, { firstName: "", lastName: "", email: "", role: "", planId: defaultPlan }]);
          }}
        />
      </Stack>
      {!hasDroppedFile && (
        <Dropzone noDragEventsBubbling onDrop={processFiles} accept=".csv">
          {({ getRootProps, isDragActive }) => (
            <Box height={"10rem"} width="100%" {...getRootProps()} borderRadius="md" border="solid #E2E8F0 1px">
              <Box
                height={"100%"}
                borderRadius="md"
                border="dashed #1251AD 1px"
                display={isDragActive ? "block" : "none"}
                pointerEvents="none"
                p="4"
              />
              <Center height="100%" display={isDragActive ? "none" : "flex"}>
                <Box>
                  <Popover
                    isOpen={false}
                    content={() => (
                      <>
                        <UnorderedList>
                          <ListItem fontSize="sm" color={textColor}>
                            File must be in CSV format, with .csv extension.
                          </ListItem>
                          <ListItem fontSize="sm" color={textColor}>
                            File must not have a header row.
                          </ListItem>
                          <ListItem fontSize="sm" color={textColor}>
                            File rows must have the following fields in the order listed:
                          </ListItem>
                          <ListItem fontSize="sm" color={textColor}>
                            First name, Last name, Email address, Role.
                          </ListItem>
                          <ListItem fontSize="sm" color={textColor}>
                            Accepted roles are member and admin.
                          </ListItem>
                        </UnorderedList>
                      </>
                    )}>
                    <HStack>
                      <Text fontSize="sm" color={textColor}>
                        Drop Bulk Import User File Here
                      </Text>
                      <Box marginInline={"0!important"} lineHeight="0" pl=".5rem">
                        <Icon cursor={"pointer"} color={textColor} as={IoMdHelpCircleOutline} />
                      </Box>
                    </HStack>
                  </Popover>
                </Box>
              </Center>
            </Box>
          )}
        </Dropzone>
      )}

      <Stack direction="row" justifyContent={"space-between"}>
        <Button
          width="6rem"
          disabled={!isValidUser(newUsers[0]) || isLoading}
          {...commonButtonProps}
          size="sm"
          className={`ch-clear-upload-user-btn`}
          onClick={() => {
            setNewUsers([{ firstName: "", lastName: "", email: "", role: "", planId: defaultPlan }]);
            setHasDroppedFile(false);
          }}>
          Clear Users
        </Button>
        <Button
          width="6rem"
          disabled={!isValidUser(newUsers[0]) || isLoading}
          {...commonButtonProps}
          size="sm"
          className={`ch-new-user-btn`}
          onClick={handleSubmitNewUsers}>
          {`Add ${isValidUser(newUsers[0]) ? newUsers.filter(isValidUser).length : ""} User${
            isValidUser(newUsers[0]) && newUsers.filter(isValidUser).length > 0 ? "s" : ""
          }`}
        </Button>
      </Stack>
      {newUsers.filter(isValidUser).length > 0 && newUsers.length > newUsers.filter(isValidUser).length && (
        <Stack mt="0.5rem!important" direction="row" justifyContent={"flex-end"} width="100%">
          <Text fontSize="sm" color={textColor}>
            {`Only ${newUsers.filter(isValidUser).length} of ${newUsers.length} users are valid. Please ensure all fields are filled out.`}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};
