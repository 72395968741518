import React, { useContext, useEffect, useMemo, useState } from "react";
import { ConversationContext } from "screens/thread/ConversationContext";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Switch,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { useButtonProps, useContentDetails, useConversation, useEntitlements, useItemSelector } from "hooks";
import { isAcceptedSummaryAVTypeByExtension } from "screens/common/modal/utils";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ToastMessageContent } from "screens/common/components";
import { TypingIndicator } from "screens/thread/components/cells/components";
import marked from "marked";

export const SummarizeButton = () => {
  const [summarizeSize, setSummarizeSize] = useState("long");
  const [summarizePages, setSummarizePages] = useState("2");
  const dispatch = useDispatch();
  const { focusedItem } = useItemSelector();
  const { summarization: hasSummarizationEntitlement } = useEntitlements();
  const { isSummarizePanelOpen, onSummarizePanelClose } = useAddToCharliContext();
  const { onConversationOpen, conversationId, contentId } = useContext(ConversationContext);
  const summaryState = useConversation(contentId, ["summarize", "generate_summary", "cancel"]);
  const itemContent = useContentDetails(contentId);
  const parsedMarkdown = marked(itemContent?.summaryDetails?.data[0].value || "");
  const commonButtonProps = useButtonProps("sm", "secondary");
  const color = useColorModeValue("gray.700", "gray.200");
  const isWide = useBreakpointValue({ base: false, md: true });

  const toast = useToast();

  useEffect(() => {
    setSummarizeSize("long");
    setSummarizePages("2");
  }, [isSummarizePanelOpen]);

  const summarize = () => {
    dispatch(
      sendMessage({
        conversationId: contentId,
        message: "Summarize this resource",
        intent: `/${summarizeSize === "comprehensive" ? "generate_comprehensive_summary" : "generate_summary"}`,
        entities: getSummaryEntities,
        datum: [
          {
            type: "update_data_callback",
            includeInResponse: true,
            body: { items: [{ type: "collection", id: conversationId }] },
          },
        ],
      })
    );
    onSummarizePanelClose();
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`No problem, I'll work on summarizing this now. You can open the conversation I started about it by clicking this message`}
          onClick={() => {
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const getSummaryEntities = useMemo(() => {
    const entities: {
      entity: string;
      value: string;
    }[] = [];

    if (focusedItem) {
      entities.push({ entity: focusedItem.type, value: focusedItem.id });
    }
    const isAVextension = isAcceptedSummaryAVTypeByExtension(focusedItem?.extension);
    if (isAVextension) {
      entities.push({ entity: "document_category_av", value: "true" });
    }

    if (summarizeSize === "comprehensive") {
      entities.push({ entity: "summary_num_pages ", value: summarizePages });
    } else {
      entities.push({ entity: "min_summary_length", value: "450" });
      entities.push({ entity: "max_summary_length", value: "512" });
      entities.push({ entity: "summarize_full_text", value: "True" });
    }
    return entities;
  }, [focusedItem, summarizePages, summarizeSize]);

  const emptyArray = useMemo(() => Array.from({ length: 100 }), []);

  return (
    <>
      {summaryState.conversationState === "in_progress" ? (
        <Stack direction="row" align="center" justifyContent={"flex-end"} height="100%">
          <Text fontStyle="italic" alignSelf="center" fontSize="xs" color={color}>
            Generating summary
          </Text>
          <Box px=".2rem">
            <TypingIndicator size="small" />
          </Box>
        </Stack>
      ) : (
        <Stack {...(isWide && focusedItem?.extension !== "pdf" && { direction: "row" })} spacing=".5rem" justifyContent="space-between">
          {summaryState.conversationState !== "error" && focusedItem?.extension === "pdf" && (
            <Stack direction="row" spacing="0" width="100%">
              <FormControl width="unset" display="flex" alignItems="center">
                <Switch
                  size="sm"
                  isChecked={summarizeSize === "comprehensive"}
                  onChange={() => setSummarizeSize(summarizeSize === "comprehensive" ? "" : "comprehensive")}
                />
                <FormLabel isTruncated fontSize="xs" htmlFor="ch-settings-opt-in-marketing" ml="0.5rem" mb="0">
                  Comprehensive Summary
                </FormLabel>
              </FormControl>
              <Select
                width="100%"
                maxWidth={"6rem"}
                size="sm"
                borderRadius="md"
                value={summarizePages}
                onChange={(event) => setSummarizePages(event.target.value)}>
                {emptyArray.map((_, index) => (
                  <option key={index + 1} value={String(index + 1)}>
                    {index + 1} Pages
                  </option>
                ))}
              </Select>
            </Stack>
          )}
          {summaryState.conversationState === "error" && (
            <Text fontSize="xs" alignSelf="center">
              Summarize didn't work,{" "}
              <Button
                size="xs"
                variant="link"
                onClick={() => {
                  onConversationOpen();
                }}>
                click here
              </Button>{" "}
              for details, or try again.
            </Text>
          )}
          <Button
            width={focusedItem?.extension !== "pdf" ? "10rem" : "13rem"}
            className="ch-generate-summary-button"
            isDisabled={!hasSummarizationEntitlement}
            {...commonButtonProps}
            onClick={() => {
              summarize();
            }}>
            {parsedMarkdown.length > 0 ? "Generate New Summary" : "Generate Summary"}
          </Button>
        </Stack>
      )}
    </>
  );
};
