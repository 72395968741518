import { Box, Stack } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext, useState, useEffect } from "react";
import React from "react";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import MarkdownEditor from "screens/content/common/notes/MarkdownEditor";
import { useAddToCharliContext } from "./AddToCharliProvider";
import { CollectionSelector } from "screens/landing/components/CollectionSelector";
import { IntegrationSelector } from "screens/landing/components/IntegrationSelector";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";
import { AddTagDetails } from "./AddTagDetails";

interface Props {
  collectionEntitySet?: boolean;
}

export const AddTagNoteDetails: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionEntitySet = false,
}) => {
  const { requestEntities, setRequestEntities } = useContext(ConversationContext);
  const { linkText } = useAddToCharliContext();
  const [noteText, setNotesText] = useState(getRequestValue("note", requestEntities) || "");

  const updateRequestEntity = (entityName: string, entityValue: string) => {
    updateBatchRequestEntities([{ entity: entityName, value: entityValue, source: "tag-note-inputs" }], setRequestEntities);
  };

  useEffect(() => {
    if (noteText) {
      updateRequestEntity("note", noteText.trim() === "<p></p>" ? "" : noteText.trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteText]);

  return (
    <Stack spacing="1.5rem" overflowY="auto" flexShrink={1}>
      {collectionEntitySet && (
        <Box>
          <SectionHeader title="Add to a Project or Collection (optional)" />
          <CollectionSelector collectionType="all" />
        </Box>
      )}
      <AddTagDetails />
      <Box>
        <SectionHeader title="Notes" />
        <MarkdownEditor setNoteText={setNotesText} placeholderText="Add a note about this resource" />
      </Box>
      {!linkText && (
        <Box>
          <SectionHeader title="Cloud Storage" />
          <IntegrationSelector categoryKey="integrations_storage_provider" category="Cloud Storage" />
        </Box>
      )}
    </Stack>
  );
};
