import type { OrganizationPreference } from "api/organizationsPreferences/models/Preference";
import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadOrganizationsPreferences } from "state/organizationPreference/operations";
import type { RootState } from "state/rootReducer";

export type LocalOrganizationPreference = Omit<OrganizationPreference, "entityId" | "preferenceType" | "updatedAt" | "updatedBy"> & {
  lastUpdatedDate?: string | Date;
  lastUpdatedByUserName?: string;
  organizationId: string;
};

const transformToLocalPreference = (preference: OrganizationPreference): LocalOrganizationPreference => {
  const { entityId, preferenceType, updatedAt, updatedBy, ...rest } = preference;

  return {
    ...rest,
    organizationId: entityId,
    lastUpdatedDate: updatedAt ? new Date(updatedAt) : undefined,
    lastUpdatedByUserName: updatedBy,
  };
};

export const useOrganizationsPreferences = (): LocalOrganizationPreference[] => {
  const { preferencesByKey, preferencesKeys } = useSelector((state: RootState) => state.organizationsPreference);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(downloadOrganizationsPreferences());
  }, [dispatch]);

  return useMemo(
    () => preferencesKeys.map((key) => transformToLocalPreference(preferencesByKey[key])),
    [preferencesKeys, preferencesByKey]
  );
};

export const useOrganizationsPreferencesIsLoading = (): boolean => {
  const { isLoading } = useSelector((state: RootState) => state.organizationsPreference);

  return isLoading;
};

export const useOrganizationPreference = (key: string | undefined): LocalOrganizationPreference | undefined => {
  const preference = useSelector((state: RootState) => (key ? state.organizationsPreference.preferencesByKey[key] : undefined));

  return useMemo(() => (preference ? transformToLocalPreference(preference) : undefined), [preference]);
};
