import { Box, Button, Text, Tooltip } from "@chakra-ui/react";
import { useButtonProps, useConfigMap, useGetViewConfig } from "hooks";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { AddIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useMaxAllowedProjectsReached } from "hooks/useStats";

interface Props {
  collectionType: string;
  buttonLabel?: string;
}

export const CollectionAddNewButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionType = "research",
  buttonLabel,
}) => {
  const {
    setIsNewMenuAction,
    setSelectedCollection,
    onAddNewProjectPanelOpen,
    setShowContentList,
    setShouldCreateNewCollection,
    onAddToCollectionModalOpen,
  } = useAddToCharliContext();
  const commonButtonProps = useButtonProps("sm", "primary");
  const { pathname } = useLocation();
  const isProject = pathname.includes("project");
  const route = pathname.split("/").slice(isProject ? 2 : 1, isProject ? 3 : 2)[0];
  const configMap = useConfigMap();
  const buttonLabelConfig = useGetViewConfig("title", collectionType || route || "", configMap);
  const maxAllowedProjects = useGetViewConfig("maxActiveProjectsAllowed", collectionType || route || "", configMap);
  const { setConversationId } = useContext(ConversationContext);
  const hasExceededMaxProjects = useMaxAllowedProjectsReached(collectionType);

  const onButtonAddClick = () => {
    setConversationId(uuid());
    setSelectedCollection(undefined);
    switch (route) {
      case "library":
        setIsNewMenuAction("library");
        setSelectedCollection(undefined);
        setShouldCreateNewCollection(true);
        setShowContentList(true);
        onAddToCollectionModalOpen();
        return;
      default:
        onAddNewProjectPanelOpen();
        return;
    }
  };

  return (
    <Tooltip
      label={
        hasExceededMaxProjects
          ? `You can only have ${maxAllowedProjects} active ${collectionType?.replace(/_/g, " ")} projects at a time`
          : ""
      }>
      <Box>
        <Button
          isDisabled={hasExceededMaxProjects}
          {...commonButtonProps}
          width="100%"
          className={`ch-new-collection-btn`}
          onClick={onButtonAddClick}>
          <AddIcon boxSize=".5rem" />
          <Text pl=".5rem">{`New ${buttonLabel ? buttonLabel : buttonLabelConfig}`}</Text>
        </Button>
      </Box>
    </Tooltip>
  );
};
