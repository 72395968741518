import { Record, String } from "runtypes";
import type { Static } from "runtypes";

export const ExternalLink = Record({
  thumbnail: String,
  link: String,
  alias: String,
});

export type ExternalLink = Static<typeof ExternalLink>;
