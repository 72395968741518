import React, { useEffect, useState } from "react";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WorkflowFetchError } from "api/workflows/models/WorkflowFetchError";
import { getWorkflows } from "api/workflows";
import { WorkflowPanel as WorkflowPanelV2 } from "screens/common/components/WorkflowSummary/v2/WorkflowPanel";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import { useFeatureFlags } from "hooks";
import { WorkflowPanel } from "./WorkflowPanel";

export const WorkflowPanelAdmin = (props: { origin?: string }) => {
  const { workflowId: workflowIdParam } = useParams<{ workflowId: string }>();
  const { search } = useLocation();
  const { pathname } = useLocation();
  const route = pathname.substring(0, pathname.lastIndexOf("/"));

  const { workflow_summary_2: hasWorkflowSummaryV2 } = useFeatureFlags();

  const navigate = useNavigate();
  const { isWorkflow, isMessageId, onWorkflowPanelClose, onWorkflowPanelOpen, isWorkflowPanelOpen, setWorkflow, setWorkflowSummary } =
    useWorkflowContext();
  const { isContentViewOpen } = useAddToCharliContext();
  const [isLoadingWorkflow, setIsLoadingWorkflow] = useState(false);

  useEffect(() => {
    if (!isWorkflowPanelOpen) {
      setWorkflow();
    }
  }, [isWorkflowPanelOpen, setWorkflow]);

  useEffect(() => {
    if (!workflowIdParam) {
      return;
    }

    onWorkflowPanelOpen();

    setIsLoadingWorkflow(true);
    getWorkflows([workflowIdParam])
      .then(([workflow]) => {
        const isAWorkflow = !WorkflowFetchError.guard(workflow);

        if (isAWorkflow) {
          setWorkflow(workflow);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoadingWorkflow(false);
      });
  }, [onWorkflowPanelOpen, setWorkflow, workflowIdParam]);

  return hasWorkflowSummaryV2 ? (
    <WorkflowPanelV2
      isOpen={isWorkflowPanelOpen}
      onClose={() => {
        setWorkflow();
        setWorkflowSummary([]);

        onWorkflowPanelClose();
        navigate(`${route}${search}`);
      }}
      workflow={isWorkflow}
      isContentViewOpen={isContentViewOpen}
      isLoadingWorkflow={isLoadingWorkflow}
      isMessageId={isMessageId}
    />
  ) : (
    <WorkflowPanel
      isOpen={isWorkflowPanelOpen}
      onClose={() => {
        onWorkflowPanelClose();
        navigate(`${route}${search}`);
      }}
      workflow={isWorkflow}
      isContentViewOpen={isContentViewOpen}
      isLoadingWorkflow={isLoadingWorkflow}
      isMessageId={isMessageId}
    />
  );
};
