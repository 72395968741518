import { Array, Record, String } from "runtypes";
import type { Static } from "runtypes";

export const GetWorkflowSummary = Array(
  Record({
    status: String,
    intent: String,
    childWorkflowCount: String,
    childWorkflowIds: Array(String),
  })
);

export type GetWorkflowSummary = Static<typeof GetWorkflowSummary>;
