import type { Static } from "runtypes";
import { InstanceOf, Number, Record, String, Union } from "runtypes";
import { DetailSectionDataAction } from "./DetailSectionDataAction";
import { TableContent } from "./TableContent";

export const DetailSectionData = Record({
  id: String.nullable().optional(),
  label: String.nullable().optional(),
  summary: String.nullable().optional(),
  value: Union(String, Number, InstanceOf(Date), TableContent, Record({})).nullable().optional(),
  context: String.nullable().optional(),
  type: String,
  entity: String.nullable().optional(),
  entityName: String.nullable().optional(),
  confidence: Number.nullable().optional(),
  action: DetailSectionDataAction.nullable().optional(),
});

export type DetailSectionData = Static<typeof DetailSectionData>;
