import { Stack, Image, HStack, useBreakpointValue, Box, useToast, Text, Center } from "@chakra-ui/react";
import type { PlanName } from "api/subscription/models/PlanName";
import { useFeatureFlags, useUserProfile } from "hooks";
import React from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import { SubscribeForm } from "./SubscribeForm";
import { ToastMessageContent } from "screens/common/components";
import { subscribeToPlan, updateCustomerDetails } from "api/subscription";
import { downloadSubscriptionDetails } from "state/subscription/operations";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useCopyValue } from "hooks/useCopies";
import { SubscriptionUpgradeFeaturesHeader } from "./SubscriptionUpgradeFeaturesHeader";

export interface Feature {
  name: string;
  trial: boolean | string;
  business: boolean | string;
}

export const SubscriptionUpgradeConfirm = ({ upgradePlan = "business" }: { upgradePlan: PlanName }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { onSubscriptionModalClose } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { id: userId } = useUserProfile();
  const copySubscriptionPlan = useCopyValue("copy_subscription_plan");
  const { enable_credit_card_processing: isCreditCardProcessingEnabled, enable_new_pricing: showNewPricing } = useFeatureFlags();

  const onSubscribeFormSubmit = async (fields: {
    firstName: string;
    lastName: string;
    organization: string;
    city: string;
    address: string;
    address2: string;
    country: string;
    state: string;
    postcode: string;
    acceptTerms: boolean;
    promotionCode?: string;
    creditCardToken?: string;
    chargifyGatewayHandle?: string;
  }) => {
    const {
      firstName,
      lastName,
      address,
      address2,
      country,
      state,
      postcode,
      city,
      promotionCode,
      creditCardToken,
      chargifyGatewayHandle,
    } = fields;

    await updateCustomerDetails(String(userId), {
      firstName,
      lastName,
      address,
      address2,
      country,
      state,
      city,
      zip: postcode,
    });

    const promoCode = isEmpty(promotionCode) ? undefined : promotionCode;
    const creditCardDetails = isCreditCardProcessingEnabled
      ? {
          token: creditCardToken,
          gatewayHandle: chargifyGatewayHandle,
        }
      : undefined;

    try {
      await subscribeToPlan(String(userId), copySubscriptionPlan, promoCode, creditCardDetails);
    } catch (error) {
      toast({
        title: "Upgrade failed",
        description: "We apologize, we are unable upgrade your subscription at this time. Please contact support or try again later",
        status: "error",
        duration: 10000,
        isClosable: true,
        position: "top-right",
      });
      return;
    } finally {
      dispatch(downloadSubscriptionDetails());
      onSubscriptionModalClose();
    }

    toast.closeAll();
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={"Thank you for confirming the upgrade, I will send you a follow-up email shortly."}
          onClick={() => {
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <Stack justifyContent={"space-between"} height="100%" minHeight={showNewPricing ? "unset" : "35rem"} spacing="0" width="100%">
      <Stack spacing="0" height="100%" justifyContent={"space-between"}>
        {showNewPricing ? (
          <Stack height="100%" spacing="1rem" bgColor="primary.default" py="2rem" color="white">
            <Center px={isMobile ? "1rem" : "2rem"}>
              <Text fontWeight="semibold" fontSize="xl" textAlign={"center"}>
                Enter your billing and credit card information to upgrade to {upgradePlan}
              </Text>
            </Center>
          </Stack>
        ) : (
          <SubscriptionUpgradeFeaturesHeader title="Step 2 of 2: Upgrade To Professional" />
        )}
        <Box height="100%" p={isMobile ? "0.5rem" : "2rem"} pt="2rem">
          <SubscribeForm onSubmit={onSubscribeFormSubmit} />
        </Box>
        {!isMobile && (
          <HStack position="absolute" bottom="2rem" right="2rem" align={"center"} spacing="1rem">
            <Image src={soc2logo} height="2.7rem" />
            <Image src={verifiedShield} height="2.5rem" />
          </HStack>
        )}
      </Stack>
    </Stack>
  );
};
