import React from "react";
import { useColorMode } from "@chakra-ui/react";

import CodeMirror from "@uiw/react-codemirror";

import { EditorState } from "@codemirror/state";
import { EditorView, lineNumbers, tooltips } from "@codemirror/view";
import { oneDark } from "@codemirror/theme-one-dark";
import { jsonParseLinter, json } from "@codemirror/lang-json";
import { linter, lintGutter } from "@codemirror/lint";
import { bracketMatching } from "@codemirror/language";
import { autocompletion, closeBrackets } from "@codemirror/autocomplete";
import { history } from "@codemirror/commands";
import type { JSONSchema7 } from "json-schema";

interface Props {
  value: JSONSchema7;
  height?: string;
}

const commonExtensions = [
  bracketMatching(),
  closeBrackets(),
  history(),
  autocompletion(),
  lineNumbers(),
  lintGutter(),
  EditorView.lineWrapping,
  EditorState.tabSize.of(2),
  autocompletion(),
  tooltips(),
];

export const JSONSchemaViewer = ({ value, height = "calc(20vh)" }: Props) => {
  const { colorMode } = useColorMode();

  return (
    <CodeMirror
      theme={colorMode === "dark" ? oneDark : "light"}
      extensions={[...commonExtensions, json(), linter(jsonParseLinter())]}
      value={JSON.stringify(value, null, 2)}
      height={height}
      width="100%"
      editable={false}
    />
  );
};
