import { Record, String } from "runtypes";
import type { Static } from "runtypes";

export const WorkflowMilestone = Record({
  checkpointId: String,
  completionDate: String.nullable(),
  label: String,
});

export type WorkflowMilestone = Static<typeof WorkflowMilestone>;
