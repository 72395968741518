import React, { useCallback } from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import {
  Stack,
  Input,
  IconButton,
  FormControl,
  FormLabel,
  Box,
  Button,
  Icon,
  useColorModeValue,
  FormErrorMessage,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { AiOutlineDelete } from "react-icons/ai";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";

interface IProps {
  checkpointIndex: number;
}

export const EntitiesToInjectFieldArray = (props: IProps) => {
  const { checkpointIndex } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>();
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const commonButtonProps = useButtonProps("sm", "secondary");
  const { fields, append, remove } = useFieldArray({
    control,
    name: `config.checkpoints.${checkpointIndex}.entitiesToInject`,
  });

  const getEntityValueError = useCallback(
    (index?: number, name?: "entity" | "value") => {
      const entitiesToInjectErrors = errors.config?.checkpoints && errors.config.checkpoints[checkpointIndex]?.entitiesToInject;

      if (!entitiesToInjectErrors) {
        return undefined;
      } else if (index !== undefined && name) {
        return entitiesToInjectErrors[index]?.[name];
      } else return entitiesToInjectErrors;
    },
    [errors, checkpointIndex]
  );

  return (
    <Box bgColor={"transparent"}>
      <Button
        {...commonButtonProps}
        onClick={() =>
          append({
            entity: "",
            value: "",
          })
        }>
        Add Entity to Inject
      </Button>
      {fields.map((field, index) => (
        <Stack direction="row" spacing="0.5rem" justifyContent="space-between" width="100%" key={field.id} mt="1rem" height="100%">
          <FormControl isInvalid={!!getEntityValueError(index, "entity")} flex={1}>
            <FormLabel fontSize="sm">Entity</FormLabel>
            <Controller
              render={({ field }) => <Input {...field} size="sm" type="text" mr="0.5rem" fontSize="sm" boxShadow="none" />}
              name={`config.checkpoints.${checkpointIndex}.entitiesToInject.${index}.entity`}
              control={control}
            />
            {getEntityValueError(index, "entity") ? (
              <FormErrorMessage>{getEntityValueError(index, "entity")?.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl isInvalid={!!getEntityValueError(index, "value")} flex={2}>
            <FormLabel fontSize="sm">Value</FormLabel>
            <Controller
              render={({ field }) => <Input {...field} size="sm" type="text" mr="0.5rem" fontSize="sm" boxShadow="none" />}
              name={`config.checkpoints.${checkpointIndex}.entitiesToInject.${index}.value`}
              control={control}
            />
            {getEntityValueError(index, "value") ? (
              <FormErrorMessage>{getEntityValueError(index, "value")?.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <Box pt="1.5rem">
            <IconButton
              onClick={() => remove(index)}
              aria-label="Delete"
              backgroundColor="unset"
              icon={<Icon as={AiOutlineDelete} color={buttonColor} boxSize="1rem" _hover={{ color: buttonHoverColor }} />}
              size="sm"
              _hover={{ backgroundColor: "unset" }}
            />
          </Box>
        </Stack>
      ))}

      {getEntityValueError()?.message && (
        <Alert mt="1rem" status="error">
          <AlertIcon />
          {getEntityValueError()?.message}
        </Alert>
      )}
    </Box>
  );
};
