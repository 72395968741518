import React from "react";
import { Icon } from "@chakra-ui/react";

export const DeleteCollectionIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M166.25 171H185.25V104.5H123.5V61.7499H104.5V104.5H42.75V171H61.75V123.5H104.5V171H123.5V123.5H166.25V171Z" fill="#CFD8DC" />
      <path d="M147.25 28.5H80.75V75.9999H147.25V28.5Z" fill="#3F51B5" />
      <path d="M199.5 152H152V199.5H199.5V152Z" fill="#D81B60" />
      <path d="M75.9999 152H28.5V199.5H75.9999V152Z" fill="#D81B60" />
      <path d="M137.75 152H90.2501V199.5H137.75V152Z" fill="#D81B60" />
      <line x1="93.9497" y1="93.0503" x2="136.376" y2="135.477" stroke="#D81B60" strokeWidth="14" />
      <line x1="93.0503" y1="135.477" x2="135.477" y2="93.0502" stroke="#D81B60" strokeWidth="14" />
    </svg>
  </Icon>
);

export const DeleteCollectionMetaDataIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M166.25 171H185.25V104.5H123.5V61.7499H104.5V104.5H42.75V171H61.75V123.5H104.5V171H123.5V123.5H166.25V171Z" fill="#CFD8DC" />
      <line x1="154.95" y1="93.0503" x2="197.376" y2="135.477" stroke="#D81B60" strokeWidth="14" />
      <line x1="154.05" y1="135.477" x2="196.477" y2="93.0502" stroke="#D81B60" strokeWidth="14" />
      <path d="M147.25 28.5H80.75V75.9999H147.25V28.5Z" fill="#3F51B5" />
      <path d="M199.5 152H152V199.5H199.5V152Z" fill="#D81B60" />
      <path d="M75.9999 152H28.5V199.5H75.9999V152Z" fill="#00BCD4" />
      <path d="M137.75 152H90.2501V199.5H137.75V152Z" fill="#00BCD4" />
    </svg>
  </Icon>
);

export const EditCollectionIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M166.25 171H185.25V104.5H123.5V61.7499H104.5V104.5H42.75V171H61.75V123.5H104.5V171H123.5V123.5H166.25V171Z" fill="#D000D4" />
      <path d="M147.25 28.5H80.75V75.9999H147.25V28.5Z" fill="#3F51B5" />
      <path d="M199.5 152H152V199.5H199.5V152Z" fill="#D000D4" />
      <path d="M75.9999 152H28.5V199.5H75.9999V152Z" fill="#00BCD4" />
      <path d="M137.75 152H90.2501V199.5H137.75V152Z" fill="#00BCD4" />
    </svg>
  </Icon>
);

export const StopWorkflowIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M171 61.75V42.75H104.5V104.5H61.75V123.5H104.5V185.25H171V166.25H123.5V123.5H171V104.5H123.5V61.75H171Z" fill="#90CAF9" />
      <path d="M76 80.75H28.5V147.25H76V80.75Z" fill="#D81B60" />
      <path d="M199.5 28.5H152V76H199.5V28.5Z" fill="#2196F3" />
      <path d="M199.5 152H152V199.5H199.5V152Z" fill="#D81B60" />
      <path d="M199.5 90.25H152V137.75H199.5V90.25Z" fill="#2196F3" />
      <path d="M94.8995 155L137.326 197.426M94 197.426L136.426 155" stroke="#D81B60" strokeWidth="14" />
    </svg>
  </Icon>
);

export const EditNoteIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_28_60)">
        <path
          d="M147.25 194.75H38C27.55 194.75 19 186.2 19 175.75V52.25C19 41.8 27.55 33.25 38 33.25H190C200.45 33.25 209 41.8 209 52.25V133C209 167.2 181.45 194.75 147.25 194.75Z"
          fill="#EFC761"
        />
        <path d="M54 86H173L171.5 141L140.5 172L54 171V86Z" fill="white" />
        <rect x="54" y="53" width="119" height="21" fill="white" />
        <path
          d="M226.575 138.225L213.275 124.925C211.375 123.025 208.05 123.025 205.675 124.925L199.5 131.1L220.4 152L226.575 145.825C228.475 143.925 228.475 140.6 226.575 138.225Z"
          fill="#E57373"
        />
        <path d="M188.904 141.86L130.47 200.293L151.291 221.114L209.725 162.681L188.904 141.86Z" fill="#FF9800" />
        <path d="M209.987 162.592L220.4 152.18L199.575 131.356L189.163 141.768L209.987 162.592Z" fill="#B0BEC5" />
        <path d="M130.625 200.45L123.5 228L151.05 220.875L130.625 200.45Z" fill="#FFC107" />
        <path d="M126.825 213.75L123.5 228L137.75 224.675L126.825 213.75Z" fill="#37474F" />
      </g>
      <defs>
        <clipPath id="clip0_28_60">
          <rect width="228" height="228" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);

export const EditDocumentIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_28_88)">
        <path
          d="M147.25 194.75H38C27.55 194.75 19 186.2 19 175.75V52.25C19 41.8 27.55 33.25 38 33.25H190C200.45 33.25 209 41.8 209 52.25V133C209 167.2 181.45 194.75 147.25 194.75Z"
          fill="#52E29D"
        />
        <path
          d="M226.575 138.225L213.275 124.925C211.375 123.025 208.05 123.025 205.675 124.925L199.5 131.1L220.4 152L226.575 145.825C228.475 143.925 228.475 140.6 226.575 138.225Z"
          fill="#E57373"
        />
        <path d="M188.904 141.86L130.47 200.293L151.291 221.114L209.725 162.681L188.904 141.86Z" fill="#FF9800" />
        <path d="M209.987 162.592L220.399 152.18L199.575 131.356L189.163 141.768L209.987 162.592Z" fill="#B0BEC5" />
        <path d="M130.625 200.45L123.5 228L151.05 220.875L130.625 200.45Z" fill="#FFC107" />
        <path d="M126.825 213.75L123.5 228L137.75 224.675L126.825 213.75Z" fill="#37474F" />
        <rect x="54" y="53" width="119" height="20" fill="white" />
        <rect x="54" y="87" width="119" height="20" fill="white" />
        <rect x="54" y="121" width="119" height="20" fill="white" />
        <rect x="54" y="155" width="44" height="20" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_28_88">
          <rect width="228" height="228" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);

export const EditLinkIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_28_74)">
        <path
          d="M147.25 194.75H38C27.55 194.75 19 186.2 19 175.75V52.25C19 41.8 27.55 33.25 38 33.25H190C200.45 33.25 209 41.8 209 52.25V133C209 167.2 181.45 194.75 147.25 194.75Z"
          fill="#21C1F3"
        />
        <path
          d="M226.575 138.225L213.275 124.925C211.375 123.025 208.05 123.025 205.675 124.925L199.5 131.1L220.4 152L226.575 145.825C228.475 143.925 228.475 140.6 226.575 138.225Z"
          fill="#E57373"
        />
        <path d="M188.904 141.86L130.47 200.293L151.291 221.114L209.725 162.681L188.904 141.86Z" fill="#FF9800" />
        <path d="M209.987 162.592L220.399 152.18L199.575 131.356L189.163 141.768L209.987 162.592Z" fill="#B0BEC5" />
        <path d="M130.625 200.45L123.5 228L151.05 220.875L130.625 200.45Z" fill="#FFC107" />
        <path d="M126.825 213.75L123.5 228L137.75 224.675L126.825 213.75Z" fill="#37474F" />
        <path d="M54 86H173L171.5 141L140.5 172L54 171V86Z" fill="white" />
        <rect x="54" y="53" width="119" height="21" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_28_74">
          <rect width="228" height="228" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);

export const EditMetaDataIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_28_118)">
        <path
          d="M147.25 194.75H38C27.55 194.75 19 186.2 19 175.75V52.25C19 41.8 27.55 33.25 38 33.25H190C200.45 33.25 209 41.8 209 52.25V133C209 167.2 181.45 194.75 147.25 194.75Z"
          fill="#52E29D"
        />
        <path
          d="M226.575 138.225L213.275 124.925C211.375 123.025 208.05 123.025 205.675 124.925L199.5 131.1L220.4 152L226.575 145.825C228.475 143.925 228.475 140.6 226.575 138.225Z"
          fill="#E57373"
        />
        <path d="M188.904 141.86L130.47 200.293L151.291 221.114L209.725 162.681L188.904 141.86Z" fill="#FF9800" />
        <path d="M209.987 162.592L220.399 152.18L199.575 131.356L189.163 141.768L209.987 162.592Z" fill="#B0BEC5" />
        <path d="M130.625 200.45L123.5 228L151.05 220.875L130.625 200.45Z" fill="#FFC107" />
        <path d="M126.825 213.75L123.5 228L137.75 224.675L126.825 213.75Z" fill="#37474F" />
        <rect x="54" y="53" width="119" height="28" fill="#37474F" />
        <rect x="54" y="100" width="119" height="28" fill="#D000D4" />
        <rect x="54" y="147" width="44" height="28" fill="#37474F" />
      </g>
      <defs>
        <clipPath id="clip0_28_118">
          <rect width="228" height="228" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);

export const EditDynamicDataIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_28_100)">
        <path
          d="M147.25 194.75H38C27.55 194.75 19 186.2 19 175.75V52.25C19 41.8 27.55 33.25 38 33.25H190C200.45 33.25 209 41.8 209 52.25V133C209 167.2 181.45 194.75 147.25 194.75Z"
          fill="#E16290"
        />
        <path
          d="M226.575 138.225L213.275 124.925C211.375 123.025 208.05 123.025 205.675 124.925L199.5 131.1L220.4 152L226.575 145.825C228.475 143.925 228.475 140.6 226.575 138.225Z"
          fill="#E57373"
        />
        <path d="M188.904 141.86L130.47 200.293L151.291 221.114L209.725 162.681L188.904 141.86Z" fill="#FF9800" />
        <path d="M209.987 162.592L220.399 152.18L199.575 131.356L189.163 141.768L209.987 162.592Z" fill="#B0BEC5" />
        <path d="M130.625 200.45L123.5 228L151.05 220.875L130.625 200.45Z" fill="#FFC107" />
        <path d="M126.825 213.75L123.5 228L137.75 224.675L126.825 213.75Z" fill="#37474F" />
        <rect x="35" y="65" width="45" height="45" fill="white" />
        <rect x="91" y="65" width="45" height="45" fill="white" />
        <rect x="147" y="65" width="45" height="45" fill="white" />
        <rect x="35" y="118" width="45" height="45" fill="white" />
        <rect x="91" y="118" width="45" height="45" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_28_100">
          <rect width="228" height="228" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);

export const EditExpenseIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_29_131)">
        <path
          d="M147.25 194.75H38C27.55 194.75 19 186.2 19 175.75V52.25C19 41.8 27.55 33.25 38 33.25H190C200.45 33.25 209 41.8 209 52.25V133C209 167.2 181.45 194.75 147.25 194.75Z"
          fill="#52E29D"
        />
        <path
          d="M226.575 138.225L213.275 124.925C211.375 123.025 208.05 123.025 205.675 124.925L199.5 131.1L220.4 152L226.575 145.825C228.475 143.925 228.475 140.6 226.575 138.225Z"
          fill="#E57373"
        />
        <path d="M188.904 141.86L130.47 200.293L151.291 221.114L209.725 162.681L188.904 141.86Z" fill="#FF9800" />
        <path d="M209.987 162.592L220.399 152.18L199.575 131.356L189.163 141.768L209.987 162.592Z" fill="#B0BEC5" />
        <path d="M130.625 200.45L123.5 228L151.05 220.875L130.625 200.45Z" fill="#FFC107" />
        <path d="M126.825 213.75L123.5 228L137.75 224.675L126.825 213.75Z" fill="#37474F" />
        <path
          d="M78.9926 110.395L59.3754 104.655C57.1049 104.001 55.5246 101.876 55.5246 99.5146C55.5246 96.5539 57.9223 94.1562 60.883 94.1562H72.9258C75.1418 94.1562 77.3215 94.8283 79.1379 96.0635C80.2459 96.8082 81.7354 96.6266 82.6799 95.7002L89.001 89.5244C90.2906 88.2711 90.109 86.1822 88.674 85.0742C84.2238 81.5867 78.6656 79.6432 72.9621 79.625V70.9062C72.9621 69.3078 71.6543 68 70.0559 68H64.2434C62.6449 68 61.3371 69.3078 61.3371 70.9062V79.625H60.883C49.3125 79.625 40.0125 89.5607 41.0842 101.349C41.8471 109.723 48.2408 116.534 56.3057 118.896L74.9238 124.345C77.1943 125.017 78.7746 127.124 78.7746 129.485C78.7746 132.446 76.377 134.844 73.4162 134.844H61.3734C59.1574 134.844 56.9777 134.172 55.1613 132.937C54.0533 132.192 52.5639 132.373 51.6194 133.3L45.2983 139.476C44.0086 140.729 44.1903 142.818 45.6252 143.926C50.0754 147.413 55.6336 149.357 61.3371 149.375V158.094C61.3371 159.692 62.6449 161 64.2434 161H70.0559C71.6543 161 72.9621 159.692 72.9621 158.094V149.339C81.4266 149.175 89.3643 144.144 92.1615 136.133C96.0668 124.944 89.5096 113.465 78.9926 110.395Z"
          fill="#37474F"
        />
        <rect x="111" y="83" width="69" height="20" fill="white" />
        <rect x="111" y="112" width="69" height="20" fill="white" />
        <rect x="111" y="141" width="20" height="20" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_29_131">
          <rect width="228" height="228" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);

export const ManageQuestionIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M187.15 109.25L183.35 90.25L123.5 102.6V38H104.5V96.425L38.4749 53.675L28.0249 69.825L100.225 116.375L44.6499 189.05L59.8499 200.45L113.525 130.15L153.425 190.475L169.575 180.025L129.675 120.65L187.15 109.25Z"
        fill="#CFD8DC"
      />
      <path
        d="M114 147.25C132.363 147.25 147.25 132.363 147.25 114C147.25 95.6365 132.363 80.75 114 80.75C95.6365 80.75 80.75 95.6365 80.75 114C80.75 132.363 95.6365 147.25 114 147.25Z"
        fill="#D000D4"
      />
      <path
        d="M114 61.75C127.117 61.75 137.75 51.1168 137.75 38C137.75 24.8832 127.117 14.25 114 14.25C100.883 14.25 90.25 24.8832 90.25 38C90.25 51.1168 100.883 61.75 114 61.75Z"
        fill="#D000D4"
      />
      <path
        d="M185.25 123.5C198.367 123.5 209 112.867 209 99.75C209 86.6332 198.367 76 185.25 76C172.133 76 161.5 86.6332 161.5 99.75C161.5 112.867 172.133 123.5 185.25 123.5Z"
        fill="#D000D4"
      />
      <path
        d="M33.25 85.5C46.3668 85.5 57 74.8668 57 61.75C57 48.6332 46.3668 38 33.25 38C20.1332 38 9.5 48.6332 9.5 61.75C9.5 74.8668 20.1332 85.5 33.25 85.5Z"
        fill="#D000D4"
      />
      <path
        d="M52.25 218.5C65.3668 218.5 76 207.867 76 194.75C76 181.633 65.3668 171 52.25 171C39.1332 171 28.5 181.633 28.5 194.75C28.5 207.867 39.1332 218.5 52.25 218.5Z"
        fill="#D000D4"
      />
      <path
        d="M161.5 209C174.617 209 185.25 198.367 185.25 185.25C185.25 172.133 174.617 161.5 161.5 161.5C148.383 161.5 137.75 172.133 137.75 185.25C137.75 198.367 148.383 209 161.5 209Z"
        fill="#D000D4"
      />
    </svg>
  </Icon>
);

export const ManageAnswerIcon = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M187.15 109.25L183.35 90.25L123.5 102.6V38H104.5V96.425L38.4749 53.675L28.0249 69.825L100.225 116.375L44.6499 189.05L59.8499 200.45L113.525 130.15L153.425 190.475L169.575 180.025L129.675 120.65L187.15 109.25Z"
        fill="#CFD8DC"
      />
      <path
        d="M114 147.25C132.363 147.25 147.25 132.363 147.25 114C147.25 95.6365 132.363 80.75 114 80.75C95.6365 80.75 80.75 95.6365 80.75 114C80.75 132.363 95.6365 147.25 114 147.25Z"
        fill="#D000D4"
      />
      <path
        d="M114 61.75C127.117 61.75 137.75 51.1168 137.75 38C137.75 24.8832 127.117 14.25 114 14.25C100.883 14.25 90.25 24.8832 90.25 38C90.25 51.1168 100.883 61.75 114 61.75Z"
        fill="#00BCD4"
      />
      <path
        d="M185.25 123.5C198.367 123.5 209 112.867 209 99.75C209 86.6332 198.367 76 185.25 76C172.133 76 161.5 86.6332 161.5 99.75C161.5 112.867 172.133 123.5 185.25 123.5Z"
        fill="#D000D4"
      />
      <path
        d="M33.25 85.5C46.3668 85.5 57 74.8668 57 61.75C57 48.6332 46.3668 38 33.25 38C20.1332 38 9.5 48.6332 9.5 61.75C9.5 74.8668 20.1332 85.5 33.25 85.5Z"
        fill="#00BCD4"
      />
      <path
        d="M52.25 218.5C65.3668 218.5 76 207.867 76 194.75C76 181.633 65.3668 171 52.25 171C39.1332 171 28.5 181.633 28.5 194.75C28.5 207.867 39.1332 218.5 52.25 218.5Z"
        fill="#00BCD4"
      />
      <path
        d="M161.5 209C174.617 209 185.25 198.367 185.25 185.25C185.25 172.133 174.617 161.5 161.5 161.5C148.383 161.5 137.75 172.133 137.75 185.25C137.75 198.367 148.383 209 161.5 209Z"
        fill="#00BCD4"
      />
    </svg>
  </Icon>
);

export const GenerateHighlights = (props) => (
  <Icon viewBox="0 0 228 228" {...props}>
    <svg width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M190 166.25V190H38V166.25H19V190C19 200.45 27.55 209 38 209H190C200.45 209 209 200.45 209 190V166.25H190Z" fill="#90A4AE" />
      <rect x="39" y="19" width="22" height="28" fill="#3F51B5" />
      <rect x="39" y="68" width="22" height="28" fill="#3F51B5" />
      <rect x="39" y="118" width="22" height="28" fill="#3F51B5" />
      <rect x="79" y="19" width="108" height="28" fill="#3F51B5" />
      <rect x="79" y="68" width="108" height="28" fill="#3F51B5" />
      <rect x="79" y="118" width="108" height="28" fill="#3F51B5" />
    </svg>
  </Icon>
);
