import React from "react";
import type { FunctionComponent } from "react";
import { Tooltip, Box, Circle } from "@chakra-ui/react";
import { getConfidenceColor } from "hooks/useStats";

interface Props {
  tooltip?: string;
  borderColor?: string;
  circleSize?: string;
  fontSize?: string;
  score?: number;
}

export const SmallActionConfidenceScore: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  circleSize = "20px",
  fontSize = "11px",
  tooltip,
  borderColor,
  score,
}) => {
  return (
    <Tooltip aria-label="" label={tooltip} placement="left" hasArrow>
      <Box>
        <Circle
          className="ch-qa-result-score"
          fontSize={fontSize}
          size={circleSize}
          fontWeight="semibold"
          borderColor={borderColor}
          borderWidth="1px"
          bg={score ? `${getConfidenceColor(score)}.400` : borderColor}
          color="white">
          {score}
        </Circle>
      </Box>
    </Tooltip>
  );
};
