import { useRef, useCallback } from "react";

export const useLastObserver = (onIntersect: () => void) => {
  const observer = useRef<IntersectionObserver | null>(null);

  return useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onIntersect();
        }
      });

      observer.current.observe(node);
    },
    [onIntersect]
  );
};
