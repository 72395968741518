import { Text, useColorModeValue, Stack, Image } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import { AddIcon } from "@chakra-ui/icons";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { AddNewMenuProjects } from "./AddNewMenuProjects";
import CharliLogoSmall from "screens/common/static/logos/charli-logo-small-gray.png";
import { useUserPreference, useButtonProps, useEntitlementKey } from "hooks";
import { ConversationContext } from "screens/thread/ConversationContext";
import { Menu, MenuItem, MenuButton, MenuDivider, MenuHeader } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

interface Props {
  buttonLabel?: string;
  className?: string;
  showProjectsOnly?: boolean;
  buttonType?: "primary" | "secondary";
}

export const AddNewMenu: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  buttonLabel,
  className = "",
  showProjectsOnly = false,
  buttonType = "primary",
}) => {
  const { setIsNewMenuAction } = useAddToCharliContext();
  const buttonTextColor = useColorModeValue("gray.600", "gray.400");
  const menuHoverName = useColorModeValue("ch-menu-item-light", "ch-menu-item-dark");
  const buttonColor = useColorModeValue("white", "gray.200");
  const newButtonBgColor = useColorModeValue("primary.default", "#004f6e");
  const hoverColor = useColorModeValue("white", "gray.200");
  const textHoverColor = useColorModeValue("primary.hover", "#013144");
  const hideCommandLineButton = useUserPreference("ui_hide_command_line_button") || false;
  const hasCommandLine = useEntitlementKey("ui_enable_command_suggestions");
  const { onConversationOpen } = useContext(ConversationContext);
  const buttonProps = {
    size: "sm",
    width: "100%",
    fontWeight: "300",
    backgroundColor: newButtonBgColor,
    color: buttonColor,
    borderRadius: "md",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: newButtonBgColor,
    variant: "outline",
    _hover: {
      color: hoverColor,
      borderColor: newButtonBgColor,
    },
  };
  const secondaryButtonStyle = useButtonProps("sm", "primary");

  return (
    <Menu
      menuButton={
        <MenuButton className={`ch-quick-actions-new-button ${className}`}>
          <Stack
            {...(buttonType === "primary" ? buttonProps : secondaryButtonStyle)}
            borderWidth="1px"
            py="4px"
            minWidth={"9rem"}
            {...(buttonLabel && { direction: "row" })}
            justifyContent="center">
            <AddIcon width={buttonLabel ? ".8rem" : "100%"} height={buttonLabel ? "unset" : ".8rem"} />
            {buttonLabel && <Text fontSize="sm">{buttonLabel}</Text>}
          </Stack>
        </MenuButton>
      }>
      <MenuHeader>Projects</MenuHeader>
      <MenuDivider />
      <AddNewMenuProjects />
      {!showProjectsOnly && !hideCommandLineButton && hasCommandLine && (
        <>
          <MenuDivider />
          <MenuHeader>Advanced</MenuHeader>
          <MenuDivider />
          <MenuItem
            key="new-command"
            id="new-resource-btn"
            onClick={() => {
              setIsNewMenuAction("command");
              onConversationOpen();
            }}
            className={`${menuHoverName} ch-quick-actions-command-button`}>
            <Stack direction="row">
              <Image src={CharliLogoSmall} height="10px" marginTop="6px" alt="Charli Logo" />
              <Text fontSize="md" color={buttonTextColor} _hover={{ color: textHoverColor }}>
                New Command
              </Text>
            </Stack>
          </MenuItem>
        </>
      )}
    </Menu>
  );
};
