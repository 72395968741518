import { useState, useCallback, useRef, useEffect } from "react";

interface IProps<T> {
  loadItems: (token: string | null) => Promise<{ nextToken: string | null; totalCount?: number; data: T }>;
}

export const useInfiniteLoading = <T>({ loadItems }: IProps<T[]>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState<T[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<null | number>(null);
  const [error, setError] = useState<string | null>(null);
  const tokenRef = useRef<string | null>(null);
  const fetchInProgress = useRef(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const wasCleared = useRef<boolean>(false);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  const loadMore = useCallback(async () => {
    if (fetchInProgress.current) {
      return;
    }

    fetchInProgress.current = true;
    setIsLoading(true);
    try {
      const { data, totalCount: localTotalCount, nextToken } = await loadItems(tokenRef.current);

      tokenRef.current = nextToken;
      setItems((current) => [...current, ...data]);
      setHasNextPage(!!nextToken);

      if (localTotalCount) {
        setTotalCount(localTotalCount);
      }
    } catch (err) {
      setError(err);
    } finally {
      fetchInProgress.current = false;
      setIsLoading(false);
    }
  }, [loadItems]);

  const clear = useCallback(() => {
    wasCleared.current = true;
    tokenRef.current = null;
    setItems([]);
    setTotalCount(null);
    setHasNextPage(true);
    setError(null);
  }, []);

  const lastMessageObserverRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();
    if (!node) return;

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsIntersecting(true);
      } else {
        setIsIntersecting(false);
      }
    });

    observer.current.observe(node);
  }, []);

  useEffect(() => {
    if (wasCleared.current) {
      loadMore();
    }

    wasCleared.current = false;
  }, [error, hasNextPage, items, totalCount, loadMore]);

  useEffect(() => {
    if (!hasNextPage) {
      return;
    }

    const timeout = setTimeout(() => {
      if (isIntersecting) {
        loadMore();
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, [loadMore, hasNextPage, isIntersecting, items]);

  return {
    lastMessageObserverRef,
    loading: isLoading,
    items,
    hasNextPage,
    error,
    totalCount,
    clear,
  };
};
