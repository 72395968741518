export interface Feature {
  text: string;
  available: boolean;
}

export interface PricingTier {
  name: string;
  internalPlanName?: string;
  price: string | "Free" | "Contact For Pricing";
  originalPrice?: string;
  description: string;
  features: Feature[];
  buttonText: string;
  isPopular?: boolean;
  isCurrent?: boolean;
  discount?: string;
  reportsPerMonth?: number;
  concurrentAnalysis?: number;
  questionsPerMonth?: number;
  rerunsPerMonth?: number;
  dataRetention?: string;
}

export const tiers: PricingTier[] = [
  {
    name: "Starter",
    internalPlanName: "free_trial",
    price: "Free",
    description: "For everyone. Get started. No credit card required and realize the business benefits immediately",
    features: [
      { text: "Unlimited *Magnificent 7 Summary Analysis for all Plans", available: true },
      { text: "*TSLA, GOOG, NVDA, MSFT, META, AAPL, AMZN", available: true },
      { text: "Access to trusted FactSet Data", available: true },
      { text: "Access to Nasdaq Real Time Data", available: true },
      { text: "Automatically collect and analyze information from SEC, EDGAR & SEDAR", available: true },
    ],
    buttonText: "Free Reports",
    reportsPerMonth: 3,
    concurrentAnalysis: 2,
    questionsPerMonth: 45,
    rerunsPerMonth: 0,
    dataRetention: "6 Months",
  },
  {
    name: "Professional",
    internalPlanName: "professional",
    price: "$99",
    originalPrice: "$125 USD /user/mo",
    description: "For Retail Investors, RIAs, Analysts AND small teams who want to automate with our basic company SEC and SEDAR filings.",
    features: [
      { text: "20% Discount and No Contract", available: true },
      { text: "**Add ETF support, available Nov 2024", available: true },
      { text: "Monthly Billing, No contract", available: true },
    ],
    buttonText: "Upgrade Plan",
    reportsPerMonth: 15,
    concurrentAnalysis: 6,
    questionsPerMonth: 225,
    rerunsPerMonth: 1,
    dataRetention: "6 Months",
    isCurrent: true,
  },
  {
    name: "Business",
    internalPlanName: "business",
    price: "$975",
    originalPrice: "$1499 USD /user/mo",
    description:
      "For small to mid-sized teams that want the power of combining qualitative and quantitative data from FactSet, Nasdaq and TMX.",
    features: [
      { text: "Includes Unlimited Reruns for projects with version control", available: true },
      { text: "See before and after outcomes", available: true },
    ],
    buttonText: "Contact Us",
    isPopular: true,
    reportsPerMonth: 40,
    concurrentAnalysis: 10,
    questionsPerMonth: 600,
    rerunsPerMonth: 4,
    dataRetention: "1 Year",
  },
  {
    name: "Premium",
    internalPlanName: "premium",
    price: "$1,899",
    originalPrice: "$3,165 USD /user/mo",
    description:
      "For mid to larger teams that need increased volumes, features, and integrations from FactSet, Nasdaq and TMX; plus, your own private data for richer research insights.",
    features: [{ text: "40% Volume Discount", available: true }],
    buttonText: "Contact Us",
    reportsPerMonth: 100,
    concurrentAnalysis: 20,
    questionsPerMonth: 1500,
    rerunsPerMonth: 10,
    dataRetention: "3 Years",
  },
  {
    name: "Enterprise",
    internalPlanName: "enterprise",
    price: "Contact For Pricing",
    description:
      "For enterprise teams that have a greater need for a data feed, analysis integration customization, and private data sources for the deepest qualitative and quantitative data insights.",
    features: [
      { text: "2 Free Custom Integrations", available: true },
      { text: "2 Free Custom Templates", available: true },
    ],
    buttonText: "Contact Us",
    reportsPerMonth: -1, // Custom
    concurrentAnalysis: -1, // Custom
    questionsPerMonth: -1, // Custom
    rerunsPerMonth: -1, // Custom
    dataRetention: "Custom",
  },
];
