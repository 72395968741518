import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useState } from "react";
import { useButtonProps } from "hooks";
import { DeleteReasonSelector } from "../contentCanvas/cell/DeleteReasonSelector";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  onHandleConfirmDelete: (reason: string) => void;
  title?: string;
  message?: string;
}

export const VerifyDeleteModal: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  onHandleConfirmDelete,
  onClose,
  isOpen,
  title = "Confirm Remove",
  message = `Please confirm that you would like to remove this item and select one of the reasons below. The reason will be used in future AI learning to improve results.`,
}) => {
  const primaryButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const [selectedReason, setSelectedReason] = useState("");

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize={"sm"}>
          <Text>{message}</Text>
          <Center>
            <Box width="10rem" pt="1rem">
              <DeleteReasonSelector onHandleDelete={(reason) => setSelectedReason(reason)} />
            </Box>
          </Center>
        </ModalBody>
        <ModalFooter>
          <Stack direction="row" spacing="1rem">
            <Button {...secondaryButtonProps} onClick={onClose}>
              Cancel
            </Button>
            <Button
              {...primaryButtonProps}
              mr={3}
              onClick={() => {
                onHandleConfirmDelete(selectedReason);
                onClose();
              }}>
              Remove
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
