import React, { useCallback, useEffect } from "react";
import { Box, useColorModeValue, Badge, Flex, Button } from "@chakra-ui/react";
import { getStatusColor } from "screens/common/components/WorkflowSummary";
import capitalize from "lodash/capitalize";
import moment from "moment";
import { TasksFiltersContextProvider, TasksFiltersPanel, useTaskFiltersContext } from "./TasksFilters";
import type { TaskLog } from "api/tasksLog/models/TaskLog";
import { getTasksLog } from "api/tasksLog/tasksLog";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { calculateTimeDifferenceWithoutFormat } from "screens/collection/components/utils";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useButtonProps, useEntitlementKey, useInfiniteLoading } from "hooks";

const STATUS_COLUMN_WIDTH = 180;
const PAGE_SIZE = 50;

const TasksLogBase = () => {
  const { status, registeredId: registeredTaskId, hideRetriedTasks, getTaskName, getIntegrationName } = useTaskFiltersContext();
  const bgColor = useColorModeValue("gray.200", "gray.700");
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");
  const navigate = useNavigate();
  const { search } = useLocation();
  const { setTaskId, onWorkflowPayloadPanelOpen } = useWorkflowContext();
  const commonButtonProps = useButtonProps("sm", "secondary");

  const hasViewWorkflowPayload = useEntitlementKey("read_workflows_payloads_admin");

  const handleOnClickTask = (workflowId: string) => {
    navigate(`${workflowId}${search}`);
  };

  const fetchMoreTasksLog = useCallback(
    async (nextToken: string | null) => {
      const filters = {
        ...(status && { status }),
        ...(registeredTaskId && { registeredTaskId }),
        ...{ excludeRetriedTasks: !!hideRetriedTasks },
      };
      const response = await getTasksLog({ ...(nextToken && { token: nextToken }), limit: PAGE_SIZE, filters });

      return { data: response.data, nextToken: response.nextToken };
    },
    [status, registeredTaskId, hideRetriedTasks]
  );

  const {
    loading: isLoading,
    items,
    lastMessageObserverRef: lastMessageObserver,
    hasNextPage,
    clear,
  } = useInfiniteLoading<TaskLog>({
    loadItems: fetchMoreTasksLog,
  });

  useEffect(() => {
    clear();
  }, [status, registeredTaskId, hideRetriedTasks, clear]);

  const getTaskDuration = useCallback((start: Date, end: Date) => {
    const { hours, minutes, seconds, milliseconds } = calculateTimeDifferenceWithoutFormat(start, end);

    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else if (seconds > 0) {
      return `${seconds}s ${milliseconds}ms`;
    } else {
      return `${milliseconds}ms`;
    }
  }, []);

  return (
    <>
      <TasksFiltersPanel isLoading={isLoading} />

      {/* Table header */}
      <Box
        fontSize={"small"}
        borderBottomColor={bgColor}
        borderBottom={`2px solid`}
        mb={1}
        color={titleColor}
        display={"flex"}
        justifyContent="space-between">
        <Box width={200}>Task name</Box>
        <Box flex={1}>Intent</Box>
        <Box flex={1}>Integration</Box>
        <Box flex={1}>Creation date</Box>
        <Box width={120}>Duration</Box>
        <Box width={STATUS_COLUMN_WIDTH + 20}>Status</Box>
        {hasViewWorkflowPayload && <Box width={100}></Box>}
      </Box>

      {items.map((taskLog) => (
        <Flex p={1} alignItems={"center"} key={`${taskLog.id}-${taskLog.registeredTaskId}-${taskLog.childWorkflowId}`}>
          <Box
            onClick={() => handleOnClickTask(taskLog.workflowId)}
            cursor={"pointer"}
            fontSize="sm"
            _hover={{ bgColor }}
            display={"flex"}
            justifyContent="space-between"
            flex={1}>
            <Box width={200} overflow={"hidden"} wordBreak="break-word" textOverflow={""}>
              {getTaskName(taskLog.registeredTaskId)}
            </Box>
            <Box flex={1} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
              {taskLog.intent}
            </Box>
            <Box flex={1} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
              {getIntegrationName(taskLog.registeredTaskId)}
            </Box>
            <Box flex={1}>{moment(new Date(taskLog.creationDate)).format("dddd, MMMM Do YYYY, h:mm:ss a")}</Box>
            <Box width={120}>
              {taskLog.startDate
                ? taskLog.status === "in_progress" || taskLog.completionDate
                  ? getTaskDuration(new Date(taskLog.startDate), taskLog.completionDate ? new Date(taskLog.completionDate) : new Date())
                  : "----"
                : "----"}
            </Box>
            <Box width={STATUS_COLUMN_WIDTH}>
              <Badge colorScheme={getStatusColor(taskLog.status)}>{capitalize(taskLog.status).split("_").join(" ")}</Badge>
            </Box>
          </Box>
          {hasViewWorkflowPayload && (
            <Button
              width={100}
              {...commonButtonProps}
              onClick={(evt) => {
                setTimeout(() => {
                  setTaskId(taskLog.id);
                  onWorkflowPayloadPanelOpen();
                }, 200);

                evt.stopPropagation();
              }}>
              View Payload
            </Button>
          )}
        </Flex>
      ))}

      {hasNextPage && (
        <Flex visibility={isLoading ? "visible" : "hidden"} height={"3rem"} ref={lastMessageObserver} align="center" justify="center">
          <TypingIndicator />
        </Flex>
      )}

      <Outlet />
    </>
  );
};

export const TasksLog = () => {
  return (
    <TasksFiltersContextProvider>
      <TasksLogBase />
    </TasksFiltersContextProvider>
  );
};
