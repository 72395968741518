import { prependProxyBaseUrl, request } from "../request";
import type { ExternalLink } from "./models/ExternalLink";
import { NewsInformationResponse } from "./models/NewsInformationResponse";

const NEWS_INFORMATION_ENDPOINT = prependProxyBaseUrl("/api/news-information");

export async function fetchNewsInformation(): Promise<ExternalLink[]> {
  try {
    const response = await request()
      .url(`${NEWS_INFORMATION_ENDPOINT}/?search_category=top_finance_news&research_topic=stock&cache=true`)
      .get()
      .json();

    const validate = NewsInformationResponse.validate(response);

    if (!validate.success) {
      throw new Error(validate.message);
    } else if (validate.value.status !== "success") {
      throw new Error(validate.value.message);
    } else {
      return validate.value.external_links;
    }
  } catch (error) {
    throw new Error(`Failed to get suggested question data: ${error}`);
  }
}
