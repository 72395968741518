import { Stack } from "@chakra-ui/react";
import { useTags } from "hooks";
import type { FunctionComponent } from "react";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Tags } from "screens/common/components";
import { InputBarContext } from "./InputBarContext";

interface Props {
  onSuggestionClick: (text: string) => void;
  hideDefaultTags?: boolean;
}

export const InputBarTagsInput: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  hideDefaultTags,
  onSuggestionClick,
}) => {
  const { messageText } = useContext(InputBarContext);
  const { tags: allTags } = useTags({ hideDefaultTags });

  // Local state, controls what tags are visible based on the current user input
  const [tags, setTags] = useState<string[]>([]);

  // Mutate tags state, filtered by user input
  useEffect(() => {
    if (!messageText) {
      setTags(allTags);
    } else {
      const incompleteTag = (messageText.split(" ").reverse()[0] || "").replace("#", "").toLowerCase();
      const isEmpty = incompleteTag === "" || incompleteTag.slice(-1) === " ";
      const usedTags = messageText.split("#").map((tag) => tag.split(" ")[0].toLowerCase());
      setTags(
        allTags.reduce((accum, tag) => {
          if ((isEmpty || tag.toLowerCase().startsWith(incompleteTag)) && !usedTags.includes(tag.toLowerCase())) {
            accum.push(tag);
          }
          return accum;
        }, [] as string[])
      );
    }
  }, [messageText, allTags]);

  const memoTags = useMemo(
    () => tags.map((tag) => ({ id: `input-bar-${tag.replace(/(?<!,)\s+/g, "-")}`, text: tag.replace(/(?<!,)\s+/g, "-") })),
    [tags]
  );

  return (
    <Stack direction="row" justifyContent="flex-start" flexWrap="wrap">
      <Tags hideInput tags={memoTags} onClickTag={onSuggestionClick} tagType="manual" allowDelete={false} allowUpdate={false} />
    </Stack>
  );
};
