import React, { useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
import { CollectionsFilterTagOptions } from "./CollectionsFilterTagOptions";
import { useLocation } from "react-router-dom";

export const CollectionsTabHeaderFilter = () => {
  const { isOpen: isFilterOpen, onClose: onFilterClose } = useDisclosure();
  const { pathname } = useLocation();
  const route = pathname.split("/")[1];

  useEffect(() => {
    onFilterClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return <Stack>{isFilterOpen && <CollectionsFilterTagOptions />}</Stack>;
};
