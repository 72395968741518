import React from "react";
import { useEntitlements } from "hooks";
import { SidebarButton } from "./SidebarButton";
import { SIDEBAR_ADMIN_BUTTON_CLICKED } from "api/analytics/events";
import { AiOutlineSetting } from "react-icons/ai";

export const SidebarAdminButton = () => {
  const entitlements = useEntitlements();

  if (!entitlements["manage_configured_workflows_read"] && !entitlements["manage_entitlements"]) {
    return null;
  }

  return (
    <SidebarButton
      text="Admin"
      icon={AiOutlineSetting}
      screen="admin"
      analyticsEvent={SIDEBAR_ADMIN_BUTTON_CLICKED}
      cssClasses={["ch-sidebar-nav-admin"]}
    />
  );
};
