import type { Static } from "runtypes";
import { Record, String, Unknown } from "runtypes";

export const DynamicData = Record({
  dynamic_data: Unknown.optional(),
  id: String,
  category: String,
});

export type DynamicData = Static<typeof DynamicData>;
