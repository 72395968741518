import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Modal,
  ModalBody,
  Text,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import type { FunctionComponent } from "react";
import React from "react";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";

interface Props {
  onClose: () => void;
  onSave?: () => void;
  onPublish?: () => void;
  isEditing?: boolean;
  isOpen: boolean;
  title?: string;
}

export const ModalView: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  children,
  onClose,
  onSave,
  onPublish,
  isOpen,
  isEditing,
  title,
}) => {
  const shadowColor = useColorModeValue("#00000080", "#000000");
  const commonButtonProps = useButtonProps("sm", "primary");
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = React.useState(false);

  const onConfirm = () => {
    onSave && onSave();
    onClose();
  };

  const handleOnClose = () => {
    if (isEditing) {
      setShowConfirmDeleteModal(true);
    } else {
      onClose();
    }
  };

  return (
    <Modal closeOnOverlayClick blockScrollOnMount onClose={handleOnClose} isOpen={isOpen} isCentered scrollBehavior={"inside"}>
      <ModalOverlay />
      <ModalContent maxWidth="calc(100vw - 7rem)" maxHeight="calc(90vh)" boxShadow={`0 4px 46px ${shadowColor}`} overflow="auto">
        <ModalHeader bgColor={"white"} maxHeight="10rem" display="flex" flexDirection="column" padding={0} borderTopRadius="lg">
          <Stack direction={"row"} fontSize="md" textAlign="center" pt="1rem" width="100%" justifyContent={"space-between"} px="1.5rem">
            <Text fontWeight={"semibold"} fontSize="lg">
              {title}
            </Text>
            <Center>
              <CloseIcon cursor={"pointer"} boxSize={".8rem"} onClick={onClose} />
            </Center>
          </Stack>
        </ModalHeader>
        <ModalBody pt="1rem">
          {children}
          {showConfirmDeleteModal && isEditing && (
            <ConfirmDeleteModal
              title="Unsaved edits"
              body="You have unsaved edits. Do you want to save them? All edits will be lost if you cancel without saving."
              buttonLabel="Save Edits"
              isOpen
              onClose={() => onClose()}
              onConfirm={() => {
                onConfirm();
              }}
            />
          )}
        </ModalBody>
        {(onPublish || onSave) && (
          <ModalFooter>
            <Stack direction="row" justifyContent={onPublish ? "space-between" : "flex-end"}>
              {onPublish && (
                <Button
                  {...commonButtonProps}
                  id="publish-button"
                  size="sm"
                  aria-label="publish"
                  onClick={() => {
                    onPublish();
                  }}>
                  Publish
                </Button>
              )}
              {onSave && (
                <Button
                  {...commonButtonProps}
                  disabled={!isEditing}
                  id="save-button"
                  aria-label="save"
                  onClick={() => {
                    onSave();
                  }}>
                  Save Edits
                </Button>
              )}
            </Stack>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
