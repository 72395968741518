import { Array } from "runtypes";
import { prependProxyBaseUrl, request } from "../request";
import type { CopyValue } from "./models/Copy";
import { Copy } from "./models/Copy";
import type { CopyKey } from "./models/CopyKey";

const COPIES_ENDPOINT = () => prependProxyBaseUrl(`/api/copies`);

export async function getCopies(): Promise<Copy[]> {
  const response = await request().url(COPIES_ENDPOINT()).get().json();

  const validate = Array(Copy).validate(response);

  if (validate.success) {
    return validate.value;
  } else {
    throw new Error(validate.message);
  }
}

export async function upsertCopy(key: CopyKey, value: CopyValue): Promise<Copy> {
  const response = await request().url(COPIES_ENDPOINT()).post({ key, value }).json();

  const validate = Copy.validate(response);

  if (validate.success) {
    return validate.value;
  } else {
    throw new Error(validate.message);
  }
}

export async function deleteCopy(key: CopyKey): Promise<void> {
  await request().url(`${COPIES_ENDPOINT()}/${key}`).delete().res();
}
