import { Box, Stack, Text } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { ChatBubble } from "./ChatBubble";
import type { MessageState } from "types/conversation";
import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useCollectionKey, useContents } from "hooks";
import { ContentImageWrapper } from "screens/content/common/ContentImage/ContentImageWrapper";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useConfigMap, useGetViewConfig } from "hooks/useViewConfig";

interface Props {
  type: string;
  contentId: string;
  senderId: string;
  state: MessageState;
  buttonText: string;
  subtitle?: string;
  metadataIds?: string[];
}

export const ChatButton = ({ contentId, senderId, state, buttonText, subtitle, metadataIds }: Props) => {
  const { onConversationClose } = useContext(ConversationContext);
  const navigate = useNavigate();
  const contentData = useContents(metadataIds ?? []);
  const collectionId = useMemo(() => contentData && contentData[0].id, [contentData]);
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionName = useCollectionKey(collectionId, "name");
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const configMap = useConfigMap();
  const projectRoute = useGetViewConfig("route", collectionType, configMap);

  const onClick = () => {
    onConversationClose();
    const contentDataId = contentData && contentData[0].id;

    if (projectRoute && projectRoute.length > 0 && contentDataId) {
      return navigate(`/${projectRoute}/${contentDataId}`);
    } else if (contentData?.length === 1 && contentDataId) {
      return navigate(`/library/resource/${contentDataId}`);
    } else {
      return navigate(`/search/${contentId}`);
    }
  };

  return (
    <Box>
      <ChatBubble
        initiator={senderId === "charli" ? "charli" : "user"}
        state={state}
        onClick={onClick}
        className="ch-collection-button"
        collectionId={collectionId}
        collectionName={collectionName}>
        <Stack direction="row" alignItems="center" spacing="1rem">
          {contentData?.length === 1 && (
            <Box pr=".5rem">
              <ContentImageWrapper
                key={`${contentData[0].id}`}
                metadataId={contentData[0].id}
                thumbnailWidth="50"
                thumbnailHeight="50"
                thumbnailBorderRadius="md"
                fit="cover"
                thumbnailFit="cover"
                thumbnailPosition="center"
              />
            </Box>
          )}
          <Stack direction="row" alignItems="center">
            <Text fontSize="sm" fontWeight="semibold" alignItems="center">
              {buttonText ? buttonText : "Resource Canvas"}
            </Text>
            <Text fontSize="xs" fontWeight="normal" alignItems="center">
              {collectionMetadataIds && collectionMetadataIds.length > 1 ? `${collectionMetadataIds.length} items` : subtitle}
            </Text>
          </Stack>
          <ChevronRightIcon boxSize="1.5rem" />
        </Stack>
      </ChatBubble>
    </Box>
  );
};
