import { useMemo } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

/**
 * A hook for managing access to Charli integrations. This hook asks the integration service for the supported
 * integrations as well as the currently configured integrations for the user.
 *
 * This is the way we should get the integrations going forward. Once Google Calendar and Drive are added to the
 * integration service the above function is no longer needed and the web-app can simply use this method instead.
 */
export function useExternalIntegrations(category?: string, categoryKey?: string, integrationId?: string, integrationUrn?: string) {
  const { integrations } = useSelector((state: RootState) => state.integrations);
  const availableIntegrations = useMemo(() => {
    if (integrationId) {
      return integrations.filter(
        (integration) => integration.isEnabled && integration.isAvailable && integration.integrationId === integrationId
      );
    } else if (category) {
      return integrations.filter((integration) => integration.isEnabled && integration.isAvailable && integration.category === category);
    } else if (categoryKey) {
      return integrations.filter(
        (integration) => integration.isEnabled && integration.isAvailable && integration.categoryKey === categoryKey
      );
    } else {
      return integrations;
    }
  }, [category, categoryKey, integrationId, integrations]);

  const getIntegration = useMemo(() => {
    if (integrationId) {
      return integrations.filter((integration) => integration.integrationId === integrationId)[0];
    } else if (category) {
      return integrations.filter((integration) => integration.category === category)[0];
    } else if (categoryKey) {
      return integrations.filter((integration) => integration.categoryKey === categoryKey)[0];
    } else if (integrationUrn) {
      return integrations.filter((integration) => integration.urn === integrationUrn)[0];
    } else {
      return undefined;
    }
  }, [category, categoryKey, integrationId, integrationUrn, integrations]);

  const hasIntegration = useMemo(() => {
    if (category) {
      return (
        integrations.filter((integration) => integration.isEnabled && integration.isAvailable && integration.category === category).length >
        0
      );
    } else if (integrationId) {
      return (
        integrations.filter(
          (integration) => integration.isEnabled && integration.isAvailable && integration.integrationId === integrationId
        ).length > 0
      );
    } else if (categoryKey) {
      return (
        integrations.filter((integration) => integration.isEnabled && integration.isAvailable && integration.categoryKey === categoryKey)
          .length > 0
      );
    } else if (integrationUrn) {
      return (
        integrations.filter((integration) => integration.isEnabled && integration.isAvailable && integration.urn === integrationUrn)
          .length > 0
      );
    } else {
      return false;
    }
  }, [category, categoryKey, integrationId, integrationUrn, integrations]);

  const availableKeysForDefaultPreferences = useMemo(() => {
    const availableIntegrations = integrations.filter(({ category, isEnabled, isAvailable }) => category && isEnabled && isAvailable);
    const groupedCategories = groupBy(availableIntegrations, "categoryKey");

    return Object.keys(groupedCategories).filter((integrations) => integrations.length > 1);
  }, [integrations]);

  const allCategories = useMemo(() => {
    const groupedCategories = groupBy(integrations, "category");

    return sortBy(
      Object.entries(groupedCategories).map(([category, integrations]) => {
        return { category, key: integrations[0].categoryKey };
      }),
      (entry) => entry.category
    );
  }, [integrations]);

  return { availableIntegrations, availableKeysForDefaultPreferences, allCategories, hasIntegration, getIntegration };
}
