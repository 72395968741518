import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { ConversationContext } from "screens/thread/ConversationContext";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";

interface Props {
  collectionType: string;
  buttonLabel?: string;
}

export const NewProjectMenuItem: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionType = "research",
  buttonLabel,
}) => {
  const {
    setIsNewMenuAction,
    setSelectedCollection,
    onAddNewProjectPanelOpen,
    setShowContentList,
    setShouldCreateNewCollection,
    onAddToCollectionModalOpen,
  } = useAddToCharliContext();
  const { pathname } = useLocation();
  const isProject = pathname.includes("project");
  const route = pathname.split("/").slice(isProject ? 2 : 1, isProject ? 3 : 2)[0];
  const { setConversationId } = useContext(ConversationContext);

  const onButtonAddClick = () => {
    setConversationId(uuid());
    setSelectedCollection(undefined);
    switch (route) {
      case "library":
        setIsNewMenuAction("library");
        setSelectedCollection(undefined);
        setShouldCreateNewCollection(true);
        setShowContentList(true);
        onAddToCollectionModalOpen();
        return;
      default:
        onAddNewProjectPanelOpen();
        return;
    }
  };

  return <OptionsMenuItem menuLabel={buttonLabel || "New Project"} className="ch-new-collection-btn" onClick={onButtonAddClick} />;
};
