import type { Middleware } from "redux";
import { loginWithAstrellaAccessToken, refreshAstrellaKeycloakToken } from "../session/operations";
import { refreshAstrellaToken } from "api/astrella";
import type { AuthToken } from "types/auth";
import { logoutActions } from "./actionGroups";

let tokenRefreshTimeout: number | undefined | null;

export const astrellaTokenRefreshMiddleware: Middleware = (store) => (next) => (action) => {
  next(action);

  const clearTimeout = () => {
    if (tokenRefreshTimeout) {
      window.clearTimeout(tokenRefreshTimeout);
      tokenRefreshTimeout = null;
    }
  };

  if (action.type === loginWithAstrellaAccessToken.fulfilled.type || action.type === refreshAstrellaKeycloakToken.fulfilled.type) {
    // Clear any existing timers
    clearTimeout();
    // Get token
    const token = action.payload.token as AuthToken;
    // Refresh token 1 minute prior to expiry (or in 1 minute if calculated expiry time is below that)
    // const timeoutInMs = Math.max(token.expires_in * 1000 - 60000, 60000);
    const timeoutInMs = Math.max(token.expires_in * 1000 - 60000, 60000);
    // Start a timer to refresh the access token prior to its expiry
    if (token) {
      tokenRefreshTimeout = setTimeout(() => {
        store.dispatch(refreshAstrellaKeycloakToken(token) as never);
        refreshAstrellaToken();
      }, timeoutInMs) as unknown as number;
    }
  }
  if (logoutActions.includes(action.type) || action.type === refreshAstrellaKeycloakToken.rejected.type) {
    // Clear the refresh timer
    clearTimeout();
  }
};
