import type { EntityState } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { downloadAllCopies, removeCopy, upsertCopy } from "./operations";
import type { Copy } from "api/copies/models/Copy";
import type { RootState } from "state/rootReducer";

interface CopiesState extends EntityState<Copy> {
  isLoading: boolean;
}

const copiesAdaptar = createEntityAdapter<Copy>({
  selectId: (copy) => copy.key,
});

const initialState: CopiesState = copiesAdaptar.getInitialState({
  isLoading: false,
});

export const { reducer, actions } = createSlice({
  name: "copies",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadAllCopies.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadAllCopies.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadAllCopies.fulfilled, (state, action) => {
      state.isLoading = false;

      copiesAdaptar.setAll(state, action.payload);
    });

    builder.addCase(upsertCopy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(upsertCopy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(upsertCopy.fulfilled, (state, action) => {
      state.isLoading = false;

      copiesAdaptar.upsertOne(state, action.payload);
    });

    builder.addCase(removeCopy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeCopy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeCopy.fulfilled, (state, action) => {
      state.isLoading = false;

      copiesAdaptar.removeOne(state, action.meta.arg);
    });
  },
});

export default reducer;

export const {
  selectAll: selectAllCopies,
  selectById: selectCopyByKey,
  selectIds: selectCopiesKeys,
  selectEntities: selectCopiesEntities,
} = copiesAdaptar.getSelectors((state: RootState) => state.copies);
