import type { TickerType } from "api/tickers/models/TickerType";

export const getTickerTypeOption = (type: TickerType): { value: TickerType; label: string } => {
  switch (type) {
    case "equity":
      return { value: "equity", label: "Equity" };
    case "fixed_income":
      return { value: "fixed_income", label: "Fixed Income" };
    case "cash":
      return { value: "cash", label: "Cash and cash equivalents" };
    case "mutual_funds":
      return { value: "mutual_funds", label: "Mutual Funds" };
    case "index_funds":
      return { value: "index_funds", label: "Index Funds" };
    case "real_estate":
      return { value: "real_estate", label: "Real Estate" };
    case "commodities":
      return { value: "commodities", label: "Commodities" };
    case "currencies":
      return { value: "currencies", label: "Currencies" };
    case "etf":
      return { value: "etf", label: "ETF" };
  }
};
