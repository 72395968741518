import { ChevronRightIcon, ChevronUpIcon } from "@chakra-ui/icons";
import type { IconProps } from "@chakra-ui/react";
import { Box, Center, Code, Icon, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { getChildWorkflowsV2 } from "api/workflows";
import { useLastObserver } from "hooks/useLastObserver";
import isEmpty from "lodash/isEmpty";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { AiFillCheckCircle, AiFillQuestionCircle } from "react-icons/ai";
import { BsFillCircleFill } from "react-icons/bs";
import { HiDotsCircleHorizontal } from "react-icons/hi";
import { IoMdRefreshCircle } from "react-icons/io";
import { Array, Number, String, Unknown } from "runtypes";
import { formatDate } from "screens/common/modal/formatters";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import type { ChildWorkflowErrorDetails, ChildWorkflow } from "types/workflows/workflow";

interface Props {
  intent: string;
  status: string;
  workflowId: string;
}

const formatEntityValue = (value: unknown): string => {
  if (String.guard(value)) {
    return value;
  } else if (Number.guard(value)) {
    return value.toString();
  } else if (Array(String).guard(value)) {
    return `[${value.join(", ")}]`;
  } else if (Array(Number).guard(value)) {
    return `[${value.map((item) => item.toString()).join(", ")}]`;
  } else if (Array(Unknown).guard(value)) {
    return `Array of ${value.length} object${value.length === 1 ? "" : "s"}`;
  } else {
    return `Object value`;
  }
};

const iconProps: IconProps = {
  pt: "0!important",
  boxSize: "1.2rem",
  backgroundColor: "gray.600",
  borderRadius: "full",
};

export const getStatusIcon = (status: string) => {
  switch (status) {
    case "succeeded":
    case "complete":
      return <Icon as={AiFillCheckCircle} color={`${getStatusColor(status)}.100`} {...iconProps} />;
    case "queued":
      return <Icon as={HiDotsCircleHorizontal} color={`${getStatusColor(status)}.100`} {...iconProps} />;
    case "in_progress":
      return <Icon as={HiDotsCircleHorizontal} color={`${getStatusColor(status)}.100`} {...iconProps} />;
    // case "failed":
    // case "error":
    //   return <Icon as={FaDotCircle} color={`${getStatusColor(status)}.100`} {...iconProps} />;
    case "clarification_needed":
      return <Icon as={AiFillQuestionCircle} color={`${getStatusColor(status)}.100`} {...iconProps} />;
    case "failed":
    case "error":
    case "denied_intent_confirmation":
      return <Icon as={IoMdRefreshCircle} color={`${getStatusColor(status)}.100`} {...iconProps} />;
    default:
      return <Icon as={BsFillCircleFill} color={`${getStatusColor(status)}.200`} {...iconProps} />;
  }
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "succeeded":
    case "complete":
      return "green";
    case "queued":
    case "in_progress":
    case "denied_intent_confirmation":
      return "orange";
    case "failed":
    case "error":
      return "red";
    case "clarification_needed":
      return "blue";
    default:
      return "gray";
  }
};

const outputFormatWithSeconds = "do MMM yyyy 'at' h:mm:ss aa";

export const ChildWorkflowSummary = ({ workflowId, intent, status }: Props) => {
  const textColor = useColorModeValue("secondary.default", "gray300");
  const codeColor = useColorModeValue("blackAlpha", "white");
  const codeBgColor = useColorModeValue("gray.100", "#191f23");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [childWorkflows, setChildWorkflows] = useState<ChildWorkflow[]>([]);
  const nextToken = useRef<string | undefined>();
  const totalCount = useRef<number | undefined>();

  const fetchChildWorkflows = useCallback(() => {
    setIsLoading(true);
    getChildWorkflowsV2(workflowId, { limit: 20, token: nextToken.current, filters: { intent, status } })
      .then((response) => {
        setIsLoading(false);

        if (response.nextToken) {
          nextToken.current = response.nextToken;
        }

        totalCount.current = response.totalCount;

        setChildWorkflows((prev) => [...prev, ...response.data]);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }, [intent, status, workflowId]);

  const lastChildCallback = useCallback(() => {
    if (totalCount.current && childWorkflows.length >= totalCount.current) {
      return;
    }

    fetchChildWorkflows();
  }, [childWorkflows.length, fetchChildWorkflows]);

  const lastChild = useLastObserver(lastChildCallback);

  const { onStepsPanelOpen, setChildWorkflow } = useWorkflowContext();

  const renderErrorDetails = useCallback(
    (errorDetails: ChildWorkflowErrorDetails) => {
      const details = (() => {
        switch (errorDetails.type) {
          case "could_not_choose_task":
            return (
              <>
                Multiple tasks with intent{" "}
                <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                  {errorDetails.intent}
                </Code>{" "}
                exist, could not pick one
              </>
            );
          case "could_not_resolve_required_entities":
            return (
              <>
                Could not resolve required entity{" "}
                <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                  {errorDetails.entityName}
                </Code>{" "}
                which is necessary for intent{" "}
                <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                  {errorDetails.intent}
                </Code>
              </>
            );
          case "intent_not_found":
            return (
              <>
                Intent{" "}
                <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                  {errorDetails.intent}
                </Code>{" "}
                does not exist
              </>
            );
          case "no_suitable_task_found":
            return (
              <>
                Intent{" "}
                <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                  {errorDetails.intent}
                </Code>{" "}
                exists, but requirements are not satisfied
              </>
            );
          case "task_with_intent_exists_but_failed":
            return (
              <>
                Intent{" "}
                <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                  {errorDetails.intent}
                </Code>{" "}
                exists, but execution failed
              </>
            );
          default:
            return "Unexpected reason";
        }
      })();

      return <Text fontSize="xs">Error details: {details}</Text>;
    },
    [codeBgColor, codeColor]
  );

  useEffect(() => {
    fetchChildWorkflows();
  }, [fetchChildWorkflows]);

  return (
    <Box maxHeight={500} overflow="auto">
      {childWorkflows.map(({ id: childWorkflowId, ...childWorkflow }, index) => (
        <Box
          {...(childWorkflows.length - 1 === index && { ref: lastChild })}
          id={`child-workflow-${childWorkflowId}`}
          key={`${workflowId}-child-workflow-${childWorkflowId}`}
          paddingTop="10px">
          <Stack spacing={2} marginBottom=".5rem">
            <Stack direction="row" justifyContent={"space-between"} width="100%">
              <Stack
                cursor="pointer"
                direction="row"
                onClick={() => {
                  const element = document.getElementById("workflow-top");
                  if (element) {
                    element.scrollIntoView();
                  }
                }}>
                <Center>
                  <Text fontSize="xs">Scroll to top</Text>
                  <ChevronUpIcon color={textColor} boxSize="1.5rem" />
                </Center>
              </Stack>
            </Stack>

            <Stack direction="row" align="center" pl="1rem">
              <Text fontSize="xs">Child worklflow id</Text>
              <Text fontSize="xs">{childWorkflowId}</Text>
            </Stack>

            {childWorkflow.inputEntities && !isEmpty(childWorkflow.inputEntities) && (
              <>
                <Text fontSize="xs">Entities</Text>
                <Stack borderLeft="1px solid gray">
                  {childWorkflow.inputEntities.map((entity, id) => (
                    <Text key={`${id}-entity-${entity}`} fontSize="xs" pl="1rem">
                      <Text>{entity.entity}</Text>
                      <Code background={codeBgColor} colorScheme={codeColor} fontSize="inherit">
                        {formatEntityValue(entity.value)}
                      </Code>
                    </Text>
                  ))}
                </Stack>
              </>
            )}
            <Stack borderLeft="1px solid gray">
              {childWorkflow.errorDetails && (
                <Stack direction="row" align="center" pl="1rem">
                  {renderErrorDetails(childWorkflow.errorDetails)}
                </Stack>
              )}
              <Stack direction="row" align="center" pl="1rem">
                <Text fontSize="xs">Started</Text>
                <Text fontSize="xs">{`${formatDate(new Date(childWorkflow.creationDate), outputFormatWithSeconds)}`}</Text>
              </Stack>
              {childWorkflow.completionDate && (
                <Stack direction="row" align="center" pl="1rem">
                  <Text fontSize="xs">Finished</Text>
                  <Text fontSize="xs">{`${formatDate(new Date(childWorkflow.completionDate), outputFormatWithSeconds)}`}</Text>
                </Stack>
              )}
              <Stack
                width="100%"
                justifyContent={"flex-end"}
                cursor="pointer"
                direction="row"
                onClick={() => {
                  setChildWorkflow(childWorkflowId);
                  onStepsPanelOpen();
                }}>
                <Center>
                  <Text fontSize="xs">View Steps</Text>
                  <ChevronRightIcon color={textColor} boxSize="1.5rem" />
                </Center>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      ))}
      {isLoading && (
        <Box mt={"1rem"} mb={"1rem"}>
          <TypingIndicator size="small" />
        </Box>
      )}
    </Box>
  );
};
