import {
  Box,
  Center,
  useColorModeValue,
  useBreakpointValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Tooltip,
  Tag,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import React, { useContext, useEffect, useRef } from "react";
import { CollectionsFilterContext } from "../collections/CollectionsFilterContext";
import { ItemLoadingIndicator } from "../collections/ItemLoadingIndicator";
import { NotesPanel } from "screens/content/common/notes/NotesPanel";
import { ContentViewPanel } from "screens/content/contentView/ContentViewPanel";
import { SharePanel } from "screens/panels/share/SharePanel";
import { VerifiedAIPanel } from "screens/content/contentView/VerifiedAIPanel";
import { useDispatch } from "react-redux";
import { deleteUserPreference } from "state/userPreference/operations";
import {
  useEquityPortfolioId,
  useGetConversationCompanyTickerValue,
  useGroupCollectionsIds,
  usePortfolioCollections,
  useProjectParams,
  useSortedCollectionsIds,
  useTileProps,
  useUserPreference,
} from "hooks";
import { ProjectLandingDefaultTile } from "./projectLandingTileLayouts/ProjectLandingDefaultTile";
import groupBy from "lodash/groupBy";
import { placeholderCompanies } from "../dashboard/PlaceholderCompanies";
import { HomePlaceholderTile } from "./projectLandingTileLayouts/HomePlaceholderTile";

interface Props {
  loadTilesCount?: number;
}

const ProjectGroup = ({ groupId }: { groupId: string }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const maybeCollectionsIds = useGroupCollectionsIds(groupId);
  const maybeSortedCollectionsIds = useSortedCollectionsIds(maybeCollectionsIds);

  const { firstCollectionId, restCollections } = useMemo(() => {
    if (!maybeSortedCollectionsIds) return { firstCollectionId: groupId, restCollections: [] };

    const [firstCollectionId, ...restCollectionsIds] = maybeSortedCollectionsIds;

    return {
      firstCollectionId,
      restCollections: restCollectionsIds,
    };
  }, [maybeSortedCollectionsIds, groupId]);

  const renderRestCollections = useCallback(
    (isExpanded: boolean) => {
      return restCollections.length > 0 ? (
        <AccordionPanel pb="1rem" pr="0" pt=".75rem">
          {isExpanded && (
            <Stack spacing=".75rem">
              {restCollections.map((id) => (
                <ProjectLandingDefaultTile key={`project-${id}`} collectionId={id} isChildProject />
              ))}
            </Stack>
          )}
        </AccordionPanel>
      ) : null;
    },
    [restCollections]
  );

  return (
    <AccordionItem className={groupId} border="none" pb="1.3rem" position="relative">
      {({ isExpanded }) => (
        <>
          {restCollections.length > 0 && (
            <AccordionButton
              className="ch-rerun-version-tag"
              _hover={{}}
              padding={0}
              paddingInline={0}
              position="absolute"
              zIndex={1}
              top={isMobile ? "3.8rem" : "4.65rem"}
              left="1.8rem">
              <Tooltip
                label={`${isExpanded ? "Hide" : "View"} previous ${restCollections.length} projects`}
                aria-label="Portfolio Name"
                placement="top">
                <Tag variant="subtle" borderColor={"gray.300"} borderWidth="1px" bgColor="white" fontSize="0.65rem" cursor={"pointer"}>
                  <AccordionIcon />
                  {`${restCollections.length} ${restCollections.length === 1 ? "version" : "versions"}`}
                </Tag>
              </Tooltip>
            </AccordionButton>
          )}
          <ProjectLandingDefaultTile collectionId={firstCollectionId} />
          {renderRestCollections(isExpanded)}
        </>
      )}
    </AccordionItem>
  );
};

export const ProjectLandingTiles: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  loadTilesCount = 10,
}) => {
  const { collectionCount, setCollectionCount, filteredCollections } = useContext(CollectionsFilterContext);
  const sectionRef = useRef<HTMLDivElement>(null);
  const bgColor = useColorModeValue("white", "gray.900");
  const dispatch = useDispatch();
  const userPreferenceSourceWeight = useUserPreference("source_weights_index") as 0 | 1;
  const observer = useRef<IntersectionObserver | null>(null);
  const portfolioCollections = usePortfolioCollections();
  const portfolioId = useEquityPortfolioId(portfolioCollections, "Magnificent 7");
  const { projectFilter } = useProjectParams();
  const commonTileProps = useTileProps();

  useEffect(() => {
    if (loadTilesCount > 0) {
      setCollectionCount(loadTilesCount);
    }
  }, [loadTilesCount, setCollectionCount]);

  useEffect(() => {
    if (!userPreferenceSourceWeight) return;

    dispatch(
      deleteUserPreference({
        preferenceKey: "source_weights_index",
      })
    );
  }, [dispatch, userPreferenceSourceWeight]);

  const groupedCollectionsIds: string[] = useMemo(() => {
    const grouped = groupBy(filteredCollections, (collection) => collection.projectGroupId || collection.id);

    return Object.keys(grouped);
  }, [filteredCollections]);

  const collectionsToRender = useMemo(() => {
    return groupedCollectionsIds.slice(0, collectionCount);
  }, [collectionCount, groupedCollectionsIds]);

  const allProjectConversationIds = useMemo(
    () => filteredCollections.map((collection) => collection.conversationId).filter((id): id is string => id !== undefined),
    [filteredCollections]
  );

  const getConversationTicker = useGetConversationCompanyTickerValue();
  const filteredPlaceholderCompanies = useMemo(() => {
    const stockTickers = new Set(filteredCollections.map((collection) => collection.ticker && collection.ticker.toLowerCase()));
    const conversationTickerValues = allProjectConversationIds.flatMap((conversationId) => {
      const maybeValue = getConversationTicker(conversationId);

      if (maybeValue) {
        return [maybeValue];
      } else {
        return [];
      }
    });
    const allTickers = new Set([...stockTickers, ...conversationTickerValues.map((ticker) => ticker.toLowerCase())]);

    return placeholderCompanies.filter((company) => !allTickers.has(company.ticker.toLowerCase()));
  }, [filteredCollections, allProjectConversationIds, getConversationTicker]);

  const lastMessageObserver = useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setCollectionCount((prev) => prev + 20);
        }
      });

      observer.current.observe(node);
    },
    [setCollectionCount]
  );

  return (
    <Box ref={sectionRef} height="100%">
      {collectionsToRender.length > 0 ? (
        <Accordion allowMultiple pb="2rem">
          {collectionsToRender.map((groupId) => {
            return <ProjectGroup key={`group-${groupId}`} groupId={groupId} />;
          })}
        </Accordion>
      ) : (
        <Box {...commonTileProps} cursor={"pointer"} height="5.3rem" hidden={portfolioId === projectFilter}>
          <Text color="primary.darkGray" fontSize={"sm"} lineHeight="4.2rem">
            No Projects created
          </Text>
        </Box>
      )}
      <Box height="5px" width="100%" className="last-content-item" ref={lastMessageObserver} />
      {collectionsToRender.length > 0 && (
        <Box
          position={"fixed"}
          bottom={"0"}
          backgroundColor={bgColor}
          borderRadius="full"
          border={`1px solid #E2E8F0`}
          zIndex={2}
          mb="1rem"
          ml="3px">
          <Center height="100%">
            <ItemLoadingIndicator
              onClick={() => null}
              currentItemCount={collectionsToRender.length}
              totalItemCount={groupedCollectionsIds.length}
            />
          </Center>
        </Box>
      )}
      {portfolioId === projectFilter && (
        <SimpleGrid spacing="1rem" columns={[1, 1, 3, 3, 4]}>
          {filteredPlaceholderCompanies.map((company) => (
            <HomePlaceholderTile
              key={company.ticker}
              logo={company.logo}
              companyName={company.companyName}
              ticker={company.ticker}
              exchange={company.exchange}
              securityType={company.securityType}
              overview={company.overview}
            />
          ))}
        </SimpleGrid>
      )}
      <ContentViewPanel />
      <SharePanel /> <NotesPanel /> <VerifiedAIPanel />
    </Box>
  );
};
