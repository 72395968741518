import { createSlice } from "@reduxjs/toolkit";
import type { Activity } from "api/charliActivities/models/activities/Activity";
import { downloadCharliActivities } from "./operations";

export interface Integration {
  urn: string;
  system: string | null;
  provider?: string;
  externalId: string | null;
  name: string;
  category: string;
  authenticationType?: string;
  developerId?: string | null;
  visibility: "everyone" | "restricted";
}

type IntegrationWithHandlersIds = Integration & { handlersIds: string[] };

export interface Handler {
  id: string;
  integrationUrn: string;
  intent: string;
  name: string;
  description: string;
  url: string;
  requirements: string[];
  requires: string[];
  optionalRequires: string[];
  shouldIncludeEntities: string[];
  mustIncludeEntities: string[];
  exhaustAllResolversEntities: string[];
  produces: string[];
  postExecutionIntents: string[];
  cost: number;
}

interface CharliActivitiesState {
  integrationsByUrn: Record<string, IntegrationWithHandlersIds>;
  handlersById: Record<string, Handler>;
  isLoading: boolean;
}

const initialState: CharliActivitiesState = {
  integrationsByUrn: {},
  handlersById: {},
  isLoading: false,
};

const normalizeHandlersAndIntegrations = (
  activities: Activity[]
): [Record<string, IntegrationWithHandlersIds>, Record<string, Handler>] => {
  return activities.reduce(
    ([integrationsByUrn, handlersById]: [Record<string, IntegrationWithHandlersIds>, Record<string, Handler>], activity) => {
      if (integrationsByUrn[activity.parentActivityId]) {
        integrationsByUrn[activity.parentActivityId].handlersIds.push(activity.id);
      } else {
        integrationsByUrn[activity.parentActivityId] = {
          urn: activity.parentActivityId,
          system: activity.system,
          externalId: activity.parentActivityExternalId ?? null,
          name: activity.parentActivityName,
          category: activity.parentActivityCategory,
          visibility: activity.visibility === "private" || activity.visibility === "protected" ? "restricted" : "everyone",
          handlersIds: [activity.id],
        };
      }

      handlersById[activity.id] = {
        id: activity.id,
        intent: activity.intent,
        name: activity.name,
        integrationUrn: activity.parentActivityId,
        description: activity.description ?? "",
        url: activity.url,
        requirements: activity.entities.map((entity) => entity.entity),
        requires: activity.entities.filter((require) => require.resolutionStrategy === "must_resolve").map((require) => require.entity),
        optionalRequires: activity.entities
          .filter((require) => require.resolutionStrategy === "should_resolve")
          .map((require) => require.entity),
        shouldIncludeEntities: activity.entities
          .filter((require) => require.resolutionStrategy === "should_include")
          .map((require) => require.entity),
        mustIncludeEntities: activity.entities
          .filter((require) => require.resolutionStrategy === "must_include")
          .map((require) => require.entity),
        exhaustAllResolversEntities: activity.entities
          .filter((require) => require.resolutionStrategy === "exhaust_all_resolvers")
          .map((require) => require.entity),
        produces: activity.produces,
        postExecutionIntents: activity.postExecutionIntents ?? [],
        cost: activity.cost,
      };

      return [integrationsByUrn, handlersById];
    },
    [{}, {}]
  );
};

export const { actions, reducer } = createSlice({
  name: "charliActivities",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadCharliActivities.fulfilled, (state, action) => {
      state.isLoading = false;
      const [integrationsByUrn, handlersById] = normalizeHandlersAndIntegrations(action.payload);

      state.integrationsByUrn = integrationsByUrn;
      state.handlersById = handlersById;
    });
    builder.addCase(downloadCharliActivities.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(downloadCharliActivities.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default reducer;
