import React from "react";
import { Center, Stack, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";

const NoContentMessageNonMemo = ({ children }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.700");
  const borderColorHover = useColorModeValue("gray.400", "gray.600");
  const textColor = useColorModeValue("gray.700", "gray.100");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  return (
    <Center>
      <Stack
        width="100%"
        spacing="1rem"
        align={"center"}
        borderRadius="md"
        p={isMobile ? "1rem" : "3rem"}
        backgroundColor={bgColor}
        boxShadow="none"
        borderColor={borderColor}
        _hover={{
          borderColor: borderColorHover,
        }}
        borderWidth="1px"
        color={textColor}>
        {children}
      </Stack>
    </Center>
  );
};

export const NoContentMessage = React.memo(NoContentMessageNonMemo);
