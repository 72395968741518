import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { FeatureUsage } from "api/usage/models/FeatureUsage";
import assign from "lodash/assign";
import { downloadCurrentUserFeatureUsage } from "./operations";

interface UsageState {
  featureUsage: Record<string, FeatureUsage>;
  isLoading: boolean;
}

const initialState: UsageState = {
  featureUsage: {},
  isLoading: false,
};

export const { actions, reducer } = createSlice({
  name: "usage",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
    setUsage(state, action: PayloadAction<Record<string, FeatureUsage>>) {
      assign(state.featureUsage, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadCurrentUserFeatureUsage.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(downloadCurrentUserFeatureUsage.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(downloadCurrentUserFeatureUsage.fulfilled, (state, action) => {
      state.isLoading = false;

      state.featureUsage = action.payload.featureAccess;
    });
  },
});

export default reducer;
