import type { TaskResponse } from "api/tasks/models/TaskResponse";
import { getTask } from "api/tasks/tasks";
import { useEffect, useState } from "react";

export const useTaskPayloads = (taskId: string | undefined) => {
  const [task, setTask] = useState<TaskResponse>();

  useEffect(() => {
    if (taskId) {
      getTask(taskId)
        .then((task) => setTask(task))
        .catch(() => setTask(undefined));
    }
  }, [taskId]);

  return task;
};
