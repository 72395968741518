import { useDisclosure, useToast } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { ConversationContext } from "screens/thread/ConversationContext";
import type { RequestEntities } from "types/charliui";
import { sendMessage } from "state/websocket/operations";
import { useDispatch } from "react-redux";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { VerifyDeleteModal } from "screens/content/contentView/VerifyDeleteModal";
import { ToastMessageContent } from "screens/common/components";
import { useCollectionKey, useProjectParams } from "hooks";

interface Props {
  highlightRecordId: string;
  onClose?: () => void;
}

export const HighlightItemActionsDelete: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  highlightRecordId,
  onClose,
}) => {
  const { projectId = "", contentId } = useProjectParams();
  const { pathname } = useLocation();
  const isResource = pathname.includes("resource");
  const [isDeletingItem, setIsDeletingItem] = useState(false);
  const dispatch = useDispatch();
  const { conversationId, setConversationId, onConversationOpen } = useContext(ConversationContext);
  const collectionConversationId = useCollectionKey(projectId, "conversationId");
  const currentConversationId = conversationId || collectionConversationId || contentId;
  const { isOpen: isOpenConfirmDelete, onOpen: onOpenConfirmDelete, onClose: onCloseConfirmDelete } = useDisclosure();
  const toast = useToast();

  const onDeleteHighlightItem = (reason?: string) => {
    if (!currentConversationId) {
      return;
    }
    setIsDeletingItem(true);
    onClose && onClose();
    const entities: RequestEntities = [];
    entities.push({ entity: "highlight_id", value: highlightRecordId });
    reason && entities.push({ entity: "highlight_deletion_reason", value: reason });
    entities.push({ entity: "metadata_id", value: contentId || projectId });
    !isResource && entities.push({ entity: "collection_id", value: contentId || projectId });

    dispatch(
      sendMessage({
        conversationId: currentConversationId,
        intent: "/delete_collection_metadata",
        entities: entities,
      })
    );
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`I'll remove this highlight from your project now. You can open the conversation I started about it by clicking this message`}
          onClick={() => {
            currentConversationId && setConversationId(currentConversationId);
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <>
      {isDeletingItem ? (
        <TypingIndicator size="small" />
      ) : (
        <SmallActionButton
          iconSize="1.4rem"
          classNames="ch-delete-highlight-record"
          iconTypeName={AiOutlineDelete}
          onClick={() => onOpenConfirmDelete()}
          tooltip={"Remove highlight"}
        />
      )}
      <VerifyDeleteModal
        title={`Remove Highlight`}
        message={`Please confirm that you would like to remove this highlight from the project and select one of the reasons below.  The reason will be used in future AI learning to improve results.`}
        onHandleConfirmDelete={(reason: string) => onDeleteHighlightItem(reason)}
        onClose={onCloseConfirmDelete}
        isOpen={isOpenConfirmDelete}
      />
    </>
  );
};
