import { Stack, Text, useColorModeValue, Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import ECHighlighter from "react-ec-highlighter";
import type { AssessmentResultsData } from "types/collection";
import { chartItemColor } from "screens/landing/tabs/collections/chartComponents/CustomChartUtils";
import { SmallActionConfidenceScore } from "screens/content/contentCanvas/cell/SmallActionConfidenceScore";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";
import { AssessmentResultsFollowupQuestions } from "./AssessmentResultsFollowupQuestions";
import { AssessmentResultsPolicies } from "./AssessmentResultsPolicies";
import { AssessmentResultsRiskAssessment } from "./AssessmentResultsRiskAssessment";

export interface Props {
  assessmentResults: AssessmentResultsData[];
  searchText?: string;
}

export const AssessmentResultsQuestion: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  assessmentResults,
  searchText,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const iconColor = useColorModeValue("gray.500", "gray.400");

  return (
    <Stack spacing="2rem">
      {assessmentResults.length > 0 &&
        assessmentResults.map((questionRecord, index) => (
          <Stack key={index}>
            <BlockSectionHeader title={`Assessment Question ${assessmentResults.length > 1 ? index + 1 : ""}`} direction="row">
              <Stack direction="row">
                {questionRecord.risk_assessment_result.risk_level.length > 0 && (
                  <Box
                    maxH="1.3rem"
                    width="100%"
                    px=".5rem"
                    py="2px"
                    borderRadius={"md"}
                    fontSize="xs"
                    bgColor={chartItemColor(questionRecord.risk_assessment_result.risk_level.replace(".", "").toLowerCase())}
                    color="white">
                    {questionRecord.risk_assessment_result.risk_level.replace(".", "")}
                  </Box>
                )}
                {questionRecord.risk_assessment_result.confidence && (
                  <SmallActionConfidenceScore
                    score={Math.round(questionRecord.risk_assessment_result.confidence * 100)}
                    tooltip={`Confidence score ${Math.round(questionRecord.risk_assessment_result.confidence * 100)}`}
                    borderColor={iconColor}
                  />
                )}
              </Stack>
            </BlockSectionHeader>
            <Text className="ch-assessment-result-question" color={textColor} fontSize="md" fontWeight={"semibold"}>
              {searchText && searchText.length > 0 && questionRecord.section.question ? (
                <ECHighlighter searchPhrase={searchText} text={questionRecord.section.question} />
              ) : (
                questionRecord.section.question
              )}
            </Text>
            {questionRecord.section.answer.length > 0 && (
              <Text fontSize="sm" className="ch-assessment-result-answer" width="100%">
                {searchText && searchText.length > 0 && questionRecord.section.answer ? (
                  <ECHighlighter searchPhrase={searchText} text={questionRecord.section.answer} />
                ) : (
                  questionRecord.section.answer
                )}
              </Text>
            )}
            <AssessmentResultsRiskAssessment subSection assessmentResults={questionRecord.risk_assessment_result} searchText={searchText} />
            <AssessmentResultsPolicies subSection assessmentResults={questionRecord.policies} searchText={searchText} />
            <AssessmentResultsFollowupQuestions subSection assessmentResults={questionRecord.followup_questions} searchText={searchText} />
          </Stack>
        ))}
    </Stack>
  );
};
