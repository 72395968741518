import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_VIEW = 5;

interface HomeFilter {
  maxRecentlyViewedTilesToShow: number;
  maxRecentlyAddedTilesToShow: number;
  maxRecentlySharedTilesToShow: number;
}

const initialState: HomeFilter = {
  maxRecentlyViewedTilesToShow: DEFAULT_VIEW,
  maxRecentlyAddedTilesToShow: DEFAULT_VIEW,
  maxRecentlySharedTilesToShow: DEFAULT_VIEW,
};

export const { actions, reducer } = createSlice({
  name: "home",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
    filterPersist(
      state,
      action: PayloadAction<{
        maxRecentlyViewedTilesToShow?: number;
        maxRecentlyAddedTilesToShow?: number;
        maxRecentlySharedTilesToShow?: number;
      }>
    ) {
      const { maxRecentlyViewedTilesToShow, maxRecentlyAddedTilesToShow, maxRecentlySharedTilesToShow } = action.payload;

      if (maxRecentlyViewedTilesToShow !== undefined) {
        state.maxRecentlyViewedTilesToShow = maxRecentlyViewedTilesToShow;
      }
      if (maxRecentlyAddedTilesToShow !== undefined) {
        state.maxRecentlyAddedTilesToShow = maxRecentlyAddedTilesToShow;
      }
      if (maxRecentlySharedTilesToShow !== undefined) {
        state.maxRecentlySharedTilesToShow = maxRecentlySharedTilesToShow;
      }
    },
  },
});

export default reducer;
