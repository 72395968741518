import { createContext } from "react";

const ERROR_MESSAGE_SUFFIX = "not set in context, did you forget to nest your component inside <InputBar />?";

export const InputBarContext = createContext({
  showTips: false,
  messageText: "",
  setMessageText: (text: string): void => {
    throw new Error(`setMessageText ${ERROR_MESSAGE_SUFFIX}`);
  },
  focusAndMoveCursorToEnd: (): void => {
    throw new Error(`focusAndMoveCursorToEnd ${ERROR_MESSAGE_SUFFIX}`);
  },
});
