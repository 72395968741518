import React, { createContext, useState, useMemo, useEffect } from "react";
import type { FunctionComponent } from "react";
import type { Integration } from "api/integrations";

export const IntegrationsFilterContext = createContext({
  isBetaFilter: false,
  setIsBetaFilter: (value: boolean): void => {
    throw new Error("Component must be nested in <CanvasFilterContext.Provider />");
  },
  isAvailableFilter: false,
  setIsAvailableFilter: (value: boolean): void => {
    throw new Error("Component must be nested in <CanvasFilterContext.Provider />");
  },
  isEnabledFilter: false,
  setIsEnabledFilter: (value: boolean): void => {
    throw new Error("Component must be nested in <CanvasFilterContext.Provider />");
  },
  isNotEnabledFilter: false,
  setIsNotEnabledFilter: (value: boolean): void => {
    throw new Error("Component must be nested in <CanvasFilterContext.Provider />");
  },
  searchText: "",
  setSearchText: (text: string): void => {
    throw new Error("Component must be nested in <CanvasFilterContext.Provider />");
  },
  isCategoryFilter: "",
  setIsCategoryFilter: (text: string): void => {
    throw new Error("Component must be nested in <CanvasFilterContext.Provider />");
  },
  isReconnectFilter: false,
  setIsReconnectFilter: (value: boolean): void => {
    throw new Error("Component must be nested in <CanvasFilterContext.Provider />");
  },
  isPreferredFilter: false,
  setIsPreferredFilter: (value: boolean): void => {
    throw new Error("Component must be nested in <CanvasFilterContext.Provider />");
  },
  isGroupedByCategory: false,
  setIsGroupedByCategory: (value: boolean): void => {
    throw new Error("Component must be nested in <CanvasFilterContext.Provider />");
  },
  availableCells: [] as Integration[],
  totalIntegrations: 0,
});

interface Props {
  cells?: Integration[];
}

const extractTokensFromCell = (cell: Required<Props>["cells"][number]) => {
  const tokens: string[] = [];

  const typedCell = cell as Integration;
  tokens.push(typedCell.name);
  return tokens;
};

export const IntegrationsFilterContextProvider: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  children,
  cells,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isCategoryFilter, setIsCategoryFilter] = useState("");
  const [isEnabledFilter, setIsEnabledFilter] = useState(false);
  const [isReconnectFilter, setIsReconnectFilter] = useState(false);
  const [isNotEnabledFilter, setIsNotEnabledFilter] = useState(false);
  const [isAvailableFilter, setIsAvailableFilter] = useState(false);
  const [isBetaFilter, setIsBetaFilter] = useState(false);
  const [isPreferredFilter, setIsPreferredFilter] = useState(false);
  const [isGroupedByCategory, setIsGroupedByCategory] = useState(false);
  const totalIntegrations = cells ? cells.length : 0;

  useEffect(() => {
    setSearchText("");
    setIsEnabledFilter(false);
    setIsNotEnabledFilter(false);
    setIsAvailableFilter(false);
    setIsBetaFilter(false);
    setIsReconnectFilter(false);
    setIsPreferredFilter(false);
    setIsGroupedByCategory(false);
  }, []);

  const availableCells = useMemo(() => {
    if (!cells) return [];

    const isMatchingSearchText = (cell) => {
      if (!searchText || searchText.trim() === "") return true;
      const searchTokens = searchText.toLowerCase().trim().split(" ");
      const cellTokens = extractTokensFromCell(cell);
      return searchTokens.every((searchToken) => cellTokens.join(" ").toLowerCase().includes(searchToken));
    };

    const isCategorySelected = (cell) => !isCategoryFilter || cell.category === isCategoryFilter;
    const isEnabledSelected = (cell) => !isEnabledFilter || cell.isEnabled === isEnabledFilter;
    const isNotEnabledSelected = (cell) => !isNotEnabledFilter || cell.isEnabled !== isNotEnabledFilter;
    const isAvailableSelected = (cell) => !isAvailableFilter || cell.isAvailable !== isAvailableFilter;
    const isBetaSelected = (cell) => !isBetaFilter || cell.isInBeta === isBetaFilter;
    const isReconnectSelected = (cell) => !isReconnectFilter || cell.isReauthRequired === isReconnectFilter;
    const isPreferredSelected = (cell) => !isPreferredFilter || cell.isPreferred === isPreferredFilter;

    return cells.reduce((accum, cell) => {
      if (
        isMatchingSearchText(cell) &&
        isCategorySelected(cell) &&
        isEnabledSelected(cell) &&
        isNotEnabledSelected(cell) &&
        isAvailableSelected(cell) &&
        isBetaSelected(cell) &&
        isReconnectSelected(cell) &&
        isPreferredSelected(cell)
      )
        accum.push(cell);

      return accum;
    }, [] as typeof cells);
  }, [
    cells,
    searchText,
    isCategoryFilter,
    isEnabledFilter,
    isNotEnabledFilter,
    isAvailableFilter,
    isBetaFilter,
    isReconnectFilter,
    isPreferredFilter,
  ]);

  return (
    <IntegrationsFilterContext.Provider
      value={{
        searchText,
        setSearchText,
        isCategoryFilter,
        setIsCategoryFilter,
        isEnabledFilter,
        setIsEnabledFilter,
        isNotEnabledFilter,
        setIsNotEnabledFilter,
        isAvailableFilter,
        setIsAvailableFilter,
        isBetaFilter,
        setIsBetaFilter,
        availableCells,
        totalIntegrations,
        isReconnectFilter,
        setIsReconnectFilter,
        isPreferredFilter,
        setIsPreferredFilter,
        isGroupedByCategory,
        setIsGroupedByCategory,
      }}>
      {children}
    </IntegrationsFilterContext.Provider>
  );
};
