import { Box, Center, Text, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent, MutableRefObject } from "react";
import React, { useCallback, useEffect, useRef } from "react";
import { ActivityTile } from "./ActivityTile";
import { useWorkflowKey } from "hooks/useWorkflows";
import { getUserWorkflows } from "api/workflows";
import { useUserProfile } from "hooks/useUserProfile";
import { useLoadItems } from "hooks/useLoadItems";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import type { VariableSizeList } from "react-window";
import { VariableSizeList as List } from "react-window";
import { useDispatch, useSelector } from "react-redux";
import { useWorkflowsFiltersContext, WorkflowsFiltersContextProvider, WorkflowsFiltersPanel } from "screens/common/components";
import { ItemLoadingIndicator } from "../collections/ItemLoadingIndicator";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { useHasConversation } from "hooks";
import { downloadConversationById } from "state/conversation/operations";
import { useLocation } from "react-router-dom";
import type { RootState } from "state/rootReducer";
import { requestToDownloadWorkflows } from "state/workflow/utils";
import { shallowEqual } from "react-redux";

const DEFAULT_HEIGHT = 62;

interface Props {
  noItemsFoundMessage?: string;
  selectedConversation?: string;
  intentFilter?: string | "allPerType" | "";
  states?: string[];
  blacklist?: string[];
  showAsList?: boolean;
}

const PAGE_SIZE = 30;

const ActivityTileRow = ({
  index,
  workflowId,
  setRowHeight,
  fetchingRecordsRef,
  selectedConversation,
  showAsList,
}: {
  index: number;
  workflowId: string;
  setRowHeight: (index: number, height: number) => void;
  fetchingRecordsRef: MutableRefObject<Record<string, string>>;
  selectedConversation?: string;
  showAsList?: boolean;
}) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const tileId = useWorkflowKey(workflowId, "id");
  const tileConversationId = useWorkflowKey(workflowId, "conversationId");
  const tileTitle = useWorkflowKey(workflowId, "title");
  const tileStatus = useWorkflowKey(workflowId, "status");
  const tileIntent = useWorkflowKey(workflowId, "intent");
  const tileCreationDate = useWorkflowKey(workflowId, "creationDate");
  const isTileDismissed = useWorkflowKey(workflowId, "dismissed");

  const hasConversation = useHasConversation(tileConversationId);
  const dispatch = useDispatch();
  const isLoadingWorkflowMap = useSelector((state: RootState) => state.workflow.isLoadingWorkflowMap, shallowEqual);

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
  }, [index, setRowHeight]);

  useEffect(() => {
    if (!tileId) {
      if (workflowId && !fetchingRecordsRef.current[workflowId]) {
        fetchingRecordsRef.current[workflowId] = workflowId;
        requestToDownloadWorkflows([workflowId], isLoadingWorkflowMap, dispatch);
      }
    } else {
      if (tileConversationId && !hasConversation && !fetchingRecordsRef.current[tileConversationId]) {
        fetchingRecordsRef.current[tileConversationId] = tileConversationId;
        dispatch(downloadConversationById({ conversationId: tileConversationId }));
      }
    }
  }, [workflowId, fetchingRecordsRef, hasConversation, dispatch, tileId, tileConversationId, isLoadingWorkflowMap]);

  if (tileId && tileConversationId && tileStatus && tileIntent && tileCreationDate) {
    return (
      <Box ref={rowRef}>
        <ActivityTile
          key={tileId}
          initiatingMessage={tileTitle}
          conversationId={tileConversationId}
          state={tileStatus}
          dismissed={isTileDismissed}
          intent={tileIntent.replace("/", "")}
          lastUpdated={new Date(tileCreationDate)}
          selected={selectedConversation === tileId}
          tileLocation="activity_list_drawer"
          workflowId={tileId}
          showAsList={showAsList}
        />
      </Box>
    );
  } else {
    return <React.Fragment />;
  }
};

export const ActivityTilesSectionPaginatedInner: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  noItemsFoundMessage,
  selectedConversation,
  intentFilter,
  states,
  blacklist,
  showAsList,
}) => {
  const { intentsIn } = useWorkflowsFiltersContext();
  const user = useUserProfile();
  const fetchingRecordsRef = useRef<Record<string, string>>({});
  const infiniteLoaderRef = useRef<InfiniteLoader | null>(null);
  const listRef = useRef<VariableSizeList | null>(null);
  const rowHeights = useRef({});
  const hasMountedRef = useRef(false);
  const bgColor = useColorModeValue("white", "gray.900");
  const location = useLocation();
  const showStatusFilter = location.pathname.includes("all") || location.pathname.includes("needs_action");

  const loadItems = async (token: string | null) => {
    const res = await getUserWorkflows(user.id ?? "", {
      ...(token && { token }),
      filters: {
        ...(states && { inStatus: states }),
        ...(blacklist && { outStatus: blacklist }),
        ...(intentsIn.length > 0 && { inIntents: intentsIn }),
      },
      limit: PAGE_SIZE,
    });

    return { data: res.data, totalCount: res.totalCount, nextToken: res.nextToken };
  };

  const {
    loading: isLoading,
    hasNextPage,
    loadMore,
    items,
    totalCount,
    clear,
  } = useLoadItems<string>({
    loadItems,
    onClear: () => {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(true);
      }
    },
  });

  const itemCount = totalCount ?? (hasNextPage ? items.length + 1 : items.length);
  const loadMoreItems = isLoading
    ? () => {
        return;
      }
    : loadMore;

  const isItemLoaded = useCallback((index: any) => !hasNextPage || index < items.length, [hasNextPage, items]);

  const setRowHeight = useCallback((index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }, []);

  const getRowHeight = useCallback((index: number) => {
    return rowHeights.current[index] + 5 || DEFAULT_HEIGHT;
  }, []);

  const Row = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const workflowId = items[index];

      return (
        <Box style={style} pr="1rem">
          <ActivityTileRow
            index={index}
            workflowId={workflowId}
            setRowHeight={setRowHeight}
            fetchingRecordsRef={fetchingRecordsRef}
            selectedConversation={selectedConversation}
            showAsList={showAsList}
          />
        </Box>
      );
    },
    [items, selectedConversation, setRowHeight, showAsList]
  );

  useEffect(() => {
    if (hasMountedRef.current) {
      clear();
    }

    hasMountedRef.current = true;
  }, [intentsIn, clear]);

  return (
    <>
      <Box height={"calc(100vh - 10rem)"}>
        <WorkflowsFiltersPanel
          hideExcludeUsersFilter
          hideIncludeUsersFilter
          hideStatusFilter={!showStatusFilter}
          hideClearButton
          isLoading={isLoading}
        />
        {!isLoading && items.length === 0 && noItemsFoundMessage && (
          <Text fontSize="sm" align="start" color="gray.500" fontWeight="400">
            {noItemsFoundMessage}
          </Text>
        )}
        {isLoading && items.length === 0 && noItemsFoundMessage && (
          <Center width="100%" height="54vh">
            <TypingIndicator />
          </Center>
        )}
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              ref={(ref) => (infiniteLoaderRef.current = ref)}
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}>
              {({ onItemsRendered, ref }) => (
                <List
                  ref={(list) => {
                    ref(list);

                    listRef.current = list;
                  }}
                  className="List"
                  height={height}
                  itemCount={itemCount}
                  itemSize={getRowHeight}
                  onItemsRendered={onItemsRendered}
                  width={width}>
                  {Row}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
        <Box position={"absolute"} bottom={"0"} backgroundColor={bgColor} borderRadius="md" zIndex={2} mb="1rem">
          <Center height="100%">
            <ItemLoadingIndicator
              onClick={loadMoreItems}
              label="Activities"
              currentItemCount={items.length}
              totalItemCount={totalCount ?? 0}
            />
          </Center>
        </Box>
      </Box>
    </>
  );
};

export const ActivityTilesSectionPaginated = (props: Props) => {
  return (
    <WorkflowsFiltersContextProvider>
      <ActivityTilesSectionPaginatedInner {...props} />
    </WorkflowsFiltersContextProvider>
  );
};
