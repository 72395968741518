import { createSlice } from "@reduxjs/toolkit";
import type { Preference } from "api/systemPreferences/models/Preference";
import { downloadSystemKeyPreferences, downloadSystemPreferences, removeSystemPreference, updateSystemPreference } from "./operations";
import { setLoaderFalse, setLoaderTrue } from "state/utils";

const initialState = {
  isLoading: false,
  systemPreferences: [] as Preference[],
  keys: [] as string[],
};

export const { actions, reducer } = createSlice({
  name: "systemPreference",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadSystemPreferences.fulfilled, (state, action) => {
      state.systemPreferences = action.payload;
      state.isLoading = false;
    });
    builder.addCase(downloadSystemKeyPreferences.fulfilled, (state, action) => {
      state.keys = action.payload;
    });

    builder.addCase(downloadSystemPreferences.pending, setLoaderTrue);
    builder.addCase(downloadSystemPreferences.rejected, setLoaderFalse);

    builder.addCase(updateSystemPreference.pending, setLoaderTrue);
    builder.addCase(updateSystemPreference.fulfilled, setLoaderFalse);
    builder.addCase(updateSystemPreference.rejected, setLoaderFalse);

    builder.addCase(removeSystemPreference.pending, setLoaderTrue);
    builder.addCase(removeSystemPreference.fulfilled, setLoaderFalse);
    builder.addCase(removeSystemPreference.rejected, setLoaderFalse);
  },
});

export default reducer;
