import React, { useCallback, useEffect, useState } from "react";
import { Text, Badge, Table, Thead, Tbody, Tr, Th, Td, Center, Stack } from "@chakra-ui/react";
import { getOrganizationLogs } from "api/organizations";
import type { EventType, OrganizationLog, TableName } from "api/organizations/models/GetOrganizationLog";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { formatDate } from "screens/common/modal/formatters";
import { useFilterInput } from "hooks/useFilterInput";

interface IProps {
  id: string | null;
}

const formatTable = (tableName: TableName) => {
  switch (tableName) {
    case "organization":
      return "organization";
    case "organization_member":
      return "member";
    case "organization_invites":
      return "email";
    default:
      return tableName;
  }
};

const formatEventType = (eventType: EventType, tableName: TableName) => {
  switch (eventType) {
    case "create":
      if (tableName.includes("member")) {
        return "added";
      } else {
        return "created";
      }
    case "update":
      return "updated";
    case "delete":
      if (tableName.includes("member")) {
        return "removed";
      } else {
        return "deleted";
      }
    case "invite":
      return "sent invitation to";
    default:
      return eventType;
  }
};

export const OrganizationLogs = (props: IProps) => {
  const { id } = props;

  const [isLoadingLogs, setIsLoadingLogs] = useState<boolean>(false);
  const [logs, setLogs] = useState<OrganizationLog[]>([]);
  const { filteredList, renderFilterInputComponent } = useFilterInput<OrganizationLog>(["modifiedByUserIdName", "eventType"], logs);

  useEffect(() => {
    if (!id) {
      return;
    }
    setIsLoadingLogs(true);
    getOrganizationLogs(id)
      .then((response) => setLogs(response))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoadingLogs(false));
  }, [logs.length, id]);

  const formatLog = useCallback((log: OrganizationLog) => {
    const formatCreateEventObject = (tableRecordName: string, eventObject: Record<string, unknown>) => {
      return (
        <span>
          {" "}
          <Badge>{tableRecordName} </Badge>
          {eventObject.role ? (
            <span>
              with role <Badge>{eventObject.role as string}</Badge>
            </span>
          ) : (
            ""
          )}
        </span>
      );
    };

    const formatUpdateObject = (tableRecordName: string, objectChanges: Record<string, { old: unknown; new: unknown }>) => {
      return (
        <span>
          <Badge>{tableRecordName}</Badge> fields:{" "}
          {Object.entries(objectChanges).map(([key, value]) => {
            return (
              <span key={key}>
                {" "}
                <Badge>{key}</Badge> from <Badge>{JSON.stringify(value.old)}</Badge> to <Badge>{JSON.stringify(value.new)}</Badge>
              </span>
            );
          })}{" "}
        </span>
      );
    };

    return (
      <>
        <Td>
          <Text width="12rem" fontSize={"sm"}>
            {formatDate(new Date(log.modifiedAt))}
          </Text>
        </Td>
        <Td>
          <Badge>{formatEventType(log.eventType, log.tableName)}</Badge>
        </Td>
        <Td>
          {formatTable(log.tableName)}
          {log.eventType === "create" && log.eventObject && formatCreateEventObject(log.tableRecordName, log.eventObject)}
          {log.eventType === "update" && log.objectChanges && formatUpdateObject(log.tableRecordName, log.objectChanges)}
          {(log.eventType === "delete" || log.eventType === "invite") && <Badge>{log.tableRecordName}</Badge>}
        </Td>
        <Td>
          <Text width="14rem" fontSize={"sm"}>
            {log.modifiedByUserIdName}
          </Text>
        </Td>
      </>
    );
  }, []);

  if (isLoadingLogs) {
    return (
      <Center height="80vh">
        <TypingIndicator />
      </Center>
    );
  }

  if (logs.length === 0) {
    return <Text>No logs found for this organization.</Text>;
  }

  return (
    <Stack>
      {renderFilterInputComponent("Filter logs user or action (create, update or delete)")}
      {logs.length === 0 ? (
        <Text>No logs found for this organization.</Text>
      ) : (
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Action</Th>
              <Th>Details</Th>
              <Th>Modified By</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredList.map((log, index) => (
              <Tr key={index}>{formatLog(log)}</Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Stack>
  );
};
