import type { Static } from "runtypes";
import { Record, String, Array } from "runtypes";
import { DetailSectionData } from "./DetailSectionData";

export const DetailSection = Record({
  sectionTitle: String,
  sectionName: String,
  data: Array(DetailSectionData),
});
export type DetailSection = Static<typeof DetailSection>;
