import { useColorModeValue, SimpleGrid, Stack, useBreakpointValue } from "@chakra-ui/react";
import { differenceInHours, differenceInMilliseconds, differenceInMinutes, differenceInSeconds } from "date-fns";
import { useContents } from "hooks";
import React, { useMemo } from "react";
import type { CollectionWithAdditionalProps } from "types/collection";
import type { ContentDetails } from "types/content/ContentDetails";
import { StockDataRecords } from "types/stock";

export enum ManualTags {
  projectOutput = "project_output",
}

export const hasProjectOutput = (content?: ContentDetails): boolean => {
  return content ? content.manualTags.includes(ManualTags.projectOutput) : false;
};

export function StateBgColor(state: string) {
  const errorColor = useColorModeValue("orange.200", "red.800");
  const warningColor = useColorModeValue("orange.200", "orange.800");
  const inProgressColor = useColorModeValue("#86cda1", "green.800");
  const defaultColor = useColorModeValue("gray.400", "gray.600");

  switch (state) {
    case "failed":
      return errorColor;
    case "clarification_needed":
      return warningColor;
    case "in_progress":
    case "queued":
      return inProgressColor;
    default:
      return defaultColor;
  }
}

export const SectionContentLayout = ({ children, useGrid, className }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const layout = useMemo(() => {
    if (useGrid && !isMobile) {
      return (
        <SimpleGrid columns={2} spacing="2rem" className={className}>
          {children}
        </SimpleGrid>
      );
    }
    return (
      <Stack spacing="2rem" className={className}>
        {children}
      </Stack>
    );
  }, [children, className, isMobile, useGrid]);

  return layout;
};

export function useProjectTimeSeriesData(collection: CollectionWithAdditionalProps): StockDataRecords | null {
  const contents = useContents(collection.metadataIds);

  return useMemo(() => {
    if (!contents) {
      return null;
    }

    const stockPrice = contents.find((content) => content.manualTags.includes("stock_price"));

    if (!stockPrice) {
      return null;
    }

    const validateStockData = StockDataRecords.validate(stockPrice.dynamicData?.dynamic_data);

    if (validateStockData.success) {
      return validateStockData.value;
    } else {
      return null;
    }
  }, [contents]);
}

export function calculateTimeDifference(initialDateTime: Date, completionDateTime: Date, includeMs = false): string {
  const { hours, minutes, seconds, milliseconds } = calculateTimeDifferenceWithoutFormat(initialDateTime, completionDateTime);
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  const formattedMilliseconds = String(milliseconds).padStart(3, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}${includeMs ? `.${formattedMilliseconds}` : ""}`;
}

export function calculateTimeDifferenceWithoutFormat(
  initialDateTime: Date,
  completionDateTime: Date
): { hours: number; minutes: number; seconds: number; milliseconds: number } {
  // Calculate the time difference in minutes
  const diffMinutes = differenceInMinutes(completionDateTime, initialDateTime);

  // Calculate the time difference in hours
  const diffHours = differenceInHours(completionDateTime, initialDateTime);

  const diffSeconds = differenceInSeconds(completionDateTime, initialDateTime);
  const diffMilliseconds = differenceInMilliseconds(completionDateTime, initialDateTime);

  // Calculate the remaining minutes after subtracting full hours
  const remainingMinutes = diffMinutes % 60;
  const remainingSeconds = diffSeconds % 60;
  const remainingMilliseconds = diffMilliseconds % 1000;

  return {
    hours: diffHours,
    minutes: remainingMinutes,
    seconds: remainingSeconds,
    milliseconds: remainingMilliseconds,
  };
}
