import { Record, Union, String, Array } from "runtypes";
import { CopyKey } from "./CopyKey";
import type { Static } from "runtypes";

export const CopyValue = Union(String, Array(String));

export const Copy = Record({
  key: CopyKey,
  value: CopyValue,
  createdAt: String,
  updatedAt: String.optional(),
  createdByUser: String,
  updatedByUser: String.optional(),
});

export type Copy = Static<typeof Copy>;
export type CopyValue = Static<typeof CopyValue>;
