import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizationEntitlements } from "api/entitlements";
import {
  listOrganizations,
  deleteOrganization as deleteOrganizationAPI,
  addOrganizationMembers as addOrganizationMembersAPI,
  createOrganization as createOrganizationAPI,
  removeMember,
  updateMemberRole as updateMemberRoleAPI,
  updateOrganization as updateOrganizationAPI,
  getOrganization,
  createAndAddOrgUser,
} from "api/organizations";
import type { AddMembersRequest } from "api/organizations/models/AddOrganizationMembers";
import type { CreateNewOrgUserRequest } from "api/organizations/models/CreateNewOrgUserRequest";
import type { CreateOrganizationRequest } from "api/organizations/models/CreateOrganizationRequest";
import type { UpdateOrganizationRequest } from "api/organizations/models/UpdateOrganizationRequest";
import type { Role } from "types/organization/Role";

export const downloadOrganizations = createAsyncThunk("organizations/download-all", async () => {
  return await listOrganizations();
});

export const downloadOrganization = createAsyncThunk(
  "organizations/download-one",
  async ({ organizationId }: { organizationId: string }) => {
    return await getOrganization(organizationId);
  }
);

export const deleteOrganization = createAsyncThunk("organizations/delete", async ({ organizationId }: { organizationId: string }) => {
  return await deleteOrganizationAPI(organizationId);
});

export const createOrganization = createAsyncThunk("organizations/create", async (organization: CreateOrganizationRequest) => {
  return await createOrganizationAPI(organization);
});

export const updateOrganization = createAsyncThunk(
  "organizations/update",
  async ({ organizationId, payload }: { organizationId: string; payload: UpdateOrganizationRequest }) => {
    return await updateOrganizationAPI(organizationId, payload);
  }
);

export const addOrganizationMembers = createAsyncThunk(
  "organizations/add-members",
  async ({ organizationId, members }: { organizationId: string; members: AddMembersRequest }) => {
    return await addOrganizationMembersAPI(organizationId, members);
  }
);

export const createAndAddOrganizationMembers = createAsyncThunk(
  "organizations/create-add-members",
  async ({ createRequests }: { createRequests: CreateNewOrgUserRequest[] }) => {
    return await createAndAddOrgUser(createRequests);
  }
);

export const removeMemberFromOrganization = createAsyncThunk(
  "organizations/remove-member",
  async ({ organizationId, memberId }: { organizationId: string; memberId: string }) => {
    return await removeMember(organizationId, memberId);
  }
);

export const updateMemberRole = createAsyncThunk(
  "organizations/update-member-role",
  async ({ organizationId, memberId, role }: { organizationId: string; memberId: string; role: Role }) => {
    return await updateMemberRoleAPI(organizationId, memberId, role);
  }
);

export const downloadOrganizationEntitlements = createAsyncThunk(
  "organizations/entitlements/download",
  async ({ organizationId }: { organizationId: string }) => {
    return await getOrganizationEntitlements(organizationId);
  }
);
