import { createSlice } from "@reduxjs/toolkit";
import type { Plan } from "types/billingPlans/Plan";
import type { Entitlement } from "types/entitlements";
import { createBillingPlan, downloadBillingPlans, downloadPlanEntitlements, updateBillingPlan } from "./operations";

interface BillingPlansState {
  plans: Record<string, Plan>;
  planCodes: string[];
  loading: boolean;
  planEntitlements: Record<string, Entitlement[]>;
  loadingEntitlements: boolean;
}

const initialState: BillingPlansState = {
  plans: {},
  planCodes: [],
  loading: false,
  planEntitlements: {},
  loadingEntitlements: false,
};

export const { actions, reducer } = createSlice({
  name: "billingPlans",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadBillingPlans.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadBillingPlans.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadBillingPlans.fulfilled, (state, action) => {
      state.loading = false;
      const { payload: billingPlans } = action;

      const codes = billingPlans.map((plan) => plan.code);

      const newBillingPlansState: Record<string, Plan> = {};
      billingPlans.forEach((plan) => {
        newBillingPlansState[plan.code] = plan;
      });

      state.plans = newBillingPlansState;
      state.planCodes = codes;
    });

    builder.addCase(createBillingPlan.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBillingPlan.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createBillingPlan.fulfilled, (state, action) => {
      state.loading = false;
      const newPlan = action.payload;

      state.plans[newPlan.code] = newPlan;
      state.planCodes.push(newPlan.code);
    });

    builder.addCase(updateBillingPlan.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBillingPlan.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateBillingPlan.fulfilled, (state, action) => {
      state.loading = false;
      const billingPlan = action.payload;

      state.plans[billingPlan.code] = billingPlan;
    });

    builder.addCase(downloadPlanEntitlements.pending, (state) => {
      state.loadingEntitlements = true;
    });
    builder.addCase(downloadPlanEntitlements.rejected, (state) => {
      state.loadingEntitlements = false;
    });
    builder.addCase(downloadPlanEntitlements.fulfilled, (state, action) => {
      state.loadingEntitlements = false;
      state.planEntitlements[action.meta.arg.planCode] = action.payload;
    });
  },
});

export default reducer;
