import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllEntityConfigurations,
  getEntityConfiguration,
  createEntityConfiguration,
  updateEntityConfiguration,
  deleteEntityConfiguration,
} from "api/entityConfigurations";
import type {
  CreateEntityConfigurationRequest,
  UpdateEntityConfigurationRequest,
} from "api/entityConfigurations/models/EntityConfigurationApi";

export const downloadEntityConfigurations = createAsyncThunk("entity-configurations/download-all", () => {
  return getAllEntityConfigurations();
});

export const downloadEntityConfiguration = createAsyncThunk("entity-configurations/download", (entityName: string) => {
  return getEntityConfiguration(entityName);
});

export const createEntityConfigurationAction = createAsyncThunk(
  "entity-configurations/create",
  (payload: CreateEntityConfigurationRequest) => {
    return createEntityConfiguration(payload);
  }
);

export const updateEntityConfigurationAction = createAsyncThunk(
  "entity-configurations/update",
  ({ entityName, payload }: { entityName: string; payload: UpdateEntityConfigurationRequest }) => {
    return updateEntityConfiguration(entityName, payload);
  }
);

export const deleteEntityConfigurationAction = createAsyncThunk("entity-configurations/delete", (entityId: string) => {
  return deleteEntityConfiguration(entityId);
});
