import wretch from "wretch";
import type { Wretcher } from "wretch";
import { getAccessToken, getFullToken, restoreAstrellaSession, restoreSession } from "api/auth";
import { BadRequestError, translateError } from "./networkingErrors";
import { actions as sessionActions } from "state/session/reducer";
import store from "state/store";
import type { AuthToken } from "../types/auth";
import { decode } from "jsonwebtoken";

export const request = (injectToken = true, refreshTokenImplicitly = true): Wretcher => {
  const accessToken = getAccessToken();
  const wretchApi = wretch()
    .headers(getInterceptId())
    .catcher(401, async (error, request) => {
      if (!refreshTokenImplicitly) {
        translateError(error);
        return;
      }
      // Refresh token
      const token = getFullToken();
      if (token) {
        try {
          const newToken = await refreshAccessToken(token);
          store.dispatch(sessionActions.setToken(newToken.token));
          return request
            .auth(`Bearer ${newToken.token.access_token}`)
            .replay()
            .unauthorized((err) => {
              // Token still bad, kill the session.
              console.error(err);
              store.dispatch(sessionActions.loginFailed({ error: BadRequestError.STALE_TOKEN }));
            })
            .json();
        } catch (err) {
          // Another error occurred when refreshing. Still treat as if the session has expired.
          console.error(err);
          store.dispatch(sessionActions.loginFailed({ error: BadRequestError.STALE_TOKEN }));
        }
      } else {
        throw error;
      }
    })
    .resolve((resolver) =>
      resolver.badRequest(translateError).internalError(translateError).forbidden(translateError).error(409, translateError)
    );

  if (injectToken && accessToken) {
    return wretchApi.auth(`Bearer ${accessToken}`);
  } else {
    return wretchApi;
  }
};

export function prependProxyBaseUrl(path: string): string {
  if (window?.env?.proxyBaseUrl) {
    return `${window.env.proxyBaseUrl}${path}`;
  } else {
    return path;
  }
}

function getInterceptId() {
  const interceptId = (() => {
    try {
      return localStorage.getItem("x-telepresence-intercept-id") || localStorage.getItem("interceptId");
    } catch (error) {
      console.warn("Failed to read localStorage from Window. Cookies are probably been blocked.");
      return undefined;
    }
  })();

  const headers = {};

  if (interceptId) {
    headers["x-telepresence-intercept-id"] = interceptId;
  }

  return headers;
}

async function refreshAccessToken(token: AuthToken) {
  const accessToken = token.access_token;
  const jwt = decode(accessToken, { json: true });

  if (jwt && jwt.azp === window.env.astrellaTokenExchangeClient) {
    return restoreAstrellaSession(token);
  }

  return restoreSession(token);
}
