import { createSlice } from "@reduxjs/toolkit";
import type { MemberOrganization } from "types/organization/MemberOrganization";
import { getMemberOrganizations } from "./operations";

interface MemberOrganizationsState {
  isLoading: boolean;
  memberOrganizations: Record<string, MemberOrganization>;
}

const initialState: MemberOrganizationsState = {
  isLoading: false,
  memberOrganizations: {},
};

export const { actions, reducer } = createSlice({
  name: "memberOrganizations",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMemberOrganizations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMemberOrganizations.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMemberOrganizations.fulfilled, (state, action) => {
      state.isLoading = false;

      const memberOrganizations = action.payload;
      memberOrganizations.forEach((memberOrg) => {
        state.memberOrganizations[memberOrg.organizationId] = memberOrg;
      });
    });
  },
});

export default reducer;
