import { Badge, Box, Button, SimpleGrid, Stack, Switch, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useAppDispatch, useButtonProps } from "hooks";
import { useBillingPlans, useIsBillingPlanLoading } from "hooks/useBillingPlans";
import { BillingPlanModal } from "./modals/BillingPlanModal";
import { BillinPlanUpsertModal } from "./modals/BillingPlanUpsertModal";
import { ConfirmDeleteModal } from "screens/common/components/ConfirmDeleteModal";
import { updateBillingPlan } from "state/billingPlans/operations";

const MODALS_KEYS = {
  DETAIL: "detail",
  UPSERT: "upsert",
  DEACTIVATE: "deactivate",
};

export const BillingPlans = () => {
  const [isOpen, setIsOpen] = useState<string | undefined>();
  const [selectedCode, setSelectedCode] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const billingPlans = useBillingPlans();

  const onClose = useCallback(() => {
    setIsOpen(undefined);
    setSelectedCode(null);
  }, []);

  const onOpenUpsert = useCallback((code?: string) => {
    if (code) setSelectedCode(code);
    setIsOpen(MODALS_KEYS.UPSERT);
  }, []);

  // styles
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const commonButtonProps = useButtonProps("sm", "primary");
  const subTitleColor = useColorModeValue("charli.mediumGray", "gray.400");
  const toast = useToast();
  const isLoadingBilling = useIsBillingPlanLoading();

  const handleSwitchStatus = useCallback(
    async (active: boolean, code: string) => {
      const response = await dispatch(
        updateBillingPlan({
          code,
          payload: {
            isActive: active,
          },
        })
      );

      if (response.type === updateBillingPlan.rejected.type) {
        toast({
          title: "Error",
          description: "An error occurred while deactivating the billing plan",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Success",
          description: `Billing plan ${active ? "activated" : "deactivated"}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }

      onClose();
    },
    [dispatch, onClose, toast]
  );

  const renderPlans = useCallback(() => {
    return (billingPlans || []).map((plan, index) => {
      return (
        <Box
          fontSize={"sm"}
          key={index}
          position="relative"
          cursor="pointer"
          backgroundColor={bgColor}
          borderRadius="md"
          overflow="hidden"
          borderColor={borderColor}
          borderWidth="1px"
          boxShadow="none"
          onClick={(event) => {
            event.stopPropagation();

            setSelectedCode(plan.code);
            setIsOpen(MODALS_KEYS.UPSERT);
          }}>
          <Stack
            onClick={(event) => event.stopPropagation()}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            backgroundColor={borderColor}
            p=".5rem">
            <Text width="100%" isTruncated color={subTitleColor} fontWeight="semibold">
              {plan.name}
            </Text>
            <Switch
              isChecked={plan.isActive}
              onChange={(event) => {
                event.stopPropagation();

                if (event.target.checked) {
                  handleSwitchStatus(true, plan.code);
                } else {
                  setSelectedCode(plan.code);
                  setIsOpen(MODALS_KEYS.DEACTIVATE);
                }
              }}
            />
          </Stack>
          <Box p=".5rem">
            <Text mb={"0.5rem"} color={subTitleColor} fontWeight="semibold">
              Code: {plan.code}
            </Text>
            <Text mb={"0.5rem"} color={subTitleColor} fontWeight="semibold">
              Plans can switch from: {plan.plansCanSwitchFrom?.join(", ") || "None"}
            </Text>

            <Text mb={"0.5rem"} color={subTitleColor} fontWeight="semibold">
              Product handle: {plan.details.productHandle}
            </Text>
            {plan.isDefault && <Badge colorScheme="green">Default</Badge>}
          </Box>
        </Box>
      );
    });
  }, [billingPlans, bgColor, borderColor, subTitleColor, handleSwitchStatus]);

  return (
    <>
      <Stack direction="row" justifyContent="end" pb="1rem" mt="0!important">
        <Button {...commonButtonProps} onClick={() => onOpenUpsert()}>
          Create New Plan
        </Button>
      </Stack>
      <SimpleGrid columns={[1, 2, 3, 4, 5]} spacingX="1rem" spacingY="1rem">
        {renderPlans()}
      </SimpleGrid>

      {isOpen === MODALS_KEYS.DETAIL && selectedCode && <BillingPlanModal code={selectedCode} isOpen onClose={onClose} />}
      {isOpen === MODALS_KEYS.UPSERT && <BillinPlanUpsertModal code={selectedCode} isOpen onClose={onClose} />}
      {isOpen === MODALS_KEYS.DEACTIVATE && selectedCode && (
        <ConfirmDeleteModal
          isOpen
          onClose={onClose}
          onConfirm={() => {
            handleSwitchStatus(false, selectedCode);
          }}
          buttonLabel="Deactivate"
          title="Confirm Delete"
          body={"Are you sure you want to deactivate this billing plan?"}
          isLoading={isLoadingBilling}
        />
      )}
    </>
  );
};
