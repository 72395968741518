import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, useColorModeValue, Badge, Skeleton, useBreakpointValue } from "@chakra-ui/react";
import { FixedSizeList as List } from "react-window";
import { getStatusColor } from "screens/common/components/WorkflowSummary";
import { getWorkflowsAdmin } from "api/workflowsAdmin";
import { useLoadItems } from "hooks/useLoadItems";
import capitalize from "lodash/capitalize";
import InfiniteLoader from "react-window-infinite-loader";
import type { Workflow } from "types/workflows/workflow";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import AutoSizer from "react-virtualized-auto-sizer";
import { WorkflowsFiltersContextProvider, useWorkflowsFiltersContext, WorkflowsFiltersPanel } from "screens/common/components";
import { WorkflowOperationsCharts } from "./WorkflowOperationsCharts";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";

const MENUS_APPROX_HEIGHT = 230;
const COLUMNS_WIDTH = 250;
const DATE_COLUMN_WIDTH = 220;
const PROGRESS_WIDTH = 300;
const DURATION_COLUMN_WIDTH = 130;
const STATUS_COLUMN_WIDTH = 180;
const PAGE_SIZE = 5000;
const ITEM_SIZE = 30;

interface Props {
  hideProgressColumn?: boolean;
}

const WorkflowsAdminBase = (props: Props) => {
  const { hideProgressColumn = false } = props;
  const { usersInIds, usersOutIds, statusIn, intentsIn } = useWorkflowsFiltersContext();
  const bgColor = useColorModeValue("gray.200", "gray.700");
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");
  const startColor = useColorModeValue("gray.100", "gray.900");
  const endColor = useColorModeValue("gray.50", "gray.800");
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const infiniteLoaderRef = useRef<InfiniteLoader | null>(null);
  const hasMountedRef = useRef(false);

  const [refreshCounter, setRefreshCounter] = useState(0);

  const handleRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  useEffect(() => {
    if (refreshCounter > 0) {
      clear();
      loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const loadItems = useCallback(
    async (token: string | null) => {
      const filters = {
        ...(usersInIds.length > 0 && { inUsersIds: usersInIds }),
        ...(usersOutIds.length > 0 && { outUsersIds: usersOutIds }),
        ...(statusIn.length > 0 && { inStatus: statusIn }),
        ...(intentsIn.length > 0 && { inIntents: intentsIn }),
      };

      const res = await getWorkflowsAdmin({
        ...(token && { token }),
        limit: PAGE_SIZE,
        ...(Object.keys(filters).length > 0 && { filters }),
      });

      return { data: res.data, totalCount: res.totalCount, nextToken: res.nextToken };
    },
    [usersInIds, usersOutIds, statusIn, intentsIn]
  );

  const {
    loading: isLoading,
    hasNextPage,
    loadMore,
    items,
    clear,
    totalCount,
  } = useLoadItems<Workflow>({
    loadItems,
    onClear: () => {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(true);
      }
    },
  });

  const itemCount = totalCount ?? (hasNextPage ? items.length + 1 : items.length);
  const loadMoreItems = useCallback(
    (startIndex: number, stopIndex: number): void | Promise<void> => {
      return isLoading
        ? (() => {
            return;
          })()
        : loadMore();
    },
    [isLoading, loadMore]
  );

  const isItemLoaded = useCallback((index: any) => !hasNextPage || index < items.length, [hasNextPage, items]);
  const handleOnClick = (workflow: Workflow) => {
    navigate(`${workflow.id}${location.search}`);
  };

  const Item = useCallback(
    ({ index, style }: any) => {
      if (!isItemLoaded(index)) {
        return (
          <div style={{ ...style, marginBottom: 10 }}>
            <Skeleton width="100%" fadeDuration={0.5} speed={0.8} startColor={startColor} endColor={endColor} height={ITEM_SIZE}>
              <div>won't be visible</div>
            </Skeleton>
          </div>
        );
      } else {
        const workflow = items[index];
        const creationDate = workflow.creationDate;
        const completionDate = workflow.completionDate || moment();

        const durationMilliseconds = moment(completionDate).diff(moment(creationDate)) / 1000;
        const durationSeconds = moment(completionDate).diff(moment(creationDate), "seconds");
        const durationInMinutes = moment(completionDate).diff(moment(creationDate), "minutes");
        const durationInHours = moment(completionDate).diff(moment(creationDate), "hours");
        const durationInDays = moment(completionDate).diff(moment(creationDate), "days");

        let duration = "";
        if (!completionDate) {
          duration = `-`;
        } else if (durationInDays > 0) {
          duration = `${durationInDays}d ${durationInHours - durationInDays * 24}h`;
        } else if (durationInHours > 0) {
          duration = `${durationInHours}h ${durationInMinutes - durationInHours * 60}m`;
        } else if (durationInMinutes > 0) {
          duration = `${durationInMinutes}m ${durationSeconds - durationInMinutes * 60}s`;
        } else {
          duration = durationSeconds === 0 ? `${durationMilliseconds.toPrecision(2)}s` : `${durationSeconds}s`;
        }

        return (
          <div style={style}>
            <Box
              height={ITEM_SIZE}
              fontSize="sm"
              onClick={() => handleOnClick(workflow)}
              p={1}
              _hover={{ bgColor }}
              role="button"
              display={"flex"}
              justifyContent="space-between">
              <Box width={COLUMNS_WIDTH} pr=".5rem" overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
                {workflow.intent}
              </Box>
              <Box width={DATE_COLUMN_WIDTH} pr=".5rem">
                {moment(new Date(workflow.creationDate)).format("MMM-DD-YYYY, h:mm a")}
              </Box>
              {!hideProgressColumn && (
                <Box width={PROGRESS_WIDTH} pr=".5rem">
                  <WorkflowProgressBar workflowId={workflow.id} size="full" showInline={false} hideCompletedInDate />
                </Box>
              )}
              <Box width={DURATION_COLUMN_WIDTH} pr=".5rem">
                {duration}
              </Box>
              <Box width={COLUMNS_WIDTH} pr=".5rem">
                {workflow.userName}
              </Box>
              <Box width={STATUS_COLUMN_WIDTH} pr=".5rem">
                <Badge colorScheme={getStatusColor(workflow.status)}>{capitalize(workflow.status).split("_").join(" ")}</Badge>
              </Box>
            </Box>
          </div>
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isItemLoaded, items, bgColor]
  );

  useEffect(() => {
    // This effect will run on mount too; there's no need to reset in that case.
    if (hasMountedRef.current) {
      clear();
    }

    hasMountedRef.current = true;
  }, [usersInIds, usersOutIds, statusIn, intentsIn, clear]);

  return (
    <Box
      width="100%"
      fontSize="sm"
      height={`calc(100% - ${MENUS_APPROX_HEIGHT}px)`}
      px={isMobile ? ".5rem" : "1rem"}
      pt={isMobile ? ".5rem" : "1rem"}
      mt="0!important">
      <WorkflowOperationsCharts filteredWorkflows={items} />
      <WorkflowsFiltersPanel
        isLoading={isLoading}
        totalWorkflows={totalCount || 0}
        filteredWorkflows={items.length}
        refreshWorkflows={handleRefresh}
      />
      <Box borderBottomColor={bgColor} borderBottom={`2px solid`} mb={1} color={titleColor} display={"flex"} justifyContent="space-between">
        <Box width={COLUMNS_WIDTH}>Workflow Intent</Box>
        <Box width={DATE_COLUMN_WIDTH}>Creation date</Box>
        {!hideProgressColumn && <Box width={PROGRESS_WIDTH}>Progress</Box>}
        <Box width={DURATION_COLUMN_WIDTH}>Duration</Box>
        <Box width={COLUMNS_WIDTH}>Created by</Box>
        <Box width={STATUS_COLUMN_WIDTH + 20}>Status</Box>
      </Box>
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            ref={(el) => (infiniteLoaderRef.current = el)}
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}>
            {({ onItemsRendered, ref }) => (
              <List
                className="List"
                height={height}
                itemCount={itemCount}
                itemSize={ITEM_SIZE}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width={width}>
                {Item}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
      <Outlet />
    </Box>
  );
};

export const WorkflowOperations = (props: Props) => {
  return (
    <WorkflowsFiltersContextProvider>
      <WorkflowsAdminBase {...props} />
    </WorkflowsFiltersContextProvider>
  );
};
