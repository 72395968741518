import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useEntitlements, useButtonProps, useAppDispatch } from "hooks";
import { useEntityLoading } from "hooks/useEntityConfigurations";
import type { ModalKey } from "types/modal";
import { isError, UpsertMemorizedClarificationConfigModal } from "./components/UpsertMemorizedClarificationConfig";
import type { FormValues } from "./components/UpsertMemorizedClarificationConfig";
import { useMemorizedClarificationConfigs } from "hooks/useMemorizedClarificationConfig";
import type { MemorizedClarificationConfiguration } from "api/memorizedClarificationConfig/models/MemorizedClarificationConfiguration";
import { AdminTiles } from "screens/common/components";
import {
  createMemorizedClarificationConfigAction,
  updateMemorizedClarificationConfigAction,
  deleteMemorizedClarificationConfigAction,
} from "state/memorizedClarificationConfig/operations";

export const MemorizedClarificationConfigs = () => {
  const [isOpen, setIsOpen] = useState<ModalKey | undefined>();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const memorizedClarificationConfigs = useMemorizedClarificationConfigs();
  const { manage_memorized_clarification_config_read: hasRead, manage_memorized_clarification_config_write: hasWrite } = useEntitlements();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();
  const commonButtonProps = useButtonProps("sm", "primary");
  const toast = useToast();
  const isEntityLoading = useEntityLoading();

  const onOpenUpsert = useCallback((id?: string) => {
    if (id) {
      setSelectedId(id);
    }

    setIsOpen("upsert");
  }, []);

  const onOpenDelete = useCallback((id: any) => {
    setSelectedId(id);
    setIsOpen("delete");
  }, []);

  const onClose = useCallback(() => setIsOpen(undefined), []);

  const onDelete = useCallback(async () => {
    if (!selectedId) {
      return;
    }

    const deleteResponse = await dispatch(deleteMemorizedClarificationConfigAction(selectedId));

    if (isError(deleteResponse)) {
      toast({
        title: "Error",
        description: deleteResponse.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    setIsOpen(undefined);
  }, [selectedId, toast, dispatch]);

  const onSubmit = useCallback(
    async (fields: FormValues) => {
      try {
        const response = await (() => {
          if (!selectedId) {
            return dispatch(
              createMemorizedClarificationConfigAction({
                entityName: fields.memorizedEntityName,
                matchingEntityNames: fields.matchingEntityNames.map(({ value }) => value),
              })
            );
          } else {
            return dispatch(
              updateMemorizedClarificationConfigAction({
                id: selectedId,
                matchingEntityNames: fields.matchingEntityNames.map(({ value }) => value),
              })
            );
          }
        })();

        if (response.type === createMemorizedClarificationConfigAction.rejected.type) {
          toast({
            title: "Error",
            description: "Error when creating memorized clarification config",
            status: "error",
            duration: 5000,
            isClosable: true,
          });

          return;
        } else if (response.type === updateMemorizedClarificationConfigAction.rejected.type) {
          toast({
            title: "Error",
            description: "Error when updating memorized clarification config",
            status: "error",
            duration: 5000,
            isClosable: true,
          });

          return;
        } else {
          toast({
            title: "Success",
            description: `Successfully ${selectedId ? "updated" : "created"} memorized clarification config`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          onClose();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, onClose, selectedId, toast]
  );

  useEffect(() => {
    if (isOpen === undefined) {
      setTimeout(() => {
        setSelectedId(null);
      }, 500);
    }
  }, [isOpen]);

  return (
    <Box>
      <AdminTiles<MemorizedClarificationConfiguration>
        items={memorizedClarificationConfigs}
        fieldsToRender={[]}
        filterByFields={["memorizedEntityName"]}
        defaultSortByKey="memorizedEntityName"
        defaultOrderBy="asc"
        sortByFields={["memorizedEntityName", "lastUpdatedByUserId"]}
        inputFilterPlaceholder="Search by entity name or Last updated by name"
        onClickCreate={() => onOpenUpsert()}
        onClickDelete={onOpenDelete}
        onClickEdit={onOpenUpsert}
        hasWrite={hasWrite}
        hasRead={hasRead}
        tileTitle="Memorized Clarification"
        tileTitleKey="memorizedEntityName"
        keyName="id"
      />

      {isOpen === "upsert" && (
        <UpsertMemorizedClarificationConfigModal {...(selectedId && { id: selectedId })} isOpen onClose={onClose} onSubmit={onSubmit} />
      )}

      <AlertDialog isOpen={isOpen === "delete"} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="sm" fontWeight="bold">
              Delete Memorized Clarification Configuration
            </AlertDialogHeader>

            <AlertDialogBody fontSize="sm">Are you sure? You can't undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button disabled={isEntityLoading} {...commonButtonProps} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button disabled={isEntityLoading} {...commonButtonProps} onClick={onDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
