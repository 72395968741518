import type { Static } from "runtypes";
import { Record, String } from "runtypes";
import { DateAsString } from "@charliai/node-core-lib/lib/src/shared/DateAsString";

export const SharedWithMe = Record({
  senderName: String,
  message: String.nullable(),
  acceptedAt: DateAsString.nullable(),
});

export type SharedWithMeType = Static<typeof SharedWithMe>;
