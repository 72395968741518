import { createSlice } from "@reduxjs/toolkit";
import type { WorkflowStatus } from "api/workflows/models/WorkflowStatus";
import omit from "lodash/omit";
import { downloadProgressSteps } from "state/progressSteps/operations";

export type WorkflowStatusStep = {
  [key in WorkflowStatus]: {
    count: number;
    latestCreationDate: string;
    latestCompletionDate: string | null;
  };
};

export type IntentStatuses = Record<string, WorkflowStatusStep>;

interface ProgressStepsState {
  isLoadingSteps: Record<string, boolean>;
  stepsByWorkflow: Record<string, { workflowIntentIds: string[] }>;
  stepsByWorkflowIntent: Record<string, { intent: string; workflowIntentStatusIds: string[] }>;
  stepsByWorkflowIntentStatus: Record<
    string,
    { status: WorkflowStatus; count: number; latestCreationDate: string; latestCompletionDate: string | null }
  >;
}

const initialState: ProgressStepsState = {
  isLoadingSteps: {},
  stepsByWorkflow: {},
  stepsByWorkflowIntent: {},
  stepsByWorkflowIntentStatus: {},
};

export const { actions, reducer } = createSlice({
  name: "progressSteps",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadProgressSteps.pending, (state, { meta }) => {
      state.isLoadingSteps = Object.assign(
        {},
        state.isLoadingSteps,
        meta.arg.workflowIds.reduce((acc, id) => ({ ...acc, [id]: true }), {})
      );
    });
    builder.addCase(downloadProgressSteps.rejected, (state, { meta }) => {
      const { workflowIds } = meta.arg;
      state.isLoadingSteps = omit(state.isLoadingSteps, workflowIds);
    });
    builder.addCase(downloadProgressSteps.fulfilled, (state, { payload, meta }) => {
      const { workflowIds } = meta.arg;
      state.isLoadingSteps = omit(state.isLoadingSteps, workflowIds);

      payload.forEach((workflowSteps) => {
        if (workflowSteps.intentStatus) {
          const workflowIntents = Object.keys(workflowSteps.intentStatus).map((intentId) => `${workflowSteps.id}-${intentId}`);
          state.stepsByWorkflow[workflowSteps.id] = { workflowIntentIds: workflowIntents };

          Object.entries(workflowSteps.intentStatus).forEach(([intent, statusProgress]) => {
            const progress = statusProgress as WorkflowStatusStep;
            const intentStatuses = Object.keys(progress).map((status) => `${workflowSteps.id}-${intent}-${status}`);
            state.stepsByWorkflowIntent[`${workflowSteps.id}-${intent}`] = { intent, workflowIntentStatusIds: intentStatuses };

            Object.entries(progress).forEach(([status, statusProgress]) => {
              state.stepsByWorkflowIntentStatus[`${workflowSteps.id}-${intent}-${status}`] = {
                status: status as WorkflowStatus,
                count: statusProgress.count,
                latestCreationDate: statusProgress.latestCreationDate,
                latestCompletionDate: statusProgress.latestCompletionDate,
              };
            });
          });
        }
      });
    });
  },
});

export default reducer;
