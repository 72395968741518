import type { Static } from "runtypes";
import { Record, String, Array } from "runtypes";
import { Field } from "./Field";

export const Schema = Record({
  fields: Array(Field),
  primaryKey: Array(String).optional(),
  pandas_version: String.optional(),
});

export type Schema = Static<typeof Schema>;
