import { createAsyncThunk } from "@reduxjs/toolkit";
import { getContent, getRecentContents } from "api/content";
import type { RootState } from "state/rootReducer";
import { ContentDetails } from "types/content/ContentDetails";
import { actions as collectionMetadataActions } from "state/collectionMetadata/reducer";

export const downloadContents = createAsyncThunk(
  "content/download-by-metadata-ids",
  async ({ metadataIds }: { metadataIds: string[] }, thunkAPI) => {
    const collectionsContents = (thunkAPI.getState() as RootState).collectionMetadata.contentsByIds;
    const contents = await getContent(metadataIds);

    if (contents !== "error") {
      const contentDetailsMap: Record<string, ContentDetails> = {};

      for (let i = 0; i < contents.length; i++) {
        const content = contents[i];
        const contentDetail = new ContentDetails(content);
        contentDetailsMap[contentDetail.id] = contentDetail;

        if (collectionsContents && contentDetail.id in collectionsContents) {
          thunkAPI.dispatch(collectionMetadataActions.updateCollectionMetadata(contentDetail));
        }
      }

      return contentDetailsMap;
    } else {
      console.error(`Failed to get chunk of metadataIds from content-service`);
      return {};
    }
  }
);

export const downloadRecentContents = createAsyncThunk("content/recent", async ({ limit }: { limit: number }, thunkAPI) => {
  const recentContents = await getRecentContents(limit);

  thunkAPI.dispatch(downloadContents({ metadataIds: recentContents.metadataIds }));

  return recentContents;
});
