import { useSelector, shallowEqual } from "react-redux";
import type { RootState } from "state/rootReducer";

/**
 * Returns a user's JSON web token.
 */
export function useJWT() {
  const jwt = useSelector((state: RootState) => state.session.token, shallowEqual);

  // Since the token could be null, return each property of user as an optional to make the hook more ergnomic (eg. allowing the JWT object to be destructured)
  return {
    accessToken: jwt?.access_token,
    refreshToken: jwt?.refresh_token,
  };
}
