import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useRef } from "react";
import { Profile } from "./tabs/profile";
import { About } from "./tabs/about";
import { Config } from "./tabs/config";
import { ConfigLayoutOptions } from "./tabs/config/ConfigLayoutOptions";
import { SubscriptionInformation } from "./tabs/subscription";
import { useFeatureFlags } from "hooks";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";

export const Settings = () => {
  const tileRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(tileRef);
  const { enable_new_pricing: showNewPricing } = useFeatureFlags();

  return (
    <Tabs overflow="hidden">
      <TabList>
        <Tab className="ch-settings-profile-button">Profile</Tab>
        {!showNewPricing && <Tab className="ch-settings-subscription-button">Subscription</Tab>}
        <Tab className="ch-settings-config-button">General</Tab>
        <Tab className="ch-config-layout-options">Project</Tab>
        <Tab className="ch-settings-about-button">About Charli</Tab>
      </TabList>
      <TabPanels overflow={"auto"} height={"100%"} maxHeight={`calc(100vh - 13rem)`} ref={tileRef} css={scrollbarStyle}>
        <TabPanel>
          <Profile />
        </TabPanel>
        {!showNewPricing && (
          <TabPanel>
            <SubscriptionInformation />
          </TabPanel>
        )}
        <TabPanel>
          <Config />
        </TabPanel>
        <TabPanel>
          <ConfigLayoutOptions />
        </TabPanel>
        <TabPanel>
          <About />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
