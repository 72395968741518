import { Record, String, Unknown } from "runtypes";
import { WorkflowStatus } from "api/tasks/models/WorkflowStatus";
import { TaskError } from "api/tasks/models/TaskError";
import type { Static } from "runtypes";

export const TaskResponse = Record({
  id: String,
  registeredTaskId: String,
  childWorkflowId: String,
  creationDate: String,
  startDate: String.nullable().optional(),
  completionDate: String.nullable().optional(),
  inputPayload: Unknown.nullable().optional(),
  outputPayload: Unknown.nullable().optional(),
  status: WorkflowStatus,
  errorPayload: TaskError.nullable().optional(),
});

export type TaskResponse = Static<typeof TaskResponse>;
