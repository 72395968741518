import { BillingPlanResponse } from "types/billing/BillingPlanResponse";
import { Plan as PlanResponse } from "types/billingPlans/Plan";
import { PlansResponse } from "./models/PlansResponse";
import { prependProxyBaseUrl, request } from "api/request";
import type { BillingPlanType } from "types/billing/BillingPlan";
import type { CreatePlanRequest } from "./models/CreatePlanRequest";
import type { UpdatePlanRequest } from "./models/UpdatePlanRequest";

const PROXY_PREFIX = prependProxyBaseUrl("/api/billing-plan");
const PLANS_ROUTE = `${PROXY_PREFIX}/plans`;
const USER_BILLING_ENDPOINT_PATH = (userId: string) => `${PROXY_PREFIX}/plans/user/${userId}`;

export async function getBillingStatus(userId: string): Promise<BillingPlanType | undefined> {
  try {
    return await request()
      .url(`${USER_BILLING_ENDPOINT_PATH(userId)}`)
      .get()
      .json((json) => {
        if (BillingPlanResponse.guard(json)) {
          return json.plan;
        } else {
          return undefined;
        }
      });
  } catch (error) {
    return undefined;
  }
}

export async function getBillingPlans(): Promise<PlansResponse> {
  const maybePlans = await request()
    .url(`${PLANS_ROUTE}?${new URLSearchParams({ fetchPlanUsers: "true" })}`)
    .get()
    .json();
  const areValidPlans = PlansResponse.validate(maybePlans);

  if (!areValidPlans.success) {
    console.error(areValidPlans);
    throw new Error(areValidPlans.message);
  }

  return areValidPlans.value;
}

export async function createBillingPlan(payload: CreatePlanRequest): Promise<PlanResponse> {
  const maybeNewPlan = await request().url(PLANS_ROUTE).post(payload).json();
  const isValidPlan = PlanResponse.validate(maybeNewPlan);

  if (!isValidPlan.success) {
    console.error(isValidPlan);
    throw new Error(isValidPlan.message);
  }

  return isValidPlan.value;
}

export async function updateBillingPlan(code: string, payload: UpdatePlanRequest): Promise<PlanResponse> {
  const response = await request().url(`${PLANS_ROUTE}/${code}`).put(payload).json();
  const isValidPlan = PlanResponse.validate(response);

  if (!isValidPlan.success) {
    console.error(isValidPlan);
    throw new Error(isValidPlan.message);
  }

  return isValidPlan.value;
}
