import type { Static } from "runtypes";
import { Record, String, Array } from "runtypes";
import { ExtractedTableHighlightsDetail } from "./ExtractedTableHighlightsDetail";

export const ExtractedTableHighlights = Record({
  topic: String,
  highlights: Array(ExtractedTableHighlightsDetail),
});

export type ExtractedTableHighlights = Static<typeof ExtractedTableHighlights>;
