import type { FunctionComponent } from "react";
import React, { useMemo, useContext } from "react";
import { Text, Stack, Box, Flex } from "@chakra-ui/react";
import type { Message } from "types/conversation";
import { MessageContainer } from "../components";
import { ChatBubble } from "../components";
import type { Button, CharliUIRoot, CollectionCell, TableView } from "types/charliui";
import { FaReceipt, FaFileAlt, FaList, FaEnvelope } from "react-icons/fa";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useNavigate } from "react-router-dom";
import { ChatButton } from "../components/ChatButton";
import { useConfigMap, useGetViewConfig } from "hooks";

interface Props {
  message: Message;
  datum: CharliUIRoot;
}

const getIcon = (iconName: string | undefined) => {
  switch (iconName) {
    case "doc.richtext":
      return FaFileAlt;
    case "dollarsign.circle.fill":
      return FaReceipt;
    case "list.dash":
      return FaList;
    case "envelope.fill":
      return FaEnvelope;
  }
};

export const ButtonCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ message, datum }) => {
  const { hideButtonElements } = useContext(ConversationContext);
  const button = (datum.body as Button).buttonElement.content;

  const singleCollectionCell = useMemo(() => {
    // Return a collection cell, provided that its the only cell in the button's table view.
    const button = (datum.body as Button).buttonElement.content;

    if (button.onTap && button.onTap.tableListView) {
      const tableView = button.onTap as TableView;
      if (tableView.tableListView && tableView.tableListView.content.cells) {
        const cells = tableView.tableListView.content.cells;
        if (cells.length === 1 && cells[0].collectionCellElement && cells[0].collectionCellElement.content) {
          return cells[0] as CollectionCell;
        }
      }
    }
  }, [datum]);

  if (button.type && button.contentId) {
    return (
      <MessageContainer initiator={message.senderId === "charli" ? "charli" : "user"}>
        <ChatButton
          buttonText={button.title}
          subtitle={
            button.metadataIds && button.metadataIds?.length > 1
              ? `${button.metadataIds?.length} ${button.metadataIds?.length > 1 ? "items" : "item"}`
              : undefined
          }
          metadataIds={button.metadataIds ? button.metadataIds : undefined}
          contentId={button.contentId}
          type={button.type}
          senderId={message.senderId}
          state={message.state}
        />
      </MessageContainer>
    );
  }

  if (hideButtonElements) {
    return <React.Fragment key={message.id} />;
  } else if (singleCollectionCell) {
    return <SingleCollectionButtonCell message={message} collection={singleCollectionCell} />;
  } else {
    return <StandardButtonCell message={message} datum={datum} />;
  }
};

// this can never be triggered at the moment
const SingleCollectionButtonCell: FunctionComponent<
  React.PropsWithChildren<React.PropsWithChildren<{ message: Message; collection: CollectionCell }>>
> = ({ message, collection }) => {
  const configMap = useConfigMap();
  const buttonTitle = collection.collectionCellElement.content.name;
  const projectRoute = useGetViewConfig("route", collection.collectionCellElement.content.collectionType || "", configMap);
  const navigate = useNavigate();

  return (
    <MessageContainer initiator={message.senderId === "charli" ? "charli" : "user"} key={`${message.id}-collection-${buttonTitle}`}>
      <Box position="relative">
        <ChatBubble
          initiator={message.senderId === "charli" ? "charli" : "user"}
          state={message.state}
          onClick={() => {
            navigate(`/${projectRoute}/${collection.collectionCellElement.content.id}`);
          }}
          className="ch-collection-button">
          <Stack direction="row" alignItems="center" p="1">
            <Flex flexDirection="column">
              <Text fontSize="md" fontWeight={600}>
                {buttonTitle}
              </Text>
            </Flex>
            <ChevronRightIcon boxSize="1.5rem" />
          </Stack>
        </ChatBubble>
      </Box>
    </MessageContainer>
  );
};

export const StandardButtonCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ message, datum }) => {
  const content = (datum.body as Button).buttonElement.content;
  const buttonTitle = content.title ? content.title : "Resource Canvas";
  const buttonSubtitle = content.subtitle;
  const icon = getIcon(content.icon);
  const navigate = useNavigate();
  const availableFileCount = useMemo(
    () =>
      (content.onTap as TableView)?.tableListView?.content.cells.filter(
        (cell) =>
          !!cell.fileCellElement ||
          !!cell.expenseCellElement ||
          !!cell.collectionCellElement ||
          !!cell.textCellElement ||
          (!!cell.linkCellElement && !!cell.linkCellElement.content.id)
      ).length || 0,
    [content]
  );
  const hasMarkdown = useMemo(() => {
    if (content.onTap?.markdownView) {
      return true;
    } else {
      return false;
    }
  }, [content]);
  if (availableFileCount || hasMarkdown) {
    return (
      <MessageContainer initiator={message.senderId === "charli" ? "charli" : "user"} key={`${message.id}-button-${buttonTitle}`}>
        <ConversationContext.Consumer>
          {({ conversationId }) => (
            <ChatBubble
              initiator={message.senderId === "charli" ? "charli" : "user"}
              state={message.state}
              onClick={() => {
                const modalPayload = content.onTap;
                if (modalPayload) {
                  navigate(`/search/${conversationId}`);
                }
              }}
              className={buttonTitle === "Resource Canvas" ? "ch-content-canvas-button" : undefined}>
              <Stack direction="row" alignItems="center" spacing="1rem">
                {icon && <Box as={icon} />}
                <Stack direction="row">
                  <Text fontSize="sm" fontWeight="semibold">
                    {buttonTitle}
                  </Text>
                  {buttonSubtitle ||
                    (availableFileCount > 0 && (
                      <Text fontSize="sm">
                        {availableFileCount > 0 ? `${availableFileCount} ${availableFileCount > 1 ? "items" : "item"}` : buttonSubtitle}
                      </Text>
                    ))}
                </Stack>
                <ChevronRightIcon height="1.5rem" />
              </Stack>
            </ChatBubble>
          )}
        </ConversationContext.Consumer>
      </MessageContainer>
    );
  } else {
    return <></>;
  }
};
