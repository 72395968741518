import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import GanttChart from "./GanttChart";
import type { GanttChartProps } from "./GanttChart";

interface Props extends GanttChartProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GanttChartModal = ({ isOpen, onClose, tasks, width, taskHeight, taskPadding }: Props) => {
  return (
    <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Gantt Chart</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{isOpen && <GanttChart tasks={tasks} width={width} taskHeight={taskHeight} taskPadding={taskPadding} />}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
