import { Textarea, Box, Input, Stack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import type { Organization } from "types/organization/organization";
import { useOrganization } from "hooks/useOrganizations";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import type { RequestEntities } from "types/charliui";
import { ConversationContext } from "screens/thread/ConversationContext";
import type { MessageIntent } from "types/conversation";
import { useSendToCharli } from "screens/panels/addToCharli/AddToCharliWizard/useSendToCharli";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";

interface IProps {
  organization: Organization;
}

export const InviteUsers = (props: IProps) => {
  const { organization } = props;
  const [emails, setEmails] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const { setRequestEntities } = useContext(ConversationContext);

  useEffect(() => {
    const entities: RequestEntities = [];

    entities.push({ entity: "organization_id", value: organization.id });
    entities.push({ entity: "email_address", value: emails.trim() });

    if (subject.trim().length > 0) {
      entities.push({ entity: "subject", value: subject.trim() });
    }
    if (message.trim().length > 0) {
      entities.push({ entity: "message", value: message.trim() });
    }

    updateBatchRequestEntities(entities, setRequestEntities);
  }, [emails, message, subject, organization.id, organization.name, setRequestEntities]);

  return (
    <Stack spacing="1.5rem">
      <Box>
        <SectionHeader title="Emails" />
        <Input
          value={emails}
          type="email"
          id="invite-organization-input-emails"
          placeholder={`Enter comma separated email addresses`}
          size="sm"
          rounded="10px"
          onChange={(ev) => setEmails(ev.target.value)}
        />
      </Box>

      <Box>
        <SectionHeader title="Subject" />
        <Input
          value={subject}
          id="invite-organization-input-subject"
          placeholder="Add the subject"
          size="sm"
          rounded="10px"
          onChange={(ev) => setSubject(ev.target.value)}
        />
      </Box>

      <Box>
        <SectionHeader title="Message" />
        <Textarea
          id="invite-organization-input-message"
          placeholder="Add a message to go with the invite"
          size="sm"
          minHeight="6rem"
          resize="none"
          rounded="5px"
          defaultValue={message}
          onChange={(ev) => setMessage(ev.target.value)}
        />
      </Box>
    </Stack>
  );
};

export const InviteUsersPanel = (props: { onClose: () => void; id: string | null }) => {
  const { id, onClose } = props;
  const organization = useOrganization(id);
  const { setRequestEntities } = useContext(ConversationContext);
  const { sendRequest } = useSendToCharli();

  const resetRequest = () => {
    setRequestEntities([]);
  };

  const handleSendRequest = () => {
    const intent: MessageIntent = "invite_to_organization";
    sendRequest(
      intent,
      resetRequest,
      `I'll invite this user to ${
        organization ? organization.name : "this organization"
      } right away. You can open the conversation I started about it by clicking this message`
    );
    onClose();
  };

  if (!organization) {
    return <React.Fragment />;
  }

  return (
    <PanelView isOpen onClose={onClose} panelTitle={`Invite members to ${organization.name}`}>
      <Wizard>
        <PanelStep onNextLabel={"Send invitations"} onSubmit={handleSendRequest}>
          <InviteUsers organization={organization} />
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
