import React from "react";
import { Center, Stack, Text } from "@chakra-ui/react";
import { Tooltip, YAxis, XAxis, ResponsiveContainer, Bar, ComposedChart, Line, Cell } from "recharts";
import { chartItemColor, CustomTooltipAreaChart } from "./CustomChartUtils";
import type { DataType } from "hooks/useStats";
import { defaultProps } from "react-select/creatable";
import { useCostsByDate } from "../../operations/tabs/useWorkflowStats";

interface Props {
  data: DataType[];
  width?: number | string;
  height?: string;
  chartHeight?: number;
  title?: string;
  chartId?: string;
}

export const CustomLineAndBarChart: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  data,
  width,
  height = "13rem",
  chartHeight = 100,
  title,
  chartId,
}) => {
  const dataByDate = useCostsByDate(data).reverse();

  return (
    <Stack height={height} width={width}>
      {data.every((item) => !item.value) ? (
        <Center height="13rem" pb="1rem" fontSize="xs">
          No Projects
        </Center>
      ) : (
        <Stack height={height} width={width}>
          <ResponsiveContainer width={"100%"} height={chartHeight}>
            <ComposedChart id={chartId} data={dataByDate} margin={{ top: 0, right: 0, left: 0, bottom: 12 }}>
              <Tooltip
                isAnimationActive={false}
                content={<CustomTooltipAreaChart active={defaultProps.active} payload={defaultProps.payload} prefix="$ " />}
              />
              <XAxis dataKey="name" fontSize={"10px"} interval={2} />
              <YAxis fontSize={"10px"} />
              <Bar maxBarSize={40} minPointSize={25} dataKey="value">
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} cursor="pointer" fill={chartItemColor("label")} />
                ))}
              </Bar>
              <Line strokeWidth={2} type="monotone" dataKey="human_resource_cost" stroke={chartItemColor("human_resource_cost")} />
            </ComposedChart>
          </ResponsiveContainer>
          <Text mt="0!important" fontSize={"xs"} width={"100%"} align={"center"} pl="3rem">
            {title}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};
