import React, { useMemo, useState, useCallback } from "react";
import { FilterInput } from "screens/common/components";

export function useFilterInput<T>(filterByKeys: (keyof T)[], list: T[]) {
  const [searchText, setSearchText] = useState("");

  const filteredList = useMemo(
    () =>
      list.filter(
        (item) =>
          filterByKeys.length === 0 ||
          filterByKeys.some((key) => (item[key] ? JSON.stringify(item[key]).toLowerCase().includes(searchText.toLowerCase()) : false))
      ),
    [searchText, list, filterByKeys]
  );

  const renderFilterInputComponent = useCallback(
    (placeholder: string) => {
      return <FilterInput searchText={searchText} setSearchText={setSearchText} placeholder={placeholder} />;
    },
    [searchText]
  );

  return {
    filteredList,
    renderFilterInputComponent,
  };
}
