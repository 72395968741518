import type { FunctionComponent } from "react";
import React, { useContext } from "react";
import { IntegrationTile } from ".";
import { v4 as uuid } from "uuid";
import { sendMessage } from "state/websocket/operations";
import { useDispatch } from "react-redux";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import type { SettingsTab } from "types/settings";
import type { Integration } from "api/integrations";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useToast } from "@chakra-ui/react";
import { ToastMessageContent } from "screens/common/components";

interface Props {
  integration: Integration;
  disableActions?: boolean;
}

export const ExternalIntegrations: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  integration,
  disableActions,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { setActiveSettingsTab } = useContext(SettingsProviderContext);
  const { onSettingsOpen } = useAddToCharliContext();
  const { onConversationOpen, setConversationId } = useContext(ConversationContext);
  const getConversationId = uuid();

  const send = () => {
    const entities: {
      entity: string;
      value: string;
    }[] = [];
    setConversationId(getConversationId);
    entities.push({ entity: "integration_urn", value: integration.urn ?? integration.integrationId });
    const intent = integration.isEnabled || integration.isReauthRequired ? "disable_integration" : "enable_integration";

    dispatch(
      sendMessage({
        conversationId: getConversationId,
        intent: `/${intent}`,
        entities: entities,
      })
    );

    if (!integration.isEnabled) {
      onConversationOpen();
    } else {
      const toastMessage = `${integration.name} is being removed now. You can open the conversation about it by clicking this message.`;
      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message={toastMessage}
            onClick={() => {
              setConversationId(getConversationId);
              onConversationOpen();
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const onClickButton = () => {
    if (integration.isUserEntitled) {
      send();
    } else {
      onSettingsOpen();
      const subscriptionPlan = `subscription.${integration.minimumPlan}` as SettingsTab;
      setActiveSettingsTab(subscriptionPlan);
    }
  };

  return <IntegrationTile integration={integration} disableActions={disableActions} onClick={onClickButton} />;
};
