import type { FunctionComponent } from "react";
import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title: string;
}

export const TabTitle: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ title = "Charli" }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={title} />
    </Helmet>
  );
};
