import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { WorkflowTaskStatus } from "types/workflows/workflow";
import { useWorkflowKey } from "./useWorkflows";

function mostCommonTask(tasks: string[]): { mostCommonTask: string | null; count: number; remainingTasksCount: number } {
  if (tasks.length === 0) {
    return {
      mostCommonTask: null,
      count: 0,
      remainingTasksCount: 0,
    };
  }

  if (tasks.length === 1) {
    return {
      mostCommonTask: tasks[0],
      count: 1,
      remainingTasksCount: 0,
    };
  }

  const taskCounts = tasks.reduce((counts: Record<string, number>, task) => {
    counts[task] = (counts[task] || 0) + 1;
    return counts;
  }, {});

  let mostCommonTask: string | null = null;
  let maxCount = 0;

  for (const [task, count] of Object.entries(taskCounts)) {
    if (count > maxCount) {
      mostCommonTask = task;
      maxCount = count;
    }
  }

  return {
    mostCommonTask,
    count: maxCount,
    remainingTasksCount: tasks.length - maxCount,
  };
}

export function useWorkflowProgress(workflowId: string) {
  const [percent, setPercent] = useState<number>(0);
  const [completed, setCompleted] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const progress = useSelector((state: RootState) => state.checkpoint.progressByWorkflow[workflowId]);

  useEffect(() => {
    if (!progress || !progress.completedStepCount || !progress.estimatedTotalStepCount) {
      return;
    }

    const { completedStepCount: completedSteps, estimatedTotalStepCount: totalSteps } = progress;

    setCompleted(completedSteps);
    setTotal(totalSteps);
    setPercent(Math.round((completedSteps / totalSteps) * 100));
  }, [progress]);

  return {
    percent,
    completed,
    total,
  };
}

export function useWorkflowChildWorkflowsIds(workflowId: string): string[] {
  return useSelector(
    (state: RootState) => (state.checkpoint.childWorkflowsIdsByWorkflow && state.checkpoint.childWorkflowsIdsByWorkflow[workflowId]) || [],
    shallowEqual
  );
}

export function useChildWorkflowsProgress(childWorkflowId: string):
  | {
      id: string;
      intent: string;
      estimatedTotalStepCount: number;
      completedStepCount: number;
      task: string;
    }
  | undefined {
  return useSelector((state: RootState) => {
    const progress = state.checkpoint.progressByChildWorkflow[childWorkflowId];
    const status = state.checkpoint.statusByChildWorkflow[childWorkflowId];
    const tasks = (state.checkpoint.tasksIdsByChildWorkflow[childWorkflowId] || []).flatMap((taskId) =>
      state.checkpoint.tasksById[taskId] ? [state.checkpoint.tasksById[taskId]] : []
    );

    const maybeTaskInProgress = tasks.find((task) => task.status === WorkflowTaskStatus.inProgress);

    if (progress && (status === WorkflowTaskStatus.inProgress || status === WorkflowTaskStatus.awaitingAyncTask)) {
      return {
        ...progress,
        task: maybeTaskInProgress?.name || "Waiting for next task to start.",
      };
    } else {
      return undefined;
    }
  }, shallowEqual);
}

export function useChildWorkflowActiveTask(childWorkflowId: string): string | undefined {
  return useSelector((state: RootState) => {
    const tasks = (state.checkpoint.tasksIdsByChildWorkflow[childWorkflowId] || []).flatMap((taskId) =>
      state.checkpoint.tasksById[taskId] ? [state.checkpoint.tasksById[taskId]] : []
    );

    const maybeTaskInProgress = tasks.find((task) => task.status === WorkflowTaskStatus.inProgress);

    return maybeTaskInProgress?.name;
  }, shallowEqual);
}

export function useWorkflowInProgressOrClarificationRequested(workflowId: string | undefined): boolean {
  const status = useWorkflowKey(workflowId, "status");

  return status === "in_progress" || status === "clarification_needed" || status === "queued" || status === "paused";
}

export function useWorkflowActiveTaskWithCount(workflowId: string) {
  return useSelector((state: RootState) => {
    const childWorkflows = state.checkpoint.childWorkflowsIdsByWorkflow[workflowId] || [];
    const tasksIds = childWorkflows.flatMap((childWorkflowId) => state.checkpoint.tasksIdsByChildWorkflow[childWorkflowId] || []);
    const tasksInProgressNames = tasksIds.flatMap((taskId) =>
      state.checkpoint.tasksById[taskId]?.status === WorkflowTaskStatus.inProgress ? [state.checkpoint.tasksById[taskId].name] : []
    );

    return mostCommonTask(tasksInProgressNames);
  }, shallowEqual);
}
