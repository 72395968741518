import { createSlice } from "@reduxjs/toolkit";
import type { Command } from "types/commands";
import type { MessageIntent } from "types/conversation";
import { downloadCommands } from "./operations";

interface CommandState {
  commands: {
    [intent in MessageIntent]?: Command;
  };
}

const initialState: CommandState = {
  commands: {},
};

export const { actions, reducer } = createSlice({
  name: "command",
  initialState,
  reducers: {
    flush(state) {
      state.commands = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadCommands.fulfilled, (state, action) => {
      state.commands = action.payload;
    });
  },
});

export default reducer;
