import { Stack, Switch, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import React, { useMemo } from "react";
import HelpPopover from "screens/landing/components/popoverComponent/HelpPopover";
import { SourceWeightsType } from "types/projectConfig/SourceWeightsType";

interface Props {
  answerFocusOptions: SourceWeightsType;
  selectedSourceWeightsIndex: 0 | 1;
  onSourceWeightPreferenceChange: (index: 0 | 1) => void;
  isDisabled?: boolean;
}

export const SemanticFocusedAnswer = ({
  answerFocusOptions,
  selectedSourceWeightsIndex,
  onSourceWeightPreferenceChange,
  isDisabled,
}: Props) => {
  const textColor = useColorModeValue("gray.500", "gray.800");
  const validAnswerFocusOptions = useMemo(() => SourceWeightsType.validate(answerFocusOptions), [answerFocusOptions]);

  const selectedSourceWeights = useMemo(
    () => answerFocusOptions[selectedSourceWeightsIndex],
    [answerFocusOptions, selectedSourceWeightsIndex]
  );

  if (!validAnswerFocusOptions.success) {
    return (
      <Text color={textColor} fontSize={"xs"}>
        {validAnswerFocusOptions.message}
      </Text>
    );
  } else
    return (
      <Stack direction="row" alignItems={"center"} alignSelf={"end"}>
        <Tooltip
          label="The Analytical and Sentiment switch determines how the AI will analyze information based on fundamentals and trusted information sources versus market sentiment information from the Internet and news sources. The AI will balance the analyses and provide a broader perspective to help you personally review the outcomes."
          aria-label="Question Sentiment">
          <Text
            cursor={"pointer"}
            onClick={() => onSourceWeightPreferenceChange(selectedSourceWeightsIndex === 0 ? 1 : 0)}
            color={textColor}
            fontSize={"xs"}>
            {selectedSourceWeights.label}
          </Text>
        </Tooltip>
        <HelpPopover
          title="Question Sentiment"
          message="Select the 'Analytical Focused Answer' for answers that are based on financial data sources such as FactSet and regulator findings. Select 'Sentiment Focused Answer' for answers that are more based on the broader market sentiment.">
          <Switch
            isDisabled={isDisabled}
            className={`ch-semantic-focused-answer-switch-${selectedSourceWeights.key}`}
            size="sm"
            isChecked={isDisabled ? false : Boolean(selectedSourceWeightsIndex)}
            onChange={() => onSourceWeightPreferenceChange(selectedSourceWeightsIndex === 0 ? 1 : 0)}
          />
        </HelpPopover>
      </Stack>
    );
};
