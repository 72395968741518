import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect } from "react";
import { ConversationContext } from "screens/thread/ConversationContext";
import { CloseIcon } from "@chakra-ui/icons";
import { useButtonProps, useUserProfile } from "hooks";
import { ToastMessageContent } from "screens/common/components";
import { track } from "api/analytics";
import { USER_TICKER_PRIORTY } from "api/analytics/events";

export const EquityFeedbackModal = () => {
  const { onFeedbackModalClose, isFeedbackModalOpen, onConversationClose, feedbackValue } = useContext(ConversationContext);
  const primaryButtonProps = useButtonProps("sm", "primary");
  const toast = useToast();
  const { email, fullName } = useUserProfile();

  const onSendFeedback = useCallback(() => {
    track(USER_TICKER_PRIORTY, {
      userName: fullName,
      userEmail: email,
      ticker: feedbackValue.toUpperCase(),
    });

    toast.closeAll();
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`I have notified the team of the priority nature of the ${feedbackValue.toUpperCase()} equity you are requesting.`}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });

    onFeedbackModalClose();
  }, [fullName, email, feedbackValue, toast, onFeedbackModalClose]);

  useEffect(() => {
    if (isFeedbackModalOpen) {
      onConversationClose();
    }
  }, [isFeedbackModalOpen, onConversationClose]);

  return (
    <Modal onClose={onFeedbackModalClose} isOpen={isFeedbackModalOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bgColor={"white"} maxHeight="10rem" display="flex" flexDirection="column" padding={0} borderTopRadius="lg">
          <Stack direction={"row"} fontSize="md" textAlign="center" pt="1rem" width="100%" justifyContent={"space-between"} px="1.5rem">
            <Text fontWeight={"semibold"} fontSize="lg">
              Prioritizing Tickers and Equities
            </Text>
            <Center>
              <CloseIcon cursor={"pointer"} boxSize={".8rem"} onClick={onFeedbackModalClose} />
            </Center>
          </Stack>
        </ModalHeader>
        <ModalBody fontSize={"sm"} pt="1rem">
          <Stack direction="column" spacing="1rem">
            <Text fontWeight="normal">
              Charli is focused on providing deep-dive due diligence analysis and reporting for a broad range of exchanges, indices and
              equities. Our team prioritizes the “tickers” based on direct feedback from our customers, and the team ensures that detailed
              data and content is made available to the AI for deep analysis.
            </Text>
            <Text fontWeight="normal">
              You can click on prioritize and I will notify our team that the equity you are requesting is a priority. The team will
              follow-up with additional information.
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack direction="row" spacing="1rem" justifyContent={"end"}>
            <Button className="ch-delete-project-confirm" {...primaryButtonProps} onClick={onSendFeedback}>
              Prioritize
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
