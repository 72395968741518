import type { FunctionComponent } from "react";
import { useMemo } from "react";
import React from "react";
import { FiArchive } from "react-icons/fi";
import { MdSettingsBackupRestore } from "react-icons/md";
import { VscFileSubmodule } from "react-icons/vsc";
import CharliLogoSmall from "screens/common/static/logos/charli-logo-small-gray.png";
import type { IconProps } from "@chakra-ui/react";
import { Image, Icon, useColorModeValue } from "@chakra-ui/react";
import omit from "lodash/omit";
import { AiOutlineTags } from "react-icons/ai";
import { CopyIcon } from "@chakra-ui/icons";
import {
  FcMindMap,
  FcLink,
  FcMultipleInputs,
  FcShare,
  FcFile,
  FcSurvey,
  FcApproval,
  FcCurrencyExchange,
  FcParallelTasks,
  FcCalendar,
  FcFlowChart,
  FcUpload,
  FcTreeStructure,
  FcDisclaimer,
  FcTodoList,
} from "react-icons/fc";
import { BsCloudUpload } from "react-icons/bs";
import { HiOutlineCollection } from "react-icons/hi";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { FcTimeline } from "react-icons/fc";
import { useConfigMap, useConfigProjectTypes } from "hooks/useViewConfig";
import {
  DeleteCollectionIcon,
  DeleteCollectionMetaDataIcon,
  EditCollectionIcon,
  EditDocumentIcon,
  EditDynamicDataIcon,
  EditExpenseIcon,
  EditLinkIcon,
  EditMetaDataIcon,
  EditNoteIcon,
  GenerateHighlights,
  ManageAnswerIcon,
  ManageQuestionIcon,
  StopWorkflowIcon,
} from "./IntentIcons";
import { SearchResultsIcon } from "./ProjectIcons";

interface Props {
  intent: string | undefined;
  styles?: React.CSSProperties;
  width?: string;
  height?: string;
}

export const IntentIcon: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props & IconProps>>> = (props) => {
  const width = props.width ?? "1.5rem";
  const maxHeight = props.height ?? "2.5rem";
  const buttonColor = useColorModeValue("gray.400", "gray.500");
  const iconProps: IconProps = {
    color: buttonColor,
    opacity: ".7",
    borderRadius: "sm",
    boxSize: width,
    maxHeight: maxHeight,
    height: maxHeight,
    _hover: {
      borderColor: "transparent",
      backgroundColor: "transparent",
    },
    ...omit(props, ["extension", "fileTypeName"]),
  };

  const configMap = useConfigMap();
  const projectTypes = useConfigProjectTypes(configMap);
  const projectIntents = useMemo(() => projectTypes.map(({ config }) => config.intent), [projectTypes]);
  const intentString = props.intent && projectIntents.includes(props.intent) ? "project" : props.intent;

  switch (intentString) {
    case "project":
    case "process_offering_memorandum":
    case "process_agreement":
      return <Icon style={props.styles} {...iconProps} as={FcParallelTasks} />;
    case "dismiss":
    case "cancel":
      return <Icon style={props.styles} {...iconProps} as={StopWorkflowIcon} />;
    case "share":
    case "accept_share":
    case "share_resources":
    case "share_documents":
      return <Icon style={props.styles} {...iconProps} as={FcShare} />;
    case "find":
      return <SearchResultsIcon style={props.styles} {...iconProps} />;
    case "store_link":
    case "add_link":
      return <Icon style={props.styles} {...iconProps} as={FcLink} />;
    case "process_receipt":
    case "prepare_expense_report":
    case "create_expense_spreadsheet":
      return <Icon style={props.styles} {...iconProps} as={FcCurrencyExchange} />;
    case "archive":
    case "find_archived":
      return <Icon style={props.styles} {...iconProps} as={FiArchive} />;
    case "restore":
      return <Icon style={props.styles} {...iconProps} as={MdSettingsBackupRestore} />;
    case "upload":
      return <Icon style={props.styles} {...iconProps} as={BsCloudUpload} />;
    case "file_document":
    case "upload_new_file":
    case "new_file_upload":
    case "add_resources":
      return <Icon style={props.styles} {...iconProps} as={FcUpload} />;
    case "extract_highlight":
    case "generate_highlights":
      return <GenerateHighlights style={props.styles} {...iconProps} />;
    case "generate_answer":
      return <Icon style={props.styles} {...iconProps} as={FcMindMap} />;
    case "manage_question":
      return <ManageQuestionIcon style={props.styles} {...iconProps} />;
    case "manage_answer":
      return <ManageAnswerIcon style={props.styles} {...iconProps} />;
    case "summarize":
    case "generate_summary":
      return <Icon style={props.styles} {...iconProps} as={FcMultipleInputs} />;
    case "note":
      return <Icon style={props.styles} {...iconProps} as={FcFile} />;
    case "tag":
      return <Icon style={props.styles} {...iconProps} as={AiOutlineTags} />;
    case "copy":
      return <CopyIcon style={props.styles} {...iconProps} />;
    case "store_collection":
    case "add_collection_content":
      return <Icon style={props.styles} {...iconProps} as={FcFlowChart} />;
    case "cancel_project":
    case "delete_collection":
      return <Icon style={props.styles} {...iconProps} as={DeleteCollectionIcon} />;
    case "delete_collection_metadata":
    case "remove_collection_content":
      return <Icon style={props.styles} {...iconProps} as={DeleteCollectionMetaDataIcon} />;
    case "edit_collection":
      return <Icon style={props.styles} {...iconProps} as={EditCollectionIcon} />;
    case "library":
      return <Icon style={props.styles} {...iconProps} as={HiOutlineCollection} />;
    case "organize":
      return <Icon style={props.styles} {...iconProps} as={VscFileSubmodule} />;
    case "affirm":
      return <Icon style={props.styles} {...iconProps} as={FcApproval} />;
    case "deny":
      return <Icon style={props.styles} {...iconProps} as={FcDisclaimer} />;
    case "create_meeting":
    case "create_reminder":
    case "create_calendar_event":
      return <Icon style={props.styles} {...iconProps} as={FcCalendar} />;
    case "generate_collection_report":
    case "generate_report":
    case "prepare_meeting_report":
      return <Icon style={props.styles} {...iconProps} as={FcSurvey} />;
    case "research":
      return <Icon style={props.styles} {...iconProps} as={FcTodoList} />;
    case "help":
    case "retrieve_supported_activities":
      return <Icon style={props.styles} {...iconProps} as={BsFillQuestionCircleFill} color="blue.400" width="1.4rem" />;
    case "how":
      return <Icon style={props.styles} {...iconProps} as={FcTimeline} />;
    case "add_activity_handler":
    case "enable_integration":
    case "remove_integration":
      return <Icon style={props.styles} {...iconProps} as={FcTreeStructure} />;
    case "edit_link":
      return <Icon style={props.styles} {...iconProps} as={EditLinkIcon} />;
    case "edit_document":
      return <Icon style={props.styles} {...iconProps} as={EditDocumentIcon} />;
    case "edit_file_metadata":
      return <Icon style={props.styles} {...iconProps} as={EditMetaDataIcon} />;
    case "delete_document_metadata":
    case "edit_dynamic_metadata":
      return <Icon style={props.styles} {...iconProps} as={EditDynamicDataIcon} />;
    case "edit_notes":
      return <Icon style={props.styles} {...iconProps} as={EditNoteIcon} />;
    case "edit_expense":
      return <Icon style={props.styles} {...iconProps} as={EditExpenseIcon} />;
    default:
      return <Image src={CharliLogoSmall} height=".9rem!important" marginTop="12px!important" style={props.styles} alt="Charli Logo" />;
  }
};
