import React, { useContext, useMemo, useCallback } from "react";
import { Stack, useBreakpointValue, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import type { Answer, Question } from "types/question";
import { AnswerStatus } from "types/question";
import { SmallActionConfidenceScoreBadge } from "screens/content/contentCanvas/cell/SmallActionConfidenceScoreBadge";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ContentFilterContext } from "screens/content";
import { SmallActionVerificationBadge } from "screens/content/contentCanvas/cell/SmallActionVerificationBadge";
import { MarkupTextViewer } from "screens/content/contentView/sections/MarkupTextViewer";
import HelpPopover from "screens/landing/components/popoverComponent/HelpPopover";
import { BiCopy } from "react-icons/bi";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { ProjectThumbHotlinks } from "./ProjectActions/ProjectThumbHotlinks";

interface AnswerCardProps {
  answerRecord?: Answer;
  questionRecord?: Question;
  collectionId: string;
  conversationId: string;
  enableHelpPopover?: boolean;
}

const copyAnswer = (question?: Question) => {
  if (!question) return "";
  let answerText = "";
  if (question.question) {
    answerText += `Question: ${question.question}\r\n`;
  }
  if (question.answers && Array.isArray(question.answers)) {
    question.answers.forEach((answer) => {
      if (answer.answer) {
        answerText += `${answer.answer}\r\n`;
      }
    });
  }
  return answerText + "\r\n";
};

const noAnswerCopy =
  "Sorry but I couldn't find an answer to your question using the resources available in this project. Can you try asking that question another way?";

export const AnswerCardPinned: React.FC<React.PropsWithChildren<React.PropsWithChildren<AnswerCardProps>>> = ({
  answerRecord,
  questionRecord,
  collectionId,
  conversationId,
  enableHelpPopover,
}) => {
  const iconColor = useColorModeValue("#9F9F9F", "gray.400");
  const { onVerifiedAIPanelOpen } = useAddToCharliContext();
  const { setSelectedResult, searchText } = useContext(ContentFilterContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const confidenceValue = useMemo(
    () => (answerRecord?.confidence ? Math.round(answerRecord?.confidence * 100) : "--"),
    [answerRecord?.confidence]
  );

  const { isOpen: isThumbsUpOpen, onToggle: onThumbsUpToggle, onClose: onThumbsUpClose } = useDisclosure();
  const { isOpen: isThumbsDownOpen, onToggle: onThumbsDownToggle, onClose: onThumbsDownClose } = useDisclosure();

  const toggleThumbsUp = useCallback(() => {
    onThumbsUpToggle();
    onThumbsDownClose();
  }, [onThumbsUpToggle, onThumbsDownClose]);

  const toggleThumbsDown = useCallback(() => {
    onThumbsDownToggle();
    onThumbsUpClose();
  }, [onThumbsDownToggle, onThumbsUpClose]);

  const handleOnClick = useCallback(() => {
    setSelectedResult({
      questionText: questionRecord?.question || "",
      resultId: answerRecord?.id || questionRecord?.id || "",
      resultStatus: answerRecord?.answerStatus || questionRecord?.status || AnswerStatus.pinned,
      resultText: answerRecord?.answer || noAnswerCopy || "",
      confidence: answerRecord?.confidence || 0,
      attributionMetadataIds: answerRecord?.metadataIds || [],
      collectionId: collectionId,
      conversationId: conversationId,
      isQuestion: answerRecord?.answer && answerRecord?.answer.length > 0 ? false : true,
      verifiedStatus: answerRecord?.answer && answerRecord?.answer.length > 0 ? "verified" : "unverified",
      resultType: "answer",
      focus: questionRecord?.focus || "",
    });
  }, [answerRecord, questionRecord, collectionId, conversationId, setSelectedResult]);

  const renderSmallActionVerificationBadge = useCallback(() => {
    return (
      <SmallActionVerificationBadge
        className={`ch-qa-result-badge-${answerRecord?.answer && answerRecord?.answer.length > 0 ? "verified" : "unverified"}`}
        shieldColor={answerRecord?.answer && answerRecord?.answer.length > 0 ? "blue" : "gray"}
        shieldTooltip={
          answerRecord?.answer && answerRecord?.answer.length > 0
            ? "Verified by AI. Click to see the details."
            : "No answer available. Click to see the details."
        }
        onClick={() => {
          handleOnClick();
          onVerifiedAIPanelOpen();
        }}
      />
    );
  }, [answerRecord, handleOnClick, onVerifiedAIPanelOpen]);

  const renderSmallActionConfidenceScoreBadge = useCallback(() => {
    return (
      <SmallActionConfidenceScoreBadge
        score={confidenceValue}
        scoreTooltip={`Confidence score: ${confidenceValue}%`}
        scoreBgColor={iconColor}
        onClick={() => {
          handleOnClick();
          onVerifiedAIPanelOpen();
        }}
      />
    );
  }, [confidenceValue, handleOnClick, iconColor, onVerifiedAIPanelOpen]);

  if (!answerRecord && !questionRecord) {
    return null;
  } else {
    return (
      <Stack pb={isMobile ? "1.5rem" : ".5rem"}>
        <Stack direction={isMobile ? "column" : "row"} justifyContent={"flex-start"} spacing="1rem" width="100%" pt=".5rem">
          <Stack direction={isMobile ? "row" : "column"} align={"center"} spacing="1rem">
            {enableHelpPopover ? (
              <>
                <HelpPopover
                  title="Verified by AI"
                  message="Click here to view the attributions on the source material Charli used for generating the response.">
                  {renderSmallActionVerificationBadge()}
                </HelpPopover>
                <HelpPopover
                  position="bottom"
                  title="Confidence Score"
                  message={`Confidence score: ${confidenceValue}. The data confidence score is an advanced calculation performed by Charli based on its ability to normalize disparate data sources, assess the trust in various data sources, and verify the validity of the data received.`}>
                  {renderSmallActionConfidenceScoreBadge()}
                </HelpPopover>
              </>
            ) : (
              <>
                {renderSmallActionVerificationBadge()} {renderSmallActionConfidenceScoreBadge()}
              </>
            )}
            <Stack direction="row" spacing="0">
              <ProjectThumbHotlinks
                action="up"
                resultId={answerRecord?.id}
                collectionId={collectionId}
                isOpen={isThumbsUpOpen}
                onToggle={toggleThumbsUp}
                onClose={onThumbsUpClose}
              />
              <ProjectThumbHotlinks
                action="down"
                resultId={answerRecord?.id}
                collectionId={collectionId}
                isOpen={isThumbsDownOpen}
                onToggle={toggleThumbsDown}
                onClose={onThumbsDownClose}
              />
            </Stack>
            <SmallActionButton
              classNames="ch-copy-record"
              iconTypeName={BiCopy}
              onClick={() => navigator.clipboard.writeText(copyAnswer(questionRecord))}
              tooltip={"Copy Answer"}
            />
          </Stack>
          <MarkupTextViewer
            maxHeight="65rem"
            className="ch-qa-result-answer"
            markupText={answerRecord?.answer && answerRecord?.answer.length > 0 ? answerRecord?.answer : noAnswerCopy}
            searchText={searchText}
          />
        </Stack>
      </Stack>
    );
  }
};
