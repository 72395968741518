import { Badge, Td } from "@chakra-ui/react";
import React from "react";
import capitalize from "lodash/capitalize";
import type { EntitlementConfig } from "api/entitlementConfigs/models/Entitlement";

export function EntitlementDetails({ entitlement }: { entitlement: EntitlementConfig }) {
  const enabledPlans = Object.entries(entitlement.plans)
    .filter(([_, config]) => config.enabled)
    .map(([plan]) => capitalize(plan))
    .sort();

  const organizationsToDisplay = entitlement.organizations ? entitlement.organizations : [];

  const users = (entitlement.users ?? []).map((u) => u.email);

  return (
    <>
      <Td>
        {enabledPlans.length === 0 || !entitlement ? (
          <>-</>
        ) : (
          enabledPlans.map((plan, index) => (
            <Badge mr={2} key={index}>
              {plan}
            </Badge>
          ))
        )}
      </Td>
      <Td>
        {organizationsToDisplay.length === 0 || !entitlement ? (
          <>-</>
        ) : (
          organizationsToDisplay.length > 0 &&
          organizationsToDisplay.map((org, index) => {
            return (
              <Badge mr={2} key={index}>
                {org.name}
              </Badge>
            );
          })
        )}
      </Td>
      <Td>{users.length === 0 || !entitlement ? <>-</> : <Badge mr={2}>{users.length}</Badge>}</Td>
    </>
  );
}
