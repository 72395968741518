import { prependProxyBaseUrl, request } from "api/request";

const WEBSCRAPE_ENDPOINT = prependProxyBaseUrl("/api/web-scraping");

export async function getWebsiteTitle(url: string) {
  const sanitizedUrl = url ? (url.startsWith("http") ? url : `http://${url}`) : undefined;
  if (sanitizedUrl) {
    try {
      const params = new URLSearchParams({ url: sanitizedUrl }).toString();
      const title = (await request().url(`${WEBSCRAPE_ENDPOINT}/title?${params}`).get().json()) as {
        [key: string]: string;
      };
      return title;
    } catch (err) {
      console.warn(`Unable to parse "${sanitizedUrl}" as a URL, no title will be returned as a result`);
    }
  }
}
