import { createAsyncThunk } from "@reduxjs/toolkit";
import { getNotifications, updateNotification, dismissAllNotifications } from "api/notifications";

export const downloadNotifications = createAsyncThunk("notification/download", async () => {
  return await getNotifications();
});

export const dismissNotification = createAsyncThunk("notification/dismiss-notification", async (id: string) => {
  return await updateNotification({ id, hasBeenDismissed: true });
});

export const dismissNotifications = createAsyncThunk("notification/dismiss-all-notifications", async () => {
  return await dismissAllNotifications();
});

export const disableNotificationFollowOnAction = createAsyncThunk("notification/disable-notification-action", async (id: string) => {
  return await updateNotification({ id, followOnActionEnabled: false });
});
