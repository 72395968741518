import { Input, Stack, useBreakpointValue, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import React, { useContext, useMemo, useRef, useEffect } from "react";
import { CollectionsFilterContext } from "./CollectionsFilterContext";
import { useButtonProps } from "hooks";
import { AiOutlineTag } from "react-icons/ai";
import { CollectionsFilterClearFilterButton } from "./CollectionsFilterClearFilterButton";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useLocation } from "react-router-dom";

interface Props {
  onFilterToggle: () => void;
  isOpen: boolean;
  hideStateFilter?: boolean;
  hideTagFilter?: boolean;
}

export const CollectionsFilterMenu = ({ onFilterToggle, isOpen, hideStateFilter, hideTagFilter }: Props) => {
  const { pathname } = useLocation();
  const isWide = useBreakpointValue({ base: false, md: true });
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const primaryButtonProps = useButtonProps("sm", "primary");
  const { selectedTags, searchText, setSearchText, filteredCollections } = useContext(CollectionsFilterContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const { setIsAnotherInputFocused } = useConversationContext();

  useEffect(() => {
    setSearchText("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const distinctTags = useMemo(() => {
    const allTags = filteredCollections.reduce((accum, collection) => {
      const tags = collection.metadata.autoTags || [];
      tags.forEach((tag) => {
        if (accum[tag]) {
          accum[tag] = accum[tag] + 1;
        } else {
          accum[tag] = 1;
        }
      });

      return accum;
    }, {} as { [key: string]: number });

    return Object.entries(allTags)
      .map((tagPair) => {
        return { name: tagPair[0], occurrences: tagPair[1] };
      })
      .sort((a, b) => {
        return b.occurrences - a.occurrences;
      });
  }, [filteredCollections]);

  const presentedTags = useMemo(() => {
    // Concatenate active tag filters that don't match the filter being applied, so that they can be unselected if needed
    return distinctTags.concat(
      selectedTags
        .filter((tag) => !distinctTags.some((distinctTag) => tag === distinctTag.name))
        .map((tagName) => {
          return { name: tagName, occurrences: 0 };
        })
    );
  }, [distinctTags, selectedTags]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <>
      {isWide && (
        <Stack direction="row">
          <Input
            className="ch-project-filter-input"
            {...secondaryButtonProps}
            ref={inputRef}
            value={searchText}
            placeholder="Filter Projects by Name, Tags or Notes"
            onChange={(e) => {
              setIsAnotherInputFocused(true);
              setSearchText(e.target.value);
            }}
            onClick={() => {
              setIsAnotherInputFocused(true);
            }}
            onBlur={() => {
              setIsAnotherInputFocused(false);
            }}
          />
          <CollectionsFilterClearFilterButton />
          {!hideTagFilter && (
            <Tooltip label={`${isOpen ? "Close Tag Filter" : "Open Tag Filter"}`}>
              <IconButton
                {...(isOpen ? { ...primaryButtonProps } : { ...secondaryButtonProps })}
                disabled={presentedTags.length === 0}
                onClick={onFilterToggle}
                aria-label="Tag Filter"
                icon={<Icon as={AiOutlineTag} fontSize="1rem" />}
              />
            </Tooltip>
          )}
        </Stack>
      )}
    </>
  );
};
