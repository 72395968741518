import React from "react";
import { PanelView } from "screens/panels/components/PanelView";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { JSONEditor } from "./JsonEditor";
import type { JSONEditorProps } from "./JsonEditor";

interface IProps extends JSONEditorProps {
  isOpen: boolean;
  panelTitle: string;
  onClose: () => void;
}

export const JsonEditorPanel = ({ isOpen, panelTitle, onClose, ...jsonEditorProps }: IProps) => {
  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={panelTitle}>
      <Wizard>
        <PanelStep justifyContent={"flex-start"}>
          <JSONEditor {...jsonEditorProps} />
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
