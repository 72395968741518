import type { Member } from "types/organization/member";
import type { Organization } from "types/organization/organization";

export function isOrganization(payload: unknown): payload is Organization {
  return (payload as Organization).id !== undefined;
}

export function isMembers(payload: unknown): payload is Member[] {
  return (payload as Member[])[0].userId !== undefined;
}

export function isMember(payload: unknown): payload is Member {
  return (payload as Member).userId !== undefined;
}
