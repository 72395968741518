import { Box, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { TabTitle } from "screens/common/components/TabTitle";
import { LandingTitle } from "screens/landing/components";
import { MarkdownItemView } from "screens/markdown/MarkdownItemView";

interface Props {
  markdownData?: { title: string; markdown: string }[];
  markdownString?: string;
  markdownTitle?: string;
}

export const MarkdownView: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  markdownData,
  markdownString,
  markdownTitle,
}) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const subTitlecolor = useColorModeValue("charli.mediumGray", "gray.400");

  return (
    <>
      <TabTitle title={`Charli > ${markdownTitle ? markdownTitle : "Markdown"}`} />
      {markdownString && markdownString !== "" && (
        <Box mt="1rem">
          <Stack direction="row" mb="1.5rem" justifyContent="space-between">
            <LandingTitle text={markdownTitle ? markdownTitle : "Markdown"} />
          </Stack>
          <Box
            mt="1.5rem"
            mr="1rem"
            backgroundColor={bgColor}
            borderRadius="md"
            overflow="hidden"
            position="relative"
            borderColor={borderColor}
            borderWidth="1px"
            boxShadow="none">
            <Box my="1.5rem" ml="1.5rem">
              <MarkdownItemView markdown={markdownString} />
            </Box>
          </Box>
        </Box>
      )}
      {markdownData && markdownData.length > 0 && (
        <SimpleGrid columns={2} spacingX="2rem" spacingY="2rem">
          {markdownData.map((cell, index) => {
            return (
              <Box
                key={`markdown-${index}`}
                mt="1.5rem"
                mr="1rem"
                backgroundColor={bgColor}
                borderRadius="md"
                overflow="hidden"
                position="relative"
                borderColor={borderColor}
                borderWidth="1px"
                boxShadow="none">
                <Stack direction="row" px="1.5rem" py="1rem" backgroundColor={borderColor} width="100%">
                  <Text fontSize="md" color={subTitlecolor} fontWeight="normal">
                    Charli Command
                  </Text>
                  <Text fontSize="md" color={subTitlecolor} fontWeight="semibold">
                    {cell.title}
                  </Text>
                </Stack>
                <Box maxHeight="20rem" overflow="auto" my="1.5rem" ml="1.5rem">
                  <MarkdownItemView markdown={cell.markdown} />
                </Box>
              </Box>
            );
          })}
        </SimpleGrid>
      )}
    </>
  );
};
