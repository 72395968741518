import {
  Box,
  Stack,
  useBreakpointValue,
  Icon,
  Tooltip,
  useDisclosure,
  Collapse,
  Button,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  MenuDivider,
  useToast,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useContext, useEffect, useMemo } from "react";
import { CollectionsFilterContext, CollectionsFilterMenu, CollectionsTabHeaderFilter, ProjectLandingTiles } from "../collections";
import {
  useButtonProps,
  useCollection,
  useConfigMap,
  useGetViewConfig,
  useProjectParams,
  useSidebarNavigation,
  useUserPreference,
  usePortfolioCollections,
  useFeatureFlags,
} from "hooks";
import { getViewConfig } from "configs/configMap";
import { IoBarChartSharp } from "react-icons/io5";
import { DEFAULT_SIDEBAR_WIDTH, LandingTitle } from "screens/landing/components";
import { ProjectMetrics } from "../collections/ProjectMetrics";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";
import { NewProjectMenuItem } from "../library/NewProjectMenuItem";
import { ProjectDeleteButton } from "screens/collection/views/ProjectActions/ProjectDeleteButton";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ConversationContext } from "screens/thread/ConversationContext";
import { PausedWorkflowModalProvider } from "screens/collection/views/ProjectActions/PausedWorkflowModal";
import { LuSettings2 } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";
import onSubmitDueDiligence from "screens/collection/components/useSubmitDueDiligence";
import { useDispatch } from "react-redux";

interface Props {
  hideTitle?: boolean;
  collectionType: string;
  loadTilesCount?: number;
}

export const ProjectLanding: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  hideTitle,
  collectionType,
  loadTilesCount,
}) => {
  const { projectFilter } = useProjectParams();
  const portfolioProject = useCollection(projectFilter);
  const configMap = useConfigMap();
  const portfolioCollectionType = getViewConfig("portfolioProjectType", portfolioProject?.collectionType || "", configMap);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const projectTitle = useGetViewConfig("title", portfolioCollectionType || projectFilter, configMap);
  const { isOpen: showMetrics, onToggle: toggleMetrics } = useDisclosure();
  const commonButtonProps = useButtonProps("sm", "primary");
  const { isOpen: isFilterOpen, onToggle: onFilterToggle, onClose: onFilterClose } = useDisclosure();
  const hasNewProjectForm = useGetViewConfig("newProjectForm", portfolioCollectionType || projectFilter, configMap);
  const { onDeleteProjectModalOpen } = useContext(ConversationContext);
  const { onPortfolioSettingsOpen } = useAddToCharliContext();
  const isDueDiligence = portfolioCollectionType === "due_diligence" || collectionType === "due_diligence";
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const { currentSidebarType } = useSidebarNavigation();
  const { filteredCollections } = useContext(CollectionsFilterContext);
  const portfolioCollections = usePortfolioCollections();
  const dispatch = useDispatch();
  const toast = useToast();
  const { enable_rerun_all_projects_button: hasRerunAllProjects } = useFeatureFlags();

  const portfolioIdByProjectIdMap = useMemo(() => {
    const map = new Map<string, string>();
    portfolioCollections.forEach((portfolio) => {
      portfolio.metadataIds.forEach((collectionId) => {
        map.set(collectionId, portfolio.id);
      });
    });

    return map;
  }, [portfolioCollections]);

  const mainCollections = useMemo(
    () => filteredCollections.filter((collection) => collection.isActiveProjectInGroup),
    [filteredCollections]
  );

  const handleRerunAllProjects = () => {
    const date = new Date();

    mainCollections.forEach((collection, index) => {
      if (!collection.ticker || !collection.stockExchange) {
        return;
      }

      const maybePortfolioId = portfolioIdByProjectIdMap.get(collection.id);

      const payload = {
        companyTickerOrName: collection.ticker,
        companyStockExchange: collection.stockExchange,
        isTicker: true,
        dispatch,
        onConversationOpen: undefined,
        onSubmitted: undefined,
        portfolioId: maybePortfolioId,
        reRunProjectId: collection.id,
        companyStockType: undefined,
        modelUpgradedQuestionAndFocus: undefined,
        scheduledStartTimestamp: index === 0 ? undefined : new Date(date.getTime() + index * 20 * 60 * 1000).getTime(),
      };

      onSubmitDueDiligence(payload);
    });

    toast({
      title: "Rerun All Projects",
      description: "All projects have been scheduled to rerun.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  useEffect(() => {
    onFilterClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioProject]);

  return (
    <>
      <Box height="100%" width="100%" maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`} overflow="hidden">
        {isDueDiligence && (
          <Collapse animateOpacity in={showMetrics}>
            <ProjectMetrics />
          </Collapse>
        )}
        <Stack spacing="0" justifyContent="space-between">
          <Stack pl={isMobile ? ".5rem" : "1rem"} pr={isMobile ? ".5rem" : "6px"} mt="0!important">
            <LandingTitle
              underline
              text={
                portfolioProject
                  ? `${portfolioProject.name}`
                  : `${projectTitle ? (projectTitle.toLowerCase().includes("projects") ? projectTitle : `${projectTitle} Projects`) : ""}`
              }
              color={"primary.default"}>
              <Stack direction="row" spacing="0.5rem" pr={isMobile ? "0" : ".7rem"}>
                {!isMobile && portfolioProject && (
                  <Tooltip label="Rerun All Projects" aria-label="Rerun Projects">
                    <Box cursor="not-allowed">
                      <IconButton
                        {...(hasRerunAllProjects && {
                          onClick: () => handleRerunAllProjects(),
                        })}
                        isDisabled={!hasRerunAllProjects}
                        className="ch-rerun-all-projects-button"
                        aria-label="Rerun Projects"
                        {...commonButtonProps}
                        icon={<Icon as={HiOutlineRefresh} boxSize="1.2rem" />}
                      />
                    </Box>
                  </Tooltip>
                )}
                {!isMobile && portfolioProject && (
                  <Tooltip label={`${showMetrics ? "Hide" : "Open"} Portfolio Settings`} aria-label="Show/Hide Settings">
                    <Box cursor="pointer" onClick={() => onPortfolioSettingsOpen()}>
                      <IconButton
                        className="ch-open-portfolio-settings-button"
                        aria-label="Show/Hide Settings"
                        {...commonButtonProps}
                        icon={<Icon as={LuSettings2} boxSize="1.2rem" />}
                        onClick={() => {
                          onPortfolioSettingsOpen();
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
                {isDueDiligence && (
                  <Tooltip label={`${showMetrics ? "Hide" : "Show"} Metrics`} aria-label="Show/Hide Metrics">
                    <Box cursor="pointer" onClick={() => toggleMetrics()}>
                      <IconButton
                        className="ch-project-landing-show-metrics"
                        aria-label="Show/Hide Metrics"
                        {...commonButtonProps}
                        icon={<Icon fontWeight={"bold"} as={IoBarChartSharp} />}
                        onClick={() => {
                          toggleMetrics();
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
                <Box>
                  <Menu isLazy>
                    <MenuButton
                      {...commonButtonProps}
                      className="ch-project-landing-actions-menu"
                      as={Button}
                      aria-label="Actions"
                      rightIcon={<TriangleDownIcon />}
                      onClick={(event) => event.stopPropagation()}>
                      Actions
                    </MenuButton>
                    <MenuList minWidth={"unset"} zIndex={10}>
                      {hasNewProjectForm && collectionType !== "due_diligence" && (
                        <>
                          <NewProjectMenuItem collectionType={collectionType} buttonLabel={`New ${projectTitle} Project`} />
                        </>
                      )}
                      {portfolioProject && (
                        <OptionsMenuItem
                          menuLabel="Portfolio Settings"
                          className="ch-open-project-settings"
                          onClick={() => {
                            onPortfolioSettingsOpen();
                          }}
                        />
                      )}
                      <OptionsMenuItem
                        {...(hasRerunAllProjects && {
                          onClick: () => handleRerunAllProjects(),
                        })}
                        menuLabel={"Rerun All Projects"}
                        className="ch-rerun-all-projects"
                        isDisabled={!hasRerunAllProjects}
                      />
                      <OptionsMenuItem
                        menuLabel={`${showMetrics ? "Hide" : "Show"} Metrics`}
                        className="ch-open-project-metrics"
                        onClick={() => {
                          toggleMetrics();
                        }}
                      />
                      {portfolioProject && (
                        <>
                          <MenuDivider />
                          <ProjectDeleteButton
                            label="Delete Portfolio"
                            className="ch-delete-portfolio-landing"
                            onOpenConfirmation={onDeleteProjectModalOpen}
                            projectId={portfolioProject.id}
                            isMenuItem
                            navigatePath="/home"
                          />
                        </>
                      )}
                    </MenuList>
                  </Menu>
                </Box>
              </Stack>
            </LandingTitle>
            <Stack justifyContent="flex-end" width="100%" direction="row" pr={isMobile ? "0" : ".7rem"}>
              <Box width={isMobile ? "100%" : "50%"} maxWidth="30rem">
                <CollectionsFilterMenu onFilterToggle={onFilterToggle} isOpen={isFilterOpen} hideStateFilter hideTagFilter />
              </Box>
            </Stack>
          </Stack>
          <Stack spacing="1rem" pl={isMobile ? ".5rem" : "1rem"} pr={isMobile ? ".2rem" : "1rem"}>
            <PausedWorkflowModalProvider>
              {!hideTitle && <CollectionsTabHeaderFilter />}
              <Box mb={"5rem!important"}>
                <ProjectLandingTiles loadTilesCount={loadTilesCount} />
              </Box>
            </PausedWorkflowModalProvider>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
