export const defaultDocumentConfig = {
  max_entity_value_length: 15,
  extractor_scores: {
    neighboring_table_extractor: 1,
  },
  include_bottom_context: false,
  flair_extractor: false,
  n_gram_categorical_extractor: false,
  unpack_address: false,
  parse_document_hierarchy_text: false,
  confidence_threshold_table: 0.8,
  confidence_threshold_text_hierarchy: 0.7,
  max_same_depth_elements: 5,
  iterate_table_hierarchy: false,
  iterate_list: false,
  num_title_levels: 1,
  parse_children_if_title_matched: false,
  free_text_extractor: false,
};
