import type { Static } from "runtypes";
import { Boolean } from "runtypes";
import { Array, Record, String, Number } from "runtypes";
import { ContentUrls } from "./ContentUrls";
import { DetailSection } from "./DetailSection";
import { DynamicData } from "./DynamicData";
import { ExtractedHighlights } from "./ExtractedHighlights";
import { ExtractedTableHighlights } from "./ExtractedTableHighlights";
import { Note } from "./Note";
import { SummaryDetail } from "./SummaryDetail";
import { TableContent } from "./TableContent";
import { Tag } from "./Tag";

export const ContentDetailsResponse = Record({
  id: String,
  mediaId: String,
  contentId: String,
  type: String,
  collectionType: String.optional(),
  name: String.nullable(),
  extension: String.nullable(),
  tags: Array(Tag),
  notes: Array(Note),
  detailSections: Array(DetailSection),
  summaryDetails: SummaryDetail.nullable(),
  urls: ContentUrls.nullable(),
  size: Number.nullable(),
  timeCreated: String,
  timeLastModified: String,
  isArchived: Boolean,
  highlights: Array(Record({})),
  extractedHighlights: Array(ExtractedHighlights),
  category: String.nullable(),
  table: Array(TableContent),
  extractedTableHighlights: Array(ExtractedTableHighlights).optional(),
  dynamicData: DynamicData.optional(),
  integrationUrn: String.optional(),
  sourceIntegrationUrn: String.optional(),
});

export type ContentDetailsResponse = Static<typeof ContentDetailsResponse>;
