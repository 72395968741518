import type { FunctionComponent } from "react";
import React, { useContext } from "react";
import { PanelView } from "screens/panels/components/PanelView";
import { PanelStep } from "screens/panels/components/PanelStep";
import { Wizard } from "react-use-wizard";
import { ResearchContext } from "./ResearchContext";
import { Box } from "@chakra-ui/react";
import { JsonItemView } from "screens/json/JsonItemView";

interface Props {
  formJson: string;
}
export const DynamicFormEditorPanel: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ formJson }) => {
  const { isDynamicFormEditorPanelOpen, onDynamicFormEditorPanelClose } = useContext(ResearchContext);

  return (
    <PanelView isOpen={isDynamicFormEditorPanelOpen} onClose={onDynamicFormEditorPanelClose} panelTitle="Dynamic Form Viewer">
      <Wizard>
        <PanelStep>
          <Box>
            <JsonItemView json={formJson} />
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
