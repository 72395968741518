import { Box, Center, SimpleGrid, Stack, Text, useBreakpointValue, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import {
  useDownloadShareHistory,
  useItemSelector,
  useContentDetails,
  useCollection,
  useTileProps,
  useUserPreference,
  useProjectParams,
} from "hooks";
import type { FunctionComponent } from "react";
import React, { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import type * as collection from "types/collection";
import { ContentSummaryData } from "../previewSection/ContentSummaryData";
import { ExtractedTableHighlightSection } from "../detailsSection/ExtractedTableHighlightSection";
import { TabTitle } from "screens/common/components/TabTitle";
import { ShareHistorySection } from "../../common/Share/ShareHistorySection";
import { useLocation } from "react-router-dom";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { ContentHeader } from "../../contentCanvas/header/ContentHeader";
import { ConversationContext } from "screens/thread/ConversationContext";
import { getMetatDataForContent } from "../../common/utils";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ContentViewTags } from "./ContentViewTags";
import { BlockSectionHeader } from "../previewSection/BlockSectionHeader";
import { ContentHighlightData } from "../previewSection/ContentHighlightData";
import { ContentMultiColumnTable } from "./ContentMultiColumnTable";
import { ContentPreview } from "../previewSection/ContentPreview";
import { ContentViewManualTags } from "./ContentViewManualTags";
import { LandingTitle } from "screens/landing/components";
import { ContentViewExtractedAISection } from "../ContentViewExtractedAISection";
import { BiExpand } from "react-icons/bi";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { NotesPanel } from "screens/content/common/notes/NotesPanel";
import { SharePanel } from "screens/panels/share/SharePanel";
import { VerifiedAIPanel } from "../VerifiedAIPanel";
import { ContentViewExtractedAISectionEntities } from "./ContentViewExtractedAISectionEntities";
import { useDebouncedCallback } from "use-debounce";

interface Props {
  metaDataId?: string;
  hasHighlightsFunction?: boolean;
  hasSummaryFunction?: boolean;
  isPanel?: boolean;
}

export const ContentView: FunctionComponent<
  React.PropsWithChildren<React.PropsWithChildren<Props & collection.CharliUIViewAsCollectionItem>>
> = ({ metaDataId, hasHighlightsFunction = true, hasSummaryFunction = true, isPanel = false }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { parentRoute, projectFilter, projectId, contentId } = useProjectParams();
  const currentMetaDataId = metaDataId ? metaDataId : contentId || projectId;
  const dispatch = useDispatch();
  const bgColorContentRoute = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.400");
  const { setSelectedItems, setFocusedItem } = useItemSelector();
  const downloadShareHistory = useDownloadShareHistory();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const getContentDetails = useContentDetails(currentMetaDataId, { refreshFromNetwork: true });
  const { pathname } = useLocation();
  const route = pathname.split("/")[2];
  const isContentView = pathname.includes("resource");
  const { setContentId, setConversationId } = useContext(ConversationContext);
  const { setHeaderText } = useContext(SettingsProviderContext);
  const { setShouldCreateNewCollection } = useAddToCharliContext();
  const collection = useCollection(projectId);
  const conversationId = contentId ? contentId : collection?.conversationId;
  const contentViewRef = React.useRef<HTMLDivElement>(null);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const commonTileProps = useTileProps();
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");

  useEffect(() => {
    contentId ? setContentId(contentId) : setContentId("");
    // if the content is being viewed in a panel, set the conversationId to the contentId so the user can click the chat button and see the correct conversation
    contentId && setConversationId(contentId);
    isContentView && setHeaderText(`${getContentDetails?.name ? getContentDetails?.name : "Resource Item"}`);

    if (contentId) {
      setShouldCreateNewCollection(false);
    } else {
      setShouldCreateNewCollection(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId, getContentDetails?.name, route]);

  useEffect(() => {
    injectDebugEntityPreference && console.log("Content Details", getContentDetails);
  }, [getContentDetails, injectDebugEntityPreference]);

  useEffect(() => {
    const fetchContentDetails = async () => {
      if (getContentDetails) {
        const metadata = getMetatDataForContent(getContentDetails);
        if (metadata && metadata.mediaId.length > 0) {
          setFocusedItem({ id: metadata.mediaId, type: metadata.fileType, ...(metadata.extension && { extension: metadata.extension }) });
        }
        if (metadata && metadata.mediaId.length > 0) setSelectedItems({ [metadata.mediaId]: { type: metadata.fileType } });
      }
    };
    fetchContentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentMetaDataId) {
      downloadShareHistory(currentMetaDataId);
    }
  }, [currentMetaDataId, downloadShareHistory]);

  const sendMetadata = (text: string, type: string) => {
    if (!getContentDetails || !conversationId) return;
    const metadata = getMetatDataForContent(getContentDetails);
    if (!metadata) return;

    dispatch(
      sendMessage({
        conversationId: conversationId,
        // eslint-disable-next-line prettier/prettier
        intent: `/${metadata.intent}`,
        entities: [
          { entity: type, value: text.trim().replace(/\n/g, "") },
          { entity: "metadata_id", value: currentMetaDataId },
          { entity: metadata.itemId.label, value: metadata.itemId.value },
        ],
      })
    );
  };

  const deleteMetadata = (text: string, type: "tag" | "note" | "highlight") => {
    if (!getContentDetails || !conversationId) return;
    const metadata = getMetatDataForContent(getContentDetails);
    if (!metadata) return;

    dispatch(
      sendMessage({
        conversationId: conversationId,
        message: `Delete ${type}: ${text} from ${getContentDetails.name}`,
        intent: "/delete_document_metadata",
        entities: [
          { entity: type, value: text },
          { entity: "metadata_id", value: getContentDetails.id },
        ],
      })
    );
  };

  const [stackDimensions, setStackDimensions] = React.useState({ height: 250, width: 650 });

  const debouncedSetDimensions = useDebouncedCallback(() => {
    if (!contentViewRef.current) return;
    setStackDimensions({
      width: contentViewRef.current.offsetWidth,
      height: contentViewRef.current.offsetHeight,
    });
  }, 30);

  useEffect(() => {
    if (contentViewRef.current) {
      setStackDimensions({
        width: contentViewRef.current.offsetWidth,
        height: contentViewRef.current.offsetHeight,
      });
    }

    window.addEventListener("resize", debouncedSetDimensions);
    debouncedSetDimensions();

    return () => {
      window.removeEventListener("resize", debouncedSetDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TabTitle title={`Charli > ${getContentDetails?.name ? getContentDetails?.name : "Content Item"}`} />
      {getContentDetails ? (
        <Box px={isPanel ? "0" : "1rem"} pb={isPanel ? "0" : "1rem"}>
          {!isPanel && conversationId && (
            <Box pb=".5rem">
              <ContentHeader itemContent={getContentDetails} showActionMenu />
            </Box>
          )}
          <Stack className="canvas-view" pb="5rem" spacing="2rem">
            <SimpleGrid className="layout-qa-sections canvas-item" columns={isMobile ? 1 : 2} spacing={"2rem"}>
              <Box maxHeight={stackDimensions.height} {...commonTileProps} p="0" cursor="default">
                <ContentPreview
                  itemContent={getContentDetails}
                  thumbnailHeight={String(stackDimensions.height)}
                  thumbnailWidth={String(stackDimensions.width)}
                  maxHeight="100%"
                />
              </Box>
              <Stack minHeight={"20rem"} ref={contentViewRef} spacing="1rem" {...commonTileProps} cursor="default">
                <ContentViewExtractedAISection
                  contentDetails={getContentDetails}
                  includedSections={["content_data", "details"]}
                  hideSectionTitles
                  metadataId={currentMetaDataId}
                  isEditModeEnabled
                  collection={collection}
                  isModalOpen={isModalOpen}
                  onModalClose={onModalClose}
                />
                <ContentViewExtractedAISectionEntities
                  contentDetails={getContentDetails}
                  includedSections={["additional_metadata", "content_details", "Item"]}
                  includedEntities={["External File Link", "Link", "Link To Filing Details"]}
                />
                <ContentViewManualTags contentDetail={getContentDetails} deleteMetadata={deleteMetadata} sendMetadata={sendMetadata} />
              </Stack>
            </SimpleGrid>
            <SimpleGrid className="layout-qa-sections canvas-item" columns={isMobile ? 1 : 2} spacing={"2rem"}>
              <Box>
                <LandingTitle text="AI Extracted Details" underline color={"primary.default"} mb={"1rem"}>
                  <SmallActionButton
                    iconTypeName={BiExpand}
                    onClick={() => {
                      onModalOpen();
                    }}
                    tooltip={"Show in modal"}
                  />
                </LandingTitle>
                <Stack>
                  <ContentViewExtractedAISection
                    contentDetails={getContentDetails}
                    excludeSections={["content_data", "details", "additional_metadata", "content_details"]}
                    metadataId={currentMetaDataId}
                    isEditModeEnabled
                    collection={collection}
                    isModalOpen={isModalOpen}
                    onModalClose={onModalClose}
                  />
                  <ContentViewTags contentDetail={getContentDetails} deleteMetadata={deleteMetadata} />
                </Stack>
              </Box>
              <Box>
                <LandingTitle text="AI Generated Information" underline color={"primary.default"} mb={"1rem"} />
                <Stack spacing="2rem">
                  <ContentSummaryData itemContent={getContentDetails} bgColor={bgColorContentRoute} />
                  <ContentHighlightData
                    hasHighlightsFunction={hasHighlightsFunction}
                    itemContent={getContentDetails}
                    bgColor={bgColorContentRoute}
                  />
                </Stack>
                {getContentDetails.extractedTableHighlights && getContentDetails.extractedTableHighlights.length > 0 && (
                  <>
                    <BlockSectionHeader title={`Extracted Highlights`} />
                    <ExtractedTableHighlightSection extractedTableHighlights={getContentDetails.extractedTableHighlights} />
                  </>
                )}
              </Box>
              {getContentDetails?.tables && getContentDetails.tables.length > 0 && (
                <ContentMultiColumnTable itemContent={getContentDetails} collection={collection} sendMetadata={sendMetadata} />
              )}
              {currentMetaDataId && <ShareHistorySection metadataId={currentMetaDataId} />}
            </SimpleGrid>
          </Stack>
        </Box>
      ) : (
        <Center height="100%">
          <Stack align="center" spacing="1rem">
            <Text color={textColor}>I couldn't find that resource</Text>
            <Text color={textColor}>You can add resources with the + New button or drag and drop files here.</Text>
          </Stack>
        </Center>
      )}
      {!isPanel && (
        <>
          <SharePanel /> <NotesPanel /> <VerifiedAIPanel />;
        </>
      )}
    </>
  );
};
