import { Flex, Image, Stack, Text } from "@chakra-ui/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as queryString from "query-string";
import type { FunctionComponent } from "react";
import React, { useEffect, useState } from "react";
import { ErrorPage } from "screens/error/ErrorPage";
import logo from "screens/common/static/logos/charli-logo-full.svg";
import type { RootState } from "state/rootReducer";
import { loginWithAstrellaAccessToken } from "state/session/operations";
import { useLocation, useNavigate } from "react-router-dom";
import type { RouteProps } from "react-router";
import { LoadingGate } from "../common/components";

export const AstrellaLogin: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props: RouteProps) => {
  const { isLoading, isLoggedIn } = useSelector((state: RootState) => state.session, shallowEqual);
  const navigate = useNavigate();
  const { search } = useLocation();
  const lastError = useSelector((state: RootState) => state.session.lastError);
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (search) {
      const params = queryString.parse(search);
      const accessToken = params["accessToken"] as string;

      dispatch(loginWithAstrellaAccessToken(accessToken));
    } else {
      setError("Access token was not provided.");
    }
    // A valid federated payload was not found.
  }, [search, dispatch]);

  const willShowLoadingSpinner = isLoading || isLoggedIn;

  useEffect(() => {
    if (isLoggedIn) {
      const navigateTo = (() => {
        const { route } = queryString.parse(search);

        return typeof route === "string" ? `${route}` : "/";
      })();

      navigate(navigateTo);
    }
  }, [isLoggedIn, search, navigate]);

  if (error || (!isLoading && lastError)) {
    return <ErrorPage title="Login failed" message={error || lastError} />;
  } else {
    return (
      <Flex height="100vh" width="100vw" alignItems="center" justifyContent="center" direction="column">
        <LoadingGate isLoading={willShowLoadingSpinner}>
          <Stack direction="row" mt="4">
            <Text fontWeight="500" color="#1351AA" mt="0.18rem" fontSize="1.15rem" fontFamily="altBody">
              Powered by
            </Text>
            <Image src={logo} alt="Charli logo" maxWidth="5rem" ml="-0.2rem" />
          </Stack>
        </LoadingGate>
      </Flex>
    );
  }
};
