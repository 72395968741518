import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConversationById, getConversationHistory } from "api/conversation/conversations";
import { getTags } from "api/tags";
import { parseISO } from "date-fns";
import { normalize, schema } from "normalizr";
import type { CharliUI } from "types/charliui";
import type { Conversation, Message } from "types/conversation";
import type { Conversation as ConversationAPI } from "api/conversation/models/Conversation";

// Convert conversations and messages from the API to redux ready models
const normalizeConversationData = (conversations: ConversationAPI[]) => {
  const convertedConversations: Conversation[] = conversations.map((apiConversation) => {
    return {
      id: apiConversation.id,
      messages: apiConversation.messages.map((apiMessage) => {
        const message: Message = {
          id: apiMessage.id,
          acknowledgmentStatus: "acknowledged",
          senderId: apiMessage.senderId,
          createdDate: apiMessage.createdDate,
          createdTime: parseISO(apiMessage.createdDate).getTime(),
          recipientId: apiMessage.recipientId,
          conversationId: apiConversation.id,
          parentMessageId: undefined,
          intent: apiMessage.intent,
          content: apiMessage.content ?? undefined,
          state: apiMessage.state ?? undefined,
          // it'll take a lot to fully map all the possible objects in this to runtypes
          // so for now, we'll keep casting like we used to
          data: apiMessage.data as CharliUI[],
          viewId: undefined,
          entities: undefined,
        };

        return message;
      }),
    };
  });

  return flattenConversationData(convertedConversations);
};

export type NormalizedConversations = {
  [key: string]: { id: string; messages: Array<string> };
};
export type NormalizedMessages = { [key: string]: Message };

export const downloadConversationHistory = createAsyncThunk("conversation/history", async (arg, thunkAPI) => {
  const history = await getConversationHistory(0, 50);

  return normalizeConversationData(history);
});

export const downloadConversationById = createAsyncThunk("conversation/by-id", async ({ conversationId }: { conversationId: string }) => {
  const conversations = await getConversationById(conversationId);

  return normalizeConversationData(conversations);
});

function flattenConversationData(conversations: Array<Conversation>) {
  const message = new schema.Entity("messages");
  const conversationSchema = new schema.Entity("conversations", {
    messages: [message],
  });

  const normalizedMessages = normalize(conversations, [conversationSchema]) as {
    entities: {
      conversations: NormalizedConversations;
      messages: NormalizedMessages;
    };
  };

  return normalizedMessages.entities;
}

export const downloadTags = createAsyncThunk("conversation/download-tags", async () => {
  return await getTags();
});
