import { Button, Center, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import React from "react";
import { Link } from "react-router-dom";
import { TabTitle } from "screens/common/components/TabTitle";
import { ReactComponent as CharliLogoFull } from "screens/common/static/logos/charli-logo-full.svg";

interface Props {
  title: string;
  message?: string;
  redirectTo?: { path: string; buttonText: string };
}

export const ErrorPage = ({ redirectTo = { path: "/", buttonText: "Return to Home" }, ...props }: Props) => {
  const titleColor = useColorModeValue("gray.600", "gray.100");
  const messageColor = useColorModeValue("gray.500", "gray.300");
  const commonButtonProps = useButtonProps("sm", "secondary");

  return (
    <Center width="100vw" height="100vh">
      <TabTitle title="Charli > Not Found" />
      <Stack alignContent="center">
        <Center width="100%">
          <CharliLogoFull id="close-modal-button" style={{ width: "11rem", height: "5rem" }} />
        </Center>
        <Stack spacing="1rem" width="100%">
          <Center width="100%">
            <Text color={titleColor} fontSize={["md", "xl", "xl"]} fontWeight="bold">
              {props.title}
            </Text>
          </Center>
          {props.message && (
            <Center width="100%">
              <Text color={messageColor} fontSize="md" fontWeight="light">
                {props.message!}
              </Text>
            </Center>
          )}
          <Center width="100%">
            <Link to={redirectTo.path}>
              <Button {...commonButtonProps} mt="2rem">
                {redirectTo.buttonText}
              </Button>
            </Link>
          </Center>
        </Stack>
      </Stack>
    </Center>
  );
};
