import type { Static } from "runtypes";
import { Record, String, Union, Literal } from "runtypes";
import { Value } from "./Value";

export const OrganizationPreference = Record({
  preferenceType: Union(Literal("organization")),
  entityId: String,
  key: String,
  value: Value,
  createdAt: String,
  updatedAt: String,
  createdBy: String,
  updatedBy: String,
});

export type OrganizationPreference = Static<typeof OrganizationPreference>;
