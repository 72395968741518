import type { OrganizationPreference } from "./models/Preference";
import { PreferenceListResponse } from "./models/PreferenceListResponse";
import { prependProxyBaseUrl, request } from "../request";
import type { Value } from "./models/Value";

export const PROXY_PREFIX = prependProxyBaseUrl("/api/user-preferences");

export async function getOrganizationsPreferences(): Promise<OrganizationPreference[]> {
  const preferences = await request().url(`${PROXY_PREFIX}/organization-preferences`).get().json();
  const validationResult = PreferenceListResponse.validate(preferences);

  if (validationResult.success) {
    return validationResult.value;
  } else {
    console.warn(`Could not fetch entitlements configs <- ${JSON.stringify(validationResult.details)}`);
    return [];
  }
}

export async function putOrganizationPreference(organizationId: string, key: string, value: Value): Promise<void> {
  await request().url(`${PROXY_PREFIX}/organization-preferences/${organizationId}/${key}`).put({ value }).res();
}

export async function deleteOrganizationPreference(organizationId: string, key: string): Promise<void> {
  await request().url(`${PROXY_PREFIX}/organization-preferences/${organizationId}/${key}`).delete().res();
}
