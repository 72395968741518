import { Box, Stack, Text, useColorModeValue, useOutsideClick } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useRef, useState } from "react";
import { Popover } from "react-tiny-popover";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useTileProps } from "hooks";

interface Props {
  title: string;
  subTitle?: string;
  titleStyle?: React.CSSProperties;
  subTitleStyle?: React.CSSProperties;
  helperText?: string;
}

export const SectionHeader: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  title,
  subTitle,
  titleStyle,
  subTitleStyle,
  helperText,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const helpColor = useColorModeValue("gray.500", "gray.300");
  const [showPopover, setShowPopover] = useState(false);
  const commonTileProps = useTileProps();
  const mainRef = useRef<HTMLDivElement | null>(null);
  const popRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick({
    ref: popRef!,
    handler: () => {
      setShowPopover(false);
    },
  });

  return (
    <>
      <Stack direction="row" ref={mainRef} spacing=".3rem">
        <Text
          color={titleStyle?.color ? titleStyle.color : textColor}
          fontSize={titleStyle?.fontSize ? titleStyle.fontSize : ["xs", "sm", "sm"]}
          fontWeight={titleStyle?.color ? titleStyle.color : "bold"}
          mb={subTitle ? "unset" : titleStyle?.marginBottom || ["0.5rem", "0.5rem", "0.5rem"]}
          style={titleStyle}>
          {title}
        </Text>
        {helperText && (
          <Popover
            ref={popRef}
            parentElement={mainRef?.current || undefined}
            isOpen={showPopover}
            positions={["top", "left"]}
            padding={10}
            reposition={false}
            onClickOutside={() => setShowPopover(false)}
            content={() => (
              <Box {...commonTileProps} maxWidth="15rem" cursor={"default"}>
                <Text fontSize={"sm"}>{helperText}</Text>
              </Box>
            )}>
            <InfoOutlineIcon
              color={helpColor}
              onClick={(event) => {
                event.stopPropagation();
                setShowPopover(!showPopover);
              }}
              cursor="pointer"
              boxSize="12px"
            />
          </Popover>
        )}
      </Stack>
      {subTitle && subTitle?.length > 0 && (
        <Text fontSize="xs" color="gray.500" style={subTitleStyle}>
          {subTitle}
        </Text>
      )}
    </>
  );
};
