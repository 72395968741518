import { Box, Button, Flex, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent, ReactElement } from "react";
import React from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { LinkLogo } from "screens/common/components";
import { ContentCheckbox } from "./ContentCheckbox";

interface Props {
  header?: ReactElement;
  footer?: ReactElement;
  url?: string;
  cellHeight?: string;
  isChecked: boolean;
  onClick: (event) => void;
  hideCheckbox?: boolean;
  hideBorder?: boolean;
}

export const RecentItemContainer: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  header,
  children,
  footer,
  onClick,
  isChecked,
  url,
  cellHeight = "100%",
  hideCheckbox = false,
  hideBorder = false,
}) => {
  const bgColor = useColorModeValue("white!important", "gray.700!important");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const borderColorHover = useColorModeValue("gray.400", "gray.400");

  return (
    <Box position="relative">
      {!hideCheckbox && <ContentCheckbox isChecked={isChecked} onClick={onClick} />}
      <Button
        borderRadius="5px"
        alignItems="top"
        color="primary.gray"
        p="0"
        m="0"
        backgroundColor={bgColor}
        borderColor={borderColor}
        _hover={{
          borderColor: borderColorHover,
        }}
        borderWidth={hideBorder ? "0" : "1px"}
        textAlign="left"
        width="100%"
        height="100%"
        overflow="hidden"
        onClick={onClick}>
        <Flex flexDirection="column" width="100%">
          {header && (
            <Box position="relative">
              <LazyLoadComponent placeholder={<Box height={cellHeight} maxHeight="10rem" />}>
                <Box height={cellHeight} overflow="hidden" width="100%" maxHeight="10rem">
                  {header}
                </Box>
                {url && (
                  <LinkLogo
                    url={url}
                    backgroundColor={bgColor}
                    position="absolute"
                    bottom="-10px"
                    right="10px"
                    maxHeight={"3rem"}
                    borderRadius={"1rem"}
                  />
                )}
              </LazyLoadComponent>
            </Box>
          )}
          <Box px="3" py="2">
            {children}
          </Box>
          {footer}
        </Flex>
      </Button>
    </Box>
  );
};
