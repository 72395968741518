import { Box, Select } from "@chakra-ui/react";
import { useButtonProps, useExternalIntegrations } from "hooks";
import type { FunctionComponent } from "react";
import React from "react";

interface Props {
  currentItem?: string;
  handleOnChange: (value: string) => void;
}

export const CategorySelector: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  currentItem,
  handleOnChange,
}) => {
  const { availableIntegrations, allCategories } = useExternalIntegrations();
  const setSelectedItem = (value: string) => {
    const selectedItem = value as string;
    handleOnChange(selectedItem);
  };
  const categories = allCategories.map((category) => category.category);
  const secondaryButtonStyle = useButtonProps("sm", "secondary");

  return (
    <Box>
      <Select
        {...secondaryButtonStyle}
        isDisabled={!availableIntegrations.length}
        className="ch-storage-select-list"
        placeholder="All Categories"
        value={currentItem}
        onChange={(e: { target: { value: string } }) => {
          setSelectedItem(e.target.value);
        }}>
        {categories.map((category) => (
          <option style={{ color: "gray" }} key={category} value={category}>
            {category}
          </option>
        ))}
      </Select>
    </Box>
  );
};
