import { Box, Text, useColorModeValue, Stack, Center } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useConversation } from "hooks";
import { ConversationContext } from "screens/thread/ConversationContext";
import { ActivityActionButtons } from "../tabs/activities/ActivityActionButtons";
import TruncateMarkup from "react-truncate-markup";
import { useWorkflowKey, useWorkflowStatus } from "hooks/useWorkflows";

interface Props {
  workflowId: string;
  collectionId?: string;
  isLineMessage?: boolean;
  size?: "compact" | "full";
}

export const WorkflowProgressClarification = (props: Props) => {
  const { workflowId, isLineMessage = true, collectionId, size } = props;
  const workflowState = useWorkflowStatus(workflowId);
  const workflowConversationId = useWorkflowKey(workflowId, "conversationId");
  const progressTextColor = useColorModeValue("gray.600", "gray.300");
  const borderColor = useColorModeValue("gray.100", "gray.800");
  const backgroundColor = useColorModeValue("#f9f9f9", "gray.600");
  const isWorkflowDismissed = useWorkflowKey(workflowId, "dismissed");
  const bgColor = useColorModeValue("white", "gray.800");
  const tileBorderColor = useColorModeValue("gray.300", "gray.700");
  const { onConversationOpen, setConversationId, setCollectionId } = useContext(ConversationContext);
  const { latestResponseText } = useConversation(workflowConversationId ?? "");
  const isClarificationNeededOrError = workflowState === "clarification_needed" || workflowState === "error" || workflowState === "failed";
  const isNotDismissedOrUndefined = workflowState !== undefined && !isWorkflowDismissed;
  const isClickable = isClarificationNeededOrError && isNotDismissedOrUndefined;
  const [isDismissing, setIsDismissing] = useState(false);

  const handleOpenConversation = () => {
    if (workflowConversationId) setConversationId(workflowConversationId);
    if (collectionId) setCollectionId(collectionId);
    onConversationOpen();
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isClickable) {
      handleOpenConversation();
      event.stopPropagation();
    }
  };

  if (!latestResponseText) return null;

  return (
    <>
      {size === "full" ? (
        <Box
          display={isDismissing ? "none" : "block"}
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          borderWidth={"1px"}
          borderRadius="md"
          p={".5rem"}
          onClick={handleClick}>
          <Stack {...(isLineMessage && { direction: "row" })} justifyContent={isLineMessage ? "space-between" : "center"}>
            <TruncateMarkup lines={2}>
              <Text lineHeight="1.5rem" fontSize="xs" fontStyle="italic" fontWeight={"normal"} color={progressTextColor}>
                {latestResponseText}
              </Text>
            </TruncateMarkup>
            {isClickable && (
              <Center>
                <Stack direction="row">
                  <ActivityActionButtons
                    {...props}
                    hideActionButtons
                    conversationId={workflowConversationId ?? ""}
                    state={workflowState}
                    workflowId={workflowId}
                    setIsDismissing={setIsDismissing}
                  />
                </Stack>
              </Center>
            )}
          </Stack>
        </Box>
      ) : (
        <Box
          zIndex={1}
          position={"absolute"}
          width="100%"
          left="0"
          bottom="0"
          borderColor={tileBorderColor}
          borderTopWidth="1px"
          p="10px"
          bgColor={bgColor}>
          <Stack borderLeft={`2px solid`} borderLeftColor={"orange.400"} pl="5px">
            <Text ml="5px" as={size === "compact" ? undefined : "i"} fontSize={size === "compact" ? "xs" : "sm"} color={progressTextColor}>
              {latestResponseText}
            </Text>
            <Stack direction="row" ml="5px">
              <ActivityActionButtons
                {...props}
                hideActionButtons
                conversationId={workflowConversationId ?? ""}
                state={workflowState}
                workflowId={workflowId}
                setIsDismissing={setIsDismissing}
              />
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};
