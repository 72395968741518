import React from "react";
import type { FunctionComponent } from "react";
import { NotificationTile } from "./NotificationTile";
import type { Notification } from "types/notifications";
import { useNotifications } from "hooks";
import { SimpleGrid, Stack, Text } from "@chakra-ui/react";

interface Props {
  notifications: Notification[];
}

export const NotificationList: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ notifications }) => {
  const { dismissNotification, actionNotification } = useNotifications();

  return (
    <Stack spacing="1.3rem" mt="0.9rem">
      {notifications.length > 0 ? (
        <SimpleGrid columns={2} spacingX="1.5rem" spacingY="1.5rem">
          {notifications.map((notification) => {
            return (
              <NotificationTile
                key={notification.id}
                notification={notification}
                onDismiss={dismissNotification}
                onAction={actionNotification}
                source="notifications_screen"
              />
            );
          })}
        </SimpleGrid>
      ) : (
        <Text fontSize="sm" align="start" color="gray.500" fontWeight="400">
          No notifications found.
        </Text>
      )}
    </Stack>
  );
};
