import { Literal, Union } from "runtypes";
import type { Static } from "runtypes";

export const TaskLogStatus = Union(
  Literal("queued"),
  Literal("in_progress"),
  Literal("failed"),
  Literal("clarification_needed"),
  Literal("complete"),
  Literal("awaiting_async_task")
);

export type TaskLogStatus = Static<typeof TaskLogStatus>;
