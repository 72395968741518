import React, { useState, useEffect, useContext } from "react";
import { Stack, Box, Text, useColorModeValue, Button, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { GenerateHighlights } from "./GenerateHighlights";
import { useButtonProps, useCollection, useContentDetails, useConversation } from "hooks";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { IoCopyOutline } from "react-icons/io5";
import { ConversationContext } from "screens/thread/ConversationContext";

export const HighlightsButton = () => {
  const { setRequestEntities, conversationId, contentId } = useContext(ConversationContext);
  const collection = useCollection(contentId);
  const getContentDetails = useContentDetails(contentId);
  const color = useColorModeValue("gray.700", "gray.200");
  const allHighlights = getContentDetails?.extractedHighlights || collection?.extractedHighlights;
  const hasHighlights = allHighlights && allHighlights.length > 0;
  const [isCopied, setIsCopied] = useState(false);
  const highlightsState = useConversation(contentId ? contentId : conversationId, ["extract_highlight", "generate_highlights", "cancel"]);
  const { onShareModalClose, isHighlightsPanelOpen, onHighlightsPanelClose, showHighlightsForm, setShowHighlightsForm } =
    useAddToCharliContext();
  const secondaryButtonProps = useButtonProps("sm", "secondary");

  useEffect(() => {
    setShowHighlightsForm(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isHighlightsPanelOpen) {
      setRequestEntities([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHighlightsPanelOpen, onShareModalClose]);

  const topicCopied = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const getAllHighlights = () => {
    const highlights = allHighlights
      ?.map((topics) => {
        let topicSection = topics.highlights.length ? `Topic: ${topics.topic} \r\n` : "";
        topics.highlights.map((highlights) => (topicSection += highlights.highlight + "\r\n"));
        return topicSection + "\r\n";
      })
      .join("");
    topicCopied();
    highlights && navigator.clipboard.writeText(highlights);
  };

  return (
    <Box>
      {!showHighlightsForm && highlightsState.conversationState !== "in_progress" && (
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Button
            className="ch-highlight-btn"
            {...secondaryButtonProps}
            aria-label="Show Get Highlight"
            onClick={() => setShowHighlightsForm(true)}>
            {hasHighlights ? "Add New Highlights" : "Add Highlights"}
          </Button>
          {hasHighlights && (
            <Tooltip label={isCopied ? "Copied to Clipboard" : "Copy Highlights"}>
              <IconButton
                {...secondaryButtonProps}
                className="ch-highlights-btn"
                size="xs"
                onClick={getAllHighlights}
                aria-label="copy"
                icon={<Icon as={IoCopyOutline} fontSize=".9rem" />}></IconButton>
            </Tooltip>
          )}
        </Stack>
      )}
      {showHighlightsForm && highlightsState.conversationState !== "in_progress" && (
        <GenerateHighlights
          conversationId={conversationId}
          collectionId={contentId ? undefined : contentId}
          onClose={() => (hasHighlights ? setShowHighlightsForm(false) : onHighlightsPanelClose())}
        />
      )}
      {contentId && highlightsState.conversationState === "in_progress" && (
        <Stack direction="row" align="center" height="2rem">
          <Text fontStyle="italic" alignSelf="center" fontSize="xs" color={color}>
            {hasHighlights ? `Generating new highlights` : `Generating highlights`}
          </Text>
          <Box px=".2rem">
            <TypingIndicator size="small" />
          </Box>
        </Stack>
      )}
    </Box>
  );
};
