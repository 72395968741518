import { downloadReportTemplates } from "../state/reportTemplates/operations";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import type { ReportTemplate } from "types/reportTemplates/ReportTemplate";
import type { ReportTemplateGrouping } from "../types/reportTemplates/ReportTemplateGrouping";
import type { RootState } from "../state/rootReducer";

export const useReportTemplates = (options: { refreshFromNetwork: boolean } = { refreshFromNetwork: false }): ReportTemplateGrouping[] => {
  const reportTemplates = useSelector((state: RootState) => state.reportTemplate.reportTemplates);
  const dispatch = useDispatch();

  useEffect(() => {
    if (options.refreshFromNetwork) dispatch(downloadReportTemplates());
  }, [dispatch, options.refreshFromNetwork]);

  return reportTemplates;
};

export const useReportTemplatesList = (): ReportTemplate[] => {
  const reportTemplates = useSelector((state: RootState) => state.reportTemplate.reportTemplatesById ?? {});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(downloadReportTemplates());
  }, [dispatch]);

  return useMemo(() => {
    return Object.values(reportTemplates);
  }, [reportTemplates]);
};

export const useReportTemplate = (reportTemplateId?: string): ReportTemplate | undefined => {
  const reportTemplates = useSelector((state: RootState) => state.reportTemplate.reportTemplatesById ?? {});

  return useMemo(() => {
    return reportTemplateId ? reportTemplates[reportTemplateId] : undefined;
  }, [reportTemplates, reportTemplateId]);
};
