import { Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { ChatBubble, MessageContainer, TypingIndicator } from "./components";

export const TypingIndicatorCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  return (
    <MessageContainer initiator="charli" key={`typing-indicator`}>
      <Box position="relative" overflow="visible">
        <ChatBubble initiator="charli">
          <TypingIndicator />
        </ChatBubble>
      </Box>
    </MessageContainer>
  );
};
