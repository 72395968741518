import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProjectConfigurations,
  updateProjectConfiguration,
  createProjectConfiguration,
  deleteProjectConfiguration,
  bulkUpsertProjectConfiguration,
} from "api/projectConfig";
import type { UserConfig } from "types/config";

export const downloadProjectConfigurations = createAsyncThunk("config/get-project-configurations", async () => {
  return getProjectConfigurations();
});

export const updateProjectConfig = createAsyncThunk(
  "config/update-project-configuration",
  ({ id, config }: { id: string; config: UserConfig; organizationId?: string }) => {
    return updateProjectConfiguration(id, config);
  }
);

export const createProjectConfig = createAsyncThunk("config/create-project-type", ({ config }: { config: UserConfig }) => {
  return createProjectConfiguration(config);
});

export const deleteProjectConfig = createAsyncThunk("config/delete-project-type", ({ id }: { id: string }) => {
  return deleteProjectConfiguration(id);
});

export const bulkUpsertProjectConfig = createAsyncThunk(
  "config/upsert-projects-configurations",
  ({ configs }: { configs: { config: UserConfig }[] }) => {
    return bulkUpsertProjectConfiguration(configs);
  }
);
