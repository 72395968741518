import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadCharliActivities } from "state/charliActivities/operations";
import type { Handler, Integration } from "state/charliActivities/reducer";
import type { RootState } from "state/rootReducer";

export type IntegrationWithHandlers = Integration & { handlers: Handler[] };

export const useCharliActivitiesIntegrations = (fetch?: boolean): IntegrationWithHandlers[] => {
  const { integrationsByUrn, handlersById } = useSelector((state: RootState) => state.charliActivities);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetch) {
      dispatch(downloadCharliActivities());
    }
  }, [fetch, dispatch]);

  return useMemo(
    () =>
      Object.values(integrationsByUrn).map((integration) => {
        return {
          ...integration,
          handlers: integration.handlersIds.map((handlerId) => handlersById[handlerId]),
        };
      }),
    [integrationsByUrn, handlersById]
  );
};
