import React, { useContext, useEffect } from "react";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, useBreakpointValue } from "@chakra-ui/react";
import { useNotifications, useTabProps } from "hooks";
import { NotificationList } from "screens/landing/tabs/notifications/NotificationList";
import { TabTitle } from "screens/common/components/TabTitle";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";

export const Notifications = React.memo(() => {
  const { acceptedNotifications, notifications, newNotifications, dismissedNotAcceptedNotifications } = useNotifications();
  const { setHeaderText } = useContext(SettingsProviderContext);
  const { defaultTabProps } = useTabProps();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  useEffect(() => {
    setHeaderText("Notifications");
  }, [setHeaderText]);

  return (
    <Box height={"100%"} width="100%" px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "0.5rem"}>
      <TabTitle title="Charli > Activity History" />
      <Tabs>
        <TabList>
          <Tab {...defaultTabProps}>New</Tab>
          <Tab {...defaultTabProps}>Not Accepted </Tab>
          <Tab {...defaultTabProps}>Accepted </Tab>
          <Tab {...defaultTabProps}>All</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px="0" pt="1rem">
            <NotificationList notifications={newNotifications} />
          </TabPanel>
          <TabPanel px="0" pt="1rem">
            <NotificationList notifications={dismissedNotAcceptedNotifications} />
          </TabPanel>
          <TabPanel px="0" pt="1rem">
            <NotificationList notifications={acceptedNotifications} />
          </TabPanel>
          <TabPanel px="0" pt="1rem">
            <NotificationList notifications={notifications} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
});
