import { Array, Number, Record, String, Boolean, Literal, Union } from "runtypes";
import { Entity } from "api/charliActivities/models/activities/Entity";
import { Requirement } from "api/charliActivities/models/activities/Requirement";
import { Visibility } from "api/charliActivities/models/activities/Visibility";
import type { Static } from "runtypes";

export const Activity = Record({
  id: String,
  parentActivityId: String,
  parentActivityName: String,
  intent: String,
  name: String,
  provider: String.nullable(),
  url: String,
  requirements: Array(Requirement).optional(),
  entities: Array(Entity),
  produces: Array(String),
  cost: Number,
  batchPriority: Number,
  postExecutionIntents: Array(String).optional(),
  parentActivityCategory: String,
  description: String.nullable(),
  visibility: Visibility.nullable().optional(),
  developerId: String.nullable().optional(),
  authenticationType: String.nullable().optional(),
  canRerunWithNewerInput: Boolean.nullable().optional(),
  parentActivityExternalId: String.nullable().optional(),
  isPaginated: Boolean.nullable().optional(),
  execution: Record({
    mode: Union(Literal("sync"), Literal("async_callback")),
    config: Record({
      asyncCallbackTimeoutSeconds: Number.optional(),
    }).optional(),
  })
    .nullable()
    .optional(),
  externalId: String.nullable(),
  system: String.nullable(),
});

export type Activity = Static<typeof Activity>;
