import { Box, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ConversationContext } from "./ConversationContext";
import { CharliLogoCircle } from "./CharliLogoCircle";

export const ConversationDialogButton = () => {
  const { hideConversationButton, isConversationOpen, onConversationClose, onConversationOpen, setConversationId, setCollectionId } =
    useContext(ConversationContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const onHandleClose = () => {
    if (isConversationOpen) {
      setCollectionId("");
      setConversationId("");
      onConversationClose();
    } else {
      onConversationOpen();
    }
  };

  return (
    <Box
      hidden={hideConversationButton}
      _hover={{ opacity: 1 }}
      position="fixed"
      bottom={isMobile ? "2rem" : "1rem"}
      right="1rem"
      zIndex="10001"
      cursor="pointer"
      opacity={0}>
      <CharliLogoCircle onClick={onHandleClose} className="ch-quick-actions-command-line-button" />
    </Box>
  );
};
