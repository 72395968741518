import {
  Box,
  Button,
  FormControl,
  Input,
  Link,
  Stack,
  Text,
  Center,
  HStack,
  Image,
  useBreakpointValue,
  FormHelperText,
} from "@chakra-ui/react";
import { validate as validateEmail } from "email-validator";
import React from "react";
import { useForm } from "react-hook-form";
import { CopyrightNotice } from "screens/common/components";
import { TabTitle } from "screens/common/components/TabTitle";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import { useButtonProps } from "hooks";
import logo from "screens/common/static/logos/charli-logo-full.svg";
import { GoogleSignInButton } from "./GoogleSignInButton";
import { FormErrorLabel } from "screens/panels/settings/tabs/subscription/SubscribeForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (fields: any) => void;
}

// Regular expression to check for HTML tags
const noHtmlTags = /<\/?[^>]+(>|$)/g;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters")
    .max(50, "Name cannot exceed 50 characters")
    .test("no-html-tags", "Name is not valid", (value) => !noHtmlTags.test(value)),
  lastName: yup
    .string()
    .required("Last name is required")
    .min(3, "Last name must be at least 3 characters")
    .max(50, "Last name cannot exceed 50 characters")
    .test("no-html-tags", "Last name is not valid", (value) => !noHtmlTags.test(value)),
  username: yup
    .string()
    .required("Email is required")
    .email("Email must be a valid email address")
    .test("email", "Email must be a valid email address", (value) => validateEmail(value)),
  password: yup
    .string()
    .required("Password is required")
    .min(12, "Password must be at least 12 characters")
    .max(64, "Password must be less than 65 characters"),
});

export const RegisterForm = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    username: string;
    password: string;
  }>({
    resolver: yupResolver(schema),
  });
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const commonButtonProps = useButtonProps("md", "cta");

  return (
    <Stack justifyContent={"space-between"} width={"100%"} height="100vh" alignSelf="center">
      <TabTitle title="Charli > Register" />
      <Stack spacing="2rem" pt="4rem">
        <Stack spacing="4rem">
          <Center>
            <Image src={logo} alt="Charli logo" width="12rem" cursor="pointer" />
          </Center>
          <Text color="gray.500" textAlign="center" fontWeight={"semibold"} fontSize="1.5rem">
            Get started for free
          </Text>
        </Stack>
        <Center>
          <Stack justifyContent={"space-between"} spacing="1rem" px="2rem" maxWidth={["90%", "40rem", "45rem"]}>
            <Text textAlign="start" fontSize="xs" fontWeight="semibold" pb="1rem">
              Already a user?{" "}
              <Link href="/" fontWeight="bold" color="primary.default">
                Sign in
              </Link>
            </Text>
            <form onSubmit={handleSubmit(props.onSubmit)} noValidate>
              <Stack spacing="1.5rem">
                <Stack direction="row" spacing="1rem">
                  <FormControl isInvalid={!!errors.firstName}>
                    <Input
                      _hover={{}}
                      size="sm"
                      {...register("firstName")}
                      id="register-firstName"
                      type="text"
                      placeholder="First Name"
                      fontSize="sm"
                      boxShadow="none"
                    />
                    {errors.firstName?.message && <FormErrorLabel error={errors.firstName.message} />}
                  </FormControl>
                  <FormControl isInvalid={!!errors.lastName}>
                    <Input
                      _hover={{}}
                      size="sm"
                      {...register("lastName")}
                      id="register-lastName"
                      type="text"
                      placeholder="Last Name"
                      fontSize="sm"
                      boxShadow="none"
                    />
                    {errors.lastName?.message && <FormErrorLabel error={errors.lastName.message} />}
                  </FormControl>
                </Stack>
                <FormControl isInvalid={!!errors.username}>
                  <Input
                    _hover={{}}
                    size="sm"
                    {...register("username")}
                    id="register-username"
                    type="email"
                    placeholder="Email Address"
                    fontSize="sm"
                    boxShadow="none"
                  />
                  {errors.username?.message && <FormErrorLabel error={errors.username.message} />}
                </FormControl>
                <FormControl isInvalid={!!errors.password}>
                  <Input
                    _hover={{}}
                    size="sm"
                    id="register-password"
                    type="password"
                    placeholder="Password"
                    {...register("password")}
                    fontSize="sm"
                    boxShadow="none"
                    autoComplete={"new-password"}
                  />
                  <FormHelperText textAlign={"start"} color="gray.500" fontSize={"xs"} mt="2px">
                    password must be at least 12 characters long
                  </FormHelperText>
                  {errors.password?.message && <FormErrorLabel error={errors.password.message} />}
                </FormControl>
                <Center>
                  <Button
                    {...commonButtonProps}
                    fontWeight={"normal"}
                    bgColor={"#81c34b"}
                    borderColor={"#81c34b"}
                    width="100%"
                    borderRadius={"full"}
                    id="login-submit"
                    mt="2rem!important"
                    type="submit">
                    REGISTER
                  </Button>
                </Center>
              </Stack>
            </form>
            <Stack spacing={"1rem"}>
              <Center>
                <Text position="relative" w="3rem" textAlign="center" fontSize="sm">
                  or
                </Text>
              </Center>
              <GoogleSignInButton variant={"signup"} />
            </Stack>
          </Stack>
        </Center>
      </Stack>

      <Stack spacing="3rem">
        <Stack pb="1.7rem">
          <Text textAlign="center" fontSize="11px" fontWeight="light" textColor="gray.500">
            By signing up you agree to Charli's{" "}
            <Link href="https://charli.ai/terms-of-service/" isExternal textDecoration={"underline"}>
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link href="https://charli.ai/privacy-policy/" isExternal textDecoration={"underline"}>
              Privacy Policy
            </Link>
          </Text>
          <Box textAlign="center">
            <CopyrightNotice />
          </Box>
        </Stack>
      </Stack>
      {!isMobile && (
        <HStack position="absolute" bottom="2rem" right="2rem" align={"center"} spacing="1rem">
          <Image src={soc2logo} height="3.2rem" />
          <Image src={verifiedShield} height="2.9rem" />
        </HStack>
      )}
    </Stack>
  );
};
