import { createSlice, createAction } from "@reduxjs/toolkit";
import type { Member } from "types/organization/member";
import type { Organization } from "types/organization/organization";
import {
  downloadOrganizations,
  deleteOrganization,
  addOrganizationMembers,
  createOrganization,
  updateMemberRole,
  updateOrganization,
  removeMemberFromOrganization,
  downloadOrganization,
  downloadOrganizationEntitlements,
  createAndAddOrganizationMembers,
} from "./operations";
import type { Entitlement } from "types/entitlements";
import { REHYDRATE } from "redux-persist";
import type { RootState } from "state/rootReducer";
import cloneDeep from "lodash/cloneDeep";

const rehydrate = createAction<RootState>(REHYDRATE);

type OrganizationDictionary = Omit<Organization, "members"> & { memberIds: string[] };

interface OrganizationState {
  isLoading: boolean;
  organizations: Record<string, OrganizationDictionary>;
  members: Record<string, Member>;
  entitlements: Record<string, Entitlement[]>;
  isLoadingEntitlements: boolean;
}

const initialState: OrganizationState = {
  isLoading: false,
  organizations: {},
  members: {},
  entitlements: {},
  isLoadingEntitlements: false,
};

export const { actions, reducer } = createSlice({
  name: "organization",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (state, action) => {
      if (!action.payload?.organizations?.entitlements) {
        state.entitlements = {};
      }
    });
    builder.addCase(downloadOrganizations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadOrganizations.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadOrganizations.fulfilled, (state, action) => {
      state.isLoading = false;
      const organizations = cloneDeep(action.payload);

      if (!organizations) {
        return;
      }

      const organizationsNewState = {};
      const membersNewState = {};
      organizations.forEach((organization) => {
        const { members, ...restOfOrganization } = organization;

        organizationsNewState[restOfOrganization.id] = {
          ...restOfOrganization,
          memberIds: members ? members.map((member) => member.userId) : [],
        };

        (members || []).forEach((member) => {
          membersNewState[member.userId] = member;
        });
      });

      state.organizations = organizationsNewState;
      state.members = membersNewState;
    });

    builder.addCase(downloadOrganization.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadOrganization.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadOrganization.fulfilled, (state, action) => {
      state.isLoading = false;
      const { members, ...restOfOrganization } = action.payload;

      state.organizations[restOfOrganization.id] = {
        ...restOfOrganization,
        memberIds: members ? members.map((member) => member.userId) : [],
      };

      (members || []).forEach((member) => {
        state.members[member.userId] = member;
      });
    });

    // Create organization
    builder.addCase(createOrganization.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createOrganization.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createOrganization.fulfilled, (state, action) => {
      state.isLoading = false;
      const organization = action.payload;
      const { members, ...restOfOrganization } = organization;

      state.organizations[restOfOrganization.id] = {
        ...restOfOrganization,
        memberIds: [],
      };
    });

    // Update organization
    builder.addCase(updateOrganization.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateOrganization.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateOrganization.fulfilled, (state, action) => {
      state.isLoading = false;
      const organization = action.payload;
      const { members, ...restOfOrganization } = organization;

      state.organizations[restOfOrganization.id] = {
        ...restOfOrganization,
        memberIds: state.organizations[restOfOrganization.id].memberIds,
      };
    });

    // Delete organization
    builder.addCase(deleteOrganization.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteOrganization.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteOrganization.fulfilled, (state, action) => {
      state.isLoading = false;
      const { organizationId } = action.meta.arg;

      const { memberIds } = state.organizations[organizationId];
      memberIds.forEach((memberId) => {
        delete state.members[memberId];
      });

      delete state.organizations[organizationId];
    });

    // Add members
    builder.addCase(addOrganizationMembers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addOrganizationMembers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addOrganizationMembers.fulfilled, (state, action) => {
      state.isLoading = false;
      const { organizationId } = action.meta.arg;
      const members = action.payload;

      const organizationMemberIds = [...state.organizations[organizationId].memberIds];
      members.forEach((member) => {
        const { userId } = member;
        if (!state.members[userId]) {
          organizationMemberIds.push(userId);
        }

        state.organizations[organizationId].memberIds = organizationMemberIds;
        state.members[userId] = member;
      });
    });

    // Create and Add members
    builder.addCase(createAndAddOrganizationMembers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createAndAddOrganizationMembers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createAndAddOrganizationMembers.fulfilled, (state, action) => {
      state.isLoading = false;
      action.payload.forEach((member) => {
        const { userId, organizationId } = member;
        const organizationMemberIds = [...(state.organizations[organizationId]?.memberIds || [])];

        if (!state.members[userId]) {
          organizationMemberIds.push(userId);
        }

        state.organizations[organizationId].memberIds = organizationMemberIds;
        state.members[userId] = member;
      });
    });

    // Remove member
    builder.addCase(removeMemberFromOrganization.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeMemberFromOrganization.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeMemberFromOrganization.fulfilled, (state, action) => {
      state.isLoading = false;
      const { organizationId, memberId } = action.meta.arg;

      delete state.members[memberId];
      state.organizations[organizationId].memberIds = state.organizations[organizationId].memberIds.filter((id) => id !== memberId);
    });

    // Update member role
    builder.addCase(updateMemberRole.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMemberRole.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMemberRole.fulfilled, (state, action) => {
      state.isLoading = false;
      const member = action.payload;

      state.members[member.userId] = member;
    });

    builder.addCase(downloadOrganizationEntitlements.pending, (state) => {
      state.isLoadingEntitlements = true;
    });
    builder.addCase(downloadOrganizationEntitlements.rejected, (state) => {
      state.isLoadingEntitlements = false;
    });
    builder.addCase(downloadOrganizationEntitlements.fulfilled, (state, action) => {
      state.isLoadingEntitlements = false;
      state.entitlements[action.meta.arg.organizationId] = action.payload;
    });
  },
});

export default reducer;
