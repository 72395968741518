import { Boolean, Record, String, Number, Array, Union, Literal } from "runtypes";
import type { Static } from "runtypes";

export const Entity = Record({
  entity: String,
  displayName: String.nullable().optional(),
  operator: Union(Literal("equal"), Literal("not_equal")).nullable().optional(),
  value: Union(String, Array(String), Number).nullable().optional(),
  mandatory: Boolean.nullable().optional(),
  canBeProvidedByUser: Boolean.nullable().optional(),
  resolutionStrategy: Union(
    Literal("must_resolve"),
    Literal("should_resolve"),
    Literal("must_include"),
    Literal("should_include"),
    Literal("exhaust_all_resolvers")
  ).nullable(),
  repromptOnFailure: Boolean.nullable().optional(),
});

export type Entity = Static<typeof Entity>;
