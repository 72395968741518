import type { FunctionComponent } from "react";
import React from "react";
import { ActivityTilesSectionPaginated } from "./ActivityTilesSectionPaginated";
import { ActivityTilesSectionList } from "./ActivityTilesSectionList";

interface Props {
  noItemsFoundMessage?: string;
  selectedConversation?: string;
  intentFilter?: string | "allPerType" | "";
  states?: string[];
  blacklist?: string[];
  maxActivities?: number;
  hasInfiniteScroll?: boolean;
  showAsList?: boolean;
}

export const ActivityTilesSection: FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const {
    noItemsFoundMessage,
    selectedConversation,
    intentFilter,
    states,
    blacklist,
    maxActivities,
    hasInfiniteScroll = false,
    showAsList = true,
  } = props;

  return hasInfiniteScroll ? (
    <ActivityTilesSectionPaginated
      noItemsFoundMessage={noItemsFoundMessage}
      selectedConversation={selectedConversation}
      intentFilter={intentFilter}
      states={states}
      blacklist={blacklist}
      showAsList={showAsList}
    />
  ) : (
    <ActivityTilesSectionList
      noItemsFoundMessage={noItemsFoundMessage}
      selectedConversation={selectedConversation}
      intentFilter={intentFilter}
      states={states}
      blacklist={blacklist}
      maxActivities={maxActivities}
      showAsList={showAsList}
    />
  );
};
