import React, { useCallback, useEffect, useMemo } from "react";
import { CollectionsFilterContextProvider } from "../collections";
import { ProjectLanding } from "./ProjectLanding";
import { parseISO } from "date-fns";
import { useCollections, useConfigMap, useProjectParams, useRefreshCollections, useRefreshPortfolioProjects } from "hooks";
import type { CollectionWithAdditionalProps } from "types/collection";
import { getViewConfig } from "configs/configMap";
import { isValidUUID } from "screens/content/common/utils";

interface Props {
  hideTitle?: boolean;
  collectionType?: string;
  collectionIds?: string[];
  loadTilesCount?: number;
}

export const ProjectLandingFilter: React.FC<React.PropsWithChildren<Props>> = ({
  hideTitle,
  collectionType = "due_diligence",
  collectionIds,
  loadTilesCount = 5,
}) => {
  const { projectFilter } = useProjectParams();
  const collections = useCollections();
  const configMap = useConfigMap();
  const refreshCollections = useRefreshCollections();
  const refreshPortfolioProjects = useRefreshPortfolioProjects();

  useEffect(() => {
    refreshCollections();
    refreshPortfolioProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const portfolioProject = useMemo(() => {
    const hasValidPortfolioProjectId = isValidUUID(projectFilter || "") ? projectFilter : "";
    return collections.find((collection) => collection.id === hasValidPortfolioProjectId);
  }, [collections, projectFilter]);

  const portfolioCollectionType = useMemo(
    () => getViewConfig("portfolioProjectType", portfolioProject?.collectionType || "", configMap),
    [portfolioProject, configMap]
  );

  const hasPortfolioCollectionType = portfolioCollectionType && portfolioCollectionType.length > 0;
  const sortFunction = useCallback(
    (a: CollectionWithAdditionalProps, b: CollectionWithAdditionalProps) =>
      parseISO(b.metadata.createdTime).getTime() - parseISO(a.metadata.createdTime).getTime(),
    []
  );

  const collectionsToRender = useMemo(() => {
    const sortedCollections = collections.sort(sortFunction);
    if (hasPortfolioCollectionType && collectionIds) {
      return sortedCollections.filter((item) => item.id && collectionIds.includes(item.id));
    }
    return sortedCollections.filter((item) => item.collectionType === collectionType);
  }, [collections, sortFunction, hasPortfolioCollectionType, collectionIds, collectionType]);
  return (
    <CollectionsFilterContextProvider collections={collectionsToRender}>
      <ProjectLanding hideTitle={hideTitle} collectionType={collectionType} loadTilesCount={loadTilesCount} />
    </CollectionsFilterContextProvider>
  );
};
