import { actions as userPreferenceActions } from "state/userPreference/reducer";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { UserPreferenceKey } from "types/userPreferences";

export function useUserPreferences() {
  const userPreferences = useSelector((state: RootState) => state.userPreference.userPreferences);
  const researchCountryCode = useSelector((state: RootState) => state.userPreference.researchCountryCode);
  const dispatch = useDispatch();

  const setResearchCountryCode = useCallback(
    (countryCode: string) => {
      dispatch(userPreferenceActions.setResearchCountryCode(countryCode));
    },
    [dispatch]
  );

  return { userPreferences, researchCountryCode, setResearchCountryCode };
}

export function useUserPreference(key: UserPreferenceKey) {
  return useSelector((state: RootState) => state.userPreference.userPreferences[key]);
}
export function useAllUserPreference() {
  return useSelector((state: RootState) => state.userPreference.userPreferences);
}
