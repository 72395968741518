import React, { useMemo } from "react";
import { Box, Center, useBreakpointValue } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { TabTitle } from "screens/common/components/TabTitle";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import { TypingIndicator } from "screens/thread/components/cells/components";
import type { TaskDetail } from "types/workflows/workflow";
import { JsonView } from "screens/content/contentCanvas/body/JsonView";

export const WorkflowStepErrorDetails = () => {
  const { childWorkflowId, taskId } = useParams();
  const { isChildWorkflow, isTaskId, steps } = useWorkflowContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const task = useMemo(() => {
    if (!isTaskId) return;

    return steps.find((step): step is TaskDetail => step.type === "task" && step.id === isTaskId);
  }, [isTaskId, steps]);

  const selectedChildWorkflowId = childWorkflowId ?? isChildWorkflow;
  const selectedTaskId = taskId ?? isTaskId;

  const fallbackErrorDetails = {
    workflowId: selectedChildWorkflowId,
    childWorkflowId: childWorkflowId,
    taskId: selectedTaskId,
  };

  const errorDetails = JSON.stringify(task?.error ?? { error: "Unable to find error details in store", context: fallbackErrorDetails });

  return (
    <>
      <TabTitle title={`Charli > JSON View`} />
      <Box width="100%" height="100%" className="canvas-view" px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"}>
        <Box width="100%" height="100%" className="canvas-modal">
          {task ? (
            <Box>
              <JsonView json={errorDetails} />
            </Box>
          ) : (
            <Center height="80vh">
              <TypingIndicator />
            </Center>
          )}
        </Box>
      </Box>
    </>
  );
};
