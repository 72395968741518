import { Record, String, Unknown } from "runtypes";
import type { Static } from "runtypes";

export const TaskError = Record({
  status: String,
  message: String,
  details: Unknown.optional(),
});

export type TaskError = Static<typeof TaskError>;
