import { Box, Button, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import {
  getEntitiesForSelectedItems,
  useButtonProps,
  useDownloadRecentContents,
  useItemSelector,
  useRecentContents,
  useTotalContentsCount,
} from "hooks";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { ItemIdentifier } from "types/items";
import { RecentItemTile } from "./RecentItemTile";
import { AddIcon } from "@chakra-ui/icons";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";

interface Props {
  maxListHeight?: string;
  maxColumns?: number;
  maxRecentStuff?: number;
  initialItemCount?: number;
  hideCheckbox?: boolean;
  showAddButton?: boolean;
  showRecordCount?: boolean;
  minChildWidth?: number | string | undefined;
}

export const LatestContentList = ({
  maxListHeight = "17rem",
  maxRecentStuff = 200,
  initialItemCount = 50,
  maxColumns = 10,
  hideCheckbox = false,
  showAddButton = false,
  minChildWidth,
  showRecordCount = false,
}: Props) => {
  const [itemCount, setItemCount] = useState(initialItemCount);
  const recentContentDetails = useRecentContents(itemCount);
  const totalContentsCount = useTotalContentsCount();
  const nameColor = useColorModeValue("gray.600", "gray.400");
  const buttonColor = useColorModeValue("primary.default", "gray.500");
  const commonButtonProps = useButtonProps("sm", "secondary");
  const { onAddToCollectionModalOpen, setIsNewMenuAction } = useAddToCharliContext();
  const { items, addItem, removeItem } = useItemSelector();
  const { setRequestEntities } = useContext(ConversationContext);

  const getMoreItems = () => {
    if (maxRecentStuff > itemCount) {
      setItemCount(itemCount + 50);
    }
  };

  const recentsToRender = useMemo(() => {
    return recentContentDetails.flatMap((stuff) => {
      switch (stuff.type) {
        case "dynamic_data": {
          return [
            {
              id: stuff.mediaId,
              metadataId: stuff.id,
              key: `data-${stuff.id}`,
              label: stuff.name || `${stuff.category} Data`,
              extension: stuff.extension || "dat",
              onSelectKey: "file_id",
            },
          ];
        }
        case "expense": {
          return [
            {
              id: stuff.mediaId,
              metadataId: stuff.id,
              key: `expense-${stuff.id}`,
              label: stuff.name || "Expense",
              extension: "exp",
              onSelectKey: "expense_id",
            },
          ];
        }
        case "file":
        case "document": {
          return [
            {
              id: stuff.mediaId,
              metadataId: stuff.id,
              key: `file-${stuff.id}`,
              label: stuff.name || "File",
              extension: stuff.extension || "doc",
              onSelectKey: "file_id",
            },
          ];
        }
        case "link": {
          return [
            {
              id: stuff.mediaId,
              metadataId: stuff.id,
              key: `link-${stuff.id}`,
              label: stuff.name || stuff.urls?.url || "Link",
              extension: "lnk",
              onSelectKey: "link_id",
            },
          ];
        }
        default: {
          return [];
        }
      }
    });
  }, [recentContentDetails]);

  useEffect(() => {
    const selectedItemEntities = getEntitiesForSelectedItems(items);
    const typeGroups = {};

    for (const item of selectedItemEntities) {
      const type = item.entity;
      if (!typeGroups[type]) {
        typeGroups[type] = [];
      }
      typeGroups[type].push(item.value);
    }

    for (const type in typeGroups) {
      updateBatchRequestEntities([{ entity: type, value: typeGroups[type].join(","), source: "selection" }], setRequestEntities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const renderRecentItemTitles = useCallback(() => {
    const isChecked = (id: string) => {
      return !!items[id];
    };

    const onSelect = (id: string, type: ItemIdentifier["type"]) => () => {
      const item = { id, type };
      if (items[item.id]) {
        // Remove item from selection
        removeItem({ id });
      } else {
        // Add item to selections
        addItem(item);
      }
    };

    return recentsToRender.map((stuff) => (
      <Box maxWidth={minChildWidth} key={stuff.key}>
        <RecentItemTile
          key={stuff.key}
          hideCheckbox={hideCheckbox}
          label={stuff.label}
          extension={stuff.extension}
          metadataId={stuff.metadataId}
          isChecked={isChecked(stuff.id)}
          onSelect={onSelect(stuff.id, stuff.onSelectKey)}
        />
      </Box>
    ));
  }, [recentsToRender, items, removeItem, addItem, minChildWidth, hideCheckbox]);

  useDownloadRecentContents(maxRecentStuff);

  return (
    <>
      {totalContentsCount > 0 && showRecordCount && showAddButton && (
        <Box pb="2rem">
          <Button
            {...commonButtonProps}
            onClick={() => {
              setIsNewMenuAction("resource");
              onAddToCollectionModalOpen();
            }}>
            <AddIcon boxSize=".8rem" />
            <Text pl=".5rem">New Resource</Text>
          </Button>
        </Box>
      )}
      <Stack
        className="ch-latest-content-list"
        maxHeight={maxListHeight}
        overflowY="auto"
        overflowX="hidden"
        justifyContent="space-between"
        spacing={recentsToRender.length === 0 ? ".5rem" : "2rem"}>
        {totalContentsCount > 0 && (
          <SimpleGrid columns={maxColumns} minChildWidth={minChildWidth} spacing={"1rem"}>
            {renderRecentItemTitles()}
          </SimpleGrid>
        )}
        {maxRecentStuff > 0 && showRecordCount && (
          <Stack direction="row" spacing=".5rem" justifyContent={"flex-start"} width="100%">
            <Text lineHeight="2rem" fontSize="xs" color={nameColor} fontWeight="normal">{`Showing ${
              itemCount < recentContentDetails.length ? itemCount : recentContentDetails.length
            } of ${maxRecentStuff} Resources.`}</Text>
            {itemCount < maxRecentStuff && (
              <Button size="md" variant="link" cursor="pointer" onClick={() => getMoreItems()} color={buttonColor}>
                <Text lineHeight="2rem" fontSize="xs" color={buttonColor} fontWeight="700">
                  View more
                </Text>
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};
