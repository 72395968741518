import React from "react";
import { Box, Icon, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";
import { BsDashLg } from "react-icons/bs";
import type { Feature } from "hooks/useCopies";
import { useSubscriptionFeatures } from "hooks/useCopies";

interface SubscriptionInformationProps {
  scrollHeight?: string;
}

const FeatureRow: React.FC<{ feature: Feature }> = React.memo(({ feature }) => {
  const renderFeatureValue = (value: string) => {
    if (value === "true") return <Icon as={FaCheck} color="green.500" />;
    if (value === "false") return <Icon as={BsDashLg} color="gray.700" />;

    const parts = value.split(/total|days|per month/);
    if (parts.length > 1) {
      return (
        <>
          {parts.map((part, index) => (
            <Stack spacing="0" key={index}>
              <Text>{part}</Text>
              {index > 0 && (
                <Text lineHeight=".6rem" fontSize="xs" color="gray.500">
                  {value.includes("days") ? "days" : value.includes("total") ? "total" : "per month"}
                </Text>
              )}
            </Stack>
          ))}
        </>
      );
    }
    return value;
  };

  return (
    <Tr className="ch-subscription-feature" fontSize="sm">
      <Td paddingInlineStart={0}>{feature.name}</Td>
      <Td paddingInlineStart={0} textAlign="center" width="8rem">
        {renderFeatureValue(feature.trial)}
      </Td>
      <Td paddingInlineStart={0} textAlign="center" width="8rem">
        {renderFeatureValue(feature.professional)}
      </Td>
    </Tr>
  );
});

FeatureRow.displayName = "FeatureRow";

export const SubscriptionInformation: React.FC<SubscriptionInformationProps> = React.memo(({ scrollHeight = "calc(100vh - 14rem)" }) => {
  const featureList = useSubscriptionFeatures();

  return (
    <Box height="100%">
      <Stack
        color="gray.700"
        direction="row"
        width="100%"
        justifyContent="space-between"
        pr=".5rem"
        pb=".5rem"
        fontWeight="semibold"
        fontSize="md">
        <Text>Feature</Text>
        <Stack direction="row">
          <Text width="8rem" textAlign="center">
            Free
          </Text>
          <Text width="8rem" textAlign="center">
            Professional
          </Text>
        </Stack>
      </Stack>
      <Box>
        <Table variant="simple">
          <Tbody>
            {featureList.map((feature, index) => (
              <FeatureRow key={index} feature={feature} />
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
});

SubscriptionInformation.displayName = "SubscriptionInformation";
