import React, { useMemo } from "react";
import { Badge, Box, Button, Icon, Stack, useToast } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import type { SerializedError } from "@reduxjs/toolkit";
import { DynamicForm } from "screens/common/components";
import type { IField } from "screens/common/components";
import { useButtonProps, useOrganizations } from "hooks";
import { useReportTemplate } from "hooks/useReportTemplates";
import { EntitiesFieldArray } from "./EntitiesFieldArray";
import cloneDeep from "lodash/cloneDeep";
import { defaultOption } from "state/reportTemplates/reducer";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { FaRegCopy } from "react-icons/fa";

const stringifyEntitiesValues = (entities: { name: string; value: string }[]) => {
  const newEntities = cloneDeep(entities);

  return newEntities.map((entity) => ({
    value: entity.value !== null ? String(JSON.stringify(entity.value)) : "null",
    name: entity.name,
  }));
};

export function isError(payload: unknown): payload is SerializedError {
  return (payload as SerializedError).message !== undefined;
}

export interface FormValues {
  name: string;
  organization: string;
  entities: { name: string; value: string }[];
}

interface ModalProps {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
  initialValues?: FormValues;
}

interface IProps {
  id?: string;
  onSubmit: (values: FormValues) => void;
  initialValues?: FormValues;
}

const UpsertReportTemplate = ({ id, initialValues, onSubmit }: IProps) => {
  const reportTemplate = useReportTemplate(id);
  const commonButtonProps = useButtonProps("sm", "primary");
  const organizations = useOrganizations();
  const toast = useToast();

  const fields: IField<FormValues>[] = useMemo(() => {
    return [
      {
        type: "text",
        label: "Report template name",
        entity: "name",
        defaultValue: "",
      },
      {
        type: "select",
        label: "Organization",
        entity: "organization",
        defaultValue: "",
        options: [defaultOption, ...organizations.map((organization) => ({ value: organization.id, label: organization.name }))],
      },
      {
        type: "field-array",
        label: "Entities",
        entity: "entities",
        render: () => <EntitiesFieldArray />,
        defaultValue: [],
      },
    ];
  }, [organizations]);

  const defaultValues: FormValues = useMemo(() => {
    return !reportTemplate
      ? {
          name: initialValues?.name ?? "",
          organization: initialValues?.organization ?? defaultOption.value,
          entities: initialValues?.entities ? stringifyEntitiesValues(initialValues.entities) : [],
        }
      : {
          name: reportTemplate.name,
          organization: reportTemplate.organizationId,
          entities: stringifyEntitiesValues(reportTemplate.entities),
        };
  }, [reportTemplate, initialValues]);

  return (
    <Box pl=".5rem">
      {id ? (
        <Box mb={"1rem"}>
          <SectionHeader title={"ID"} />
          <Stack direction="row">
            <Badge>{id}</Badge>
            <Icon
              as={FaRegCopy}
              cursor="pointer"
              onClick={() => {
                navigator.clipboard.writeText(id);
                toast({
                  title: "ID copied to clipboard",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                });
              }}
            />
          </Stack>
        </Box>
      ) : null}
      <DynamicForm<FormValues>
        title={`${id ? "Update" : "Create"} report template`}
        fields={fields}
        defaultValues={defaultValues}
        formId="upsert-report-template-form"
        onSubmit={onSubmit}
      />
      <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
        <Button disabled={false} {...commonButtonProps} type="submit" form="upsert-report-template-form">
          Save Report Template
        </Button>
      </Box>
    </Box>
  );
};

export const UpsertReportTemplateModal = (props: ModalProps) => {
  const { id, isOpen, initialValues, onSubmit, onClose } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${id ? "Update" : "Create"} report template`}>
      <Wizard>
        <PanelStep>{isOpen && <UpsertReportTemplate id={id} onSubmit={onSubmit} initialValues={initialValues} />}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
