import {
  Text,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
  Portal,
  Stack,
  Box,
  useColorModeValue,
  DrawerFooter,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useEffect, useRef, useState } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useButtonProps } from "hooks";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";

interface Props {
  onClose: () => void;
  panelTitle?: string;
  panelMenuBar?: React.ReactNode;
  panelHeader?: React.ReactNode;
  panelFooter?: React.ReactNode;
  isOpen: boolean;
  className?: string;
  maxWidth?: string | string[];
}

export const PanelView: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  children,
  onClose,
  isOpen,
  panelTitle,
  panelMenuBar,
  panelHeader,
  panelFooter,
  className,
  maxWidth,
}) => {
  const secondaryTextColor = useColorModeValue("primary.darkGray", "gray.300");
  const backgroundColor = useColorModeValue("white!important", "#191f23");
  const { hideConversationButton, isConversationOpen, onConversationClose } = useConversationContext();
  const [childPanelCount, setChildPanelCount] = useState(0);
  const [hasParentPanel, setHasParentPanel] = useState(false);
  const commonButtonProps = useButtonProps("sm", "secondary");
  const mounted = useRef<boolean>(false);
  const drawerBodyRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(drawerBodyRef);

  useEffect(() => {
    // Close conversation panel if open and if the component is actually mounted
    if (isConversationOpen && mounted.current) {
      onConversationClose();
    }

    setChildPanelCount(document.querySelectorAll(".child-panel").length);
    setHasParentPanel(document.querySelectorAll(".parent-panel").length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Portal appendToParentPortal={false}>
      <Drawer onClose={onClose} isOpen={isOpen} trapFocus={false} blockScrollOnMount={false}>
        {!hasParentPanel && <DrawerOverlay />}
        <DrawerContent
          background={backgroundColor}
          className={`${hasParentPanel ? "child-panel" : "parent-panel"} ${className}`}
          maxWidth={
            maxWidth
              ? maxWidth
              : [
                  "100vw",
                  `${hasParentPanel ? `${70 - childPanelCount * 10}vw` : "80vw"}`,
                  `${hasParentPanel ? `${70 - childPanelCount * 10}vw` : "80vw"}`,
                  `${hasParentPanel ? `${55 - childPanelCount * 10}vw` : "65vw"}`,
                  `${hasParentPanel ? `${50 - childPanelCount * 10}vw` : "60vw"}`,
                ]
          }>
          <DrawerCloseButton {...commonButtonProps} backgroundColor="transparent" border="none" mt="8px" _active={{}} _hover={{}} />
          <DrawerHeader
            pb="1rem!important"
            pt={panelHeader ? "unset" : "1rem"}
            pl={panelHeader ? "unset" : "1rem"}
            pr={panelHeader ? "unset" : "2rem"}
            border="none">
            {panelHeader ? (
              panelHeader
            ) : (
              <Stack direction="row" justifyContent={"space-between"} spacing="1rem">
                <Text isTruncated fontWeight="700" fontSize={["md", "md", "lg"]} color={secondaryTextColor}>
                  {panelTitle}
                </Text>
                <Box pr="1rem">{panelMenuBar}</Box>
              </Stack>
            )}
          </DrawerHeader>
          <DrawerBody ref={drawerBodyRef} py="0!important" px="1rem!important" css={scrollbarStyle}>
            {children}
          </DrawerBody>
          {panelFooter && (
            <DrawerFooter pl="1rem" pr={hideConversationButton ? "1.5rem" : "4.5rem}"} width="100%" pb="1.5rem">
              {panelFooter}
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </Portal>
  );
};
