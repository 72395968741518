import { Box, Button, Icon, IconButton, SimpleGrid, Stack, Text, Tooltip, useColorModeValue, useToast } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useAppDispatch, useButtonProps, useEntitlements } from "hooks";
import { AiOutlineDelete } from "react-icons/ai";

import { downloadSystemPreferences, removeSystemPreference } from "state/systemPreference/operations";
import { useIsSystemPreferenceLoading, useSystemPreference, useSystemPreferences } from "hooks/useSystemPreferences";
import { PreferenceDetailModal } from "./modals/PreferenceDetailModal";
import { ValueDisplay } from "./components/ValueDisplay";
import { PreferenceUpsertModal } from "./modals/PreferenceUpsertModal";
import { ConfirmDeleteModal } from "screens/common/components/ConfirmDeleteModal";

const MODALS_KEYS = {
  DETAIL: "detail",
  UPSERT: "upsert",
  DELETE: "delete",
};

export const Preferences = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [isOpen, setIsOpen] = useState<string | undefined>();
  const [selectedCode, setSelectedCode] = useState<string | null>(null);

  const { manage_system_preferences_write: hasManagePreferencesWrite } = useEntitlements();
  const preferences = useSystemPreferences();
  const isLoading = useIsSystemPreferenceLoading();
  const selectedPreference = useSystemPreference(selectedCode);
  const onClose = useCallback(() => {
    setIsOpen(undefined);
    setSelectedCode(null);
    dispatch(downloadSystemPreferences());
  }, [dispatch]);

  const onDelete = useCallback(async () => {
    if (!selectedCode) {
      return;
    }

    await dispatch(removeSystemPreference(selectedCode));
    await dispatch(downloadSystemPreferences());

    toast({
      title: "Preference configuration",
      description: `Action completed`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  }, [selectedCode, dispatch, onClose, toast]);

  const onOpenUpsert = useCallback((code?: string) => {
    if (code) setSelectedCode(code);
    setIsOpen(MODALS_KEYS.UPSERT);
  }, []);

  // styles
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const commonButtonProps = useButtonProps("sm", "primary");
  const subTitleColor = useColorModeValue("charli.mediumGray", "gray.400");
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");

  const renderPreferences = useCallback(() => {
    return (preferences || []).map((preference) => {
      return (
        <Box
          fontSize={"sm"}
          key={preference.key}
          id={preference.key}
          cursor="pointer"
          backgroundColor={bgColor}
          borderRadius="md"
          overflow="hidden"
          position="relative"
          borderColor={borderColor}
          borderWidth="1px"
          boxShadow="none"
          onClick={(event: { stopPropagation: () => void }) => {
            event.stopPropagation();
            setSelectedCode(preference.key);
            setIsOpen(MODALS_KEYS.UPSERT);
          }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" backgroundColor={borderColor} p=".5rem">
            <Text width="100%" isTruncated color={subTitleColor} fontWeight="semibold">
              {preference.key}
            </Text>
            <Tooltip aria-label="" label={`Delete ${preference.key} Plan`} placement="top" hasArrow>
              <IconButton
                backgroundColor={"transparent"}
                height="unset"
                minWidth={"unset"}
                isDisabled={!hasManagePreferencesWrite}
                cursor="pointer"
                icon={<Icon as={AiOutlineDelete} boxSize="1rem" />}
                aria-label="Delete"
                onClick={async (event: { stopPropagation: () => void }) => {
                  event.stopPropagation();

                  setSelectedCode(preference.key);
                  setIsOpen(MODALS_KEYS.DELETE);
                }}
                _hover={{ backgroundColor: "unset" }}
              />
            </Tooltip>
          </Stack>
          <Stack p=".5rem">
            <Stack align="flex-start" justifyContent="space-between" width="100%">
              <Stack>
                <Text fontSize="xs" color={titleColor}>
                  Key
                </Text>
                <Text fontSize="sm" color={subTitleColor} fontWeight="semibold" mt="0!important">
                  {preference.key}
                </Text>
              </Stack>
              <Stack>
                <Text fontSize="xs" color={titleColor}>
                  Value
                </Text>
                <Text fontSize="sm" color={subTitleColor} fontWeight="semibold" mt="0!important">
                  <ValueDisplay preference={preference} />
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      );
    });
  }, [preferences, hasManagePreferencesWrite, bgColor, borderColor, subTitleColor, titleColor]);

  return (
    <>
      <Stack direction="row" justifyContent="end" pb="1rem" mt="0!important">
        <Button isDisabled={!hasManagePreferencesWrite} {...commonButtonProps} onClick={() => onOpenUpsert()}>
          Create System Preference
        </Button>
      </Stack>
      <SimpleGrid columns={[1, 2, 3, 4, 5]} spacingX="1rem" spacingY="1rem">
        {renderPreferences()}
      </SimpleGrid>

      {isOpen === MODALS_KEYS.DETAIL && selectedPreference && (
        <PreferenceDetailModal preference={selectedPreference} isOpen onClose={onClose} />
      )}
      {isOpen === MODALS_KEYS.UPSERT && (
        <PreferenceUpsertModal preference={selectedPreference} isOpen onClose={onClose} isLoading={isLoading} />
      )}
      {isOpen === MODALS_KEYS.DELETE && selectedCode && (
        <ConfirmDeleteModal
          isOpen
          onClose={onClose}
          onConfirm={onDelete}
          title="Confirm Delete"
          body={selectedCode}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
