import { createSlice } from "@reduxjs/toolkit";
import type { SubscriptionDetails } from "api/subscription/models/SubscriptionDetails";
import { downloadSubscriptionDetails, generateSecurityToken } from "./operations";

interface SubscriptionState {
  subscriptionDetails: SubscriptionDetails;
  securityToken: string;
  isLoading: boolean;
}

const initialState: SubscriptionState = {
  subscriptionDetails: {} as SubscriptionDetails,
  securityToken: "",
  isLoading: false,
};

export const { actions, reducer } = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadSubscriptionDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(downloadSubscriptionDetails.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(downloadSubscriptionDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscriptionDetails = action.payload;
    });
    builder.addCase(generateSecurityToken.fulfilled, (state, action) => {
      if (action.payload) {
        state.securityToken = action.payload;
      }
    });
  },
});

export default reducer;
