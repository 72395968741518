/* eslint-disable @typescript-eslint/no-empty-function */
import { useDisclosure } from "@chakra-ui/react";
import type { ReactNode } from "react";
import React, { createContext, useState, useContext, useCallback } from "react";
import type { RequestEntities } from "types/charliui";
import type { ConversationInputIds } from "./ConversationDialogInput";

const defaultThrowError = (): void => {
  throw new Error("Component must be nested inside <ConversationContextProvider />");
};

export const useConversationContext = () => {
  return useContext(ConversationContext);
};

export interface SelectedQuestionAndFocus {
  question?: string;
  focus?: string;
  answerLength?: string;
  projectTicker?: string;
  projectExchange?: string;
  projectId?: string;
}

export const ConversationContext = createContext({
  conversationId: "",
  setConversationId: (() => {
    defaultThrowError();
  }) as React.Dispatch<React.SetStateAction<string>>,
  contentId: "",
  setContentId: (text: string): void => {
    defaultThrowError();
  },
  collectionId: "",
  setCollectionId: (text: string): void => {
    defaultThrowError();
  },
  requestEntities: [] as RequestEntities,
  setRequestEntities: (() => {
    defaultThrowError();
  }) as React.Dispatch<React.SetStateAction<RequestEntities>>,
  hideButtonElements: false,
  setHideButtonElements: (value: boolean): void => {
    defaultThrowError();
  },
  showHelpPopups: false,
  setShowHelpPopups: (value: boolean): void => {
    defaultThrowError();
  },
  hideConversationButton: false,
  setHideConversationButton: (value: boolean): void => {
    defaultThrowError();
  },
  showCommands: false,
  setShowCommands: (() => {
    defaultThrowError();
  }) as React.Dispatch<React.SetStateAction<boolean>>,
  onConversationOpen: (conversationId?: string): void => {
    defaultThrowError();
  },
  isConversationOpen: false,
  onConversationClose: (): void => {
    defaultThrowError();
  },
  childPanelCount: 0,
  setChildPanelCount: (value: number): void => {
    defaultThrowError();
  },
  isConversationDialogExpanded: false,
  setIsConversationDialogExpanded: (value: boolean): void => {
    defaultThrowError();
  },
  willResetConversationDialog: false,
  setWillResetConversationDialog: (value: boolean): void => {
    defaultThrowError();
  },
  isUsingDefaultConversationDialog: false,
  setIsUsingDefaultConversationDialog: (value: boolean): void => {
    defaultThrowError();
  },
  isEditing: false,
  setIsEditing: (value: boolean): void => {
    defaultThrowError();
  },
  conversationDialogWidth: 0,
  setConversationDialogWidth: (value: number): void => {
    defaultThrowError();
  },
  isAnotherInputFocused: false,
  setIsAnotherInputFocused: (value: boolean): void => {
    defaultThrowError();
  },
  initialQuestionText: "",
  setInitialQuestionText: (value: string): void => {
    defaultThrowError();
  },
  initialQuestionFocus: undefined as undefined | 0 | 1,
  setInitialQuestionFocus: (value: undefined | 0 | 1): void => {
    defaultThrowError();
  },
  selectedQuestionAndFocus: {
    question: undefined,
    focus: undefined,
    answerLength: undefined,
    projectTicker: undefined,
    projectExchange: undefined,
    projectId: undefined,
  } as SelectedQuestionAndFocus,
  setSelectedQuestionAndFocus: (value: SelectedQuestionAndFocus): void => {
    defaultThrowError();
  },
  showSuggestions: false,
  setShowSuggestions: (value: boolean): void => {
    defaultThrowError();
  },
  isUpgradeModalOpen: true,
  onUpgradeModalOpen: (): void => {
    defaultThrowError();
  },
  onUpgradeModalClose: (): void => {
    defaultThrowError();
  },
  onSubscriptionModalOpen: (): void => {
    defaultThrowError();
  },
  onSubscriptionModalClose: (): void => {
    defaultThrowError();
  },
  isSubscriptionModalOpen: false,
  onFeedbackModalOpen: (): void => {
    defaultThrowError();
  },
  onFeedbackModalClose: (): void => {
    defaultThrowError();
  },
  isFeedbackModalOpen: false,
  feedbackValue: "",
  setFeedbackValue: (value: string): void => {
    defaultThrowError();
  },
  isUpgrading: false,
  setIsUpgrading: (value: boolean): void => {
    defaultThrowError();
  },
  isPortfolioModalOpen: false,
  onPortfolioModalOpen: (): void => {
    defaultThrowError();
  },
  onPortfolioModalClose: (): void => {
    defaultThrowError();
  },
  selectedProjectCategory: "" as string | undefined,
  setSelectedProjectCategory: (value: string | undefined): void => {
    defaultThrowError();
  },
  filteredProjectsIds: [] as string[] | undefined,
  setFilteredProjectsIds: (() => {
    defaultThrowError();
  }) as React.Dispatch<React.SetStateAction<string[] | undefined>>,
  isDeleteProjectModalOpen: false,
  onDeleteProjectModalOpen: (): void => {
    defaultThrowError();
  },
  onDeleteProjectModalClose: (): void => {
    defaultThrowError();
  },
  isDeleteProjectId: "" as string | undefined,
  setIsDeleteProjectId: (value: string | undefined): void => {
    defaultThrowError();
  },
  navigatePath: "" as string | undefined,
  setNavigatePath: (value: string | undefined): void => {
    defaultThrowError();
  },
  isOnboardingModalOpen: false,
  onOnboardingModalOpen: (): void => {
    defaultThrowError();
  },
  onOnboardingModalClose: (): void => {
    defaultThrowError();
  },
  focusedInputId: "" as ConversationInputIds,
  setFocusedInputId: (value: ConversationInputIds): void => {
    defaultThrowError();
  },
  setProjectDetailViewTab: (value: number | undefined): void => {
    defaultThrowError();
  },
  projectDetailViewTab: undefined as number | undefined,
  onAIUpdatedModalOpen: (): void => {
    defaultThrowError();
  },
  onAIUpdatedModalClose: (): void => {
    defaultThrowError();
  },
  isAIUpdatedModalOpen: false,
});

export const ConversationContextProvider = ({ children }: { children: ReactNode }) => {
  const {
    isOpen: isConversationOpen,
    onOpen: onConversationOpen,
    onClose: onConversationClose,
  } = useDisclosure({
    defaultIsOpen: navigator.userAgent.includes("HeadlessChrome"),
  });
  const [conversationId, setConversationId] = useState("");
  const [contentId, setContentId] = useState("");
  const [requestEntities, setRequestEntities] = useState<RequestEntities>([]);
  const [collectionId, setCollectionId] = useState("");
  const [hideButtonElements, setHideButtonElements] = useState(false);
  const [hideConversationButton, setHideConversationButton] = useState(false);
  const [showHelpPopups, setShowHelpPopups] = useState(false);
  const [showCommands, setShowCommands] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [childPanelCount, setChildPanelCount] = useState(0);
  const [isConversationDialogExpanded, setIsConversationDialogExpanded] = useState(false);
  const [conversationDialogWidth, setConversationDialogWidth] = useState(0);
  const [willResetConversationDialog, setWillResetConversationDialog] = useState(false);
  const [isUsingDefaultConversationDialog, setIsUsingDefaultConversationDialog] = useState(false);
  const [isAnotherInputFocused, setIsAnotherInputFocused] = useState(false);
  const [initialQuestionText, setInitialQuestionText] = useState("");
  const [initialQuestionFocus, setInitialQuestionFocus] = useState<0 | 1 | undefined>(undefined);
  const [selectedQuestionAndFocus, setSelectedQuestionAndFocusState] = useState<SelectedQuestionAndFocus>({
    question: undefined,
    focus: undefined,
    answerLength: undefined,
    projectTicker: undefined,
    projectExchange: undefined,
    projectId: undefined,
  });
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const { isOpen: isUpgradeModalOpen, onOpen: onUpgradeModalOpen, onClose: onUpgradeModalClose } = useDisclosure();
  const { isOpen: isPortfolioModalOpen, onOpen: onPortfolioModalOpen, onClose: onPortfolioModalClose } = useDisclosure();
  const [selectedProjectCategory, setSelectedProjectCategory] = useState<string | undefined>(undefined);
  const [filteredProjectsIds, setFilteredProjectsIds] = useState<string[] | undefined>(undefined);
  const { isOpen: isSubscriptionModalOpen, onOpen: onSubscriptionModalOpen, onClose: onSubscriptionModalClose } = useDisclosure();
  const [isUpgrading, setIsUpgrading] = useState(false);
  const { isOpen: isDeleteProjectModalOpen, onOpen: onDeleteProjectModalOpen, onClose: onDeleteProjectModalClose } = useDisclosure();
  const [isDeleteProjectId, setIsDeleteProjectId] = useState<string | undefined>(undefined);
  const [navigatePath, setNavigatePath] = useState<string | undefined>(undefined);
  const { isOpen: isOnboardingModalOpen, onOpen: onOnboardingModalOpen, onClose: onOnboardingModalClose } = useDisclosure();
  const [focusedInputId, setFocusedInputId] = useState<ConversationInputIds>("view-input");
  const [projectDetailViewTab, setProjectDetailViewTab] = useState<number | undefined>(0);
  const { isOpen: isFeedbackModalOpen, onOpen: onFeedbackModalOpen, onClose: onFeedbackModalClose } = useDisclosure();
  const [feedbackValue, setFeedbackValue] = useState("");
  const { isOpen: isAIUpdatedModalOpen, onOpen: onAIUpdatedModalOpen, onClose: onAIUpdatedModalClose } = useDisclosure();

  const onConversationOpenCB = useCallback(
    (conversationId?: string) => {
      if (conversationId) {
        setConversationId(conversationId);
      }

      onConversationOpen();
    },
    [onConversationOpen]
  );

  const setSelectedQuestionAndFocus = useCallback((value: SelectedQuestionAndFocus) => {
    setSelectedQuestionAndFocusState((prev) => ({
      ...prev,
      ...value,
    }));
  }, []);

  return (
    <ConversationContext.Provider
      value={{
        isAIUpdatedModalOpen,
        onAIUpdatedModalOpen,
        onAIUpdatedModalClose,
        focusedInputId,
        setFocusedInputId,
        isOnboardingModalOpen,
        onOnboardingModalOpen,
        onOnboardingModalClose,
        navigatePath,
        setNavigatePath,
        isDeleteProjectModalOpen,
        onDeleteProjectModalOpen,
        onDeleteProjectModalClose,
        isDeleteProjectId,
        setIsDeleteProjectId,
        isSubscriptionModalOpen,
        onSubscriptionModalOpen,
        onSubscriptionModalClose,
        isUpgrading,
        setIsUpgrading,
        filteredProjectsIds,
        setFilteredProjectsIds,
        initialQuestionText,
        setInitialQuestionText,
        selectedQuestionAndFocus,
        setSelectedQuestionAndFocus,
        conversationId,
        setConversationId,
        contentId,
        setContentId,
        requestEntities,
        setRequestEntities,
        collectionId,
        setCollectionId,
        hideButtonElements,
        setHideButtonElements,
        hideConversationButton,
        setHideConversationButton,
        showHelpPopups,
        setShowHelpPopups,
        onConversationOpen: onConversationOpenCB,
        isConversationOpen,
        onConversationClose,
        showCommands,
        setShowCommands,
        childPanelCount,
        setChildPanelCount,
        isConversationDialogExpanded,
        setIsConversationDialogExpanded,
        isEditing,
        setIsEditing,
        conversationDialogWidth,
        setConversationDialogWidth,
        willResetConversationDialog,
        setWillResetConversationDialog,
        isUsingDefaultConversationDialog,
        setIsUsingDefaultConversationDialog,
        isAnotherInputFocused,
        setIsAnotherInputFocused,
        showSuggestions,
        setShowSuggestions,
        isUpgradeModalOpen,
        onUpgradeModalOpen,
        onUpgradeModalClose,
        isPortfolioModalOpen,
        onPortfolioModalOpen,
        onPortfolioModalClose,
        selectedProjectCategory,
        setSelectedProjectCategory,
        projectDetailViewTab,
        setProjectDetailViewTab,
        isFeedbackModalOpen,
        onFeedbackModalOpen,
        onFeedbackModalClose,
        feedbackValue,
        setFeedbackValue,
        initialQuestionFocus,
        setInitialQuestionFocus,
      }}>
      {children}
    </ConversationContext.Provider>
  );
};
