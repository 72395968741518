import React from "react";
import { Icon } from "@chakra-ui/react";

// PROJECT ICONS
export const ProjectGenericIcon = (props) => (
  <Icon viewBox="0 0 600 180" {...props}>
    <svg width="488" height="180" viewBox="0 0 488 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="253" y1="90.5" x2="368" y2="90.5" stroke="#AFBCD8" strokeWidth="13" />
      <path
        d="M487.22 90.11C487.22 127.726 456.726 158.22 419.11 158.22C381.494 158.22 351 127.726 351 90.11C351 52.4938 381.494 22 419.11 22C456.726 22 487.22 52.4938 487.22 90.11ZM411.232 126.174L461.765 75.6405C463.481 73.9245 463.481 71.1422 461.765 69.4263L455.551 63.212C453.835 61.4958 451.052 61.4958 449.336 63.212L408.125 104.424L388.884 85.1827C387.168 83.4668 384.385 83.4668 382.669 85.1827L376.455 91.397C374.739 93.1129 374.739 95.8952 376.455 97.6112L405.017 126.173C406.733 127.89 409.516 127.89 411.232 126.174Z"
        fill="#4DA786"
      />
      <path
        d="M23.8068 159C23.8068 159 -17.6069 159 33.6333 159C84.8735 159 50.7569 119 89.5752 119C128.394 119 162 119 162 119"
        stroke="#AFBCD8"
        strokeWidth="12"
      />
      <line x1="25" y1="91" x2="162" y2="91" stroke="#AFBCD8" strokeWidth="12" />
      <path
        d="M23.8068 21C23.8068 21 -17.6069 21 33.6333 21C84.8735 21 50.7569 60 89.5752 60C128.394 60 162 60 162 60"
        stroke="#AFBCD8"
        strokeWidth="12"
      />
      <circle cx="21" cy="21" r="21" fill="#F2CF82" />
      <circle cx="21" cy="159" r="21" fill="#F2CF82" />
      <circle cx="21" cy="90" r="21" fill="#F2CF82" />
      <path
        d="M296.112 111.629L280.653 102.702C282.213 94.2822 282.213 85.6452 280.653 77.2259L296.112 68.2985C297.89 67.2823 298.689 65.1775 298.108 63.2178C294.08 50.2985 287.221 38.6131 278.257 28.8873C276.878 27.3994 274.628 27.0365 272.887 28.0527L257.427 36.98C250.931 31.3913 243.455 27.0728 235.363 24.2422V6.4237C235.363 4.39145 233.947 2.61323 231.951 2.17775C218.633 -0.798044 204.988 -0.652883 192.322 2.17775C190.326 2.61323 188.911 4.39145 188.911 6.4237V24.2785C180.855 27.1454 173.379 31.4639 166.847 37.0163L151.423 28.0889C149.645 27.0728 147.432 27.3994 146.053 28.9236C137.089 38.6131 130.23 50.2985 126.202 63.2541C125.585 65.2138 126.42 67.3186 128.198 68.3348L143.657 77.2621C142.097 85.6815 142.097 94.3185 143.657 102.738L128.198 111.665C126.42 112.681 125.621 114.786 126.202 116.746C130.23 129.665 137.089 141.351 146.053 151.076C147.432 152.564 149.682 152.927 151.423 151.911L166.883 142.984C173.379 148.572 180.855 152.891 188.947 155.722V173.576C188.947 175.609 190.363 177.387 192.359 177.822C205.677 180.798 219.322 180.653 231.988 177.822C233.984 177.387 235.399 175.609 235.399 173.576V155.722C243.455 152.855 250.931 148.536 257.463 142.984L272.923 151.911C274.701 152.927 276.915 152.601 278.294 151.076C287.257 141.387 294.116 129.701 298.144 116.746C298.689 114.75 297.89 112.645 296.112 111.629ZM212.137 118.996C196.133 118.996 183.105 105.968 183.105 89.9637C183.105 73.9597 196.133 60.9316 212.137 60.9316C228.141 60.9316 241.169 73.9597 241.169 89.9637C241.169 105.968 228.141 118.996 212.137 118.996Z"
        fill="#626262"
      />
      <circle cx="211.5" cy="89.5" r="34.5" fill="#59B6FF" />
    </svg>
  </Icon>
);

export const SearchResultsIcon = (props) => (
  <Icon viewBox="0 0 324 288" {...props}>
    <svg width="324" height="288" viewBox="0 0 324 288" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.8068 159C23.8068 159 -17.6069 159 33.6333 159C84.8735 159 50.7569 119 89.5752 119C128.394 119 162 119 162 119"
        stroke="#AFBCD8"
        strokeWidth="12"
      />
      <line x1="25" y1="91" x2="162" y2="91" stroke="#AFBCD8" strokeWidth="12" />
      <path
        d="M23.8068 21C23.8068 21 -17.6069 21 33.6333 21C84.8735 21 50.7569 60 89.5752 60C128.394 60 162 60 162 60"
        stroke="#AFBCD8"
        strokeWidth="12"
      />
      <circle cx="21" cy="21" r="21" fill="#3F51B5" />
      <circle cx="21" cy="159" r="21" fill="#3F51B5" />
      <circle cx="21" cy="90" r="21" fill="#3F51B5" />
      <path
        d="M300.193 159C300.193 159 341.607 159 290.367 159C239.127 159 273.243 119 234.425 119C195.606 119 162 119 162 119"
        stroke="#AFBCD8"
        strokeWidth="12"
      />
      <line y1="-6" x2="137" y2="-6" transform="matrix(-1 0 0 1 299 97)" stroke="#AFBCD8" strokeWidth="12" />
      <path
        d="M300.193 21C300.193 21 341.607 21 290.367 21C239.127 21 273.243 60 234.425 60C195.606 60 162 60 162 60"
        stroke="#AFBCD8"
        strokeWidth="12"
      />
      <circle cx="21" cy="21" r="21" transform="matrix(-1 0 0 1 324 0)" fill="#3F51B5" />
      <circle cx="21" cy="21" r="21" transform="matrix(-1 0 0 1 324 138)" fill="#3F51B5" />
      <circle cx="21" cy="21" r="21" transform="matrix(-1 0 0 1 324 69)" fill="#3F51B5" />
      <rect x="147.361" y="172.528" width="28.5282" height="36.6791" fill="#3B3B3B" />
      <rect x="141.924" y="195.623" width="39.396" height="92.3771" fill="#3C3C3C" />
      <circle cx="162.301" cy="88.3015" r="88.3015" fill="#626262" />
      <ellipse cx="162.305" cy="88.3004" rx="67.9242" ry="67.9242" fill="#59B6FF" />
    </svg>
  </Icon>
);
