import type { FunctionComponent } from "react";
import { useEffect, useCallback, useMemo } from "react";
import React, { useState } from "react";
import type { TableContent } from "types/content/TableContent";
import type { CellChange, Column, Id, MenuOption, Row, TextCell, SelectionMode, CellLocation, DateCell } from "@silevis/reactgrid";
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import isEqual from "lodash/isEqual";
import type { Field } from "types/content/Field";

interface Props {
  tablesData: TableContent;
  isEditEnabled?: boolean;
  fieldBlackList?: string[];
  onEditCell?: (tableData: TableContent) => void;
}

export interface Data {
  [key: string]: string | number;
}

export const ReactGridTable: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  tablesData,
  isEditEnabled = false,
  fieldBlackList = ["stakeholder_key"],
  onEditCell,
}) => {
  const getColumns = useCallback((tablesData: TableContent): Column[] => {
    const tableColumns = tablesData.schema.fields
      .filter((row) => row.name !== "stakeholder_key")
      .map((column) => {
        return {
          columnId: column.name,
          width: 200,
          resizable: true,
        };
      });
    return tableColumns;
  }, []);
  const getTableData = useCallback((tablesData: TableContent, fieldBlackList: string[] = []) => {
    const data = tablesData.data
      .filter((row) => !fieldBlackList.includes(String(row.value)))
      .map((row) => {
        return {
          ...row,
        };
      });
    return data;
  }, []);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [columns, setColumns] = useState<Column[]>(getColumns(tablesData));
  const [rowData, setRowData] = useState<Data[]>(getTableData(tablesData));
  const [internalFieldBlackList, setInternalFieldBlackList] = useState<string[]>(fieldBlackList);
  const [internalTablesData, setInternalTablesData] = useState<TableContent>(tablesData);

  const rows = useMemo(() => {
    const getTableRowHeader = (): Row => {
      return {
        rowId: -1,
        cells: internalTablesData.schema.fields
          .filter((row) => !internalFieldBlackList.includes(String(row.name)))
          .map((column) => {
            return {
              type: "header",
              text: String(column.name),
            };
          }),
      };
    };

    const createCellFromRecord = (record: Data, column: Field): TextCell | DateCell => {
      const lowerCaseColumnName = column.name.toLowerCase();
      const recordValue = record[column.name] ? record[column.name].valueOf() : "";

      const dateTimeFormat = new Intl.DateTimeFormat("en-US", { month: "2-digit", day: "2-digit", year: "numeric" });

      const dateValue = new Date(recordValue);
      const isDateValueValidDate = !isNaN(dateValue.getTime());

      if (lowerCaseColumnName.includes("date") && isDateValueValidDate) {
        return {
          type: "date",
          date: dateValue,
          format: dateTimeFormat,
          nonEditable: !isEditEnabled,
        };
      } else {
        return {
          type: "text",
          text: String(recordValue),
          nonEditable: !isEditEnabled,
        };
      }
    };

    const getRows = (tableData: Data[]): Row[] => [
      getTableRowHeader(),
      ...tableData.map<Row>((record, idx) => ({
        rowId: idx,
        cells: internalTablesData.schema.fields
          .filter((row) => !internalFieldBlackList.includes(String(row.name)))
          .map((column) => createCellFromRecord(record, column)),
      })),
    ];

    return getRows(rowData);
  }, [isEditEnabled, rowData, internalFieldBlackList, internalTablesData]);

  const handleColumnResize = (ci: Id, width: number) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const handleContextMenu = (
    selectedRowIds: Id[],
    selectedColIds: Id[],
    selectionMode: SelectionMode,
    menuOptions: MenuOption[],
    selectedRanges: CellLocation[][]
  ): MenuOption[] => {
    if (selectionMode === "range") {
      menuOptions = [
        ...menuOptions,
        {
          id: "removeRecord",
          label: "Remove Row",
          handler: () => {
            setIsEditing(true);
            setRowData((prevTableData) => {
              const newTableData = prevTableData.filter((row, index) => {
                return !selectedRanges[0].some((cell) => {
                  return cell.rowId === index;
                });
              });
              return newTableData;
            });
          },
        },
      ];
    }
    return menuOptions;
  };

  const applyChangesToData = (changes: CellChange[], prevTableData: Data[]): Data[] => {
    const changesLookup: Record<number, CellChange> = {};
    changes.forEach((change) => {
      changesLookup[change.rowId] = change;
    });

    return prevTableData.map((row, index) => {
      const change = changesLookup[index];
      if (change) {
        return {
          ...row,
          [change.columnId]: (change.newCell as TextCell).text,
        };
      }
      return row;
    });
  };

  const handleChanges = (changes: CellChange[]) => {
    setIsEditing(true);
    setRowData((prevTableData) => applyChangesToData(changes, prevTableData));
  };

  useEffect(() => {
    setColumns(getColumns(internalTablesData));
    setRowData(getTableData(internalTablesData, internalFieldBlackList));
  }, [getColumns, getTableData, internalTablesData, internalFieldBlackList]);

  useEffect(() => {
    if (isEditing) {
      onEditCell?.({
        ...internalTablesData,
        data: rowData,
      });
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, internalTablesData]);

  useEffect(() => {
    if (!isEqual(tablesData, internalTablesData)) {
      setInternalTablesData(tablesData);
    }
  }, [tablesData, internalTablesData]);

  useEffect(() => {
    if (!isEqual(fieldBlackList, internalFieldBlackList)) {
      setInternalFieldBlackList(fieldBlackList);
    }
  }, [fieldBlackList, internalFieldBlackList]);

  return (
    <ReactGrid
      rows={rows}
      columns={columns}
      enableFillHandle={isEditEnabled}
      stickyTopRows={1}
      onColumnResized={handleColumnResize}
      onCellsChanged={isEditEnabled ? handleChanges : undefined}
      onContextMenu={isEditEnabled ? handleContextMenu : undefined}
      enableRangeSelection={isEditEnabled}
    />
  );
};
