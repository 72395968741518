import {
  Center,
  Box,
  Text,
  Circle,
  Stack,
  useColorModeValue,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { ChatBubble, MessageContainer, TypingIndicator } from "../components";
import { sortWorkflows } from "./utils";
import { useWorkflowsByIds } from "hooks/useWorkflows";
import parseISO from "date-fns/parseISO";
import React, { useMemo, useCallback } from "react";
import type { Message } from "types/conversation";
import { useWorkflowContext } from "screens/thread/WorkflowContextProvider";
import { ChevronRightIcon } from "@chakra-ui/icons";
import type { Workflow } from "types/workflows/workflow";

interface Props {
  message: Message;
  workflowsIds: string[];
}

export const WorkflowSummaryCell = ({ message, workflowsIds }: Props) => {
  const workflowSummary = useWorkflowsByIds(workflowsIds);
  const sortedWorkflows = useMemo(() => [...workflowSummary].sort(sortWorkflows), [workflowSummary]);
  const { setWorkflow, setMessageId, onWorkflowPanelOpen } = useWorkflowContext();
  const textColor = useColorModeValue("secondary.default", "gray300");

  const handleOnClick = (workflow: Workflow, messageId: string) => {
    setWorkflow(workflow);
    setMessageId(messageId);
    onWorkflowPanelOpen();
  };

  const renderSortedWorkflows = useCallback(() => {
    if (sortedWorkflows.length === 0) {
      return (
        <Text fontSize="sm" fontWeight="bold">
          There were no workflows executed in this conversation
        </Text>
      );
    }

    return (
      <>
        <Text fontSize="sm" fontWeight="semibold" pb=".7rem">
          Workflows for this conversation:
        </Text>
        <Box>
          {sortedWorkflows.length > 1 ? (
            <>
              <Accordion allowToggle>
                <AccordionItem>
                  <AccordionButton width="100%" pl="0!important">
                    <Text textAlign={"start"} isTruncated fontSize="sm" fontWeight="normal" width="14.6rem">
                      {sortedWorkflows.length - 1} Previous Workflows
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>{renderSortedWorkflowsItems(sortedWorkflows.slice(0, -1))}</AccordionPanel>
                </AccordionItem>
              </Accordion>
              {renderSortedWorkflowsItems(sortedWorkflows.slice(-1))}
            </>
          ) : (
            renderSortedWorkflowsItems(sortedWorkflows)
          )}
        </Box>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedWorkflows, message.id]);

  const renderSortedWorkflowsItems = useCallback(
    (workflows: Workflow[]) => {
      return (
        <>
          {workflows.map((workflow, index) => {
            if (workflow === undefined) {
              return <TypingIndicator color="transparent" key={`workflow-summary-circular-progress-${index}`} />;
            }
            return (
              <Stack
                className={`ch-workflow-summary-item`}
                key={`workflow-summary-item-${index}`}
                cursor="pointer"
                direction="row"
                onClick={() => handleOnClick(workflow, message.id)}
                py=".5rem">
                <Center>
                  {getStatusCircle(workflow.status)}{" "}
                  <Text isTruncated fontSize="sm" fontWeight="normal" pl=".5rem" width="14rem">
                    {workflow.title.length > 0 ? workflow.title : workflow.intent.split("_").join(" ")}
                  </Text>
                  <ChevronRightIcon color={textColor} boxSize="1.5rem" />
                </Center>
              </Stack>
            );
          })}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleOnClick, message.id]
  );

  const getStatusCircle = (status: string) => {
    switch (status) {
      case "succeeded":
      case "complete":
        return <Circle size=".7rem" backgroundColor="green.400" />;
      case "queued":
      case "in_progress":
        return <Circle size=".7rem" backgroundColor="orange.400" />;
      case "failed":
      case "error":
        return <Circle size=".7rem" backgroundColor="red.400" />;
      case "clarification_needed":
        return <Circle size=".7rem" backgroundColor="blue.400" />;
      default:
        return <Circle size=".7rem" backgroundColor="gray.400" />;
    }
  };

  return (
    <MessageContainer initiator="charli" key={`${message.id}-workflow-summary-message-container`}>
      <ChatBubble key={`${message.id}-workflow-summary-chat-bubble`} initiator="charli" date={parseISO(message.createdDate) ?? new Date()}>
        {renderSortedWorkflows()}
      </ChatBubble>
    </MessageContainer>
  );
};
