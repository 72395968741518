import React from "react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "../components/PanelStep";
import { PanelView } from "../components/PanelView";
import { Box } from "@chakra-ui/react";
import { CollectionDetailTags } from "screens/collection/components/CollectionDetailTags";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useProjectParams } from "hooks";

export const TagsPanel = () => {
  const { projectId } = useProjectParams();
  const { isTagsPanelOpen, onTagsPanelClose } = useAddToCharliContext();

  return (
    <PanelView isOpen={isTagsPanelOpen} onClose={onTagsPanelClose} panelTitle="Project Tags">
      <Wizard>
        <PanelStep>
          <Box mt="1rem">{projectId && <CollectionDetailTags collectionId={projectId} maxTagsToShow={100} />}</Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
