import React from "react";
import { Box, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { PanelView } from "screens/panels/components/PanelView";
import { ExternalIntegrations } from ".";
import { useExternalIntegrations } from "hooks";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

export const IntegrationsPanel: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const { availableIntegrations } = useExternalIntegrations();
  const { onIntegrationsPanelClose, isIntegrationsPanelOpen, isIntegrationCategory } = useAddToCharliContext();

  const gridColumns = useBreakpointValue([1, 1, 2, 3, 3]);
  return (
    <PanelView isOpen={isIntegrationsPanelOpen} onClose={onIntegrationsPanelClose} panelTitle="Integrations">
      <Box width="100%" height="100%" pt="1rem">
        <SimpleGrid columns={gridColumns} spacing="1.5rem">
          {availableIntegrations &&
            availableIntegrations
              .filter((integration) => integration.category === isIntegrationCategory)
              .map((integration, index) => {
                return <ExternalIntegrations key={`${integration.integrationId}-${index}`} integration={integration} />;
              })}
        </SimpleGrid>
      </Box>
    </PanelView>
  );
};
