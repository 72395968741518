import { Array } from "runtypes";
import { GetResourcesResponse } from "./models/api/GetResourcesResponse";
import { prependProxyBaseUrl, request } from "../request";
import { Resource } from "./models/Resource";
import { TargetEntity } from "./models/TargetEntity";
import type { UpsertResourceRequest } from "./models/api/CreateResourceRequest";
import type { UpsertTargetEntitiesRequest } from "./models/api/CreateTargetEntitiesRequest";
import type { Static } from "runtypes";
import type { UpdateResourceRequest } from "./models/api/UpdateResourceRequest";
import type { UpdateTargetEntityRequest } from "./models/api/UpdateTargetEntityRequest";
import { GetEventLogs } from "api/eventLogs/models/GetEventLog";

const TargetEntities = Array(TargetEntity);
type TargetEntities = Static<typeof TargetEntities>;

const INFORMATION_EXTRACTION_CONFIG_ENDPOINT = prependProxyBaseUrl("/api/information-extraction-config");

export const getResources = async (): Promise<GetResourcesResponse> => {
  const res = await request().url(`${INFORMATION_EXTRACTION_CONFIG_ENDPOINT}/resources`).get().json();

  const validate = GetResourcesResponse.validate(res);

  if (!validate.success) {
    console.log(validate.message);
    throw new Error(validate.message);
  }

  return validate.value;
};

export const upsertResource = async (resource: UpsertResourceRequest): Promise<Resource> => {
  const res = await request().url(`${INFORMATION_EXTRACTION_CONFIG_ENDPOINT}/resources`).post(resource).json();

  const validate = Resource.validate(res);

  if (!validate.success) {
    throw new Error(validate.message);
  }

  return validate.value;
};

export const updateResource = async (id: string, resource: UpdateResourceRequest): Promise<Resource> => {
  const res = await request().url(`${INFORMATION_EXTRACTION_CONFIG_ENDPOINT}/resources/${id}`).put(resource).json();

  const validate = Resource.validate(res);

  if (!validate.success) {
    throw new Error(validate.message);
  }

  return validate.value;
};

export const deleteResource = async (resourceId: string): Promise<void> => {
  await request().url(`${INFORMATION_EXTRACTION_CONFIG_ENDPOINT}/resources/${resourceId}`).delete().res();
};

export const createEntities = async (targetEntities: UpsertTargetEntitiesRequest): Promise<TargetEntities> => {
  const res = await request().url(`${INFORMATION_EXTRACTION_CONFIG_ENDPOINT}/entities`).post(targetEntities).json();

  const validate = TargetEntities.validate(res);

  if (!validate.success) {
    throw new Error(validate.message);
  }

  return validate.value;
};

export const updateEntity = async (targetEntityId: string, targetEntity: UpdateTargetEntityRequest): Promise<TargetEntity> => {
  const res = await request().url(`${INFORMATION_EXTRACTION_CONFIG_ENDPOINT}/entities/${targetEntityId}`).put(targetEntity).json();

  const validate = TargetEntity.validate(res);

  if (!validate.success) {
    throw new Error(validate.message);
  }

  return validate.value;
};

export const deleteEntity = async (targetEntityId: string): Promise<void> => {
  await request().url(`${INFORMATION_EXTRACTION_CONFIG_ENDPOINT}/entities/${targetEntityId}`).delete().res();
};

export const getResourceLogs = async (resourceId: string): Promise<GetEventLogs> => {
  const res = await request().url(`${INFORMATION_EXTRACTION_CONFIG_ENDPOINT}/resources/${resourceId}/logs`).get().json();

  const validate = GetEventLogs.validate(res);

  if (!validate.success) {
    throw new Error(validate.message);
  } else {
    return validate.value;
  }
};
