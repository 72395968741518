import { Box, Stack, useColorModeValue, Text, Tooltip, Tag } from "@chakra-ui/react";
import {
  useCollectionHasHighlights,
  useCollectionKey,
  useCompanyStockFromCollectionContentsOrConversation,
  useCollectionMetadataIds,
  useConfigMap,
  useDownloadCollectionContents,
  useGetViewConfig,
  useLatestCollectionWorkflowId,
  useProjectStockEquityDataFromCollectionMetadata,
  useCollectionCreatedDate,
  useMainCollectionWorkflowId,
  useTileProps,
} from "hooks";
import type { FunctionComponent } from "react";
import React, { useMemo, useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Highlights } from "screens/panels/highlights/Highlights";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useWorkflowCompletionDateOrNow, useWorkflowKey } from "hooks/useWorkflows";
import { CollectionsFilterContext } from "screens/landing/tabs";
import { getViewConfig } from "configs/configMap";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { formatDistanceToNow } from "date-fns";
import { ProjectHeaderImage } from "./components/ProjectHeaderImage";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { WorkflowProgressClarification } from "screens/landing/components/WorkflowProgressClarification";
import { MarkdownItemView } from "screens/markdown/MarkdownItemView";
import Highlighter from "react-highlight-words";
import { StockEquityChart } from "screens/collection/components/StockEquityChart";
import { formatDateWithOutputFormat } from "screens/common/modal/formatters";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";

interface Props {
  collectionId: string;
  projectGroupCount?: number;
}

export const HomeDefaultTile: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
  projectGroupCount,
}) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const tileBorderColor = useColorModeValue("gray.300", "gray.700");
  const navigate = useNavigate();
  const extractedHighlights = useCollectionKey(collectionId, "extractedHighlights");
  const currentWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const mainWorkflowId = useMainCollectionWorkflowId(collectionId);
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  const configMap = useConfigMap();
  const { onConversationOpen } = useContext(ConversationContext);
  const { searchText } = useContext(CollectionsFilterContext);
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionQuestions = useCollectionKey(collectionId, "questions");
  const collectionName = useCollectionKey(collectionId, "name");
  const projectGroupId = useCollectionKey(collectionId, "projectGroupId");
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(collectionId);
  const filteredContentData = useCollectionMetadataIds(collectionId);
  const stockEquityData = useProjectStockEquityDataFromCollectionMetadata(collectionMetadataIds || []);
  const projectCreationDate = useCollectionCreatedDate(collectionId);
  const mainWorkflowStatus = useWorkflowKey(mainWorkflowId, "status");
  const currentWorkflowStatus = useWorkflowKey(currentWorkflowId, "status");

  const getPath = () => {
    const projectRoute = getViewConfig("route", collectionType || "", configMap);
    if (projectRoute) {
      return navigate(`/${projectRoute}/${collectionId}`);
    } else {
      onConversationOpen();
    }
  };

  const businessOverview = useMemo(() => {
    const relevantQuestions = [
      "what are the material changes",
      "company nature of business and overview",
      "in a concise one paragraph, describe the investment potential with the company",
    ];
    const question = collectionQuestions?.find(
      (q) => q.status !== "unpinned" && relevantQuestions.includes(q.question.toLowerCase()) && q.answers && q.answers.length > 0
    );

    return question?.answers[0].answer;
  }, [collectionQuestions]);

  const hasHighlights = useCollectionHasHighlights(collectionId);

  const projectTitle = useGetViewConfig("title", collectionType, configMap);

  const itemCount = collectionMetadataIds?.length ?? 0;

  useDownloadCollectionContents(collectionMetadataIds, collectionId);
  const commonTileProps = useTileProps();

  const tileRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(tileRef);
  const [showFadeout, setShowFadeout] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (tileRef.current) {
        setShowFadeout(tileRef.current.scrollHeight > tileRef.current.clientHeight);
      }
    };

    checkScroll();
    window.addEventListener("resize", checkScroll);
    return () => window.removeEventListener("resize", checkScroll);
  }, []);

  return (
    <Box
      {...commonTileProps}
      py="1rem"
      pr=".5rem"
      minHeight="13rem"
      onClick={() => {
        getPath();
      }}
      className={`ch-collections-tab-collection-tile simple-tile-layout ${hasHighlights ? "has-highlights" : ""}`}>
      {currentWorkflowId && (
        <Box position="absolute" right="5px" top="5px" bgColor={bgColor} borderRadius="full" boxSize={"1.4rem"}>
          <WorkflowProgressBar
            workflowId={currentWorkflowId}
            size="compact"
            lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
              addSuffix: true,
              includeSeconds: false,
            })}
            progressIndicatorStyle="circle"
            conversationId={collectionConversationId}
          />
        </Box>
      )}
      <Stack direction="row" height="100%" width="100%" spacing="1rem">
        <Stack width="8rem" justifyContent={"space-between"}>
          {collectionType && (
            <ProjectHeaderImage
              projectGroupId={projectGroupId}
              projectName={companyStockData?.name}
              metadataIds={filteredContentData}
              projectType={collectionType}
              collectionId={collectionId}
              numberOfThumbnails={4}
            />
          )}
          {projectGroupCount && projectGroupCount > 0 && (
            <Tag
              variant="subtle"
              position={"absolute"}
              top="2.7rem"
              borderColor={"gray.300"}
              borderWidth="1px"
              bgColor="white"
              fontSize="0.65rem"
              cursor={"pointer"}>
              {`${projectGroupCount} ${projectGroupCount === 1 ? "version" : "versions"}`}
            </Tag>
          )}
          {stockEquityData && (
            <Tooltip
              zIndex={2}
              aria-label="stock-equity-tooltip"
              label={`As of ${projectCreationDate && formatDateWithOutputFormat(new Date(projectCreationDate), "do MMM yyyy hh:mm")}`}>
              <Box zIndex={1}>
                <StockEquityChart height="8rem" hideAxis stockEquityData={stockEquityData} />
              </Box>
            </Tooltip>
          )}
        </Stack>
        <Stack alignContent={"top"} width="100%" spacing=".5rem">
          {collectionType !== "due_diligence" && collectionName && (
            <Box fontWeight="semibold">
              <TextOverflowTooltip label={collectionName} />
            </Box>
          )}
          {hasHighlights || (businessOverview && businessOverview.length > 0) ? (
            <Box
              alignContent={"top"}
              maxHeight={filteredContentData.length === 0 ? "8.5rem" : "11rem"}
              fontSize={"xs"}
              overflowY={"auto"}
              overflowX={"hidden"}
              ref={tileRef}
              pb="2rem"
              css={scrollbarStyle}>
              {businessOverview && businessOverview.length > 0 ? (
                <MarkdownItemView markdown={businessOverview} />
              ) : (
                <Highlights
                  textOnlyView
                  textTruncated
                  extractedHighlights={extractedHighlights}
                  showActionMenu={false}
                  maxHighlights={1}
                  searchPhrase={searchText}
                />
              )}
              {showFadeout && (
                <Box
                  position="absolute"
                  bottom="16px"
                  left="0"
                  right="0"
                  height="30px"
                  background="linear-gradient(to bottom, rgba(255,255,255,34%), rgba(255,255,255,1))"
                  pointerEvents="none"
                />
              )}
            </Box>
          ) : (
            <>
              {mainWorkflowStatus === "in_progress" && (
                <Text alignSelf="left" fontSize="xs" fontStyle={"italic"}>
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: "transparent",
                      fontWeight: "bold",
                    }}
                    searchWords={[`${companyStockData?.name} (${companyStockData?.ticker})` || ""]}
                    autoEscape
                    textToHighlight={`I am collecting the data and running a full analysis ${
                      companyStockData ? `on ${companyStockData.name} (${companyStockData.ticker})` : ""
                    }. I will send you an email with the summary report shortly.  Once complete you can ask detailed questions and dive deeper.`}
                  />
                </Text>
              )}
              {mainWorkflowStatus === "complete" && (
                <Text alignSelf="left" fontSize="xs">
                  {itemCount
                    ? `Your ${projectTitle?.replace("All", "")} project ${
                        companyStockData ? `on ${companyStockData.name} (${companyStockData.ticker})` : ""
                      } is complete. There ${itemCount === 1 ? "is" : "are"} ${itemCount} source content item${
                        itemCount === 1 ? "" : "s"
                      } in the project. Click here to access the project.`
                    : `Your ${projectTitle?.replace(
                        "All",
                        ""
                      )} project is complete. No resources were added but you can click here to access the project.`}
                </Text>
              )}
            </>
          )}
        </Stack>
      </Stack>
      {currentWorkflowId && currentWorkflowStatus === "in_progress" && collectionConversationId && (
        <Box
          zIndex={1}
          position={"absolute"}
          width="100%"
          left="0"
          bottom="0"
          borderColor={tileBorderColor}
          borderTopWidth="1px"
          p="10px"
          bgColor={bgColor}>
          <WorkflowProgressBar
            workflowId={currentWorkflowId}
            lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
              addSuffix: true,
              includeSeconds: false,
            })}
            size="compact"
            showInline={false}
            hideCompletedInDate
            conversationId={collectionConversationId}
          />
        </Box>
      )}
      {currentWorkflowId && currentWorkflowStatus !== "complete" && currentWorkflowStatus !== "in_progress" && (
        <WorkflowProgressClarification workflowId={currentWorkflowId} collectionId={collectionId} size="compact" />
      )}
    </Box>
  );
};
