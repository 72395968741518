import { Record, String } from "runtypes";
import type { Static } from "runtypes";

export const FinancialMarket = Record({
  price: String,
  trading_date: String,
  name: String,
  stock: String,
  percent_change: String,
  value_change: String,
  movement: String,
});

export type FinancialMarket = Static<typeof FinancialMarket>;
