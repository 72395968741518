import { Box, Stack, useColorMode } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Dashboard } from "@uppy/react";
import { useAddToCharliContext } from "./AddToCharliProvider";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import { useConfigForm, useConfigMap, useEntitlements, useGetViewConfig, useProjectParams } from "hooks";
import { AddTagDetails } from "./AddTagDetails";
import { ResearchContext } from "screens/panels/research/ResearchContext";

const MAX_FILE_SIZE = 62914560; // 30MB
const MAX_FILE_SIZE_WITH_ENTITLEMENT = 524288000; // 500MB

interface Props {
  height?: string;
  maxFiles?: number;
}

export const AddToCharliUploadForm = ({ height = "76vh", maxFiles }: Props) => {
  const { projectId } = useProjectParams();
  const { isNewMenuAction, uppy, isAddToCollectionModalOpen } = useAddToCharliContext();
  const { colorMode } = useColorMode();
  const { setRequestEntities } = useContext(ConversationContext);
  const { projectType } = useContext(ResearchContext);
  const { pathname } = useLocation();
  const isProject = pathname.includes("project");
  const route = pathname.split("/")[isProject ? 2 : 1];
  const { increased_max_upload_size: hasMaxUploadSizeEntitlement } = useEntitlements();
  const configMap = useConfigMap();
  const collectionType = useGetViewConfig("collectionType", route, configMap);
  const { projectForm } = useConfigForm(projectType || collectionType || "", configMap);
  const uploadStepTags = projectForm?.uploadStepTags;
  const [tagInputWidth, setTagInputWidth] = useState("100%");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 530) {
        setTagInputWidth("50%");
      } else {
        setTagInputWidth("100%");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    uppy.setOptions({
      restrictions: {
        maxNumberOfFiles: maxFiles,
        maxFileSize: hasMaxUploadSizeEntitlement ? MAX_FILE_SIZE_WITH_ENTITLEMENT : MAX_FILE_SIZE,
      },
    });
  }, [maxFiles, route, uppy, hasMaxUploadSizeEntitlement]);

  useEffect(() => {
    if (route === "valuation" && !isAddToCollectionModalOpen && !projectId) {
      const collectionName = `New valuation project started at ${new Date().toLocaleTimeString()}`;
      updateBatchRequestEntities([{ entity: "collection_name", value: collectionName, source: "collection-selector" }], setRequestEntities);
    } else if (isNewMenuAction !== "resource" && projectId) {
      updateBatchRequestEntities(
        [
          {
            entity: "collection_id",
            value: projectId,
            source: "collection-selector",
          },
        ],
        setRequestEntities
      );
    }
  }, [projectId, isAddToCollectionModalOpen, isNewMenuAction, route, setRequestEntities]);

  return (
    <Dropzone noDragEventsBubbling>
      {() => (
        <Stack spacing="2rem" height={"40vh"} width="100%">
          <Dashboard
            note={maxFiles ? `You can upload up to ${maxFiles} file${maxFiles && maxFiles > 1 ? "s" : ""} at a time` : ""}
            uppy={uppy}
            proudlyDisplayPoweredByUppy={false}
            height={"40vh"}
            width="100%"
            disableStatusBar
            showRemoveButtonAfterComplete
            showProgressDetails
            showLinkToFileUploadResult={false}
            theme={colorMode}
            locale={{ strings: { uploadComplete: "" } }}
          />
          {(uploadStepTags || uploadStepTags === "") && (
            <Box width={tagInputWidth}>
              <AddTagDetails defaultTags={uploadStepTags} />
            </Box>
          )}
        </Stack>
      )}
    </Dropzone>
  );
};
