import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDueDiligenceSuggestedQuestions } from "api/suggestions";

export const fetchSuggestedQuestionData = createAsyncThunk("suggested-questions-dd/download", async (_, thunkAPI) => {
  const suggestedQuestionsResponse = await fetchDueDiligenceSuggestedQuestions();

  if (suggestedQuestionsResponse?.status !== "success") {
    console.warn(` ${suggestedQuestionsResponse?.status} : Failed to fetch suggested questions: ${suggestedQuestionsResponse?.message}`);
    return false;
  } else {
    return suggestedQuestionsResponse.suggested_questions;
  }
});
