import type { FunctionComponent } from "react";
import React, { useMemo } from "react";
import { Tag, Box, Icon, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { AcknowledgmentStatus, MessageState } from "types/conversation";
import { formatDate } from "screens/common/modal/formatters";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FiCircle } from "react-icons/fi";

type Initiator = "user" | "charli";

interface Props {
  text?: string;
  date?: Date;
  state?: MessageState;
  initiator: Initiator;
  backgroundColor?: string;
  foregroundColor?: string;
  borderColor?: string;
  onClick?: () => void;
  className?: string;
  conversationId?: string;
  collectionId?: string;
  collectionName?: string;
  acknowledgmentStatus?: AcknowledgmentStatus;
}

const CharliMessageText = ({ text, state, foregroundColor }: Pick<Props, "text" | "state" | "foregroundColor">) => {
  // Replace markdown formatted links with <a/> tags
  const textWithLinks = text
    ? text.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" style="text-decoration: underline;" target="_blank">$1</a>')
    : "";

  return (
    <Text
      as="span"
      {...(state ? { "data-state": state, "aria-label": "Charli reply" } : { "aria-label": "Charli reply" })}
      fontSize="sm"
      fontWeight="normal"
      textAlign={"left"}
      color={foregroundColor}
      dangerouslySetInnerHTML={{ __html: textWithLinks }}
      wordBreak={"break-word"}
    />
  );
};

const CommandSymbol = ({ text = "", color }) => {
  return (
    <Tag bgColor={"#6B8AAC"} color="white" variant="solid" fontSize="sm">
      {text}
    </Tag>
  );
};

const EntitySymbol = ({ text = "", color }) => {
  return (
    <Tag bgColor="#5DACA0" color="white" variant="solid" fontSize="sm">
      {text}
    </Tag>
  );
};

export const UserMessageText = ({ text, state, foregroundColor }: Pick<Props, "text" | "state" | "foregroundColor">) => {
  const tokenStartsWithCommandSymbols = (text: string) => {
    return text.startsWith("/");
  };
  const tokenStartsWithEntitySymbols = (text: string) => {
    return text.startsWith(">");
  };

  const tokens = useMemo(() => {
    if (!text) return [];

    return text.split(" ").reduce((accum, token) => {
      if (tokenStartsWithCommandSymbols(token)) {
        if (accum.length !== 0 && !tokenStartsWithCommandSymbols(accum[accum.length - 1])) {
          accum[accum.length - 1] += ` `;
        }
        accum.push(token);
        accum.push(" ");
      } else if (tokenStartsWithEntitySymbols(token)) {
        if (accum.length !== 0 && !tokenStartsWithEntitySymbols(accum[accum.length - 1])) {
          accum[accum.length - 1] += ` `;
        }
        accum.push(token);
        accum.push(" ");
      } else {
        if (accum.length === 0 || tokenStartsWithCommandSymbols(accum[accum.length - 1])) {
          accum.push(token);
        } else {
          accum[accum.length - 1] += ` ${token}`;
        }
      }

      return accum;
    }, [] as string[]);
  }, [text]);

  return (
    <Box lineHeight="1.6rem" {...(state ? { "data-state": state, "aria-label": "User message" } : { "aria-label": "User message" })}>
      {tokens.map((token, i) => {
        if (tokenStartsWithCommandSymbols(token)) {
          return <CommandSymbol key={`${token}-${i}`} text={token} color={foregroundColor} />;
        } else if (tokenStartsWithEntitySymbols(token)) {
          return <EntitySymbol key={`${token}-${i}`} text={token} color={foregroundColor} />;
        } else {
          return (
            <Text key={`${token}-${i}`} as="span" fontSize="sm" fontWeight="normal" color={foregroundColor} wordBreak={"break-word"}>
              {token}
            </Text>
          );
        }
      })}
    </Box>
  );
};

export const ChatBubble: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = React.forwardRef(
  (
    {
      children,
      text,
      state,
      initiator,
      backgroundColor,
      foregroundColor,
      onClick,
      className,
      date,
      conversationId,
      collectionId,
      collectionName,
      acknowledgmentStatus,
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const charliBgColor = useColorModeValue("gray.100", "gray.700");
    const charliTextColor = useColorModeValue("gray.600", "gray.200");
    const userBgColor = useColorModeValue("#81c34c", "#1a3b48");
    const userTextColor = useColorModeValue("white", "gray.300");

    const messageBgColor = initiator === "user" ? backgroundColor || userBgColor : charliBgColor;
    const messageColor = initiator === "user" ? userTextColor : charliTextColor;

    return (
      <Box
        maxHeight={"30rem"}
        overflowY="auto"
        overflowX={"hidden"}
        ref={ref}
        px="1rem"
        py=".5rem"
        rounded="lg"
        backgroundColor={messageBgColor}
        color={messageColor}
        {...(state ? { "data-state": state } : {})}
        {...(onClick ? { "data-button": true } : {})}
        {...(conversationId ? { "data-conversation-id": conversationId } : {})}
        {...(collectionId ? { "data-collection-id": collectionId } : {})}
        {...(collectionName ? { "data-collection-name": collectionName } : {})}
        cursor={onClick ? "pointer" : "unset"}
        onClick={onClick}
        className={className}>
        <Stack direction="row" justifyContent={date ? "space-between" : "flex-end"}>
          {date && (
            <Text textAlign={initiator === "charli" ? "start" : "end"} fontSize="10px" pb="4px">
              {formatDate(date, "hh:mm a")}
            </Text>
          )}
          {acknowledgmentStatus === "acknowledged" && initiator === "user" && <Icon as={FaCheckCircle} boxSize=".7rem" />}
          {acknowledgmentStatus === "not_acknowledged" && initiator === "user" && <Icon as={FaTimesCircle} boxSize=".7rem" />}
          {acknowledgmentStatus === "will_not_retry" && initiator === "user" && <Icon as={FiCircle} boxSize=".7rem" />}
        </Stack>
        {text &&
          (initiator === "charli" ? (
            <CharliMessageText text={text} foregroundColor={messageColor} state={state} />
          ) : (
            <UserMessageText text={text} foregroundColor={messageColor} state={state} />
          ))}
        {children}
      </Box>
    );
  }
);
