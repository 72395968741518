import type { FunctionComponent } from "react";
import React, { useContext } from "react";
import { Stack, Input } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { ContentFilterContext } from "../body/ContentFilterContext";
import { ContentCanvasClearFilterButton } from "./ContentCanvasClearFilterButton";

interface Props {
  placeholderText?: string;
}

export const ContentCanvasFilterInput: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  placeholderText = "Filter results...",
}) => {
  const { searchText, setSearchText } = useContext(ContentFilterContext);
  const secondaryButtonProps = useButtonProps("sm", "secondary");

  return (
    <Stack direction="row" width="100%">
      <Input {...secondaryButtonProps} value={searchText} placeholder={placeholderText} onChange={(e) => setSearchText(e.target.value)} />
      <ContentCanvasClearFilterButton />
    </Stack>
  );
};
