import type { ColorProps } from "@chakra-ui/react";
import { Badge, Box, Input, Button, Stack, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import React, { useMemo, useCallback, useEffect, createContext, useContext, useRef, useState } from "react";
import { Select as FilterSelect } from "chakra-react-select";
import { UsersSelect } from "screens/common/components/UsersSelect";
import { useLocation, useNavigate } from "react-router-dom";
import { useDownloadUsers, useUsersMap } from "hooks/useUsers";
import { useButtonProps } from "hooks";
import { getRelevantIntents } from "api/workflows";
import sortBy from "lodash/sortBy";
import type { ChakraStylesConfig } from "chakra-react-select";
import { isValidUUID } from "screens/content/common/utils";

interface IProps {
  isLoading: boolean;
  hideExcludeUsersFilter?: boolean;
  hideIncludeUsersFilter?: boolean;
  hideStatusFilter?: boolean;
  hideIntentsFilter?: boolean;
  hideClearButton?: boolean;
  totalWorkflows?: number;
  filteredWorkflows?: number;
  refreshWorkflows?: () => void;
}

interface FilterOption {
  value: string;
  label: string;
}

export enum WorkflowQueryParamFilter {
  usersOut = "usersOut",
  usersIn = "usersIn",
  statusIn = "statusIn",
  intentsIn = "intentsIn",
  collectionId = "collectionId",
}

interface ColourOption {
  readonly value: string;
  readonly label: string;
}

const getOptionColor = (option: string): ColorProps["color"] => {
  switch (option) {
    case "queued":
      return "gray";
    case "complete":
      return "green";
    case "in_progress":
      return "blue";
    case "failed":
      return "red";
    case "clarification_needed":
      return "blue";
    case "paused":
      return "gray";
    case "error":
      return "red";
    case "intent_not_found":
      return "gray";
    case "no_suitable_task_found":
      return "gray";
    case "skipped_mandatory_clarification":
      return "gray";
    case "failed_checkstop":
      return "orange";
    default:
      return "gray";
  }
};

export const WORKFLOW_STATUS: ColourOption[] = [
  { value: "queued", label: "Queued" },
  { value: "complete", label: "Complete" },
  { value: "in_progress", label: "In Progress" },
  { value: "failed", label: "Failed" },
  { value: "clarification_needed", label: "Clarification Needed" },
  { value: "paused", label: "Paused" },
  { value: "error", label: "Error" },
  { value: "intent_not_found", label: "Intent not found" },
  { value: "no_suitable_task_found", label: "No suitable task found" },
  { value: "skipped_mandatory_clarification", label: "Skipped mandatory clarification" },
  { value: "failed_checkstop", label: "Failed checkstop" },
];

const styles: ChakraStylesConfig<ColourOption, true> = {
  option: (styles, { data }) => ({
    ...styles,
    color: `${getOptionColor(data.value)}`,
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: `${getOptionColor(data.value)}.100`,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: `${getOptionColor(data.value)}.700`,
  }),
};

const WorkflowsFiltersContext = createContext({
  usersOutIds: [] as string[],
  usersInIds: [] as string[],
  statusIn: [] as string[],
  intentsIn: [] as string[],
  collectionId: "",
  setUsersOutIds: (states: string[]) => {
    return;
  },
  setUsersInIds: (userIds: string[]) => {
    return;
  },
  setStatusIn: (states: string[]) => {
    return;
  },
  setIntentsIn: (intents: string[]) => {
    return;
  },
  setCollectionId: (collectionId: string) => {
    return;
  },
});

export const useWorkflowsFiltersContext = () => {
  return useContext(WorkflowsFiltersContext);
};

export const WorkflowsFiltersContextProvider = ({ children }) => {
  const location = useLocation();
  const mounted = useRef(false);
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const usersOutParam = searchParams.get(WorkflowQueryParamFilter.usersOut);
  const usersInParam = searchParams.get(WorkflowQueryParamFilter.usersIn);
  const statusInParam = searchParams.get(WorkflowQueryParamFilter.statusIn);
  const intentsInParam = searchParams.get(WorkflowQueryParamFilter.intentsIn);
  const collectionIdParam = searchParams.get(WorkflowQueryParamFilter.collectionId);

  const [usersOutIds, setUsersOutIds] = useState<string[]>(usersOutParam ? usersOutParam.split(",") : []);
  const [usersInIds, setUsersInIds] = useState<string[]>(usersInParam ? usersInParam.split(",") : []);
  const [statusIn, setStatusIn] = useState<string[]>(statusInParam ? statusInParam.split(",") : []);
  const [intentsIn, setIntentsIn] = useState<string[]>(intentsInParam ? intentsInParam.split(",") : []);
  const [collectionId, setCollectionId] = useState<string>(collectionIdParam ?? "");

  const mergedUsers = useMemo(() => {
    return Array.from(new Set([...(usersOutParam ? usersOutParam.split(",") : []), ...(usersInParam ? usersInParam.split(",") : [])]));
  }, [usersOutParam, usersInParam]);

  useDownloadUsers(mergedUsers);

  useEffect(() => {
    mounted.current = true;
    const usersOutParam = searchParams.get(WorkflowQueryParamFilter.usersOut);
    const usersInParam = searchParams.get(WorkflowQueryParamFilter.usersIn);
    const statusInParam = searchParams.get(WorkflowQueryParamFilter.statusIn);
    const intentsInParam = searchParams.get(WorkflowQueryParamFilter.intentsIn);
    const collectionIdParam = searchParams.get(WorkflowQueryParamFilter.collectionId);

    if (mounted.current) {
      setUsersOutIds(usersOutParam ? usersOutParam.split(",") : []);
      setUsersInIds(usersInParam ? usersInParam.split(",") : []);
      setStatusIn(statusInParam ? statusInParam.split(",") : []);
      setIntentsIn(intentsInParam ? intentsInParam.split(",") : []);
      setCollectionId(collectionIdParam ?? "");
    }

    return () => {
      mounted.current = false;
    };
  }, [searchParams]);

  return (
    <WorkflowsFiltersContext.Provider
      value={{
        usersOutIds,
        setUsersOutIds,
        usersInIds,
        setUsersInIds,
        statusIn,
        setStatusIn,
        intentsIn,
        setIntentsIn,
        collectionId,
        setCollectionId,
      }}>
      {children}
    </WorkflowsFiltersContext.Provider>
  );
};

const FilterWithBadge = ({ count, filterComponent }: { count: number; filterComponent: React.ReactElement }) => {
  return (
    <Box position="relative" width={"100%"} mb={2}>
      {count > 0 && (
        <Badge zIndex={2} top={"-6px"} right={"-6px"} colorScheme="blue" variant="solid" position={"absolute"}>
          {count}
        </Badge>
      )}
      {filterComponent}
    </Box>
  );
};

export const WorkflowsFiltersPanel = (props: IProps) => {
  const {
    isLoading,
    hideExcludeUsersFilter,
    hideIncludeUsersFilter,
    hideStatusFilter,
    hideIntentsFilter,
    hideClearButton,
    filteredWorkflows,
    totalWorkflows,
    refreshWorkflows,
  } = props;
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const navigate = useNavigate();
  const headingTextColor = useColorModeValue("gray.600", "gray.100");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const mounted = React.useRef(false);
  const toast = useToast();

  const { usersOutIds, usersInIds, statusIn, intentsIn } = useWorkflowsFiltersContext();
  const usersMap = useUsersMap();
  const [relevantIntents, setRelevantIntents] = React.useState<FilterOption[]>([]);
  const [collectionInput, setCollectionInput] = React.useState<string>("");

  const selectedUsersOut = useMemo(() => {
    return usersOutIds.flatMap((userId) => {
      const user = usersMap[userId];

      if (!user) {
        return [];
      } else {
        return [{ label: user.email, value: user.id }];
      }
    });
  }, [usersMap, usersOutIds]);

  const selectedUsersIn = useMemo(() => {
    return usersInIds.flatMap((userId) => {
      const user = usersMap[userId];

      if (!user) {
        return [];
      } else {
        return [{ label: user.email, value: user.id }];
      }
    });
  }, [usersMap, usersInIds]);

  const selectedStatus = useMemo(() => {
    return statusIn.flatMap((status) => {
      const statusOption = WORKFLOW_STATUS.find((option) => option.value === status);

      if (!statusOption) {
        return [];
      } else {
        return [{ label: statusOption.label, value: statusOption.value }];
      }
    });
  }, [statusIn]);

  const selectedIntents = useMemo(() => {
    return intentsIn.flatMap((intent) => {
      const intentOption = relevantIntents.find((option) => option.value === intent);

      if (!intentOption) {
        return [];
      } else {
        return [{ label: intentOption.label, value: intentOption.value }];
      }
    });
  }, [intentsIn, relevantIntents]);

  const handleFilter = (options: FilterOption[], type: WorkflowQueryParamFilter) => {
    searchParams.set(type, options.map((option) => option.value).join(","));
    navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  const handleCollectionInput = (collectionId: string) => {
    setCollectionInput(collectionId);
  };

  const clearFilters = useCallback(() => {
    navigate({ pathname: location.pathname, search: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mounted.current = true;
    (() => {
      getRelevantIntents()
        .then((intents) => {
          if (mounted.current) {
            setRelevantIntents(
              sortBy(
                intents.map(({ intent }) => ({ label: intent, value: intent })),
                "label"
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    })();

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const debounced = setTimeout(() => {
      if (collectionInput) {
        if (isValidUUID(collectionInput)) {
          searchParams.set(WorkflowQueryParamFilter.collectionId, collectionInput);
        } else {
          toast({
            title: "Collection id must be a valid UUID. Try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });

          return;
        }
      } else {
        searchParams.delete(WorkflowQueryParamFilter.collectionId);
      }

      navigate({ pathname: location.pathname, search: searchParams.toString() });
    }, 500);

    return () => {
      clearTimeout(debounced);
    };
  }, [collectionInput, location.pathname, navigate, searchParams, toast]);

  return (
    <Stack width="100%" justifyContent="space-between" pb=".5rem" spacing="1rem">
      {(!hideExcludeUsersFilter || !hideIncludeUsersFilter) && (
        <Stack direction="row" width="100%" spacing="1rem">
          {!hideExcludeUsersFilter && (
            <Stack width="100%">
              <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
                Exclude Users
              </Text>
              <FilterWithBadge
                count={selectedUsersOut.length}
                filterComponent={
                  <UsersSelect
                    isDisabled={isLoading}
                    selectedUsers={selectedUsersOut}
                    handleUsers={(users) => handleFilter(users, WorkflowQueryParamFilter.usersOut)}
                  />
                }
              />
            </Stack>
          )}
          {!hideIncludeUsersFilter && (
            <Stack width="100%">
              <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
                Include Users
              </Text>
              <FilterWithBadge
                count={selectedUsersIn.length}
                filterComponent={
                  <UsersSelect
                    isDisabled={isLoading}
                    selectedUsers={selectedUsersIn}
                    handleUsers={(users) => handleFilter(users, WorkflowQueryParamFilter.usersIn)}
                  />
                }
              />
            </Stack>
          )}
        </Stack>
      )}
      {(!hideIntentsFilter || !hideStatusFilter) && (
        <Stack direction="row" width="100%" spacing="1rem" justifyContent={"space-between"} alignContent="flex-start">
          {!hideIntentsFilter && (
            <Stack width="100%">
              <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
                Filter by intents
              </Text>
              <FilterWithBadge
                count={selectedIntents.length}
                filterComponent={
                  <FilterSelect
                    className="ch-multi-select"
                    useBasicStyles
                    size="sm"
                    selectedOptionStyle="check"
                    options={relevantIntents}
                    onChange={(newStatus) => {
                      handleFilter(
                        newStatus.map((status) => ({ ...status })),
                        WorkflowQueryParamFilter.intentsIn
                      );
                    }}
                    isMulti
                    value={selectedIntents}
                    isDisabled={isLoading}
                  />
                }
              />
            </Stack>
          )}
          {!hideStatusFilter && (
            <Stack width="100%">
              <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
                Filter by workflow status
              </Text>
              <FilterWithBadge
                count={selectedStatus.length}
                filterComponent={
                  <FilterSelect
                    className="ch-multi-select"
                    useBasicStyles
                    size="sm"
                    selectedOptionStyle="check"
                    options={WORKFLOW_STATUS}
                    onChange={(newStatus) => {
                      handleFilter(
                        newStatus.map((status) => ({ ...status })),
                        WorkflowQueryParamFilter.statusIn
                      );
                    }}
                    isMulti
                    value={selectedStatus}
                    isDisabled={isLoading}
                    chakraStyles={styles}
                  />
                }
              />
            </Stack>
          )}
        </Stack>
      )}

      <Stack width="50%">
        <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
          Collection Id
        </Text>
        <Input
          isDisabled={isLoading}
          value={collectionInput}
          onChange={(evt) => {
            handleCollectionInput(evt.target.value);
          }}
          size={"sm"}
          placeholder="Type collection id"
        />
      </Stack>

      <Stack direction="row" width="100%" spacing="1rem" justifyContent={refreshWorkflows ? "space-between" : "flex-end"}>
        {refreshWorkflows && (
          <Stack direction="row">
            <Button width="100%" {...secondaryButtonProps} isDisabled={isLoading} onClick={() => refreshWorkflows()}>
              Refresh Workflows
            </Button>
          </Stack>
        )}
        {filteredWorkflows && totalWorkflows && (
          <Stack direction="row" spacing="1rem">
            <Text fontSize="xs" color={headingTextColor} lineHeight="2rem">
              {`Filtering ${filteredWorkflows} of ${totalWorkflows} Workflows`}
            </Text>
            {!hideClearButton && (
              <Button {...secondaryButtonProps} isDisabled={isLoading} onClick={() => clearFilters()}>
                Clear Filters
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
