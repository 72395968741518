import { Stack, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useContext } from "react";
import { ResearchContext } from "screens/panels/research/ResearchContext";
import type { Research, SearchHistory } from "types/collection";
import { getEntityValue } from "../panels/research/utils";

interface Props {
  research?: Research; // TODO(CHARLI-2339): remove this when SearchHistory is fully ready (i.e., all projects have SearchHistory).
  searchHistory?: SearchHistory;
}

export const ResearchHistoryItemQuery: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  research,
  searchHistory,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { projectType } = useContext(ResearchContext);

  const extractFromSearchHistoryOrResearch = <T,>(name: string, orDefault: string): T => {
    return getEntityValue<T>(name, searchHistory?.entities) ?? searchHistory?.[name] ?? research?.[name] ?? orDefault;
  };

  return (
    <Stack {...(!isMobile && { direction: "row" })} justifyContent="space-between">
      {projectType === "gis" ? (
        <Stack direction="row">
          <Text fontSize="sm" color={textColor} fontWeight="semibold">
            Location:
          </Text>
          <Text fontSize="sm" color={textColor}>
            {extractFromSearchHistoryOrResearch<string>("location", "No Location Found")}
          </Text>
        </Stack>
      ) : (
        <>
          <Stack direction="row">
            <Text fontSize="sm" color={textColor} fontWeight="semibold">
              Query:
            </Text>
            <Text className="ch-search-history-query" fontSize="sm" color={textColor}>
              {extractFromSearchHistoryOrResearch<string>("query", "No Query Found")}
            </Text>
          </Stack>
          <Stack direction="row">
            <Stack direction="row">
              <Text fontSize="sm" color={textColor} fontWeight="semibold">
                Size:
              </Text>
              <Text fontSize="sm" color={textColor}>
                {extractFromSearchHistoryOrResearch<string>("size", "Not set")}
              </Text>
            </Stack>
            <Stack direction="row">
              <Text fontSize="sm" color={textColor} fontWeight="semibold">
                Region:
              </Text>
              <Text fontSize="sm" color={textColor}>
                {extractFromSearchHistoryOrResearch<string>("region", "Not set")}
              </Text>
            </Stack>
            <Stack direction="row">
              <Text fontSize="sm" color={textColor} fontWeight="semibold">
                Timeframe:
              </Text>
              <Text fontSize="sm" color={textColor}>
                {extractFromSearchHistoryOrResearch<string>("timeframe", "Not set")}
              </Text>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};
