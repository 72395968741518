import { Record, String, Array } from "runtypes";
import type { Static } from "runtypes";

export const RegisteredHandler = Record({
  id: String,
  name: String,
  intent: String,
  requirements: Array(String),
  integration: String,
  produces: Array(String),
  requires: Array(String).optional(),
  optionalRequires: Array(String).optional(),
  shouldIncludeEntities: Array(String).optional(),
  mustIncludeEntities: Array(String).optional(),
});

export type RegisteredHandler = Static<typeof RegisteredHandler>;
