import { useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { SupportedItemKeys } from "state/selection/reducer";
import { actions as selectionActions } from "state/selection/reducer";

export function useItemSelector() {
  const dispatch = useDispatch();
  const { items, availableItems, focusedItem, isSharingEnabled, selectedItems } = useSelector(
    (state: RootState) => state.selection,
    shallowEqual
  );

  const addItem = useCallback(
    ({ id, type, value }: { id: string; type: SupportedItemKeys; value?: string }) => {
      dispatch(selectionActions.addItem({ id, type, value }));
    },
    [dispatch]
  );

  const removeItem = useCallback(
    ({ id }: { id: string }) => {
      dispatch(selectionActions.removeItem({ id }));
    },
    [dispatch]
  );

  const setItems = useCallback(
    (items: { [id: string]: { type: SupportedItemKeys } }) => {
      dispatch(selectionActions.setItems(items));
    },
    [dispatch]
  );

  const deselectAll = useCallback(() => {
    dispatch(selectionActions.deselectAll());
  }, [dispatch]);

  const reselectAll = useCallback(() => {
    dispatch(selectionActions.setItems(availableItems));
  }, [dispatch, availableItems]);

  const resetItems = useCallback(() => {
    dispatch(selectionActions.resetItems());
  }, [dispatch]);

  const setFocusedItem = useCallback(
    ({ id, type, extension }: { id: string; type: SupportedItemKeys; extension?: string }) => {
      dispatch(selectionActions.setFocusedItem({ id, type, extension }));
    },
    [dispatch]
  );

  const clearFocusedItem = useCallback(() => {
    dispatch(selectionActions.clearFocusedItem());
  }, [dispatch]);

  const setSharingEnabled = useCallback(
    ({ state }: { state: boolean }) => {
      dispatch(selectionActions.setSharingEnabled({ state }));
    },
    [dispatch]
  );

  const setSelectedItems = useCallback(
    (items: { [id: string]: { type: SupportedItemKeys } }) => {
      dispatch(selectionActions.addAllToSelectedItems(items));
    },
    [dispatch]
  );

  const reselectAllSelected = useCallback(() => {
    dispatch(selectionActions.addAllToSelectedItems(availableItems));
  }, [dispatch, availableItems]);

  const addSelectedItem = useCallback(
    ({ id, type, value }: { id: string; type: SupportedItemKeys; value?: string }) => {
      dispatch(selectionActions.addSelectedItem({ id, type, value }));
    },
    [dispatch]
  );

  const removeSelectedItem = useCallback(
    ({ id }: { id: string }) => {
      dispatch(selectionActions.removeSelectedItem({ id }));
    },
    [dispatch]
  );

  const resetSelectedItems = useCallback(() => {
    dispatch(selectionActions.resetSelectedItems());
  }, [dispatch]);

  return {
    items,
    availableItems,
    focusedItem,
    isSharingEnabled,
    setSharingEnabled,
    addItem,
    removeItem,
    setItems,
    resetItems,
    deselectAll,
    reselectAll,
    setFocusedItem,
    clearFocusedItem,
    setSelectedItems,
    reselectAllSelected,
    addSelectedItem,
    removeSelectedItem,
    resetSelectedItems,
    selectedItems,
  };
}
