import { Record, String, Literal, Union, InstanceOf, Unknown, Dictionary } from "runtypes";
import type { Static } from "runtypes";

export const EventType = Union(Literal("update"), Literal("create"), Literal("delete"));

export const EventLog = Record({
  id: String,
  tableName: String,
  recordId: String,
  tableSchema: String,
  eventType: EventType,
  modifiedAt: Union(String, InstanceOf(Date)),
  modifiedByUserId: String,
  modifiedByUserIdName: String.optional(),
  eventObject: Dictionary(Unknown, String).nullable(),
  objectChanges: Dictionary(Record({ old: Unknown, new: Unknown }), String).nullable(),
});

export type EventType = Static<typeof EventType>;
export type EventLog = Static<typeof EventLog>;
