import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction, createSlice } from "@reduxjs/toolkit";
import {
  deleteUserPreference,
  fetchUserMarketingPreference,
  fetchUserPreference,
  fetchUserPreferencesByKeys,
  updateTypedUserPreference,
  updateUserPreference,
} from "./operations";
import type { RootState } from "state/rootReducer";
import { REHYDRATE } from "redux-persist";

const rehydrate = createAction<RootState>(REHYDRATE);

interface UserPreferenceState {
  isLoading: boolean;
  userPreferences: Record<string, string | boolean | number | string[]>;
  researchCountryCode?: string;
}

const initialState: UserPreferenceState = {
  isLoading: false,
  userPreferences: {},
};

export const { actions, reducer } = createSlice({
  name: "userPreference",
  initialState,
  reducers: {
    flush(state) {
      state.userPreferences = {};
    },
    setResearchCountryCode(state, action: PayloadAction<string>) {
      state.researchCountryCode = action.payload;
    },
    setMarketingPreference(state, action: PayloadAction<boolean>) {
      state.userPreferences.marketing_emails_opt_in = String(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (_, action) => {
      if (action.payload?.userPreference) {
        return { ...action.payload.userPreference };
      }
    });
    builder.addCase(fetchUserPreference.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserPreference.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userPreferences[action.meta.arg.preferenceKey] = action.payload;
    });
    builder.addCase(updateUserPreference.fulfilled, (state, action) => {
      state.userPreferences[action.meta.arg.preferenceKey] = action.payload;
    });
    builder.addCase(updateTypedUserPreference.fulfilled, (state, action) => {
      state.userPreferences[action.meta.arg.preferenceKey] = action.payload;
    });
    builder.addCase(fetchUserMarketingPreference.fulfilled, (state, action) => {
      state.userPreferences.marketing_emails_opt_in = action.payload;
    });
    builder.addCase(fetchUserPreferencesByKeys.fulfilled, (state, action) => {
      const payload = action.payload;

      if (!payload) {
        return;
      }

      payload.forEach((preference) => {
        state.userPreferences[preference.key] = preference.value;
      });
    });

    builder.addCase(deleteUserPreference.fulfilled, (state, action) => {
      delete state.userPreferences[action.meta.arg.preferenceKey];
    });
  },
});

export default reducer;
