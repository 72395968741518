import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";

export const useContextCoordinates = (answerId: string | undefined, metadataId: string | undefined) => {
  return useSelector((state: RootState) => {
    if (!answerId || !metadataId) {
      return undefined;
    }

    return state.contextCoordinates.coordinatesByAnswerId[answerId]?.find(
      (contextCoordinates) => contextCoordinates.metadataId === metadataId
    )?.contextCoordinates;
  }, shallowEqual);
};
