import { useToast } from "@chakra-ui/react";
import React, { useContext, useCallback } from "react";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { ConversationContext } from "screens/thread/ConversationContext";
import { ToastMessageContent } from "screens/common/components";
import { useCollectionKey, useConfigMap, useGetViewConfig, useUserPreference, useUserProfile } from "hooks";
import type { RequestEntities } from "types/charliui";

interface Props {
  collectionId: string;
  setIsRequesting?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedReportTemplate?: string;
  selectedEmails?: string;
  selectedOutputFormat?: string;
  selectedReportType?: string;
}

export const useRequestDefaultReport = ({
  collectionId,
  setIsRequesting,
  selectedReportTemplate,
  selectedOutputFormat,
  selectedEmails,
  selectedReportType,
}: Props) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { onConversationOpen, setConversationId } = useContext(ConversationContext);
  const { email } = useUserProfile();
  const userDefaultReportTemplate = useUserPreference("default_report_template") || "BasicReportTemplate.docx";
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const configMap = useConfigMap();
  const projectDefaultReport = useGetViewConfig("defaultReport", collectionType, configMap);
  const userDefaultReportTemplateType = useUserPreference("default_report_template_type");

  const requestDefaultReport = useCallback(() => {
    if (!collectionConversationId || !email) return;

    const entities: RequestEntities = [];
    entities.push({ entity: "collection_id", value: collectionId });
    entities.push({
      entity: "report_template_id",
      value: selectedReportTemplate || projectDefaultReport || String(userDefaultReportTemplate),
    });
    if (selectedEmails) {
      selectedEmails.split(",").forEach((email) => {
        entities.push({ entity: "email", value: email });
      });
    } else {
      entities.push({ entity: "email", value: email });
    }
    entities.push({
      entity: "outputFormat",
      value: selectedOutputFormat || (userDefaultReportTemplateType && String(userDefaultReportTemplateType)) || "PDF",
    });
    if (selectedReportType) {
      entities.push({ entity: "report_type", value: selectedReportType });
    }

    setIsRequesting && setIsRequesting(true);
    setTimeout(() => {
      setIsRequesting && setIsRequesting(false);
    }, 10000);

    dispatch(
      sendMessage({
        conversationId: collectionConversationId,
        intent: "/generate_collection_report",
        entities: entities,
      })
    );
    toast.closeAll();
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`I'll get onto creating that report now. When I'm done I will share it via email. You can open the conversation I started about it by clicking this message`}
          onClick={() => {
            setConversationId(collectionConversationId);
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  }, [
    collectionConversationId,
    email,
    collectionId,
    selectedReportTemplate,
    projectDefaultReport,
    userDefaultReportTemplate,
    selectedEmails,
    selectedOutputFormat,
    userDefaultReportTemplateType,
    selectedReportType,
    setIsRequesting,
    dispatch,
    toast,
    setConversationId,
    onConversationOpen,
  ]);

  return requestDefaultReport;
};
