import React, { useContext } from "react";
import { Button, Menu, MenuButton, MenuList, Stack, Box, useBreakpointValue } from "@chakra-ui/react";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import type { ContentDetails } from "types/content/ContentDetails";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";
import { useButtonProps } from "hooks";
import { SummarizeButtonMenuItem } from "screens/panels/summarize/SummarizeButtonMenuItem";
import { HighlightsButtonMenuItem } from "screens/panels/highlights/HighlightsButtonMenuItem";
import { ConversationContext } from "screens/thread/ConversationContext";
import { getMetatDataForContent } from "screens/content/common/utils";
import { request } from "api/request";
import { IntegrationURN } from "types/integration";

interface Props {
  itemContent?: ContentDetails;
  showActionMenu?: boolean;
  showAddToCollection?: boolean;
}

export const ContentHeader = ({ itemContent, showActionMenu = true, showAddToCollection = true }: Props) => {
  const { onNotesPanelOpen, onAddToCollectionModalOpen, setShowContentList, setShouldCreateNewCollection, setSelectedCollection } =
    useAddToCharliContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const buttonSize = isMobile ? "xs" : "sm";
  const commonButtonProps = useButtonProps(buttonSize, "primary");
  const { setRequestEntities } = useContext(ConversationContext);

  return (
    <Stack direction="row" id="ContentHeader">
      {showActionMenu && (
        <Box textAlign={"end"} width="100%" pt="1px">
          <Menu>
            <MenuButton
              {...commonButtonProps}
              className="ch-content-options"
              as={Button}
              aria-label="Options"
              rightIcon={<TriangleDownIcon />}
              onClick={(event) => event.stopPropagation()}>
              Actions
            </MenuButton>
            <MenuList minWidth={"unset"}>
              {itemContent?.urls?.downloadUrl && (
                <OptionsMenuItem
                  menuLabel={"Download Content"}
                  className="ch-content-download"
                  onClick={() => {
                    if (itemContent?.integrationUrn === IntegrationURN.charliCloudStorage && itemContent?.urls?.downloadUrl) {
                      request()
                        .url(itemContent?.urls?.downloadUrl)
                        .get()
                        .blob((blob) => blob && window.open(URL.createObjectURL(blob), "_blank"))
                        .catch((error) => console.error(error));
                    } else {
                      itemContent?.urls?.downloadUrl !== null && window.open(itemContent?.urls?.downloadUrl, "_blank");
                    }
                  }}
                />
              )}
              {/* <OptionsMenuItem menuLabel={"Share Content"} className="ch-share-btn" onClick={onShareModalOpen} /> */}
              <OptionsMenuItem menuLabel={"Add Notes"} className="ch-content-notes-panel" onClick={onNotesPanelOpen} />
              {showAddToCollection && (
                <OptionsMenuItem
                  menuLabel={"Add To Project"}
                  className="ch-add-to-collection-btn"
                  onClick={() => {
                    setSelectedCollection(undefined);
                    setShouldCreateNewCollection(false);
                    setShowContentList(false);
                    if (itemContent?.id) {
                      const metadata = getMetatDataForContent(itemContent);
                      updateBatchRequestEntities(
                        [
                          { entity: "metadata_id", value: itemContent?.id, source: "content-view" },
                          { entity: metadata.itemId.label, value: itemContent?.id, source: metadata.itemId.value },
                        ],
                        setRequestEntities
                      );
                      onAddToCollectionModalOpen();
                    }
                  }}
                />
              )}
              {itemContent && <SummarizeButtonMenuItem itemContent={itemContent} />}
              <HighlightsButtonMenuItem />
            </MenuList>
          </Menu>
        </Box>
      )}
    </Stack>
  );
};
