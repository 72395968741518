import { createSlice } from "@reduxjs/toolkit";
import { downloadWorkflowsStats } from "./operations";

interface WorkflowStatsState {
  isLoading: boolean;
  statsByWorkflowId: Record<
    string,
    {
      executionTime: number;
    }
  >;
}

const initialState: WorkflowStatsState = {
  isLoading: false,
  statsByWorkflowId: {},
};

export const { actions, reducer } = createSlice({
  name: "workflowStats",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadWorkflowsStats.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadWorkflowsStats.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadWorkflowsStats.fulfilled, (state, action) => {
      const response = action.payload;

      response.forEach((workflowState) => {
        state.statsByWorkflowId[workflowState.id] = {
          executionTime: workflowState.executionTime,
        };
      });
    });
  },
});

export default reducer;
