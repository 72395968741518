import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useEntitlements, useButtonProps, useAppDispatch } from "hooks";
import { useEntityConfigurations, useEntityLoading } from "hooks/useEntityConfigurations";
import type { ModalKey } from "types/modal";
import { isError, UpsertEntityConfigurationModal } from "./components/UpsertEntityConfiguration";
import type { FormValues } from "./components/UpsertEntityConfiguration";
import { deleteEntityConfigurationAction } from "state/entityConfigurations/operations";
import { createEntityConfigurationAction, updateEntityConfigurationAction } from "state/entityConfigurations/operations";
import type {
  CreateEntityConfigurationRequest,
  UpdateEntityConfigurationRequest,
} from "api/entityConfigurations/models/EntityConfigurationApi";
import type { TriggerChildWorkflow } from "api/entityConfigurations/models/TriggerChildWorkflow";
import type { RunTask } from "api/entityConfigurations/models/RunTask";
import type { EntityConfiguration } from "api/entityConfigurations/models/EntityConfiguration";
import { useFilterInput } from "hooks/useFilterInput";
import { EntityConfigurationDetailModal } from "./components/EntityConfigurationDetail";
import { useSortList } from "hooks/useSortList";
import { AdminTiles } from "screens/common/components";

export const EntityConfigurations = () => {
  const [isOpen, setIsOpen] = useState<ModalKey | undefined>();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const entityConfigurations = useEntityConfigurations();
  const {
    manage_entity_configurations_read: hasManageEntityConfigurationsRead,
    manage_entity_configurations_write: hasManageEntityConfigurationsWrite,
  } = useEntitlements();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();
  const commonButtonProps = useButtonProps("sm", "primary");
  const toast = useToast();
  const isEntityLoading = useEntityLoading();

  const { filteredList } = useFilterInput<EntityConfiguration>(["entityName"], entityConfigurations);
  const { sortedList } = useSortList<EntityConfiguration>(filteredList, ["entityName", "lastUpdatedByUserName", "lastUpdatedDate"]);

  const onOpenUpsert = useCallback((id?: string) => {
    if (id) {
      setSelectedId(id);
    }

    setIsOpen("upsert");
  }, []);

  const onOpenDelete = useCallback((id: any) => {
    setSelectedId(id);
    setIsOpen("delete");
  }, []);

  const onClose = useCallback(() => setIsOpen(undefined), []);

  const onDelete = useCallback(async () => {
    if (!selectedId) {
      return;
    }

    const deleteResponse = await dispatch(deleteEntityConfigurationAction(selectedId));

    if (isError(deleteResponse)) {
      toast({
        title: "Error",
        description: deleteResponse.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    setIsOpen(undefined);
  }, [selectedId, toast, dispatch]);

  const onSubmit = useCallback(
    async (fields: FormValues) => {
      const config: (TriggerChildWorkflow | RunTask)[] = fields.config.map((item) => ({
        intent: item.intent,
        ...(item.type === "run_task"
          ? { type: "run_task" }
          : {
              type: "trigger_child_workflow",
              entitiesToInclude: item.entities?.map((entity) => entity.value) || [],
            }),
      }));

      const response = await (() => {
        if (!selectedId) {
          const createPayload: CreateEntityConfigurationRequest = {
            entityName: fields.entityName,
            config,
          };

          return dispatch(createEntityConfigurationAction(createPayload));
        } else {
          const updatePayload: UpdateEntityConfigurationRequest = {
            id: selectedId,
            config,
          };

          return dispatch(updateEntityConfigurationAction({ entityName: fields.entityName, payload: updatePayload }));
        }
      })();

      if (isError(response)) {
        toast({
          title: "Error",
          description: "Something went wrong. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });

        return;
      }

      toast({
        title: "Success",
        description: `Entity configuration ${selectedId ? "updated" : "created"}.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    },
    [dispatch, onClose, selectedId, toast]
  );

  useEffect(() => {
    if (isOpen === undefined) {
      setTimeout(() => {
        setSelectedId(null);
      }, 500);
    }
  }, [isOpen]);

  return (
    <Box>
      <AdminTiles<EntityConfiguration>
        items={sortedList}
        fieldsToRender={[]}
        filterByFields={["entityName"]}
        defaultSortByKey="lastUpdatedDate"
        sortByFields={["entityName", "lastUpdatedByUserName", "lastUpdatedDate"]}
        inputFilterPlaceholder="Search by entity name or Last updated by name"
        onClickCreate={() => onOpenUpsert()}
        onClickDelete={onOpenDelete}
        onClickEdit={onOpenUpsert}
        hasWrite={hasManageEntityConfigurationsWrite}
        hasRead={hasManageEntityConfigurationsRead}
        tileTitle="Entity Configuration"
        tileTitleKey="entityName"
        keyName="id"
      />
      {isOpen === "detail" && <EntityConfigurationDetailModal id={selectedId} isOpen onClose={onClose} />}
      {isOpen === "upsert" && (
        <UpsertEntityConfigurationModal {...(selectedId && { id: selectedId })} isOpen onClose={onClose} onSubmit={onSubmit} />
      )}
      <AlertDialog isOpen={isOpen === "delete"} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="sm" fontWeight="bold">
              Delete Entity Configuration
            </AlertDialogHeader>

            <AlertDialogBody fontSize={"sm"}>Are you sure? You can't undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button disabled={isEntityLoading} {...commonButtonProps} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button disabled={isEntityLoading} {...commonButtonProps} onClick={onDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
