import type { Static } from "runtypes";
import { Dictionary } from "runtypes";
import { Record, String } from "runtypes";

export const DetailSectionDataAction = Record({
  label: String.nullable().optional(),
  context: String.nullable().optional(),
  intent: String.nullable().optional(),
  entities: Dictionary(String, String).optional(),
});

export type DetailSectionDataAction = Static<typeof DetailSectionDataAction>;
