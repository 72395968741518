import { captureException } from "@sentry/browser";
import type { ReactNode } from "react";
import React, { createContext, useEffect, useState } from "react";

export const GoogleApiProviderContext = createContext({
  gapi: undefined as typeof window.gapi | undefined,
});

const ATTEMPTS_WARN_LIMIT = 50;
const MAX_ATTEMPTS = 150;
const ATTEMPT_INTERVAL_IN_MS = 100;
const ATTEMPTS_WARN_LOG_INTERVAL = Math.min(ATTEMPTS_WARN_LIMIT, MAX_ATTEMPTS / 10);

/**
 * Provides an instance of the Google API to children using the useGoogleApi hook.
 * This provider assumes that the Google API script tag has been added to the page, and will continously test to see if the window.gapi object has been set.
 */
export const GoogleApiProvider = ({ children }: { children: ReactNode }) => {
  const [googleApi, setGoogleApi] = useState<typeof window.gapi | undefined>();
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    // If Google API is already set, immediately return.
    if (googleApi) return;

    // If the Google API is available, set and return.
    if (window.gapi) {
      setGoogleApi(window.gapi);
      return;
    }

    // If the Google API is unavailable, wait 100ms and try again.
    const timeout = setTimeout(() => {
      if (attempts >= ATTEMPTS_WARN_LIMIT && attempts < MAX_ATTEMPTS && attempts % ATTEMPTS_WARN_LOG_INTERVAL === 0) {
        // Complain every warn interval if Google API is still unset.
        console.warn(`Google API still unavailable after ${attempts} attempts.`);
      } else if (attempts >= MAX_ATTEMPTS) {
        // Give up once max attempts are reached.
        console.error(`Google API still unavailable after ${attempts} attempts, giving up.`);
        captureException(new Error(`Google API is unavailable after ${attempts} attempts.`));
        return;
      }

      // Use attempts counter as a useEffect trigger
      setAttempts(attempts + 1);
    }, ATTEMPT_INTERVAL_IN_MS);
    return () => {
      clearTimeout(timeout);
    };
  }, [attempts, googleApi]);

  return <GoogleApiProviderContext.Provider value={{ gapi: googleApi }}>{children}</GoogleApiProviderContext.Provider>;
};
