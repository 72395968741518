import type { JSONSchema7 } from "json-schema";

export const CostPerProjectSchema: JSONSchema7 = {
  $id: "http://json-schema.org/draft-07/schema#",
  type: ["object", "null"],
  description: "The cost of the project execution. Used in charts on project views.",
  properties: {
    humanResourceCost: {
      type: "number",
      description: "Cost in dollars to execute this project manually using human resources",
    },
    aiGeneratedCost: {
      type: "number",
      description: "Cost in dollars to generate this project using AI",
    },
  },
};
