import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { EntityConfiguration } from "api/entityConfigurations/models/EntityConfiguration";
import type { RootState } from "state/rootReducer";
import { downloadEntityConfigurations } from "state/entityConfigurations/operations";

export const useEntityConfigurations = (): EntityConfiguration[] => {
  const dispatch = useDispatch();
  const { entityConfigurationsByIds, entityConfigurationsIds } = useSelector((state: RootState) => state.entityConfigurations);

  useEffect(() => {
    dispatch(downloadEntityConfigurations());
  }, [dispatch]);

  return useMemo(() => {
    return entityConfigurationsIds.map((entityConfigurationId) => entityConfigurationsByIds[entityConfigurationId]);
  }, [entityConfigurationsByIds, entityConfigurationsIds]);
};

export const useEntityConfiguration = (id: string | undefined): EntityConfiguration | undefined => {
  const entityConfiguration = useSelector((state: RootState) =>
    id ? state.entityConfigurations.entityConfigurationsByIds[id] : undefined
  );

  return entityConfiguration;
};

export const useEntityLoading = () => {
  return useSelector((state: RootState) => state.entityConfigurations.isLoading);
};
