import React, { useEffect, useMemo, useCallback, useContext } from "react";
import { useToast } from "@chakra-ui/react";
import type { UseToastOptions } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { v4 as uuid } from "uuid";
import type { RootState } from "state/rootReducer";
import { getInviteStatus, InviteErrors } from "api/invite";
import type { InviteStatusResponse } from "api/invite";
import { useUserProfile } from "hooks";
import { captureException } from "@sentry/browser";
import { track } from "api/analytics";
import { ORGANIZATION_INVITE_ACCEPTED, SHARE_INVITE_ACCEPTED } from "api/analytics/events";
import { ConversationContext } from "screens/thread/ConversationContext";

const toastProps: UseToastOptions = {
  status: "error",
  duration: 15000,
  isClosable: true,
  position: "top",
};

export const ShareInviteCodeListener = () => {
  const { search } = useLocation();
  const { email } = useUserProfile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const { onConversationOpen, setConversationId } = useContext(ConversationContext);

  const acceptInviteCode = useCallback(
    async (
      code: string,
      eventName: string,
      processName: string,
      successCallback: (inviteResponse: InviteStatusResponse, conversationId: string) => void
    ) => {
      const inviteStatus = await getInviteStatus(code);

      if (inviteStatus.isOk) {
        // Has the invite code been redeemed?
        if (inviteStatus.value.hasBeenAccepted) {
          track(eventName, { status: "already_accepted" });

          toast({
            ...toastProps,
            title: "Unable to accept invite",
            description: `Invitation was already accepted.`,
          });

          // Remove the invite code from the query string and do nothing further.
          navigate(`/`, { replace: true });
        } else {
          track(eventName, { status: "success" });

          const conversationId = uuid();
          setConversationId(conversationId);
          successCallback(inviteStatus.value, conversationId);
        }
      } else {
        switch (inviteStatus.error.message) {
          case InviteErrors.forbidden:
            track(eventName, { status: "wrong_account" });

            toast({
              ...toastProps,
              title: "Unable to accept invite",
              description: `You are currently logged in as ${email}, but this ${processName} invitation was sent to a different e-mail address.`,
            });
            break;
          case InviteErrors.notFound:
            track(eventName, { status: "invite_not_found" });

            toast({
              ...toastProps,
              title: "Invalid invite code",
              description: "The supplied invite code doesn't exist. If you believe this is in error, please contact support@charli.ai.",
            });
            break;
          default:
            track(eventName, { status: "unknown_error" });

            captureException(inviteStatus.error);
        }

        // Remove invite code from query string
        navigate(`/`, { replace: true });
      }
    },
    [email, toast, setConversationId, navigate]
  );

  const shareInviteCode: string | undefined = useMemo(() => {
    const urlSearchParams = Object.fromEntries(new URLSearchParams(search)) || {};
    return (
      urlSearchParams.invite_code ??
      urlSearchParams.share_invite_code ??
      urlSearchParams.share_invite_id ??
      urlSearchParams.organization_invite_code
    );
  }, [search]);

  const organizationCodeInvite = useMemo(() => {
    const urlSearchParams = Object.fromEntries(new URLSearchParams(search)) || {};

    return urlSearchParams.organization_invite_code;
  }, [search]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    if (organizationCodeInvite) {
      acceptInviteCode(organizationCodeInvite, ORGANIZATION_INVITE_ACCEPTED, "organization", (inviteResponse, conversationId) => {
        dispatch(
          sendMessage({
            conversationId,
            message: `Accept to join this organization from ${inviteResponse.ownerEmails[0]}`,
            intent: "/accept_organization_invite",
            entities: [
              {
                entity: "organization_invite_code",
                value: organizationCodeInvite,
              },
            ],
          })
        );

        onConversationOpen();
      });
    } else if (shareInviteCode) {
      acceptInviteCode(shareInviteCode, SHARE_INVITE_ACCEPTED, "share", (inviteResponse, conversationId) => {
        dispatch(
          sendMessage({
            conversationId,
            message: `Accept this share from ${inviteResponse.ownerEmails[0]}`,
            intent: "/accept_share",
            entities: [
              {
                entity: "share_invite_id",
                value: shareInviteCode,
              },
            ],
          })
        );

        onConversationOpen();
      });
    }
  }, [shareInviteCode, organizationCodeInvite, isConnected, onConversationOpen, navigate, acceptInviteCode, dispatch, setConversationId]);

  return <React.Fragment />;
};
