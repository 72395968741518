import { Box, Center, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Image, Text } from "@chakra-ui/react";
import {
  useCollectionKey,
  useConfigForm,
  useConfigMap,
  useEntitlementKey,
  useGetViewConfig,
  useItemSelector,
  useProjectParams,
  useTabProps,
  useUserPreference,
} from "hooks";
import type { ReactNode } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import React, { useContext, useMemo, useState } from "react";
import { LatestContentList } from "screens/content/common/LatestContent/LatestContentList";
import { PanelView } from "screens/panels/components/PanelView";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { ConversationContext } from "screens/thread/ConversationContext";
import { AddToCharliUploadForm, AddToCharliBookmarkForm, AddTagNoteDetails } from "screens/panels/addToCharli/AddToCharliWizard";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useSendToCharli } from "screens/panels/addToCharli/AddToCharliWizard/useSendToCharli";
import panelIcon from "screens/common/static/misc/panel_add_source.png";
import { ResearchSearchInputs } from "screens/panels/research/ResearchSearchInputs";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { UpgradePlanButton } from "screens/panels/settings/tabs/subscription/SubscriptionUpgrade";

export const ProjectAddSource = () => {
  const { projectId } = useProjectParams();
  const { resetItems } = useItemSelector();
  const { setRequestEntities, projectDetailViewTab, setProjectDetailViewTab } = useContext(ConversationContext);
  const { resetFormState, shouldCreateNewCollection, isNewMenuAction, isProjectAddSourceOpen, onProjectAddSourceClose } =
    useAddToCharliContext();
  const { sendRequest, useCharliRequestLog } = useSendToCharli();
  const configMap = useConfigMap();
  const collectionType = useCollectionKey(projectId, "collectionType");
  const getViewConfigResult = useGetViewConfig("addSourceOptions", collectionType, configMap);
  const addSourceOptions = useMemo(() => {
    const defaultOptions = ["search", "files", "links"];
    if (getViewConfigResult) {
      return defaultOptions
        .filter((option) => getViewConfigResult.includes(option))
        .concat(getViewConfigResult.filter((option) => !defaultOptions.includes(option)));
    }
    return defaultOptions;
  }, [getViewConfigResult]);
  const [shouldDisableInputs, setShouldDisableInputs] = useState(false);
  const injectTestEntityPreference = useUserPreference("ui_inject_test_entity");
  const hasAddSourceContent = useEntitlementKey("ui_enable_add_source_content");

  useEffect(() => {
    setShouldDisableInputs(injectTestEntityPreference ? false : !hasAddSourceContent);
  }, [injectTestEntityPreference, hasAddSourceContent]);

  const convertAddSourceOptionToIntent = useCallback(
    (option: string) => {
      switch (option) {
        case "search":
          return "add_web_search_results_to_project";
        case "files":
          return "add_resources";
        case "links":
          return "add_link";
        case "content":
          return `${!isNewMenuAction && projectId ? "add_collection_content" : "store_collection"}`;
        default:
          return undefined;
      }
    },
    [isNewMenuAction, projectId]
  );

  useEffect(() => {
    const resourceType = addSourceOptions[projectDetailViewTab || 0];
    const intent = convertAddSourceOptionToIntent(resourceType);
    setSelectedTabIntent(intent);
  }, [addSourceOptions, convertAddSourceOptionToIntent, projectDetailViewTab]);

  const [selectedTabIntent, setSelectedTabIntent] = useState(() => {
    const resourceType = addSourceOptions[projectDetailViewTab || 0];
    return convertAddSourceOptionToIntent(resourceType);
  });

  const { projectForm } = useConfigForm(collectionType || "", configMap);
  const showUploadMaxFiles = projectForm?.showUploadMaxFiles;
  const { defaultTabProps } = useTabProps();

  const send = () => {
    selectedTabIntent &&
      sendRequest(
        selectedTabIntent,
        resetPanel,
        `${
          selectedTabIntent === "add_collection_content"
            ? "I'll send the selected resource to the project now. Click here if you want to open the project."
            : "I'll this resource to the project now. You can open the conversation I started about it by clicking this message"
        }`
      );
    resetPanel();
  };

  const resetPanel = () => {
    resetFormState();
    resetItems();
    setRequestEntities([]);
    setSelectedTabIntent(undefined);
    setProjectDetailViewTab(undefined);
    onProjectAddSourceClose();
  };

  useCharliRequestLog(isProjectAddSourceOpen);

  useEffect(() => {
    projectId &&
      updateBatchRequestEntities(
        [
          {
            entity: "collection_id",
            value: projectId,
            source: "collection-selector",
          },
        ],
        setRequestEntities
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectAddSourceOpen]);

  const handleTabChange = (index: number) => {
    setProjectDetailViewTab(index);
    setRequestEntities([]);
    projectId &&
      updateBatchRequestEntities(
        [
          {
            entity: "collection_id",
            value: projectId,
            source: "collection-selector",
          },
        ],
        setRequestEntities
      );
  };

  const panelHeader: ReactNode = useMemo(
    () => (
      <Stack spacing="0">
        <Box bgColor={"gray.500"} height="1rem" width="100%" />
        <Stack direction="row" justifyContent={"space-between"} backgroundColor={"gray.100"} px={"1.5rem"} py="1rem">
          <Stack width="100%">
            <Text fontSize={"md"} fontWeight="semibold">
              {`Add Source Content`}
            </Text>
            <Text fontSize={"sm"} fontWeight="normal">
              Upload new source content to the project for AI analysis, data extraction and follow-on Q&A. Source content can be files on
              your desktop, links or even discovered via web search.
            </Text>
          </Stack>
          <Center>
            <Image src={panelIcon} width={["2rem", "2.5rem", "3rem"]} ml="1.5rem" />
          </Center>
        </Stack>
        {shouldDisableInputs && (
          <UpgradePlanButton
            label={"Upgrade your plan to add sources to this project"}
            buttonSize="md"
            style={{ borderRadius: "none", minHeight: "2.5rem" }}
          />
        )}
      </Stack>
    ),
    [shouldDisableInputs]
  );

  return (
    <PanelView
      panelHeader={panelHeader}
      isOpen={isProjectAddSourceOpen}
      onClose={() => {
        resetPanel();
        onProjectAddSourceClose();
      }}
      panelTitle={"Add source content"}>
      <Wizard>
        <PanelStep
          onNextLabel={`${isNewMenuAction ? "Resource Details" : !shouldCreateNewCollection ? "Add to Project" : "Create Project"}`}
          onNextDisabled={shouldDisableInputs || !selectedTabIntent}
          onSubmit={isNewMenuAction ? undefined : send}>
          <Stack spacing="2rem">
            <Tabs isLazy onChange={handleTabChange} index={projectDetailViewTab} defaultIndex={0}>
              <TabList borderBottom={"none"}>
                {addSourceOptions?.includes("search") && (
                  <Tab
                    className="ch-project-add-research"
                    {...defaultTabProps}
                    onClick={() => setSelectedTabIntent("add_web_search_results_to_project")}>
                    Web Search
                  </Tab>
                )}
                {addSourceOptions?.includes("files") && (
                  <Tab
                    isDisabled={shouldDisableInputs}
                    className="ch-add-to-charli-option-upload"
                    {...defaultTabProps}
                    onClick={() => setSelectedTabIntent("add_resources")}>
                    Local Resource
                  </Tab>
                )}
                {addSourceOptions?.includes("links") && (
                  <Tab
                    isDisabled={shouldDisableInputs}
                    className="ch-add-to-charli-option-bookmark"
                    {...defaultTabProps}
                    onClick={() => setSelectedTabIntent("add_link")}>
                    Add a link
                  </Tab>
                )}
                {addSourceOptions?.includes("content") && (
                  <Tab
                    isDisabled={shouldDisableInputs}
                    className="ch-add-to-charli-option-existing"
                    {...defaultTabProps}
                    onClick={() =>
                      setSelectedTabIntent(`${!isNewMenuAction && projectId ? "add_collection_content" : "store_collection"}`)
                    }>
                    Add existing Resource
                  </Tab>
                )}
              </TabList>
              <TabPanels>
                {addSourceOptions?.includes("search") && (
                  <TabPanel px="1rem" pt="2rem">
                    <ResearchSearchInputs showResearchTopics={false} disableInputs={shouldDisableInputs} />
                  </TabPanel>
                )}
                {addSourceOptions?.includes("files") && (
                  <TabPanel px="0" pt="1rem" pb="5rem">
                    <Stack spacing="2rem">
                      <AddToCharliUploadForm maxFiles={showUploadMaxFiles} />
                      <AddTagNoteDetails />
                    </Stack>
                  </TabPanel>
                )}
                {addSourceOptions?.includes("links") && (
                  <TabPanel px="0">
                    <AddToCharliBookmarkForm />
                  </TabPanel>
                )}
                {(addSourceOptions?.includes("content") || isNewMenuAction !== "resource") && (
                  <TabPanel px="0">
                    <LatestContentList
                      maxListHeight={shouldCreateNewCollection ? "calc(100vh - 20rem)" : "calc(100vh - 16rem)"}
                      minChildWidth={130}
                    />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </Stack>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
