import { Box } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";

type Initiator = "user" | "charli";

interface Props {
  initiator: Initiator;
  minWidth?: string;
  className?: string;
}

export const MessageContainer: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  children,
  initiator,
  minWidth,
  className,
}) => {
  return (
    <Box
      {...(className && { className })}
      alignSelf={initiator === "charli" ? "flex-start" : "flex-end"}
      maxWidth="65%"
      minWidth={minWidth ? minWidth : "unset"}>
      {children}
    </Box>
  );
};
