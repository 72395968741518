import React, { useMemo } from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";

interface BuySellHoldChartProps {
  data?: {
    buy: number;
    sell: number;
    hold: number;
    outPerform: number;
    underPerform?: number;
  };
}

interface ChartData {
  name: string;
  value: number;
  color: string;
}

export enum BuySellChartColors {
  buy = "#A0D7FF",
  underPerform = "#FFA0A0",
  hold = "#FFE4A0",
  outPerform = "#A0E4E2",
  sell = "#FFB5C0",
}

export const BuySellHoldChart: React.FC<BuySellHoldChartProps> = ({
  data = { buy: 4, sell: 1, hold: 2, outPerform: 1, underPerform: 2 },
}) => {
  const chartData = useMemo<ChartData[]>(
    () => [
      { name: "Sell", value: data.sell, color: BuySellChartColors.sell },
      { name: "Under Perform", value: data.underPerform ?? 0, color: BuySellChartColors.underPerform },
      { name: "Hold", value: data.hold, color: BuySellChartColors.hold },
      { name: "Out Perform", value: data.outPerform, color: BuySellChartColors.outPerform },
      { name: "Buy", value: data.buy, color: BuySellChartColors.buy },
    ],
    [data]
  );
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });

  return (
    <Box height="19rem" width={isMobile ? "4/3" : "21rem"}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Legend wrapperStyle={{ fontSize: "10px" }} />
          <Pie
            isAnimationActive={false}
            data={chartData}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={100}
            paddingAngle={2}
            dataKey="value">
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};
