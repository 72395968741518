import { createSlice } from "@reduxjs/toolkit";
import { downloadFinancialMarketData } from "./operations";
import type { FinancialMarket } from "api/companyExchange/models/FinancialMarket";

interface State {
  financialMarket: FinancialMarket[];
}

const initialState: State = {
  financialMarket: [],
};

export const { reducer, actions } = createSlice({
  name: "financialMarket",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadFinancialMarketData.fulfilled, (state, action) => {
      state.financialMarket = action.payload;
    });
  },
});

export default reducer;
