import React, { useRef, useEffect, useState, useCallback } from "react";
import * as d3 from "d3";
import { Box, Portal, Text, Icon, Flex, CloseButton } from "@chakra-ui/react";
import { calculateTimeDifference } from "screens/collection/components/utils";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { outputFormatWithSeconds } from "./WorkflowSummary";
import { formatDate } from "screens/common/modal/formatters";
import { getStatusColor } from "screens/common/components/WorkflowStatusIcon";
import type { WorkflowTaskStatus } from "types/workflows/workflow";

export interface GanttTask {
  taskName: string;
  taskIntent?: string;
  taskStatus: WorkflowTaskStatus | "grouped";
  taskId: string;
  creationDate: Date;
  startDate: Date;
  endDate: Date;
  childWorkflowIntent: string;
  childWorkflowStatus: WorkflowTaskStatus;
  childWorkflowId: string;
}

export interface GanttChartProps {
  tasks: GanttTask[];
  width: number;
  taskHeight?: number;
  taskPadding?: number;
}

const margin = { top: 20, right: 20, bottom: 40, left: 200 };

const GanttChart: React.FC<GanttChartProps> = ({ tasks, width, taskHeight = 10, taskPadding = 0.1 }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [popoverPosition, setPopoverPosition] = useState<{ top: number; left: number; task: GanttTask } | null>(null);
  const [zoom, setZoom] = useState<number>(1.0);
  const [mappedTasks, setMappedTasks] = useState<Record<string, GanttTask>>({});
  const containerRef = useRef<HTMLDivElement | null>(null);
  const popoverRef = useRef<HTMLElement | null>(null);
  const popoverCallback = useCallback((ref: HTMLElement | null) => {
    if (!ref || !containerRef.current) {
      popoverRef.current = null;
      return;
    }

    popoverRef.current = ref;

    const containerWidth = containerRef.current.clientWidth;
    const containerHeight = containerRef.current.clientHeight;

    const poppoverWidth = ref.clientWidth;
    const poppoverHeight = ref.clientHeight;

    setPopoverPosition((prev) => {
      if (prev) {
        return {
          ...prev,
          top: prev.top + poppoverHeight > containerHeight ? prev.top - poppoverHeight + 25 : prev.top + 25 || 50,
          left: prev.left + poppoverWidth > containerWidth ? prev.left - poppoverWidth + 25 : prev.left + 25 || 50,
        };
      }

      return prev;
    });
  }, []);
  const selectedRectRef = useRef<SVGRectElement>();

  const handleZoomInOut = (zoomIn: boolean) => {
    if (zoomIn) {
      setZoom(zoom + 0.1);
    } else {
      if (zoom <= 0.5) return;
      setZoom(zoom - 0.1);
    }
  };

  // Calculate the height based on the number of tasks
  const height = tasks.length * (taskHeight + taskHeight * taskPadding);
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  useEffect(() => {
    const mappedTasks: Record<string, GanttTask> = {};
    tasks.forEach((task) => {
      mappedTasks[task.childWorkflowId] = task;
    });

    setMappedTasks(mappedTasks);
  }, [tasks]);

  useEffect(() => {
    if (!svgRef.current) return;

    // Set up SVG canvas
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous renders

    // Create scales
    const xScale = d3
      .scaleTime()
      .domain([d3.min(tasks, (d) => d.startDate)!, d3.max(tasks, (d) => d.endDate)!])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleBand()
      .domain(tasks.map((task) => task.childWorkflowId))
      .range([0, innerHeight])
      .padding(taskPadding);

    // Create axes with finer time granularity
    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat(d3.timeFormat("%H:%M:%S.%L") as unknown as (value: Date | { valueOf(): number }) => string);

    const yAxis = d3.axisLeft(yScale).tickFormat((id) => {
      const task = mappedTasks[id];
      return task ? `${task.childWorkflowIntent}${task.childWorkflowStatus !== "complete" ? ` (${task.childWorkflowStatus})` : ""}` : id;
    });

    // Append group for the chart
    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    // Draw bars
    g.selectAll(".bar")
      .data(tasks)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.startDate)!)
      .attr("y", (d) => yScale(d.childWorkflowId)!)
      .attr("width", (d) => xScale(d.endDate)! - xScale(d.startDate)!)
      .attr("height", yScale.bandwidth())
      .attr("fill", (task) => {
        return getStatusColor(task.taskStatus);
      })
      .on("mouseover", function (event, d) {
        if (!selectedRectRef.current) {
          setPopoverPosition({
            top: event.clientY,
            left: event.clientX,
            task: d,
          });

          d3.select(this).attr("fill", "orange");
        }
      })
      .on("click", function (_, d) {
        if (!selectedRectRef.current) {
          selectedRectRef.current = this;
        }
      })
      .on("mouseout", function (_, d) {
        if (!selectedRectRef.current) {
          setPopoverPosition(null);
          d3.select(this).attr("fill", getStatusColor(d.taskStatus));
        }
      });

    // Append axes
    g.append("g").attr("transform", `translate(0,${innerHeight})`).call(xAxis);

    g.append("g").call(yAxis);
  }, [tasks, mappedTasks, width, innerWidth, innerHeight, taskHeight, taskPadding]);

  return (
    <Box>
      <Box ref={containerRef} overflow={"auto"} width="100%" height={"calc(100vh - 150px)"}>
        <svg style={{ zoom }} ref={svgRef} width={width} height={innerHeight + 100} />
      </Box>
      <Flex justifyContent={"flex-end"} align={"center"} p="1rem">
        <Icon _hover={{ cursor: "pointer" }} onClick={() => handleZoomInOut(false)} as={FiZoomOut} />
        <Text ml="1rem" mr="1rem">
          {Math.round(zoom * 100)}%
        </Text>
        <Icon _hover={{ cursor: "pointer" }} onClick={() => handleZoomInOut(true)} as={FiZoomIn} />
      </Flex>
      {popoverPosition && (
        <Portal appendToParentPortal={false}>
          <Box
            ref={popoverCallback}
            boxShadow="md"
            p="6"
            rounded="md"
            bg="white"
            zIndex={10000}
            borderRadius={"5px"}
            position={"absolute"}
            top={popoverPosition.top}
            left={popoverPosition.left}>
            <Flex justifyContent={"flex-end"}>
              <CloseButton
                onClick={() => {
                  if (selectedRectRef.current) {
                    d3.select(selectedRectRef.current).attr("fill", getStatusColor(popoverPosition.task.taskStatus));
                  }

                  setPopoverPosition(null);
                  selectedRectRef.current = undefined;
                }}
              />
            </Flex>
            <Text fontSize={"xs"}>Task Name: {popoverPosition.task.taskName}</Text>
            <Text fontSize={"xs"}>Task Intent: {popoverPosition.task.taskIntent ?? "N/A"}</Text>
            <Text fontSize={"xs"}>Task Status: {popoverPosition.task.taskStatus}</Text>
            <Text fontSize={"xs"}>Duration: {calculateTimeDifference(popoverPosition.task.startDate, popoverPosition.task.endDate)}</Text>
            <Text fontSize={"xs"}>Task ID: {popoverPosition.task.taskId}</Text>
            <Text fontSize={"xs"}>Creation Date: {formatDate(new Date(popoverPosition.task.creationDate), outputFormatWithSeconds)}</Text>
            <Text fontSize={"xs"}>Start Date: {formatDate(new Date(popoverPosition.task.startDate), outputFormatWithSeconds)}</Text>
            <Text fontSize={"xs"}>End Date: {formatDate(new Date(popoverPosition.task.endDate), outputFormatWithSeconds)}</Text>
            <Text fontSize={"xs"}>Child Workflow Intent: {popoverPosition.task.childWorkflowIntent}</Text>
            <Text fontSize={"xs"}>Child Workflow ID: {popoverPosition.task.childWorkflowId}</Text>
          </Box>
        </Portal>
      )}
    </Box>
  );
};

export default GanttChart;
