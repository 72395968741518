import { prependProxyBaseUrl, request } from "api/request";
import { TaskResponse } from "api/tasks/models/TaskResponse";

const TASKS_ENDPOINT = prependProxyBaseUrl("/api/tasks");

export async function getTask(taskId: string) {
  const maybeTask = await request().url(`${TASKS_ENDPOINT}/${taskId}`).get().json();

  const validateTask = TaskResponse.validate(maybeTask);

  if (validateTask.success) {
    return validateTask.value;
  } else {
    console.error(`Invalid payload received when fetching task: `, validateTask);
    throw new Error(validateTask.message);
  }
}
