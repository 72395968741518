import type { FunctionComponent } from "react";
import React, { useContext, useEffect } from "react";
import { Stack, Button, Tooltip, Text, Box, useBreakpointValue } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { RiFilterLine } from "react-icons/ri";
import { ContentFilterContext } from "../body/ContentFilterContext";
import { ContentCanvasFilterInput } from "./ContentCanvasFilterInput";
import { FileTypeBadge } from "screens/common/components";

interface Props {
  onFilterToggle: () => void;
  onOpen: () => void;
  isOpen: boolean;
  onClose: () => void;
  isShowingResources?: boolean;
  placeholderText?: string;
}

export const ContentCanvasFilterMenu: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  onFilterToggle,
  onOpen,
  isOpen,
  onClose,
  isShowingResources,
  placeholderText = "Filter source content",
}) => {
  const { availableContent, selectedTags, setSelectedCategories, selectedCategories } = useContext(ContentFilterContext);
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const primaryButtonProps = useButtonProps("sm", "primary");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const hasProjects = availableContent.filter((content) => content.type.includes("collection")).length > 0;

  useEffect(() => {
    if (selectedTags.length > 0) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  return (
    <Stack direction="row" width="100%" justifyContent={"flex-end"}>
      {hasProjects && (
        <Tooltip label={`${selectedCategories.length > 0 ? "Clear Projects Filter" : "Filter Projects"}`}>
          <Button
            {...(selectedCategories.length > 0 ? { ...primaryButtonProps } : { ...secondaryButtonProps })}
            onClick={() => setSelectedCategories(selectedCategories.length > 0 ? [] : ["collection"])}>
            <FileTypeBadge width="1rem" height="1.3rem" hideExtensionLabel extension="col" />
          </Button>
        </Tooltip>
      )}
      <Box width={isMobile ? "100%" : "30rem"}>
        <ContentCanvasFilterInput placeholderText={placeholderText} />
      </Box>
      <Tooltip label={`${isOpen ? "Close Tag Filter" : "Open Tag Filter"}`}>
        <Button
          className="ch-content-filter-button"
          isDisabled={!isShowingResources}
          paddingInline=".3rem!important"
          {...(isOpen ? { ...primaryButtonProps } : { ...secondaryButtonProps })}
          onClick={onFilterToggle}
          aria-label="Filter"
          leftIcon={<RiFilterLine size=".9rem" />}>
          <Text>{`${Object.keys(availableContent).length > 0 ? Object.keys(availableContent).length : 0}`}</Text>
        </Button>
      </Tooltip>
    </Stack>
  );
};
