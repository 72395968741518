import { FormControl, Input, Select, Stack, useColorModeValue } from "@chakra-ui/react";
import { format } from "date-fns";
import type { FunctionComponent, SetStateAction } from "react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DatePickerWrapper } from "screens/common/components/DatePickerWrapper";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { IntegrationSelector } from "screens/landing/components/IntegrationSelector";
import { ConversationContext } from "screens/thread/ConversationContext";

export const SecSearchInputs: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const inputBgColor = useColorModeValue("white", "#191f23");
  const { requestEntities, setRequestEntities } = useContext(ConversationContext);
  const [companyName, setCompanyName] = useState(getRequestValue("company_name", requestEntities));
  const [companyCik, setCompanyCik] = useState(getRequestValue("company_cik", requestEntities));
  const [companyTicker, setCompanyTicker] = useState(getRequestValue("company_ticker", requestEntities));
  const maybeRequestValueDateFrom = getRequestValue("date_from", requestEntities);
  const [dateFrom, setDateFrom] = useState<Date | null>(maybeRequestValueDateFrom ? new Date(maybeRequestValueDateFrom) : null);
  const maybeRequestValueDateTo = getRequestValue("date_to", requestEntities);
  const [dateTo, setDateTo] = useState<Date | null>(maybeRequestValueDateTo ? new Date(maybeRequestValueDateTo) : null);
  const [filingFormType, setFilingFormType] = useState(getRequestValue("filing_form_type", requestEntities));
  const [frequencyPeriod, setFrequencyPeriod] = useState(getRequestValue("frequency_period", requestEntities));
  const currentDate = new Date();
  const [frequencyTime, setFrequencyTime] = useState<Date | null>(
    getRequestValue("frequency_time", requestEntities)
      ? new Date(`${format(currentDate, "yyyy-MM-dd")} ${getRequestValue("frequency_time", requestEntities)}`)
      : null
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    updateBatchRequestEntities([{ entity: "frequency_period", value: "once", source: "sec-inputs" }], setRequestEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateRequestEntity = (entityName: string, entityValue: string) => {
    updateBatchRequestEntities([{ entity: entityName, value: entityValue, source: "sec-inputs" }], setRequestEntities);
  };

  useEffect(() => {
    updateRequestEntity("collection_name", companyName as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  return (
    <Stack mt="0!important" spacing="1.5rem">
      <Stack direction="row" justifyContent="space-between" spacing="4rem">
        <FormControl>
          <SectionHeader title="Company Name" />
          <Input
            ref={inputRef}
            type="text"
            background={inputBgColor}
            id="sec-company-name"
            placeholder=""
            size="sm"
            rounded="10px"
            value={companyName}
            onChange={(e: { target: { value: SetStateAction<string> } }) => {
              setCompanyName(e.target.value);
              updateRequestEntity("company_name", e.target.value as string);
            }}
          />
        </FormControl>
        <FormControl>
          <SectionHeader title="Company CIK" />
          <Input
            type="text"
            background={inputBgColor}
            id="sec-company-cik"
            placeholder=""
            size="sm"
            rounded="10px"
            value={companyCik}
            onChange={(e: { target: { value: SetStateAction<string> } }) => {
              setCompanyCik(e.target.value);
              updateRequestEntity("company_cik", e.target.value as string);
            }}
          />
        </FormControl>
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing="4rem">
        <FormControl>
          <SectionHeader title="Company Ticker" />
          <Input
            type="text"
            background={inputBgColor}
            id="sec-company-ticker"
            placeholder=""
            size="sm"
            rounded="10px"
            value={companyTicker}
            onChange={(e: { target: { value: SetStateAction<string> } }) => {
              setCompanyTicker(e.target.value);
              updateRequestEntity("company_ticker", e.target.value as string);
            }}
          />
        </FormControl>
        <FormControl>
          <SectionHeader title="Filing Form Type" />
          <Select
            id="sec-filing-form-type"
            borderRadius="lg"
            background={inputBgColor}
            size="sm"
            value={filingFormType}
            onChange={(e: { target: { value: SetStateAction<string> } }) => {
              setFilingFormType(e.target.value);
              updateRequestEntity("filing_form_type", e.target.value as string);
            }}>
            <option value="">Not required</option>
            <option value="8-K">8-K</option>
            <option value="10-K">10-K</option>
            <option value="13-D">13-D</option>
            <option value="13-G">13-G</option>
            <option value="S-1">S-1</option>
            <option value="S-8">S-8</option>
            <option value="Form-4">Form-4</option>
            <option value="Form-8">Form-8</option>
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing="4rem">
        <FormControl>
          <SectionHeader title="From Date" />
          <Stack width="100%">
            <DatePickerWrapper
              id="sec-date-from"
              selected={dateFrom}
              onChange={(date: Date) => {
                setDateFrom(date);
                const formattedDate = date ? format(date, "yyyy-MM-dd") : "";
                updateRequestEntity("date_from", formattedDate);
              }}
              isClearable
              dateFormat="d MMMM yyyy"
              customInput={
                <Input width="100%" type="text" background={inputBgColor} id="sec-date-to" placeholder="" size="sm" rounded="10px" />
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="select"
            />
          </Stack>
        </FormControl>
        <FormControl>
          <SectionHeader title="To Date" />
          <Stack width="100%">
            <DatePickerWrapper
              id="sec-date-to"
              selected={dateTo}
              onChange={(date: Date) => {
                setDateTo(date);
                const formattedDate = date ? format(date, "yyyy-MM-dd") : "";
                updateRequestEntity("date_to", formattedDate);
              }}
              isClearable
              dateFormat="d MMMM yyyy"
              customInput={
                <Input width="100%" type="text" background={inputBgColor} id="sec-date-to" placeholder="" size="sm" rounded="10px" />
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="select"
            />
          </Stack>
        </FormControl>
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing="4rem">
        <FormControl>
          <SectionHeader title="Frequency Period" />
          <Select
            id="sec-frequency-period"
            background={inputBgColor}
            size="sm"
            value={frequencyPeriod}
            onChange={(e: { target: { value: SetStateAction<string> } }) => {
              setFrequencyPeriod(e.target.value);
              updateRequestEntity("frequency_period", e.target.value as string);
            }}>
            <option value="once">Once</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </Select>
        </FormControl>
        <FormControl>
          <SectionHeader title="Frequency Time" />
          <Stack width="100%">
            <DatePickerWrapper
              selected={frequencyTime}
              onChange={(date: Date) => {
                setFrequencyTime(date);
                const formattedDate = date ? format(date, "h:mm aa") : "";
                updateRequestEntity("frequency_time", formattedDate);
              }}
              isClearable
              customInput={
                <Input width="100%" type="text" background={inputBgColor} id="sec-frequency-time" placeholder="" size="sm" rounded="10px" />
              }
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mm aa"
              dropdownMode="select"
            />
          </Stack>
        </FormControl>
      </Stack>
      <FormControl>
        <SectionHeader title="Search Integration" />
        <IntegrationSelector
          category="Cloud Data"
          categoryKey="integrations_cloud_data_provider"
          integrationUrn="activityhandler:v2:external:charli.ai:sec"
        />
      </FormControl>
    </Stack>
  );
};
