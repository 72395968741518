import type { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import type { AuthToken, User } from "types/auth";
import type { SessionState } from "./reducer";

export const pendingReducer = (state: SessionState) => {
  state.isLoading = true;
  delete state.lastError;
};

export const successReducer = (
  state: SessionState,
  action: PayloadAction<
    {
      token: AuthToken;
      user: User;
    },
    string,
    unknown,
    never
  >
) => {
  state.token = action.payload.token;
  state.user = {
    avatarUrl: state.user?.avatarUrl,
    ...action.payload.user,
  };
  state.isLoading = false;
  state.isLoggedIn = true;
  state.isAuthorized = true;
  delete state.lastError;
};

export const errorReducer = (
  state: SessionState,
  action: PayloadAction<
    unknown,
    string,
    {
      arg: unknown;
      requestId: string;
      aborted: boolean;
      condition: boolean;
    },
    SerializedError
  >
) => {
  return { isLoggedIn: false, isAuthorized: false, isLoading: false, isProfileUpdatePending: false, lastError: action.error.message };
};
