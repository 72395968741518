import {
  Tag,
  Button,
  Icon,
  Stack,
  Tooltip,
  useToast,
  useColorModeValue,
  Box,
  SimpleGrid,
  Text,
  Center,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useCallback, useState, useMemo, useEffect, useContext } from "react";
import { useAppDispatch, useButtonProps, useEntitlements, useOrganizationIsLoading, useOrganizations } from "hooks";
import { AiOutlineDelete } from "react-icons/ai";
import { ConfirmDeleteModal } from "screens/common/components/ConfirmDeleteModal";
import { deleteOrganization } from "state/organization/operations";
import { formatDistanceToNow } from "date-fns";
import sortBy from "lodash/sortBy";
import type { ModalKey } from "types/modal";
import { useFilterInput } from "hooks/useFilterInput";
import type { Organization } from "types/organization/organization";
import { HiOutlineMail } from "react-icons/hi";
import { FaList } from "react-icons/fa";
import { InviteUsersPanel } from "../admin/organizationsAdmin/modals/InviteUsersPanel";
import { OrganizationLogsPanel } from "../admin/organizationsAdmin/modals/OrganizationLogsPanel";
import { OrganizationModal } from "../admin/organizationsAdmin/modals/OrganizationModal";
import { UpsertOrganizationModal } from "../admin/organizationsAdmin/modals/UpsertOrganizationModal";
import { TabTitle } from "screens/common/components/TabTitle";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { LinkLogo } from "screens/common/components";
import { RiUserAddLine } from "react-icons/ri";
import { MdAddTask } from "react-icons/md";

import { AddUsersPanel } from "../admin/organizationsAdmin/modals/AddUsersPanel";
import { UpsertOrganizationEntitlementModal } from "../admin/organizationsAdmin/modals/UpsertOrganizationEntitlementModal";

export const DeliveryDashboard = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<ModalKey | undefined>();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { manage_organizations_write: hasOrganizationsWrite } = useEntitlements();
  const unorderedOrganizations = useOrganizations();
  const organizations = useMemo(() => sortBy(unorderedOrganizations, "name"), [unorderedOrganizations]);
  const isLoading = useOrganizationIsLoading();
  const { filteredList, renderFilterInputComponent } = useFilterInput<Organization>(["name"], organizations);
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const bgColor = useColorModeValue("white", "gray.800");
  const tileBorderColor = useColorModeValue("gray.300", "gray.700");
  const borderColorHover = useColorModeValue("gray.400", "gray.600");
  const { setHeaderText } = useContext(SettingsProviderContext);

  useEffect(() => {
    setHeaderText("Delivery Dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(undefined);
  }, []);

  const onOpenUpsert = useCallback((openId?: string) => {
    if (openId) {
      setSelectedId(openId);
    } else {
      setSelectedId(null);
    }

    setIsOpen("upsert");
  }, []);

  const onOpenModal = useCallback((modalKey: ModalKey, openId?: string) => {
    if (openId) setSelectedId(openId);

    setIsOpen(modalKey);
  }, []);

  const onDelete = useCallback(async () => {
    try {
      if (!selectedId) {
        return;
      }

      const response = await dispatch(deleteOrganization({ organizationId: selectedId }));

      if (response.type.includes("rejected")) {
        const {
          error: { message },
        } = response as { error: { message: string } };

        toast({
          title: "Organization",
          description: message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });

        return;
      }

      toast({
        title: "Organization",
        description: `Organization deleted`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  }, [dispatch, onClose, selectedId, toast]);

  const commonButtonProps = useButtonProps("sm", "primary");

  const renderOrganizations = useCallback(() => {
    return (filteredList || []).map((organization) => {
      return (
        <Box
          fontSize={"sm"}
          key={organization.id}
          cursor="default"
          boxShadow="none"
          _hover={{
            borderColor: borderColorHover,
          }}
          backgroundColor={bgColor}
          borderColor={tileBorderColor}
          borderWidth="1px"
          borderRadius="md"
          px="1rem"
          justifyContent="flex-start"
          width="100%"
          height="100%"
          overflow="hidden"
          minHeight="7rem"
          _active={{ backgroundColor: { bgColor } }}
          p=".5rem"
          whiteSpace="normal"
          textAlign="left"
          className={`ch-delivery-organization-tile ${organization.name}`}>
          <Stack spacing=".5rem" justifyContent={"space-between"} width="100%">
            <Center>
              <Stack direction="row" width="100%" spacing=".5rem">
                {organization.name && (
                  <LinkLogo
                    url={organization.name.includes("Charli") ? "charli.ai" : `${organization.name}.com`}
                    height={organization.name.includes("Charli") ? "1.6rem" : "2rem"}
                    width="fit-content"
                  />
                )}
                <Text lineHeight={organization.name.includes("Charli") ? "1.6rem" : "2rem"} fontSize="md" fontWeight={"bold"} isTruncated>
                  {organization.name}
                </Text>
              </Stack>
            </Center>
            <Stack direction="row" width="100%" justifyContent={"space-between"}>
              <Text>Plan</Text>
              <Tag size="sm">{organization.planCode.toUpperCase()}</Tag>
            </Stack>
            <Stack direction="row" width="100%" justifyContent={"space-between"}>
              <Text>Members</Text>
              <Tag size="sm">{organization.members?.length ?? 0}</Tag>
            </Stack>
            <Stack direction="row" width="100%" justifyContent={"space-between"}>
              <Text>Last Updated</Text>
              <Stack spacing="0">
                <Tag size="sm">{organization.lastUpdatedByUserName}</Tag>
                <Text textAlign={"right"} fontSize={"xs"}>
                  {formatDistanceToNow(new Date(organization.lastUpdatedDate), { addSuffix: true })}
                </Text>
              </Stack>
            </Stack>
            <Center>
              <Stack direction="row" gridGap={2} pt="1rem">
                <Tooltip aria-label="Org Logs" label="Logs" placement="top" hasArrow>
                  <Box>
                    <Icon
                      pt="3px"
                      cursor="pointer"
                      as={FaList}
                      color={buttonColor}
                      boxSize="1rem"
                      _hover={{ color: buttonHoverColor }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenModal("logs", organization.id);
                      }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip aria-label="Add users" label="Add users" placement="top" hasArrow>
                  <Box>
                    <Icon
                      cursor="pointer"
                      as={RiUserAddLine}
                      color={buttonColor}
                      boxSize="1rem"
                      _hover={{ color: buttonHoverColor }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenModal("add-users", organization.id);
                      }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip aria-label="Add entitlement" label="Add entitlement" placement="top" hasArrow>
                  <Box>
                    <Icon
                      cursor="pointer"
                      as={MdAddTask}
                      color={buttonColor}
                      boxSize="1rem"
                      _hover={{ color: buttonHoverColor }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenModal("add-entitlements", organization.id);
                      }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip aria-label="Email users" label="Email users" placement="top" hasArrow>
                  <Box>
                    <Icon
                      cursor="pointer"
                      as={HiOutlineMail}
                      color={buttonColor}
                      boxSize="1rem"
                      _hover={{ color: buttonHoverColor }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenModal("invite-users", organization.id);
                      }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip aria-label="Delete Org" label="Delete organization" placement="top" hasArrow>
                  <Box>
                    <Icon
                      cursor="pointer"
                      as={AiOutlineDelete}
                      color={buttonColor}
                      boxSize="1rem"
                      _hover={{ color: buttonHoverColor }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenModal("delete", organization.id);
                      }}
                    />
                  </Box>
                </Tooltip>
              </Stack>
            </Center>
          </Stack>
        </Box>
      );
    });
  }, [filteredList, borderColorHover, bgColor, tileBorderColor, buttonColor, buttonHoverColor, onOpenModal]);

  return (
    <Box px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"} mt="0!important">
      <TabTitle title="Charli > Delivery" />
      <Stack direction="row" justifyContent="space-between" spacing={"3rem"} pb="1rem">
        <Stack direction="row" width="100%" mt="0!important" spacing="1rem">
          {renderFilterInputComponent("Filter by organization name")}
        </Stack>
        {hasOrganizationsWrite && (
          <Button width="9rem" {...commonButtonProps} onClick={() => onOpenUpsert()}>
            Add Organization
          </Button>
        )}
      </Stack>
      {organizations.length > 0 && (
        <SimpleGrid columns={[1, 3, 4]} spacing={4}>
          {renderOrganizations()}
        </SimpleGrid>
      )}
      {isOpen === "upsert" && (
        <UpsertOrganizationModal hasWriteOrganizationEntitlement={hasOrganizationsWrite} id={selectedId} isOpen onClose={onClose} />
      )}
      {isOpen === "delete" && (
        <ConfirmDeleteModal
          title="Delete Organization"
          body="Are you sure you want to delete this organization? All users in this organization will loose the entitlements of this organisation but will still be able to login to Charli with any personal entitlements they have."
          isOpen
          onClose={onClose}
          onConfirm={onDelete}
          isLoading={isLoading}
        />
      )}
      {isOpen === "detail" && <OrganizationModal id={selectedId} onClose={onClose} />}
      {isOpen === "add-users" && <AddUsersPanel id={selectedId} onClose={onClose} />}
      {isOpen === "add-entitlements" && <UpsertOrganizationEntitlementModal maxHeight="80vh" id={selectedId} onClose={onClose} />}
      {isOpen === "invite-users" && <InviteUsersPanel id={selectedId} onClose={onClose} />}
      {isOpen === "logs" && <OrganizationLogsPanel id={selectedId} onClose={onClose} />}
    </Box>
  );
};
