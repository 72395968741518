import escapeStringRegexp from "escape-string-regexp";
import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { Command } from "types/commands";
import type { MessageIntent } from "types/conversation";

export type MergeStrategy = "merge" | "replace";

export function useCommandSuggestions() {
  // State selectors
  const commandsByIntent = useSelector((state: RootState) => state.command.commands);
  const commandsByLabel = useMemo(() => {
    const commandDictionary = Object.values(commandsByIntent).reduce((accum, command) => {
      if (command && command.command_label) accum[command.command_label] = command;
      return accum;
    }, {} as { [key: string]: Command });
    return commandDictionary;
  }, [commandsByIntent]);
  const availableIntents = useMemo(() => {
    return Object.keys(commandsByIntent) as MessageIntent[];
  }, [commandsByIntent]);

  // Utility functions
  const mergeSuggestionWithMessage = useCallback((suggestion: string, message: string, strategy: MergeStrategy) => {
    if (message.trim() === "") return `${suggestion} `;

    if (strategy === "merge") {
      const tokens = message.trim().split(" ");
      const lastToken = tokens.reverse()[0];

      // If the user has partially typed a token, replace that partially typed token with the complete suggestion
      if (lastToken && (lastToken.startsWith("/") || lastToken.startsWith(">"))) {
        // Does the suggestion start with this token?
        if (suggestion.startsWith(lastToken)) {
          // Replace that token with the suggestion
          return message.replace(new RegExp(`${escapeStringRegexp(lastToken)}$`), `${suggestion} `);
        }
      }

      return `${message.trim()} ${suggestion} `;
    } else {
      return `${suggestion.trim()} `;
    }
  }, []);

  return { commandsByIntent, commandsByLabel, availableIntents, mergeSuggestionWithMessage };
}
