import type { FunctionComponent } from "react";
import React from "react";
import type { Message } from "types/conversation";
import { MessageContainer } from "./components";
import { MessageImage } from "./components";
import type { File } from "types/charliui";
import { prependProxyBaseUrl } from "api/request";

interface Props {
  message: Message;
  datum: File;
}

export const ImageCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ message, datum }) => {
  return (
    <MessageContainer initiator={message.senderId === "charli" ? "charli" : "user"} key={`${message.id}-${datum.body.file_id}`}>
      <MessageImage
        initiator={message.senderId === "charli" ? "charli" : "user"}
        src={prependProxyBaseUrl(`/api/media/internal/download-file/${datum.body.file_id}`)}
      />
    </MessageContainer>
  );
};
