import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteSystemPreference, listSystemKeyPreferences, listSystemPreferences, putSystemPreference } from "api/systemPreferences";
import type { UpdatePreference } from "types/systemPreferences/UpdatePreference";

export const downloadSystemKeyPreferences = createAsyncThunk("system-key-preferences/download", async () => {
  return await listSystemKeyPreferences();
});

export const downloadSystemPreferences = createAsyncThunk("system-preferences/download", async () => {
  return await listSystemPreferences();
});

export const updateSystemPreference = createAsyncThunk("system-preferences/update", async (payload: UpdatePreference) => {
  return await putSystemPreference(payload);
});

export const removeSystemPreference = createAsyncThunk("system-preferences/remove", async (preferenceKey: string) => {
  return await deleteSystemPreference(preferenceKey);
});
