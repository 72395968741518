import { RenderIfHasEntitlements } from "screens/common/components";
import { Select } from "chakra-react-select";
import { useOrganizations } from "hooks";
import React, { useMemo } from "react";
import type { MultiValue, GroupBase, SelectInstance } from "chakra-react-select";

type OrganizationOption = { label: string; value: string };

interface OrganizationsSelectProps {
  name: string;
  isLoading?: boolean;
  onChange: (values: MultiValue<OrganizationOption>) => void;
  value: OrganizationOption[] | undefined;
}

const OrganizationsSelectInner = React.forwardRef<
  SelectInstance<OrganizationOption, true, GroupBase<OrganizationOption>>,
  OrganizationsSelectProps
>(({ value, name, isLoading = false, onChange }, ref) => {
  const organizations = useOrganizations();

  const organizationsOptions = useMemo(() => organizations.map((org) => ({ value: org.id, label: org.name })), [organizations]);

  return (
    <Select
      ref={ref}
      name={name}
      value={value}
      placeholder="Select organizations..."
      isDisabled={isLoading}
      isLoading={isLoading}
      isMulti
      isClearable
      options={organizationsOptions}
      onChange={(values) => onChange(values)}
    />
  );
});

export const OrganizationsSelect = React.forwardRef<
  SelectInstance<OrganizationOption, true, GroupBase<OrganizationOption>>,
  OrganizationsSelectProps
>((props: OrganizationsSelectProps, ref) => {
  return (
    <RenderIfHasEntitlements entitlement="manage_organizations_read">
      <OrganizationsSelectInner ref={ref} {...props} />
    </RenderIfHasEntitlements>
  );
});
