import { Record, String, Array, Boolean } from "runtypes";
import { User } from "@charliai/node-core-lib/lib/src/userService/models/User";

export const GetUsersRequest = Array(
  User.extend({
    isEmailVerified: Boolean,
    organizations: Array(
      Record({
        organizationId: String,
        organizationName: String,
        organizationPlanCode: String,
        memberRole: String,
      })
    ),
    passwordResetCode: String.nullable(),
  })
);
