import type { JSONSchema7 } from "json-schema";

export const AnswerFocusOptionsSchema: JSONSchema7 = {
  $id: "http://json-schema.org/draft-07/schema#",
  description: "The weights of the sources used in the project.",
  type: "array",
  maxLength: 2,
  minLength: 2,
  items: {
    type: "object",
    properties: {
      label: {
        type: "string",
      },
      key: {
        type: "string",
        enum: ["analytical", "sentiment"],
      },
      default: {
        type: "boolean",
      },
      sourceContentWeights: {
        type: "object",
        properties: {
          factset: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
          sec: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
          google_search: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
          bing: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
          duckduckgo: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
          user_uploaded: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
        },
      },
    },
    required: ["label", "key", "sourceContentWeights"],
  },
};
