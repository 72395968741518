import React, { useEffect, useState } from "react";
import type { PropsWithChildren } from "react";
import { Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { usePrevious } from "@chakra-ui/react";
import { ReactComponent as CharliLogoFull } from "screens/common/static/logos/charli-logo-full.svg";
import { useEntitlementKey, useFeatureFlags } from "hooks";

const Maintenance = () => {
  const messageColor = useColorModeValue("gray.500", "gray.300");

  return (
    <Flex height="100vh" width="100vw">
      <Stack margin="auto" align="center">
        <CharliLogoFull id="close-modal-button" style={{ width: "11rem", height: "5rem" }} />
        <Text color={messageColor} fontSize="md" fontWeight="light">
          Sorry, we're down for maintenance right now.
        </Text>
        <Text color={messageColor} fontSize="md" fontWeight="light">
          This page will reload when we're back online.
        </Text>
      </Stack>
    </Flex>
  );
};

export const MaintenanceGate: React.FC<React.PropsWithChildren<React.PropsWithChildren<PropsWithChildren>>> = ({ children }) => {
  const { show_maintenance_screen: isMaintenanceModeEnabled } = useFeatureFlags();
  const wasMaintenanceModeEnabled = usePrevious(isMaintenanceModeEnabled);
  const [canRefreshPage, setCanRefreshPage] = useState(false);
  const canBypassMaintenance = useEntitlementKey("ui_bypass_maintenance_page");

  useEffect(() => {
    /*
     * Do not refresh the page after maintenance mode is switched off if the page was loaded in the last 10 seconds.
     * A change in maintenance status this soon indicates that the flag state may have been stale, and even if not, a refresh
     * so soon after the page was loaded is unlikely to have any meaningful impact on the health of the app.
     */
    const timer = setTimeout(() => {
      setCanRefreshPage(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, [setCanRefreshPage]);

  useEffect(() => {
    if (wasMaintenanceModeEnabled && !isMaintenanceModeEnabled && canRefreshPage) {
      // We are transitioning out of maintenance mode. Refresh the page to pull in any new web app updates, and clear up any issues tied to the user's potentially stale session.
      window.location.reload();
    }
  }, [isMaintenanceModeEnabled, wasMaintenanceModeEnabled, canRefreshPage]);

  if (!canBypassMaintenance && isMaintenanceModeEnabled) {
    return <Maintenance />;
  } else {
    return <>{children}</>;
  }
};
