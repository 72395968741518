import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { WorkflowMilestone } from "api/workflows/models/WorkflowMilestone";
import omit from "lodash/omit";
import type { WorkflowMilestoneUpdate } from "state/websocket/api/WorkflowMilestoneUpdate";
import { downloadWorkflowsMilestones } from "./operations";

const getMilestoneId = (workflowId: string, checkpointId: string) => `${workflowId}-${checkpointId}`;

interface NormalizedMilestone extends WorkflowMilestone {
  milestoneId: string;
}

interface MilestonesState {
  isLoadingWorkflowsMilestones: Record<string, boolean>;
  milestonesIdsByWorkflow: Record<string, { milestonesIds: string[] }>;
  milestonesById: Record<string, NormalizedMilestone>;
}

const initialState: MilestonesState = {
  isLoadingWorkflowsMilestones: {},
  milestonesIdsByWorkflow: {},
  milestonesById: {},
};

export const { actions, reducer } = createSlice({
  name: "milestones",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
    setMilestone(state, action: PayloadAction<WorkflowMilestoneUpdate>) {
      const { workflowId, checkpointId, completionDate } = action.payload;

      const milestoneToChange = state.milestonesById[getMilestoneId(workflowId, checkpointId)];

      if (milestoneToChange) {
        milestoneToChange.completionDate = completionDate;
      }
    },
    removeMilestone(state, action: PayloadAction<{ workflowId: string; checkpointId: string }>) {
      const { workflowId, checkpointId } = action.payload;
      const milestoneId = getMilestoneId(workflowId, checkpointId);

      if (state.milestonesById[milestoneId]) {
        state.milestonesIdsByWorkflow[workflowId].milestonesIds = state.milestonesIdsByWorkflow[workflowId].milestonesIds.filter(
          (id) => id !== milestoneId
        );

        delete state.milestonesById[milestoneId];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadWorkflowsMilestones.pending, (state, { meta }) => {
      state.isLoadingWorkflowsMilestones = Object.assign(
        {},
        state.isLoadingWorkflowsMilestones,
        meta.arg.workflowIds.reduce((acc, id) => ({ ...acc, [id]: true }), {})
      );
    });
    builder.addCase(downloadWorkflowsMilestones.rejected, (state, { meta }) => {
      const { workflowIds } = meta.arg;
      state.isLoadingWorkflowsMilestones = omit(state.isLoadingWorkflowsMilestones, workflowIds);
    });
    builder.addCase(downloadWorkflowsMilestones.fulfilled, (state, { payload, meta }) => {
      const { workflowIds } = meta.arg;
      state.isLoadingWorkflowsMilestones = omit(state.isLoadingWorkflowsMilestones, workflowIds);

      payload.forEach(({ id, events }) => {
        state.milestonesIdsByWorkflow[id] = { milestonesIds: events.map(({ checkpointId }) => getMilestoneId(id, checkpointId)) };
        events.forEach((event) => {
          state.milestonesById[getMilestoneId(id, event.checkpointId)] = { ...event, milestoneId: getMilestoneId(id, event.checkpointId) };
        });
      });
    });
  },
});

export default reducer;
