import type { Dispatch } from "redux";
import chunk from "lodash/chunk";
import { downloadContents } from "./operations";

const CHUNK_SIZE = 50;

export const requestToDownloadContents = async (
  { metadataIds }: { metadataIds: string[] },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
): Promise<void> => {
  const chunksOfMetadataIds = chunk(metadataIds, CHUNK_SIZE);
  for (const chunk of chunksOfMetadataIds) {
    dispatch(downloadContents({ metadataIds: chunk }));
  }
};
