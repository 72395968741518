import React from "react";
import { Center, Stack, Text, Image, HStack, useBreakpointValue } from "@chakra-ui/react";
import nasdaqLogo from "screens/common/static/logos/nasdaq_logo_white.svg";
import tmxLogo from "screens/common/static/logos/tmx_logo_white.svg";
import { useCopyValue } from "hooks/useCopies";

interface Props {
  title: string;
}

export const SubscriptionUpgradeFeaturesHeader: React.FC<Props> = ({ title }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const subscriptionPrice = useCopyValue("copy_subscription_plan_price");

  return (
    <>
      <Stack height="100%" spacing="1rem" bgColor="primary.default" py="2rem" color="white">
        <Center px={isMobile ? "1rem" : "5rem"}>
          <Text fontWeight="semibold" fontSize="xl">
            {title}
          </Text>
        </Center>
        <Text fontSize="md" width="100%" textAlign="center" px={isMobile ? "1rem" : "5rem"}>
          Get started with the Automated Analyst for Only{" "}
          <Text fontWeight="semibold" as="span" color="#ffc070">
            USD ${subscriptionPrice}
          </Text>{" "}
          a month.
        </Text>
        {!isMobile && (
          <Text fontSize="md" width="100%" textAlign="center" px={isMobile ? "1rem" : "15rem"}>
            For our Business, Premium and Enterprise subscriptions, including access to FactSet data, please press{" "}
            <Text
              cursor="pointer"
              fontWeight="semibold"
              as="span"
              color="#ffc070"
              onClick={() => window.open("https://charliai.com/pricing/", "_blank")}
              aria-label="Open pricing page in new tab">
              here
            </Text>{" "}
            for more details.
          </Text>
        )}
      </Stack>
      {!isMobile && (
        <HStack position="absolute" top="8rem" right="1rem" align="center" spacing="1rem">
          <Image src={nasdaqLogo} height="2rem" alt="NASDAQ Logo" />
          <Image src={tmxLogo} height="2.5rem" alt="TMX Logo" />
        </HStack>
      )}
    </>
  );
};
