import { createSlice } from "@reduxjs/toolkit";
import type { ReportTemplate } from "types/reportTemplates/ReportTemplate";
import type { ReportTemplateGrouping } from "types/reportTemplates/ReportTemplateGrouping";
import { addReportTemplate, downloadReportTemplates, updateReportTemplate, removeReportTemplate } from "./operations";

interface ReportTemplateState {
  isLoading: boolean;
  reportTemplates: ReportTemplateGrouping[];
  reportTemplatesById: Record<string, ReportTemplate>;
}

const initialState: ReportTemplateState = {
  isLoading: false,
  reportTemplates: [],
  reportTemplatesById: {},
};

export const defaultOption = { value: "default", label: "Available to everyone" };

export const { actions, reducer } = createSlice({
  name: "reportTemplates",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadReportTemplates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadReportTemplates.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadReportTemplates.fulfilled, (state, action) => {
      state.isLoading = false;

      state.reportTemplates = action.payload;
      state.reportTemplatesById = action.payload.reduce((acc: Record<string, ReportTemplate>, reportTemplateGrouping) => {
        reportTemplateGrouping.reportTemplates.forEach((reportTemplate) => {
          acc[reportTemplate.id] = {
            ...reportTemplate,
            organizationName: reportTemplate.organizationId ? reportTemplateGrouping.organizationName : defaultOption.label,
            organizationId: reportTemplate.organizationId ?? defaultOption.value,
          };
        });

        return acc;
      }, {});
    });

    builder.addCase(addReportTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addReportTemplate.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addReportTemplate.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateReportTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateReportTemplate.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateReportTemplate.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(removeReportTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeReportTemplate.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeReportTemplate.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export default reducer;
