import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFeatureUsage } from "api/usage";
import type { RootState } from "state/rootReducer";

export const downloadCurrentUserFeatureUsage = createAsyncThunk("usage/download-current-user", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;

  if (state.session.user?.id) {
    return getFeatureUsage(state.session.user.id);
  } else {
    console.warn(`Tried to fetch user usage but no user id was available`);

    throw new Error("No user id available");
  }
});
