import { Button, Stack, useToast } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useEffect } from "react";
import React, { useContext, useState } from "react";
import { useButtonProps, useCommaSeparatedEntitiesMap, useConfigMap, useItemSelector } from "hooks";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { ConversationContext } from "screens/thread/ConversationContext";
import { ToastMessageContent } from "screens/common/components/ToastMessageContent";
import { ResearchTopicsInput } from "../research/ResearchTopicsInput";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import type { RequestEntities } from "types/charliui";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";

interface Props {
  conversationId: string;
  collectionId?: string;
  onClose: () => void;
  openGenerateForm?: boolean;
}

export const GenerateHighlights: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  conversationId,
  collectionId,
  onClose,
  openGenerateForm = false,
}) => {
  const configMap = useConfigMap();
  const { onConversationOpen, requestEntities, setRequestEntities } = useContext(ConversationContext);
  const dispatch = useDispatch();
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const { focusedItem } = useItemSelector();
  const toast = useToast();
  const [topics, setTopics] = useState("");
  const hasSelectedTopics = getRequestValue("topic", requestEntities).length > 0;
  const commaSeparatedEntitiesMap = useCommaSeparatedEntitiesMap(configMap);
  const { setShowHighlightsForm } = useAddToCharliContext();

  useEffect(() => {
    const newTopics = getRequestValue("topic", requestEntities);
    setTopics(newTopics);
  }, [requestEntities]);

  useEffect(() => {
    if (topics.length > 0) {
      updateBatchRequestEntities(
        [
          { entity: "min_length", value: "90", source: "topic-inputs" },
          { entity: "max_length", value: "130", source: "topic-inputs" },
        ],
        setRequestEntities
      );
      if (focusedItem) {
        updateBatchRequestEntities([{ entity: focusedItem.type, value: focusedItem.id, source: "topic-inputs" }], setRequestEntities);
      }
      if (collectionId) {
        updateBatchRequestEntities(
          [
            { entity: "collection_id", value: collectionId || "", source: "topic-inputs" },
            { entity: "metadata_id", value: collectionId || "", source: "topic-inputs" },
          ],
          setRequestEntities
        );
      }
    } else {
      setRequestEntities([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics]);

  const generateHighlights = () => {
    onClose();
    setShowHighlightsForm(false);
    const entities: RequestEntities = [];

    if (requestEntities && requestEntities.length > 0) {
      requestEntities.forEach((requestEntity) => {
        const splitEntity = commaSeparatedEntitiesMap[requestEntity.entity];
        if (splitEntity) {
          requestEntity.value.split(splitEntity).forEach((itemValue) => {
            if (itemValue.length > 0) {
              entities.push({ entity: requestEntity.entity, value: itemValue.trim() });
            }
          });
        } else {
          entities.push({ entity: requestEntity.entity, value: requestEntity.value });
        }
      });
    }

    dispatch(
      sendMessage({
        conversationId: conversationId,
        intent: "/generate_highlights",
        entities: entities,
      })
    );

    setRequestEntities([]);

    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`No problem, I'll generate the highlights. You can open the conversation I started about it by clicking this message`}
          onClick={() => {
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const onCancel = () => {
    setRequestEntities([]);
    setShowHighlightsForm(false);
  };

  return (
    <Stack direction="row">
      <Stack {...(!openGenerateForm && { direction: "row" })} justifyContent="space-between" spacing="2rem">
        <ResearchTopicsInput />
      </Stack>
      <Stack direction="row" justifyContent={openGenerateForm ? "flex-start" : "space-between"} mt="1rem">
        <Button
          isDisabled={!hasSelectedTopics}
          className="ch-highlight-generate-button"
          {...secondaryButtonProps}
          onClick={() => {
            generateHighlights();
          }}>
          Generate
        </Button>
        {!openGenerateForm && (
          <Button id="add-to-collection-cancel-button" {...secondaryButtonProps} onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
