import { createSlice } from "@reduxjs/toolkit";
import type { EntityConfiguration } from "api/entityConfigurations/models/EntityConfiguration";
import {
  downloadEntityConfigurations,
  downloadEntityConfiguration,
  createEntityConfigurationAction,
  updateEntityConfigurationAction,
  deleteEntityConfigurationAction,
} from "./operations";

interface EntityConfigurationsState {
  isLoading: boolean;
  entityConfigurationsIds: string[];
  entityConfigurationsByIds: Record<string, EntityConfiguration>;
}

const initialState: EntityConfigurationsState = {
  isLoading: false,
  entityConfigurationsIds: [],
  entityConfigurationsByIds: {},
};

export const { actions, reducer } = createSlice({
  name: "entityConfigurations",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadEntityConfigurations.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(downloadEntityConfigurations.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(downloadEntityConfigurations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.entityConfigurationsIds = action.payload.map((entityConfiguration) => entityConfiguration.id);
      state.entityConfigurationsByIds = action.payload.reduce((acc: Record<string, EntityConfiguration>, entityConfiguration) => {
        acc[entityConfiguration.id] = entityConfiguration;
        return acc;
      }, {});
    });

    builder.addCase(downloadEntityConfiguration.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(downloadEntityConfiguration.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(downloadEntityConfiguration.fulfilled, (state, action) => {
      state.isLoading = false;
      const entityConfiguration = action.payload;

      if (!state.entityConfigurationsByIds[entityConfiguration.id]) {
        state.entityConfigurationsIds.push(entityConfiguration.id);
      }

      state.entityConfigurationsByIds[entityConfiguration.id] = entityConfiguration;
    });

    builder.addCase(createEntityConfigurationAction.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(createEntityConfigurationAction.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(createEntityConfigurationAction.fulfilled, (state, action) => {
      state.isLoading = false;
      const entityConfiguration = action.payload;

      state.entityConfigurationsIds.push(entityConfiguration.id);
      state.entityConfigurationsByIds[entityConfiguration.id] = entityConfiguration;
    });

    builder.addCase(updateEntityConfigurationAction.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(updateEntityConfigurationAction.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(updateEntityConfigurationAction.fulfilled, (state, action) => {
      state.isLoading = false;
      const entityConfiguration = action.payload;

      state.entityConfigurationsByIds[entityConfiguration.id] = entityConfiguration;
    });

    builder.addCase(deleteEntityConfigurationAction.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(deleteEntityConfigurationAction.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(deleteEntityConfigurationAction.fulfilled, (state, action) => {
      state.isLoading = false;
      const entityId = action.meta.arg;

      delete state.entityConfigurationsByIds[entityId];
      state.entityConfigurationsIds = state.entityConfigurationsIds.filter((entityConfigurationId) => entityConfigurationId !== entityId);
    });
  },
});

export default reducer;
