import type { RootState } from "state/rootReducer";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBillingStatus as getBillingStatusApi } from "api/billingPlan";

export const getBillingPlan = createAsyncThunk("billing/plan", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;

  if (state.session.user?.id) {
    return await getBillingStatusApi(state.session.user.id);
  } else {
    console.warn(`Tried to fetch billing plan but no user id was available`);
    return undefined;
  }
});
