import { Text, Stack, useColorModeValue, MenuItem } from "@chakra-ui/react";
import { useCollectionKey, useCompanyStockFromCollectionContentsOrConversation, useProjectInPortfolios } from "hooks";
import type { FunctionComponent } from "react";
import React, { useState } from "react";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { useConversationDialogSubmitFunctions } from "screens/thread/useConversationDialogSubmitFunctions";
import { OptionsMenuItem } from "../../components/OptionsMenuItem";
import { useConversationContext } from "screens/thread/ConversationContext";

interface Props {
  reRunProjectId: string; // collectionId
  isDisabled?: boolean;
}

export const ProjectRerunButton: FunctionComponent<Props> = ({ reRunProjectId, isDisabled }) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const fontColor = useColorModeValue("gray.500", "gray.400");
  const { onSubmitDueDiligenceProject } = useConversationDialogSubmitFunctions({});
  const portfoliosProjectIsIn = useProjectInPortfolios(reRunProjectId || "");
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(reRunProjectId);
  const reRunPortfolioId = portfoliosProjectIsIn && portfoliosProjectIsIn[0] && portfoliosProjectIsIn[0].id;
  const { setSelectedQuestionAndFocus } = useConversationContext();
  const isEmbeddingModelVersionOutdated = useCollectionKey(reRunProjectId, "isEmbeddingModelVersionOutdated");

  const submitMessage = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    if (!companyStockData) {
      return;
    }
    setSelectedQuestionAndFocus({
      projectTicker: companyStockData.ticker,
      projectExchange: companyStockData.exchange,
      projectId: reRunProjectId,
    });
    onSubmitDueDiligenceProject(
      companyStockData.ticker,
      companyStockData.exchange,
      undefined,
      reRunProjectId,
      reRunPortfolioId,
      undefined,
      undefined,
      isEmbeddingModelVersionOutdated
    );

    setTimeout(() => {
      setIsRequesting(false);
    }, 5000);
    setIsRequesting(true);
  };

  return (
    <>
      {isRequesting ? (
        <MenuItem isDisabled>
          <Stack direction="row" spacing="1rem">
            <Text color={fontColor} fontSize={"sm"}>
              Rerunning Project
            </Text>
            <TypingIndicator size="small" />
          </Stack>
        </MenuItem>
      ) : (
        <OptionsMenuItem
          menuLabel={"Rerun Project"}
          className="ch-rerun-project"
          onClick={(event) => submitMessage(event)}
          isDisabled={!reRunProjectId || isDisabled}
        />
      )}
    </>
  );
};
