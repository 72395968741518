import { createSlice } from "@reduxjs/toolkit";
import type { WorkflowTaskStatus } from "types/workflows/workflow";
import type { Progress } from "types/workflows/workflow";
import type { PayloadAction } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";

export type ProgressWithAdditionalParams = Omit<Progress, "task"> & { id: string; intent: string };

interface CheckpointsState {
  runningTaskByWorkflow: Record<string, string>;
  progressByWorkflow: Record<string, Progress>;
  childWorkflowsIdsByWorkflow: Record<string, string[]>;
  progressByChildWorkflow: Record<string, ProgressWithAdditionalParams>;
  statusByChildWorkflow: Record<string, WorkflowTaskStatus>;
  runningTaskByChildWorkflow: Record<string, string>;
  tasksIdsByChildWorkflow: Record<string, string[]>;
  tasksById: Record<
    string,
    {
      childWorkflowId: string;
      workflowId: string;
      name: string;
      status: WorkflowTaskStatus;
    }
  >;
}

const initialState: CheckpointsState = {
  runningTaskByWorkflow: {},
  progressByWorkflow: {},
  childWorkflowsIdsByWorkflow: {},
  progressByChildWorkflow: {},
  statusByChildWorkflow: {},
  runningTaskByChildWorkflow: {},
  tasksIdsByChildWorkflow: {},
  tasksById: {},
};

export const { actions, reducer } = createSlice({
  name: "checkpoints",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
    setCheckpointsWorkflows(state, action: PayloadAction<{ id: string; progress: Progress }[]>) {
      action.payload.forEach((workflow) => {
        const { id, progress } = workflow;

        state.progressByWorkflow[id] = progress;
      });
    },
    setCheckpointsChildWorkflows(
      state,
      action: PayloadAction<{
        workflowId: string;
        progressByChildWorkflow: Record<string, ProgressWithAdditionalParams>;
      }>
    ) {
      Object.entries(action.payload.progressByChildWorkflow).forEach(
        ([childWorkflowId, { intent, completedStepCount, estimatedTotalStepCount }]) => {
          if (!state.progressByChildWorkflow[childWorkflowId]) {
            state.progressByChildWorkflow[childWorkflowId] = {
              completedStepCount,
              estimatedTotalStepCount,
              intent,
              id: childWorkflowId,
            };

            state.childWorkflowsIdsByWorkflow[action.payload.workflowId] = [
              childWorkflowId,
              ...(state.childWorkflowsIdsByWorkflow[action.payload.workflowId] || []),
            ];
          } else {
            state.progressByChildWorkflow[childWorkflowId].completedStepCount = completedStepCount;
            state.progressByChildWorkflow[childWorkflowId].estimatedTotalStepCount = estimatedTotalStepCount;
          }
        }
      );
    },
    setStatusByChildWorkflow(state, action: PayloadAction<{ childWorkflowId: string; status: WorkflowTaskStatus }>) {
      const { childWorkflowId, status } = action.payload;

      state.statusByChildWorkflow[childWorkflowId] = status;
    },
    setWorkflowRunningTask(state, action: PayloadAction<{ workflowId: string; name: string }>) {
      const { workflowId, name } = action.payload;

      state.runningTaskByWorkflow[workflowId] = name;
    },
    setChildWorkflowTask(
      state,
      action: PayloadAction<{
        taskId: string;
        childWorkflowId: string;
        workflowId: string;
        name: string;
        status: WorkflowTaskStatus;
      }>
    ) {
      const { childWorkflowId, workflowId, name, status, taskId } = action.payload;

      if (!state.tasksById[taskId]) {
        state.tasksIdsByChildWorkflow[childWorkflowId] = uniq([taskId, ...(state.tasksIdsByChildWorkflow[childWorkflowId] || [])]);
      }

      state.tasksById[taskId] = { childWorkflowId, workflowId, name, status };
    },
    clearWorkflowCheckpoints(state, action: PayloadAction<string>) {
      const workflowId = action.payload;

      delete state.progressByWorkflow[workflowId];
      delete state.runningTaskByWorkflow[workflowId];
    },
    clearChildWorkflowCheckpoints(state, action: PayloadAction<string>) {
      const workflowId = action.payload;
      const childWorkflowsIds = state.childWorkflowsIdsByWorkflow[workflowId] || [];

      childWorkflowsIds.forEach((childWorkflowId) => {
        delete state.progressByChildWorkflow[childWorkflowId];
        delete state.runningTaskByChildWorkflow[childWorkflowId];
        delete state.statusByChildWorkflow[childWorkflowId];

        const tasksIds = state.tasksIdsByChildWorkflow[childWorkflowId] || [];

        tasksIds.forEach((taskId) => {
          delete state.tasksById[taskId];
        });

        delete state.tasksIdsByChildWorkflow[childWorkflowId];
      });

      delete state.childWorkflowsIdsByWorkflow[workflowId];
    },
  },
});

export default reducer;
