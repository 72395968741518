import type { SuggestedQuestion, SuggestedQuestions } from "api/suggestions";
import { useCompanyStockFromCollectionOrContents, useCollectionKey, useCommandSuggestions } from "hooks";
import { useMemo } from "react";

export const useAvailableCommands = (intentFilter?: string, exitCondition?: boolean) => {
  const { commandsByLabel } = useCommandSuggestions();
  const availableCommandsMemo = useMemo(() => {
    if (exitCondition) return [];
    if (commandsByLabel) {
      return Object.entries(commandsByLabel).map((command) => ({
        question: `/${command[1].intent}`,
        matchFilter: [`/${command[1].intent}`],
        focus: `${command[1].intent.split("_")[0]}`,
      }));
    }
    return [];
  }, [exitCondition, commandsByLabel]);

  const filterCommandEntitiesMemo: SuggestedQuestion[] = useMemo(() => {
    if (exitCondition || !intentFilter) return [];
    if (!commandsByLabel) return [];

    return Object.entries(commandsByLabel).reduce<SuggestedQuestion[]>((acc, [_, command]) => {
      const intent = intentFilter.split(" ")[0];
      const isFilteredCommand = command.intent === intent.replace("/", "").trim();

      if (isFilteredCommand) {
        command.mandatory_entities.forEach((entity) => {
          acc.push({
            question: `>${entity}`,
            matchFilter: [`>${entity}`],
            focus: "mandatory",
          });
        });

        command.optional_entities.forEach((entity) => {
          acc.push({
            question: `>${entity}`,
            matchFilter: [`>${entity}`],
            focus: "optional",
          });
        });

        command.recommended_entities.forEach((entity) => {
          acc.push({
            question: `>${entity}`,
            matchFilter: [`>${entity}`],
            focus: "recommended",
          });
        });
      }

      return acc;
    }, []);
  }, [exitCondition, commandsByLabel, intentFilter]);

  return { availableCommandsMemo, filterCommandEntitiesMemo };
};

export const useCurrentCollectionQuestions = (suggestedQuestions?: SuggestedQuestions, collectionId?: string) => {
  const collectionQuestions = useCollectionKey(collectionId, "questions");
  const companyStockData = useCompanyStockFromCollectionOrContents(collectionId);

  const currentCollectionQuestions = useMemo(() => {
    return collectionQuestions?.flatMap((question) => question?.question) || [];
  }, [collectionQuestions]);

  const filterQuestionsByFocusMemo = useMemo(() => {
    if (!suggestedQuestions) return [];
    const suggestedQuestionReplaceCompanyName = suggestedQuestions.map((suggestion) => {
      return {
        ...suggestion,
        question: suggestion.question.replace("{company_name}", companyStockData?.name ?? ""),
      };
    });

    return suggestedQuestionReplaceCompanyName.filter((suggestion) => !currentCollectionQuestions.includes(suggestion.question));
  }, [suggestedQuestions, companyStockData?.name, currentCollectionQuestions]);

  return { currentCollectionQuestions, filterQuestionsByFocusMemo };
};
