import type { Static } from "runtypes";
import { Record, String, Array } from "runtypes";
import { Schema } from "./Schema";

export const TableContent = Record({
  title: String.optional(),
  schema: Schema,
  data: Array(Record({})),
});

export type TableContent = Static<typeof TableContent>;
