import type { ChangeEvent } from "react";
import { useCallback, useMemo, useState } from "react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, Box, Icon, useColorModeValue, TableContainer } from "@chakra-ui/react";
import { useAllUserPreference, useConfigMap, useMenuConfig, useUserPreference } from "hooks";
import { fetchUserMarketingPreference, updateTypedUserPreference } from "state/userPreference/operations";
import { getTypeFromRoute, getViewConfig } from "configs/configMap";
import type { UserPreferenceKey } from "types/userPreferences";
import { MdDragIndicator } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import type { DropResult } from "@hello-pangea/dnd";
import sortBy from "lodash/sortBy";

export const ConfigLayoutOptions = () => {
  const dispatch = useDispatch();
  const userPreferences = useAllUserPreference();
  const configMap = useConfigMap();
  const menuConfig = useMenuConfig(configMap, "entitlements", false);
  const buttonColor = useColorModeValue("primaryButton.color", "primaryButton.colorDarkMode");
  const menuItemsOrderPreference = useUserPreference("ui_menu_order") as string;
  const [menuItemOrder, setMenuItemOrder] = useState(
    (menuItemsOrderPreference && menuItemsOrderPreference.split(",")) || menuConfig.map((menuItem) => menuItem.id)
  );

  const reorderedMenuConfig = useMemo(() => {
    const sortedMenuConfig = sortBy(menuConfig, (menuItem) => {
      const index = menuItemOrder.indexOf(menuItem.id);
      return index !== -1 ? index : Number.MAX_SAFE_INTEGER;
    });

    const shownItems = sortedMenuConfig.filter((menuItem) => {
      const type = getTypeFromRoute(menuItem.config.route);
      const isShown = userPreferences[`ui_show_${type}_menu`] !== false;
      return isShown;
    });

    const hiddenItems = sortedMenuConfig.filter((menuItem) => {
      const type = getTypeFromRoute(menuItem.config.route);
      const isShown = userPreferences[`ui_show_${type}_menu`] !== false;
      return !isShown;
    });

    return [...shownItems, ...hiddenItems];
  }, [menuConfig, menuItemOrder, userPreferences]);

  const reorder = useCallback((list: string[], startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      const { index: startIndex } = source;
      const { index: endIndex } = destination;

      const filteredMenuConfig = reorderedMenuConfig.filter((menuItem) => {
        const type = getTypeFromRoute(menuItem.config.route);
        const isShown = userPreferences[`ui_show_${type}_menu`] !== false;
        return isShown;
      });
      const filteredMenuConfigIds = filteredMenuConfig.map((menuItem) => menuItem.id);
      const items = reorder(filteredMenuConfigIds, startIndex, endIndex);

      const makeItemsCommaString = items.join(",");
      setMenuItemOrder(items);
      dispatch(updateTypedUserPreference({ preferenceKey: "ui_menu_order", value: makeItemsCommaString }));
    },
    [reorderedMenuConfig, reorder, dispatch, userPreferences]
  );

  useEffect(() => {
    dispatch(fetchUserMarketingPreference());
  }, [dispatch]);

  const onSwitchChange = (key: UserPreferenceKey) => async (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    dispatch(updateTypedUserPreference({ preferenceKey: key, value: isChecked }));
  };

  return (
    <Stack width="100%" justifyContent={"space-between"}>
      <Text fontSize="sm" color="gray.500" pb="2rem">
        Customize how you view your projects overviews and the detail of a selected project. Any changes you make here will be override the
        configured defaults.
      </Text>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <TableContainer {...provided.droppableProps} ref={provided.innerRef} display={"fixed"}>
              <Table size={"sm"} variant="simple">
                <Thead>
                  <Tr>
                    <Th width="5rem">Order</Th>
                    <Th textAlign={"center"}>Show Menu</Th>
                    <Th>Project Title</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {reorderedMenuConfig.map((menuItem, index) => {
                    const type = getTypeFromRoute(menuItem.config.route);
                    const isShown = userPreferences[`ui_show_${type}_menu`] !== false;
                    return (
                      <Draggable isDragDisabled={!isShown} key={menuItem.id} draggableId={menuItem.id} index={index}>
                        {(provided, snapshot) => {
                          const { style, ...draggableProps } = provided.draggableProps;
                          return (
                            <Tr
                              style={{ ...style, height: "3rem", left: "auto !important", top: "auto !important" }}
                              ref={provided.innerRef}
                              {...draggableProps}
                              {...provided.dragHandleProps}>
                              <Td width="5rem" textAlign="center">
                                {isShown && <Icon as={MdDragIndicator} color={buttonColor} borderColor={buttonColor} boxSize="1.5rem" />}{" "}
                              </Td>
                              <Td width="5rem">
                                <Box textAlign="center">
                                  <Checkbox
                                    id="ch-settings-advanced-show-meetings"
                                    isChecked={isShown}
                                    onChange={onSwitchChange(`ui_show_${type}_menu`)}
                                  />
                                </Box>
                              </Td>
                              <Td>
                                <Text>{`${getViewConfig("title", menuItem.id, configMap)}`}</Text>
                              </Td>
                            </Tr>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Tbody>
              </Table>
              {provided.placeholder}
            </TableContainer>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>
  );
};
