/* eslint-disable react/jsx-no-comment-textnodes */
import { Stack, Table, Tbody, Td, Th, Thead, Badge, Tr, Input, InputGroup, InputLeftElement, Text, Button } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useAppDispatch, useButtonProps, useEntitlements } from "hooks";

import { useIsUserSearching, useUsersSearch } from "hooks/useUsers";
import { UserDetailModal } from "./modals/UserDetailModal";
import { SearchIcon } from "@chakra-ui/icons";
import { formatDate } from "screens/common/modal/formatters";
import type { User } from "screens/landing/tabs/admin/common";
import { CreateUsersPanel } from "./modals/CreateUsersPanel";
import { createUsersFromAdmin, searchUsers } from "state/users/operations";

export const UsersAdmin = () => {
  const [isOpen, setIsOpen] = useState<"create" | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<null | string>(null);
  const [searchText, setSearchText] = useState("@charli.ai");
  const users = useUsersSearch(searchText, { returnOrganizations: true });
  const dispatch = useAppDispatch();
  const isLoadingUsers = useIsUserSearching();

  const { view_users: hasManageUsersRead, manage_users_write: hasManageUsersWrite } = useEntitlements();
  const commonButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const searchInput = React.createRef<HTMLInputElement>();

  const onClose = useCallback(() => {
    setSelectedUserId(null);
  }, []);

  const userFilter = users.filter((user) => {
    const hasFirstNameMatch = user.firstName?.toLowerCase().includes(searchText.toLowerCase());
    const hasLastNameMatch = user.lastName?.toLowerCase().includes(searchText.toLowerCase());
    const hasEmailMatch = user.email?.toLowerCase().includes(searchText.toLowerCase());
    return hasFirstNameMatch || hasLastNameMatch || hasEmailMatch;
  });
  const selectedUser = users.find((user) => user.id === selectedUserId);

  const handleSubmitNewUsers = async (newUsers: User[]) => {
    const createUsersResponse = await dispatch(createUsersFromAdmin(newUsers));

    if (createUsersResponse.type === createUsersFromAdmin.rejected.type) {
      return false;
    } else {
      dispatch(searchUsers({ search: searchText, returnOrganizations: true }));
      setIsOpen(null);

      return true;
    }
  };

  return (
    <>
      {hasManageUsersRead && (
        <Stack justifyContent={"space-between"} direction="row" width="100%" pb="1rem" mt="0!important" spacing="1rem">
          <Stack direction="row" width="100%">
            <InputGroup size="xs" maxWidth="30rem">
              <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
              <Input
                {...secondaryButtonProps}
                size="xs"
                maxWidth="30rem"
                placeholder={"Filter by name or email..."}
                ref={searchInput}
                defaultValue={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </InputGroup>
            <Text lineHeight={"1.5rem"} fontSize="xs">
              filtering {users.length} users
            </Text>
          </Stack>
          {hasManageUsersWrite && (
            <Button onClick={() => setIsOpen("create")} width="6rem" {...commonButtonProps}>
              Add User
            </Button>
          )}
        </Stack>
      )}
      <Table fontSize={"sm"} variant="simple">
        <Thead>
          <Tr>
            <Th px="0" py=".5rem">
              Name
            </Th>
            <Th px="0" py=".5rem">
              Organization
            </Th>
            <Th px="0" py=".5rem">
              Creation date
            </Th>
            <Th px="0" py=".5rem">
              Email address
            </Th>
            <Th px="0" py=".5rem">
              Verified status
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {userFilter.map((user) => (
            <Tr
              cursor="pointer"
              key={user.id}
              onClick={async () => {
                setSelectedUserId(user.id);
              }}>
              <Td px="0" py=".5rem">
                {`${user.firstName} ${user.lastName}`.trim()}
              </Td>
              <Td px="0" py=".5rem">
                {user.organizations.length > 0 ? user.organizations.map((org) => org.organizationName).join(", ") : "n/a"}
              </Td>
              <Td px="0" py=".5rem" minWidth={"16rem"}>
                {user.creationDate && formatDate(new Date(user.creationDate))}
              </Td>
              <Td px="0" py=".5rem">
                {user.email}
              </Td>
              <Td px="0" py=".5rem">
                <Badge colorScheme={!user.isEmailVerified ? "red" : "gray"}>{String(user.isEmailVerified)}</Badge>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {selectedUser && <UserDetailModal user={selectedUser} isOpen={!!selectedUser} onClose={onClose} />}
      <CreateUsersPanel
        isLoadingUsers={isLoadingUsers}
        isOpen={isOpen === "create"}
        onClose={() => setIsOpen(null)}
        onSubmitNewUsers={handleSubmitNewUsers}
      />
    </>
  );
};
