import { Box, Button, Text, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import DOMPurify from "dompurify";
import marked from "marked";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import ECHighlighter from "react-ec-highlighter";

interface Props {
  summary?: string;
  summaryTitle?: string;
  noSummaryMessage?: string;
  textTruncated?: boolean;
  searchPhrase?: string;
}

export const Summary: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  summary,
  summaryTitle,
  textTruncated = false,
  noSummaryMessage,
  searchPhrase,
}) => {
  const secondaryTextColor = useColorModeValue("primary.darkGray", "gray.400");
  const parsedMarkdown = marked(summary || "");
  const purifiedHtml = DOMPurify.sanitize(parsedMarkdown);
  const buttonColor = useColorModeValue("primary.default", "gray.500");
  const { onSummarizePanelOpen } = useAddToCharliContext();
  const hasLineBreaks = purifiedHtml?.includes("<br>");

  return (
    <Box>
      {searchPhrase && summary && (
        <Text wordBreak="break-word" fontSize="sm" className="file-name" textColor={secondaryTextColor}>
          <ECHighlighter searchPhrase={searchPhrase} text={summary} />
        </Text>
      )}
      {!searchPhrase && summary && (
        <>
          {summaryTitle && (
            <Text fontSize="sm" color={secondaryTextColor} fontWeight="bold" mb="0.2rem">
              {summaryTitle}
            </Text>
          )}
          {textTruncated && hasLineBreaks ? (
            <Box fontWeight="normal" fontSize="sm" color={secondaryTextColor}>
              <Text noOfLines={10}>{summary}</Text>
              <Button mt="1rem" onClick={onSummarizePanelOpen} height="100%" variant="link" cursor="pointer" size="sm" color={buttonColor}>
                View Full Summary
              </Button>
            </Box>
          ) : (
            <Box
              maxHeight="40rem"
              color={secondaryTextColor}
              overflowY="auto"
              dangerouslySetInnerHTML={{ __html: purifiedHtml.replace(/<br>/g, "<p>") }}
              css={{
                fontSize: "0.85rem",
                blockquote: {
                  background: "#f9f9f9",
                  marginTop: "1.25rem !important",
                  marginBottom: "1.25rem !important",
                },
                p: {
                  textAlign: "left",
                  display: "block",
                },
                "blockquote p": {
                  display: "inline",
                  color: "#555555",
                  fontStyle: "italic",
                },
              }}></Box>
          )}
        </>
      )}
    </Box>
  );
};
