import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteCopy, upsertCopy as upsertCopyAPI, getCopies } from "api/copies";
import type { CopyValue } from "api/copies/models/Copy";
import type { CopyKey } from "api/copies/models/CopyKey";

export const downloadAllCopies = createAsyncThunk("copies/download-all", async () => {
  return getCopies();
});

export const upsertCopy = createAsyncThunk("copies/upsert", async ({ key, value }: { key: CopyKey; value: CopyValue }) => {
  return upsertCopyAPI(key, value);
});

export const removeCopy = createAsyncThunk("copies/remove", async (key: CopyKey) => {
  return deleteCopy(key);
});
