import React, { useState, useRef, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useColorModeValue } from "@chakra-ui/react";

interface MarkdownEditorProps {
  noteText?: string;
  placeholderText?: string;
  setNoteText: (note: string) => void;
  setToBeDeletedNote?: (value: string) => void;
  focusEditor?: boolean;
}

export default function MarkdownEditor(props: MarkdownEditorProps) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const editorRef = useRef<Editor>(null);
  const placeholder = props.placeholderText ? props.placeholderText : "Add notes here";
  const borderColor = useColorModeValue("lightgray", "darkgray");
  const toolbarColor = useColorModeValue("#FFF", "#191f23");

  useEffect(() => {
    if (props.focusEditor && editorRef) {
      editorRef.current?.focusEditor();
    }

    if (props.noteText) {
      const contentState = ContentState.createFromBlockArray(convertFromHTML(props.noteText).contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);

      // Set the EditorState, move cursor to the end of the note content
      if (editorRef.current && props.focusEditor) {
        const newEditorState = EditorState.moveSelectionToEnd(editorState);
        setEditorState(newEditorState);
        editorRef.current?.focusEditor();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.noteText === "") {
      setEditorState(EditorState.createEmpty());
    }
  }, [props.noteText]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const editorHtmlValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (editorHtmlValue !== "<p></p>") props.setNoteText(editorHtmlValue);
  };

  return (
    <Editor
      editorClassName={`note-editor ${
        editorState.getCurrentContent().getBlockMap().first().getType() !== "unstyled" ? "hide-placeholder" : ""
      }`}
      placeholder={placeholder}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      ref={editorRef}
      editorStyle={{
        borderBottom: `1px solid ${borderColor}`,
        fontSize: "14px",
        paddingLeft: "0",
        paddingRight: "1rem",
        lineHeight: "1.5rem",
      }}
      toolbarStyle={{ border: "none", paddingLeft: "0", backgroundColor: toolbarColor }}
      toolbar={{
        options: ["inline", "list"],
        inline: {
          options: ["bold", "italic", "underline", "strikethrough"],
        },
      }}
    />
  );
}
