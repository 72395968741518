import type { Static } from "runtypes";
import { Array, Record } from "runtypes";
import { SharedByMe } from "./SharedByMe";
import { SharedWithMe } from "./SharedWithMe";

export const ShareHistoryResponse = Record({
  sharedByMe: Array(SharedByMe),
  sharedWithMe: Array(SharedWithMe),
});

export type ShareHistoryResponseType = Static<typeof ShareHistoryResponse>;
