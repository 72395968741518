import React, { useRef, useState } from "react";
import {
  Box,
  Circle,
  useColorModeValue,
  Button,
  Text,
  Center,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useOutsideClick,
  useBreakpointValue,
  PopoverCloseButton,
  PopoverHeader,
} from "@chakra-ui/react";
import { useNotifications } from "hooks";
import { BellIcon } from "@chakra-ui/icons";
import { NotificationTile } from "../tabs/notifications/NotificationTile";
import { useNavigate } from "react-router-dom";

export const NotificationsMenu = () => {
  const hoverColor = useColorModeValue("primary.default", "gray.700");
  const buttonColor = useColorModeValue("gray.400", "gray.800");
  const notificationColor = useColorModeValue("white", "gray.800");
  const { actionNotification, dismissNotification, newNotifications, dismissedNotAcceptedNotifications } = useNotifications();
  const actionableNotifications = newNotifications;
  const navigate = useNavigate();
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef<HTMLInputElement>(null);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  useOutsideClick({
    ref: popoverRef,
    handler: () => {
      setShowPopover(false);
    },
  });

  return (
    <Box>
      <Popover isLazy isOpen={showPopover} placement="bottom-end" trigger="hover" offset={[0, 5]}>
        <PopoverTrigger>
          <Center
            className="ch-notification-menu-button"
            onClick={() => setShowPopover(!showPopover)}
            cursor="pointer"
            color={buttonColor}
            _hover={{ color: hoverColor }}
            _active={{ backgroundColor: "none" }}>
            <Box position="relative" onClick={() => setShowPopover(!showPopover)}>
              <BellIcon boxSize="1.6rem" onClick={() => setShowPopover(!showPopover)} />
              {actionableNotifications.length > 0 && (
                <Circle
                  id="ch-notification-menu-button-indicator"
                  position="absolute"
                  left="16px"
                  bottom="0"
                  size="17px"
                  bg={dismissedNotAcceptedNotifications.length > 0 ? "orange" : "green"}>
                  <Text p="4px" fontWeight={"bold"} fontSize="12px" color={notificationColor}>
                    1
                  </Text>
                </Circle>
              )}
            </Box>
          </Center>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverCloseButton zIndex={1} />
          <PopoverHeader fontSize={"md"}>Notifications</PopoverHeader>
          <PopoverBody p="0">
            <Box
              ref={popoverRef}
              p="1rem"
              width={isMobile ? "100vw" : "100%"}
              minWidth="20rem"
              maxWidth={isMobile ? "100vw" : "30rem"}
              maxHeight={"16rem"}>
              {actionableNotifications.length > 0 && (
                <Box display="flex" justifyContent={"flex-end"} width="100%">
                  <Button
                    variant="link"
                    cursor="pointer"
                    onClick={() => {
                      setShowPopover(false);
                      navigate(`/notifications`);
                    }}
                    size="sm"
                    color={hoverColor}>
                    <Text>View All</Text>
                  </Button>
                </Box>
              )}
              <>
                {actionableNotifications.length > 0 ? (
                  actionableNotifications.map((notification) => {
                    return (
                      <Box pb="3px" key={notification.id}>
                        <NotificationTile
                          notification={notification}
                          onDismiss={() => {
                            setShowPopover(false);
                            dismissNotification(notification.id);
                          }}
                          onAction={() => {
                            setShowPopover(false);
                            actionNotification(notification.id);
                          }}
                          source="notifications_menu"
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Box>
                    <Text fontSize={"sm"} color="gray.500">
                      No new notifications
                    </Text>
                  </Box>
                )}
              </>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
