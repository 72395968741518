import { prependProxyBaseUrl, request } from "api/request";

// Define the response type (replace `any` with your actual data type)
export interface TickerQuote {
  market_cap: string;
  ticker_symbol: string;
  previous_close: string;
  price: string;
  trading_date: string;
  percent_change: string;
  timestamp: string;
}

export interface StockTickerData {
  status: string;
  message: string;
  ticker_quote: TickerQuote;
}
const STOCK_TICKER_ENDPOINT = prependProxyBaseUrl("/api/stock-data");

export async function getStockTickerData(companyTicker: string, companyStockExchange: string): Promise<StockTickerData | null> {
  if (companyTicker === "N/A") return null;

  try {
    const response: StockTickerData = await request()
      .url(`${STOCK_TICKER_ENDPOINT}/?company_ticker=${companyTicker}&company_stock_exchange=${companyStockExchange}`)
      .get()
      .json();
    return response;
  } catch (error) {
    console.error(`Failed to get stock ticker data: ${error}`);
    return null;
  }
}
