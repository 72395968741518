import React, { useCallback } from "react";
import { Badge, Box, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react";

import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useEntitlementConfig } from "hooks/useEntitlementConfigs";
import capitalize from "lodash/capitalize";

interface IProps {
  code: string | null;
  isOpen: boolean;
  onClose: () => void;
}

export const EntitlementConfigModal = (props: IProps) => {
  const { code, isOpen, onClose } = props;
  const entitlement = useEntitlementConfig(code);

  const titleColor = useColorModeValue("gray.900", "gray.500");

  const renderOrganizations = useCallback(() => {
    if (!entitlement) {
      return;
    }

    const organizations = entitlement.organizations ?? [];

    if (organizations.length > 0) {
      return organizations.map((org) => (
        <Box borderWidth="2px" borderRadius="lg" padding="1rem" key={org.id}>
          <Stack direction="row" alignItems="center">
            <Text color={titleColor} fontSize="md" minWidth="8rem">
              Name:
            </Text>
            <Text>{org.name}</Text>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Text color={titleColor} fontSize="md" minWidth="8rem">
              Plan:
            </Text>
            <Text>{capitalize(String(org.planCode))}</Text>
          </Stack>
        </Box>
      ));
    } else {
      return <Text>No organizations configured</Text>;
    }
  }, [entitlement, titleColor]);

  const renderUsers = useCallback(() => {
    if (!entitlement) {
      return <></>;
    }

    const users = entitlement.users ?? [];

    if (users.length > 0) {
      return users.map((user) => (
        <Box borderWidth="2px" borderRadius="lg" padding="1rem" key={user.email}>
          <Stack>
            <Text>
              Name:{" "}
              {(user.firstName ?? "")
                .concat(" ")
                .concat(user.lastName ?? "")
                .trim()}
            </Text>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Text>{user.email}</Text>
          </Stack>
        </Box>
      ));
    } else {
      return <Text>No users configured</Text>;
    }
  }, [entitlement]);

  const renderPlans = useCallback(() => {
    return Object.entries(entitlement?.plans || [])
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([code, plan]) => (
        <Box borderWidth="2px" borderRadius="lg" padding="1rem" key={code}>
          <Stack direction="row" alignItems="center">
            <Text color={titleColor} fontSize="md" minWidth="8rem">
              Plan:
            </Text>
            <Text>{capitalize(String(code))}</Text>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Text color={titleColor} fontSize="md" minWidth="8rem">
              Enabled:
            </Text>
            <Badge colorScheme={plan.enabled ? "green" : "gray"}>{String(plan.enabled)}</Badge>
          </Stack>
        </Box>
      ));
  }, [entitlement?.plans, titleColor]);

  if (!entitlement) return <></>;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`Entitlement: ${entitlement.code}`}>
      <Wizard>
        <PanelStep>
          <Stack spacing={8}>
            <Stack my="1rem">
              <Stack direction="row" alignItems="center">
                <Text color={titleColor} fontSize="md" minWidth="8rem">
                  Code:
                </Text>
                <Badge>{entitlement.code}</Badge>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Text color={titleColor} fontSize="md" minWidth="8rem">
                  Name:
                </Text>
                <Text fontSize="md">{entitlement.name}</Text>
              </Stack>
              {entitlement.description && (
                <Stack direction="row" alignItems="center">
                  <Text color={titleColor} fontSize="md" minWidth="8rem">
                    Description:
                  </Text>
                  <Text fontSize="md">{entitlement.description}</Text>
                </Stack>
              )}
            </Stack>

            {Object.values(entitlement.plans).length >= 0 && (
              <Box>
                <Text color={titleColor} marginBottom={4} fontSize="md">
                  Plans
                </Text>
                <SimpleGrid columns={2} spacingX="1.5rem" spacingY="1.5rem">
                  {renderPlans()}
                </SimpleGrid>
              </Box>
            )}

            <Box>
              <Text color={titleColor} marginBottom={4} fontSize="md">
                Users
              </Text>
              <SimpleGrid columns={2} spacingX="1.5rem" spacingY="1.5rem">
                {renderUsers()}
              </SimpleGrid>
            </Box>
            <Box pb={10}>
              <Text color={titleColor} marginBottom={4} fontSize="md">
                Organizations
              </Text>
              <SimpleGrid columns={2} spacingX="1.5rem" spacingY="1.5rem">
                {renderOrganizations()}
              </SimpleGrid>
            </Box>
          </Stack>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
