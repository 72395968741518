import { Box, Text, Stack, useToast, useBreakpointValue, Badge } from "@chakra-ui/react";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { ConversationContext } from "screens/thread/ConversationContext";
import { getStatusColor } from "screens/common/components/WorkflowSummary";
import { sendMessage } from "state/websocket/operations";
import { useDispatch } from "react-redux";
import { ToastMessageContent } from "screens/common/components";
import {
  useAllProjectConfigsIntents,
  useCollectionKey,
  useCollectionWorkflowIdInProgress,
  useConfigMap,
  useFeatureFlags,
  useGetViewConfig,
  useLatestCollectionWorkflowId,
  useMainCollectionWorkflowId,
  useTileProps,
} from "hooks";
import { useNavigate } from "react-router-dom";
import { useWorkflowCompletionDateOrNow, useWorkflowKey } from "hooks/useWorkflows";
import { formatDistanceToNow } from "date-fns";
import { WorkflowProgressBar } from "./WorkflowProgressBar";
import {
  useAggregateCollectionWorkflowsIntentStatus,
  useCollectionWorkflowsIntentStatusList,
  useCollectionWorkflowsNewestStatusByIntent,
  useWorkflowsIntents,
} from "hooks/useProgressSteps";
import type { WorkflowStatus } from "api/workflows/models/WorkflowStatus";
import capitalize from "lodash/capitalize";
import { formatDate } from "screens/common/modal/formatters";
import { Popover } from "react-tiny-popover";
import { WorkflowStatusIcon } from "screens/common/components/WorkflowStatusIcon";
import { ChildWorkflowProgressBar } from "./ChildWorkflowProgressBar";
import { usePausedWorkflowModal } from "screens/collection/views/ProjectActions/PausedWorkflowModal";
import { MilestonesStepper } from "./MilestonesStepper";

export interface ContainerProps {
  collectionId: string;
  size?: "xs" | "sm" | "md";
  isProjectView?: boolean;
  maxWidth?: string;
  hideProgressBar?: boolean;
}

interface WorkflowProgressStepperProps extends ContainerProps {
  intents: string[];
  conversationId?: string;
}

const StatusStack = ({
  status,
  intent,
  collectionId,
}: {
  status: WorkflowStatus | "not_started";
  intent: string;
  collectionId: string;
}) => {
  const { count, latestCreationDate, latestCompletionDate } = useAggregateCollectionWorkflowsIntentStatus(collectionId, intent, status);

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Text fontSize={"xs"} fontWeight="semibold">{`${intent}`}</Text>
        {count && <Badge>{count}</Badge>}
      </Stack>
      <Box pl="1rem" borderLeft="1px solid #718096">
        <Stack direction="row" align="center">
          <Text fontSize="xs">Status:</Text>
          <Text fontSize="xs" as="em">
            {capitalize(status).split("_").join(" ")}
          </Text>
        </Stack>
        <Stack direction="row" align="center">
          <Text fontSize="xs">Started:</Text>
          <Text fontSize="xs" as="em">{`${
            latestCreationDate && status !== "not_started" ? formatDate(new Date(latestCreationDate), "dd MM yyyy hh:mm") : "--"
          }`}</Text>
        </Stack>
        <Stack direction="row" align="center">
          <Text fontSize="xs">Finished:</Text>
          <Text fontSize="xs" as="em">{`${
            latestCompletionDate && status !== "not_started" ? formatDate(new Date(latestCompletionDate), "dd MM yyyy hh:mm") : "--"
          }`}</Text>
        </Stack>
      </Box>
    </Stack>
  );
};

const ProgressStepper = ({
  intent,
  isOpen,
  onClose,
  onOpen,
  workflowId,
  isProjectView,
  onClickAction,
  collectionId,
}: {
  intent: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  workflowId: string | undefined;
  isProjectView: boolean;
  onClickAction: (intent: string, status: WorkflowStatus) => void;
  collectionId: string;
}) => {
  const commonTileProps = useTileProps();
  const maybeStatus = useCollectionWorkflowsNewestStatusByIntent(collectionId, intent);
  const intentStatusList = useCollectionWorkflowsIntentStatusList(collectionId, intent);

  const renderWorkflowStatusSteps = useCallback(() => {
    return intentStatusList.map((status) => (
      <StatusStack collectionId={collectionId} status={status} intent={intent} key={`${workflowId}-${intent}-${status}`} />
    ));
  }, [workflowId, collectionId, intent, intentStatusList]);

  const getRelevantStatus = useCallback((status: WorkflowStatus | undefined): WorkflowStatus | "not_started" => {
    if (!status) {
      return "not_started";
    } else {
      return status;
    }
  }, []);

  return (
    <Popover
      isOpen={isOpen}
      positions={["top", "left"]}
      padding={10}
      reposition={false}
      onClickOutside={() => onClose}
      content={() => (
        <Stack {...commonTileProps}>
          {intentStatusList.length > 0 ? (
            renderWorkflowStatusSteps()
          ) : (
            <StatusStack collectionId={collectionId} status={"not_started"} intent={intent} />
          )}
        </Stack>
      )}>
      <Box
        p="3px"
        borderRadius={"full"}
        backgroundColor={`${getStatusColor(getRelevantStatus(maybeStatus))}.200`}
        border="none"
        height={isProjectView ? "2rem" : "unset"}
        className="ch-workflow-step"
        cursor="pointer"
        textAlign="left"
        onMouseEnter={() => onOpen()}
        onMouseLeave={() => onClose()}
        onClick={(event) => {
          event.stopPropagation();
          onClickAction(intent, getRelevantStatus(maybeStatus));
        }}>
        <Stack direction="row" justifyContent="left" alignItems="left">
          <WorkflowStatusIcon status={getRelevantStatus(maybeStatus)} />
        </Stack>
      </Box>
    </Popover>
  );
};

const WorkflowProgressStepper = (props: WorkflowProgressStepperProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { collectionId, intents, conversationId, isProjectView, hideProgressBar = true, maxWidth } = props;
  const { onConversationOpen, setConversationId } = useContext(ConversationContext);
  const toast = useToast();
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const configMap = useConfigMap();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const currentWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const mainWorkflowId = useMainCollectionWorkflowId(collectionId);
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  const projectRoute = useGetViewConfig("route", collectionType, configMap);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const { onOpen: onOpenPausedWorkflowModal } = usePausedWorkflowModal();
  const inProgressWorkflowId = useCollectionWorkflowIdInProgress(collectionId);

  const onClickAction = useCallback(
    (intent: string, status: WorkflowStatus) => {
      if (!conversationId) return;

      const processAction = (intentAction?: string, entities?: { entity: string; value: unknown }[]) => {
        if (!conversationId) return;
        dispatch(
          sendMessage({
            conversationId,
            intent: intentAction,
            entities: entities,
            disableDebugging: true,
            disableTesting: true,
          })
        );
        setConversationId(conversationId);
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={`Rerunning ${intentAction?.replace(/_+/g, " ")} step now. You can open the conversation by clicking this message`}
              onClick={() => {
                onConversationOpen();
                onClose();
              }}
              onClose={onClose}
            />
          ),
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      };

      const getPath = () => {
        if (projectRoute) {
          return navigate(`/${projectRoute}/${collectionId}`);
        } else {
          onConversationOpen();
        }
      };

      switch (status) {
        case "clarification_needed":
          setConversationId(conversationId);
          onConversationOpen();
          break;
        case "failed":
        case "error":
          processAction("/retry", [{ entity: "collection_id", value: collectionId }]);
          break;
        case "cancelled":
        case "denied_intent_confirmation":
          processAction(`/${intent}`, [{ entity: "collection_id", value: collectionId }]);
          break;
        default:
          getPath();
      }
    },
    [collectionId, conversationId, dispatch, navigate, onConversationOpen, projectRoute, setConversationId, toast]
  );

  const { new_progress_stepper: hasNewProgressStepper } = useFeatureFlags();

  return (
    <>
      <Stack
        className="ch-workflow-stepper"
        direction="row"
        flexWrap="wrap"
        spacing={isMobile ? "unset" : ".3rem"}
        alignContent="flex-end"
        borderRadius={"full"}>
        <Stack justifyContent={"end"} width="100%">
          <Stack alignItems={"center"} direction="row" justifyContent={"end"}>
            {inProgressWorkflowId && <ChildWorkflowProgressBar workflowId={inProgressWorkflowId} />}
            {hasNewProgressStepper && mainWorkflowId ? (
              <MilestonesStepper collectionId={collectionId} workflowId={mainWorkflowId} isProjectView={!!isProjectView} />
            ) : (
              intents.map((intent, index) => (
                <ProgressStepper
                  collectionId={collectionId}
                  isOpen={hoveredIndex === index}
                  onClose={() => setHoveredIndex(-1)}
                  key={`step-${intent}-${currentWorkflowId}`}
                  intent={intent}
                  workflowId={currentWorkflowId}
                  onOpen={() => setHoveredIndex(index)}
                  isProjectView={!!isProjectView}
                  onClickAction={(intent, status) => {
                    if (status === "failed_checkstop") {
                      onOpenPausedWorkflowModal(collectionId);
                    } else {
                      onClickAction(intent, status);
                    }
                  }}
                />
              ))
            )}
          </Stack>
          {inProgressWorkflowId && !hideProgressBar && (
            <Stack direction={"row"} spacing="1rem" justifyContent={"end"} width="100%">
              <WorkflowProgressBar
                maxWidth={maxWidth}
                workflowId={inProgressWorkflowId}
                size="compact"
                lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
                  addSuffix: true,
                  includeSeconds: false,
                })}
                hideCompletedInDate
                conversationId={collectionConversationId}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export const WorkflowProgressStepperContainer = (props: ContainerProps) => {
  const { collectionId } = props;
  const configMap = useConfigMap();
  const collectionType = useCollectionKey(collectionId, "collectionType");

  const workflowIntentFilters = useGetViewConfig("workflowIntentFilters", collectionType, configMap);

  const workflowIntentWithCommonFilters = useMemo(() => {
    const commonFilters = ["generate_collection_report"];

    return [...(workflowIntentFilters ? workflowIntentFilters : []), ...commonFilters];
  }, [workflowIntentFilters]);

  const includedIntentsForStepper = useAllProjectConfigsIntents();
  const mainWorkflowId = useMainCollectionWorkflowId(collectionId, includedIntentsForStepper);
  const conversationId = useWorkflowKey(mainWorkflowId, "conversationId");

  const workflowsIntents = useWorkflowsIntents(collectionId, workflowIntentWithCommonFilters);

  const combinedIntents = useMemo(() => {
    const workflowIntentMap = workflowsIntents.reduce<{ [intent: string]: string }>((accum, intent) => {
      accum[intent] = intent;
      return accum;
    }, {});

    return (workflowIntentFilters || []).map((intent) => {
      return workflowIntentMap[intent] || intent;
    });
  }, [workflowsIntents, workflowIntentFilters]);

  return <WorkflowProgressStepper {...props} intents={combinedIntents} conversationId={conversationId} />;
};
