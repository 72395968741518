import { useCommandSuggestions, useConversation, useItemSelector, useProjectParams } from "hooks";
import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConversationContext } from "screens/thread/ConversationContext";
import type { RootState } from "state/rootReducer";
import { sendMessage as sendFrame } from "state/websocket/operations";
import type { CharliUI, UpdateDataCallback } from "types/charliui";
import { v4 as uuid } from "uuid";
import { InputBarDropzoneContext } from "./InputBarDropzoneContext";

type MergeStrategy = "merge" | "replace";

const COMMAND_TO_INTENT_MAPPING: { command: string; intent: string }[] = [
  { command: "/share", intent: "/share" },
  { command: "/archive", intent: "/archive" },
  { command: "/restore", intent: "/restore" },
  { command: "/summarize", intent: "/summarize" },
  { command: "/store_collection", intent: "/store_collection" },
  { command: "/upload_to_astrella", intent: "/upload_to_astrella" },
  { command: "/generate_comprehensive_summary", intent: "/generate_comprehensive_summary" },
];

interface InputBarParameters {
  conversationId?: string;
  collectionId?: string;
  areAttachmentsDisabled: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  defaultMessageText?: string;
  showConversationModal?: boolean;
}

/**
 * Stateful logic for <InputBar />
 *
 * The purpose of extracting this into a hook is for the readability of the <InputBar /> component, rather than reusability.
 * Therefore, this is stored adjacent to the component (instead of src/hooks) and is not intended for use anywhere else in the application.
 */
export function useInputBar(params: InputBarParameters) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { inputRef, conversationId, collectionId, areAttachmentsDisabled, defaultMessageText } = params;

  // Input bar state
  const isLoading = useSelector((state: RootState) => state.conversation.isLoading);
  const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const [messageText, setMessageText] = useState(defaultMessageText ?? "");
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const { files, resetFiles, isUploadInProgress, didUploadFail } = useContext(InputBarDropzoneContext);
  const { selectedItems, focusedItem } = useItemSelector();
  const [showCommandLineSuggestions, setShowCommandLineSuggestions] = useState(false);
  const { parentRoute, isPortfolios, isProjects, projectId, contentId } = useProjectParams();
  const { isConversationOpen } = useContext(ConversationContext);
  const conversationState = useConversation(conversationId || contentId || projectId || "");
  const items: typeof selectedItems = useMemo(() => {
    if (focusedItem) {
      return {
        [focusedItem.id]: { type: focusedItem.type },
      };
    } else {
      return selectedItems;
    }
  }, [focusedItem, selectedItems]);

  // Effects
  useEffect(() => {
    // Prevent message submission if state is invalid (eg. invalid message, has attachment that failed to upload, etc)
    setIsSendDisabled(!isConnected || isLoading || didUploadFail || isUploadInProgress);
  }, [isConnected, isLoading, didUploadFail, isUploadInProgress, messageText, files, areAttachmentsDisabled]);

  useEffect(() => {
    // Refocus input bar when websocket (re)connects
    if (isConnected && inputRef) inputRef.current?.focus();
  }, [inputRef, isConnected]);

  // Utility functions
  const { mergeSuggestionWithMessage } = useCommandSuggestions();

  const focusAndMoveCursorToEnd = useCallback(() => {
    // Capture ref so it's available in our closure
    const ref = inputRef?.current;
    // Focus next tick (after input is edited)
    setTimeout(() => {
      if (ref) {
        ref.focus();
        ref.selectionStart = ref.value.length || 0;
        ref.selectionEnd = ref.value.length || 0;
        ref.scrollLeft = ref.scrollWidth;
      }
    }, 0);
  }, [inputRef]);

  const validateMessageText = (messageText: string) => {
    if ((isProjects || isPortfolios || parentRoute === "library") && !contentId && isConversationOpen) {
      // if there is no >collection_id in event.target.value then add it
      if (!messageText.includes(">collection_id") && conversationState.conversationState !== "action") {
        const currentCollectionId = collectionId && collectionId.length > 0 ? collectionId : projectId;
        if (currentCollectionId && messageText.length > 0) {
          return `${messageText} >collection_id ${currentCollectionId}`;
        } else {
          return messageText;
        }
      }
      return messageText;
    }
    return messageText;
  };

  // Event handlers
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const messageText = (event.target as HTMLInputElement).value;

    if (event.key === "Enter") {
      sendMessage(messageText);
    }

    if (event.key === "Tab" && document.querySelector("#chat-suggestions button")) {
      // If a suggestion button is being rendered, press it.
      (document.querySelector("#chat-suggestions button") as HTMLButtonElement).click();
      event.preventDefault();
    }

    return true;
  };

  useEffect(() => {
    if (!messageText.includes("/")) {
      setShowCommandLineSuggestions(false);
    } else {
      setShowCommandLineSuggestions(true);
    }
  }, [messageText]);

  const onSuggestionClick = useCallback(
    (text: string, strategy: MergeStrategy = "merge") => {
      setMessageText(mergeSuggestionWithMessage(text, messageText, strategy));
      focusAndMoveCursorToEnd();
    },
    [focusAndMoveCursorToEnd, mergeSuggestionWithMessage, messageText]
  );

  const sendMessage = useCallback(
    (text?: string) => {
      if (isSendDisabled) return;
      const resolvedText = validateMessageText(text || "");
      const resolvedConversationId = conversationId ?? uuid();
      const intentForCommand = COMMAND_TO_INTENT_MAPPING.find((mapping) => text?.startsWith(mapping.command))?.intent;
      const updateDataCallback: UpdateDataCallback | undefined = collectionId
        ? {
            type: "update_data_callback",
            includeInResponse: true,
            body: { items: [{ type: "collection", id: collectionId }] },
          }
        : undefined;
      const data: CharliUI[] = updateDataCallback ? [updateDataCallback] : [];
      if (Object.keys(items).length > 0 && intentForCommand) {
        // If submitting items selected from a canvas
        const entities: { entity: string; value: unknown }[] = Object.entries(items).map(([id, itemProperties]) => {
          return { entity: itemProperties.type, value: id };
        });
        dispatch(
          sendFrame({
            disableDebugging: true,
            conversationId: resolvedConversationId,
            intent: resolvedText, // remove the slash at the beginning of the intent
            entities: entities,
            datum: data,
          })
        );
      } else {
        // If doing anything else
        dispatch(
          sendFrame({
            disableDebugging: true,
            conversationId: resolvedConversationId,
            intent: resolvedText,
            files: files,
            datum: data,
          })
        );
      }

      if (files.length) resetFiles();
      setMessageText("");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSendDisabled, conversationId, collectionId, items, navigate, params.showConversationModal, files, resetFiles, dispatch]
  );

  return {
    messageText,
    setMessageText,
    focusAndMoveCursorToEnd,
    sendMessage,
    onSuggestionClick,
    onKeyDown,
    isSendDisabled,
    isConnected,
    showCommandLineSuggestions,
  };
}
