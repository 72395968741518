import { createAsyncThunk } from "@reduxjs/toolkit";
import { getContent } from "api/content";
import { ContentDetails } from "types/content/ContentDetails";
import type { CollectionMetadataChecks } from "./reducer";

export function getCollectionCheckKey(contentDetail: ContentDetails): keyof CollectionMetadataChecks | undefined {
  if (contentDetail.manualTags.includes("company_logo")) {
    return "companyLogoId";
  } else if (contentDetail.manualTags.includes("project_landing_thumbnail")) {
    return "landingThumbnailId";
  } else if (contentDetail.name?.toLowerCase() === "stock equity data") {
    return "stockEquityDataId";
  } else if (contentDetail.dynamicData?.category === "company_details") {
    return "companyDetailsId";
  } else {
    return undefined;
  }
}

export const downloadCollectionMetadata = createAsyncThunk(
  "collections/download-collection-metadata",
  async ({ metadataIds }: { metadataIds: string[]; collectionId: string }) => {
    const contents = await getContent(metadataIds);

    if (contents !== "error") {
      const contentDetailsMap: Record<string, ContentDetails> = {};
      const collectionChecks: CollectionMetadataChecks = {
        companyLogoId: "",
        stockEquityDataId: "",
        companyDetailsId: "",
      };

      for (let i = 0; i < contents.length; i++) {
        const content = contents[i];
        const contentDetail = new ContentDetails(content);

        const maybeCheckKey = getCollectionCheckKey(contentDetail);
        if (maybeCheckKey) {
          collectionChecks[maybeCheckKey] = contentDetail.id;
          contentDetailsMap[contentDetail.id] = contentDetail;
        }
      }

      return {
        contents: contentDetailsMap,
        checks: collectionChecks,
      };
    } else {
      throw new Error("Failed to download collection metadata");
    }
  }
);
