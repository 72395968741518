import { Button, HStack, VStack, Input, InputGroup, Stack, Text, Icon, IconButton, Tooltip, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useContext, useRef, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { VscClearAll } from "react-icons/vsc";
import { IntegrationsFilterContext } from "./IntegrationsFilterContext";
import { CategorySelector } from "screens/landing/components/CategorySelector";
import { useButtonProps } from "hooks/useCommonProps";
import { HiViewList, HiOutlineViewGrid } from "react-icons/hi";

interface Props {
  category?: string;
}

export const FilterIntegrationsView: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ category }) => {
  const {
    isReconnectFilter,
    setIsReconnectFilter,
    isEnabledFilter,
    setIsEnabledFilter,
    searchText,
    setSearchText,
    isCategoryFilter,
    setIsCategoryFilter,
    isAvailableFilter,
    setIsAvailableFilter,
    isNotEnabledFilter,
    setIsNotEnabledFilter,
    isPreferredFilter,
    setIsPreferredFilter,
    isBetaFilter,
    setIsBetaFilter,
    availableCells,
    totalIntegrations,
    isGroupedByCategory,
    setIsGroupedByCategory,
  } = useContext(IntegrationsFilterContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const secondaryButtonStyle = useButtonProps("sm", "secondary");
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value.replace("#", ""));
  }, 200);
  const selectedBg = useColorModeValue("gray.200", "gray.700");

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  useEffect(() => {
    if (category) setIsCategoryFilter(category);
  }, [category, setIsCategoryFilter]);

  return (
    <Stack
      display={["none", "none", "flex"]}
      backgroundColor="transparent"
      mb="1rem"
      position="relative"
      spacing="1rem"
      className="filter-container">
      <HStack>
        <VStack align="left" flexGrow={1}>
          <InputGroup size="sm">
            <Input
              size="xs"
              ref={inputRef}
              defaultValue={searchText}
              placeholder="Filter by integration Name"
              onChange={(e: { target: { value: unknown } }) => debounced(e.target.value)}
            />
          </InputGroup>
        </VStack>
      </HStack>
      <Stack direction="row" align="flex-end" justify="space-between">
        <Stack direction="row">
          <Button
            key={"is-reconnect"}
            {...secondaryButtonStyle}
            backgroundColor={isReconnectFilter ? selectedBg : "unset"}
            fontWeight="bold"
            _hover={{
              borderColor: isReconnectFilter ? selectedBg : "unset",
            }}
            onClick={() => {
              setIsReconnectFilter(!isReconnectFilter);
            }}>
            <Text>Reconnect</Text>
          </Button>
          <Button
            key={"is-enabled"}
            {...secondaryButtonStyle}
            backgroundColor={isEnabledFilter ? selectedBg : "unset"}
            onClick={() => {
              setIsEnabledFilter(!isEnabledFilter);
            }}>
            <Text>Connected</Text>
          </Button>
          <Button
            key={"is-not-enabled"}
            {...secondaryButtonStyle}
            backgroundColor={isNotEnabledFilter ? selectedBg : "unset"}
            onClick={() => {
              setIsNotEnabledFilter(!isNotEnabledFilter);
            }}>
            <Text>Not Connected</Text>
          </Button>
          <Button
            key={"coming-soon"}
            {...secondaryButtonStyle}
            backgroundColor={isAvailableFilter ? selectedBg : "unset"}
            onClick={() => {
              setIsAvailableFilter(!isAvailableFilter);
            }}>
            <Text>Coming Soon</Text>
          </Button>
          <Button
            key={"beta"}
            {...secondaryButtonStyle}
            backgroundColor={isBetaFilter ? selectedBg : "unset"}
            onClick={() => {
              setIsBetaFilter(!isBetaFilter);
            }}>
            <Text>Beta</Text>
          </Button>
          <Button
            key={"preferred"}
            {...secondaryButtonStyle}
            backgroundColor={isPreferredFilter ? selectedBg : "unset"}
            onClick={() => {
              setIsPreferredFilter(!isPreferredFilter);
            }}>
            <Text>Preferred</Text>
          </Button>
          <CategorySelector currentItem={isCategoryFilter} handleOnChange={setIsCategoryFilter} />
          <Button
            key={"clear_filter"}
            {...secondaryButtonStyle}
            backgroundColor={availableCells.length < totalIntegrations ? selectedBg : "unset"}
            leftIcon={<VscClearAll />}
            onClick={() => {
              setIsReconnectFilter(false);
              setIsAvailableFilter(false);
              setIsEnabledFilter(false);
              setIsCategoryFilter("");
              setIsNotEnabledFilter(false);
              setIsBetaFilter(false);
              setSearchText("");
              setIsPreferredFilter(false);
              if (inputRef.current) inputRef.current.value = "";
            }}>
            <Text>{availableCells.length < totalIntegrations ? `Clear filter (${availableCells.length})` : `Clear filter`}</Text>
          </Button>
        </Stack>
        <Tooltip label={isGroupedByCategory ? "Group by category" : "Change to Full View"}>
          <IconButton
            {...secondaryButtonStyle}
            onClick={() => setIsGroupedByCategory(!isGroupedByCategory)}
            aria-label="Filter"
            icon={<Icon fontSize=".9rem" as={isGroupedByCategory ? HiViewList : HiOutlineViewGrid} />}
          />
        </Tooltip>
      </Stack>
    </Stack>
  );
};
