import { Record, String, Array, Union, Literal, Number, InstanceOf, Unknown } from "runtypes";
import type { Static } from "runtypes";
import { TargetEntity } from "./TargetEntity";

export const Visibility = Union(Literal("public"), Literal("private"), Literal("protected"));

export const Resource = Record({
  urn: String,
  category: String,
  keywords: Array(String),
  visibility: Visibility,
  confidenceThreshold: Number,
  documentConfig: Unknown,
  jsonConfig: Unknown,
  creationDate: Union(InstanceOf(Date), String),
  lastUpdatedDate: Union(InstanceOf(Date), String),
  creationByUserId: String,
  creationByUserName: String,
  lastUpdatedByUserId: String,
  lastUpdatedByUserName: String,
  entities: Array(TargetEntity).optional(),
});

export type Resource = Static<typeof Resource>;
export type Visibility = Static<typeof Visibility>;
