export const formatPath = (path: string | undefined) => {
  if (!path) return;
  // Strip leading backslash if present
  const formattedPath = path.charAt(0) === "/" ? path.substring(1) : path;

  return formattedPath.split("/").join(" > ");
};

export const getFileTypeNameByExtension = (extension?: string) => {
  switch (extension?.toLowerCase()) {
    case "pdf":
      return "PDF";
    case "xls":
    case "xlsx":
    case "xlsm":
    case "csv":
    case "ods":
      return "XLS";
    case "ppt":
    case "pptx":
    case "odp":
      return "PPT";
    case "lnk":
    case "lin":
    case "link":
      return "Link";
    case "doc":
    case "docx":
    case "txt":
    case "odt":
    case "rtf":
    case "md":
    case "vtt":
      return "Doc";
    case "img":
    case "jpg":
    case "jpe":
    case "png":
    case "bmp":
    case "svg":
    case "webp":
      return "Image";
    case "exp":
      return "Expense";
    case "hlp":
      return "Help";
    case "col":
      return "Project";
    case "mp3":
    case "mpeg":
    case "wav":
      return "Audio";
    case "mp4":
    case "gif":
    case "quicktime":
    case "3gpp":
    case "wmv":
    case "avi":
      return "Video";
    case "ltsa":
      return "LTSA";
    case "gis":
      return "GIS";
    case "sec":
      return "SEC";
    case "dyn":
    case "dat":
    case "jso":
    case "json":
      return "DATA";
    default:
      return "Other";
  }
};

export const isAcceptedSummaryAVTypeByExtension = (extension?: string) => {
  switch (extension) {
    case "mp4":
    case "m4v":
    case "mov":
    case "wmv":
    case "avi":
    case "mpg":
    case "wav":
    case "aiff":
    case "flac":
      return true;
    default:
      return false;
  }
};
