import React, { createContext, useState, useMemo, useEffect, useCallback } from "react";
import type { FunctionComponent } from "react";
import type { ContentDetails } from "types/content/ContentDetails";
import { useDisclosure } from "@chakra-ui/react";
import type { AnswerStatus } from "types/question";
import { hasProjectOutput } from "screens/collection/components/utils";

export const ITEM_COUNT = 20;

const defaultThrowError = (): void => {
  throw new Error("Component must be nested inside <ContentFilterContext.Provider />");
};

export const ContentFilterContext = createContext({
  resultsDataCount: undefined as number | undefined,
  setResultsDataCount: (() => {
    defaultThrowError();
  }) as React.Dispatch<React.SetStateAction<number | undefined>>,
  selectedTags: [] as string[],
  setSelectedTags: (tags: string[]): void => {
    defaultThrowError();
  },
  selectedCategories: [] as string[],
  setSelectedCategories: (categories: string[]): void => {
    defaultThrowError();
  },
  selectedResult: {
    questionText: "",
    resultId: "",
    resultStatus: "pinned",
    resultText: "",
    confidence: 0,
    attributionMetadataIds: [] as string[],
    collectionId: "",
    conversationId: "",
    isQuestion: false,
    verifiedStatus: "verified",
    resultType: "answer",
    focus: "",
  },
  setSelectedResult: (result: {
    questionText: string;
    resultId: string;
    resultText: string;
    resultStatus: AnswerStatus;
    confidence: number;
    attributionMetadataIds: string[];
    collectionId: string;
    conversationId: string;
    isQuestion: boolean;
    verifiedStatus: "verified" | "unverified";
    resultType: "answer" | "highlight";
    focus: string;
  }): void => {
    defaultThrowError();
  },
  selectedExtractedContentTypes: [] as string[],
  setExtractedContentTypes: (extractedType: string[]): void => {
    defaultThrowError();
  },
  searchText: "",
  setSearchText: (text: string): void => {
    defaultThrowError();
  },
  endDate: new Date() as Date | undefined,
  setEndDate: (date: Date | undefined): void => {
    defaultThrowError();
  },
  startDate: new Date() as Date | undefined,
  setStartDate: (date: Date | undefined): void => {
    defaultThrowError();
  },
  availableContent: [] as ContentDetails[],
  shouldExcludeContentTypes: false,
  setShouldExcludeContentTypes: (value: boolean): void => {
    defaultThrowError();
  },
  itemCount: 5,
  setItemCount: (value: number): void => {
    defaultThrowError();
  },
  hasEverySelectedTag: false,
  setHasEverySelectedTag: (value: boolean): void => {
    defaultThrowError();
  },
  resetFilter: defaultThrowError,
  isFilterOpen: false,
  onFilterToggle: defaultThrowError,
  onFilterOpen: defaultThrowError,
  onFilterClose: defaultThrowError,
  totalResources: 0,
  setTotalResources: (value: number): void => {
    defaultThrowError();
  },
});

interface Props {
  contentItems?: ContentDetails[];
}

const extractExtractedContentFromCell = (content: ContentDetails, exclude: boolean): string[] => {
  if (!content) return [];

  const tokens: string[] = [
    { key: "notes", condition: content.notes.length > 0 },
    {
      key: "highlights",
      condition: content.extractedHighlights && content.extractedHighlights.length > 0,
    },
    {
      key: "summary",
      condition: content.summaryDetails && content.summaryDetails.data[0]?.value.length > 0,
    },
  ]
    .filter(({ condition }) => (exclude ? !condition : condition))
    .map(({ key }) => key);

  return tokens;
};

const extractTokensFromCell = (content: ContentDetails) => {
  if (!content) return [];

  const tokens = [
    content.name,
    ...(content.manualTags || []),
    ...(content.autoTags || []),
    ...(content.notes || []),
    content.timeCreated,
    ...(content.extractedHighlights ? content.extractedHighlights.flatMap((highlight) => [highlight.highlights, highlight.topic]) : []),
    content.summaryDetails?.data[0]?.value,
    content.category,
  ];

  return tokens.filter((token) => token && token.length > 0);
};

export const ContentFilterContextProvider: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  children,
  contentItems,
}) => {
  const [resultsDataCount, setResultsDataCount] = useState<number | undefined>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [hasEverySelectedTag, setHasEverySelectedTag] = useState(true);
  const [selectedExtractedContentTypes, setExtractedContentTypes] = useState<string[]>([]);
  const [shouldExcludeContentTypes, setShouldExcludeContentTypes] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [itemCount, setItemCount] = useState(10);
  const { isOpen: isFilterOpen, onToggle: onFilterToggle, onOpen: onFilterOpen, onClose: onFilterClose } = useDisclosure();

  const totalResourcesFilterReports = useMemo(() => {
    if (!contentItems) return [];
    return contentItems.filter((content) => {
      return !hasProjectOutput(content);
    });
  }, [contentItems]);

  const [totalResources, setTotalResources] = useState(totalResourcesFilterReports.length || 0);
  const [selectedResult, setSelectedResult] = useState({
    questionText: "",
    resultId: "",
    resultStatus: "pinned",
    resultText: "",
    confidence: 0,
    attributionMetadataIds: [] as string[],
    collectionId: "",
    conversationId: "",
    isQuestion: false,
    verifiedStatus: "verified",
    resultType: "answer",
    focus: "",
  });

  useEffect(() => {
    setTotalResources(totalResourcesFilterReports.length || 0);
  }, [totalResourcesFilterReports]);

  const resetFilter = useCallback(() => {
    setSelectedTags([]);
    setSearchText("");
    setStartDate(undefined);
    setEndDate(undefined);
    setExtractedContentTypes([]);
    setShouldExcludeContentTypes(false);
    setHasEverySelectedTag(true);
  }, []);

  useEffect(() => {
    // Reset filter state whenever content changes
    setShouldExcludeContentTypes(false);
  }, [contentItems]);

  const availableContent = useMemo(() => {
    if (!contentItems) return [];

    const isMatchingSelectedTags = (content: ContentDetails, selectedTags: string[], hasEverySelectedTag: boolean): boolean => {
      if (selectedTags.length === 0) return true;
      const tags = [...content.manualTags, ...content.autoTags, content.category!];
      return hasEverySelectedTag
        ? selectedTags.every((selectedTag) => tags!.includes(selectedTag.trim()))
        : selectedTags.some((selectedTag) => tags!.includes(selectedTag.trim()));
    };

    const isMatchingSelectedCategories = (content: ContentDetails, selectedCategories: string[]): boolean => {
      if (selectedCategories.length === 0) return true;
      return selectedCategories.every(
        (selectedCategory) =>
          (content.category && content.category!.includes(selectedCategory.trim())) ||
          (content.type && content.type!.includes(selectedCategory.trim()))
      );
    };

    const isMatchingExtractedContent = (
      content: ContentDetails,
      selectedExtractedContentTypes: string[],
      shouldExcludeContentTypes: boolean
    ): boolean => {
      const searchTokens = selectedExtractedContentTypes;
      const cellTokens = extractExtractedContentFromCell(content, shouldExcludeContentTypes);

      return searchTokens.every((searchToken) => cellTokens?.join(" ").toLowerCase().includes(searchToken));
    };

    const isMatchingSearchText = (content: ContentDetails, searchText: string): boolean => {
      if (!searchText || searchText.trim() === "") return true;
      const searchTokens = searchText.toLowerCase().trim().split(" ");
      const cellTokens = extractTokensFromCell(content);

      return searchTokens.every((searchToken) => cellTokens?.join(" ").toLowerCase().includes(searchToken));
    };

    const isMatchingDateRange = (content: ContentDetails, startDate?: Date, endDate?: Date): boolean => {
      if (!startDate && !endDate) return true;

      const cellDate = new Date(content.timeCreated).getTime();
      if (startDate) {
        const startDateEpoch = startDate.getTime();
        if (cellDate < startDateEpoch) return false;
      }
      if (endDate) {
        const endDateEpoch = endDate.getTime();
        if (cellDate > endDateEpoch) return false;
      }
      return true;
    };

    return contentItems.reduce((accum, content) => {
      if (
        isMatchingSelectedTags(content, selectedTags, hasEverySelectedTag) &&
        isMatchingSelectedCategories(content, selectedCategories) &&
        isMatchingExtractedContent(content, selectedExtractedContentTypes, shouldExcludeContentTypes) &&
        isMatchingSearchText(content, searchText) &&
        isMatchingDateRange(content, startDate, endDate)
      )
        accum.push(content);

      return accum;
    }, [] as typeof contentItems);
  }, [
    contentItems,
    selectedTags,
    hasEverySelectedTag,
    selectedCategories,
    selectedExtractedContentTypes,
    shouldExcludeContentTypes,
    searchText,
    startDate,
    endDate,
  ]);

  return (
    <ContentFilterContext.Provider
      value={{
        resultsDataCount,
        setResultsDataCount,
        selectedExtractedContentTypes,
        setExtractedContentTypes,
        selectedTags,
        setSelectedTags,
        selectedCategories,
        setSelectedCategories,
        searchText,
        setSearchText,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        availableContent,
        shouldExcludeContentTypes,
        setShouldExcludeContentTypes,
        itemCount,
        setItemCount,
        resetFilter,
        isFilterOpen,
        onFilterToggle,
        onFilterOpen,
        onFilterClose,
        hasEverySelectedTag,
        setHasEverySelectedTag,
        totalResources,
        setTotalResources,
        selectedResult,
        setSelectedResult,
      }}>
      {children}
    </ContentFilterContext.Provider>
  );
};
