import { Button } from "@chakra-ui/react";
import React from "react";
import type { FunctionComponent } from "react";
import type { Notification } from "types/notifications";
import { v4 as uuid } from "uuid";
import { useButtonProps } from "hooks";
import { sendMessage } from "state/websocket/operations";

interface Props {
  notification: Notification;
}

export const AcceptShareButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ notification }) => {
  const commonButtonProps = useButtonProps("sm", "primary");

  return (
    <Button
      className="ch-notification-message-accept"
      aria-label="Accept"
      visibility={["hidden", "hidden", "visible"]}
      {...commonButtonProps}
      onClick={() => {
        const reference = notification.reference;
        if (reference) {
          const code = reference.inviteCode as string;
          const conversationId = uuid();
          sendMessage({
            conversationId: conversationId,
            intent: "/accept_share",
            entities: [{ entity: "share_invite_id", value: code }],
            message: `/accept_share >share_invite_id ${code}`,
          });
        }
      }}>
      Accept Share
    </Button>
  );
};
