import { Box, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import { useExternalIntegrations } from "hooks";
import { ReportSelector } from "../researchReport/ReportSelector";
import { InstallIntegrationButton } from "../components/InstallIntegrationButton";

export const ProjectReportForm = () => {
  const inputBgColor = useColorModeValue("white", "#191f23");
  const { hasIntegration } = useExternalIntegrations("Reporting");

  return (
    <Box pt="1rem">
      <SectionHeader
        title="Research Report (optional)"
        subTitle="Selecting a report template will allow Charli to generate a report containing the research content, and send it to the email address below. The report will use all the research criteria, results and topics defined in the previous steps."
        subTitleStyle={{ marginBottom: "1rem" }}
      />
      {!hasIntegration && (
        <Box pb="2rem">
          <InstallIntegrationButton category="Reporting" />
        </Box>
      )}
      <ReportSelector disabled={!hasIntegration} styles={{ background: inputBgColor }} />
    </Box>
  );
};
