import type { FunctionComponent } from "react";
import React from "react";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import type { Message } from "types/conversation";
import { ChatBubble, MessageContainer } from "../components";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useContents } from "hooks";

interface Props {
  message: Message;
  label: string;
  metadataIds: string[];
}

export const CanvasCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ message, label, metadataIds }) => {
  const navigate = useNavigate();
  const contents = useContents(metadataIds);
  const allItemsAreSameType = contents ? new Set(contents.map((content) => content.type)) : undefined;
  const maybeSingleType = allItemsAreSameType && allItemsAreSameType.size === 1 ? [...allItemsAreSameType][0] : "item";
  const subText = metadataIds.length === 1 ? `1 ${maybeSingleType}` : `${metadataIds.length} ${maybeSingleType}s`;

  return (
    <MessageContainer initiator={message.senderId === "charli" ? "charli" : "user"} key={`${message.id}-button-${label}`}>
      <Box position="relative">
        <ChatBubble
          initiator={message.senderId === "charli" ? "charli" : "user"}
          state={message.state}
          onClick={() => {
            navigate(`/search/${message.conversationId}`);
          }}>
          <Stack direction="row" alignItems="center" p="1">
            <Flex flexDirection="column">
              <Text fontSize="sm" fontWeight={600}>
                {label}
              </Text>
              <Text fontSize="sm">{subText}</Text>
            </Flex>
            <ChevronRightIcon boxSize="1.5rem" />
          </Stack>
        </ChatBubble>
      </Box>
    </MessageContainer>
  );
};
