import React, { useCallback } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";
import { Checkbox, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, Stack } from "@chakra-ui/react";

interface IProps {
  checkpointIndex: number;
}

export const EntityToSplit = ({ checkpointIndex }: IProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>();
  const showEntityToSplit = useWatch({ control, name: `config.checkpoints.${checkpointIndex}.showEntityToSplit` });

  const getError = useCallback(() => {
    const maybeEntityToSplit = errors.config?.checkpoints && errors.config?.checkpoints[checkpointIndex];

    if (!maybeEntityToSplit) {
      return undefined;
    } else return maybeEntityToSplit.entityToSplitName;
  }, [checkpointIndex, errors.config?.checkpoints]);

  return (
    <Stack>
      <Controller
        render={({ field }) => (
          <Checkbox size={"sm"} isChecked={field.value} name={field.name} onChange={(e) => field.onChange(e.target.checked)}>
            Split on entity?
          </Checkbox>
        )}
        name={`config.checkpoints.${checkpointIndex}.showEntityToSplit`}
        control={control}
      />

      {showEntityToSplit && (
        <Grid templateColumns={"repeat(3, 1fr)"} gap="1rem">
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!getError()}>
              <FormLabel fontSize="sm">Entity name</FormLabel>
              <Controller
                render={({ field }) => <Input {...field} size="sm" type="text" />}
                name={`config.checkpoints.${checkpointIndex}.entityToSplitName`}
                control={control}
              />
              {getError() && (
                <FormErrorMessage fontSize="sm" color="red.500">
                  {getError()?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>

          <GridItem colSpan={1}>
            <FormControl>
              <FormLabel fontSize="sm">Rename to (optional)</FormLabel>
              <Controller
                render={({ field }) => <Input {...field} size="sm" type="text" />}
                name={`config.checkpoints.${checkpointIndex}.entityToSplitRenameTo`}
                control={control}
              />
            </FormControl>
          </GridItem>

          <GridItem colSpan={1}>
            <FormControl>
              <Controller
                render={({ field }) => (
                  <Checkbox size={"sm"} isChecked={field.value} name={field.name} onChange={(e) => field.onChange(e.target.checked)}>
                    Should unwrap?
                  </Checkbox>
                )}
                name={`config.checkpoints.${checkpointIndex}.entityToSplitShouldUnwrap`}
                control={control}
              />
            </FormControl>
          </GridItem>
        </Grid>
      )}
    </Stack>
  );
};
