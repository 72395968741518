import type { Static } from "runtypes";
import { Record, String } from "runtypes";

export const SummaryData = Record({
  type: String,
  value: String,
  context: String.nullable().optional(),
  label: String.nullable().optional(),
});

export type SummaryData = Static<typeof SummaryData>;
