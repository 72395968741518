import { useToast } from "@chakra-ui/react";
import React, { useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { ToastMessageContent } from "screens/common/components";
import { sendMessage } from "state/websocket/operations";
import { v4 as uuid } from "uuid";
import { ConversationContext } from "screens/thread/ConversationContext";
import moment from "moment";
import type { RequestEntities } from "types/charliui";
import { useUserProfile } from "./useUserProfile";
import type { CalendarEvent } from "types/meetings";
import type { Entity } from "types/workflows/workflow";

export const VIDEO_CONFERENCE_SYSTEM_TO_INTEGRATION_MAP = {
  webex: "webex_video_conference",
  teams: "ms_teams_video_conference",
};

export function useMeetings() {
  const toast = useToast();
  const dispatch = useDispatch();
  const { onConversationOpen, setConversationId } = useContext(ConversationContext);
  const conversationId = useRef(uuid());
  const daysToRetrieve = 30;
  const fromDateTime = moment().add(-daysToRetrieve, "days").format("YYYY-MM-DD");
  const toDateTime = moment().format("YYYY-MM-DD");
  const entities: RequestEntities = [];
  entities.push({ entity: "from_date_time", value: fromDateTime });
  entities.push({ entity: "to_date_time", value: toDateTime });
  const { email } = useUserProfile();

  const syncMeetings = () => {
    setConversationId(conversationId.current);
    dispatch(
      sendMessage({
        conversationId: conversationId.current,
        intent: "/sync_meetings",
        entities,
        message: `/sync_meetings >from_date_time ${fromDateTime} >to_date_time ${toDateTime}`,
      })
    );
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`Retrieving meeting details from the last ${daysToRetrieve} days. This may take a few minutes.`}
          onClick={() => {
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const prepareMeetingReport = (meetingId: string, projectName: string) => {
    setConversationId(conversationId.current);
    dispatch(
      sendMessage({
        conversationId: conversationId.current,
        intent: "/prepare_meeting_report",
        entities: [
          { entity: "meetingId", value: meetingId },
          { entity: "email_address", value: email },
        ],
        message: `/prepare_meeting_report >name ${projectName} >email_address ${email} >meetingId ${meetingId}`,
      })
    );
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`Retrieving meeting details for ${projectName}. I will create a report and email it to you.`}
          onClick={() => {
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const getFullVideoConferenceEvent = (calendarEvent: CalendarEvent): string => {
    const webLink = calendarEvent.video_conference_event.video_conference_web_link;
    const subject = calendarEvent.subject;

    const entities: Entity[] = [{ entity: "video_conference_web_link", value: webLink }];

    if (VIDEO_CONFERENCE_SYSTEM_TO_INTEGRATION_MAP[calendarEvent.video_conference_event.video_conference_system]) {
      entities.push({
        entity: "integration",
        value: VIDEO_CONFERENCE_SYSTEM_TO_INTEGRATION_MAP[calendarEvent.video_conference_event.video_conference_system],
      });
    }

    const mapEntities = (entities: Entity[]) => {
      return entities.reduce((currentStr, current) => currentStr + `>${current.entity} ${current.value}`, "");
    };

    dispatch(
      sendMessage({
        conversationId: conversationId.current,
        intent: "/resolve_video_conference_event",
        entities: entities,
        message: `/resolve_video_conference_event ${mapEntities}`,
      })
    );
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`Hold tight! I'm Checking if meeting: "${subject}" has a transcript that I can process`}
          onClick={() => {
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });

    return conversationId.current;
  };

  return { syncMeetings, prepareMeetingReport, getFullVideoConferenceEvent };
}
