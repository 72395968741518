import React, { useEffect, useState } from "react";
import { useToast, Box, Button, useColorModeValue, Text, Stack } from "@chakra-ui/react";
import { useNotifications } from "hooks";
import { NotificationTile } from "screens/landing/tabs/notifications/NotificationTile";
import { shallowEqual, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { useNavigate } from "react-router-dom";

export const NotificationListener = () => {
  const toast = useToast();
  const { notificationsToPopup, newNotifications, dismissNotification, actionNotification } = useNotifications();
  const [toastRefs, setToastRefs] = useState<string[]>([]);
  const { hasInitialSyncCompleted } = useSelector((state: RootState) => state.conversation, shallowEqual);
  const navigate = useNavigate();
  const linkColor = useColorModeValue("charli.primaryBlue", "gray.500");
  const bgColorToast = useColorModeValue("gray.50", "gray.700");

  useEffect(() => {
    if (hasInitialSyncCompleted && notificationsToPopup.length > 0) {
      const newToastRefs = toastRefs;

      // Show toasts for any new notifications
      toast({
        id: notificationsToPopup[0].id,
        position: "top-right",
        duration: null,
        isClosable: true,
        render: ({ onClose }) => {
          return (
            <Box width="28rem" pr="1.5rem" pt="1rem">
              <NotificationTile
                notification={notificationsToPopup[0]}
                onDismiss={(id) => {
                  onClose();
                  dismissNotification(id);
                }}
                onAction={(id) => {
                  onClose();
                  actionNotification(id);
                }}
                source="toast"
              />
              {notificationsToPopup.length > 1 && (
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  bgColor={bgColorToast}
                  borderRadius="md"
                  py="1rem"
                  px="1.5rem"
                  boxShadow="none"
                  mt="5px">
                  <Stack direction="row" justifyContent="space-between">
                    <Text fontSize="sm" color="gray.500">
                      {notificationsToPopup.length - 1} more notifications
                    </Text>
                    <Button
                      variant="link"
                      cursor="pointer"
                      onClick={() => {
                        onClose();
                        navigate(`/notifications`);
                      }}
                      size="sm"
                      color={linkColor}>
                      <Text>View All</Text>
                    </Button>
                  </Stack>
                </Box>
              )}
            </Box>
          );
        },
      });

      newToastRefs.push(notificationsToPopup[0].id);

      setToastRefs(newToastRefs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionNotification, dismissNotification, notificationsToPopup, toast, toastRefs, hasInitialSyncCompleted]);

  useEffect(() => {
    let newToastRefs = toastRefs;

    // Dismiss toasts that have been closed elsewhere
    toastRefs.forEach((toastRef) => {
      if (!newNotifications.find((notification) => notification.id === toastRef)) {
        // We've found a toast that doesn't exist in the newNotifications array. Close it.
        toast.close(toastRef);

        newToastRefs = newToastRefs.filter((existingToastRef) => existingToastRef !== toastRef);
      }
    });

    setToastRefs(newToastRefs);
  }, [newNotifications, toast, toastRefs]);

  return <React.Fragment />;
};
