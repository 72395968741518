import { Box, FormControl, Select, Stack } from "@chakra-ui/react";
import { useExternalIntegrations, useUserPreferences } from "hooks";
import type { FunctionComponent } from "react";
import { useState, useEffect, useCallback, useContext } from "react";
import React from "react";
import { InstallIntegrationButton } from "screens/panels/components/InstallIntegrationButton";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";

interface Props {
  categoryKey?: string; // the category key filter and use in the selector
  category: string; // the category name to display in the InstallIntegrationButton
  integrationUrn?: string; // the integration urn to set as the default
  name?: string;
  showConnectIntegrationsButton?: boolean;
  isDisabled?: boolean;
}

export const IntegrationSelector: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  categoryKey,
  category,
  integrationUrn,
  name,
  showConnectIntegrationsButton = false,
  isDisabled,
}) => {
  const { availableIntegrations } = useExternalIntegrations(undefined, categoryKey, undefined, integrationUrn);
  const { userPreferences } = useUserPreferences();
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const { setRequestEntities } = useContext(ConversationContext);

  const handleOnChange = (value: string) => {
    setSelectedIntegration(value);
  };

  const updateIntegrations = useCallback(
    (entityValue: string) => {
      updateBatchRequestEntities([{ entity: "integration", value: entityValue, source: "integration-selector" }], setRequestEntities);
    },
    [setRequestEntities]
  );

  const selectIntegrationDefault = useCallback(() => {
    const userPreferenceUrn = userPreferences[categoryKey || ""];
    if (userPreferenceUrn) {
      setSelectedIntegration(String(userPreferenceUrn) || "");
    } else {
      availableIntegrations.length > 0 && availableIntegrations[0].urn && updateIntegrations(availableIntegrations[0].urn);
    }
  }, [availableIntegrations, categoryKey, updateIntegrations, userPreferences]);

  useEffect(() => {
    selectIntegrationDefault();
  }, [selectIntegrationDefault]);

  useEffect(() => {
    updateIntegrations(selectedIntegration);
  }, [selectedIntegration, updateIntegrations]);

  return (
    <IntegrationSelect
      name={name}
      isDisabled={isDisabled}
      onIntegrationChange={(newIntegrationValue) => {
        categoryKey && handleOnChange(newIntegrationValue);
      }}
      categoryKey={categoryKey}
      selectedIntegration={selectedIntegration}
      category={category}
      integrationUrn={integrationUrn}
      showConnectIntegrationsButton={showConnectIntegrationsButton}
    />
  );
};

export const IntegrationSelect = ({
  name,
  onIntegrationChange,
  categoryKey,
  selectedIntegration,
  category,
  integrationUrn,
  showConnectIntegrationsButton,
  isDisabled,
}: {
  name?: string;
  onIntegrationChange: (value: string) => void;
  categoryKey?: string;
  selectedIntegration: string;
  category: string;
  integrationUrn?: string;
  showConnectIntegrationsButton?: boolean;
  isDisabled?: boolean;
}) => {
  const { userPreferences } = useUserPreferences();
  const { availableIntegrations } = useExternalIntegrations(undefined, categoryKey, undefined, integrationUrn);
  const { hasIntegration } = useExternalIntegrations(category, categoryKey, undefined, integrationUrn);
  const categoryPreference = userPreferences[categoryKey || ""];
  const { setRequestEntities } = useContext(ConversationContext);
  const [hasSelectedIntegration, setHasSelectedIntegration] = useState(selectedIntegration);

  useEffect(() => {
    !selectedIntegration && setHasSelectedIntegration(categoryPreference as string);
    updateBatchRequestEntities(
      [{ entity: "integration", value: categoryPreference as string, source: "integration-selector" }],
      setRequestEntities
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      <FormControl isDisabled={isDisabled} flex={1} display="flex" alignItems="center">
        <Box width={"100%"}>
          <Select
            name={name}
            width={"100%"}
            className={`ch-integration-selector ${categoryKey && categoryKey.toLowerCase()}-selector`}
            size="sm"
            value={hasSelectedIntegration}
            borderRadius="lg"
            onChange={(e: { target: { value: string } }) => {
              setHasSelectedIntegration(e.target.value);
              onIntegrationChange(e.target.value);
            }}>
            {availableIntegrations
              .filter((integration) => integration.isEnabled && integration.isAvailable)
              .map((integration) => (
                <option key={integration.urn} value={integration.urn}>
                  {integration.name}
                </option>
              ))}
          </Select>
        </Box>
      </FormControl>
      <>
        {!hasIntegration ? (
          <Box flex={1}>
            <InstallIntegrationButton category={category} />
          </Box>
        ) : (
          showConnectIntegrationsButton && (
            <Box flex={1}>
              <InstallIntegrationButton
                category={category}
                message={
                  categoryKey && categoryPreference
                    ? `You can connect more ${category} integrations by clicking here`
                    : `You can change your default ${category} integration by clicking here`
                }
              />
            </Box>
          )
        )}
      </>
    </Stack>
  );
};
