import type { FunctionComponent } from "react";
import React from "react";
import { Text } from "@chakra-ui/react";
import type { Message } from "types/conversation";
import { MessageContainer } from "./components/MessageContainer";
import { ChatBubble } from "./components";
import type { CharliUIRoot } from "types/charliui";
import { parseISO } from "date-fns";

interface Props {
  message: Message;
  datum: CharliUIRoot;
}

export const ListCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ message, datum }) => {
  const list = datum.body.inlineListElement.content.list as Array<string>;
  const title = datum.body.inlineListElement.content.title as string | null;

  return (
    <MessageContainer initiator={message.senderId === "charli" ? "charli" : "user"} key={`${message.id}-list`}>
      <ChatBubble
        key={message.id}
        initiator={message.senderId === "charli" ? "charli" : "user"}
        date={parseISO(message.createdDate) ?? new Date()}>
        {title && (
          <Text fontSize="sm" fontWeight="500" color={"black"}>
            {title}
          </Text>
        )}
        <ul style={{ listStylePosition: "inside", fontSize: "0.8rem" }}>
          {list.map((listItem) => (
            <li>{listItem}</li>
          ))}
        </ul>
      </ChatBubble>
    </MessageContainer>
  );
};
