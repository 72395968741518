import type { Static } from "runtypes";
import { Record, String } from "runtypes";
import { DateAsString } from "@charliai/node-core-lib/lib/src/shared/DateAsString";

export const SharedByMe = Record({
  recipientName: String,
  message: String.nullable(),
  acceptedAt: DateAsString.nullable(),
});

export type SharedByMeType = Static<typeof SharedByMe>;
