import { useCollectionKey, useConfigMap, useGetViewConfig, useLatestCollectionWorkflowId, useProjectParams } from "hooks";
import { useWorkflowCompletionDateOrNow, useWorkflowKey } from "hooks/useWorkflows";
import { useMaxAllowedProjectsReached } from "hooks/useStats";
import { useMemo, useState, useCallback } from "react";

export const useConversationDialogUtils = () => {
  const { projectFilter, projectId, parentRoute } = useProjectParams();
  const maybeCollectionType = useCollectionKey(projectFilter, "collectionType");
  const configMap = useConfigMap();
  const portfolioCollectionType = useGetViewConfig("portfolioProjectType", maybeCollectionType, configMap);

  const currentCollectionType = useMemo(() => {
    if (portfolioCollectionType) return portfolioCollectionType;
    if (maybeCollectionType) return maybeCollectionType;
    if (projectFilter || parentRoute === "home") return "due_diligence";
    return undefined;
  }, [portfolioCollectionType, maybeCollectionType, projectFilter, parentRoute]);

  const hasExceededMaxProjects = useMaxAllowedProjectsReached(currentCollectionType || "");
  const maxAllowedProjects = useGetViewConfig("maxActiveProjectsAllowed", currentCollectionType || "", configMap);
  const currentWorkflowId = useLatestCollectionWorkflowId(projectId);
  const workflowStatus = useWorkflowKey(currentWorkflowId, "status");
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  const [canBypassDisabled, setCanBypassDisabled] = useState(false);

  const setCanBypassDisabledCallback = useCallback((value: boolean) => {
    setCanBypassDisabled(value);
  }, []);

  const result = useMemo(
    () => ({
      hasExceededMaxProjects,
      currentCollectionType,
      maxAllowedProjects,
      currentWorkflowId,
      workflowStatus,
      currentWorkflowCompletionDate,
      canBypassDisabled,
      setCanBypassDisabled: setCanBypassDisabledCallback,
    }),
    [
      hasExceededMaxProjects,
      currentCollectionType,
      maxAllowedProjects,
      currentWorkflowId,
      workflowStatus,
      currentWorkflowCompletionDate,
      canBypassDisabled,
      setCanBypassDisabledCallback,
    ]
  );

  return result;
};
