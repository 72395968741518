import { Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useContext, useState, useEffect, startTransition } from "react";
import ECHighlighter from "react-ec-highlighter";
import { ContentFilterContext } from "screens/content";
import type { GapAnalysisResultsData } from "types/collection";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";
import { SuggestedQuestionsResults } from "./SuggestedQuestionsResults";

export interface Props {
  gapAnalysisResults: GapAnalysisResultsData[];
}

export const GapAnalysisResults: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ gapAnalysisResults }) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const { searchText } = useContext(ContentFilterContext);
  const [filteredQuestions, setFilteredQuestions] = useState<GapAnalysisResultsData[]>(gapAnalysisResults);

  useEffect(() => {
    if (searchText && searchText.length > 0) {
      startTransition(() => {
        setFilteredQuestions(
          gapAnalysisResults.filter(
            (result) =>
              result.corpus.topic.includes(searchText) ||
              result.corpus.content.includes(searchText) ||
              result.suggested_questions.some((question) => question.question.includes(searchText))
          )
        );
      });
    } else {
      setFilteredQuestions(gapAnalysisResults);
    }
  }, [gapAnalysisResults, searchText]);

  return (
    <>
      {filteredQuestions.length > 0 &&
        filteredQuestions.map((gapAnalysisRecord, index) => (
          <Stack key={index}>
            <BlockSectionHeader title={`AI Gap Analysis ${filteredQuestions.length > 1 ? index + 1 : ""}`} />
            <Stack width="100%" spacing="1rem">
              <Text color={textColor} fontSize="md" fontWeight="semibold">
                {searchText && searchText.length > 0 && gapAnalysisRecord.corpus.topic ? (
                  <ECHighlighter searchPhrase={searchText} text={gapAnalysisRecord.corpus.topic} />
                ) : (
                  gapAnalysisRecord.corpus.topic
                )}
              </Text>
              <Stack pb="1rem">
                {gapAnalysisRecord.corpus.content.length > 0 &&
                  gapAnalysisRecord.corpus.content.split("\n").map(
                    (sentence, index) =>
                      sentence.length > 0 && (
                        <Text fontSize="sm" key={index} pl=".7rem" width="100%">
                          {searchText && searchText.length > 0 && sentence ? (
                            <ECHighlighter searchPhrase={searchText} text={sentence} />
                          ) : (
                            sentence
                          )}
                        </Text>
                      )
                  )}
              </Stack>
            </Stack>
            <SuggestedQuestionsResults gapAnalysisQuestions={gapAnalysisRecord.suggested_questions} subSection />
          </Stack>
        ))}
    </>
  );
};
